import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import TimePickerCustomCss from '../../css/CustomGlobalStyle/TimePickerCustom'
import DatePicker, { registerLocale } from "react-datepicker";
import ko from 'date-fns/locale/ko';
import Util, {calert, calertPromise, setLocalStorage, getLocalStorage} from '../../lib/Util'
import { getReserveAvaliableDate, getSelectedDateAlertFlag, getNextDayStr } from '../../lib/TimeApi'
import store from '../../reduxStore'

import 'rc-time-picker/assets/index.css';
import TimePickerNewCustomCss from '../../css/CustomGlobalStyle/TimePickerNewCustom.js'
import {
    RESERVE_TYPE_GENERAL_FLAG,
    RESERVE_TYPE_GOLF_FLAG,
    RESERVATION_BASE_TIME,
    RESERVATION_GOLF_BASE_TIME,
    RESERVATION_BASE_PRICE,
    RESERVATION_GOLF_PRICE,
} from '../../constantData'
import {getTotalMinuteToHourMinuteStr} from '../../lib/MonthlyMosilerApi'



// import {NavLink} from 'react-router-dom'
// import icon_calendar from '../../img/booking/icon_calendar.svg'
// import icon_arrow_right from '../../img/booking/icon_arrow_right.svg'
// import scriptjs from 'scriptjs'
import { addDays, subDays, getDate } from 'date-fns';
import TextTimePicker from './TextTimePicker';
import TextTimePickerType2 from './TextTimePickerType2';
registerLocale('ko', ko)

const MAX_HOURS = 24;
const MAX_MINUTE = 60;
const MINUTE_STEP = 10;     // 10분단위로 (10, 20, 30, 40 ...)

class TimeSelectorNewMonthlyOnce extends Component {
    constructor(props) {
        super(props)
        this.endEsc = null;
        this.state={
            date            : getReserveAvaliableDate(moment().toDate(), this.props.closeHour),
            startTime       : moment().startOf('days'),
            endTime         : moment().startOf('days').add(this.getReserveTypeToEndTimeHour(this.props.type), 'hours'),
            diffTime        : 0,
            diffTimeRemain  : 0,
            addTime         : 0,
            limitTime         : 0,

            // selectedDate: moment('2021-01-10T00:00:00.000'),
            // startTime: moment(),
            // endTime: moment().add(1,'hours'),
            startVisibleHours:[],
            startVisibleMinute:[],
            endVisibleHours:[],
            endVisibleMinute:[],

            startTimePickerProps:{},
            endTimePickerProps:{},

            isOpenFlag:false,
            isAlertFlag:false,

            step1Flag : false,
            step2Flag : false,
            step3Flag : false,

            displayStartDate: '',
            displayStartTime:'',
            displayEndDate:'',
            displayEndTime:'',

            calcItem:{
                DrivingTimes:'',
                ReseveCharge:0,
                ReserveDiscountAmount:0,
                DrivingCharge:0,
                StartAreaFee:0,
                AreaFee:0,
                NightFee:0,
                RemainTimes:0,
                ExcessAmount:0,
                ContractOneDayAvaliableHour:8,
            },
            reserveType: 0
        }        
    }  

    typingTimer = null;
    doneTypingInterval = 500;

    /*
    로딩을 화면 전체로 하는 것이 아니라 
    데이터 표시부, 다음 버튼 쪽에 gray치는 식으로 하는 게 좋을 것 같다.
    시작시간은 되도록 딜레이 없게끔 처리하는 방향으로..
    */

    componentWillUnmount() {
        setLocalStorage('reserve_step2_backup', this.state)
    }

    getUserAvaliableTime = () => {
        
        return this.state.calcItem.ContractOneDayAvaliableHour
        
    }
    
    async componentDidMount () {
        let tmpLS = getLocalStorage('reserve_step2_backup')
        await this.calcDataProcessNewInit()
        if (tmpLS === null) {
            this.setState({
                // startVisibleHours:this.initStartDisabledHour(),
                // startVisibleMinute: this.initStartDisabledMinute(),
                endVisibleHours:this.initEndDisabledHour(),
                endVisibleMinute: this.initEndDisabledMinute(),
                reserveType: this.props.type,
                // limitTime: this.getUserAvaliableTime(),
                // addTime:this.getUserAvaliableTime()
            })
            this.props.onInit();
        }
        else {
            this.setState({
                ...tmpLS,
                date                : moment(tmpLS.date).toDate(),
                startTime           : moment(tmpLS.startTime),
                endTime             : moment(tmpLS.endTime),
                reserveType         : tmpLS.reserveType,
                endVisibleHours     : this.initEndDisabledHour(),
                endVisibleMinute    : this.initEndDisabledMinute(),
                // limitTime: this.getUserAvaliableTime(),
                // addTime:this.getUserAvaliableTime()
            }, ()=>{
                // this.calcDataProcess()
            })
        }
    }   

    setIsOpenFlag = (flag) => {
        this.setState({ isOpenFlag: flag });
    }

    setIsAlertFlag = (flag) => {
        this.setState({ isAlertFlag: flag });
    }

    setStateOnce = (key, val) => {
        return new Promise((resolve) => {
            this.setState({
                [key]:val
            }, ()=>{
                resolve(true)
            }) 
        })
    }
    
    setStateMultiple = (obj) => {
        return new Promise((resolve) => {
            this.setState({
                ...obj
            }, ()=>{
                resolve(true)
            }) 
        })
    }

    setStateAddTime = (type) => {
        return new Promise((resolve) => {
            this.setState(
                (prevState) => ({
                    addTime: (type === 'increase') ? prevState.addTime + 1 : prevState.addTime - 1
                }),
                () => {
                    resolve(true)
                }
            );
        })
    }   

    getReserveTypeToEndTimeHour = (type) => {
        if (Number(type) === RESERVE_TYPE_GOLF_FLAG) return RESERVATION_GOLF_BASE_TIME;
        return RESERVATION_BASE_TIME;
    }
    

    // TimeSelector2가 처음 열릴때, 변경될때 시작시간 부분 선택 가능 영역에 대해서 세팅하는 처리
    initStartDisabledHour = () => {
        // 여기에 date가 개입하게 되면..
        let tmpArr = [];
        // let nowMoment = moment()        // 현재 시간으로 하고 싶을때는 moment()로
        // let nowMoment = moment('2021-01-13T19:51:00');
        let nowMoment = this.props.fixedStartTime
        
        let selectedDate = moment(this.state.date);
        
        // 내가 선택한 날짜와 현재의 날짜가 동일하면
        if (Number(selectedDate.format('YYYYMMDD')) === Number(nowMoment.format('YYYYMMDD'))) {
            for (let i=0; i<MAX_HOURS; i++) {
                // 매시 51분 이상일때는 disabled list로 포함시켜야 한다.
                if ( (i < nowMoment.hours()) ) { tmpArr.push(i); }  // 같은 시 이면 현 시점에서 선택 가능한 시을 배열화 한다.
                else if ( (i === nowMoment.hours()) && (nowMoment.minutes() >= 51)) {  tmpArr.push(i); }
            }
        } else {
            tmpArr = [];
        }
       
        return tmpArr;
    }

    initStartDisabledMinute = () => {
        // 여기에 date가 개입하게 되면..
        let tmpArr = [];
        // let nowMoment = moment()        // 현재 시간으로 하고 싶을때는 moment()로
        
        let nowMoment = this.state.startTime
        let selectedDate = moment(this.state.date);
        
        if (Number(selectedDate.format('YYYYMMDD')) === Number(nowMoment.format('YYYYMMDD'))) {
            for (let i=0; i<MAX_MINUTE; i+=MINUTE_STEP) {
                if (i < nowMoment.minutes()) {  tmpArr.push(i);  }  // 같은 시 이면 현 시점에서 선택 가능한 분을 배열화 한다.
            }
        } 
        else {
            tmpArr = [];
        }
        
        return tmpArr;
    }

    initEndDisabledHour = (e) => {
        // 여기에 date가 개입하게 되면..
        let tmpArr = [];
        let rteth = this.getReserveTypeToEndTimeHour(this.props.type);
        let tmpStartTime = (e && e !== undefined) ? 
            moment(e.toDate()).add(rteth, 'hours').hours() : 
            moment(this.state.startTime.toDate()).add(rteth, 'hours').hours();
        
        // 지정한 startTime 이전으로는 선택할 수 없도록 처리한다.
        for (let i=0; i<tmpStartTime; i++) {
            tmpArr.push(i);
        }

        return tmpArr;
    }

    initEndDisabledMinute = (e) => {
        // 여기에 date가 개입하게 되면..
        let tmpArr = [];
        let rteth = this.getReserveTypeToEndTimeHour(this.props.type);
        let tmpStartTime = (e && e !== undefined) ? 
            moment(e.toDate()).add(rteth, 'hours').minutes() : 
            moment(this.state.startTime.toDate()).add(rteth, 'hours').minutes();
        
        // 지정한 startTime 이전으로는 선택할 수 없도록 처리한다.
        for (let i = 0; i < tmpStartTime; i+=MINUTE_STEP ) {
            tmpArr.push(i);
        }

        return tmpArr;
    }


    disabledMinute = (e) => {
        // 여기에 date가 개입하게 되면..
        let tmpArr = [];
        // let nowMoment = moment()        // 현재 시간으로 하고 싶을때는 moment()로
        let nowMoment = this.props.fixedStartTime
        let selectedDate = moment(this.props.selectedDate);

        if (Number(selectedDate.format('YYYYMMDD')) === Number(e.format('YYYYMMDD'))) {
            if (e.hours() === nowMoment.hours()) {
                for (let i=0; i<MAX_MINUTE; i+=MINUTE_STEP) {
                    // 같은 시 이면 현 시점에서 선택 가능한 분을 배열화 한다.
                    if (i < nowMoment.minutes()) {
                        tmpArr.push(i);
                    }
                }
            } else {
                tmpArr = [];
            }
            
        } else {
            tmpArr = [];
        }
        
        return tmpArr;
    }

        /*
    starttime : 14:15면
    endtime은 15:15일테고

    endtime을 누르면 
    15:15 이전은 선택되면 안된다
    시를 16으로 바뀌면 00, 10, 20, 30.. 다 나와야 한다.
    다시 15시로 바뀌면 20, 30, 40, 50 으로 나와야 한다.

    이렇게 되려면 startTime
   
    */

    disabledMinuteFromStartTime = (e) => {
        // 여기에 date가 개입하게 되면..
        let tmpArr = [];
        let tmpStartTime = moment(this.state.startTime.toDate()).add(1,'hours');
        
        // 내가 지정한 시 와 startTime이 같다면
        if (e.hours() === tmpStartTime.hours()) {
            for (let i=0; i<MAX_MINUTE; i+=MINUTE_STEP) {
                // 같은 시 이면 현 시점에서 선택 가능한 분을 배열화 한다.
                if (i < tmpStartTime.minutes()) {
                    tmpArr.push(i);
                }
            }
        } else {
            tmpArr = [];
        }
        
        return tmpArr;
    }


    
    handleChangeStartTime = async (e) => {
        // 클릭할때마다 시간 계산 실시해서 setState에 넣어놓고
        // disabledHours에는 state값을 리턴하는 식으로 진행.
        // 시작시간일경우 "시"를 건들면 안된다. 분을 건들자.
        // 현재 시간이 14:10 라면 분쪽은 15, 20, 25, 30, 35, 40, 45, 50, 55 선택 가능
        // 현재 시간이 15:00 라면 분쪽은 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55 선택 가능
        
        // let tmp = moment(e.toDate());
        
        let tmpEndTime = moment(e.toDate())
        await this.setStateOnce('startTime', e)
        await this.setStateOnce('endTime', tmpEndTime.add(this.getReserveTypeToEndTimeHour(this.props.type), 'hours'))
        // this.props.onChangeStartTime(e)
        // this.props.onChangeEndTime(tmpEndTime.add(1, 'hours'))

        
        // 시간추가제로 되었으므로 이부분 처리는 필요없다.
        // this.setState({
        //     // startTime: e,
        //     // endTime: tmp.add(1,'hours'),

        //     startVisibleMinute: this.disabledMinute(e),
        //     endVisibleHours: this.initEndDisabledHour(e),
        //     endVisibleMinute: this.initEndDisabledMinute(e)
        // },()=>{
            
        // })
        await this.setStateOnce('step1Flag', true)
        this.calcDataProcess()
        
        
    }

    handleChangeEndTime = async (e) => {
        // 클릭할때마다 시간 계산 실시해서 setState에 넣어놓고
        // disabledHours에는 state값을 리턴하는 식으로 진행.
        // 시작시간일경우 "시"를 건들면 안된다. 분을 건들자.
        // 현재 시간이 14:10 라면 분쪽은 15, 20, 25, 30, 35, 40, 45, 50, 55 선택 가능
        // 현재 시간이 15:00 라면 분쪽은 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55 선택 가능
        await this.setStateOnce('endTime', e)
        this.props.onChangeEndTime(e)
        this.setState({
            // endTime: e,
            endVisibleMinute: this.disabledMinuteFromStartTime(e)
        },()=>{
            
        })
        this.calcDataProcess()
        // 20210218 [MosilerAPP-User]TestCheck_List.xlsx H10셀에 의해 사용하지 않음
        // this.endEsc();
    }

    handleChangeIncreaseAddTime = async (e) => {
        await this.setStateAddTime('increase')
        this.calcDataProcess()
    }
    handleChangeDecreaseAddTime = async (e) => {
        await this.setStateAddTime('decrease')
        this.calcDataProcess()
    }
    handleChangeAddTime = async (e) => {
        this.props.onDisableBtn()
        await this.setStateOnce('addTime', Number(e.target.value))
        await this.calcDataProcessNew();
        this.props.onEnableBtn()       
    }

    changeHourView = (data) => {
        let tmp = '';
        try {
            tmp = data.replace('시간','시간 ')
            
        }
        catch(e){}
        return tmp;
    }

    // 예상소요시간이 1분 이상인지를 체크한다. 1분 이상일경우
    // 예상소요시간, 시간 추가 기능을 활성화 한다.
    isChkPredictingHourMin = (hour,min) => {
        let tmpMoment = moment().startOf('days');
        let tmpMomentOrigin = tmpMoment.unix()  // 0시 0분 기준의 timestamp값
        let tmpMomentCompare = tmpMoment.add(hour,'hours').add(min,'minutes').unix() // hour, min값이 포함된 timestamp값
        
        // 같으면 false 다르면 true
        // origin과 compare가 같으면 예상소요시간이 산출되지 않았다는 의미가 된다.(0시간0분이기 때문에)
        return !(tmpMomentOrigin === tmpMomentCompare)
    }

    calcDataProcess = async () => {
        const {step1Flag, addTime} = this.state;

        if (step1Flag) {
            console.log('조건에 해당됨.')
            let startHour = Number(this.state.startTime.hours()),
                startMinute = Number(this.state.startTime.minutes());
            let endHour = Number(this.state.endTime.hours()),
                endMinute = Number(this.state.endTime.minutes());

            let tmpStartTime = moment(this.state.date).set('hours', startHour).set('minutes', startMinute)
            let tmpEndTime = moment(tmpStartTime.toDate()).add(addTime, 'hours')
            

            
            // 날짜, 시간관련 Redux에도 저장해놓는다. 
            this.props.onChangeStartTime(tmpStartTime)
            this.props.onChangeEndTime(tmpEndTime)
            this.props.onChangeDate(this.state.date)
            this.props.onChangeAddtime(addTime)
            
            await this.setStateMultiple({
                displayStartDate : tmpStartTime.format('yyyy-MM-DD'),
                displayStartTime : tmpStartTime.format('HH시 mm분'),

                displayEndDate : tmpEndTime.format('yyyy-MM-DD'),
                displayEndTime : getNextDayStr(tmpStartTime.format('yyyyMMDD'), tmpEndTime.format('yyyyMMDD')) + tmpEndTime.format('HH:mm'),
                diffTime: `${moment.duration(tmpEndTime.diff(tmpStartTime)).hours()}시간 ${moment.duration(tmpEndTime.diff(tmpStartTime)).minutes()}분`,
                diffTimeRemain: moment.duration(tmpEndTime.diff(tmpStartTime)).asMinutes()
            }) 


            try {
              
                let calcData = await this.props.onCalc({
                    startTime: tmpStartTime.format('yyyy-MM-DD HH:mm:00.000'),
                    endTime: tmpEndTime.format('yyyy-MM-DD HH:mm:00.000')
                })
                await this.setStateMultiple({
                    // limitTime: calcData.ContractOneDayAvaliableHour,
                    // addTime:calcData.ContractOneDayAvaliableHour,
                    calcItem: calcData
                }) 

                
            }
            catch(e){
                await calertPromise('계산할 수 없었습니다. 다시 확인해주세요.')
                console.log(e)
            }

        }
        else {
            console.log('조건에 해당하지 않았음')
        }
    }

    calcDataProcessNewInit = () =>{
        return new Promise(async (resolve)=>{
            let calcData = await this.props.onCalc({
                startTime: moment().format('yyyy-MM-DD HH:mm:00.000'),
                endTime: moment().format('yyyy-MM-DD HH:mm:00.000')
            })
            await this.setStateMultiple({
                limitTime: calcData.ContractOneDayAvaliableHour,
                addTime:calcData.ContractOneDayAvaliableHour,
            }) 
            resolve(true)
        })
    }

    calcDataProcessNew = (timeDate) => {
        const {step1Flag, addTime} = this.state;
        return new Promise(async (resolve)=>{
            if (step1Flag) {
                console.log('조건에 해당됨.')
                let startHour = null,
                    startMinute = null;
                
                if (timeDate === undefined) {
                    startHour = Number(this.state.startTime.hours());
                    startMinute = Number(this.state.startTime.minutes());
                }
                else {
                    startHour = moment(timeDate).hours();
                    startMinute = moment(timeDate).minutes();
                }
                
                let tmpStartTime = moment(this.state.date).set('hours', startHour).set('minutes', startMinute)
                let tmpEndTime = moment(tmpStartTime.toDate()).add(addTime, 'hours')
                
                // 날짜, 시간관련 Redux에도 저장해놓는다. 
                this.props.onChangeStartTime(tmpStartTime)
                this.props.onChangeEndTime(tmpEndTime)
                this.props.onChangeDate(this.state.date)
                this.props.onChangeAddtime(addTime)
                
                const ampmText = tmpEndTime.format('a') === 'am' ? '오전 ' : '오후 ';

                await this.setStateMultiple({
                    displayStartDate : tmpStartTime.format('yyyy-MM-DD'),
                    displayStartTime : tmpStartTime.format('HH시 mm분'),
    
                    displayEndDate : tmpEndTime.format('yyyy-MM-DD'),
                    displayEndTime : getNextDayStr(tmpStartTime.format('yyyyMMDD'), tmpEndTime.format('yyyyMMDD')) +  ampmText + tmpEndTime.format('hh:mm'),
                    // displayEndTime : getNextDayStr(tmpStartTime.format('yyyyMMDD'), tmpEndTime.format('yyyyMMDD')) +  tmpEndTime.format('HH시 mm분'),
                    diffTime: `${moment.duration(tmpEndTime.diff(tmpStartTime)).hours()}시간 ${moment.duration(tmpEndTime.diff(tmpStartTime)).minutes()}분`,
                    diffTimeRemain: moment.duration(tmpEndTime.diff(tmpStartTime)).asMinutes()
                }) 
                
                if (timeDate !== undefined) {
                    await this.setStateOnce('startTime', tmpStartTime)
                }
                
                try {
                    let calcData = await this.props.onCalc({
                        startTime: tmpStartTime.format('yyyy-MM-DD HH:mm:00.000'),
                        endTime: tmpEndTime.format('yyyy-MM-DD HH:mm:00.000')
                    })
                    // 최소이용시간 적용
                    await this.setStateMultiple({
                        // limitTime: calcData.ContractOneDayAvaliableHour,
                        // addTime:calcData.ContractOneDayAvaliableHour,
                        calcItem: calcData
                    }) 
                    resolve(true)
                }
                catch(e){
                    await calertPromise('계산할 수 없었습니다. 다시 확인해주세요.')
                    console.log(e)
                    resolve(false)
                }
    
            }
            else {
                console.log('조건에 해당하지 않았음')
                resolve(false)
            }
        })
        
    }
                      
    render() {
        const DatePickCustomComponent = ({ value, onClick }) => (
            <div className="reserve-list">
                <div className="grid cross-center flex-between" style={customLineHeight}>
                    <div className="col"><i className="ico ico-map-calendar"></i></div>
                    <div className="col text-bold">{value}</div>
                    <div className="col right">
                        <button className="btn-option" onClick={onClick}>날짜선택</button>
                    </div>
                </div>
                {/* {this.state.calcItem.SelectReserveDateCount >= 1 ? (
                    <div style={{
                        fontWeight:'bold',
                        color:'#bf041a'
                    }}>이미 예약하셨습니다.</div>
                    
                ) : (
                    <div style={{
                        fontWeight:'bold',
                        color:'#37cc0a'
                    }}>예약 가능합니다.</div>
                )} */}
            </div>
        );

        const filterPassedDate = date => {
            // date : 화면상에 디스플레이될 날짜 목록

            // 선택시 본 콜백이 발생하며 달력에 표시된 모든 데이터가 나온다.
            // 여기서 선택했을때 이전의 날짜는 선택 못하도록 해야한다.
            let currentDate = new Date();
            currentDate.setDate(currentDate.getDate() - 1)    // 하루 전

            let selectedDate = new Date(date);              // 선택한 날짜

            
            return currentDate.getTime() <= selectedDate.getTime();
        }


        // disabledHours={this.state.endVisibleHours}
        // disabledMinutes={this.state.endVisibleMinute}
        const testHours = () => {
            return this.state.endVisibleHours
        }

        const testMinutes = () => {
            return this.state.endVisibleMinute
        }

        /*
            1. 날짜를 선택
            2. 날짜를 최초로 선택하면 시작시간이 표시
            3. 시작시간을 최초로 선택하면 종료시간이 표시되며, calc계산 처리를 실시
            4. 계산 처리가 완료되면 관련 데이터가 표시된다.
        */
        const addTimeComponent = () => (
            <div className="grid cross-center flex-between"  style={{fontSize:'1.1em'}}>   
                <div className="col">
                    추가시간 (1)
                </div>
                <div className="col right">
                    {(this.state.addTime === 0) ? null : (<button className="btn-option3" onClick={this.handleChangeDecreaseAddTime} ><i className="ico ico-minus"></i></button>)}
                    
                    <span style={{paddingLeft:'10px', paddingRight:'10px'}}><strong>{this.state.addTime} 시간</strong></span>
                    {(this.state.addTime >= 24) ? null : (<button className="btn-option3" onClick={this.handleChangeIncreaseAddTime}><i className="ico ico-plus"></i></button>)}
                </div>
            </div>
        )
        
        const labelPrice = (type,data) => {
            let retData = ''
            if (type === RESERVE_TYPE_GENERAL_FLAG) {
                retData = (data===0) ? Util.numberWithCommas(RESERVATION_BASE_PRICE * RESERVATION_BASE_TIME + '') : Util.numberWithCommas(((RESERVATION_BASE_PRICE * RESERVATION_BASE_TIME)+(RESERVATION_BASE_PRICE * data)) + '')                 
            }
            else {
                retData = (data===0) ? Util.numberWithCommas(RESERVATION_GOLF_PRICE + '') : Util.numberWithCommas(((RESERVATION_GOLF_PRICE)+(RESERVATION_BASE_PRICE * data)) + '')                 
            }
            return retData
        }

        const addTimeOption = (type) => {
            let tmpArr = []
            const MAX_HOURS = 24
            
            for(let i = this.state.limitTime; i <= MAX_HOURS; i++) {
                tmpArr.push(i)
            }
            
            return tmpArr.map((data, idx)=>{
                return (<option value={data}>{data} 시간</option>)
            })
        }

        const addTimeSelectBoxComponent = () => (
            <>
            
            <div className="grid cross-center flex-between">
                <div className="col grid cross-center">
                    <div>
                        <i className="ico ico-map-time"></i>
                    </div>
                    <div className="col">이용시간</div>
                </div>
                <div className="col right">
                    <select className="select type2" onChange={this.handleChangeAddTime} value={this.state.addTime} disabled={this.props.nextBtnDisableStat}>
                        {addTimeOption(this.props.type)}
                    </select>
                </div>
            </div>
            </>
        )

        const customStyleMinHeight = {
            minHeight:'55px',
            margin: '0 auto'  ,        
            paddingBottom:'0px'
        }

        const customLineHeight = {
            lineHeight:'34px'         
        }

        return (
            <>
                <TimePickerNewCustomCss />
                <span
                    className="section_box_deatil"
                    style={{ margin: "-60px 0 0 0" }}
                >
                    <div className="reserve-list"  style={{paddingBottom:'0px'}}>
                        <div className="search-box" style={{paddingBottom:'0px'}}>
                            <h1 className="h1">날짜, 시간 선택</h1>
                            <span className="list_t2">
                                <DatePicker
                                    onInputClick={() => { this.setIsOpenFlag(true); }}
                                    onClickOutside={() => { 
                                        if (!this.state.isAlertFlag)
                                            this.setIsOpenFlag(false);
                                    }}
                                    open={this.state.isOpenFlag}
                                    dateFormat="yyyy-MM-dd(eee)"
                                    renderDayContents={(day, date) => {
                                        return (
                                            <div style={{ display: "inline-block", width: "1.7rem", lineHeight: "1.7rem", }}
                                                onClick={async () => {
                                                    let flagData =
                                                        getSelectedDateAlertFlag( this.props.nowTime, date, this.props.closeHour );
                                                    switch (flagData) {
                                                        case 1: // 당일 선택시
                                                            this.setIsAlertFlag( true );
                                                            await calertPromise( "좀 더 안정적인 서비스를 위하여 당일 예약을 받지 않습니다. 예약은 이용 하루 전 15시 전까지 부탁드립니다." );
                                                            this.setIsAlertFlag( false );
                                                            break;
                                                        case 2: // 15시 이후 당일,명일 선택시
                                                            this.setIsAlertFlag( true ); 
                                                            await calertPromise( "좀 더 안정적인 서비스를 위하여 15시 이후 날짜 선택 시, 15시 이후에는 다음날 예약을 받지 않습니다. 예약은 이용 하루 전 15시 전까지 부탁드립니다." );
                                                            this.setIsAlertFlag( false );
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                }} >
                                                <span>{getDate(date)}</span>
                                            </div>
                                        );
                                    }}
                                    renderCustomHeader={({
                                        date,
                                        changeYear,
                                        changeMonth,
                                        decreaseMonth,
                                        increaseMonth,
                                        prevMonthButtonDisabled,
                                        nextMonthButtonDisabled,
                                    }) => (
                                        <div style={{ margin: 10, display: "flex", }} >
                                            <div style={{ flex: "1" }}>
                                                <button
                                                    style={{ fontSize: "1.2em", border: "none", }}
                                                    onClick={decreaseMonth}
                                                    disabled={ prevMonthButtonDisabled } >
                                                    {"◀"}
                                                </button>
                                            </div>
                                            <div style={{ flex: "10" }}>
                                                <span style={{ fontSize: "1.2em", fontWeight: "bold", }} >
                                                    {moment(date).format( "YYYY" )} 년{" "} {moment(date).format("MM")} 월
                                                </span>
                                            </div>
                                            <div style={{ flex: "1" }}>
                                                <button style={{ fontSize: "1.2em", border: "none", }} 
                                                    onClick={increaseMonth}
                                                    disabled={  nextMonthButtonDisabled } >
                                                    {"▶"}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                    selected={this.state.date}
                                    minDate={getReserveAvaliableDate(
                                        this.props.nowTime,
                                        this.props.closeHour
                                    )}
                                    onChange={async (date) => {
                                        await this.setStateOnce("date", date);
                                        this.props.onChangeDate(date);

                                        this.setIsOpenFlag(false);
                                        this.props.onDisableBtn()
                                        await this.calcDataProcessNew();
                                        this.props.onEnableBtn() 
                                        console.log('onchange')
                                        
                                        // this.calcDataProcess();
                                    }}
                                    customInput={<DatePickCustomComponent />}
                                    popperPlacement="auto"
                                    locale="ko"
                                    filterDate={filterPassedDate}
                                />

                                <div className="reserve-list" style={customStyleMinHeight}>
                                    <div className="grid cross-center flex-between" style={customLineHeight}>
                                        <div className="col grid cross-center">
                                            <div>
                                                <i className="ico ico-map-time"></i>
                                            </div>
                                            <div className="col">시작시간</div>
                                        </div>
                                        <div className="col right">
                                            <TextTimePicker 
                                                reserveType={this.props.type}
                                                onChangeTime={async (e)=>{
                                                    console.log(e)
                                                    
                                                    this.props.onDisableBtn()
                                                    clearTimeout(this.typingTimer)
                                                    this.typingTimer = setTimeout(async()=>{
                                                        await this.calcDataProcessNew(e);
                                                        this.props.onEnableBtn()
                                                    }, this.doneTypingInterval)
                                                    await this.setStateOnce('step1Flag', true)     
                                                }} 
                                            />

                                        </div>
                                    </div>
                                </div>

                                {this.state.step1Flag && (
                                    <>
                                        <div className="reserve-list">
                                            {addTimeSelectBoxComponent()}
                                            {/* <div className="bul notice">
                                                <p>연장 사용 시간은 최대 <strong>2시간</strong> 입니다.</p>
                                            </div> */}
                                        </div>
                                        
                                    </>
                                    
                                )}

                                {this.state.step1Flag && (
                                    <div className="reserve-list"  style={customStyleMinHeight}>
                                        <div className="grid cross-center flex-between" style={customLineHeight}>
                                            <div className="col grid cross-center">
                                                <div>
                                                    <i className="ico ico-map-time"></i>
                                                </div>
                                                <div className="col">종료시간</div>
                                            </div>
                                            <div className="col right">
                                                <span><strong>{this.state.displayEndTime}</strong></span>   
                                            </div>
                                        </div>
                                    </div>
                                )} 

                                {/* <div className="reserve-list"  style={customStyleMinHeight}>
                                    <div className="grid cross-center flex-between" style={{lineHeight:'17px'}}>
                                        <div className="col grid cross-center">
                                            <div>
                                                <i className="ico ico-map-time"></i>
                                            </div>
                                            <div className="col">잔여시간</div>
                                        </div>
                                        <div className="col right">
                                            <div><strong>{   getTotalMinuteToHourMinuteStr(this.state.calcItem.RemainTimes) }</strong></div>
                                            <div>
                                                <strong>
                                                    → {  
                                                    getTotalMinuteToHourMinuteStr(
                                                        (this.state.calcItem.RemainTimes - this.state.diffTimeRemain) <= 0 ? 
                                                        0 : (this.state.calcItem.RemainTimes - this.state.diffTimeRemain)
                                                    )}
                                                </strong>
                                            </div>   
                                        </div>
                                    </div>
                                </div> */}


                                {this.state.step1Flag && (
                                    <div className="reserve-list">
                                        <ul className="list inner-list">
                                            <li className="item">
                                                
                                                {this.state.calcItem.ExcessAmount <= 0 ? null : (
                                                    <div className="item-row grid crocss-center">
                                                        <div className="col left">
                                                            <span>초과금액</span>
                                                            <NavLink to="#" className="tooltip"  onClick={(e)=>e.preventDefault()}>
                                                                <i className="ico ico-quest"></i>
                                                                <span className="tooltip-content">
                                                                    <p>잔여시간 부족시의 금액</p>
                                                                    <p>(VAT 포함)</p>
                                                                </span>
                                                            </NavLink>
                                                        </div>
                                                        <div className="col right">
                                                            <i className="ico price-add">
                                                                할증
                                                            </i>
                                                            <span>
                                                                {this.props.nextBtnDisableStat ? '계산중...' : (<>{Util.numberWithCommas( String( this.state.calcItem.ExcessAmount))}원</>)}
                                                            </span>
                                                        </div>
                                                    </div>

                                                )}

                                                {this.state.calcItem.StartAreaFee <= 0 ? null : (
                                                    <div className="item-row grid crocss-center">
                                                        <div className="col left">
                                                            <span>출발지할증</span>
                                                                <NavLink to="#" className="tooltip"  onClick={(e)=>e.preventDefault()}>
                                                                    <i className="ico ico-quest"></i>
                                                                    <span className="tooltip-content">
                                                                        <p>경기/인천 30,000원</p>
                                                                        {/* <button className="tooltip-close">
                                                                            <span className="blind">툴팁 닫기</span>
                                                                        </button> */}
                                                                    </span>
                                                                </NavLink>
                                                        </div>
                                                        <div className="col right">
                                                            <i className="ico price-add">
                                                                할증
                                                            </i>
                                                            <span>
                                                                {this.props.nextBtnDisableStat ? '계산중...' : (<>{Util.numberWithCommas( String( this.state.calcItem.StartAreaFee))}원</>)}
                                                                
                                                            </span>
                                                        </div>
                                                    </div>

                                                )}
                                                
                                                {this.state.calcItem.AreaFee <= 0 ? null : (
                                                    <div className="item-row grid crocss-center">
                                                        <div className="col left">
                                                            <span>지역할증</span>
                                                            <NavLink to="#" className="tooltip" onClick={(e)=>e.preventDefault()}>
                                                                <i className="ico ico-quest"></i>
                                                                <span className="tooltip-content">
                                                                    <p>경기/인천 30,000원</p>
                                                                    <p>충청 50,000원</p>
                                                                    <p>강원 70,000원</p>
                                                                    <p>전북/경북 60,000원</p>
                                                                    <p>전남/경남 90,000원</p>
                                                                    <p>기타지역 120,000원</p>
                                                                </span>
                                                            </NavLink>
                                                        </div>
                                                        <div className="col right">
                                                            <i className="ico price-add">
                                                                할증
                                                            </i>
                                                            <span>
                                                                {this.props.nextBtnDisableStat ? '계산중...' : (<>{Util.numberWithCommas(String(this.state.calcItem.AreaFee))}원</>)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                                
                                                {this.state.calcItem.NightFee <= 0 ? null : (
                                                    <div className="item-row grid crocss-center">
                                                        <div className="col left">
                                                            <span>심야할증</span>
                                                            <NavLink to="#" className="tooltip" onClick={(e)=>e.preventDefault()}>
                                                                <i className="ico ico-quest"></i>
                                                                <span className="tooltip-content">
                                                                    <p>23시부터 06시까지는</p>
                                                                    <p>심야할증 구간입니다.</p>
                                                                    
                                                                    {/* <button className="tooltip-close">
                                                                        <span className="blind">툴팁 닫기</span>
                                                                    </button> */}
                                                                </span>
                                                            </NavLink>
                                                        </div>
                                                        <div className="col right">
                                                            <i className="ico price-add">
                                                                할증
                                                            </i>
                                                            <span>
                                                                {this.props.nextBtnDisableStat ? '계산중...' : (<>{Util.numberWithCommas(String(this.state.calcItem.NightFee))}원</>)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                                

                                                {this.state.calcItem.ReseveCharge !== 0 ? (
                                                    <>
                                                        <br />
                                                        <div className="item-row grid crocss-center">
                                                            <div className="col left">
                                                                <strong style={{ fontSize:"1.5em",}}>
                                                                    이용예상금액
                                                                </strong>
                                                            </div>
                                                            <div className="col right">
                                                                <strong style={{ fontSize: "1.5em", }} >
                                                                    {this.props.nextBtnDisableStat ? '계산중...' : (<>{Util.numberWithCommas(this.state.calcItem.ReseveCharge + '')}원</>)}   
                                                                </strong>
                                                            </div>
                                                        </div>
                                                    </>
                                                    
                                                ) : null}

                                                
                                                
                                            </li>
                                        </ul>
                                    </div>
                                )}






                            </span>
                        </div>
                    </div>
                </span>
            </>
        );
    }
};

export default TimeSelectorNewMonthlyOnce;
