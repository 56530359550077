import Moment from 'moment-timezone'

export const getKoreaDate = () => {
    // 1. 현재 시간(Locale)
    const curr = new Date();

    // 2. UTC 시간 계산
    const utc = 
        curr.getTime() + 
        (curr.getTimezoneOffset() * 60 * 1000);

    // 3. UTC to KST (UTC + 9시간)
    const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
    return new Date(utc + (KR_TIME_DIFF))
}

export const getKoreaDate2 = (serverTime) => {
    // 1. 현재 시간(Locale)
    const curr = new Date(serverTime);

    // 2. UTC 시간 계산
    const utc = 
        curr.getTime() + 
        (curr.getTimezoneOffset() * 60 * 1000);

    // 3. UTC to KST (UTC + 9시간)
    const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
    return new Date(utc + (KR_TIME_DIFF))
}

export const convertTZ = (date, tzString) => {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en", {timeZone: tzString}));   
}
    


// parameter type : date, int
// return type : moment().toDate() or new Date()
export const getReserveAvaliableDate = (serverDate, closeHour) => {
    /*
        기준시간 : 6/24. 
            19시 이전 : 6/24 선택 불가, 6/25 선택 가능 => 당일 예약 불가. 다음날 예약 가능.
            19시 이후 : 6/24 선택 불가, 6/25 선택 불가, 6/26 선택 가능 => 당일 예약 불가. 다음날 예약 불가.

        시간쪽도 disabled 제한 해제가 필요하고, 0~23시까지 선택 가능하도록 해야할듯.

        화면을 열었을때 시간체크 1회 실시.
        다음버튼 클릭시 시간체크 1회 실시.
            => 19시가 넘었을때 시간 처리를 실시해야한다.
    */
    let tmpMoment = Moment(serverDate).tz('Asia/Seoul');
    let retDate = null;
    
    // 현재 시간이 closeHour보다 크거나 같을 때
    if (tmpMoment.hours() >= closeHour) {
        // 19시 이후 : 6/24 선택 불가, 6/25 선택 불가, 6/26 선택 가능 => 당일 예약 불가. 다음날 예약 불가. 다다음날 예약 가능.
        retDate = tmpMoment.add(2, 'days').startOf('day');    
    }
    else {
        // 19시 이전 : 6/24 선택 불가, 6/25 선택 가능 => 당일 예약 불가. 다음날 예약 가능.
        retDate = tmpMoment.add(1, 'days').startOf('day');
    }     
    // console.log('date: ', retDate.toDate())
    return retDate.toDate();
}

// 금일 선택 불가, 19시 이후 금일/명일 선택 불가, 그외의 날짜를 파악하여
// 해당되는 flag를 리턴하는 메소드다.
export const getSelectedDateAlertFlag = (serverDate, selectedDate, closeHour) => {
    /*
        기준시간 : 6/24. 
            19시 이전 : 6/24 선택 불가, 6/25 선택 가능 => 당일 예약 불가. 다음날 예약 가능.                     flag : 1
            19시 이후 : 6/24 선택 불가, 6/25 선택 불가, 6/26 선택 가능 => 당일 예약 불가. 다음날 예약 불가.       flag : 2
            상기 이외 : 해당없음.                                                                      flag : 3
    */
    let tmpMoment = Moment(serverDate).tz('Asia/Seoul');
    let retData = 0;

    let nowTimeStartOf = Moment(serverDate).tz('Asia/Seoul').startOf('day')         // 현재 서버상의 시간과
    let selDateStartOf = Moment(selectedDate).tz('Asia/Seoul').startOf('day')       // 내가 선택한 날짜를 
    let diffDays = Moment.duration( selDateStartOf.diff(nowTimeStartOf) ).asDays(); // 일자를 비교한다.
    
    // 19시 이후 케이스이다.
    if (tmpMoment.hours() >= closeHour) {
        if (diffDays === 0) {       // 금일 선택시
            retData = 1;
        }
        else if(diffDays === 1) {   // 명일 선택시
            retData = 2;
        } 
        else {                      // 그 이외의 날짜 선택시
            retData = 3;
        }        
    }
    
    // 19시 이전 케이스
    else {
        if (diffDays === 0) {       // 금일 선택시
            retData = 1;
        }
        else {                      // 금일 이외 선택시
            retData = 3;
        }
    }     
    return retData;
}




export const isReserveAvaliableDateValidateChk = (serverDate, closeHour) => {
    /*
        19시 이전 : true
        19시 이후 : false
    */
    let tmpMoment = Moment(serverDate).tz('Asia/Seoul');
    let retData = true;

    // 현재 시간이 closeHour보다 크거나 같을 때
    if (tmpMoment.hours() >= closeHour) {
        retData = false;
    }
    
    return retData;
}


export const getCurrentSession = () => {
    return Moment(new Date()).format("A");
}

// 익일이면 "익일"이라고 표시. 아니면 표시 안함.
export const getNextDayStr = (dateA, dateB) => {
    return (dateA === dateB) ? '' : '익일 ';
}

export const getCurrentHour = () => {
    return Moment(new Date()).format("HH");
}

export const getCurrentMinute = () => {
    return Moment(new Date()).format("mm");
}

export const getStrAmPm = (dateTime) => {
    return (Moment(dateTime).format("A") === 'AM') ? '오전' : '오후';
}

export const getStryyyymmdd = (dateTime) => {
    return Moment(dateTime).format("yyyy-MM-DD");
}

export const getDayKorean = (dateTime) => {
    let retData = '일';
    switch(Moment(dateTime).format('d')) {
        case '1':   retData = '월'; break;
        case '2':   retData = '화'; break;
        case '3':   retData = '수'; break;
        case '4':   retData = '목'; break;
        case '5':   retData = '금'; break;
        case '6':   retData = '토'; break;
        default:    retData = '일'; break;
    }
    return retData;
}


export const plusSecondsMoment = (day, month, year, hour, minute, session, secondPlus) => {
    if (`${day}`.length === 1) day = `0${day}`;
    if (`${month}`.length === 1) month = `0${month}`;
    if (`${year}`.length === 1) year = `0${year}`;
    if (`${hour}`.length === 1) hour = `0${hour}`;
    if (`${minute}`.length === 1) minute = `0${minute}`;
    console.log(`${year}-${month}-${day} ${hour}:${minute} ${session}`)
    const mm = Moment(
        `${year}-${month}-${day} ${hour}:${minute} ${session}`,
        "YYYY-MM-DD hh:mm A",
        true
    );
    if (secondPlus) {
        mm.add(secondPlus, "seconds");
    }
    return mm.format("YYYY-MM-DD A HH:mm:00").toString().replace('AM', '오전').replace('PM', '오후');
};

export const plusSecondsMomentWithFormat = (day, month, year, hour, minute, session, secondPlus, format) => {
    if (`${day}`.length === 1) day = `0${day}`;
    if (`${month}`.length === 1) month = `0${month}`;
    if (`${year}`.length === 1) year = `0${year}`;
    if (`${hour}`.length === 1) hour = `0${hour}`;
    if (`${minute}`.length === 1) minute = `0${minute}`;
    const mm = Moment(
        `${year}-${month}-${day} ${hour}:${minute} ${session}`,
        "YYYY-MM-DD hh:mm A",
        true
    );
    if (secondPlus) {
        mm.add(secondPlus, "seconds");
    }
    return mm.format(format).toString().replace('AM', '오전').replace('PM', '오후');
};

export const plusSecondsMomentWithFormatCustom = (day, month, year, hour, minute) => {
    if (`${day}`.length === 1) day = `0${day}`;
    if (`${month}`.length === 1) month = `0${month}`;
    if (`${year}`.length === 1) year = `0${year}`;
    if (`${hour}`.length === 1) hour = `0${hour}`;
    if (`${minute}`.length === 1) minute = `0${minute}`;

    return `${year}-${month}-${day} ${hour}:${minute}:00.000`;

};