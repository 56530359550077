// 라이브러리 Load
import React, { Component } from 'react';
import { withCookies } from 'react-cookie'
import { NavLink } from 'react-router-dom'
import moment from 'moment'

// 리덕스 store
import store from '../../../reduxStore'

// Custom Component
import Header from '../../../components/Common/Header'
import Loading from '../../../components/Common/Loading'


import {
    plusSecondsMomentWithFormatCustom,
    getNextDayStr
} from '../../../lib/TimeApi'

// Mosiler API
import CouponApi from '../../../lib/CouponApi'
import ReserveApi from '../../../lib/ReserveApi'
import RnInterface from '../../../lib/RnInterface'
import TicketApi from '../../../lib/TicketApi'

import Modal from 'react-modal'

// Etc API
import Util, { 
    calert, 
    calertConfirm, 
    calertPromise, 
    calertPromise3,
    // calertPromiseCustomMsg ,
    isChkResultSuccess
} from '../../../lib/Util'

import { 
    getChauffeurDrivenEventAvailableDate,
    setChauffeurDrivenReserveComplate,
    getChauffeurDrivenApplyCountDate,
    getChauffeurDrivenLimitCount
} from '../../../lib/GoogleSheetApi'

// import { 
//     IsPayment, 
//     IsPaymentPartner, 
//     TargetType_Reservation, 
//     Payment_Insert,
//     PAYMENT_CODE_SUCCESS,
//     PAYMENTPARTNER_CODE_SUCCESS,
// } from '../../../constantData'

// image, Css
import PaymentCss from '../../../css/CustomGlobalStyle/Payment'

import wiDdaySsunny from '../../../img/weather/wi-day-sunny.svg'
import wiCloudy from '../../../img/weather/wi-cloudy.svg'
import wiRain from '../../../img/weather/wi-rain.svg'
import wiRainMix from '../../../img/weather/wi-rain.svg'
import wiSnow from '../../../img/weather/wi-snow.svg'


import icon_point from "../../../img/record/icon_point.svg"
import icon_point_waypoint from "../../../img/record/icon_point_waypoint.svg"
// import icon_arrow from "../../../img/record/icon_arrow.svg"
import icon_location from "../../../img/record/icon_location.svg"

// import icon_arrow from "../../../img/record/icon_arrow.svg"
// import { MAX_WAYPOINT } from '../../../constantData'


// var tlmCouponUseAfterReuseYn = true;

// const RESERVE_GENERAL = '1'
const RESERVE_GOLF = '3'
const RESERVE_AIRPORT_HOMEVALET = '26'
const RESERVE_AIRPORT_HOMEVALET_INTEGER = 26

const RESERVE_TYPE_GENERAL_FLAG = 1
const RESERVE_TYPE_GOLF_FLAG = 3

const RESERVATION_BASE_TIME = 2;                    // 골프 이외 최소 예약 시간 (기존 1시간, 리뉴얼 이후 2시간)
const RESERVATION_GOLF_BASE_TIME = 10;              // 골프 최소 예약 시간 (10시간)



const DISCOUNT_TYPE_COUPON = '0'
const DISCOUNT_TYPE_TICKET = '1'
const DISCOUNT_TYPE_NONE = '2'     // 이경우는 할인 없음 (쿠폰에서 사용안함을 선택했을 때.)


class ReserveConfirmStep2View extends Component {
    state = {
        date: new Date(),
        RevStartDt: new Date(),
        RevEndDt: new Date(),
        carLocation: '',
        carNo:'',
        carModel:'',
        CustomerComment: '',
        CustomerNo: 0,
        Passenger: '',
        PassengerPhoneNo: '',
        passengerCheckStatus: true,
        DrivingType: 1,
        hourView: '',
        typeView: '일반 ',
        moneyView: 0,
        CustomerName: '',
        CustomerPhoneNo: '',
        loading: 1,
        couponAvailableList: [],         // 사용가능한 쿠폰 리스트
        selectCouponRedeemCode: '0',      // 드랍다운으로 선택한 쿠폰의 리딤코드
        selCouponId: null,                 // 선택한 쿠폰의 ID
        DiscountAmount: '0',            // 할인 적용된 금액
        DiscountRate: '0',              // 할인율
        DrivingCharge: '0',              // 기본 요금
        ReserveCharge: '10',            // 최종 결제금액
        calcPricing:{},
        finalEndTime:'',
        ticketData:{
            Item:{
                RemainAmount: 0
            }
        },

        discountType: DISCOUNT_TYPE_NONE,

        // 쿠폰 관련
        CouponId: '',               // 쿠폰 ID
        partnerUseYn: false,        // 파트너 카드 존재 유무
        mallReserved: '',           // 파트너 카드의 정보(?)
        paymentCardId: '',          // default card의 id
        sales10CouponId: '',        // 기본 10% 할인 쿠폰 id
        myDefaultCardYn: false,     // 개인 default card 존재 유무
        calcItem:{},                // 계산 결과 전체 값
        tlmCouponCode:'',
        TransactionId:'',
        defaultCardInfo:{},
        partnerHiddenCoupon:null,       // 제휴카드에 따라 calc api 호출시 쿠폰 id가 넘어오는 경우도 있다.   
        freeTicketFlag : true,          // 사용 가능한 무료이용권이 없을때 쿠폰 선택 유무 : true - 무료이용권이 있다. false - 무료이용권이 없다.
        useCouponAvaliable: false,       // 무료이용권 이용 가능 유무 true : 이용 가능, false : 이용 불가
        psCouponId:'',                   // useCoupon API호출후 coupon_num값. 경유지 등록실패시, 예약 취소와 동시에 쿠폰 취소도 필요하다.

        modalOpenFlag: false,
        covidKitStatus: false,

        startTimeString:'',
        endTimeString:'',
        weatherResult:[],
        commentAdd:'',
        addTime: store.getState().TimeSelector_ReduxModule.addTime,
        checkSwitchFlag : false,
        twoHours: false,
        eventFlag:false,
    }

    async componentDidMount() {
        
        try {
            const { IsOldFare } = store.getState().global_data.loginedData
            this.setState({loading:0})
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            
            // let tmpTicketData = await this.getTicketRemain();   // 1. 충전권 데이터 취득  (20220106 이전 화면에서 처리하기에 필요없을듯)
            await this.initState()                              // 2. 예약화면에서 작성했던 예약 내역 (localStorage)
            // await this.getCustomerDefaultCard();                // 3. Default Card 정보 (20220106 이전 화면에서 처리하기에 필요없을듯)
            // await this.getCouponData();                         // 4. Coupon 정보 (사용 가능한 쿠폰만 표시)         (20220106 이전 화면에서 처리하기에 필요없을듯)
            await this.getCalcDrivingReservationConfirm();      // 5. CalcDrivingReservation 이후의 값
            let tmpWeatherResult = await this.getWeatherInfo(); // 6. 내가 지정한 지역에 대해 날씨 정보를 취득하여 표시해주는 모듈.           

            if (this.props.location.state !== undefined &&
                this.props.location.state.result_cardregist === 'success') {
                await this.initStateBackup();       // localstorage에 임시저장한 정보를 빼온다.
                await this.getCustomerDefaultCard();                // 2. Default Card 정보
            }

            // alert('현재 DrivingType: ' + this.state.DrivingType);
            this.setState({
                loading:1,
                // ticketData: tmpTicketData,
                weatherResult: tmpWeatherResult
            })
            
            let messageFlag = sessionStorage.getItem('chargeticketmessageflag') !== null ? true : false
                
            if (IsOldFare && !messageFlag) {
                await calertPromise3('시간제 충전권 사용은 카카오톡채널로 문의해주시기 바랍니다.');
                sessionStorage.setItem('chargeticketmessageflag','helloworld');
            }
           
        }
        catch(e) {
            this.setState({loading:1})
            console.log(e)
        }
        
        /* 
            초기화에 필요한 값
            1. 예약화면에서 작성했던 예약 내역 (localStorage)
            2. Default Card 정보
            3. Coupon 정보
            4. CalcDrivingReservation 이후의 값
        */
    }

    getDayKorean = (data) => {
        let retData = '일';
        switch(data) {
            case '1':
                retData = '월';
                break;
            case '2':
                retData = '화';
                break;
            case '3':
                retData = '수';
                break;
            case '4':
                retData = '목';
                break;
            case '5':
                retData = '금';
                break;
            case '6':
                retData = '토';
                break;
            default:
                retData = '일';
                break;
        }
        return retData;
    }

    getWeatherIcon = (str) => {
        /*
            맑음 : wi-day-sunny.svg
            구름 많음, 흐림, 구름조금, 구름많음  : wi-cloudy.svg
            비, 소나기, 빗방울, 구름많고 비,  흐리고 비  : wi-rain.svg
            비/눈, 빗방울/눈날림, 구름많고 비/눈, 구름많고 눈/비, 흐리고 비/눈, 흐리고 눈/비 : wi-rain-mix.svg
            눈, 눈날림, 구름많고 눈, 흐리고 눈 : wi-snow.svg
            
            맑음, 비, 등의 문자열 정보는 아래 PDF파일 참고.
            https://www.weather.go.kr/w/resources/pdf/dongnaeforecast_rss.pdf
            https://www.weather.go.kr/w/resources/pdf/midtermforecast_rss.pdf

            
         */
        switch(str){
            case '맑음':
                return wiDdaySsunny

            case '구름 많음':
            case '흐림':
            case '구름조금':
            case '구름많음':
                return wiCloudy
            
            case '비':
            case '소나기':
            case '빗방울':
            case '구름많고 비':
            case '흐리고 비':
                return wiRain
                            
            case '비/눈':
            case '빗방울/눈날림':
            case '구름많고 비/눈':
            case '구름많고 눈/비':
            case '흐리고 비/눈':
            case '흐리고 눈/비':
                return wiRainMix

            case '눈':
            case '눈날림':
            case '구름많고 눈':
            case '흐리고 눈':
                return wiSnow
            
        }
    }

    getWeatherInfo = () => {
        return new Promise(async (resolve, reject) => {
            const {
                startLocationAddress,
                endLocationAddress,                
                stopOverList
            } = store.getState().LocationSelector_ReduxModule;
            
            const {
                startTimeString,
            } = this.state;

            let tmpList = [];
            for (let name in stopOverList) {
                tmpList.push(stopOverList[name].waypointAddress);
            }
            let address = [
                startLocationAddress,
                ...tmpList,
                endLocationAddress,
            ];

            let reserveDate = startTimeString;
            let today = moment().startOf('day').toDate();
            let weather = [];

            let retDataArr = [];
            let weatherResultData = []
            
            let tmpDiffDayMain = moment(reserveDate).diff(moment(today),"days");
            if (Number(tmpDiffDayMain) <= 10) {
                weatherResultData = await ReserveApi.getWeatherData()
                // "20211204": [ { "do": "광주_광주광역시", "weaKor": "흐림" }, ...],
                // "20211205": [ { "do": "광주_광주광역시", "weaKor": "맑음" }, ... ], 
            }

            for (let i in address) {
                let flag = false;
                // 현재 날짜와 예약한 날짜를 비교하여 10일 이상일 경우는 없음이라고 나와야 하고, retDataArr도 push하고 continue를 실시한다.
                let tmpDiffDay = moment(reserveDate).diff(moment(today),"days");

                // 10일 이내일경우는 단기, 중기 데이터를 취득하여 날씨를 파악해야한다.
                if (Number(tmpDiffDay) <= 10) {
                    let sidoData = address[i].split(' ')[0];
                    for (let s in weatherResultData) {
                        let tmpReserve = moment(reserveDate).format('yyyyMMDD')
                        let tmpTarget = s
                        
                        if (tmpReserve === tmpTarget) {
                            for (let ss in weatherResultData[s]) {
                                // console.log(`${weatherResultData[s][ss].do} - ${sidoData}`)
                                if (weatherResultData[s][ss].do.indexOf(sidoData) !== -1) {
                                    retDataArr.push(weatherResultData[s][ss].weaKor)
                                    flag = true
                                    break;
                                }
                            }
                            if (flag) break;
                        }
                    }
                }
                // 10일이 지났을 경우는 데이터 처리를 하지 않도록 한다.
                // 그리고,
                else {
                    console.log("10일이 지났음.");
                    break;
                }
            }
            resolve(retDataArr);
        });
    };

    handleChangeModalStatusTrue = () => {
        this.setState((prevState) => ({ modalOpenFlag : true }));
    }

    handleChangeModalStatusFalse = () => {
        this.setState((prevState) => ({ modalOpenFlag : false }));
    }

    // 충전권 정보를 취득한다.
    getTicketRemain = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const { cookies } = this.props;
                const { CustomerNo } = store.getState().global_data.loginedData
                let tmpRemainTicketListResult = await TicketApi.GetCustomerRemainTicket(
                    CustomerNo,
                    cookies.get("ostype"),
                    cookies.get("deviceid"),
                    cookies.get("login_encrypt")
                );
    
                // 이용 가능 금액 취득 성공시
                if (isChkResultSuccess(tmpRemainTicketListResult)) {
                    resolve(tmpRemainTicketListResult)
                }
                else {
                    resolve ({})
                }
            }
            catch(e){
                return reject(e);
            }
            
        })
    }
    

    // 현재 등록되어있는 Default 카드 정보를 취득해서 그 카드가 일반카드인지 파트너카드인지에 따라서
    // 쿠폰 확인 후 쿠폰이 존재한다면 적용하는 처리를 담당한다.
    // 화면 load시 한번만 호출된다.
    getCustomerDefaultCard = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const { cookies } = this.props;
                let { CustomerNo } = store.getState().global_data.loginedData
                let defaultPaymentCardData = await ReserveApi.GetBasicPaymentCards(
                    CustomerNo,
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                )
                // 기본카드가 있을 경우
                if (defaultPaymentCardData.Item && defaultPaymentCardData.Result === 'success') {
                    this.setState({defaultCardInfo: defaultPaymentCardData.Item},()=>{
                        resolve(true)
                    })
                }
                // 기본카드가 없을 경우
                else {
                    resolve(false)
                }
            }
            catch (e) {
                return reject(e);
            }
        })
    }

    // 예약 화면에서 작성했던 예약 내역 정보를 취득.
    initState = (ticket) => {
        let tmpInitReserveBackupData = [
            localStorage.getItem('reserve_temp_date'),                          // 0
            localStorage.getItem('reserve_temp_startTime'),                     // 1
            localStorage.getItem('reserve_temp_endTime'),                       // 2
            localStorage.getItem('reserve_temp_carLocation'),                   // 3
            localStorage.getItem('reserve_temp_comment'),                       // 4
            localStorage.getItem('reserve_temp_type'),                          // 5
            localStorage.getItem('reserve_temp_passengername'),                 // 6
            localStorage.getItem('reserve_temp_passengerphone'),                // 7
            localStorage.getItem('reserve_temp_ReseveCharge'),                  // 8
            localStorage.getItem('reserve_temp_carNo'),                         // 9
            localStorage.getItem('reserve_temp_carModel'),                      // 10
            localStorage.getItem('reserve_temp_end_prdicting_time'),            // 11
            localStorage.getItem('reserve_temp_covidKit'),                      // 12
            localStorage.getItem('reserve_view_all_state'),                      // 13
        ]

        let { loginedData } = store.getState().global_data      // 로그인 데이터 취득
        const { checkSwitch } = store.getState().LocationSelector_ReduxModule;

        let backupAllState = tmpInitReserveBackupData[13]  !== null ? JSON.parse(tmpInitReserveBackupData[13]) : null;
        let isBackupAllStateResult = (backupAllState !== null);

        return new Promise((resolve) => {
            // 값 초기화
            this.setState({
                date: isBackupAllStateResult ? new Date(backupAllState.date) : new Date(),
                RevStartDt: isBackupAllStateResult ? new Date(backupAllState.startTime) : new Date(),
                RevEndDt: isBackupAllStateResult ? new Date(backupAllState.endTime) : new Date(),
                carLocation: isBackupAllStateResult ? backupAllState.carLocation : '',
                CustomerComment: isBackupAllStateResult ? backupAllState.comment : '',
                DrivingType: isBackupAllStateResult ? backupAllState.reserveType : '',
                Passenger: isBackupAllStateResult ? backupAllState.passengerName: '',
                PassengerPhoneNo: isBackupAllStateResult ? backupAllState.passengerPhone: '',                
                CustomerNo: loginedData.CustomerNo,
                CustomerName: loginedData.CustomerName,
                CustomerPhoneNo: loginedData.PhoneNumber,
                
                carNo: isBackupAllStateResult ? backupAllState.carNo : '',
                carModel: isBackupAllStateResult ? backupAllState.carModel : '',
                // finalEndTime: tmpInitReserveBackupData[11] !== null ? tmpInitReserveBackupData[11] : '',

                covidKitStatus: isBackupAllStateResult ? backupAllState.covidKitStatus : false,

                discountType: backupAllState.discountType,

                startTimeString : backupAllState.startTimeString,
                endTimeString : backupAllState.endTimeString,

                selectCouponRedeemCode : backupAllState.selectCouponRedeemCode,
                selCouponId : backupAllState.selCouponId,
                ticketData : backupAllState.ticketData,
                mallReserved : backupAllState.mallReserved,
                CouponId : backupAllState.CouponId,
                defaultCardInfo : backupAllState.defaultCardInfo,
                partnerHiddenCoupon : backupAllState.partnerHiddenCoupon,

                commentAdd:  backupAllState.commentAdd,
                checkSwitchFlag: checkSwitch,
                twoHours: backupAllState.twoHours,

                eventFlag: backupAllState.eventFlag

            }, ()=>{
                resolve(true)
            })
        })
    }

    // 화면 전환시 백업한 예약 데이터들을 받아온다.
    initStateBackup = () => {
        return new Promise((resolve) => {
            let restoreData = localStorage.getItem('ReserveConfirmViewBackup');
            if (restoreData !== null) {
                console.log('initStateBackup not null')
                // 값 초기화
                this.setState({
                    ...JSON.parse(restoreData)
                }, () => {
                    resolve(true)
                })
            } else {
                console.log('initStateBackup null')
                resolve(true)
            }
        })
    }
    

    getCouponData = () => {
        return new Promise(async (resolve, reject) => {
            const { cookies } = this.props;
            const { CustomerNo } = store.getState().global_data.loginedData;
            

            try {

                let resultData = await CouponApi.GetMyCoupons(
                    CustomerNo,
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt'),
                    this.state.DrivingType
                )

                // console.log(moment(data.ExpiredDt).toDate().getTime())
                // console.log(moment().toDate().getTime())

                


                // 쿠폰 취득 성공시
                if (resultData.ItemList && resultData.Result === 'success') {

                    let tmpAvaliableList = [];
                    /*
                    쿠폰관련 기능반영요청입니다.
                    GetMyCoupons 로 쿠폰리스트 가져올때 DrivingType을 추가해놓았습니다. 
                    DrivingType 값과 해당서비스 DrivingType 값이 같을때만 예약화면 보유쿠폰리스트에 노출되게 해주세요. 
                    리턴받는 DrivingType 값이 null 이거나 '0' 이면 모든서비스(운행)에 노출되는 로직입니다. 

                        * GetMyCoupons 취득값중 DrivingType이 null or 0일때
                        쿠폰 사용 가능한 예약
                            - 일반 예약
                            - 골프 예약
                            - 공항홈발렛
                            - 마스터카드 예약
                            - 하나카드 예약
                        
                        * GetMyCoupons 취득값중 DrivingType이 1 or 3 or 25일때
                        쿠폰 사용 가능한 예약
                        DrivingType이 1일때 : 일반 예약, 마스터카드 예약, 하나카드 예약
                        DrivingType이 3일때 : 골프 예약
                        DrivingType이 25일때 : 공항홈발렛
                    */

                    tmpAvaliableList = resultData.ItemList.filter((data) => {
                        //GetMyCoupons취득값중 DrivingType값이 null이거나 0일때
                        if (Number(data.DrivingType) === null || Number(data.DrivingType) === 0) {
                            return (data.IsUsedYn === 'N') && (data.IsExpiredYn === 'N')
                        }
                        // 상기 외라면 DrivingType값과 사용자가 접근한 예약 서비스 DrivingType이 일치하는 쿠폰에 한해서 리스트에 추가한다.
                        return (data.IsUsedYn === 'N') && (data.IsExpiredYn === 'N') && (Number(data.DrivingType) === Number(this.state.DrivingType))
                    })
                    
                    this.setState({
                        couponAvailableList: tmpAvaliableList,
                    }, () => {
                        resolve(true)
                    })
                } else {
                    this.setState({
                        couponAvailableList: [],                       
                    }, () => {
                        resolve(false)
                    })
                }
            } catch (e) {
                await calertPromise('쿠폰 취득 도중 오류가 발생했습니다. 고객센터에 문의해주세요.')
                console.log(e);
                return reject(e);
            }
        })
    }

    // 현재 보유중인 카드가 제휴 인증된 카드인지를 판별하는 처리
    isChkPartnerCard = () => {
        const {defaultCardInfo} = this.state;
        let retData = false;
        
        // 카드가 존재하고 MallReserved값이 있다면
        if (Object.keys(defaultCardInfo).length && defaultCardInfo.MallReserved !== '') {
            retData = true;
        }
        return retData;
    }



    getCalcDrivingReservationConfirm = () => {
        console.log('getCalcDrivingReservationConfirm');
        return new Promise(async (resolve, reject) => {
            try {
                // "CustomerNo" : 12534,
                // "PaymentType": "2",
                // "DrivingType": 1,
                // "RevStartDt": "2020-12-16 00:00:00.000",
                // "RevEndDt" : "2020-12-16 02:30:00.000"
                const {
                    startLocationAddress,
                    startLocationLat,
                    startLocationLng,
                    endLocationAddress,
                    endLocationLat,
                    endLocationLng,
                    stopOverList
                } = store.getState().LocationSelector_ReduxModule;

                const { cookies } = this.props;
                const {
                    discountType,
                    calcPricing,
                    startTimeString,
                    endTimeString,
                } = this.state;

                let tmpDate = this.state.date
                let tmpStartDate = new Date(this.state.RevStartDt)
                let tmpEndDate = new Date(this.state.RevEndDt)

                let tmpStartDateFormat = '';
                let tmpEndDateFormat = '';

                let testStart = Number(moment(tmpStartDate).format('YYYYMMDD'))                 // start date
                let testEnd = Number(moment(tmpEndDate).format('YYYYMMDD'));                    //end date

                // start date를 먼저 세팅해주고
                tmpStartDateFormat = plusSecondsMomentWithFormatCustom(
                    tmpDate.getDate(),
                    (tmpDate.getMonth() + 1),
                    tmpDate.getFullYear(),
                    tmpStartDate.getHours(),
                    tmpStartDate.getMinutes(),
                )

                // 아래 코드를 넣는 이유 : 23:30 ~ 0:30 시간대일경우에는 0:30는 다음날로 처리해야하는데 당일로 처리하고 있어서
                // 계산 결과가 23시간 이렇게 나온다. 이 문제를 방지하고자 처리하는 것이다.
                // end가 start의 날짜를 넘어간다면 
                if (testStart < testEnd) {
                    let tmpAfterDay = moment(this.state.date).add(1,'days').toDate();

                    tmpEndDateFormat = plusSecondsMomentWithFormatCustom(
                        tmpAfterDay.getDate(),
                        (tmpAfterDay.getMonth() + 1),
                        tmpAfterDay.getFullYear(),
                        tmpEndDate.getHours(),
                        tmpEndDate.getMinutes(),
                    )
                }

                else {
                    tmpEndDateFormat = plusSecondsMomentWithFormatCustom(
                        tmpDate.getDate(),
                        (tmpDate.getMonth() + 1),
                        tmpDate.getFullYear(),
                        tmpEndDate.getHours(),
                        tmpEndDate.getMinutes(),
                    )
                }

                


                let tmpObj = {
                    CustomerNo: this.state.CustomerNo,
                    PaymentType: '2',
                    DrivingType: this.state.DrivingType,
                    RevStartDt: startTimeString,
                    // RevEndDt: tmpEndDateFormat,
                    RevEndDt: endTimeString,

                    // 결제전 쿠폰을 사용할경우에는 내가 선택한 selCouponID값이 들어가도록 설정한다.
                    // 쿠폰을 사용하지 않을 경우에는 null값으로 넘긴다.
                    CouponId: (discountType === DISCOUNT_TYPE_COUPON) ?  this.state.selCouponId : null,       // 내가 선택한 쿠폰값이 적용(integer)
                    PrePaidTicketYN: (discountType === DISCOUNT_TYPE_TICKET) ?  'Y' : 'N',
                    // PrePaidTicketAmount: (discountType === DISCOUNT_TYPE_TICKET) ?  (calcPricing.DrivingCharge + calcPricing.StartAreaFee + calcPricing.AreaFee) : 0,
                    
                    StartAddress: startLocationAddress,
                    StartLat: startLocationLat,
                    StartLng: startLocationLng,

                    EndAddress: endLocationAddress,
                    EndLat: endLocationLat,
                    EndLng: endLocationLng,

                    ...ReserveApi.getWaypointGenerate2(stopOverList)
                }

                // alert('이 alert은 예약확인 화면이 열렸을때 getCalcDrivingReservation API를 호출 하도록 되어있음.')
                // alert(`현재 등록된 카드 있어? ${Object.keys(this.state.defaultCardInfo).length ? '있음' : '없음'}`)
                // alert(`제휴인증된 카드야? ${this.isChkPartnerCard()}`)
                // alert('getCalcDrivingReservationConfirm 계산 전 폼 데이터')
                // alert(JSON.stringify(tmpObj))
                

                this.setState({ loading: 0 })	// 로딩중
                let result = await ReserveApi.CalcDrivingReservation(
                    tmpObj,
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                )

                
                if (result.Result === 'success') {
                    // alert('CalcDrivingReservation 응답결과 success')
                    // alert(JSON.stringify(result.Item))
                    // alert(`CalcDrivingReservation 응답결과 중 쿠폰 ID : ${result.Item.CouponId}`)
                    // localStorage.setItem('reserve_temp_ReseveCharge', result.Item.ReseveCharge)  

                    this.setState({
                        DiscountAmount: result.Item.DiscountAmount, 
                        DiscountRate: result.Item.DiscountRate,
                        DrivingCharge: result.Item.DrivingCharge,       // 운행요금
                        ReserveCharge: result.Item.ReseveCharge,
                        calcPricing: result.Item,
                        partnerHiddenCoupon: (result.Item.CouponId === 1599) ? result.Item.CouponId : null,              // /v2/calcDrivingReservation 호출 시 =>  couponId : 1599 이면 히든값으로 보관. 1599가 아니면 null값으로.
                        
                        startAreaFee: result.Item.StartAreaFee,         // 출발지 할증
                        areaFee: result.Item.AreaFee,                   // 지역할증
                        
                    })
                    
                    resolve(true)
                } else {
                    await calertPromise('금액을 계산 하지 못했습니다.')
                    resolve(false)
                }

            } catch (e) {
                await calertPromise('금액 계산 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
                return reject(e)

            }
        })
    }


    // 공항 홈발렛 전용 메소드. 
    // 무료이용권이 없을 때 나오는 alert에서 '아니오'를 눌렀을때 호출된다.
    getCalcDrivingReservationConfirmCancelFreeTicket = () => {
        return new Promise(async (resolve, reject) => {
            try {
                // "CustomerNo" : 12534,
                // "PaymentType": "2",
                // "DrivingType": 1,
                // "RevStartDt": "2020-12-16 00:00:00.000",
                // "RevEndDt" : "2020-12-16 02:30:00.000"
                const {
                    startLocationAddress,
                    startLocationLat,
                    startLocationLng,
                    endLocationAddress,
                    endLocationLat,
                    endLocationLng
                } = store.getState().LocationSelector_ReduxModule;

                const {
                    startTimeString,
                    endTimeString
                } = this.state;
                
                const { cookies } = this.props;
                let tmpDate = this.state.date
                let tmpStartDate = new Date(this.state.RevStartDt)
                let tmpEndDate = new Date(this.state.RevEndDt)

                let tmpStartDateFormat = '';
                let tmpEndDateFormat = '';

                let testStart = Number(moment(tmpStartDate).format('YYYYMMDD'))                 // start date
                let testEnd = Number(moment(tmpEndDate).format('YYYYMMDD')); //end date

                // start date를 먼저 세팅해주고
                tmpStartDateFormat = plusSecondsMomentWithFormatCustom(
                    tmpDate.getDate(),
                    (tmpDate.getMonth() + 1),
                    tmpDate.getFullYear(),
                    tmpStartDate.getHours(),
                    tmpStartDate.getMinutes(),
                )

                // 아래 코드를 넣는 이유 : 23:30 ~ 0:30 시간대일경우에는 0:30는 다음날로 처리해야하는데 당일로 처리하고 있어서
                // 계산 결과가 23시간 이렇게 나온다. 이 문제를 방지하고자 처리하는 것이다.
                // end가 start의 날짜를 넘어간다면 
                if (testStart < testEnd) {
                    let tmpAfterDay = moment(this.state.date).add(1,'days').toDate();

                    tmpEndDateFormat = plusSecondsMomentWithFormatCustom(
                        tmpAfterDay.getDate(),
                        (tmpAfterDay.getMonth() + 1),
                        tmpAfterDay.getFullYear(),
                        tmpEndDate.getHours(),
                        tmpEndDate.getMinutes(),
                    )
                }

                else {
                    tmpEndDateFormat = plusSecondsMomentWithFormatCustom(
                        tmpDate.getDate(),
                        (tmpDate.getMonth() + 1),
                        tmpDate.getFullYear(),
                        tmpEndDate.getHours(),
                        tmpEndDate.getMinutes(),
                    )
                }

/* 제휴카드 인증 받지 않은 상태여도
    요청값에 아래처럼 하면 될까요?
    CouponId: 1599,       // 내가 선택한 쿠폰값이 적용(integer)
    DiscountAmount: null,
    DiscountRate: null,

*/
                let tmpObj = {
                    CustomerNo: this.state.CustomerNo,
                    PaymentType: '2',
                    DrivingType: this.state.DrivingType,
                    RevStartDt: startTimeString,
                    RevEndDt: endTimeString,
                    CouponId: 1599,       // 내가 선택한 쿠폰값이 적용(integer)
                    DiscountAmount: null,
                    DiscountRate: null,

                    StartAddress: startLocationAddress,
                    StartLat: startLocationLat,
                    StartLng: startLocationLng,

                    EndAddress: endLocationAddress,
                    EndLat: endLocationLat,
                    EndLng: endLocationLng,
                }

                this.setState({ loading: 0 })	// 로딩중
                let result = await ReserveApi.CalcDrivingReservation(
                    tmpObj,
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                )

                
                if (result.Result === 'success') {
                    this.setState({
                        DiscountAmount: result.Item.DiscountAmount, 
                        DiscountRate: result.Item.DiscountRate,
                        DrivingCharge: result.Item.DrivingCharge,
                        ReserveCharge: result.Item.ReseveCharge,
                        calcPricing: result.Item,
                        partnerHiddenCoupon: (result.Item.CouponId === 1599) ? result.Item.CouponId : null,              // /v2/calcDrivingReservation 호출 시 =>  couponId : 1599 이면 히든값으로 보관. 1599가 아니면 null값으로.
                    })
                    
                    resolve(true)
                } else {
                    await calertPromise('금액을 계산 하지 못했습니다.')
                    resolve(false)
                }

            } catch (e) {
                await calertPromise('금액 계산 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
                return reject(e)

            }
        })

    }


    // 쿠폰 변경시 호출
    handleChangeSelectCoupon = async (e) => {

        //1. 공항홈발렛 예약결제에만 적용됨 ===> 일반, 골프 등 타서비스 예약결제시 적용 안함
        // 여기선 해당되는가?
        // => 해당된다. DrivingType에 따라 처리가 달라야한다.
        // 제휴카드의 경우는 partnerHiddenCoupon값이 항상 1599로 오고있다고 한다.
        // 공항 홈발렛의 경우에만 partnerHiddenCoupon값 체크를 하고,
        // 그 외에는 partnerHiddenCoupon를 언급조차하면 안됨.
        const { 
            couponAvailableList, 
            // selectCouponRedeemCode, 
            // selCouponId, 
            partnerHiddenCoupon, 
            DrivingType, 
            freeTicketFlag 
        } = this.state;

        if (DrivingType === RESERVE_AIRPORT_HOMEVALET) {

            // 예약 확인 버튼 클릭시 useCoupon.vm 호출 > 이용권이 없다고 나올경우 팝업이 뜬다. (사용가능한 무료이용권없음. 계속 결제 진행?)
            // 이때 사용자가 취소버튼을 클릭할때 freeTicketFlag값을 false로 변경하여 쿠폰을 선택할 수 있어야함.

            // 현재 제휴 쿠폰이 있을 경우에는 다른 쿠폰을 선택할 수 없도록 막아야한다.
            if (partnerHiddenCoupon !== null && partnerHiddenCoupon === 1599 && freeTicketFlag === true) {
                this.setState({
                    selectCouponRedeemCode: '0',
                    selCouponId: null                   // integer or null
                })
                await calertPromise('현재 무료이용권이 있습니다.\n중복할인할 수 없습니다.')
            }
            else {
                if (e.target.value === "0") {   // 드랍다운에서 "사용 안함" 선택시.
                    this.setState({
                        selectCouponRedeemCode: e.target.value,
                        selCouponId: null                   // integer or null
                    }, async () => {
                        this.setState({ loading: 0 })	// 로딩완료
                        await this.getCalcDrivingReservationConfirm();
                        this.setState({ loading: 1 })	// 로딩완료
                    })
                } else {
                    let tmpData = couponAvailableList.filter((data) => (data.RedeemCode === e.target.value));       // 내가 선택한 쿠폰의 쿠폰 ID를 취득하기위한 필터처리
                    this.setState({
                        selectCouponRedeemCode: e.target.value,
                        selCouponId: Number(tmpData[0].Id)      // 내가 선택한 쿠폰의 쿠폰 ID값 (integer or null)
                    }, async () => {
                        this.setState({ loading: 0 })	// 로딩완료
                        await this.getCalcDrivingReservationConfirm();
                        this.setState({ loading: 1 })	// 로딩완료
                    })
                }

            }
        }
        // 공항홈발렛 이외의 경우
        else {

            if (e.target.value === "0") {   // 드랍다운에서 "사용 안함" 선택시.
                this.setState({
                    selectCouponRedeemCode: e.target.value,
                    selCouponId: null                   // integer or null
                }, async () => {
                    this.setState({ loading: 0 })	// 로딩완료
                    await this.getCalcDrivingReservationConfirm();
                    this.setState({ loading: 1 })	// 로딩완료
                })
            } else {
                let tmpData = couponAvailableList.filter((data) => (data.RedeemCode === e.target.value));       // 내가 선택한 쿠폰의 쿠폰 ID를 취득하기위한 필터처리
                this.setState({
                    selectCouponRedeemCode: e.target.value,
                    selCouponId: Number(tmpData[0].Id)      // 내가 선택한 쿠폰의 쿠폰 ID값 (integer or null)
                }, async () => {
                    this.setState({ loading: 0 })	// 로딩완료
                    await this.getCalcDrivingReservationConfirm();
                    this.setState({ loading: 1 })	// 로딩완료
                })
            }
        }
    }

    handleGotoBack = () => {this.props.history.goBack();}
    handleGotoHome = () => {this.props.history.replace('/main'); this.props.history.push('/main');}
    stateChangeFreeTicketCancel = () => {
        return new Promise((resolve) => {
            this.setState({
                freeTicketFlag: false,
                selCouponId:null,
            }, ()=>{
                resolve(true)
            })
        })
    }

    // DrivingType 1,3번에 대해서는 일반, 골프예약을 실시
    // DrivingType 26번에 대해서는 공항홈발렛 용 예약을 실시
    handleSetReserve = async () => {
        try {
            
            this.setState({loading:0})

            const { 
                defaultCardInfo, 
                DrivingType, 
                partnerHiddenCoupon, 
                freeTicketFlag, 
                // couponAvailableList 
            } = this.state;
            // 카드가 없을 경우
            if (Object.keys(defaultCardInfo).length === 0) {
                await calertPromise('카드가 등록되어 있지 않습니다. 등록하고 다시 예약해주세요.')
    
                // 화면 이동을 위해 잠시 설정했던 데이터는 백업해둔다.
                localStorage.setItem('ReserveConfirmViewBackup', JSON.stringify(this.state));
    
                // 카드 등록 화면으로 이동.
                this.props.history.replace(`/card_regist?redirect=reserve_confirm_new&customerno=${this.state.CustomerNo}`)
            }
            // 카드가 있을 경우
            else {
                // 공항 홈발렛일경우
                if (DrivingType === RESERVE_AIRPORT_HOMEVALET) {
                    // alert('공항홈발렛 예약 확인버튼 클릭')
                    // 제휴카드인가? MallReserved값과 쿠폰ID가 1599라면
                    if (defaultCardInfo.MallReserved !== '' && partnerHiddenCoupon === 1599) {
                        // alert('쿠폰 1599')
                        // 사용 가능한 무료이용권이 없어서 취소를 눌렀을 경우
                        if (!freeTicketFlag) {
                            this.makeReservation();
                        }
                        else {
                            let result = await this.callPartnerCouponRegisterReq();
                            // 모든 처리가 정상적으로 끝났다면
                            if (result.finalResult) {
                                this.makeReservation();
                                // 무료 이용권 할인이 적용되었습니다. 이용해주셔서 감사합니다.
                            }
    
                            // 무료이용권이 있는지 없는지는 뭐로 판단해? 
                            // 우선 결과가 C0000가 아닐때를 판단했는데 물어봐야..
                            else if (!result.useCouponAvaliable) {
                                
                                if (await calertConfirm('사용가능한 무료이용권이 없습니다.\n계속 결제는 "확인", 보유쿠폰 확인은 "아니오" 를 눌러주세요.')) {
                                    // 확인 눌렀을때 
                                    this.makeReservation();
                                }
                                // 취소 눌렀을때
                                else {
                                    await this.stateChangeFreeTicketCancel()
                                    await this.getCalcDrivingReservationConfirmCancelFreeTicket()
                                    this.setState({  loading:1 })
                                }
                                
                            }
                            // useCoupon, SetPsCouponId 둘다 거짓일때
                            else {
                                if (!result.setPsCouponIdResult) await calertPromise('제휴카드 홈발렛 쿠폰처리 중 오류발생하였습니다.')
                                else if (!result.useCouponResult) await calertPromise('제휴카드 홈발렛 쿠폰처리 중 오류발생하였습니다.\n잠시 후 다시 예약을 하시기 바랍니다.')
                            }
                        }
                    }
                    // 제휴카드가 아니면 일반, 골프예약
                    else {
                        this.makeReservation();
                    }
    
                }
                // 공항 홈발렛 이외일 경우(일반, 골프 등등..)
                else {
                    this.makeReservation();
                }
            }
        }
        catch(e) {
            await calertPromise('예약 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
            console.log(e)
            this.setState({  loading:1 })
        }
        
    }

    // 예약이 완료되고 표시되는 화면
    // 월간 모시러나 다른 것 안내하고 싶을때 활용해도 좋을 듯.
    reserveComplateViewProcess = () => {
        let tmpLS = localStorage.getItem('monthly_mosiler_date')

        // 고객이 예약후에 월간 모시러 홍보 페이지에서 "7일동안 보지않기"를 누르지 않았을 경우
        if (tmpLS === null) {
            // 예약 리스트 화면으로 이동
            this.props.history.replace('/main')
            this.props.history.push('/reserve_complate_after_view')
        }
        // 고객이 예약후에 월간 모시러 홍보 페이지에서 "7일동안 보지않기"를 눌렀을 경우
        else {
            // 15일이 지났을 때
            if (moment().diff(moment(tmpLS), 'days') >= 7) {
                // 예약 리스트 화면으로 이동
                this.props.history.replace('/main')
                this.props.history.push('/reserve_complate_after_view')
            }
            // 15일이 안지났을 때
            else {
                // 예약 리스트 화면으로 이동
                this.props.history.replace('/main')
                this.props.history.push('/use_state')
            }
        }
    }

    makeReservation = async () => {    
        //1. 공항홈발렛 예약결제에만 적용됨 ===> 일반, 골프 등 타서비스 예약결제시 적용 안함
        // 여기선 해당되는가?
        // 해당된다. formData를 만들어줄때 처리가 조금 달라야 할듯싶네.
        const { defaultCardInfo, 
            DrivingType, 
            partnerHiddenCoupon, 
            // freeTicketFlag, 
            selectCouponRedeemCode, useCouponAvaliable, CustomerNo, psCouponId, date, RevStartDt, covidKitStatus } = this.state;
        const { cookies } = this.props;
        
        const {
            stopOverList
        } = store.getState().LocationSelector_ReduxModule;

        let formData = {};

        // 공항 홈발렛일경우
        if (DrivingType === RESERVE_AIRPORT_HOMEVALET) {
            
            // 제휴카드인가? MallReserved값과 쿠폰ID가 1599라면
            if (defaultCardInfo.MallReserved !== '' && partnerHiddenCoupon === 1599) {
                // 이용권 보유
                if (useCouponAvaliable) {
                    formData = { 
                        ...this.state.calcPricing,          // 예약 확인 화면을 열때 한번 계산하므로 정보가 들어가있다.
                        PaymentType: "2",
                        DrivingType,
                    }
                } 
                // 이용권 미보유
                else {
                    // 쿠폰 상태가 "사용 안함"이 아니라면
                    if (selectCouponRedeemCode !== '0') {
                        // 쿠폰을 선택했다면?
                        formData = {
                            ...this.state.calcPricing,      // 쿠폰 선택시 calcPricing에 값이 들어갔을 것이니 그대로 넣어준다.
                            PaymentType: "2",
                            DrivingType,
                        }
                    } 
                    // 쿠폰 상태가 "사용 안함"일 경우
                    else {
                        formData = {
                            ...this.state.calcPricing,
                            PaymentType: "2",
                            DrivingType,
                            CouponId: 1599,
                            DiscountAmount: null,
                            DiscountRate: null
                        }
                    }
                }
            } 
            // 제휴카드가 아니면 일반적인 예약처리
            else {
                formData = { 
                    ...this.state.calcPricing,
                    PaymentType: "2",
                    DrivingType,
                }
            }
        } 
        // 공항 홈발렛 이외일때는 일반적인 예약처리
        else {
            formData = { 
                ...this.state.calcPricing,
                PaymentType: "2",
                DrivingType,
            }
        }


        /*
        아래 2가지 케이스
        1. 이용권 미보
        */
        let tempText = '';
        if (this.state.twoHours) {
         tempText += '해당 운행건은 최대 2시간 연장될 수 있습니다.'
        }

        // 공통 데이터
        formData.CustomerNo = this.state.CustomerNo;
        formData.CustomerName = this.state.CustomerName;
        formData.CustomerPhoneNo = this.state.CustomerPhoneNo;

        formData.Passenger = this.state.Passenger;
        formData.PassengerPhoneNo = this.state.PassengerPhoneNo;
        formData.CustomerComment = this.state.CustomerComment + '\n' + tempText + '\n\n' + this.state.commentAdd;
        
        if (!getChauffeurDrivenEventAvailableDate()) {
            formData.CustomerComment = formData.CustomerComment + (this.state.eventFlag ? '@@driven@@' : '');
        }
        
        formData.CarMemo = this.state.carLocation;

        formData.CarModel = this.state.carModel;
        formData.CarNo = this.state.carNo;

        formData.RequestCovid19Kit = this.state.covidKitStatus;

        // 골프 예약 할인이 적용되었다면 쿠폰을 반영하면 안된다.
        formData.CouponId = (ReserveApi.isChkReserveDiscount(this.state.DrivingType, this.state.calcPricing.ReserveDiscountAmount)) ? null : formData.CouponId;
        

        let nowDateData = new Date();

        try {
            // moment
            let isSucc = false;
            let sMsgg = ''
            let chkDateData = moment(`${moment(date).format('YYYY-MM-DD')}T${moment(RevStartDt).format('HH:mm:00.000')}`).toDate()


            // 쇼퍼드리븐 신청 가능한지 체크 실시.
            // 신청 가능하다면 바로 예약 진행
            // 신청 불가라면 예약 진행을 계속할것인지
            // 이 시점에 서비스 종료시에 요금 부과된다는 내용을 언급해준다.
            if (!getChauffeurDrivenEventAvailableDate()) {
                if (this.state.DrivingType === Number(RESERVE_GOLF)) {                                    
                    if (this.state.eventFlag) {
                        if (await calertConfirm('쇼퍼드리븐 이용 종료 후 200,000원이 추가 결제됩니다.\n예약을 진행하겠습니까?')) {
                            let cdComerCount = await getChauffeurDrivenApplyCountDate(this.state.date)
                            let cdLimit = await getChauffeurDrivenLimitCount(this.state.date)

                            if (cdComerCount >= cdLimit) {
                                if (!await calertConfirm('선택한 예약일의 쇼퍼드리븐 신청이 마감되었습니다.\n예약을 계속 진행하시겠습니까?')){
                                    this.setState({ loading: 1 })
                                    return;
                                }
                            }
                        }
                        else {
                            this.setState({ loading: 1 })
                            return;
                        }
                    }                                    
                }
            }


            if (nowDateData.getTime() < chkDateData.getTime()) {
                this.setState({ loading: 0 })	// 로딩중
                let response = await ReserveApi.SetDrivingReservation( formData, cookies.get('ostype'), cookies.get('deviceid'), cookies.get('login_encrypt') )
                if (response.Result === 'success') {
                    
                    // 경유지가 존재하는가?
                    if (stopOverList.length) {
                        // waypoint는 최대 10개까지 지정 가능
                        // WaypointAddressXX, WaypointLatXX, WaypointLngXX
                        const waypointData = {
                            CustomerNo,
                            DrivingNo: response.Item.DrivingNo,
                            ...ReserveApi.getWaypointGenerate(stopOverList),
                            RegDt: nowDateData,
                            RegUserNo: 1,
                            // ...this.getWaypoint(stopOverList),
                        }

                        // 경유지 추가
                        let waypointResult = await ReserveApi.Add_DRVG_STOP_AREA( waypointData, cookies.get('ostype'), cookies.get('deviceid'), cookies.get('login_encrypt') )

                        // 경유지 추가 성공시
                        if (waypointResult.Result === 'success') {
                            if (defaultCardInfo.MallReserved !== '' && partnerHiddenCoupon === 1599 && useCouponAvaliable) {
                                await calertPromise('무료이용권 할인이 적용되었습니다.\n이용해주셔서 감사합니다.');
                            } 
                            else {
                                await calertPromise('예약이 완료되었습니다.');
                            }
                            
                            // 쇼퍼드리븐 이벤트
                            if (!getChauffeurDrivenEventAvailableDate()) {
                                if (this.state.DrivingType === Number(RESERVE_GOLF)) {                                    
                                    if (this.state.eventFlag) {
                                        let chauffeurResult = await setChauffeurDrivenReserveComplate(this.state.date, CustomerNo, response.Item.DrivingNo)
                                            if (chauffeurResult !== 'success')
                                                await setChauffeurDrivenReserveComplate(this.state.date, CustomerNo, response.Item.DrivingNo)
                                    }                                    
                                }
                            }
                            // onDone && onDone();
                            this.setState({ loading: 1 })	// 로딩완료
                            
                            // await RnInterface.callReactNative(`ScreenViewAnalyticsReservationComplate_${DrivingType}`)
                            localStorage.removeItem('ReserveConfirmViewBackup')

                            // 이용한 경로를 localStorage에 저장한다. 이 내용은 출발도착경유지 주소 지정시 최근경로 목록에 추가된다.
                            this.saveLocationLocalStorage();
                            
                            // 
                            this.reserveComplateViewProcess()
                            
                            
                            
                        } 
                        // 경유지 추가 실패시
                        else {
                            // psCouponId가 ''가 아니면 이미 callPartnerCouponRegisterReq를 호출했다는 의미이며, 제휴카드라는 것을 의미하는 값이다.
                            if (psCouponId !== '') {
                                try {

                                    /*
                                        useCoupon을 호출했다면
                                        cancelCoupon을 호출해야하고, 성공할경우 cancelDrivingReservation도 호출해야한다.
                                    */

                                    // 경유지 추가 실패시 예약한거 취소해야함.
                                    let cancelCoupon = await CouponApi.CancelCouponEBridge(
                                        CustomerNo,
                                        psCouponId,
                                        defaultCardInfo.MallReserved,
                                        cookies.get('ostype'),
                                        cookies.get('deviceid'),
                                        cookies.get('login_encrypt')
                                    )
                                    if (cancelCoupon && cancelCoupon.success) {
                                        isSucc = true
                                    } else {
                                        isSucc = false
                                        if (cancelCoupon.code === "E0204") {
                                            sMsgg = '제휴카드 홈발렛 쿠폰취소 처리중 오류 발생하였습니다.\n고객센터에 문의부탁드립니다.'
                                        } else {
                                            sMsgg = cancelCoupon.msg;
                                        }
                                    }
                                    this.setState({ loading: 1 })	// 로딩완료
                                }
                                catch(e) {
                                    isSucc = false;
                                    sMsgg = '제휴카드 홈발렛 쿠폰취소 처리중 오류 발생하였습니다.\n고객센터에 문의부탁드립니다..'
                                    console.log(e);
                                    this.setState({ loading: 1 })	// 로딩완료
                                }
                                
                                if (isSucc) {
                                    try {
                                        // 경유지 추가 실패시 예약한거 취소해야함.
                                        let CancelResult = await ReserveApi.CancelDrivingReservation(
                                            CustomerNo,
                                            response.Item.DrivingNo,
                                            cookies.get('ostype'),
                                            cookies.get('deviceid'),
                                            cookies.get('login_encrypt')
                                        )
    
                                        if (CancelResult.Result === 'success') {
                                            await calertPromise('경유지를 추가하지 못했습니다. 예약이 취소되었습니다.');
                                            this.setState({ loading: 1 })	// 로딩완료
                                            
                                        } 
                                        else {
                                            await calertPromise('경유지를 추가하지 못했습니다.');
                                            await calertPromise(CancelResult.Message);
                                            
                                            this.setState({ loading: 1 })	// 로딩완료
                                            
                                        }
                                    }
                                    catch(e) {
                                        await calertPromise('예약 취소 도중 오류가 발생했습니다.\n고객센터에 문의부탁드립니다.');
                                        console.log(e)
                                        this.setState({ loading: 1 })	// 로딩완료
                                    }
                                } else {
                                    await calertPromise(sMsgg);
                                }
                                                                
                            } else {
                                try {
                                    // 경유지 추가 실패시 예약한거 취소해야함.
                                    let CancelResult = await ReserveApi.CancelDrivingReservation(
                                        CustomerNo,
                                        response.Item.DrivingNo,
                                        cookies.get('ostype'),
                                        cookies.get('deviceid'),
                                        cookies.get('login_encrypt')
                                    )

                                    if (CancelResult.Result === 'success') {
                                        await calertPromise('경유지를 추가하지 못했습니다. 예약이 취소되었습니다.');
                                        this.setState({ loading: 1 })	// 로딩완료
                                        
                                    } 
                                    else {
                                        await calertPromise('경유지를 추가하지 못했습니다.');
                                        await calertPromise(CancelResult.Message);
                                        
                                        this.setState({ loading: 1 })	// 로딩완료
                                        
                                    }
                                }
                                catch(e) {
                                    await calertPromise('예약 취소 도중 오류가 발생했습니다.\n고객센터에 문의부탁드립니다.');
                                    console.log(e)
                                    this.setState({ loading: 1 })	// 로딩완료
                                }
                            }
                            
                        }
                    }
                    // 존재하지 않으면 예약 완료
                    else {
                        if (defaultCardInfo.MallReserved !== '' && partnerHiddenCoupon === 1599 && useCouponAvaliable) {
                            await calertPromise('무료이용권 할인이 적용되었습니다.\n이용해주셔서 감사합니다.');
                        } 
                        else {
                            await calertPromise('예약이 완료되었습니다.');
                        }
                        
                        
                        // 쇼퍼드리븐 이벤트
                        if (!getChauffeurDrivenEventAvailableDate()) {
                            if (this.state.DrivingType === Number(RESERVE_GOLF)) {                                    
                                if (this.state.eventFlag) {
                                    let chauffeurResult = await setChauffeurDrivenReserveComplate(this.state.date, CustomerNo, response.Item.DrivingNo)
                                        if (chauffeurResult !== 'success')
                                            await setChauffeurDrivenReserveComplate(this.state.date, CustomerNo, response.Item.DrivingNo) 
                                }                                    
                            }
                        }
                        this.setState({ loading: 1 })	// 로딩완료
                        // await RnInterface.callReactNative(`ScreenViewAnalyticsReservationComplate_${DrivingType}`)
                        localStorage.removeItem('ReserveConfirmViewBackup')
                        
                        
                        // 이용한 경로를 localStorage에 저장한다. 이 내용은 출발도착경유지 주소 지정시 최근경로 목록에 추가된다.
                        this.saveLocationLocalStorage();
                        
                        // 
                        this.reserveComplateViewProcess()
                    }
                } else {
                    if (response.Message === 'You need to setup default payment card .' &&
                        response.ResultCode === "NoCard") {
                        await calertPromise('카드가 등록되어 있지 않습니다. 등록하고 다시 예약해주세요.')
                        this.setState({ loading: 1 })	// 로딩완료                   

                    } else {
                        await calertPromise(response.Message)
                        this.setState({ loading: 1 })	// 로딩완료
                    }
                }

            } 
            else {
                await calertPromise('시작시간이 지났습니다. 다시 예약해주세요.')
                this.setState({ loading: 1 })	// 로딩완료
            }
        } catch (e) {
            await calertPromise('예약 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
            console.log(e)
            this.setState({ loading: 1 })	// 로딩완료
        }


    }

    //
    callPartnerCouponRegisterReq = () => {
        return new Promise( async (resolve,reject)=>{
            try {
                const { cookies } = this.props;
                const { defaultCardInfo } = this.state;
                const { CustomerNo  } = store.getState().global_data.loginedData;
                let useCouponAvaliable = false,
                    useCouponResult = false,
                    setPsCouponIdResult = false,
                    finalResult = false;
                // alert(JSON.stringify(defaultCardInfo));

                let response = await CouponApi.UseCouponEBridge( CustomerNo, defaultCardInfo.MallReserved, cookies.get('ostype'), cookies.get('deviceid'), cookies.get('login_encrypt') );
                
                //  useCoupon API 응답 성공시의 처리
                if (response && response.success) {
                    // alert('useCoupon 응답 완료');
                    // alert(JSON.stringify(response));
                    
                    useCouponAvaliable = (response.code === 'C0000');       // 이용권이 존재할때 true 아니면 false
                    useCouponResult = true;                                 // useCoupon 요청 성공 flag
                    
                    const dataTmp = JSON.parse(response.data);              // 리스폰스값을 json 파싱한다.
                    const coupon_num = dataTmp.coupon_num;                  // 여기에서 쿠폰 번호를 받을 수 있는가봄.
                    this.setState({psCouponId: coupon_num})                 // 예약 중 경유지 세팅 실패시 쿠폰, 예약 취소할때 필요함.

                    //C0000
                    const responsecp = await CouponApi.SetPsCouponId( defaultCardInfo.Id, coupon_num, cookies.get('ostype'), cookies.get('deviceid'), cookies.get('login_encrypt') );
    
                    // alert('SetPsCouponId API result')
                    // alert(JSON.stringify(responsecp));

                    // SetPsCouponId 응답 처리
                    if (responsecp && responsecp.Result === "success") {
                        setPsCouponIdResult = true;
                        
    
                    // SetPsCouponId 응답 실패시
                    }
                    else {
                        // 쿠폰 생성 실패
                        setPsCouponIdResult = false;
                        
                    }
                    finalResult = (useCouponAvaliable && useCouponResult && setPsCouponIdResult)
                    this.setState({useCouponAvaliable})
                    
                    resolve({
                        useCouponAvaliable,
                        useCouponResult,
                        setPsCouponIdResult,
                        finalResult
                    })
                }
    
                //  useCoupon API 응답 실패시의 처리
                else {
                    // alert('useCoupon API Failed')
                    // alert(JSON.stringify(response));
                    resolve({
                        useCouponAvaliable,
                        useCouponResult,
                        setPsCouponIdResult,
                        finalResult
                    })
                }
            } catch (error) {
                console.log('callPartnerCouponRegisterReq error : ', error)
                return reject (error);
            }
        })
        
    };

    handleDiscountTypeChange = async (type) => { 
        // 쿠폰을 선택했고, 예약할인을 받았는지 여부를 체크
        if (
            type===DISCOUNT_TYPE_COUPON && 
            ReserveApi.isChkReserveDiscount(this.state.DrivingType, this.state.calcPricing.ReserveDiscountAmount)) {
            await calertPromise('예약할인 적용 시 쿠폰은\n사용할 수 없습니다.')
        }
        else {
                this.setState({ discountType: type },async ()=>{
                    this.setState({ loading: 0 })	// 로딩완료
                    await this.getCalcDrivingReservationConfirm();
                    this.setState({ loading: 1 })	// 로딩완료
                }) 
        }
    }

    handleDiscountTypeChangeCoupon = () => { 
        this.setState({ discountType: DISCOUNT_TYPE_COUPON },async ()=>{
            this.setState({ loading: 0 })	// 로딩완료
            await this.getCalcDrivingReservationConfirm();
            this.setState({ loading: 1 })	// 로딩완료
        }) 
    }
    
    handleDiscountTypeChangeTicket = () => { 
        this.setState({ discountType: DISCOUNT_TYPE_TICKET },async ()=>{
            this.setState({ loading: 0 })	// 로딩완료
            await this.getCalcDrivingReservationConfirm();
            this.setState({ loading: 1 })	// 로딩완료
        }) 
    }

    getReserveTypeToEndTimeHour = (type) => {
        if (Number(type) === RESERVE_TYPE_GOLF_FLAG) return RESERVATION_GOLF_BASE_TIME;
        return RESERVATION_BASE_TIME;
    }
    
    handleChargeTicket = () => {
        // 화면 이동을 위해 잠시 설정했던 데이터는 백업해둔다.
        localStorage.setItem('ReserveConfirmViewBackup', JSON.stringify(this.state));

        // 카드 등록 화면으로 이동.
        this.props.history.push({
            pathname:'/ticket_buying',
            state: {
                previousView:'reserve_confirm'
            },
        })
        

    }

    saveLocationLocalStorage = () => {
        const {
            startLocationAddress, startLocationLat, startLocationLng,
            endLocationAddress, endLocationLat, endLocationLng,
            stopOverList, checkSwitch
        } = store.getState().LocationSelector_ReduxModule;

        let tmpLocationList = localStorage.getItem('history_location_list');
        let tmpParseData = '';
        let resultData = '';
        let newData = {
            date: moment().format(),
            endLat: endLocationLat,
            endLng: endLocationLng,
            endLocation: endLocationAddress,
            startLat: startLocationLat,
            startLng: startLocationLng,
            startLocation: startLocationAddress,
            stopOverList: stopOverList,
            checkSwitch: (checkSwitch === '') ? false : checkSwitch
        }

        // null이 아닐때는 존재하므로 JSON.parse실시
        if (tmpLocationList !== null) {
            tmpParseData = JSON.parse(tmpLocationList);   
            resultData = [newData, ...tmpParseData];
        }
        // null일 경우에는 신규추가가 되므로 신규 작성한다.
        else {
            resultData = [newData];
        }
        
        localStorage.setItem('history_location_list', JSON.stringify(resultData))
        
       
    }

    

    render() {
        const {
            startLocationAddress,
            stopOverList,
            endLocationAddress,

        } = store.getState().LocationSelector_ReduxModule;

        const {
            date,
            RevStartDt,
            RevEndDt,
            CustomerComment,
            Passenger,
            CustomerName,
            ReserveCharge,
            couponAvailableList,
            selectCouponRedeemCode,
            DiscountAmount, 
            // DiscountRate, 
            DrivingCharge, 
            carLocation,
            // CustomerNo,
            PassengerPhoneNo,
            // passengerCheckStatus,
            // reserveType,
            // hourView,
            // typeView,
            // moneyView,
            calcPricing,
            discountType,
            covidKitStatus
        } = this.state;


        let examComponent2 = (arr) => {
            return arr.map((data, idx) => {
                return (
                    <>
                        <li><i className="ico ico-vial1-gray"></i>{data.waypointAddress}</li>
                    </>
                )
            });
        }

        let generateCouponSelectOption = (arr) => {
            return arr.map((data, idx) => {
                return (
                    <>
                        <option value={data.RedeemCode}>{`${data.PromotionName} ${CouponApi.GetCouponTypeToStr(data.DiscountRate, data.DiscountAmount)} (${moment(data.ExpiredDt).format('YY-MM-DD')} 까지)`}</option>
                    </>
                )
            });
        }
        
        let classHeaderColor = (this.state.DrivingType === Number(RESERVE_GOLF)) ? 'reserve-head golf grid cross-center' : 'reserve-head general grid cross-center';

        // "없음" 선택 컴포넌트
        let noneComponent = () => {
            return (
                
            <section className="accordion on space-top">
                <h1 className="accordion-head">
                    <button type="button"  onClick={(e)=>{
                        this.handleDiscountTypeChange(DISCOUNT_TYPE_NONE)
                        e.stopPropagation()
                    }}>
                        <span className="grid cross-center mar-r25">
                            <div className="col title">
                                <div className="radio-box">
                                    <input type="radio" name="payment_ticket" id="payment_ticket" checked={discountType === DISCOUNT_TYPE_NONE} />
                                    없음
                                </div>
                            </div>
                            <div className="col right"></div>
                        </span>
                    </button>
                </h1>
            </section>  
            )
        }

        // "쿠폰" 선택 컴포넌트
        let counponComponent = () => {
            return(
                <>
                    <section className="accordion on space-top">
                        <h1 className="accordion-head">
                            <button type="button" onClick={(e)=>{
                                this.handleDiscountTypeChange(DISCOUNT_TYPE_COUPON)
                                e.stopPropagation()
                            }}>
                                <span className="grid cross-center mar-r25">
                                    <div className="col title">
                                        <div className="radio-box">
                                            <input type="radio" name="payment_coupon" id="payment_coupon" checked={discountType === DISCOUNT_TYPE_COUPON} />
                                            쿠폰
                                        </div>
                                    </div>
                                    <div className="col right">
                                        <span className="text-medium text-primary">
                                            {couponAvailableList.length ? `${couponAvailableList.length}장 사용가능` : ''}
                                        </span>
                                    </div>
                                </span>
                                {/* <i className="arrow"></i> */}
                            </button>
                        </h1>
                        <div className="accordion-body">
                            <select className="select type1" value={selectCouponRedeemCode} onChange={this.handleChangeSelectCoupon} disabled={discountType !== DISCOUNT_TYPE_COUPON}>
                                {/* <option value="0">사용 안함</option>
                                <option value="0">적용할 수 있는 쿠폰이 없습니다.</option>
                                <option value="0">선택가능한 쿠폰이 있습니다.</option>
                                {generateCouponSelectOption(couponAvailableList)} */}

                                <option value="0">사용 안함</option>
                                {generateCouponSelectOption(couponAvailableList)}
                            </select>
                        </div>
                    </section>  

                </>
            )
        }

        // "충전권" 선택 컴포넌트
        let ticketComponent = () => {
            
            return(
                <>
                    <section className="accordion on space-top">
                            <h1 className="accordion-head">
                                <button type="button" onClick={(e)=>{
                                    this.handleDiscountTypeChange(DISCOUNT_TYPE_TICKET)
                                    e.stopPropagation()
                                }}>
                                    <span className="grid cross-center mar-r25">
                                        <div className="col title">
                                            <div className="radio-box">
                                                <input type="radio" name="payment_ticket" id="payment_ticket" checked={discountType === DISCOUNT_TYPE_TICKET} />
                                                충전권
                                            </div>
                                        </div>
                                        <div className="col right"><span className="text-medium text-primary">쿠폰과 충전권 중복 사용 불가</span></div>
                                    </span>
                                    {/* <i className="arrow"></i> */}
                                </button>
                            </h1>
                            <div className="accordion-body">
                                <ul className="list type1">
                                    <li className="item">
                                        <div className="item-row grid cross-center">
                                            <div className="col left">
                                                <span>잔여금액</span>
                                            </div>
                                            <div className="col right text-money">
                                                <strong>{Util.numberWithCommas(String(this.state.ticketData.Item.RemainAmount))}</strong>원
                                            </div>
                                            <div className="col mar-l10">                                    
                                                <button type="button" className="btn small primary" onClick={this.handleChargeTicket}><span>충전하기</span></button>
                                            </div>                                             
                                        </div>                                                                         
                                    </li>
                                </ul> 
                            </div>
                        </section>  
                </>
            )
        }

        const idxToStr = (length, idx) =>{
            console.log(`length : ${length}, idx: ${idx}`)
            let retdata = ''
            if (length >= 3) {
                if (idx === 0 || idx === length-1) {
                    switch(idx) {
                        case 0: 
                            retdata='출발지';
                            break;
                        case length-1:
                            retdata='종료지';
                            break;
                    }
                }
                else {
                    retdata = `경유지${idx}`
                }
                
            }
            else {
                switch(idx) {
                    case 0: 
                        retdata='출발지';
                        break;
                    case 1:
                        retdata='종료지';
                        break;
                }
            }

            return retdata

        }

        let weatherComponent = (arr) => {
            return arr.map((data, idx)=>{
                return (
                <div className="col" style={{textAlign:'center', width:'20%'}}>
                    <p style={{fontSize:'0.6rem', paddingBottom:'0.2rem'}}>{idxToStr(arr.length, idx)}</p>
                    <img src={this.getWeatherIcon(data)} width="50" style={{paddingBottom:'0.5rem'}} />
                    <p>{data}</p>
                </div>
            )
            })
        }

        /* ebridge - 카드 바우처 마케팅.  
        하나카드 공항홈발렛 10% 할인쿠폰. 모시러랑 졔휴를 했데요.. */

        
        
        return (
            <>
                {!this.state.loading ? (<Loading />) : ''}
                <PaymentCss />
                <Header
                    title={`최종 예약 확인`}
                    onBackButton={this.handleGotoBack}
                    onHomeButton={this.handleGotoHome}
                />

                <main id="Contents" className="section-reserve">
                    {/* <div className="component-wrap">
                        <div className={classHeaderColor} >
                            <div className="col tit">
                                <strong>{ReserveApi.getDrivingTypeStr(this.state.DrivingType)}예약</strong>
                            </div>
                        </div>
                    </div> */}

                    <div className="accordion-container">
                         {/* 예약일 예상 날씨 */}
                         {/* {this.state.weatherResult.length ? (
                            <section className="accordion on space-top">
                                <h1 className="accordion-head2">
                                    <button type="button">
                                        <span className="grid cross-center mar-r25">
                                            <div className="col title">예약일 예상 날씨 ({moment(date).format('MM/DD')})</div>
                                            <div className="col right"></div>
                                        </span>
                                    </button>
                                </h1>
                                <div className="accordion-body">
                                    <ul className="list type1">
                                        <li className="item">
                                            <div className="item-row grid" style={{paddingBottom:'0.625rem', justifyContent:'center'}}>
                                                {weatherComponent(this.state.weatherResult)}
                                            </div>                                        
                                        </li>
                                        <li className="item">
                                            <div className="bul notice">
                                                <p>예약 당일 오전 날씨를 기상청에서 받아옵니다. 자세한 날씨 정보는 <NavLink className="text-underline" to="#" onClick={async (e)=>{
                                                    e.preventDefault();
                                                    await RnInterface.callReactNative(`NewIntentLinkBrowsing${'https://www.weather.go.kr/w/index.do'}`)
                                            }} >기상청 홈페이지</NavLink>를 확인해주세요.</p>
                                            </div>
                                        </li>
                                    </ul> 
                                </div>
                            </section>
                        ) : null} */}
                        
                        {/* 예약 종류 */}
                        <section className="accordion on">
                            <h1 className="accordion-head2">
                                <button type="button">
                                    <span className="grid cross-center mar-r25">
                                        <div className="col title">예약 종류</div>
                                    </span>
                                </button>
                            </h1>

                            <div className="accordion-body pad-0">                                
                                <div className="route-box2">
                                    <ul>
                                        <li>
                                            <strong>{ReserveApi.getDrivingTypeStr(this.state.DrivingType)}예약 ({this.state.checkSwitchFlag ? "왕복" : "편도"})</strong>
                                        </li>
                                    </ul>
                                </div> 
                            </div>
                        </section>   

                        {/* 예약 시간 */}
                        <section className="accordion on space-top">
                            <h1 className="accordion-head2">
                                <button type="button">
                                    <span className="grid cross-center mar-r25">
                                        <div className="col title">예약 시간</div>
                                    </span>
                                </button>
                            </h1>

                            <div className="accordion-body2">
                                <ul className="list type1">
                                    <li className="item">
                                        <div className="item-row grid">
                                            <div className="col col-3">
                                                <span>예약날짜</span>
                                            </div>
                                            <div className="col col-9">
                                                <span><strong>{moment(date).format('YYYY-MM-DD')} ({this.getDayKorean(moment(date).format('d'))})</strong></span>
                                                
                                            </div>
                                        </div>
                                        <div className="item-row grid">
                                            <div className="col col-3">
                                                <span>예약시간</span>
                                            </div>
                                            <div className="col col-9">
                                                <span><strong>{`${moment(calcPricing.RevStartDt).format('HH:mm')} ~ ${getNextDayStr(moment(calcPricing.RevStartDt).format('yyyyMMDD'), moment(calcPricing.RevEndDt).format('yyyyMMDD'))}${moment(calcPricing.RevEndDt).format('HH:mm')}`} ({ReserveApi.getTimeDifferenceHourToStr(calcPricing.RevStartDt, calcPricing.RevEndDt)})</strong></span>
                                            </div>
                                        </div>
                                        {/* <div className="item-row grid">
                                            <div className="col col-3">
                                                
                                            </div>
                                            <div className="col col-9">
                                                <span>(기본 {this.getReserveTypeToEndTimeHour(this.state.DrivingType)}시간{this.state.addTime >= 1 ? (<> / 추가 {this.state.addTime}시간</>) : null })</span>
                                            </div>
                                        </div> */}
                                    </li>
                                </ul> 
                            </div>
                        </section>   

                        {/* 이용정보 */}
                        <section className="accordion on space-top">
                            <h1 className="accordion-head2">
                                <button type="button">
                                    <span className="grid cross-center mar-r25">
                                        <div className="col title">주소 정보</div>
                                        {/* <div className="col right">
                                            <div class="route-legend">
                                                <ul>
                                                    <li><i className="ico ico-start"></i><label className="text-sub">출발지</label></li>
                                                    <li><i className="ico ico-vial1"></i><label className="text-sub">경유지</label></li>
                                                    <li><i className="ico ico-goal"></i><label className="text-sub">종료지</label></li>
                                                </ul>
                                            </div>                                            
                                        </div> */}
                                    </span>
                                    {/* <i className="arrow"></i> */}
                                </button>
                            </h1>

                            <div className="accordion-body pad-0">                                
                                <div className="route-box2">
                                    <ul>
                                        <li><i className="ico ico-start"></i>{startLocationAddress}</li>
                                        {examComponent2(stopOverList)}
                                        <li><i className="ico ico-goal"></i>{endLocationAddress}</li>
                                    </ul>
                                </div> 
                            </div>
                        </section>   

                        {/* 예약자정보 */}
                        <section className="accordion on space-top">
                            <h1 className="accordion-head2">
                                <button type="button">
                                    <span className="grid cross-center mar-r25">
                                        <div className="col title">예약자정보</div>
                                        <div className="col right"></div>
                                    </span>
                                    {/* <i className="arrow"></i> */}
                                </button>
                            </h1>                            
                            
                            <div className="accordion-body2">
                                <ul className="list type1">
                                    <li className="item">
                                        <div className="item-row grid">
                                            <div className="col col-3">
                                                <span>예약자</span>
                                            </div>
                                            <div className="col col-9">
                                                <span>{CustomerName}</span>
                                            </div>
                                        </div>
                                        <div className="item-row grid">
                                            <div className="col col-3">
                                                <span>탑승자</span>
                                            </div>
                                            <div className="col col-9">
                                                <span>{Passenger}</span>
                                            </div>
                                        </div>
                                        
                                        {/* <div className="item-row grid">
                                            <div className="col col-3">
                                                <span>차량위치</span>
                                            </div>
                                            <div className="col col-9">
                                                <span>{carLocation && carLocation.length !== undefined ? carLocation : '없음'}</span>
                                            </div>
                                        </div>   */}

                                        <div className="item-row grid">
                                            <div className="col col-3">
                                                <span>요청사항</span>
                                            </div>
                                            <div className="col col-9">
                                                <span>{CustomerComment && CustomerComment.length !== undefined ? CustomerComment : '없음'}</span>
                                            </div>
                                        </div>
                                        {/* 방역키트 지급 축소
                                        <div className="item-row grid">
                                            <div className="col col-3">
                                                <span>방역키트</span>
                                            </div>
                                            <div className="col col-9">
                                                <span>{covidKitStatus ? '추가' : '미추가'}</span>
                                            </div>
                                        </div> */}
                                    </li>
                                </ul> 
                            </div>
                        </section>
                        
                        
                        {/* 결제상세 */}
                        <section className="accordion on space-top">
                            <h1 className="accordion-head2">
                                <button type="button">
                                    <span className="grid cross-center mar-r25">
                                        <div className="col title">결제상세</div>
                                        <div className="col right"></div>
                                    </span>
                                    {/* <i className="arrow"></i> */}
                                </button>                                
                            </h1>

                            <div className="accordion-body">
                                <ul className="list type1 price-detail">
                                    <li className="item pad-b10">

                                        <div className="item-row grid">
                                            <div className="col left">
                                                <strong  style={{ fontSize:"1.3em",}}>
                                                    이용금액
                                                </strong>
                                            </div>
                                            <div className="col right align-r text-money">
                                                <strong  style={{ fontSize:"1.3em",}}>
                                                    {this.state.loading && Util.numberWithCommas(String(calcPricing.DrivingCharge + calcPricing.StartAreaFee + calcPricing.AreaFee + calcPricing.NightFee))}원
                                                </strong>
                                            </div>
                                        </div> 

                                        <ul className="inner-list-down">
                                            <li className="grid crocss-center">
                                                <div className="col left">운행요금</div>
                                                <div className="col right align-r text-money">
                                                    <span>{this.state.loading && Util.numberWithCommas(String(calcPricing.DrivingCharge))}</span>원
                                                </div>
                                            </li>
                                            {calcPricing.StartAreaFee <= 0 ? null : (
                                                <li className="grid crocss-center"> 
                                                    <div className="col left">
                                                        <span>출발지할증</span>
                                                        {/* <NavLink to="#" className="tooltip">
                                                            <i className="ico ico-quest"></i>
                                                            <span className="tooltip-content">
                                                                <p>경기도 30,000원</p>
                                                                <button className="tooltip-close">
                                                                    <span className="blind">툴팁 닫기</span>
                                                                </button>
                                                            </span>
                                                        </NavLink>   */}
                                                    </div>
                                                    <div className="col right align-r text-money">
                                                        <i className="ico price-add">할증</i>
                                                        <span>{this.state.loading && Util.numberWithCommas(String(calcPricing.StartAreaFee))}</span>원
                                                    </div>
                                                </li>   
                                            )}
                                            

                                            {calcPricing.AreaFee <= 0 ? null : (
                                                <li className="grid crocss-center">
                                                    <div className="col left">
                                                        <span>지역할증</span>
                                                        {/* <NavLink to="#" className="tooltip">
                                                            <i className="ico ico-quest"></i>
                                                            <span className="tooltip-content">충청도 50,000원
                                                                <button className="tooltip-close">
                                                                    <span className="blind">툴팁 닫기</span>
                                                                </button>
                                                            </span>
                                                        </NavLink>   */}
                                                    </div>
                                                    <div className="col right align-r text-money">
                                                        <i className="ico price-add">할증</i>
                                                        <span>{this.state.loading && Util.numberWithCommas(String(calcPricing.AreaFee))}</span>원
                                                    </div>
                                                </li>
                                            )}
                                            
                                            
                                            {calcPricing.NightFee <= 0 ? null : (
                                                <li className="grid crocss-center">
                                                    <div className="col left">
                                                        <span>심야할증</span>
                                                        {/* <NavLink to="#" className="tooltip">
                                                            <i className="ico ico-quest"></i>
                                                            <span className="tooltip-content">충청도 50,000원
                                                                <button className="tooltip-close">
                                                                    <span className="blind">툴팁 닫기</span>
                                                                </button>
                                                            </span>
                                                        </NavLink>   */}
                                                    </div>
                                                    <div className="col right align-r text-money">
                                                        <i className="ico price-add">할증</i>
                                                        <span>{this.state.loading && Util.numberWithCommas(String(calcPricing.NightFee))}</span>원
                                                    </div>
                                                </li>
                                            )}
                                            

                                        </ul>
                                    </li>

                                    {/* 쿠폰 선택 되었을 경우에는 할인금액쪽이 나오도록 해야한다. */}
                                    { ((calcPricing.DiscountAmount !== 0) || (calcPricing.ReserveDiscountAmount !== 0)) && (
                                        <li className="item pad-b10">
                                            <div className="item-row grid">
                                                <div className="col left">
                                                    <strong>할인금액</strong>
                                                </div>
                                                <div className="col right align-r text-money">
                                                    <strong> {this.state.loading && Util.numberWithCommas(String(calcPricing.DiscountAmount + calcPricing.ReserveDiscountAmount))}  </strong>원
                                                </div> 
                                            </div> 

                                            <ul className="inner-list-down">
                                                
                                                {(calcPricing.DiscountAmount !== 0) && (
                                                    <li className="grid crocss-center">
                                                        {/* 쿠폰 할인 */}
                                                        <div className="col left">
                                                            <span>쿠폰할인</span>
                                                            {/* <NavLink to="#" className="tooltip">
                                                                <i className="ico ico-quest"></i>
                                                                <span className="tooltip-content">마스터 프리미엄카드 10% 할인
                                                                    <button className="tooltip-close">
                                                                        <span className="blind">툴팁 닫기</span>
                                                                    </button>
                                                                </span>
                                                            </NavLink>   */}
                                                        </div>
                                                        <div className="col right align-r text-money">
                                                            {/*<i className="ico price-discount">할인</i>*/}
                                                            <span>{this.state.loading && Util.numberWithCommas(String(calcPricing.DiscountAmount))}</span>원
                                                        </div>
                                                    </li> 
                                                )}

                                                {(calcPricing.ReserveDiscountAmount !== 0) && (
                                                    <li className="grid crocss-center">
                                                        {/* 예약 할인 (골프 할인 등.) */}
                                                        <div className="col left">
                                                            <span>예약할인</span>
                                                            {/* <NavLink to="#" className="tooltip">
                                                                <i className="ico ico-quest"></i>
                                                                <span className="tooltip-content">골프장 예약할인 30,000원
                                                                    <button className="tooltip-close">
                                                                        <span className="blind">툴팁 닫기</span>
                                                                    </button>
                                                                </span>
                                                            </NavLink>  */}
                                                        </div>
                                                        <div className="col right align-r text-money">
                                                            {/*<i className="ico price-discount">할인</i>*/}
                                                            <span>{this.state.loading && Util.numberWithCommas(String(calcPricing.ReserveDiscountAmount))}</span>원
                                                        </div>
                                                    </li>
                                                )}
                                                
                                            </ul>
                                        </li>
                                    ) }
                                    
                                    {/* 충전권 사용시에는 충전권 금액 화면이 나오도록 해야한다. */}
                                    { (this.state.discountType === DISCOUNT_TYPE_TICKET) && (
                                        <li className="item pad-b10">
                                            <div className="item-row grid">
                                                <div className="col left">
                                                    <strong>충전권사용</strong>
                                                </div>
                                                <div className="col right align-r text-money text-primary">
                                                    <strong>{this.state.loading && Util.numberWithCommas(String(calcPricing.PrePaidTicketAmount))}</strong>원
                                                    {/* <p className="desc-tip">잔여금액 {this.state.loading && Util.numberWithCommas(String(this.state.ticketData.Item.RemainAmount - calcPricing.PrePaidTicketAmount))}원</p> */}
                                                </div>
                                            </div>
                                        </li>
                                    ) }
                                    

                                    <li className="item">
                                        <div className="bul notice">
                                            <p>할증 요금 정책은 <NavLink className="text-underline" to="#" onClick={(e)=>{
                                                e.preventDefault();
                                                this.handleChangeModalStatusTrue();
                                            }} >이곳을</NavLink> 눌러주시면 확인할 수 있습니다.</p>
                                            {/* <p>등록 카드는 <NavLink className="text-underline" to="">전체메뉴 &lt; 카드관리</NavLink>에서 변경 가능합니다.</p> */}
                                        </div>
                                    </li>
                                </ul> 
                            </div>

                            <div className="component-wrap">
                                <div className="box total grid cross-center">
                                    <div className="col">
                                        <span>총결제금액</span>
                                    </div>
                                    <div className="col right align-r">
                                        <strong className="text-largest text-bold">{this.state.loading && Util.numberWithCommas(String(calcPricing.ReseveCharge))}원</strong>
                                        { (this.state.discountType === DISCOUNT_TYPE_TICKET) && (
                                            <p>충전권 잔여금액 : {this.state.loading && Util.numberWithCommas(String(this.state.ticketData.Item.RemainAmount - calcPricing.PrePaidTicketAmount))}원</p>
                                        ) }
                                    </div>
                                </div>
                            </div>     
                                                    
                        </section>
                       
                    </div>

                    
                                            


                    {/* 내용을 없애고 싶을때는 information-text on 에서 on을 빼면 된다. */}
                    <div className="component-wrap">
                        <section className="information-text on">
                            <h1 className="information-head">
                                <button type="button" className="btn-viewmore">
                                    <i className="ico ico-info"></i>
                                    꼭 확인해주세요.
                                    <i className="icon arrow"></i>
                                </button>
                            </h1>

                            <div className="information-body">
                                <div className="info-desc">
                                    <h3 className="info-stit">취소 수수료 안내(예약일 기준)</h3>
                                    <ul className="text-sub">
                                        <li><p className="text-gray">전일 : 30,000원</p></li>
                                        <li><p className="text-gray">당일 : 예약금 기준 50%</p></li>
                                        <li><p className="text-gray">예약시간 이후 : 예약금 기준 100%</p></li>
                                        {this.state.DrivingType === Number(RESERVE_GOLF) && (<li><p className="text-gray">운행중 우천으로 인한 취소 : 예약금 기준 50%</p></li>)}
                                    </ul>
                                </div>

                                {/* <div className="info-desc">
                                    <h3 className="info-stit">쿠폰사용안내</h3>
                                    <ul className="text-sub">
                                        <li><p className="text-import">쿠폰 사용 시</p>운행요금에 대해서만 할인이 적용되며, 할증 및 연장요금, 수수료 등은 할인이 적용되지 않습니다.</li>
                                    </ul>
                                </div>
                                <div className="info-desc">
                                    <h3 className="info-stit">혜택안내</h3>
                                    <ul className="text-sub">
                                        <li>
                                            <p className="text-import">마스터 프리미엄카드</p>모든 서비스 상시 10% 할인쿠폰 발급
                                            
                                        </li>
                                        <li><p className="text-import">하나카드</p>공항홈발렛 서비스 상시 10% 할인쿠폰 발급<br />(결제수단카드 변경 시 사용 불가)</li>
                                    </ul>
                                </div> */}

                            </div>
                        </section> 
                    </div>

                    <div className="component-wrap inner-container">
                        <div className="btn-area horizontal">
                            <button type="button" className="btn large secondary liner" onClick={this.handleGotoBack}><span>수정</span></button>
                            <button type="button" className="btn large secondary" onClick={this.handleSetReserve}><span>결제</span></button>
                        </div>
				    </div>
               
                </main>

                <Modal
                    isOpen={this.state.modalOpenFlag}
                    onRequestClose={this.handleChangeModalStatusFalse}
                    contentLabel="Example Modal" 
                    overlayClassName="modal-overlay"
                    className="modal-box"
                    bodyOpenClassName="modal-scrolllock"
                >
                    <section className="modal-container">
                        <div className="modal">
                            <div className="modal-head">
                                <h1 className="modal-tit" tabindex="0">할증 요금 안내({ReserveApi.getDrivingTypeStr(this.state.DrivingType)})</h1>
                            </div>
                            <div className="modal-body">
                                <div className="modal-contents">
                                    <div className="modal-contents-body">
                                        <div className="component-wrap">
                                            <div className="inner-container">
                                                
                                                {/* 골프왕복운행건에 한해서만 심야할증과 출발지역할증은 부과하지 않는걸로 변경됩니다. 
                                                10월 1일부터 정식반영되고, 고객앱내 관련 안내문구도 모두 변경해야합니다. */}
                                                <div className="bul dash bg-skyblue">
                                                    {this.state.DrivingType === Number(RESERVE_GOLF) ? 
                                                    (<>
                                                        <p className="text-import">서울, 경기, 인천에서 출발 가능하며, 출발 지역이 경기, 인천일 경우 출발지 할증 30,000원이 과금됩니다.</p>
                                                    </>) : 
                                                    (<>
                                                        <p className="text-import">서울, 경기, 인천에서 출발 가능하며, 출발 지역이 경기, 인천일 경우 출발지 할증 30,000원이 과금됩니다.</p>
                                                    </>)}

                                                    
                                                    {this.state.DrivingType === Number(RESERVE_GOLF) ? 
                                                    (<>
                                                        <p className="">왕복운행시 출발지할증 요금만 과금됩니다.</p>
                                                        <p className="">편도운행시 출발지할증 + 지역할증이 과금됩니다.</p>
                                                        <p className="">예약시간이 23시~06시를 포함할 경우 심야할증이 과금됩니다.</p>
                                                    </>) : 
                                                    (<>
                                                        <p className="">왕복운행시 출발지할증 요금만 과금됩니다.</p>
                                                        <p className="">편도운행시 출발지할증 + 지역할증이 과금됩니다.</p>
                                                        <p className="">예약시간이 23시~06시를 포함할 경우 심야할증이 과금됩니다.</p>
                                                    </>)}
                                                    
                                                </div>
                                                <div className="tbl type1 mar-t10">                                        
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" style={{width:"25%"}}>지역</th>
                                                                <th scope="col" style={{width:"25%"}} className="point">할증요금</th>
                                                                <th scope="col" style={{width:"25%"}}>지역</th>
                                                                <th scope="col" style={{width:"25%"}} className="point">할증요금</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>경기/인천</td>
                                                                <td className="point">30,000원</td>
                                                                <td>충청</td>
                                                                <td className="point">50,000원</td>
                                                            </tr>
                                                            <tr>
                                                                <td>강원</td>
                                                                <td className="point">70,000원</td>
                                                                <td>전북/경북</td>
                                                                <td className="point">60,000원</td>
                                                            </tr>   
                                                            <tr>
                                                                <td>전남/경남</td>
                                                                <td className="point">90,000원</td>
                                                                <td>기타지역</td>
                                                                <td className="point">120,000원</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="bul notice">
                                                    <p>기타지역 : 부산, 남해, 여수, 통영, 울산, 목포, 순천, 광양, 거제, 고흥, 보성 등</p>
                                                </div>                                                 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className="btn-top-close" onClick={this.handleChangeModalStatusFalse}>
                                <span className="blind">팝업 닫기</span>
                            </button>
                        </div>
                    </section>
                </Modal>

            </>
        );
    }
};

export default withCookies(ReserveConfirmStep2View);

