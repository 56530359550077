import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { createGlobalStyle } from "styled-components";
import store from "../../reduxStore";
import {address_del_service} from '../../modules/Global/Address_ReduxModule'
import MainMenuMemberChangeInfoCss from '../../css/CustomGlobalStyle/MainMenuMemberChangeInfo'
import Header from '../../components/Common/Header'
import RnInterface from '../../lib/RnInterface'


import '../../css/default.css';
import '../../css/booking.css';

//import service_car from "../../img/booking/service_car.svg";
import btn_prev from '../../img/btn/btn_prev.svg'
import btn_home from '../../img/btn/btn_home.svg'
import btn_arrow_right from '../../img/mypage/btn_arrow_right.svg'
import profile_ph_no from '../../img/mypage/profile_ph_no.svg'

/*

검색 : <input.*[^>]
치환 : $0/

검색 : <img.*[^>]
치환 : $0 alt=""/
*/


const GlobalStyle = createGlobalStyle`

body {background-color: #f3f4f8;}

`;


class MainMenuMemberChangeInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            info : store.getState().global_data.loginedData
        }
    }
    async componentDidMount() {
        try {
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            store.dispatch(address_del_service());
        }
        catch(e){
            console.log(e)
        }
        
    }
    
    handleGotoBack = () => {this.props.history.replace('/main')}
    handleGotoHome = () => {this.props.history.replace('/main')}
    handleGotoMemberFree = (e) => {
        e.preventDefault();
        this.props.history.push('/member_free')
    }

    handleChangePw = () => {
        this.props.history.push('/myinfo_changepw')
    }

    handleChangePhone = () => {
        this.props.history.push('/myinfo_changephone')
    }

    handleChangeAddress = () => {
        this.props.history.push('/myinfo_changeAddress')
    }

    componentWillUnmount() {
        
    }
    

    render() {
        let accountViewComponent = (accountType, UserId) => {
            let title = '';
            switch(accountType) {
                case 'email':
                    title = '이메일';
                    break;
                case 'kakao':
                    title = '카카오계정';
                    break;
                case 'apple':
                    title = '애플계정';
                    break;
                default:
                    title = '이메일';
                    break;

            }
            
            return (
                <div className="form vertical">
                    <div className="form-title">
                        <label className="form-tit">{title}</label>								
                    </div>
                    <div className="form-cont">
                        <div className="form-controls flexible">
                            <span className="input-group has-append">
                                <input type="text"  value={UserId} placeholder="이메일을 입력해주세요." className="input type1" disabled />														
                            </span>
                        </div>
                    </div>
                </div>                 
            )
            
            
            
        }
        
        return (<>
            <GlobalStyle />
            <Header
                title="내정보"
                onBackButton={this.handleGotoBack}
                onHomeButton={this.handleGotoHome} 
            />

            <main id="Contents" className="section-member"> 
                <div className="inner-container">
                    <div className="profile-wrap">
                        <i className="ico ico-bi-profile"></i>
                        <span className="profile-name">{this.state.info.CustomerName}님</span>
                    </div>
                </div>
                <div className="inner-container">
                    <div className="box myinfo">

                        {accountViewComponent(this.state.info.AccountType, this.state.info.UserId)}

                        {/* 휴대전화번호 */}
                        <div className="form vertical">
                            <div className="form-title">
                                <label className="form-tit">휴대전화번호</label>								
                            </div>
                            <div className="form-cont">
                                <div className="form-controls flexible">
                                    <span className="input-group has-append">
                                        <input type="tel" placeholder="휴대전화번호를 입력해주세요." className="input type1" value={this.state.info.PhoneNumber} />												
                                        <span className="append">
                                            <button type="button" onClick={this.handleChangePhone} className="btn text2"><span>변경</span></button>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>  
                        {/* 비밀번호 */}
                        <div className="form vertical">
                            <div className="form-title">
                                <label className="form-tit">비밀번호</label>								
                            </div>
                            <div className="form-cont">
                                <div className="form-controls flexible">
                                    <span className="input-group has-append">
                                        <input type="password" value="********" placeholder="비밀번호를 입력해주세요." className="input type1"/>														
                                        <span className="append">
                                            <button type="button" onClick={this.handleChangePw} className="btn text2"><span>변경</span></button>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div> 
                        {/* 주소 */} 
                        <div className="form vertical">
                            <div className="form-title">
                                <label className="form-tit">주소</label>								
                            </div>
                            <div className="form-cont">
                                <div className="form-controls flexible">
                                    <span className="input-group has-append">                                                
                                        <input type="text" value={this.state.info.HomeAddress} placeholder="주소를 입력해주세요." className="input type1" />														
                                        <span className="append">
                                            <button type="button" onClick={this.handleChangeAddress} className="btn text2"><span>변경</span></button>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>                     
                    </div>
                    <div className="box myinfo">
                        <NavLink to="/first_1/1">
                            <div className="grid cross-center">
                                <div className="col text-main"><strong>차량정보</strong></div>
                                <div className="col right"><i class="arw arw-more1"></i></div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="box myinfo">
                        <NavLink to="/first_end/1">
                            <div className="grid cross-center">
                                <div className="col text-main"><strong>선호하는 파트너 정보</strong></div>
                                <div className="col right"><i class="arw arw-more1"></i></div>
                            </div>
                        </NavLink>
                    </div>  
                    {Number(process.env.REACT_APP_MEMBER_FREE_DEVELOP) ? (
                    <div className="inner-container">
                        <button type="button" onClick={this.handleGotoMemberFree} className="btn text1"><span>회원탈퇴</span></button>
                    </div> 
                    ) : (
                        ''
                    )}                                                   
                </div>
            </main>    
 
        </>
        );
    }
};

export default MainMenuMemberChangeInfo;
