import { connect } from "react-redux";
import LocationSelectorNew from "../../components/Reserve/LocationSelectorNew";
import { step_over_add_service, step_over_del_service, copy_address_service } from '../../modules/Reserve/LocationSelector_ReduxModule';
// import { checkbox_service } from "../../modules/MemberJoin/agreebutton";

export default connect(
    state => {

        return {
            startLocationAddress: state.LocationSelector_ReduxModule.startLocationAddress,
            startLocationLat: state.LocationSelector_ReduxModule.startLocationLat,
            startLocationLng: state.LocationSelector_ReduxModule.startLocationLng,
            
            stopOverList: state.LocationSelector_ReduxModule.stopOverList,
            
            endLocationAddress: state.LocationSelector_ReduxModule.endLocationAddress,
            endLocationLat: state.LocationSelector_ReduxModule.endLocationLat,
            endLocationLng: state.LocationSelector_ReduxModule.endLocationLng,

            //경유지 정보_jane
            wayLocationLat: state.LocationSelector_ReduxModule.wayLocationLat,
            wayLocationLng: state.LocationSelector_ReduxModule.wayLocationLng,

            //출발지, 종료지 동일_jane
            checkSwitch: state.LocationSelector_ReduxModule.checkSwitch
        }
    },
    
    dispatch => ({
        onClickAdd: (data)=>dispatch(step_over_add_service(data)), 
        onClickDel: (idx)=>dispatch(step_over_del_service(idx)),
        onChangeAddressSwitch: (result)=>dispatch(copy_address_service(result)) //출발지, 종료지 동일_jane
    })
    
    )(LocationSelectorNew);


/**,
dispatch  => ({
    checkbox_service: ()=> dispatch(checkbox_service()) // <- "../../modules/MemberJoin/agreebutton"에 있는 type값을 dispatch
}) */
