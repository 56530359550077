import { createStore, applyMiddleware } from "redux";      // 추가
import rootReducer from "./modules";      // 추가
import { composeWithDevTools } from "redux-devtools-extension";

// redux middle ware
import logger from './middleware/logger';
import ReduxThunk from 'redux-thunk';

export default createStore(rootReducer, composeWithDevTools(
    applyMiddleware(logger, ReduxThunk)
) ); // 추가