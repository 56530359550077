import axios from 'axios'
const HEADER = {
    'Content-Type' : "application/json",
    "Token": "10AD4FB24CE89DC79E3DA38B3BFDE90CBEE3267C227FA7FFD788D576901A528F",
    "OsType" : "A",
    "DeviceId" : ""
}


export const SetConfirmApplication = (userData) => {
    return new Promise(async (resolve)=>{
        let data = await axios({
            url: `/SetConfirmApplication?driverNo=${userData.driverno}&drivingNo=${userData.drivingno}`,
            method : "GET",
            headers : HEADER
        })
        resolve(data.data)
    })
}

export const SetDrivePrepareStart = (userData) => {
    return new Promise(async (resolve)=>{
        let data = await axios({
            url: `/v2/SetDrivePrepareStart?drivingNo=${userData.drivingno}&driverNo=${userData.driverno}&lat=${userData.startLat}&lng=${userData.startLng}`,
            method : "GET",
            headers : HEADER
        })
        resolve(data.data)
    })
}

export const SetDriveStart = (userData) => {
    return new Promise(async (resolve)=>{
        let data = await axios({
            url: `/v2/SetDriveStart?drivingNo=${userData.drivingno}&driverNo=${userData.driverno}&appVersion=1.1.15&lat=${userData.startLat}&lng=${userData.startLng}`,
            method : "GET",
            headers : HEADER
        })
        resolve(data.data)
    })
}

//endDate : 2022-05-19 12:30
export const SetDriveEnd = (userData) => {
    return new Promise(async (resolve)=>{
        let data = await axios({
            url: `/v2/SetDriveEnd?drivingNo=${userData.drivingno}&driverNo=${userData.driverno}&lat=${userData.endLat}&lng=${userData.endLng}&appVersion=1.1.15&endDate=${`${userData.date} ${userData.time}`}`,
            method : "GET",
            headers : HEADER
        })
        resolve(data.data)
    })
}