import React, { Component } from 'react';
import {createGlobalStyle} from 'styled-components'

const GlobalStyle = createGlobalStyle`
#container2 { z-index:1050; width:100%; height:100vh; background-color: rgba(0, 0, 0, 0.25); top:0px;}
#container2 > ul {width: 180px; padding-top: 30%; margin: auto; vertical-align: middle; text-align:center;}

#loading2 {
    z-index:1050 !important;
    display: inline-block;
    width: 100px;
    height: 100px;
    border: 10px solid rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}
`;


class LoadingInner extends Component {
    state = {
        modalOpenFlag: false,
    }

    render() {
        return (
            <>
                <GlobalStyle />
                <div id="container2">
                    <ul>
                        <div id="loading2"></div>
                    </ul>
                </div>
                
                
            </>
        );
    }
};

export default LoadingInner;
