import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

// import DatePicker, { registerLocale } from "react-datepicker";
// import ko from 'date-fns/locale/ko';
import { withCookies } from 'react-cookie';

import store from '../../reduxStore'
// import AddressApi from '../../lib/AddressApi';
import ReserveApi from '../../lib/ReserveApi'
import LocationSelectorAirContainer from '../../containers/Reserve/LocationSelectorAirContainer'
import {
    plusSecondsMomentWithFormatCustom
} from '../../lib/TimeApi'
import Header from '../../components/Common/Header'
import Util from '../../lib/Util'
import Loading from '../../components/Common/Loading'
// import TimeSelectorContainter from '../../containers/Reserve/TimeSelectorContainer'
import TimeSelector2 from '../../components/Reserve/TimeSelector2Air';
import {calert, calertPromise} from '../../lib/Util'
import moment from 'moment';
import RnInterface from '../../lib/RnInterface'

import '../../css/default.css';
import '../../css/booking.css';
import "react-datepicker/dist/react-datepicker.css";

// import btn_prev from '../../img/btn/btn_prev.svg'
// import btn_home from '../../img/btn/btn_home.svg'

import icon_arrow_down from '../../img/booking/icon_arrow_down.svg'
import icon_notice from '../../img/booking/icon_notice.svg'

// import DateSelector from '../../components/Reserve/DateSelector'
// import TimeSelector from '../../components/Reserve/TimeSelector'

import icon_arrow_right from '../../img/booking/icon_arrow_right.svg'
import icon_calendar from '../../img/booking/icon_calendar.svg'
import icon_chat from '../../img/booking/icon_chat.svg'
// import icon_point from '../../img/booking/icon_point.svg'         // 2
// import icon_delete from '../../img/booking/icon_delete.svg'
// import icon_location from '../../img/booking/icon_location.svg'


// registerLocale('ko', ko)

const TEXTAREA_MAX_LENGTH = 100;


/*
검색 : <input.*[^>]
치환 : $0/

검색 : <img.*[^>]
치환 : $0 alt=""/
*/

class ReserveAirView extends Component {
    // state = {
    //     startLocation:{
    //         address:'',
    //         lat:0,
    //         lng:0
    //     },
    //     stopOverList : [],
    //     endLocation:{
    //         address:'',
    //         lat:0,
    //         lng:0
    //     }
    // }

    state = {
        date: moment().add(2, 'hours').toDate(),
        startTime: moment().add(2, 'hours'),
        fixedStartTime: moment().add(2, 'hours'),
        endTime: moment().add(3, 'hours'),
        carLocation: '',
        comment:'',
        CustomerNo: 0,
        passengerName: '',
        passengerPhone:'',
        passengerCheckStatus:true,
        drivingType: 1,
        hourView: '',
        typeView: '일반',
        moneyView: 0,
        loading:1
    }
    LIMIT_STOPOVER_COUNT = 3;

    handleGotoBack = () => {
        this.props.history.replace('/main');
    }

    asyncProcess = async () => {
        try {
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            let tmpInitReserveBackupData = [
                localStorage.getItem('reserve_temp_date'),                          // 0
                localStorage.getItem('reserve_temp_startTime'),                     // 1
                localStorage.getItem('reserve_temp_endTime'),                       // 2
                localStorage.getItem('reserve_temp_carLocation'),                   // 3
                localStorage.getItem('reserve_temp_comment'),                       // 4
                localStorage.getItem('reserve_temp_type')                           // 5
            ]
    
            let {loginedData} = store.getState().global_data
            this.setState({
                date: tmpInitReserveBackupData[0]           !== null ? new Date(tmpInitReserveBackupData[0]) : moment().add(2, 'hours').toDate(),
                startTime: tmpInitReserveBackupData[1] !== null ? moment(tmpInitReserveBackupData[1]) : moment().add(2, 'hours'),
                endTime: tmpInitReserveBackupData[2] !== null ? moment(tmpInitReserveBackupData[2]) : moment().add(3, 'hours'),
                carLocation: tmpInitReserveBackupData[3]    !== null ? tmpInitReserveBackupData[3] : '',
                comment:tmpInitReserveBackupData[4]         !== null ? tmpInitReserveBackupData[4] : '',
                drivingType: tmpInitReserveBackupData[5]    !== null ? Number(tmpInitReserveBackupData[5]) : this.props.match.params.state,
                passengerName: loginedData.CustomerName     !== undefined ? loginedData.CustomerName : '',
                passengerPhone:loginedData.PhoneNumber      !== undefined ? loginedData.PhoneNumber : '',
                CustomerNo: store.getState().global_data.loginedData.CustomerNo,
                
            }, () => {
                this.getCalcDrivingReservation();
            })
        }
        catch(e) {
            console.log(e);
        }

        
    }

    componentDidMount () {
        // 초기값 세팅
        this.asyncProcess();
        // Load될때 
        
        // 출발지의 정보를 받아야한다.
        // 1. 기기로부터 위도, 경도 정보를 얻어온다
        // 2. getConvertLatlngToAddress를 호출하여 주소를 반환시킨다.
        // 위도 경도 정보를 못받아올 경우에는 GPS기능이 꺼져있어서 표시되지 않습니다.로 하고 위경도 정보를 0,0로 한다.
        // 3. 위, 경도, 주소값을 setState한다.
    }

    componentWillUnmount() {
        localStorage.setItem('reserve_temp_type', this.state.drivingType)
        localStorage.setItem('reserve_temp_date', this.state.date)             
        localStorage.setItem('reserve_temp_startTime', this.state.startTime.toDate())        // 미사용
	    localStorage.setItem('reserve_temp_endTime', this.state.endTime.toDate())            // 미사용
        localStorage.setItem('reserve_temp_carLocation', this.state.carLocation)
        localStorage.setItem('reserve_temp_comment', this.state.comment)       
        localStorage.setItem('reserve_temp_passengername', this.state.passengerName)       
        localStorage.setItem('reserve_temp_passengerphone', this.state.passengerPhone) 
        
    }
    
    handleAddStopover = async (e) => {
        let tmpArr = this.state.stopOverList;
        let tmpArrLen = this.state.stopOverList.length;

        if (tmpArrLen >= this.LIMIT_STOPOVER_COUNT) {
            await calertPromise('경유지는 3개까지 등록 가능합니다.');
        } else {
            tmpArr.push(1);
            this.setState({
                stopOverList: tmpArr
            })
        }
    }

    handleDelStopover = (e) => {
        let tmpArr = [];
        
        for (let i in this.state.stopOverList) {
            if (e.target.id === i) continue;
            else tmpArr.push(this.state.stopOverList[i]);
        }

        this.setState({
            stopOverList: tmpArr
        })
    }

    handleSaveDate = (data) => {
        return new Promise((resolve)=>{
            this.setState({ date: data }, () => {
                this.getCalcDrivingReservation();        
                resolve(true)

            });
        })
        
        
    }

    changeHourView = (data) => {
        console.log(data)
        return data.replace('시간','시간 ')

    }


    handleUpdateStartTimeGeneral = (data) => {
        this.setState({
            startTime: data,
            endTime: moment(data).add(1, 'hours'),
        }, () => {




            this.getCalcDrivingReservation();
        });
    }

    handleUpdateStartTimeGolf = (data) => {
        this.setState({
            startTime : data,
            endTime : moment(data).add(10, 'hours').toDate(),
        }, ()=>{
            this.getCalcDrivingReservation();
        });
    }

    handleUpdateEndtime = (data) => {
        this.setState({endTime : data}, ()=>{
            this.getCalcDrivingReservation();
        });
    }

    handleCarLocation = (e) => {
        if (Util.getStringLength(e.target.value) <= TEXTAREA_MAX_LENGTH) {
            this.setState({carLocation: e.target.value})
        }
    }

    handleComment = (e) => {
        if (Util.getStringLength(e.target.value) <= TEXTAREA_MAX_LENGTH) {
            this.setState({comment: e.target.value})
        }
    }

    getCalcDrivingReservation = async (e) => {
        try {
            // "CustomerNo" : 12534,
            // "PaymentType": "2",
            // "DrivingType": 1,
            // "RevStartDt": "2020-12-16 00:00:00.000",
            // "RevEndDt" : "2020-12-16 02:30:00.000"
            const { cookies } = this.props;
            let tmpDate = this.state.date
            let tmpStartDate = this.state.startTime.toDate()
            let tmpEndDate = this.state.endTime.toDate()

            let tmpStartDateFormat = '';
            let tmpEndDateFormat = '';

            let testStart = Number(moment(tmpStartDate).format('YYYYMMDD'))                 // start date
            let testEnd = Number(moment(tmpEndDate).format('YYYYMMDD')); //end date1111111111

            const {
                startLocationAddress,
                startLocationLat,
                startLocationLng,
                endLocationAddress,
                endLocationLat,
                endLocationLng
            } = store.getState().LocationSelector_ReduxModule;

            // 아래 값에 변경이 이루어졌을때 계산중을 띄운다.
            if (this.state.moneyView !== 0 && this.state.hourView !== '일반 ') {
                this.setState({
                    moneyView: '(계산중...)',
                    hourView: '(계산중...)',
                })
            }

            // start date를 먼저 세팅해주고
            tmpStartDateFormat = plusSecondsMomentWithFormatCustom(
                tmpDate.getDate(),
                (tmpDate.getMonth() + 1),
                tmpDate.getFullYear(),
                tmpStartDate.getHours(),
                tmpStartDate.getMinutes(),
            )

            // 아래 코드를 넣는 이유 : 23:30 ~ 0:30 시간대일경우에는 0:30는 다음날로 처리해야하는데 당일로 처리하고 있어서
            // 계산 결과가 23시간 이렇게 나온다. 이 문제를 방지하고자 처리하는 것이다.
            // end가 start의 날짜를 넘어간다면 
            if (testStart < testEnd) {
                let tmpAfterDay = moment(this.state.date).add(1, 'days').toDate();

                tmpEndDateFormat = plusSecondsMomentWithFormatCustom(
                    tmpAfterDay.getDate(),
                    (tmpAfterDay.getMonth() + 1),
                    tmpAfterDay.getFullYear(),
                    tmpEndDate.getHours(),
                    tmpEndDate.getMinutes(),
                )
            } 
            
            else {
                tmpEndDateFormat = plusSecondsMomentWithFormatCustom(
                    tmpDate.getDate(),
                    (tmpDate.getMonth() + 1),
                    tmpDate.getFullYear(),
                    tmpEndDate.getHours(),
                    tmpEndDate.getMinutes(),
                )
            }
            

            // 시작시간이 종료시간보다 작다면(timestamp로 비교)
            if (tmpStartDate.getTime() < tmpEndDate.getTime()) {
                let tmpObj = {
                    CustomerNo: this.state.CustomerNo,
                    PaymentType: '2',
                    DrivingType: this.state.drivingType,
                    RevStartDt: tmpStartDateFormat,
                    RevEndDt: tmpEndDateFormat,
                    // CouponId: this.state.CouponId,
                    StartAddress: startLocationAddress,
                    StartLat: startLocationLat,
                    StartLng: startLocationLng,

                    EndAddress: endLocationAddress,
                    EndLat: endLocationLat,
                    EndLng: endLocationLng,
                }
                this.setState({ loading: 0 })	// 로딩중
                let result = await ReserveApi.CalcDrivingReservation(
                    tmpObj,
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                )
                if (result.Result === 'success') {
                    localStorage.setItem('reserve_temp_ReseveCharge', result.Item.ReseveCharge)

                    this.setState({
                        moneyView: result.Item.ReseveCharge,
                        hourView: result.Item.DrivingTimes,
                        loading: 1
                    })
                } else {
                    await calertPromise('금액을 계산 하지 못했습니다.')
                    this.setState({ loading: 1 })	// 로딩완료
                }

                console.log(result)

            } else {
                console.log('시작시간이 종료시간보다 클 수 없습니다.')
                this.setState({ loading: 1 })	// 로딩완료
            }
        } catch (e) {
            await calertPromise('금액 계산 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
            this.setState({ loading: 1 })	// 로딩완료
        }
    }

    
    handlePassengerCheckStatus = (e) => {
        let {loginedData} = store.getState().global_data

        this.setState({
            passengerCheckStatus: e.target.checked,
            passengerName: e.target.checked ? loginedData.CustomerName : '',
            passengerPhone:e.target.checked ? loginedData.PhoneNumber : ''
        })
    }

    handlePassengerName = (e) => {
        this.setState({
            passengerName: e.target.value
        })
    }

    handlePassengerPhone = (e) => {
        this.setState({
            passengerPhone: e.target.value
        })
    }

    handleNextBtnValidate = async () => {
        try {
            const {
                startTime,
                endTime,
                passengerName,
                passengerPhone,
                carLocation,
                comment,
                drivingType
            } = this.state;
            const {
                AirOptionState,
                
                stopOverList,
                endLocationAddress,
                endLocationLat,
                endLocationLng
            } = store.getState().LocationSelector_ReduxModule;
            let retData = true;

            // 출발지 체크
            if (AirOptionState === '0') {
                await calertPromise('공항을 선택해주세요.')
                return;
            }
            
            // 도착지 체크
            if (endLocationAddress.length === 0 || 
                endLocationLat === 0 ||
                endLocationLng === 0) {
                    await calertPromise(`${ReserveApi.getChangeStrJongryoji(this.state.reserveType)}를 입력해주세요.`)

                    
                return;
            }
            
            // 경유지 체크 (1개 이상 추가시 체크대상)
            if (stopOverList.length >= 1) {
                for (let i in stopOverList) {
                    console.log(stopOverList[i]);
                    if (
                        (stopOverList[i].waypointAddress.length === 0) ||
                        (stopOverList[i].waypointLat.length === 0) ||
                        (stopOverList[i].waypointLng.length === 0)
                    ) {
                        await calertPromise(`${Number(i)+1} 번째 경유지를 입력해주세요.`)
                        return;
                    }
                }
            }

            // 예약일(시작, 종료시간) 체크 (시작시간이 종료시간보다 더 크거나 같을때)
            if (startTime.toDate().getTime() >= endTime.toDate().getTime()) {
                await calertPromise('시작시간이나 종료시간을 확인해주세요.')
                return;
            }
            
            // 탑승자 정보 체크
            if (passengerName.length === 0 || passengerPhone.length === 0) {
                await calertPromise('탑승자정보 이름이나 전화번호를 입력해주세요.')
                return;
            }

            if (retData) {
                this.props.history.push('/reserve_confirm')
            } else {
                await calertPromise('입력 실패')
            }


        } catch(e){
            console.log(e);
        }
    }

    render() {

        const DatePickCustomComponent = ({ value, onClick }) => (
            <span className="list_sec1">
                <span>{value} </span>
                <button className="icon_calandar" onClick={onClick}>
                    <img src={icon_calendar} alt="" />
                </button>
            </span>
        );

        const StartTimeSelectCustomComponent = ({ value, onClick }) => (
            <span className="ts1" >시작시간 <b onClick={onClick}>{value}</b></span>
        );
    
        const EndTimeSelectCustomComponent = ({ value, onClick }) => (
            <span className="ts1" >종료시간 <b onClick={onClick}>{value}</b></span>
        );
    
        const filterPassedTime = time => {
            const currentDate = new Date();
            const selectedDate = new Date(time);
        
            return currentDate.getTime() < selectedDate.getTime();
        }

        const filterPassedEndTime = time => {
            // 날짜를 한번 선택하면 이 함수도 호출되나? yes
            let retData = false;
            let startTime = new Date(this.state.startTime);
            let endTime = new Date(this.state.endTime);
            let listVisibleTime = new Date(time);

            // 같은 날이라면 시작시간 이후의 시간을 선택 가능하도록 처리하고
            if (
                startTime.getFullYear() === endTime.getFullYear() &&
                startTime.getMonth() === endTime.getMonth() &&
                startTime.getDay() === endTime.getDay()
                ) {
                    retData = startTime.getTime() < listVisibleTime.getTime();
                    
            } 
            // 같은날의 다음날이라면 무조건 표시
            else {
                retData = true;
            }

            return retData;
        }
        
        const filterPassedDate = date => {
            // date : 화면상에 디스플레이될 날짜 목록
                                                    
            // 선택시 본 콜백이 발생하며 달력에 표시된 모든 데이터가 나온다.
            // 여기서 선택했을때 이전의 날짜는 선택 못하도록 해야한다.
            let currentDate = new Date();
            currentDate.setDate(currentDate.getDate()-1)    // 하루 전
            
            let selectedDate = new Date(date);              // 선택한 날짜

            // console.log(currentDate.getTime()) 
            return currentDate.getTime() <= selectedDate.getTime();
        }


        return (
            <>
            {!this.state.loading ? (<Loading />) : ''}
                <Header 
                    title={`공항 홈발렛`}
                    onBackButton={this.handleGotoBack}
                    onHomeButton={this.handleGotoBack}
                    />

                <div className="booking_box2 hd_margin_top">
                    <ul className={`bk_type1 bk_type_c1`}>
                        <li className="bk_head">
                            <span className="bk_title">공항 홈발렛
                            </span>
                            <span className="bk_info">
                                {ReserveApi.getDrivingTypeDescStr(this.props.match.params.state)}
                            </span>
                        </li>
                    </ul>


                    <ul className="bk_section">
                        <li className="section_box">
                            
                            <LocationSelectorAirContainer history={this.props.history} endPointStr={ReserveApi.getChangeStrJongryoji(this.state.reserveType)}/>
                            
                            <span className="list_notice">
                                <span className="list_notice_section">
                                    <span className="icon">
                                        <img src={icon_notice} className="icon_notice" alt="" />
                                    </span>
                                    <span className="text">계속해서 더 많은 경유지를 추가하실 경우, 요청사항에 메모해주세요.</span>
                                </span>
                                <span className="list_notice_section">
                                    <span className="icon">
                                        <img src={icon_notice} className="icon_notice" alt="" />
                                    </span>
                                    <span className="text">경유지는 최대 3개까지 등록하실 수 있습니다.</span>
                                </span>
                            </span>
                        </li>

                        <li className="section_box">
                            <span className="section_box_deatil">
                                <span className="section_title_booking">
                                    <span className="bline">예약일</span>
                                    <span className="right">
                                        예상소요시간 : 02시간 10분
                                    </span>
                                </span>
                                <TimeSelector2
                                    startTime={this.state.startTime}
                                    endTime={this.state.endTime}
                                    selectedDate={this.state.date}
                                    type={this.state.drivingType}
                                    onChangeStart={this.handleUpdateStartTimeGeneral}
                                    onChangeEnd={this.handleUpdateEndtime}
                                    onDateChange={this.handleSaveDate}
                                    fixedStartTime={this.state.fixedStartTime}
                                    hourView={this.changeHourView(this.state.hourView)}
                                />

                            </span>
                            <span className="list_notice">
                                 <span className="list_notice_section">
                                    <span className="icon">
                                        <img src={icon_notice} className="icon_notice" alt="" />
                                    </span>
                                    <span className="text">총 이동거리/시간을 고려하여 예상소요 시간을 측정하였으나, 기준거리/시간초과시 추가요금이 발생할수 있습니다.</span>
                                </span>
                            </span>
                        </li>

                        <li className="section_box_title">주차위치
                            {/* <a href="#" className="btn_modify">수정</a>  */}
                        </li>
                        <li className="section_box2">
                            <span className="section_box_deatil2">
                                <textarea 
                                    className="" 
                                    placeholder="주차할 위치를 입력해주세요."
                                    value={this.state.carLocation}
                                    onChange={this.handleCarLocation}
                                    >
                                 </textarea>
                                 {Util.getStringLength(this.state.carLocation)}/{TEXTAREA_MAX_LENGTH}
                                 {Util.getStringLength(this.state.carLocation) >= TEXTAREA_MAX_LENGTH ? (<b> (100자 이상 입력할 수 없습니다.)</b>) : ''}
                            </span>
                        </li>

                        <li className="section_box_title ">탑승자정보</li>
                        <li className="section_box2 section_box2_padding">
                            <span className="partner_info">
                                <span className="pti_section1">
                                    
                                    <label className="chk_box">
                                        <input type="checkbox" checked={this.state.passengerCheckStatus} onChange={this.handlePassengerCheckStatus}/>
                                        <span></span>
                                         고객정보와 동일(다른 경우 입력 및 수정)
                                    </label>

                                </span>
                                <span className="pti_section2">
                                    <span className="form">
                                        <span className="form_name">탑승자 이름</span>
                                        <input type="text" className="input_fm1" value={this.state.passengerName} onChange={this.handlePassengerName}/>
                                    </span>
                                    <span className="form">
                                        <span className="form_name">휴대전화번호</span>
                                        <input type="text" className="input_fm1" value={this.state.passengerPhone} onChange={this.handlePassengerPhone}/>
                                    </span>
                                </span>
                            </span>
                        </li>


                        <li className="section_box_title">요청사항 
                        {/* <NavLink to="#" className="btn_modify">수정</NavLink> */}
                        </li>
                        <li className="section_box2">
                            <span className="section_box_deatil2">
                                <textarea 
                                    className="" 
                                    placeholder="요청사항을 입력해주세요."
                                    value={this.state.comment}
                                    onChange={this.handleComment}>
                                </textarea>
                                {Util.getStringLength(this.state.comment)}/{TEXTAREA_MAX_LENGTH}
                                {Util.getStringLength(this.state.comment) >= TEXTAREA_MAX_LENGTH ? (<b> (100자 이상 입력할 수 없습니다.)</b>) : ''}
                            </span>
                        </li>
                    </ul>

                    <ul className="btn_fixed_box">
                        <span className="bk_infrom">
                            <span className="bki_f1">공항 홈발렛 {this.changeHourView(this.state.hourView)}</span>
                            <span className="bki_f2">이용예상금액 <b>{Util.numberWithCommas(this.state.moneyView)}원</b>
                            </span>
                        </span>
                        <span className="btn_box">
                            <button className="btn_st_2e303f" onClick={this.handleNextBtnValidate}>다음</button>
                        </span>
                    </ul>
                    {/* <span className="icon_chat"><img src={icon_chat} alt="" /></span> */}
                </div>
                
            </>
        );
    }
};

export default withCookies(ReserveAirView);
