import moment from 'moment';
import {calert} from '../../lib/Util'
import {getReserveAvaliableDate} from '../../lib/TimeApi'

// end 시간 등록

// 구현해야 할 것
// 1. 날짜 선택하게 되면
// 시작시간 종료시간 계산해서 선택 가능한 시간 계산해서 집어넣기
// 2. 시작시간 선택하게 되면 종료시간은 

// 초기화
// localStorage 데이터 존재하면 시간 세팅
// 화면 닫혀질때 localStorage에 저장
// time update 메소드
// getCalcDrivingReservation() 함수에 현재 시간 취득
// 시간 Validate

const RESERVE_TYPE_GENERAL_FLAG = 1
const RESERVE_TYPE_GOLF_FLAG = 3

const LIMIT_HOUR = 0;
const RESERVATION_BASE_TIME = 0;                   // 예약 기준 시간 (현재일로부터 29시간 이후로 시작/종료시간을 산정.)  devclient면 30시간(테스트용) hybclient는 29시간
const RESERVATION_GOLF_BASE_TIME = 10;              // 골프 기본 시간 (10시간)

const CLOSE_HOUR = 19;                              // 예약 종료 시간 19:00. 만약 18:00로 변경할 경우  18로 변경한다.


const INIT_TIME_ALL_SELECTOR = "TimePicker/INIT_TIME_ALL_SELECTOR";
const INIT_START_TIME_SELECTOR = "TimePicker/INIT_START_TIME_SELECTOR";
const INIT_END_TIME_SELECTOR = "TimePicker/INIT_END_TIME_SELECTOR";
const CHANGE_START_TIME = "TimePicker/CHANGE_START_TIME";
const CHANGE_END_TIME = "TimePicker/CHANGE_END_TIME";
const CHANGE_DATE = "TimePicker/CHANGE_DATE";
const CHANGE_ADD_TIME = "TimePicker/CHANGE_ADD_TIME";

export const init_time_all_selector_service = () => ({ type: INIT_TIME_ALL_SELECTOR });
export const init_time_start_selector_service = () => ({ type: INIT_START_TIME_SELECTOR });
export const init_time_end_selector_service = () => ({ type: INIT_END_TIME_SELECTOR });
export const change_start_time_service = (obj) => ({ type: CHANGE_START_TIME, payload:obj });
export const change_end_time_service = (obj) => ({ type: CHANGE_END_TIME, payload:obj });
export const change_date_service = (obj) => ({ type: CHANGE_DATE, payload:obj });
export const change_addtime_service = (obj) => ({ type: CHANGE_ADD_TIME, payload:obj });

console.log('TimeSelector')


const initialState = {
    startTime       : moment().add(RESERVATION_BASE_TIME, 'hours').startOf('days'),     // 
    endTime         : moment().add(RESERVATION_BASE_TIME, 'hours').startOf('days').add(1, 'hours'),                 // 
    date            : getReserveAvaliableDate(moment().toDate(), CLOSE_HOUR),            // 
    fixedStartTime  : moment(),                                                         // 
    addTime         : 0
    
}

export default (state = initialState, action) => {
    
    switch (action.type) {
        case INIT_TIME_ALL_SELECTOR:
            return {
                ...state,
                ...initialState
            };      

        case CHANGE_START_TIME:
            return {
                ...state,
                startTime: action.payload, // moment type
            };
        case CHANGE_END_TIME:
            return {
                ...state,
                endTime: action.payload,  // moment type
            };
        case CHANGE_DATE:
            return {
                ...state,
                date: action.payload,      // date type
            };
        case CHANGE_ADD_TIME:
            return {
                ...state,
                addTime: action.payload,      // integer type
            };
        default:
            return state;
    }
};




/*import moment from 'moment';
import {calert} from '../../lib/Util'

// end 시간 등록

// 구현해야 할 것
// 1. 날짜 선택하게 되면
// 시작시간 종료시간 계산해서 선택 가능한 시간 계산해서 집어넣기
// 2. 시작시간 선택하게 되면 종료시간은 

// 초기화
// localStorage 데이터 존재하면 시간 세팅
// 화면 닫혀질때 localStorage에 저장
// time update 메소드
// getCalcDrivingReservation() 함수에 현재 시간 취득
// 시간 Validate

const INIT_TIME_ALL_SELECTOR = "TimePicker/INIT_TIME_ALL_SELECTOR";
const INIT_START_TIME_SELECTOR = "TimePicker/INIT_START_TIME_SELECTOR";
const INIT_END_TIME_SELECTOR = "TimePicker/INIT_END_TIME_SELECTOR";
const CHANGE_START_TIME = "TimePicker/CHANGE_START_TIME";
const CHANGE_END_TIME = "TimePicker/CHANGE_END_TIME";

export const init_time_all_selector_service = () => ({ type: INIT_TIME_ALL_SELECTOR });
export const init_time_start_selector_service = () => ({ type: INIT_START_TIME_SELECTOR });
export const init_time_end_selector_service = () => ({ type: INIT_END_TIME_SELECTOR });
export const change_start_time_service = (obj) => ({ type: CHANGE_START_TIME, payload:{Hour:obj.hour, Minute:obj.minute } });
export const change_end_time_service = (obj) => ({ type: CHANGE_END_TIME, payload:{Hour:obj.hour, Minute:obj.minute } });
  
const initMoment = moment()

const initialState = {
    startTimeFormat:initMoment.format('HH:mm:00.000'),            //11:11:00.000
    startTimeValue:initMoment.format('A h:mm'),                   //AM 7:30
    startTimeId:'start',                                        // 시간 만들어주는 input 태그의 id값

    endTimeFormat:initMoment.format('HH:mm:00.000'),
    endTimeValue:initMoment.format('A h:mm'),
    endTimeId:'end',
}

export default (state = initialState, action) => {
    
    switch (action.type) {
        case INIT_TIME_ALL_SELECTOR:
            return {
                ...state,
                ...initialState
            };
        case INIT_START_TIME_SELECTOR:
            return {
                ...state,
                startTimeFormat: initMoment.format('HH:mm:00.000'),
                startTimeValue: initMoment.format('A h:mm'),
            };
        case INIT_END_TIME_SELECTOR:
            return {
                ...state,
                endTimeFormat: initMoment.format('HH:mm:00.000'),
                endTimeValue: initMoment.format('A h:mm'),
            };

        case CHANGE_START_TIME:
            // start 시간 등록
            // start 시간 등록시 end시간에는 start시간 + 1시간 더하기 로직 필요
            return {
                ...state,
                startTimeFormat: moment(`2020-12-25 ${action.payload.Hour}:${action.payload.Minute}`).format('HH:mm:00.000'),
                startTimeValue: moment(`2020-12-25 ${action.payload.Hour}:${action.payload.Minute}`).format('A h:mm'),

                endTimeFormat:moment(`2020-12-25 ${action.payload.Hour}:${action.payload.Minute}`).add(1, 'hours').format('HH:mm:00.000'),
                endTimeValue:moment(`2020-12-25 ${action.payload.Hour}:${action.payload.Minute}`).add(1, 'hours').format('A h:mm'),
            };
        case CHANGE_END_TIME:
            return {
                ...state,
                endTimeFormat: moment(`2020-12-25 ${action.payload.Hour}:${action.payload.Minute}`).format('HH:mm:00.000'),
                endTimeValue: moment(`2020-12-25 ${action.payload.Hour}:${action.payload.Minute}`).format('A h:mm'),
            };
        default:
            return state;
    }
};
*/
// arr.filter((data, idx)=>{console.log(data, idx); return this})
// arr.filter((data, i)=>(i !== idx))

/*
{
type: 'LocationSelector/STEP_OVER_ADD',
stopOverItem: '1'
}

{
type: 'LocationSelector/STEP_OVER_DEL',
stopOverDelItemIdx: '1'
}

{
type: 'LocationSelector/STEP_OVER_UPDATE',
stopOverUpdateData: 'test01', 
stopOverUpdateIdx: 1
}
*/

// 배열 저장하는 함수
// function updateObjectInArray(array, action) {
//     return array.map((item, index) => {
//       if (index !== action.stopOverUpdateIdx) {
//           console.log('index !== action.stopOverUpdateIdx result' + item);
//         // This isn't the item we care about - keep it as-is
//         return item
//       }
      
//       // Otherwise, this is the one we want - return an updated value
//       return action.stopOverUpdateData;
      
      
//       /*{
//         ...item,
//         ...action.stopOverUpdateData
//       }*/
//     })
//   }


// handleAddStopover = (e) => {
//     let tmpArr = this.state.stopOverList;
//     let tmpArrLen = this.state.stopOverList.length;

//     if (tmpArrLen >= LIMIT_STOPOVER_COUNT) {
//         alert('경유지는 3개까지 등록 가능합니다.');
//     } else {
//         tmpArr.push(1);
//         this.setState({
//             stopOverList: tmpArr
//         })
//     }
// }

// handleDelStopover = (e) => {
//     let tmpArr = [];
    
//     for (let i in this.state.stopOverList) {
//         if (e.target.id === i) continue;
//         else tmpArr.push(this.state.stopOverList[i]);
//     }

//     this.setState({
//         stopOverList: tmpArr
//     })
// }

