import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

.rc-time-picker {
    float:left;
}

.timeadd .rc-time-picker {
    float:right !important;
}


/*.rc-time-picker-input  { display:inline-block; border:0; width:48px; height:20px; padding:0; padding-bottom:3px; font-weight:bold; font-size:1.05em; text-align:center; }*/

/* [MosilerAPP-User]TestCheck_List.xlsx H10셀에 의해 수정 */
.rc-time-picker-input  {display: inline-block;border: 1px solid #d6d6d6;;width: 54px;padding: 0;font-weight:bold;color: #222;font-size: 14px;padding: 0 5px;border-radius: 2px;margin-left: 10px;padding-bottom: 0px;}
.rc-time-picker.disabled .rc-time-picker-input {color: #999;background: #f7f7f7;cursor: not-allowed;border: 1px solid #ededed;border-radius: 2px;}

.rc-time-picker-panel-narrow {
    max-width:200px;
}
.rc-time-picker-panel-select li {
    text-align:center !important;
    padding: 0
}

.rc-time-picker-panel-select:first-child {
    
    /* border-left: 0;
    margin-left: 0; */
    width:80px;
}

.rc-time-picker-panel-select:last-child {
    /* border-left: 0;
    margin-left: 0; */
    width:80px;
}

.rc-time-picker-panel-select::-webkit-scrollbar {
    width:5px;
}

.rc-time-picker-panel-select::-webkit-scrollbar-thumb {
    background-color: #2f3542;
    border-radius: 15px;
  }

.rc-time-picker-clear {
    display:none;
}
`;
