import React, { Component } from "react";

import { withLastLocation } from "react-router-last-location";
import { withCookies } from "react-cookie";
import Header from "../../../components/Common/Header";

import ReserveApi from "../../../lib/ReserveApi";

import RnInterface from "../../../lib/RnInterface";
import { getLocalStorage, setLocalStorage } from "../../../lib/Util";

import Loading from '../../../components/Common/Loading'

import "../../../css/default.css";
import "../../../css/booking.css";
import "react-datepicker/dist/react-datepicker.css";


/*
    * Reserve Confirm View에서 아래의 데이터를 넘겨야 한다.
    - localStorage name : reserve_view_all_state  
    - Property            
            date                    date: "2022-01-04T15:00:00.000Z"
            startTime               startTime: "2022-01-03T22:00:00.000Z"
            endTime                 endTime: "2022-01-04T05:20:55.939Z"
            reserveType             reserveType: 1
            startTimeString         startTimeString: "2022-01-05 07:00:00.000"
            endTimeString           endTimeString: "2022-01-05 10:00:00.000"
            carLocation             
            comment                 
            passengerName           
            passengerPhone          
            carNo                   
            carModel                
            covidKitStatus                     
*/


class ReserveStep4View extends Component {
    // 원 페이지로 가는게 좋을까.
    // 페이지마다 js로 만들어야 하나...
    // 우선 원페이지로 시도해볼까?

    state = {
        loading: 1,
        
        reserveType: 1,
        serviceEscort: false,
        serviceBaggage: false,
        serviceWheelchair: false,
    };

    async componentDidMount() {
        await RnInterface.AndroidURLChange(this.props.location.pathname)
        let reserveAllState = getLocalStorage('reserve_view_all_state');       

        this.setState({
            reserveType: Number(this.props.match.params.state),
            serviceEscort: (reserveAllState !== null) ? reserveAllState.serviceEscort : false,
            serviceBaggage: (reserveAllState !== null) ? reserveAllState.serviceBaggage : false,
            serviceWheelchair: (reserveAllState !== null) ? reserveAllState.serviceWheelchair : false,
        });

        

    }

    handleGotoBack = async () => {
        this.props.history.goBack();
    };
    handleGotoHome = async () => {
        this.props.history.replace('/main');
        this.props.history.push('/main');
    };

    setStateOnce = (key, val) => {
        return new Promise((resolve) => {
            this.setState({
                [key]:val
            }, ()=>{
                resolve(true)
            }) 
        })
    }

    handleChangeCheckbox = async (e, key) => {
        await this.setStateOnce(key, e.target.checked)
    }


    handleStep4ValidateBtn = async () => {
        const {
            serviceEscort,
            serviceBaggage,
            serviceWheelchair,
        } = this.state;

        let reserveAllState = getLocalStorage('reserve_view_all_state');
        if (reserveAllState !== null) {
            
            let commentAdd2 = ''
            if (!serviceEscort && !serviceBaggage && !serviceWheelchair) {
                commentAdd2 = `${`**추가서비스**\n없음`}`
            }
            else {
                commentAdd2 = `${`**추가서비스**\n`}${serviceEscort ? `에스코트\n` : ''}${serviceBaggage ? `짐들어주기\n` : ''}${serviceWheelchair ? `휠체어끌기\n` : ''}\n`
            }            

            reserveAllState = {
                ...reserveAllState,
                commentAdd2,
                serviceEscort,
                serviceBaggage,
                serviceWheelchair,
            }

            

            setLocalStorage('reserve_view_all_state',reserveAllState)
            
        } 
        this.props.history.push(`/reserve_renew_ski/${this.state.reserveType}/5`)
        // 애널리틱스
    }


    render() {

        return (
            <>
            {!this.state.loading ? (<Loading />) : ''}
                <Header
                    title={`${ReserveApi.getDrivingTypeStr(this.state.reserveType)} 예약 (4/5)`}
                    onBackButton={this.handleGotoBack}
                    onHomeButton={this.handleGotoHome}
                />

                <div className="booking_box2 hd_margin_top">
                    <div className="reserve-list">
                        <div className="search-box">
                            <h1 className='h1' style={{paddingBottom:'10px'}}>추가 서비스<i className="ico required2"><span className="blind">필수입력항목</span></i></h1>

                            <div className="grid cross-center flex-between"  style={{fontSize:'1.1em', paddingBottom:'5px'}}>   
                                <div className="col">
                                    에스코트
                                </div>
            
                                <div className="col right">
                                    <span className="check-box round2">
                                        <input type="checkbox" id="passenger" checked={this.state.serviceEscort} onChange={(e)=>this.handleChangeCheckbox(e,'serviceEscort')} />
                                    </span>
                                </div>
                            </div>

                            <div className="grid cross-center flex-between"  style={{fontSize:'1.1em', paddingBottom:'5px'}}>   
                                <div className="col">
                                    짐들어주기
                                </div>
            
                                <div className="col right">
                                    <span className="check-box round2">
                                        <input type="checkbox" id="passenger" checked={this.state.serviceBaggage} onChange={(e)=>this.handleChangeCheckbox(e,'serviceBaggage')} />
                                    </span>
                                </div>
                            </div>

                            <div className="grid cross-center flex-between"  style={{fontSize:'1.1em', paddingBottom:'5px'}}>   
                                <div className="col">
                                    휠체어끌기
                                </div>
            
                                <div className="col right">
                                    <span className="check-box round2">
                                        <input type="checkbox" id="passenger" checked={this.state.serviceWheelchair} onChange={(e)=>this.handleChangeCheckbox(e,'serviceWheelchair')} />
                                    </span>
                                </div>
                            </div>
                            

                        </div>
                    </div>

                    <div className="component-wrap">
                        <div class="btn-area btn-group-fixed horizontal">
                            <button
                                type="button"
                                className="btn large secondary"
                                onClick={this.handleStep4ValidateBtn}
                            >
                                <span>다음</span>
                            </button>
                        </div>
                    </div>
                </div>


                
            </>
        );
    }
}

export default withLastLocation(withCookies(ReserveStep4View));
