import React, { Component } from 'react';
import { createGlobalStyle } from "styled-components";
import store from '../../reduxStore'
// import axios from 'axios';
// import { Token } from '../../constantData'
import { withCookies } from 'react-cookie'
import AccountApi from '../../lib/AccountApi'
import Loading from '../../components/Common/Loading'
import {calert, calertPromise} from '../../lib/Util'
import btn_cancel from "../../img/btn/btn_cancel.svg"
import RnInterface from '../../lib/RnInterface'
import HeaderJoin from '../../components/Common/HeaderJoin'
import HeaderJoinCancel from '../../components/Common/HeaderJoinCancel'

import icon_arrow_down from '../../img/mypage/icon_arrow_down.svg'

const GlobalStyle = createGlobalStyle`

body {background-color: #f3f4f8;}

`;
// import '../css/default.css';
// import '../css/member.css';

//view_~~~ 로 시작하는 파일은 html inline에 style로 정해놓은 것을 css화 시킨 것이다.
// import '../css/view_login.css';

// import btn_prev from '../../img/btn/btn_prev.svg';


/*

검색 : class=
치환 : className=

검색 : br
치환 : br/

검색 : <input.*[^>]
치환 : $0/

검색 : <img.*[^>]
치환 : $0 alt=""/
*/

const CREATE_FLAG = 0;
const MODIFY_FLAG = 1;


class FirstStepCarInfo2View extends Component {
    state = {
        Tb_partn_pref_seq: '',
        stateDrvCarer: '무관',     // 경력
        stateDrvAge: '무관',     // 나이
        stateDrvSex: '무관',     // 성별
        stateDrvSmokYn: '무관',     // 흡연 여부
        stateDrvMariTyYn: '무관',     // 결혼 여부
        stateDrvLang: '무관',     // 언어
        stateDrvCertType: '무관',     // 자격증
        stateDrvHobby: '무관',     // 취미
        userInfo: store.getState().global_data,
        stateflag : CREATE_FLAG,
        initflag: false,                // 최초 가입 후 추가정보 입력하겠냐는 화면에서 skip하면 false 정보를 입력하면 true
        loading:0
    }

    async componentDidMount() {
        try {
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            const {flag} = this.props.match.params;
            if (Number(flag) === MODIFY_FLAG) {
                let { CustomerNo } = store.getState().global_data.loginedData;
                let {cookies} = this.props;

                let getPartnPrefResult = await AccountApi.Get_partn_pref(
                    CustomerNo,
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                )
                if (getPartnPrefResult.Result === 'success') {
                    this.setState({
                        stateflag: MODIFY_FLAG,
                        Tb_partn_pref_seq: getPartnPrefResult.Item.Tb_partn_pref_seq,
                        stateDrvCarer: getPartnPrefResult.Item.DrvCarer,
                        stateDrvAge: getPartnPrefResult.Item.DrvAge,
                        stateDrvSex: getPartnPrefResult.Item.DrvSex,
                        stateDrvSmokYn: getPartnPrefResult.Item.DrvSmokYn,
                        stateDrvMariTyYn: getPartnPrefResult.Item.DrvMariTyYn,
                        stateDrvLang: getPartnPrefResult.Item.DrvLang,
                        stateDrvCertType: getPartnPrefResult.Item.DrvCertType,
                        stateDrvHobby: getPartnPrefResult.Item.DrvHobby,
                        loading:1,
                        initflag:true
                    })
                } else {
                    this.setState({
                        loading:1,
                        initflag:false,
                    })
                }
                
            } else {
                this.setState({
                    loading:1
                })
            }
        } catch(e) {
            alert(JSON.stringify(e))
        }
        
        
    }

    handleClick = async () => {
        this.setState({loading:0})
        let formData = {  
            CustDrvrType: "01",                         // 고정인듯.
            CustomerNo: this.state.userInfo.loginedData.CustomerNo,
            DriverNo: "",                               // insert시에도 update시에도 공백이던데 
            DrvCarer: this.state.stateDrvCarer,
            DrvAge: this.state.stateDrvAge,
            DrvSex: this.state.stateDrvSex,
            DrvSmokYn: this.state.stateDrvSmokYn,
            DrvMariTyYn: this.state.stateDrvMariTyYn,
            DrvLang: this.state.stateDrvLang,
            DrvCertType: this.state.stateDrvCertType,
            DrvHobby:this.state.stateDrvHobby,
            RegUserNo: this.state.userInfo.loginedData.CustomerNo    
        }

        const {flag} = this.props.match.params;

        if ((Number(flag) === CREATE_FLAG) || (this.state.initflag === false)) {
            formData.Tb_partn_pref_seq = ""                      // 업데이트시에 /Get_partn_pref로 얻어온 Tb_partn_pref_seq를 참고한다. 아마도 ID인듯..
        } else {
            formData.Tb_partn_pref_seq = this.state.Tb_partn_pref_seq;
        }       

        let {cookies} = this.props;
        let partnerPreferenceResult = {};
        
        if (Number(flag) === CREATE_FLAG || (this.state.initflag === false)) {
            partnerPreferenceResult = await AccountApi.add_partn_pref(
                formData,
                cookies.get('ostype'),
                cookies.get('deviceid'),
                cookies.get('login_encrypt')
            );
        } else {
            partnerPreferenceResult = await AccountApi.update_partn_pref(
                formData,
                cookies.get('ostype'),
                cookies.get('deviceid'),
                cookies.get('login_encrypt')
            );
        }       
        
        if (partnerPreferenceResult.Result === 'success') {           
            await calertPromise('설정이 완료되었습니다.')
            if (Number(flag) === CREATE_FLAG) {
                this.props.history.replace('/main')
            } else {
                this.props.history.goBack()
            }
            
        } 
        else {
            await calertPromise('설정하지 못했습니다.')
            this.setState({loading:1})
        }
    }

        
    handleAfter = () => {
        const {flag} = this.props.match.params;
        if (Number(flag) === CREATE_FLAG) {
            this.props.history.replace('/main')
        } else {
            this.props.history.goBack()
        }
    }




    handleChangeStateDrvCarer = (e) => { this.setState({ stateDrvCarer: e.target.value }) }
    handleChangeStateDrvAge = (e) => { this.setState({ stateDrvAge: e.target.value }) }
    handleChangeStateDrvSex = (e) => { this.setState({ stateDrvSex: e.target.value }) }
    handleChangeStateDrvSmokYn = (e) => { this.setState({ stateDrvSmokYn: e.target.value }) }
    handleChangeStateDrvMariTyYn = (e) => { this.setState({ stateDrvMariTyYn: e.target.value }) }
    handleChangeStateDrvLang = (e) => { this.setState({ stateDrvLang: e.target.value }) }
    handleChangeStateDrvCertType = (e) => { this.setState({ stateDrvCertType: e.target.value }) }
    handleChangeStateDrvHobby = (e) => { this.setState({ stateDrvHobby: e.target.value }) }

    render() {
        const { flag } = this.props.match.params;
        const customSelectArrowDown = 
            {position:'absolute', display:'inline-block', width:'12px',right:'15px', top:'22px'}
        
        return (
            <>
                <GlobalStyle />
                {!this.state.loading ? (<Loading />) : ''}
                {Number(flag) === MODIFY_FLAG ? (
                    <HeaderJoin
                        onBackButton={this.handleAfter}
                        title={'선호하는 파트너 변경'}
                    />
                ) : (
                        <HeaderJoinCancel
                            onBackButton={this.handleAfter}
                            title={'선호하는 파트너 정보'}
                        />
                    )}
                
                <main id="Contents">
                    <div className="component-wrap inner-container">  
                        {/* 운전경력 */}
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">운전경력</label>									
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <select className="select type1" value={this.state.stateDrvCarer} onChange={this.handleChangeStateDrvCarer}>
                                            <option value="0">선택하세요</option>
                                            <option value="무관">무관</option>
                                            <option value="1년">1년</option>
                                            <option value="2년">2년</option>
                                            <option value="3년">3년</option>
                                            <option value="5년">5년</option>
                                            <option value="7년">7년</option>
                                            <option value="10년">10년</option>
                                            <option value="10년이상">10년이상</option>
                                            <option value="20년이상">20년이상</option>
                                        </select>
                                    </div>
                                </div>	
                            </div>                                               
                        </div> 
                        {/* 나이 */} 
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">나이</label>									
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <select className="select type1" value={this.state.stateDrvAge} onChange={this.handleChangeStateDrvAge}>
                                            <option value="0">선택하세요</option>
                                            <option value="무관">무관</option>
                                            <option value="10대">10대</option>
                                            <option value="20대">20대</option>
                                            <option value="30대">30대</option>
                                            <option value="40대">40대</option>
                                            <option value="50대">50대</option>
                                            <option value="60대">60대</option>
                                        </select>
                                    </div>
                                </div>	
                            </div>                                               
                        </div>                         
                        {/* 성별 */} 
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">성별</label>									
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible grid gut-guide">
                                        <div className="col col-3">
                                            <button type="button" className={this.state.stateDrvSex === '무관' ? 'btn check2 is-selected' : 'btn check2'}  value="무관" onClick={this.handleChangeStateDrvSex}>무관</button>
                                        </div>
                                        <div className="col col-3">
                                            <button type="button" className={this.state.stateDrvSex === '남성' ? 'btn check2 is-selected' : 'btn check2'}  value="남성" onClick={this.handleChangeStateDrvSex}>남성</button>
                                        </div>
                                        <div className="col col-3">
                                            <button type="button" className={this.state.stateDrvSex === '여성' ? 'btn check2 is-selected' : 'btn check2'}  value="여성" onClick={this.handleChangeStateDrvSex}>여성</button>
                                        </div>																																	
                                    </div>
                                </div>	
                            </div>                                               
                        </div>                         
                        {/* 흡연 */}
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">흡연</label>									
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible grid gut-guide">
                                        <div className="col col-3">
                                            <button type="button" className={this.state.stateDrvSmokYn === '무관' ? 'btn check2 is-selected' : 'btn check2'} value="무관" onClick={this.handleChangeStateDrvSmokYn} >무관</button>
                                        </div>
                                        <div className="col col-3">
                                            <button type="button" className={this.state.stateDrvSmokYn === '비흡연' ? 'btn check2 is-selected' : 'btn check2'} value="비흡연" onClick={this.handleChangeStateDrvSmokYn} >비흡연</button>
                                        </div>
                                        <div className="col col-3">
                                            <button type="button" className={this.state.stateDrvSmokYn === '흡연' ? 'btn check2 is-selected' : 'btn check2'} value="흡연" onClick={this.handleChangeStateDrvSmokYn} >흡연</button>
                                        </div>																															
                                    </div>
                                </div>	
                            </div>                                               
                        </div>                           
                        {/* 결혼 */}  
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">결혼</label>									
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible grid gut-guide">
                                        <div className="col col-3">
                                            <button type="button" className={this.state.stateDrvMariTyYn === '무관' ? 'btn check2 is-selected' : 'btn check2'}  value="무관" onClick={this.handleChangeStateDrvMariTyYn} >무관</button>
                                        </div>
                                        <div className="col col-3">
                                            <button type="button" className={this.state.stateDrvMariTyYn === '미혼' ? 'btn check2 is-selected' : 'btn check2'}  value="미혼" onClick={this.handleChangeStateDrvMariTyYn} >미혼</button>
                                        </div>
                                        <div className="col col-3">
                                            <button type="button" className={this.state.stateDrvMariTyYn === '기혼' ? 'btn check2 is-selected' : 'btn check2'}  value="기혼" onClick={this.handleChangeStateDrvMariTyYn} >기혼</button>
                                        </div>																													
                                    </div>
                                </div>	
                            </div>                                               
                        </div>                          
                        {/* 언어 */} 
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">언어</label>									
                                </div>
                                <div className="form-cont">
                                    <select className="select type1" value={this.state.stateDrvLang} onChange={this.handleChangeStateDrvLang}>
                                        <option value="0">선택하세요</option>
                                        <option value="무관">무관</option>
                                        <option value="한국">한국</option>
                                        <option value="영어">영어</option>
                                        <option value="불어">불어</option>
                                        <option value="스페인">스페인</option>
                                        <option value="중국">중국</option>
                                        <option value="러시아">러시아</option>
                                        <option value="일본">일본</option>
                                        <option value="기타">기타</option>
                                    </select>
                                </div>	
                            </div>                                               
                        </div>                         
                        {/* 자격증 */}  
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">자격증</label>									
                                </div>
                                <div className="form-cont">
                                    <select className="select type1" value={this.state.stateDrvCertType} onChange={this.handleChangeStateDrvCertType}>
                                        <option value="0">선택하세요</option>
                                        <option value="무관">무관</option>
                                        <option value="문화,예술,방송">문화,예술,방송</option>
                                        <option value="스포츠">스포츠</option>
                                        <option value="정보통신">정보통신</option>
                                        <option value="보건의료">보건의료</option>
                                        <option value="사회복지">사회복지</option>
                                        <option value="식품,요리">식품,요리</option>
                                        <option value="환경">환경</option>
                                        <option value="공업">공업</option>
                                        <option value="농어업">농어업</option>
                                        <option value="기타">기타</option>
                                    </select>
                                </div>	
                            </div>                                               
                        </div>                          
                        {/* 취미 */} 
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">취미</label>									
                                </div>
                                <div className="form-cont">
                                    <select className="select type1" value={this.state.stateDrvHobby} onChange={this.handleChangeStateDrvHobby}>
                                        <option value="0">선택하세요</option>
                                        <option value="무관">무관</option>
                                        <option value="게임">게임</option>
                                        <option value="미술">미술</option>
                                        <option value="스포츠">스포츠</option>
                                        <option value="동물">동물</option>
                                        <option value="악기">악기</option>
                                        <option value="여행">여행</option>
                                        <option value="요리">요리</option>
                                        <option value="언어">언어</option>
                                        <option value="기타">기타</option>
                                    </select>
                                </div>	
                            </div>                                               
                        </div>                                        
                    </div>  
                    <div className="component-wrap inner-container">
                        <div className="btn-area horizontal">
                            <button type="button" className="btn large secondary liner text-bold"  onClick={this.handleAfter}><span>나중에</span></button>
                            <button type="button" className="btn large secondary text-bold" onClick={this.handleClick}><span>저장</span></button>
                        </div>
                    </div>                    
                </main>  
            </>
        );
    }
};

export default withCookies(FirstStepCarInfo2View);



/*
const drvcarer = [
  {label:'무관', value:'무관'},
  {label:'1년', value:'1년'},
  {label:'2년', value:'2년'},
  {label:'3년', value:'3년'},
  {label:'5년', value:'5년'},
  {label:'7년', value:'7년'},
  {label:'10년', value:'10년'},
  {label:'10년이상', value:'10년이상'},
  {label:'20년이상', value:'20년이상'}
];
const drvage = [
  {label:'무관', value:'무관'},{label:'10대', value:'10대'},{label:'20대', value:'20대'},{label:'30대', value:'30대'},{label:'40대', value:'40대'},
  {label:'50대', value:'50대'},{label:'60대', value:'60대'}
];
const drvlang = [
  {label:'무관', value:'무관'},
  {label:'한국', value:'한국'},
  {label:'영어', value:'영어'},
  {label:'불어', value:'불어'},
  {label:'스페인', value:'스페인'},
  {label:'중국', value:'중국'},
  {label:'러시아', value:'러시아'},
  {label:'일본', value:'일본'},
  {label:'기타', value:'기타'}
];
const drvcerttype = [
  {label:'무관', value:'무관'},
  {label:'문화,예술,방송', value:'문화,예술,방송'},
  {label:'스포츠', value:'스포츠'},
  {label:'정보통신', value:'정보통신'},
  {label:'보건의료', value:'보건의료'},
  {label:'사회복지', value:'사회복지'},
  {label:'식품,요리', value:'식품,요리'},
  {label:'환경', value:'환경'},
  {label:'공업', value:'공업'},
  {label:'농어업', value:'농어업'},
  {label:'기타', value:'기타'}
];
const drvhobby = [
  {label:'무관', value:'무관'},
  {label:'게임', value:'게임'},
  {label:'미술', value:'미술'},
  {label:'스포츠', value:'스포츠'},
  {label:'동물', value:'동물'},
  {label:'악기', value:'악기'},
  {label:'여행', value:'여행'},
  {label:'요리', value:'요리'},
  {label:'언어', value:'언어'},
  {label:'기타', value:'기타'}
];
const drvsex = [
  {label:'무관', value:'무관'},{label:'남자', value:'남자'},{label:'여자', value:'여자'}
];
const drvsmok = [
  {label:'무관', value:'무관'},{label:'비흡연', value:'비흡연'},{label:'흡연', value:'흡연'}
];
const drvmari = [
  {label:'무관', value:'무관'},{label:'결혼', value:'결혼'},{label:'미혼', value:'미혼'}
];


this.savePartnrPrefer(stag, sbDrvcarer, sbDrvage, sbDrvlang, sbDrvcerttype, sbDrvhobby, txSex, txSmokYn, txMariTyYn);


savePartnrPrefer = async (stag, sbDrvcarer, sbDrvage, sbDrvlang, sbDrvcerttype, sbDrvhobby, txSex, txSmokYn, txMariTyYn ) => {
    store.dispatch(AppStateActions.startLoading());
    const deviceId = DeviceInfo.getUniqueID();
    const osType = DeviceInfo.getSystemName();
    instance.defaults.headers.common["OsType"] = (osType === "Android" ? "A" : "I");
    instance.defaults.headers.common["DeviceId"] = deviceId;
    instance.defaults.headers.common["Token"] = "4D006D0B3FF2019759A6FD719AE8B291E2B2A4935F0CC42D72534C494C95AAFD";
    // Get user info from local
    const userInfoTemp = await AsyncStorage.getItem(StorageLocal.UserInfo);
    const userInfo = JSON.parse(userInfoTemp);
    instance.defaults.headers.common["Authorize"] = userInfo.LoginEncrypt;
    const iseq = (stag === "U" ? ppData.Tb_partn_pref_seq : "")
    // call api
    var data = {
      Tb_partn_pref_seq: iseq,      // 업데이트시에 /Get_partn_pref로 얻어온 Tb_partn_pref_seq를 참고한다. 아마도 ID인듯..
      CustDrvrType: "01",           // 고정인듯.
      CustomerNo: userInfo.CustomerNo,
      DriverNo: "",
      DrvCarer: sbDrvcarer,
      DrvAge: sbDrvage,
      DrvSex: txSex,
      DrvSmokYn: txSmokYn,
      DrvMariTyYn: txMariTyYn,
      DrvLang: sbDrvlang,
      DrvCertType: sbDrvcerttype,
      DrvHobby: sbDrvhobby,
      RegUserNo: userInfo.CustomerNo
    };
    console.log("stag:", stag);
    var response = null;
    if (stag === "I") {
      response = await instance.post(`/add_partn_pref`, data);
    } else {
      response = await instance.post(`/update_partn_pref`, data);
    }
    console.log(response);
    // Stop Indicator
    store.dispatch(AppStateActions.stopLoading());
    // Success
    if (response.data && response.data.Result === "success") {
      var callback = this.props.navigation.getParam("callback");
      callback && callback();
      this.props.navigation.pop();
      showMessage({
        message: response.data.Message,
        type: "success"
      });
    } else {
      showMessage({
        message: response.data.Message,
        type: "danger"
      });
    }
  };
*/