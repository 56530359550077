import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
.member_form {width:100%; min-height: calc(100vh - 120px);}
.member_form ul { display:inline-block; width:100%;}
.member_form li {}
.member_form ul.ma_box { padding:5px 20px 20px 20px; background: #f3f4f8; height:100px;}
.member_form li.ma_title {font-size:1.5em; letter-spacing:-1px; line-height:30px;}
.member_form ul.ma_box2 { padding:20px; }

.member_form li.info_form { display:inline-block; width:100%; margin:0 0 0 0;}

footer { bottom:20px; padding:0 20px; text-align:center;}
`;