import React, { Component } from 'react';
import IntroCss from '../../css/CustomGlobalStyle/Intro'
import { withCookies } from 'react-cookie'
import InviteApi from '../../lib/InviteApi'
import store from '../../reduxStore'
import Header from '../../components/Common/Header'
import Loading from '../../components/Common/Loading'
import Util, { calertPromise, calertPromiseCustomMsg } from '../../lib/Util'
import RnInterface from '../../lib/RnInterface'
import InviteCss from '../../css/CustomGlobalStyle/Invite'

import '../../css/default.css';
import '../../css/member.css';

import logo_w from '../../img/invite/logo_w.svg'
class InviteView extends Component {

    state = {
        inviteData: {},
        inviteHistoryData: [],
        senderName:'',
        loading: 0
    }
    async componentDidMount() {
        try {
            this.setState({
                
                loading: 0
            })
            const { cookies } = this.props;
            const { CustomerNo, CustomerName } = store.getState().global_data.loginedData;

            await RnInterface.AndroidURLChange(this.props.location.pathname)

            let getShareCodeResult = await InviteApi.GetShareCode(
                CustomerNo,
                cookies.get('ostype'),
                cookies.get('deviceid'),
                cookies.get('login_encrypt')
            )
            
            let getShareCodeHistoryResult = await InviteApi.GetShareCodeHistory(
                CustomerNo,
                cookies.get('ostype'),
                cookies.get('deviceid'),
                cookies.get('login_encrypt')
            )

            if (
                (getShareCodeResult.Item && getShareCodeResult.Result === 'success') &&
                (getShareCodeHistoryResult.Item && getShareCodeHistoryResult.Result === 'success')
            ) {
                this.setState({
                    inviteData: getShareCodeResult.Item,
                    inviteHistoryData: getShareCodeHistoryResult.Item,
                    senderName: CustomerName,
                    loading: 1
                })
            }
            else {
                await calertPromise('데이터를 불러오지 못했습니다.')
                this.setState({
                    loading: 1
                })
            }
        } catch (e) {
            await calertPromise('데이터 취득 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
            this.setState({
                loading: 1
            })
        }
    }

    handleHome = async () => {
        this.props.history.replace('/main')    
    }
    handleBack = async () => {
        this.props.history.goBack();
    }

    handleShare = async () => {
        const { inviteData, name } = this.state;
        const { CustomerNo, CustomerName } = store.getState().global_data.loginedData;
        const { cookies } = this.props;
        let tmpUrl = `http://invite.mosiler.com/?sharecode=${inviteData.Code}&senderName=${CustomerName}`;
        let shareOptions = {
            message: `Mosiler App : ${tmpUrl}`,
            // url: `http://invite.mosiler.com/?sharecode=${inviteData.Code}&senderName=${CustomerName}`,
            //url: "https://play.google.com/store/apps/details?id=com.mosiler.client",
            // subject: "Mosiler" //  for email
        };
        let formData = {
            Code: inviteData.Code,
            CustomerNo: CustomerNo
        }
        try {
            this.setState({loading:0})
            let setShareResult = await InviteApi.SetShareCode(
                formData,
                cookies.get('ostype'),
                cookies.get('deviceid'),
                cookies.get('login_encrypt')
            )

            if (setShareResult.Result === 'success') {
                await RnInterface.callReactNative(`shareInviteCode${JSON.stringify(shareOptions)}`)
                setTimeout(async ()=>{
                    this.setState({loading:1})
                },3000)
                
                // let data = await RnInterface.getData('shareInviteCode')

                
            } 
            else {
                await calertPromise('공유하지 못했습니다. 잠시후 다시 시도해주세요.')
                this.setState({loading:1})
            }
        } 
        catch(e) {
            console.log(e);
            await calertPromise('공유중 오류가 발생했습니다. 관리자에게 문의해주세요.')
            this.setState({loading:1})
        }
        
    }
    

    /*
    동작흐름
    0. 버튼 클릭시
    1. SetShareCode 실행 (as-is postSetShareCodeRequest 함수)
    2. Link 생성
    3. Native쪽으로 Link보냄
    4. Native쪽은 share 함수를 통해 native 기능을 불러온다.
    */

    render() {
        const { inviteData, loading } = this.state;
        return (
            <>
                <InviteCss/>
                <Header
                    title="친구초대"
                    onBackButton={this.handleBack}
                    onHomeButton={this.handleHome}
                />

                {!loading ? <Loading /> : ''}

                <div class="invite_form hd_margin_top">
                    <ul class="ma_box">
                        <li class="info_form">

                            <span class="invite_box">
                                <span class="name">
                                    나만의 추천코드를<br />
                                친구와 공유하세요.
                            </span>
                                <span class="code_box">
                                    <span class="box_s1">
                                        <span class="logo"><img src={logo_w} alt="" /></span>
                                        <span class="title">추천코드</span>
                                        <span class="code">{inviteData.Code}</span>
                                    </span>
                                    
                                </span>
                            </span>

                        </li>

                        {inviteData.Code && inviteData.Code !== '???' && inviteData.SharedTimes < inviteData.MaxShareTimes ? (
                            <li class="btn_box">
                                <button class="btn_st_2e303f" onClick={this.handleShare}>내 추천코드 복사 및 링크 공유</button>
                            </li>
                        ):null}
                        

                    </ul>

                </div>
            </>
        );
    }
};

export default withCookies(InviteView);
