import React, { Component } from 'react';

import AccountApi from '../../lib/AccountApi'
import { withCookies } from 'react-cookie';
import {global_data_async} from '../../modules/Global/global_data'
import CookieApi from '../../lib/CookieApi'
import store from '../../reduxStore'
// import Loading from '../../components/Common/Loading'
import {calert, calertPromise, getLocalStorage} from '../../lib/Util'
import RnInterface from '../../lib/RnInterface'
import MailApi from '../../lib/MailApi';
import MemberJoinComplateCss from '../../css/CustomGlobalStyle/MemberJoinComplate'
import Loading from '../../components/Common/Loading'
import { Link } from 'react-router-dom';
// import constData from '../constData';
// import btn_prev from '../img/btn/btn_prev.svg';

import icon_check from "../../img/login/icon_check.svg";

/*

검색 : <input.*[^>]
치환 : $0/

검색 : <img.*[^>]
치환 : $0 alt=""/
*/


// 자동로그인을 눌렀을때의 처리
// 1. Login실시
// 2. 로그인 성공했다면 Login정보를 받아와서 Cookie 세팅 실시
// 3. /first/1 화면으로 이동

class MemberJoinComplateView extends Component {

    state = {
        id: '',
        hash: '',
        accountType: '',
        loading:0,
        ostype:'',
        deviceid:'',
    }

    async componentDidMount() {
        let tmpId = localStorage.getItem('c_emailid');
        let tmpHash = localStorage.getItem('c_password')
        let tmpAccountType = localStorage.getItem('AccountType');
        let tmpOsType = localStorage.getItem('osType');
        let tmpDeviceId = localStorage.getItem('deviceId');

        try {
            // calert(await RnInterface.getData('deviceinfo'));
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            
            // 메일 전송 실패했을 때에도 동작에는 문제가 없어야 하므로
            // 그냥 넘긴다.
            // let mailResult = await MailApi.sendMail({
            //     "name":localStorage.getItem('name'),
            //     "email": localStorage.getItem('emailaddress') === null ? localStorage.getItem('c_emailid') : localStorage.getItem('emailaddress')
            // })
        }
        catch (e) {
            console.log(JSON.stringify(e));
        }

        this.setState({
            id: tmpId !== null ? tmpId : '',
            hash: tmpHash !== null ? tmpHash : '',
            accountType: tmpAccountType  !== null ? tmpAccountType : '',
            ostype:tmpOsType  !== null ? tmpOsType : '',
            deviceid:tmpDeviceId  !== null ? tmpDeviceId : '',
            loading:1,
        })

    }

    handleLoginClick = async () =>{
        try {
            
            this.setState({loading:0})	// 로딩중
            let {cookies} = this.props           
            // id, pw validate chk
    
            let resultData = await AccountApi.requestLogin(this.state.id, this.state.hash, this.state.accountType, this.state.ostype, this.state.deviceid)
            if (resultData.Result === 'success') {
                //resultData.Item
                
            
                await store.dispatch(global_data_async(resultData.Item));
            
                // localStorage에 갱신
                localStorage.setItem('mosiler_cookie', JSON.stringify({
                    account_type: resultData.Item.AccountType,
                    deviceid: resultData.Item.DeviceId,
                    email_id: resultData.Item.UserId,
                    login_encrypt: resultData.Item.LoginEncrypt,
                    ostype: resultData.Item.OsType,
                    auto_login_chk: 1,
                    hashcode: this.state.hash,
                }))
            
                CookieApi.SettingCookie(cookies, {
                    UserId : resultData.Item.UserId,
                    AccountType : resultData.Item.AccountType,
                    hash : this.state.hash,
                    LoginEncrypt : resultData.Item.LoginEncrypt,
                    OsType : resultData.Item.OsType,
                    DeviceId : resultData.Item.DeviceId
                }, 1)
            
                // 로그인까지 성공하고 쿠키 세팅도 끝났다면 localstorage의 정보를 지운다.
                AccountApi.removeJoinLocalStorageData()
            
                
                this.props.history.replace('/first_0');
                // this.props.history.replace('/main');
            } else {
                this.setState({loading:1})	// 로딩완료
                await calertPromise(resultData.Message);
            }
        } catch(e) {
            alert(JSON.stringify(e));
            await calertPromise('자동로그인 도중 오류가 발생하였습니다. 관리자에게 문의해주세요.')
            this.setState({loading:1})	// 로딩완료
        }
        
    }

    render() {
        return (
            <>
            {!this.state.loading ? (<Loading />) : ''}
            <MemberJoinComplateCss/>
            <div className="result_box_main_container">
                <div className="result_box">
                    <div className="item1"></div>
                    <div className="item2">
                            <span className="icon_box">
                                <img src={icon_check} className="icon_check" alt="" />
                            </span>
                            <span className="TXST_t1"><b>회원가입이 완료</b>되었습니다.</span>
                            <span className="TXST_t2">
                                <p>고객의 마음까지 운전합니다</p>
                                <p>운전 동행 서비스 모시러</p>
                            </span>
                            <span className="result_btn_box">
                                <button className="btn_st1_1fafd2" onClick={this.handleLoginClick}>자동로그인</button>
                            </span>
                    </div>
                    <div className="item3"></div>

                    
                </div>
                </div>
            </>
        );
    }
};
export default withCookies(MemberJoinComplateView);
