import React, { Component } from "react";
import { withLastLocation } from "react-router-last-location";
import { withCookies } from "react-cookie";
import Header from "../../../components/Common/Header";
import TimeSelectorNewMonthlyOnceContainer from '../../../containers/Reserve/TimeSelectorNewMonthlyOnceContainer'
import ReserveApi from "../../../lib/ReserveApi";
import {
    getStrAmPm,
    getDayKorean,
    getStryyyymmdd
} from '../../../lib/TimeApi'

import moment from 'moment'
import Util, { calertPromise, setLocalStorage, getLocalStorage, calertConfirmBtnCustom } from "../../../lib/Util";
import RnInterface from "../../../lib/RnInterface";
import store from '../../../reduxStore'
import Loading from '../../../components/Common/Loading'
import MonthlyMosilerApi from "../../../lib/MonthlyMosilerApi";
import AnimatedCheckbox from '../../../components/Common/AnimatedCheckbox'

import "../../../css/default.css";
import "../../../css/booking.css";
import "react-datepicker/dist/react-datepicker.css";

const CAR_INFO_TYPE_CAR_NO = 'CAR_NO';
const CAR_INFO_TYPE_CAR_MODEL = 'CAR_MODEL';

const RESERVE_TYPE_GENERAL_FLAG = 1
const RESERVE_TYPE_GOLF_FLAG = 3

const TEXTAREA_REQUEST_MAX_LENGTH = 300;
const RESERVATION_BASE_TIME = (window.location.href.indexOf('devclient.mosiler.com') !== -1) ? 0 : 0;                   // 예약 기준 시간 (현재일로부터 29시간 이후로 시작/종료시간을 산정.)  devclient면 30시간(테스트용) hybclient는 29시간
const RESERVATION_GOLF_BASE_TIME = 10;              // 골프 기본 시간 (10시간)

const CLOSE_HOUR = 15;                              // 예약 종료 시간 15시
const LIMIT_HOUR = 0;


/*
    * Reserve Confirm View에서 아래의 데이터를 넘겨야 한다.
    - localStorage name : reserve_view_all_state  
    - Property            
            date                    date: "2022-01-04T15:00:00.000Z"
            startTime               startTime: "2022-01-03T22:00:00.000Z"
            endTime                 endTime: "2022-01-04T05:20:55.939Z"
            reserveType             reserveType: 1
            startTimeString         startTimeString: "2022-01-05 07:00:00.000"
            endTimeString           endTimeString: "2022-01-05 10:00:00.000"
            carLocation             
            comment                 
            passengerName           
            passengerPhone          
            carNo                   
            carModel                
            covidKitStatus                     
*/


class ReserveStep2View extends Component {
    // 원 페이지로 가는게 좋을까.
    // 페이지마다 js로 만들어야 하나...
    // 우선 원페이지로 시도해볼까?

    state = {
        loading: 1,
        

        // phase 1
        reserveType: Number(this.props.match.params.state),

        // phase 2
        calcComplateFlag:false,
        
        
        date: moment().add(RESERVATION_BASE_TIME, 'hours').toDate(),                   // 현재 시각에서 24시간 이후 시점의 날짜로 봐야한다.
        fixedStartTime: moment().add(RESERVATION_BASE_TIME, 'hours'),                  // 현재 시각에서 24시간 이후 시점의 날짜로 봐야한다.
        
        // calcItem:{
        //     ReseveCharge:'0',
        //     DrivingTimes:'',
        //     startAreaFee:'0',
        //     areaFee:'0',
        //     drivingCharge:'0',
        //     nightFee:'0',
        //     ReserveDiscountAmount:'0',
        // },

        // phase 3
        carLocation: '',
        carNo:'',
        carModel:'',
        comment: '',
        CustomerNo: 0,
        passengerName: '',
        passengerPhone: '',
        passengerCheckStatus: false,
        covidKitStatus: false,

        // 탑승자 상세 내용.
        passInfant:0,
        passAdult:0,
        passinfirm:0,
        passGravida:0,
        

        // hourView:'',
        selectHour: LIMIT_HOUR,
        // detailViewState: false,
        predictingTimeStr:'',

        totalTime:'',      

        displayEndTime:moment(),
        trustNowTime: moment().toDate(),
        timeAddMoment : moment().startOf('days'),
        nextBtnDisableStat : false,

        selectedRevStartDt: '',
        selectedRevEndDt: '',
        SelectReserveDateCount: 0,
        limitTimeData:0,
    };

    componentWillUnmount() {
        // setLocalStorage('reserve_step2_calc_flag', this.state.calcComplateFlag ? '1' : '0')
        
        
    }

    async componentDidMount() {
        await RnInterface.AndroidURLChange(this.props.location.pathname)
        let ls = getLocalStorage('reserve_step2_calc_flag')
        // let timeresult = await this.getUserAvaliableTime();
        let { loginedData } = store.getState().global_data

        this.setState({
            reserveType: Number(this.props.match.params.state),
            passengerCheckStatus: true,
            passengerName:  loginedData.CustomerName,
            passengerPhone: loginedData.PhoneNumber,
            carNo:          loginedData.CarNo,
            carModel:       loginedData.CarName,
            // limitTimeData: timeresult
            // calcComplateFlag: (ls !== null) ? ((ls === '1') ? true : false) : false
            // reserveType: isBackupAllStateResult ? Number(backupAllState.reserveType) : Number(this.props.match.params.state),
        });


    }

    handleGotoBack = async () => {
        this.props.history.goBack();
    };
    handleGotoHome = async () => {
        this.props.history.replace('/main');
        this.props.history.push('/main');
    };

    setStateOnce = (key, val) => {
        return new Promise((resolve) => {
            this.setState({
                [key]:val
            }, ()=>{
                resolve(true)
            }) 
        })
    }
    
    getAddressSimpleData = (address) => {
        let data = address.split(' ');
        let retdata = ''
        if (data.length) {
            retdata = `${data[0]} ${data[1]}` 
        }
        return retdata;
    }

    saveLocationLocalStorage = () => {
        const {
            startLocationAddress, startLocationLat, startLocationLng,
            endLocationAddress, endLocationLat, endLocationLng,
            stopOverList, checkSwitch
        } = store.getState().LocationSelector_ReduxModule;

        let tmpLocationList = localStorage.getItem('history_location_list');
        let tmpParseData = '';
        let resultData = '';
        let newData = {
            date: moment().format(),
            endLat: endLocationLat,
            endLng: endLocationLng,
            endLocation: endLocationAddress,
            startLat: startLocationLat,
            startLng: startLocationLng,
            startLocation: startLocationAddress,
            stopOverList: stopOverList,
            checkSwitch: (checkSwitch === '') ? false : checkSwitch
        }

        // null이 아닐때는 존재하므로 JSON.parse실시
        if (tmpLocationList !== null) {
            tmpParseData = JSON.parse(tmpLocationList);   
            resultData = [newData, ...tmpParseData];
        }
        // null일 경우에는 신규추가가 되므로 신규 작성한다.
        else {
            resultData = [newData];
        }
        
        localStorage.setItem('history_location_list', JSON.stringify(resultData))
        
       
    }
    
    makeMonthlyReservation =  () => {
        return new Promise(async (resolve, reject)=>{
            const { cookies } = this.props;
            const {selectedRevStartDt , selectedRevEndDt, comment, passengerName, passengerPhone, carNo, carModel} = this.state;

            const {
                startLocationAddress,
                startLocationLat,
                startLocationLng,
                endLocationAddress,
                endLocationLat,
                endLocationLng,
                stopOverList,
                checkSwitch,
            } = store.getState().LocationSelector_ReduxModule;
    
            const { 
                loginedData 
            } = store.getState().global_data;
    
            let tmpObj = {
                CustomerNo: loginedData.CustomerNo,
                CustomerName : loginedData.CustomerName, 
                CustomerPhoneNo: loginedData.PhoneNumber,
    
                DrivingType: '1',
                PaymentType: '2',
                
                RevStartDt: selectedRevStartDt,
                RevEndDt: selectedRevEndDt,
                
                StartAddress: startLocationAddress,
                StartLat: startLocationLat,
                StartLng: startLocationLng,
    
                EndAddress: endLocationAddress,
                EndLat: endLocationLat,
                EndLng: endLocationLng,
    
                CarModel : carModel,
                CarNo : carNo,
                RequestCovid19Kit : false,
    
                CustomerComment: `**탑승자정보**\n총 0 명\n\n**end**\n${comment}`,
    
                Passenger : passengerName,
                PassengerPhoneNo : passengerPhone,
    
                CouponId: null,
    
                ...ReserveApi.getWaypointGenerate2(stopOverList)
            }
    
            try {
                this.setState({ loading: 0 })	// 로딩중
                let response = await ReserveApi.SetDrivingReservationMonthly( tmpObj, cookies.get('ostype'), cookies.get('deviceid'), cookies.get('login_encrypt') )
                if (response.Result === 'success') {
                    let nowDateData = new Date();
                    // 경유지가 존재하는가?
                    if (stopOverList.length) {
                        // waypoint는 최대 10개까지 지정 가능
                        // WaypointAddressXX, WaypointLatXX, WaypointLngXX
                        const waypointData = {
                            CustomerNo: loginedData.CustomerNo,
                            DrivingNo: response.Item.DrivingNo,
                            ...ReserveApi.getWaypointGenerate(stopOverList),
                            RegDt: nowDateData,
                            RegUserNo: 1,
                            // ...this.getWaypoint(stopOverList),
                        }
    
                        // 경유지 추가
                        let waypointResult = await ReserveApi.Add_DRVG_STOP_AREA( waypointData, cookies.get('ostype'), cookies.get('deviceid'), cookies.get('login_encrypt') )
    
                        // 경유지 추가 성공시
                        if (waypointResult.Result === 'success') {
                            
                            // onDone && onDone();
                            this.setState({ loading: 1 })	// 로딩완료
                            await calertPromise('예약이 완료되었습니다.');
                            
                            // await RnInterface.callReactNative(`ScreenViewAnalyticsReservationComplate_${DrivingType}`)
                            localStorage.removeItem('ReserveConfirmViewBackup')
                            // 이용한 경로를 localStorage에 저장한다. 이 내용은 출발도착경유지 주소 지정시 최근경로 목록에 추가된다.
                            this.saveLocationLocalStorage();
                            
                            // 예약 리스트 화면으로 이동
                            this.props.history.replace('/main')
                            this.props.history.push('/use_state')
                           
                            resolve(true)
                        } 
                        // 경유지 추가 실패시
                        else {
                            try {
                                // 경유지 추가 실패시 예약한거 취소해야함.
                                let CancelResult = await ReserveApi.CancelDrivingReservation(
                                    loginedData.CustomerNo,
                                    response.Item.DrivingNo,
                                    cookies.get('ostype'),
                                    cookies.get('deviceid'),
                                    cookies.get('login_encrypt')
                                )
    
                                if (CancelResult.Result === 'success') {
                                    await calertPromise('경유지를 추가하지 못했습니다. 예약이 취소되었습니다.');
                                    this.setState({ loading: 1 })	// 로딩완료
                                    resolve(false)
                                    
                                } 
                                else {
                                    await calertPromise('경유지를 추가하지 못했습니다.');
                                    await calertPromise(CancelResult.Message);
                                    
                                    this.setState({ loading: 1 })	// 로딩완료
                                    resolve(false)
                                    
                                }
                            }
                            catch(e) {
                                await calertPromise('예약 취소 도중 오류가 발생했습니다.\n고객센터에 문의부탁드립니다.');
                                console.log(e)
                                this.setState({ loading: 1 })	// 로딩완료
                                resolve(false)
                            }
                            
                        }
                    }
                    // 존재하지 않으면 예약 완료
                    else {
                        await calertPromise('예약이 완료되었습니다.');
                        
                        this.setState({ loading: 1 })	// 로딩완료
                        // await RnInterface.callReactNative(`ScreenViewAnalyticsReservationComplate_${DrivingType}`)
                        localStorage.removeItem('ReserveConfirmViewBackup')
                        // 이용한 경로를 localStorage에 저장한다. 이 내용은 출발도착경유지 주소 지정시 최근경로 목록에 추가된다.
                        this.saveLocationLocalStorage();
                        resolve(true)
                        
                        // 예약 리스트 화면으로 이동
                        this.props.history.replace('/main')
                        this.props.history.push('/use_state')



                    }
                } else {
                    await calertPromise(response.Message)
                    this.setState({ loading: 1 })	// 로딩완료
                    resolve(false)
                }
    
            } catch (e) {
                await calertPromise('예약 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
                console.log(e)
                this.setState({ loading: 1 })	// 로딩완료
                resolve(false)
            }
        })
        
        
    }

    handleStep2ValidateBtn = async () => {
        const {calcComplateFlag, selectedRevStartDt, selectedRevEndDt, SelectReserveDateCount} = this.state;
        const {
            startLocationAddress,
            endLocationAddress,
            stopOverList,
        } = store.getState().LocationSelector_ReduxModule;
        
        if (SelectReserveDateCount >= 1) {
            await calertPromise(`월간모시러 예약은 하루에 한 건만 예약할 수 있습니다.\n[${getStryyyymmdd(selectedRevStartDt)}]는 이미 예약 되어 있습니다.\n`)
        }
        else {
            if (calcComplateFlag) {
                let msg = `[예약 정보]\n
                ${getStryyyymmdd(selectedRevStartDt)} (${getDayKorean(selectedRevStartDt)})
    
                ${getStrAmPm(selectedRevStartDt)} ${moment(selectedRevStartDt).format('hh:mm')} ~ ${getStrAmPm(selectedRevEndDt)} ${moment(selectedRevEndDt).format('hh:mm')}
                (${moment.duration(moment(selectedRevEndDt).diff(moment(selectedRevStartDt))).asHours()}시간)
                
                ${this.getAddressSimpleData(startLocationAddress)} ▶ ${this.getAddressSimpleData(endLocationAddress)}
    
                ※ 연장 운행은 최대 2시간까지 가능합니다.
                ※ 2시간 이상의 연장을 원하실 경우 배정된 파트너와 협의해 주세요.
                `
                if (await calertConfirmBtnCustom(msg,'취소', '예약하기')) {
                    this.makeMonthlyReservation()
                    
                    // this.props.history.push(`/reserve_renew/${this.state.reserveType}/3`)
                }  
            }
            else {
                await calertPromise('시간 설정 후 요금이\n계산 되었는지 확인해주세요.')
            }
        }

        
    }

    changeInput = (e) => {
        let name = e.target.name;
        let value = e.target.value
        this.setState({
            [name]: value
        })
    }
    
    changeInputLimit = (e, maxlength) => {
        let name = e.target.name;
        let value = e.target.value
        
        if (Util.getStringLength(value) <= maxlength) {
            this.setState({
                [name]: value
            })
        }
        
    }

    getCalcDrivingReservationNew = (timeObjData) => {
        return new Promise(async (resolve, reject)=>{
            const { cookies } = this.props;
            const {
                startLocationAddress,
                startLocationLat,
                startLocationLng,
                endLocationAddress,
                endLocationLat,
                endLocationLng,
                stopOverList,
                checkSwitch,
            } = store.getState().LocationSelector_ReduxModule;

            const { 
                loginedData 
            } = store.getState().global_data;
    
            let tmpObj = {
                CustomerNo: loginedData.CustomerNo,
                DrivingType: this.state.reserveType,
                
                PaymentType: '2',
                
                RevStartDt: timeObjData.startTime,
                RevEndDt: timeObjData.endTime,
                
                StartAddress: startLocationAddress,
                StartLat: startLocationLat,
                StartLng: startLocationLng,
    
                EndAddress: endLocationAddress,
                EndLat: endLocationLat,
                EndLng: endLocationLng,
    
                ...ReserveApi.getWaypointGenerate2(stopOverList)
            }

            try {
                let result = await MonthlyMosilerApi.CalcDrivingReservationMonthly(
                    tmpObj,
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                )

                if (result.Result === 'success') {
                    this.setState({ 
                        // loading: 1 ,
                        calcComplateFlag:true,
                        selectedRevStartDt: timeObjData.startTime,
                        selectedRevEndDt: timeObjData.endTime,
                        SelectReserveDateCount: result.Item.SelectReserveDateCount
                    })	// 로딩완료
                    resolve(result.Item)
    
                } else {
                    await calertPromise('금액을 계산 하지 못했습니다.' + result.Message)
                    resolve({})
                }    
            } catch (e) {
                console.log(e)
                await calertPromise('금액 계산 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
                resolve({})
            }
        })
    }
    
    handleEnableBtn = async () => {
        await this.setStateOnce('nextBtnDisableStat', false)
    }

    handleDisableBtn = async () => {
        await this.setStateOnce('nextBtnDisableStat', true)
    }

    handlePassengerName = (e) => {
        this.setState({
            passengerName: e.target.value
        })
    }

    handlePassengerPhone = (e) => {
        this.setState({
            passengerPhone: e.target.value
        })
    }

    // 차량번호, 차량모델 취득하는 메소드
    // 로그인시에 차량번호, 모델을 등록해놨다면 
    // 해당 정보를 취득하도록하고,
    // 상기 정보를 등록하지 않았다면
    getCarNoCarModel = (type) => {
        const { loginedData } = store.getState().global_data
        let retData = '';
        try {
            switch(type) {
                case CAR_INFO_TYPE_CAR_NO:
                    retData = loginedData.CarNo !== null ? loginedData.CarNo : '';
                    break;
                case CAR_INFO_TYPE_CAR_MODEL:
                    retData = loginedData.CarName !== null ? loginedData.CarName : '';
                    break;
                defalut:
                    retData = '';
                    break;
            }
        }
        catch(e) {
            console.log('getCarNoCarModel err : ', e)
        }
        
      
        return retData;
    }

    handlePassengerCheckStatus = (e) => {
        let { loginedData } = store.getState().global_data

        this.setState({
            passengerCheckStatus: e.target.checked,
            passengerName: e.target.checked ? loginedData.CustomerName : '',
            passengerPhone: e.target.checked ? loginedData.PhoneNumber : ''
        })
    }

    render() {

        let carInfoAreaDynamicClassName = (flag, value) => {
            let retData = 'input type1';
            try {
                
                /* 
                    최초 화면에 들어갔다면 input type1이 나와야 함
                    다음 눌렀을 때 입력안된 영역은 input type1 fail이 나와야 함
                    입력 안된 영역에 1자라도 입력시에는 input type1이 나와야 함.
                */
                if (flag && !value.length) {
                    retData = 'input type1';
                }
                else if (!flag && !value.length) {
                    retData = 'input type1 fail';
                }
                else if (!flag && value.length) {
                    retData = 'input type1';
                }
            }
            catch(e) {

            }
            
            return retData;
        }

        return (
            <>
                {!this.state.loading ? <Loading /> : ""}
                <Header
                    // title={`${ReserveApi.getDrivingTypeStr(this.state.reserveType)} 예약 (2/5)`}
                    title={`예약 (2/2)`}
                    onBackButton={this.handleGotoBack}
                    onHomeButton={this.handleGotoHome}
                />

                <div className="booking_box2 hd_margin_top">
                    <TimeSelectorNewMonthlyOnceContainer
                        history={this.props.history}
                        nowTime={this.state.trustNowTime}
                        closeHour={CLOSE_HOUR}
                        limitHour={LIMIT_HOUR}
                        type={this.state.reserveType}
                        onCalc={this.getCalcDrivingReservationNew}
                        onEnableBtn={this.handleEnableBtn}
                        onDisableBtn={this.handleDisableBtn}
                        nextBtnDisableStat={this.state.nextBtnDisableStat}
                        // limitTime={this.state.limitTimeData}
                    />

                    <div className="reserve-list">
                        <div className="search-box">
                            <div className="form-controls flexible"  style={{justifyContent:'space-between', paddingBottom:'10px'}}>
                                <h1 className='h1' style={{display:'inline'}}>탑승자 정보<i className="ico required2"><span className="blind">필수입력항목</span></i></h1>
                                
                                <div style={{display:'flex', alignItems:'center'}}>
                                    <h1 className='h2' style={{display:'inline'}}>본인</h1>&nbsp;    
                                    <AnimatedCheckbox
                                        reserveType={this.state.reserveType}
                                        checkSwitch={this.state.passengerCheckStatus}
                                        onChangeCheckdata={this.handlePassengerCheckStatus} />
                                </div>
                            </div>

                            <div className="form-controls type-passenger" >
                                <input type="text" disabled={this.state.passengerCheckStatus} className={`${carInfoAreaDynamicClassName(this.state.passengerAreaFlag, this.state.passengerName)} W100`}  placeholder="탑승자 이름" value={this.state.passengerName} onChange={this.handlePassengerName} />
                                <input type="text" disabled={this.state.passengerCheckStatus} className={`${carInfoAreaDynamicClassName(this.state.passengerAreaFlag, this.state.passengerPhone)} W100`} placeholder="탑승자 전화번호" value={this.state.passengerPhone} onChange={this.handlePassengerPhone} />
                            </div>        
                            
                        </div>
                    </div>
                    <div className="reserve-list">
                        <div className="search-box">
                            <h1 className='h1' style={{paddingBottom:'10px'}}>차량정보<i className="ico required2"><span className="blind">필수입력항목</span></i></h1>
                            <div className="form-controls type-carinfo">
                                <div className="row">
                                    <input type="text" name="carModel" id="" onChange={this.changeInput} value={this.state.carModel} placeholder="차량 모델 ex: BMW 7" title="" className={carInfoAreaDynamicClassName(this.state.carInfoAreaFlag, this.state.carModel)} />
                                    <input type="text" name="carNo" id="" onChange={this.changeInput} value={this.state.carNo} placeholder="차량 번호 ex: 12가 1234" title="" className={carInfoAreaDynamicClassName(this.state.carInfoAreaFlag, this.state.carNo)} />                                    
                                </div>
                            </div>
                        </div>
                    </div>   

                    <div className="reserve-list">
                        <div className="search-box">
                            <h1 className='h1' style={{paddingBottom:'10px'}}>파트너(드라이버)에게 알려주세요.</h1>
                            <div className="form-controls type-carinfo" style={{paddingLeft:'15px'}}>
                                <div className="row">
                                    <div className="placeholder_sim">
                                        ex)  <br/>
                                        1. 105동 지하2층에서 만나요. <br/>
                                        2. 난폭 운전 싫어요! 안전 운전 부탁드려요. <br/>
                                        3. 담배 냄새를 싫어해요. <br/>
                                    </div>
                                    <textarea 
                                        className="input type1" 
                                        rows="6"  
                                        name="comment" 
                                        value={this.state.comment} 
                                        onFocus={()=>{ document.querySelector('.placeholder_sim').style.display='none' }} 
                                        onBlur={()=>{ document.querySelector('.placeholder_sim').style.display='block' }} 
                                        onChange={(e)=>this.changeInputLimit(e, TEXTAREA_REQUEST_MAX_LENGTH)} >
                                    </textarea>
                                </div>
                                <div className="row text-gray">
                                    {Util.getStringLength(this.state.comment)}/{TEXTAREA_REQUEST_MAX_LENGTH} 
                                    {Util.getStringLength(this.state.comment) >= TEXTAREA_REQUEST_MAX_LENGTH ? (<b> (300자 이상 입력할 수 없습니다.)</b>) : ''}
                                    
                                </div>
                            </div>                
                        </div>
                    </div>   
                    

                    <div className="reserve-list"  style={{marginBottom:'70px', paddingTop:'0px'}}>
                        <div className="component-wrap">
                            <section className="information-text on">
                                <h1 className="information-head">
                                    <button type="button" className="btn-viewmore">
                                        <i className="ico ico-info"></i>
                                        꼭 확인해주세요.
                                        {/* <i className="icon arrow"></i> */}
                                    </button>
                                </h1>
                                <div className="information-body">
                                    
                                    <div className="info-desc">
                                        <h3 className="info-stit">예약안내</h3>
                                        <ul className="text-sub">
                                            {/* <li className="text-import"><b>※★같은 예약일에 2번이상 예약할 수 없습니다.(추가)※★</b></li> */}
                                            <li>월간 모시러 기본 이용시간은 8시간입니다.</li>
                                            <li>예약 접수 및 변경은 전일 15시에 마감됩니다.</li>
                                            <li>예약 취소 시 시점에 따라 수수료가 부과될 수 있습니다.</li>
                                            <li>이용지역 또는 시간대에 따라 할증이 부과될 수 있습니다.</li>
                                        </ul>
                                    </div>
                                    <div className="info-desc">
                                        <h3 className="info-stit">이용연장안내</h3>
                                        <ul className="text-sub">
                                            <li>연장 가능 시간은 최대 2시간입니다.</li>
                                            <li>연장이 필요할 경우 파트너와 꼭 협의 바랍니다.</li>
                                            <button onClick={()=>this.props.history.push('/policy_price_monthly?state=1')}>상세내용안내</button>
                                        </ul>
                                    </div>
                                    
                                </div>
                            </section> 
                        </div>     
                    </div>

                    
                    
                    <div className="component-wrap">
                        <div class="btn-area btn-group-fixed horizontal">
                            <button
                                type="button"
                                className="btn large secondary"
                                onClick={this.handleStep2ValidateBtn}
                                disabled={this.state.nextBtnDisableStat}
                            >
                                <span>예약</span>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withLastLocation(withCookies(ReserveStep2View));
