import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
@charset "utf-8";

/* Head Type 1 */
/* .head {width:100%; position:fixed; top:0%; background:#fff; z-index:1000;}
.head ul.type1 { display:inline-block; position:relative; width:100%; text-align:center; height:50px; line-height:50px; padding:0px 0; margin-top:10px; box-sizing:border-box;}
.head ul.type1 li.btn {position:absolute; top:0%; left:0%; margin-left:20px;}
.head ul.type1 li.head_title {width:100%; text-align:center; font-size:1.5em; font-weight:bold;}
.head ul.type1 li.home {position:absolute; top:0%; right:0%; margin-right:15px;}
.head ul.type1 img.hd_icon {width:25px; height:25px;}
.head ul.type1 .btn_style1 {border:0px; background:#fff;}
.head ul.type1_mt5 {margin:5px 0 !important;} */


/* 충전권 */
.ticket {display:inline-block; width:100%; min-height:calc(100vh - 53px); background:#ebebed; padding:0px;}
.ticket ul { display:inline-block; width:100%;}
.ticket li {}

/* 충전권 - 현황 */
.ticket ul.ma_box { padding:20px; background:#fff; border-top:1px solid #eee;}
.ticket li.ma_title {font-size:1.5em; letter-spacing:-1px;}
.ticket li.info_form { display:inline-block; width:100%; margin:0px 0 0 0;}
.ticket li.info_form span.money_box { display:inline-block; width:100%; background:#1fafd2; border-radius:10px; padding:15px 25px; color:#fff; box-shadow:0 5px 5px rgba(0, 0, 0, 0.2);}
.ticket li.info_form span.money_box span.mn_title { display:inline-block; height:35px; line-height:35px; font-size:1.2em;}
.ticket li.info_form span.money_box span.mn_title img {width:35px; float:left; margin-right:10px;}
.ticket li.info_form span.money_box span.mn_time { display:inline-block; float:right; font-size:1.5em; line-height:35px; height:35px;}

/* 충전권 - 충전권리스트 */
.ticket ul.coupon_detail { display:inline-block; width:100%;}

.ticket li.date { display:inline-block; width:100%; padding:20px;}
.ticket li.date button { display:inline-block; width:60px; background:#fff; color:#000; border:1px solid #ddd; border-radius:50px; text-align:center; height:30px;}
.ticket li.date button.on {background:#1fafd2; color:#fff; border:1px solid #1fafd2;}

.ticket li.ticket_list_box { display:inline-block; width:100%; padding:0px;}
.ticket li.ticket_list_box span.box_notice { display:inline-block; width:100%; font-size:1.1em; color:#666;}
.ticket li.ticket_list_box span.tk_box { display:inline-block; width:100%; background:#fff; padding:0px; margin-bottom:10px; padding:0 20px;}
.ticket li.ticket_list_box span.tk_box span.tk_title { display:inline-block; width:100%; border-bottom:1px dashed #ddd; padding:20px 0px 15px 0px;}
.ticket li.ticket_list_box span.tk_box span.tk_title span.ct_title {display:inline-block; width:70px; float:left; font-size:1.3em; font-weight:bold; padding-bottom:5px;}
.ticket li.ticket_list_box span.tk_box span.tk_title span.ct_charge {color:#718ae4 !important;}
.ticket li.ticket_list_box span.tk_box span.tk_title span.ct_info { display:inline-block; float:left;  font-size:1.2em;}
.ticket li.ticket_list_box span.tk_box span.tk_title span.ct_info span.time { display:inline-block; padding-left:10px;}
.ticket li.ticket_list_box span.tk_box span.tk_number { display:inline-block; width:100%; padding:15px 0px 20px 0px;}
.ticket li.ticket_list_box span.tk_box span.tk_number span.tk_info { display:inline-block; width:100%;}
.ticket li.ticket_list_box span.tk_box span.tk_number b { display:inline-block; width:70px; padding:0 0 5px 0; color:#999}

.hd_tap { display:inline-block; width:100%; background:#fff; padding:0px 20px; text-align:center; margin-bottom:1px; border-top:1px solid #eee; border-bottom:1px  solid #eee;}
.hd_tap button.tap { display:inline-block; width:50%; float:left; padding:15px 0; background:#fff; font-size:1.3em; border:0px; border-bottom:2px solid #ebebed; margin-bottom:-1px;}
.hd_tap button.tap_on { font-weight:bold; border-bottom:2px solid #000;}



/* 쿠폰 - 쿠폰없음 */
.ticket li.ticket_list_box span.tk_box span.no_ticket { display:inline-block; width:100%; text-align:center; padding:30px;}
.ticket li.ticket_list_box span.tk_box span.no_ticket span.img { display:inline-block; width:100%;}
.ticket li.ticket_list_box span.tk_box span.no_ticket span.img img {width:50px; padding-bottom:20px;}
.ticket li.ticket_list_box span.tk_box span.no_ticket span.info { display:inline-block; width:100%; font-size:1.3em; color:#666;}

`;