import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
@charset "utf-8";

/* 쿠폰 */
.coupon {display:inline-block; width:100%; min-height:calc(100vh - 53px); background:#ebebed; padding:0px;}
.coupon ul { display:inline-block; width:100%;}
.coupon li {}

/* 쿠폰 - 쿠폰발급 */
.coupon ul.ma_box { padding:20px; background:#fff;}
.coupon li.ma_title {font-size:1.5em; letter-spacing:-1px;}
.coupon li.info_form { display:inline-block; width:100%; margin:10px 0 0 0;}
.coupon li.info_form span.form_section { display:inline-block; width:100%;}
.coupon li.info_form span.section_title { position:relative; display:inline-block; width:100%; font-size:1.2em; font-weight:bold; margin:0px 0 5px 0; height:40px; line-height:40px;}
.coupon li.info_form span.section_input { position:relative; display:inline-block; width:100%;}
.coupon li.info_form span.section_input_bm {margin-bottom:10px;}
.coupon li.info_form span.section_input span.notice {display:inline-block; width:100%; color:#000; letter-spacing:-1px; color:#666; margin:15px 0 0 0; line-height:160%;}

/* 쿠폰 - 쿠폰리스트 */
.coupon ul.coupon_detail { display:inline-block; width:100%;}
.coupon li.coupon_list_box { display:inline-block; width:100%; padding:20px; padding-left:27px; padding-right:27px;}
.coupon li.coupon_list_box span.box_notice { display:inline-block; width:100%; font-size:1.1em; color:#666;}
.coupon li.coupon_list_box span.coupon_box { display:inline-block; width:100%; background:#fff; padding:0px; border-radius:10px; margin-top:5px;}
.coupon li.coupon_list_box span.coupon_box span.coupon_title { display:inline-block; width:100%;  padding:25px 20px 5px 30px;}
.coupon li.coupon_list_box span.coupon_box span.coupon_title span.ct_title { display:inline-block; width:100%; font-size:1.3em; font-weight:bold; padding-bottom:7px;}
.coupon li.coupon_list_box span.coupon_box span.coupon_title span.ct_info { display:inline-block; white-space:100%; font-size:1.2em;}

.coupon li.coupon_list_box span.coupon_box .seperator_container { display:inline-block; width:100%; height:5px; font-size:1em; padding: 0 15px 0 15px; }
.coupon li.coupon_list_box span.coupon_box .seperator_container .seperator { display:inline-block; width:100%; border-bottom:2px dashed #ddd;}

.coupon li.coupon_list_box span.coupon_box span.coupon_number { display:inline-block; width:100%; padding:10px 20px 25px 30px; color:#1fafd2;}
.coupon li.coupon_list_box span.coupon_box span.coupon_number b { display:inline-block; padding:0 0 5px 0;}

/* 쿠폰 - 쿠폰없음 */
.coupon li.coupon_list_box span.coupon_box span.no_coupon { display:inline-block; width:100%; text-align:center; padding:37px;}
.coupon li.coupon_list_box span.coupon_box span.no_coupon span.img { display:inline-block; width:100%;}
.coupon li.coupon_list_box span.coupon_box span.no_coupon span.img img {width:50px; padding-bottom:20px;}
.coupon li.coupon_list_box span.coupon_box span.no_coupon span.info { display:inline-block; width:100%; font-size:1.3em; color:#666;}


/* 쿠폰 - 버튼 */
input.certp_st1 {display:inline-block; float:right; width:70px; height:43px; line-height:43px; background:#1fafd2; color:#fff; border:0px; font-size:15px; font-weight:bold; border-radius:5px; margin-left:5px;}
input.input_fm1 { display:inline-block; width:calc(100% - 85px); border:1px solid #ddd; font-size:1.2em; height:43px; line-height:51px; padding:0 15px;}

.hd_tap { display:inline-block; width:100%; background:#fff; padding:0px 20px; text-align:center; margin-bottom:1px; border-top:1px solid #eee; border-bottom:1px  solid #eee;}
.hd_tap button.tap { display:inline-block; width:50%; float:left; padding:15px 0; background:#fff; font-size:1.3em; border:0px; border-bottom:2px solid #ebebed; margin-bottom:-1px;}
.hd_tap button.tap_on { font-weight:bold; border-bottom:2px solid #000;}
`;