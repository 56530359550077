import React, { Component, ShadowDom } from 'react';
import { API_BASE_URL, Token, PAYMENT_BASE_URL, PAYMENT_INTERFACE_URL, PARTNER_BASE_URL } from "../constantData";
import querystring from 'qs';
import Iframe from 'react-iframe'
import RnInterface from '../lib/RnInterface'
import { createGlobalStyle } from "styled-components";
import Header from "../components/Common/Header";

import '../css/default.css';

const GlobalStyle = createGlobalStyle`
.iframe_area { display:block; width:100%; height:100vh; border:1px; }

`;


class TestPayment extends Component {
    state = {
        formData: null,
        outer_html: '<html><body>helloworld</body></html>'
    }

    setUpFormData = async () => {
        let data = {
            MallUserId: "mosiler01m"
        };
        try {
            // Login 후의 UserInfo 데이터 취득
            // JSON형태로 Parse실시
            // data.MallUserId = __DEV__ ? "mosiler@mosiler.com" : userInfo.CustomerNo;
            data.MallUserId = 12534;
        } catch (e) { }
        this.setState({ formData: querystring.stringify(data) });
        console.log("__formData__", data);
    }

    getRequestData = () => {
        const { formData } = this.state;
        return {
            uri: PAYMENT_BASE_URL + "/mainBillPay.aspx",
            method: "POST",
            body: formData
        };

        /*if (Platform.OS === "ios")
          return {
            uri: PAYMENT_BASE_URL + "/mainBillPay.aspx",
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            },
            body: formData
          };
        else
          
      }*/
    }
      
    waitData = async () =>{
        try {
            let resultData = await RnInterface.getDataOnlyListener()
            JSON.parse(resultData)
        } catch(e) {
            // JSON.parse에서 에러가 발생하면 이쪽에서 처리한다.
            // 결제부분에서 실패하거나 성공하면 RN쪽으로 json형태로 반환하고, 
            // 그 값을 web단으로 보낸다. 만약에 그 값이 json형태가 아닐경우
            // 예외처리를 실시해야한다.
        }
    }

    componentDidMount() {
        this.setUpFormData();
        this.waitData();
    }

    handleBack = () => {
        alert('back');
    }

    handleHome = () => {
        this.props.history.replace('/login')
    }

    render() {
        return (
            <>
                <GlobalStyle />
                <Header 
                    title="결제등록하기"
                    onBackButton={this.handleBack}
                    onHomeButton={this.handleHome}
                    />

                
                <Iframe 
                    url="http://smilepay.mosiler.com/mainBillPay.aspx?MallUserId=12534"
                    id="iframe_id"
                    className="iframe_area"
                />
                
            </>
        );
    }
};

export default TestPayment;
