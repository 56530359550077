// GET v2/GetLiveDrivings?customerNo={customerNo}
// DrivingView.js : DrivingView component
// 현재 위치 정보 취득하는 함수 : getLiveDrivings
// DrivingState
// DrivingContainer
// DrivingMap
// 메뉴 목록 표시 : drivingList > DrivingListViewContainer > DrivingListView
// MainScreenNavigator
// Navigator.js : 등록된 네비게이션 확인 가능
// 10초마다 폴링.
// fetchBlob > rn-fetch-blob
import React, { Component } from 'react';

import { withCookies } from 'react-cookie';
import Header from '../../components/Common/Header'
import axios from 'axios'
// import NoticeApi from '../../lib/NoticeApi';
// import { createGlobalStyle } from "styled-components";
import Loading from '../../components/Common/Loading'
// import { calert } from '../../lib/Util'
import RealtimeLiveDrivingViewCss from '../../css/CustomGlobalStyle/RealtimeLiveDrivingView'
import RnInterface from '../../lib/RnInterface'

import store from '../../reduxStore'
import moment from 'moment'

import DrivingApi from '../../lib/DrivingApi'
import ReserveApi from '../../lib/ReserveApi'
import {NAVER_KEY, NAVER_CLIENT_ID} from '../../constantData'


import no_profile from '../../img/realtime/no_profile.svg'
import icon_user from '../../img/rating/icon_user.svg'
import icon_star from '../../img/realtime/icon_star.svg'
import icon_tel from '../../img/realtime/icon_tel.svg'
import icon_point from '../../img/realtime/icon_point.svg'
import icon_location from '../../img/realtime/icon_location.svg'
import record_icon_point from '../../img/record/icon_point.svg'
import record_icon_location from '../../img/record/icon_location.svg'
import icon_point_waypoint from '../../img/booking/icon_point_waypoint.svg'


import start_marker from '../../img/marker/start_marker.svg'
import end_marker from '../../img/marker/end_marker.svg'
import waypoint_marker from '../../img/marker/waypoint_marker.svg'
import driver_marker from '../../img/marker/driver_marker.svg'
import icon_mosiler_mark from '../../img/realtime/icon_mosiler_mark.svg'
import { calert, calertPromise } from '../../lib/Util';


const INIT_START_LATLNG = {
    lat: 37.566907531463585,
    lng: 126.97857866109217
}

const INIT_END_LATLNG = {
    lat: 37.766907531463585,
    lng: 127.97857866109217
}

var latData = 37.30101132
var lngData = 126.80101312


class RealtimeLiveDrivingView extends Component {

    state = {
        path: [],
        position: {},
        drivers: [],
        waypoint:[],
        drivingDetail:{},
        driverInfo:{},
        loading: 0,
        initLoading:0,
        directionInfo:{},
    }

    // state = {
    //     path: [],
    //     position: {},
    //     drivers:     [{
    //         RevStartAddress: "서울특별시 강남구 압구정동 압구정로29길 69 현대아파트 1265441562315613165135612",
    //         RevStartLat: INIT_START_LATLNG.lat,
    //         RevStartLng: INIT_START_LATLNG.lng,
    //         RevEndAddress: "서울특별시 강남구 압구정동 압구정로29길 69 현대아파트",
    //         RevEndLat: INIT_END_LATLNG.lat,
    //         RevEndLng: INIT_END_LATLNG.lng,
    //         Lat: INIT_START_LATLNG.lat,
    //         Lng: INIT_START_LATLNG.lng,
    //         DrivingStartDt:"2021-01-30T12:22:22.307"
    //     }],
    //     waypoint:[
    //         {
    //             WaypointAddress:'',
    //             WaypointLat:37.56229845185046,
    //             WaypointLng:126.99357682380312
    //         },
    //         {
    //             WaypointAddress:'',
    //             WaypointLat:37.56437264200381,
    //             WaypointLng:127.0620320552649
    //         },
    //         {
    //             WaypointAddress:'서울특별시 강남구 압구정동 압구정로29길',
    //             WaypointLat:37.551985925266216,
    //             WaypointLng:127.18786465798242
    //         },
            
    //     ],
    //     drivingDetail:{
    //         DriverName:'아무개',
    //         DriverEstimateAvgPoint:10,
    //         ShowContact: true,
    //         DriverPhoneNumber:'01001010101',
    //         DriverProfileImgName:'1.png'
    //     },
    //     driverInfo:{},
    //     loading: 0,
    //     initLoading:0,
    //     directionInfo:{},
    // }

    

    drivingTimeCalc = (startDt) => {
        let startTime = new Date(startDt);
        let nowTime = new Date();
        let calcdata = Math.floor((nowTime - startTime) / 1000) 
        let timeZeroStr = (minuteData) =>{
            return minuteData <= 9 ? '0'+minuteData : minuteData
        }

        return `${timeZeroStr(Math.floor((calcdata/60/60)))}:${timeZeroStr(Math.floor( (calcdata/60) %60))}:${timeZeroStr(Math.floor(calcdata%60))}`
        // console.log(calcdata%60)            // 초
        // console.log(calcdata/60)            // 분
        // console.log(calcdata/60/60)         // 시
        
    }
    



    fetchDirections =  (payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.get(
                    `/map-direction/v1/driving?start=${payload[0].longitude},${payload[0].latitude}&goal=${payload[1].longitude},${payload[1].latitude}`,
                    {
                        headers: {
                            "X-NCP-APIGW-API-KEY-ID": NAVER_KEY,
                            "X-NCP-APIGW-API-KEY": NAVER_CLIENT_ID,
                        },
                    }
                );
                this.setState({
                    directionInfo: response &&
                    response.data &&
                    response.data.route &&
                    response.data.route.traoptimal[0].summary
                }, ()=>{
                    resolve(true)
                })
                
            } catch (err) {
                return reject(err)
            }
        })

    }

    getLiveDrivings = (cn, drivingno, ostype, deviceid, loginEncrypt, payload) => {
        return new Promise(async (resolve,reject) => {
            try {
                var response = undefined;
                //Init driver position from api once, and next time request we don't need this request anymore
                //Just request fetchBlob
                var tmpDrivers = undefined;
                let isOnlyLatLong = false;
        
                (payload.previousDrivings || []).map((previousDriving) => {
                    if (Object.keys(previousDriving || {}).length <= 2) {
                        isOnlyLatLong = true;
                    }
                });
                if (
                    !payload.previousDrivings ||
                    Boolean(payload.init) ||
                    (payload.previousDrivings || []).length === 0 ||
                    Boolean(isOnlyLatLong)
                ) {
                    //Check if is the first time request
                    response = await DrivingApi.GetLiveDrivings(cn, ostype, deviceid, loginEncrypt)
                    
                    // 경유지 취득 begin
                    try {       
                        let waypointResult = await ReserveApi.Get_DRVG_STOP_AREA(cn, response.Item[0].DrivingNo, ostype, deviceid, loginEncrypt)
                        if (waypointResult.Result === 'success') {
                            let testArr = [];
                            // Object 형태를 배열형태로 처리
                            for(let i = 1; i <= 10; i++) {
                                let no = '01'
                                if (i <= 9) no=`0${i}`
                                else no=`${i}`
                                if ((waypointResult.Item[`WaypointAddress${no}`] !== undefined && waypointResult.Item[`WaypointAddress${no}`] !== null) &&
                                    (waypointResult.Item[`WaypointLat${no}`] !== undefined && waypointResult.Item[`WaypointLat${no}`] !== null) &&
                                    (waypointResult.Item[`WaypointLng${no}`] !== undefined && waypointResult.Item[`WaypointLng${no}`] !== null)) {
                                    testArr.push({
                                        WaypointAddress: waypointResult.Item[`WaypointAddress${no}`],
                                        WaypointLat: waypointResult.Item[`WaypointLat${no}`],
                                        WaypointLng: waypointResult.Item[`WaypointLng${no}`]
                                    })
                                }
                                else break;
                            }
                            this.setState({waypoint:testArr})
                        } 
                        
                    } catch(e) {
                        console.log(e)
                        return reject(e)
                    }
                    // 경유지 취득 end

                    // 드라이빙 상세 정보 취득 begin
                    try {       
                        let DrivingDetailResult = await ReserveApi.DrivingDetail(response.Item[0].DrivingNo, ostype, deviceid, loginEncrypt)
                        if (DrivingDetailResult.Result === 'success') {
                            this.setState({drivingDetail:DrivingDetailResult.Item})
                        } 
                        
                    } catch(e) {
                        console.log(e)
                        return reject(e)
                    }
                    // 드라이빙 상세 정보 취득 end
        
                    console.log(response);
                    tmpDrivers = [
                        ...(response && response.Item),
                        // {
                        //     DrivingNo: 12,
                        //     Lng: -122.08400000000001,
                        //     Lat: 37.42149833433534,
                        //     DrivingStatus: "4",
                        //     DrivingStatusName: "운행중",
                        //     DrivingStartDt: "2020-03-27T09:10:56.523",
                        //     RevStartAddress:
                        //         "서울특별시 강남구 압구정동 압구정로29길 69 현대아파트",
                        //     RevStartLat: "37.53151096287975",
                        //     RevStartLng: "127.0259852910155",
                        //     RevStartDt: "2020-03-27T10:10:00",
                        //     RevEndAddress:
                        //         "서울특별시 강남구 압구정동 압구정로29길 69 현대아파트",
                        //     RevEndLat: "37.53151096287975",
                        //     RevEndLng: "127.0259852910155",
                        //     RevEndDt: "2020-03-27T11:10:00",
                        //     DriverName: "test1",
                        // },
                        // {
                        //     DrivingNo: 51246,
                        //     Lng: -122.08400000000001,
                        //     Lat: 37.42149833333434,
                        //     DrivingStatus: "4",
                        //     DrivingStatusName: "운행중",
                        //     DrivingStartDt: "2020-03-27T09:10:56.523",
                        //     RevStartAddress:
                        //         "서울특별시 강남구 압구정동 압구정로29길 69 현대아파트",
                        //     RevStartLat: "37.53151096287975",
                        //     RevStartLng: "127.0259852910155",
                        //     RevStartDt: "2020-03-27T10:10:00",
                        //     RevEndAddress:
                        //         "서울특별시 강남구 압구정동 압구정로29길 69 현대아파트",
                        //     RevEndLat: "37.53151096287975",
                        //     RevEndLng: "127.0259852910155",
                        //     RevEndDt: "2020-03-27T11:10:00",
                        //     DriverName: "test2",
                        // },
                    ];
                } else {
                    tmpDrivers = [...payload.previousDrivings];
                }
                console.log(response);
                console.log(tmpDrivers);
        
                var arr = [];
                for (let i = 0; i < (tmpDrivers || []).length; i++) {
                    let tmpDriver = (tmpDrivers || [])[i];
                    //Next fetch real coords position from Azure Blob cache
                    //https://mosilergps.blob.core.windows.net/lclog/{{DrivingNo}}
        
                    let drivingBlob = await axios({
                        url: `/lclog/${tmpDriver.DrivingNo}`,
                        method: 'GET',
                        timeout: 10000,
                    })
                    console.log(`drivingBlob`, drivingBlob.data);
                    
                    if (drivingBlob.data && drivingBlob.data.Lat && drivingBlob.data.Lng) {
                        tmpDriver.Lat = drivingBlob.data.Lat;
                        tmpDriver.Lng = drivingBlob.data.Lng;
                    }
        
                    if (`${tmpDriver.Lat}` !== `0` && `${tmpDriver.Lng}` !== `0`) {
                        arr.push({ ...tmpDriver });
                    }
                }
        
                console.log(arr);
                this.setState({drivers: arr}, ()=>{
                    resolve(true)
                })
                
            } catch (err) {
                console.log(err);
                return reject(err)
                
                // return getLiveDrivingsFailedAction(err);
            }
        })
    }

    initLoadingPromise = () => {
        return new Promise((resolve)=>{
            this.setState({initLoading:1}, ()=>{
                resolve(true)
            })
        })
        
    }
    async componentDidMount() {
        try {
            const {cookies} = this.props;
            let { CustomerNo } = store.getState().global_data.loginedData
            await RnInterface.AndroidURLChange(this.props.location.pathname)

            // 예약 리스트 취득
            let drivingLiveResult = await ReserveApi.GetDrivingReservationList( CustomerNo, 1, 10, 10, cookies.get('ostype'), cookies.get('deviceid'), cookies.get('login_encrypt') )
            
            if (drivingLiveResult && drivingLiveResult.Result === 'success') {
                // 현재 운행중인 건이 있으면 초기화 후 맵 화면을 띄운다.
                if (drivingLiveResult.LiveList.length) {

                    
                    await this.getLiveDrivings(CustomerNo,0,cookies.get('ostype'), cookies.get('deviceid'), cookies.get('login_encrypt'),{
                        previousDrivings: this.state.drivers,
                        init: true
                    });
                    
                    // 현재 파트너 위치와 출발지쪽위치
                    // 20210202 - 네이버 API는 사용하지 않는다.
                    // (하단 render() 부분에 "상단 ~~파트너 / ~~분 후 도착예정 주석처리 20210129" 표시와 연관있는 처리이다.)
                    // await this.fetchDirections([
                    //     {latitude:this.state.drivers[0].Lat, longitude:this.state.drivers[0].Lng},
                    //     {latitude:this.state.drivers[0].RevStartLat, longitude:this.state.drivers[0].RevStartLng}])
        
                    await this.initLoadingPromise();
                    
                    // 1. 맵 생성
                    let KAKAO = window.kakao;
                    
                    var mapContainer = document.getElementById('container'), // 지도를 표시할 div 
                        mapOption = {
                            center: new KAKAO.maps.LatLng(this.state.drivers[0].Lat, this.state.drivers[0].Lng), // 지도의 중심좌표
                            level: 8 // 지도의 확대 레벨
                        };
                    var map = new KAKAO.maps.Map(mapContainer, mapOption); // 지도를 생성합니다
                    
                    // 2. 출발지, 도착지, 경유지 마커 찍을 위치를 생성
                    // 출발 도착지
                    var fixedPositionArr = [
                        {
                            position: new KAKAO.maps.LatLng(parseFloat(this.state.drivers[0].RevStartLat), parseFloat(this.state.drivers[0].RevStartLng)),
                            markerImage : new KAKAO.maps.MarkerImage(
                                start_marker, 
                                new KAKAO.maps.Size(40, 95), 
                                {offset: new KAKAO.maps.Point(20, 59)}
                            )
                        },
                        {
                            position: new KAKAO.maps.LatLng(parseFloat(this.state.drivers[0].RevEndLat), parseFloat(this.state.drivers[0].RevEndLng)),
                            markerImage : new KAKAO.maps.MarkerImage(
                                end_marker, 
                                new KAKAO.maps.Size(40, 95), 
                                {offset: new KAKAO.maps.Point(20, 59)}
                            )
                        }
                    ];
        
        
        
                    // 경유지
                    if (this.state.waypoint.length) {
                        for (let i in this.state.waypoint) {
                            fixedPositionArr.push(
                                {
                                    position: new KAKAO.maps.LatLng(parseFloat(this.state.waypoint[i].WaypointLat), parseFloat(this.state.waypoint[i].WaypointLng)),
                                    markerImage : new KAKAO.maps.MarkerImage(
                                        waypoint_marker, 
                                        new KAKAO.maps.Size(40, 95), 
                                        {offset: new KAKAO.maps.Point(20, 59)}
                                    )
                                }
                            )
                        }
                    }
            
            
                    // 3. 출발, 도착, 경유지 마커를 찍는다.
                    for(let i=0; i<fixedPositionArr.length; i++) {
                        // todo 경유지 세팅이 안되어 있을때의 체크도 필요.
                        // todo 각각의 아이콘은 달라야 한다.
                        new KAKAO.maps.Marker({
                            position: fixedPositionArr[i].position,
                            image: fixedPositionArr[i].markerImage
                            
                        }).setMap(map);
                    }
                    
                    // 4. 파트너 마커 찍을 위치를 생성
                    var driverPosition = new KAKAO.maps.LatLng(parseFloat(this.state.drivers[0].Lat), parseFloat(this.state.drivers[0].Lng))
                    
                    // // 5. 파트너 마커를 찍는다. 파트너 마커는 별도 변수로 관리한다.
                    // var driverMarker = new KAKAO.maps.Marker({
                    //     position: driverPosition,
                    //     image: new KAKAO.maps.MarkerImage(
                    //         driver_marker, 
                    //         new KAKAO.maps.Size(40, 95), 
                    //         {offset: new KAKAO.maps.Point(20, 65)}
                    //     )
                    // });
            
                    // driverMarker.setMap(map);
        
                    var customOverlay = new KAKAO.maps.CustomOverlay({
                        map: map,
                        clickable: true,
                        content: `<div class="live_container">
                                    <div class="rect"><div class="rect_left">ㅋㅋ</div><div class="rect_right">이동중</div></div>
                                    <div class="tri"></div>
                                    <span class="redmarker_container">
                                        <div class="circle pulse red_marker"></div>
                                    </span>                            
                                </div>`,
                        position: driverPosition,
                        xAnchor: 0,
                        yAnchor: 1,
                        zIndex: 10
                    });
        
                    // 확인해봐야할 기능
                    // 1. 마커 이미지 변경 (출발지, 도착지, 경유지, 파트너)
                    // 2. 몇분 후에 도착예정 표시필요. (NAVER API) 
                    // 3. 출발지, 도착지, 경유지의 주소,위경도 취득 필요.
                    // - distance 전체 경로 거리(meters)  (int)
                    // - duration 전체 경로 소요 시간(milisecond(1/1000초))   (int)
                    
                    this.interval = setInterval( async ()=>{
                        await this.getLiveDrivings(CustomerNo,0,cookies.get('ostype'), cookies.get('deviceid'), cookies.get('login_encrypt'),{
                            previousDrivings: this.state.drivers,
                            init: false
                        });
            
                        // setposition을 줘야 마커가 이동을 한다.
                        // driverMarker.setPosition(new KAKAO.maps.LatLng(parseFloat(this.state.drivers[0].Lat), parseFloat(this.state.drivers[0].Lng)))
                        customOverlay.setPosition(new KAKAO.maps.LatLng(parseFloat(this.state.drivers[0].Lat), parseFloat(this.state.drivers[0].Lng)))
                    },10000)
        
                    try { document.querySelector('.rect_left').innerHTML = this.drivingTimeCalc(this.state.drivers[0].DrivingStartDt) } catch(e){}
        
                    this.timerInterval = setInterval(()=>{
                        try { document.querySelector('.rect_left').innerHTML = this.drivingTimeCalc(this.state.drivers[0].DrivingStartDt); } catch(e){}
                    },1000)


                } else {
                    // 운전이 끝난 상태라면 /main으로 이동
                    this.props.history.replace('/main')
                }
            }

        }
        catch(e) {
            console.log(e);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        clearInterval(this.timerInterval)
    }

    handleBack = () => {
        this.props.history.replace('/main')
    }

    handleHome = () => {
        this.props.history.replace('/main')
    }
    


    render() {
        let stopOverComponent = (arr) => {
            return arr.map((data, idx) => {
                if (data.WaypointAddress !== '')
                    return (
                        <>
                            <tr>
                                <td className="first"><img src={icon_point_waypoint} alt="" /></td>
                                <td>{data.WaypointAddress}</td>
                            </tr>
                        
                        </>
                    )
                return ;
            });
        }
        

        if (!this.state.initLoading) return (
            <>
            <Loading />
            <RealtimeLiveDrivingViewCss />
                <Header
                    title="실시간 운행"
                    onBackButton={this.handleBack}
                    onHomeButton={this.handleHome}
                />
            </>
        )
        return (
            <>
                {/* {!this.state.loading ? (<Loading />) : ''} */}
                <RealtimeLiveDrivingViewCss />
                <Header
                    title="실시간 운행"
                    onBackButton={this.handleBack}
                    onHomeButton={this.handleHome}
                />
                <div className="booking_sch">
                    {/* 상단 ~~파트너 / ~~분 후 도착예정 주석처리 20210129 */}
                    {/* {Math.floor(this.state.directionInfo.duration / 60000) !== 0 ? (
                        <span>
                            <span style={{
                                display: 'inline-block',
                                top: '80px',
                                left: '15px',
                                width: '180px',
                                height: '42px',
                                border: '1px solid #4d5467',
                                background: '#4d5467',
                                position: 'absolute',
                                zIndex: '10',
                                borderRadius: '20px'
                            }}>
                                <span style={{
                                    position: 'relative',
                                    top: '4px',
                                    left: '4px',
                                    width: '100%',
                                    height: '36px',
                                    fontSize: '1.1em',
                                }}>
                                    <img style={{
                                        display: 'inline-block',
                                        width: '36px',
                                    }}
                                        src={icon_mosiler_mark} alt="" />
                                    <span style={{ color: 'white' }}>{this.state.drivingDetail.DriverName} 파트너</span>
                                </span>
                            </span>
                            <span style={{
                                display: 'inline-block',
                                top: '80px',
                                left: '165px',
                                width: '134px',
                                height: '42px',
                                border: '1px solid #4d5467',
                                background: '#ffffff',
                                position: 'absolute',
                                zIndex: '10',
                                borderRadius: '0px 20px 20px 0px'

                            }}>
                                <span style={{
                                    position: 'relative',
                                    top: '11px',
                                    left: '10px',
                                    width: '100%',
                                    height: '36px',
                                    fontSize: '1.1em',
                                }}>

                                    <span style={{ color: 'black' }}> {Math.floor(this.state.directionInfo.duration / 60000)}분뒤 도착 예정</span>
                                </span>
                            </span>
                        </span>
                    ):null}
                   */}

                    <ul className="map_box">

                        <li className="sch_map">

                            <div id="parent_container">
                                <div id="container">
                                    {/* <div className="marker_center"><b><img src="./img/booking/icon_navi.svg" alt="" /></b></div> */}
                                </div>
                            </div>

                            
                            {/* <div id="result2"></div> */}

                        </li>
                        <li className="guide_info_box">
                            <span className="profile">
                                <table className="profile_table">
                                    <tr>
                                        <td className="user_ph">
                                            <img 
                                                style={{
                                                    width:'50px',
                                                    height:'50px',
                                                    objectFit:'cover',
                                                    borderRadius:'50%'
                                                }}
                                                src={this.state.drivingDetail.DriverProfileImgName} 
                                                onError={(e)=>{e.target.onerror = null; e.target.src = icon_user}} 
                                                alt="" />
                                            </td>
                                        <td className="user_name">
                                            {this.state.drivingDetail.DriverName === undefined ? '' :this.state.drivingDetail.DriverName} 파트너
                                            <span className="grade"><img src={icon_star} alt="" /> {Math.floor(this.state.drivingDetail.DriverEstimateAvgPoint/2)}</span>
                                        </td>
                                        
                                        {this.state.drivingDetail.ShowContact ? (
                                            <td className="user_tel">
                                                <a href={`tel:${this.state.drivingDetail.DriverPhoneNumber}`}><img src={icon_tel} alt="" /> 전화걸기</a>
                                            </td>
                                        ):null}
                                        
                                    </tr>
                                </table>
                            </span>
                            {/* 주석처리하여 비표시 20210129 */}
                            {/* <span className="place_box">
                                <span className="place_start">
                                    <span className="place_title">출발</span>
                                    <span className="place_address"><img src={icon_point} alt="" /> {this.state.drivers[0].RevStartAddress}</span>
                                </span>
                                <span className="place_start">
                                    <span className="place_title">도착</span>
                                    <span className="place_address"><img src={icon_location} alt="" /> {this.state.drivers[0].RevEndAddress}</span>
                                </span>
                            </span> */}
                            <span className="navi_info">
                                <table className="rtable3">

                                    <tr>
                                        <td rowSpan="2" className="first"><img src={record_icon_point} alt="" /></td>
                                        <td>{this.state.drivers[0].RevStartAddress}</td>
                                    </tr>
                                    <tr>
                                        <td>{moment(this.state.drivers[0].RevStartDt).format('YYYY-MM-DD HH:mm:ss')}</td>
                                    </tr>
                                </table>

                                <table className="rtable4">
                                    {stopOverComponent(this.state.waypoint)}
                                </table>
                                
                                <table className="rtable3">
                                    <tr>
                                        <td rowSpan="2" className="first"><img src={record_icon_location} alt="" /></td>
                                        <td>{this.state.drivers[0].RevEndAddress}</td>
                                    </tr>
                                    <tr>
                                        <td>{moment(this.state.drivers[0].RevEndDt).format('YYYY-MM-DD HH:mm:ss')}</td>
                                    </tr>

                                </table>
                            </span>
                        </li>
                    </ul>

                </div>


            </>
        );
    }
};



export default withCookies(RealtimeLiveDrivingView);
