import React, { Component } from 'react';
import TimePicker from 'rc-time-picker';
import {NavLink} from 'react-router-dom'
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import TimePickerCustomCss from '../../css/CustomGlobalStyle/TimePickerCustom'
import DatePicker, { registerLocale } from "react-datepicker";
import ko from 'date-fns/locale/ko';
import Util, {calertPromise} from '../../lib/Util'
import { getReserveAvaliableDate, getSelectedDateAlertFlag } from '../../lib/TimeApi'

import icon_calendar from '../../img/booking/icon_calendar.svg'
import icon_arrow_right from '../../img/booking/icon_arrow_right.svg'
import scriptjs from 'scriptjs'
import { addDays, subDays, getDate } from 'date-fns';
registerLocale('ko', ko)

const MAX_HOURS = 24;
const MAX_MINUTE = 60;
const MINUTE_STEP = 10;     // 10분단위로 (10, 20, 30, 40 ...)

const RESERVE_TYPE_GENERAL_FLAG = 1
const RESERVE_TYPE_GOLF_FLAG = 3

class TimeSelector2 extends Component {
    constructor(props) {
        super(props)
        this.endEsc = null;
        this.state={
            // selectedDate: moment('2021-01-10T00:00:00.000'),
            // startTime: moment(),
            // endTime: moment().add(1,'hours'),
            startVisibleHours:[],
            startVisibleMinute:[],
            endVisibleHours:[],
            endVisibleMinute:[],
            startTimePickerProps:{},
            endTimePickerProps:{},
            isOpenFlag:false,
            isAlertFlag:false
        }
        
        
    }  
    setIsOpenFlag = (flag) => {
        this.setState({ isOpenFlag: flag });
    }
    setIsAlertFlag = (flag) => {
        this.setState({ isAlertFlag: flag });
    }
    

    // TimeSelector2가 처음 열릴때, 변경될때 시작시간 부분 선택 가능 영역에 대해서 세팅하는 처리
    initStartDisabledHour = () => {
        // 여기에 date가 개입하게 되면..
        let tmpArr = [];
        // let nowMoment = moment()        // 현재 시간으로 하고 싶을때는 moment()로
        // let nowMoment = moment('2021-01-13T19:51:00');
        let nowMoment = this.props.fixedStartTime
        
        let selectedDate = moment(this.props.selectedDate);
        console.log(nowMoment.minutes());
        if (Number(selectedDate.format('YYYYMMDD')) === Number(nowMoment.format('YYYYMMDD'))) {
            // 아래처리를 내가 왜 넣어놨었지?
            // this.props.onChangeStart(this.props.fixedStartTime)
            for (let i=0; i<MAX_HOURS; i++) {
                // 매시 51분 이상일때는 disabled list로 포함시켜야 한다.
                if ( (i < nowMoment.hours()) ) { tmpArr.push(i); }  // 같은 시 이면 현 시점에서 선택 가능한 시을 배열화 한다.
                else if ( (i === nowMoment.hours()) && (nowMoment.minutes() >= 51)) {  tmpArr.push(i); }
            }
        } else {
            tmpArr = [];
        }
        console.log(tmpArr)
        
        return tmpArr;
    }

    initStartDisabledMinute = () => {
        // 여기에 date가 개입하게 되면..
        let tmpArr = [];
        // let nowMoment = moment()        // 현재 시간으로 하고 싶을때는 moment()로
        let nowMoment = this.props.startTime
        let selectedDate = moment(this.props.selectedDate);
        
        if (Number(selectedDate.format('YYYYMMDD')) === Number(nowMoment.format('YYYYMMDD'))) {
            for (let i=0; i<MAX_MINUTE; i+=MINUTE_STEP) {
                if (i < nowMoment.minutes()) {  tmpArr.push(i);  }  // 같은 시 이면 현 시점에서 선택 가능한 분을 배열화 한다.
            }
        } 
        else {
            tmpArr = [];
        }
        
        return tmpArr;
    }

    initEndDisabledHour = (e) => {
        // 여기에 date가 개입하게 되면..
        let tmpArr = [];
        let tmpStartTime = (e && e !== undefined) ? 
            moment(e.toDate()).add(1, 'hours').hours() : 
            moment(this.props.startTime.toDate()).add(1, 'hours').hours();
        
        // 지정한 startTime 이전으로는 선택할 수 없도록 처리한다.
        for (let i=0; i<tmpStartTime; i++) {
            tmpArr.push(i);
        }

        return tmpArr;
    }

    initEndDisabledMinute = (e) => {
        // 여기에 date가 개입하게 되면..
        let tmpArr = [];
        let tmpStartTime = (e && e !== undefined) ? 
            moment(e.toDate()).add(1, 'hours').minutes() : 
            moment(this.props.startTime.toDate()).add(1, 'hours').minutes();
        
        // 지정한 startTime 이전으로는 선택할 수 없도록 처리한다.
        for (let i = 0; i < tmpStartTime; i+=MINUTE_STEP ) {
            tmpArr.push(i);
        }

        return tmpArr;
    }


    disabledMinute = (e) => {
        // 여기에 date가 개입하게 되면..
        let tmpArr = [];
        // let nowMoment = moment()        // 현재 시간으로 하고 싶을때는 moment()로
        let nowMoment = this.props.fixedStartTime
        let selectedDate = moment(this.props.selectedDate);

        if (Number(selectedDate.format('YYYYMMDD')) === Number(e.format('YYYYMMDD'))) {
            if (e.hours() === nowMoment.hours()) {
                for (let i=0; i<MAX_MINUTE; i+=MINUTE_STEP) {
                    // 같은 시 이면 현 시점에서 선택 가능한 분을 배열화 한다.
                    if (i < nowMoment.minutes()) {
                        tmpArr.push(i);
                    }
                }
            } else {
                tmpArr = [];
            }
            
        } else {
            tmpArr = [];
        }
        
        return tmpArr;
    }

        /*
    starttime : 14:15면
    endtime은 15:15일테고

    endtime을 누르면 
    15:15 이전은 선택되면 안된다
    시를 16으로 바뀌면 00, 10, 20, 30.. 다 나와야 한다.
    다시 15시로 바뀌면 20, 30, 40, 50 으로 나와야 한다.

    이렇게 되려면 startTime
   
    */

    disabledMinuteFromStartTime = (e) => {
        // 여기에 date가 개입하게 되면..
        let tmpArr = [];
        let tmpStartTime = moment(this.props.startTime.toDate()).add(1,'hours');
        
        // 내가 지정한 시 와 startTime이 같다면
        if (e.hours() === tmpStartTime.hours()) {
            for (let i=0; i<MAX_MINUTE; i+=MINUTE_STEP) {
                // 같은 시 이면 현 시점에서 선택 가능한 분을 배열화 한다.
                if (i < tmpStartTime.minutes()) {
                    tmpArr.push(i);
                }
            }
        } else {
            tmpArr = [];
        }
        
        return tmpArr;
    }



    componentDidMount () {
        // scriptjs('https://code.jquery.com/jquery-1.12.4.min.js', ()=>{
            
        // });
        console.log(window)
        console.log('componentDidMount timeselector')

        this.setState({
            startVisibleHours:this.initStartDisabledHour(),
            startVisibleMinute: this.initStartDisabledMinute(),
            endVisibleHours:this.initEndDisabledHour(),
            endVisibleMinute: this.initEndDisabledMinute(),
        })
        /*
        -컴포넌트가 로드 될때
        1. startTime 관점
            1) 현재 날짜와 시간을 고려하여 startVisibleHours, startVisibleMinute를 세팅한다.
            startVisibleHours
                오늘 날짜일 경우 => 현재 시각을 고려하여 disable할 시를 계산한다.
                오늘 날짜 외일 경우 => 24시간 표시한다.
            startVisibleMinute =>
                오늘 날짜일 경우 => 현재 시각을 고려하여 disable할 분을 계산한다.
                오늘 날짜 외일 경우 => 전체 분을 표시한다.
        2. endTime 관점
            1) startTime만 보면 된다. 지정한 시, 분에 따라 선택 가능한 값이 달라야 한다.
               만약 startTime - 11:05라면 
        
        -startTime 변경시
        1. handleChangeStartTime()가 호출되어야 한다.
            startTime을 갱신한다.
            endtime은 starttime + 1시간으로 갱신한다.
            startVisibleMinute도 변경해야한다. ("시" 변경시 분 계산을 해줘서 표시할 분을 표시해야한다.)
            endVisibleHour, endVisibleMinute도 변경해줘야한다.
            

        -endTime 변경시
        
        -날짜 변경시
        */

    }

    handleChangeStartTime = (e) => {
        // 클릭할때마다 시간 계산 실시해서 setState에 넣어놓고
        // disabledHours에는 state값을 리턴하는 식으로 진행.
        // 시작시간일경우 "시"를 건들면 안된다. 분을 건들자.
        // 현재 시간이 14:10 라면 분쪽은 15, 20, 25, 30, 35, 40, 45, 50, 55 선택 가능
        // 현재 시간이 15:00 라면 분쪽은 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55 선택 가능
        
        // let tmp = moment(e.toDate());
        // console.log(tmp.add(1,'hours'))
        console.log('handleChangeStartTime')
        this.props.onChangeStart(e)

        

        this.setState({
            // startTime: e,
            // endTime: tmp.add(1,'hours'),

            startVisibleMinute: this.disabledMinute(e),
            endVisibleHours: this.initEndDisabledHour(e),
            endVisibleMinute: this.initEndDisabledMinute(e)
        },()=>{
            
        })
        
        
    }

    handleChangeEndTime = (e) => {
        // 클릭할때마다 시간 계산 실시해서 setState에 넣어놓고
        // disabledHours에는 state값을 리턴하는 식으로 진행.
        // 시작시간일경우 "시"를 건들면 안된다. 분을 건들자.
        // 현재 시간이 14:10 라면 분쪽은 15, 20, 25, 30, 35, 40, 45, 50, 55 선택 가능
        // 현재 시간이 15:00 라면 분쪽은 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55 선택 가능
        console.log('handleChangeEndTime')
        this.props.onChangeEnd(e)
        this.setState({
            // endTime: e,
            endVisibleMinute: this.disabledMinuteFromStartTime(e)
        },()=>{
            
        })
        // 20210218 [MosilerAPP-User]TestCheck_List.xlsx H10셀에 의해 사용하지 않음
        // this.endEsc();
    }

    changeHourView = (data) => {
        let tmp = '';
        try {
            tmp = data.replace('시간','시간 ')
            
        }
        catch(e){}
        return tmp;
    }

    // 예상소요시간이 1분 이상인지를 체크한다. 1분 이상일경우
    // 예상소요시간, 시간 추가 기능을 활성화 한다.
    isChkPredictingHourMin = (hour,min) => {
        let tmpMoment = moment().startOf('days');
        let tmpMomentOrigin = tmpMoment.unix()  // 0시 0분 기준의 timestamp값
        let tmpMomentCompare = tmpMoment.add(hour,'hours').add(min,'minutes').unix() // hour, min값이 포함된 timestamp값
        
        // 같으면 false 다르면 true
        // origin과 compare가 같으면 예상소요시간이 산출되지 않았다는 의미가 된다.(0시간0분이기 때문에)
        return !(tmpMomentOrigin === tmpMomentCompare)
    }

    render() {
        const DatePickCustomComponent = ({ value, onClick }) => (
            <div className="reserve-list">
                <div className="grid cross-center flex-between">
                    <div className="col"><i className="ico ico-map-calendar"></i></div>
                    <div className="col text-bold">{value}</div>
                    <div className="col right">
                        <button className="btn-option" onClick={onClick}>날짜</button>
                    </div>
                </div>
            </div>
        );

        const filterPassedDate = date => {
            // date : 화면상에 디스플레이될 날짜 목록

            // 선택시 본 콜백이 발생하며 달력에 표시된 모든 데이터가 나온다.
            // 여기서 선택했을때 이전의 날짜는 선택 못하도록 해야한다.
            let currentDate = new Date();
            currentDate.setDate(currentDate.getDate() - 1)    // 하루 전

            let selectedDate = new Date(date);              // 선택한 날짜

            // console.log(currentDate.getTime()) 
            return currentDate.getTime() <= selectedDate.getTime();
        }


        return (
            <>
                <TimePickerCustomCss />
                <span className="list_t2">

                    {/* 날짜를 지정하는게 의미가 없음. 시작시간, 종료시간이 다음날을 넘어가는 경우가 생길 수 있기 때문. */}
                    <DatePicker
                        onInputClick={() => {this.setIsOpenFlag(true)}}
                        onClickOutside={()=>{
                            if (!this.state.isAlertFlag)   this.setIsOpenFlag(false)
                        }}
                        open={this.state.isOpenFlag}
                        dateFormat="yyyy-MM-dd(eee)"
                        renderDayContents={(day,date)=>{
                            return <div style={{
                                display:'inline-block',
                                width:'1.7rem',
                                lineHeight:'1.7rem',
                            }} onClick={async ()=>{
                                let flagData = getSelectedDateAlertFlag(this.props.nowTime, date, this.props.closeHour)
                                switch(flagData) {
                                    case 1:     // 당일 선택시
                                        this.setIsAlertFlag(true);
                                        await calertPromise('좀 더 안정적인 서비스를 위하여 당일 예약을 받지 않습니다. 예약은 이용 하루 전 19시 전까지 부탁드립니다.')
                                        this.setIsAlertFlag(false);
                                        break;
                                    case 2:     // 19시 이후 당일,명일 선택시
                                        this.setIsAlertFlag(true);
                                        await calertPromise('좀 더 안정적인 서비스를 위하여 19시 이후 날짜 선택 시, 19시 이후에는 다음날 예약을 받지 않습니다. 예약은 이용 하루 전 19시 전까지 부탁드립니다.')
                                        this.setIsAlertFlag(false);
                                        break;
                                    default:
                                        break;
                                }
                                }}>
                                <span>{getDate(date)}</span>
                            </div>
                            
                        }}
                        renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled
                        }) => (
                            <div style={{
                                margin: 10,
                                display: "flex",
                            }} >
                                <div style={{flex:'1'}}>
                                    <button style={{fontSize:'1.2em', border:'none'}} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                        {"◀"}
                                    </button>
                                </div>
                                <div style={{flex:'10'}}>
                                    <span style={{
                                        fontSize:'1.2em',
                                        fontWeight:'bold'
                                    }}>
                                    {moment(date).format('YYYY')}년 {moment(date).format('MM')}월
                                    </span>
                                </div>
                                <div style={{flex:'1'}}>
                                    <button style={{fontSize:'1.2em', border:'none'}} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                        {"▶"}
                                    </button>
                                </div>
                            </div>
                        )}
                        selected={this.props.selectedDate}
                        
                        
                        // minDate={this.props.fixedStartTime.toDate()}
                        minDate={getReserveAvaliableDate(this.props.nowTime, this.props.closeHour)}
                        onChange={async date => {


                            await this.props.onDateChange(date);
                            this.setState({
                                startVisibleHours: this.initStartDisabledHour(),
                                startVisibleMinute: this.initStartDisabledMinute(),
                                endVisibleHours: this.initEndDisabledHour(),
                                endVisibleMinute: this.initEndDisabledMinute(),
                            })
                            this.setIsOpenFlag(false)
                        }}
                        customInput={<DatePickCustomComponent />}
                        popperPlacement="auto"
                        locale="ko"
                        
                        filterDate={filterPassedDate}
                    />


                    {/* <DateSelector
                                        onChangeData={this.handleSaveDate} /> */}
                    {/* <span className="list_sec1">
                                        <span>{this.state.date}</span>
                                        <span>2020.07.15(수)</span>
                                        <button className="icon_calandar">
                                            <img src={icon_calendar} alt="" />
                                        </button>
                                    </span> */}



                    <div className="reserve-list">
                        <div className="grid cross-center flex-between">
                            <div className="col grid cross-center">
                                <div><i className="ico ico-map-time"></i></div>
                                <div className="col">시작시간</div>
                                <div className="col">
                                <TimePicker
                                    showSecond={false}
                                    value={this.props.startTime}
                                    onChange={this.handleChangeStartTime}
                                    format={'HH:mm'}
                                    // disabledHours={() => (this.state.startVisibleHours)}                 // 당일 예약 불가가 되었기 때문에 24시간 선택 가능
                                    // disabledMinutes={() => (this.state.startVisibleMinute)}              // 당일 예약 불가가 되었기 때문에 24시간 선택 가능
                                    minuteStep={MINUTE_STEP}
                                    hideDisabledOptions={true}
                                    focusOnOpen={true}
                                    inputReadOnly={true}
                                />
                                </div>
                                <div className="col"><i className="ico ico-map-arrow"></i></div>
                                <div className="col">종료시간</div>
                                <div className="col text-bold">

                                &nbsp;&nbsp;{this.props.displayEndTime.format('HH:mm')}

                                <NavLink to="#" className="tooltip">
                                    <i className="ico ico-quest"></i>
                                    <span className="tooltip-content-custom">
                                        <p>시작시간을 기준으로</p>
                                        <p>총예약시간{this.props.type === RESERVE_TYPE_GENERAL_FLAG && (<>(예상소요시간 + 시간추가)</>)}을</p>
                                        <p>합한 시간이 종료시간이 됩니다.</p>
                                        {/* <button className="tooltip-close">
                                            <span className="blind">툴팁 닫기</span>
                                        </button> */}
                                    </span>
                                </NavLink>                                         

                                {/* <TimePicker
                                    disabled={true}
                                    showSecond={false}
                                    value={this.props.displayEndTime}
                                    // className="disabled"
                                    // onChange={this.handleChangeEndTime}
                                    // format={'HH:mm'}
                                    // disabledHours={() => (this.state.endVisibleHours)}
                                    // disabledMinutes={() => (this.state.endVisibleMinute)}
                                    // minuteStep={MINUTE_STEP}
                                    // hideDisabledOptions={true}
                                    // focusOnOpen={true}
                                    // inputReadOnly={true}
                                    // addon={(data)=>{
                                    //     this.endEsc = data.props.onEsc
                                    // }}
                                />                                  */}
                                    
                                </div>
                            </div>
                            {/* 
                            <div className="col right mar-r5 text-bold">{ Util.getStrDoubleDigit(this.props.selectHour)}시간</div>
                            <div className="col">
                                <button className="btn-option2" onClick={()=>{  this.props.onIncrease();  }}><i className="ico ico-plus"></i></button>
                                <button className="btn-option2" onClick={()=>{  this.props.onDecrease();  }}><i className="ico ico-minus"></i></button>
                            </div> */}

                        </div>
                    </div>
                    <div className="reserve-list">
                        <ul className="list inner-list">
                            <li className="item">
                                
                                

                                {this.props.type === RESERVE_TYPE_GENERAL_FLAG ? (
                                    <>
                                        {(   this.isChkPredictingHourMin(this.props.predictingHour,this.props.predictingMin)  ) && (
                                            <>
                                                <div className="item-row grid crocss-center">
                                                    <div className="col left">
                                                        <span>예상소요시간</span>
                                                    </div>
                                                    <div className="col right">
                                                        <span>{this.props.predictingTimeStr}</span>
                                                    </div>
                                                </div> 
                                                <div className="item-row grid crocss-center mar-t2">
                                                    <div className="col left">
                                                        <span>시간추가</span>
                                                    </div>
                                                    <div className="col right mar-r10">
                                                        
                                                        {/* 
                                                        버튼형태의 시간 추가 컨트롤러는 없앤다.
                                                        <button type="button" onClick={()=>{ this.props.onIncrease(); }}className="ico btn-map-plus mar-r5"><span className="blind">시간추가</span></button>
                                                        {(this.props.selectHour > this.props.limitHour) ? 
                                                        (<button type="button" onClick={()=>{ this.props.onDecrease(); }} className="ico btn-map-delete"><span className="blind">시간삭제</span></button>) :
                                                        (<button type="button" className="ico btn-map-delete"><span className="blind">시간삭제</span></button>)} */}
                                                    </div>
                                                    <div className="col timeadd">
                                                        <i className="ico price-add">할증</i>
                                                        {/* <span>{ Util.getStrDoubleDigit(this.props.selectHour)}시간 00분</span>   -10분-*/}
                                                        
                                                        <span>
                                                            
                                                            {/* { moment('2021-01-01 00:00').add(this.props.selectHour * 10, 'minutes').format('HH시간 mm분') } */}
                                                            <TimePicker
                                                                showSecond={false}
                                                                value={this.props.timeAddMoment}
                                                                onChange={(e)=>{
                                                                    this.props.onTimeAddMomentChange(e)
                                                                }}
                                                                format={'HH:mm'}
                                                                // disabledHours={() => (this.state.startVisibleHours)}                 // 당일 예약 불가가 되었기 때문에 24시간 선택 가능
                                                                // disabledMinutes={() => (this.state.startVisibleMinute)}              // 당일 예약 불가가 되었기 때문에 24시간 선택 가능
                                                                minuteStep={MINUTE_STEP}
                                                                hideDisabledOptions={true}
                                                                focusOnOpen={true}
                                                                inputReadOnly={true}
                                                            />
  
                                                        </span>

                                                    </div>
                                                </div> 
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <div className="item-row grid crocss-center mar-t2">
                                        <div className="col left">
                                            <span>시간추가</span>
                                        </div>
                                        <div className="col right mar-r10">
                                            {/* 
                                            버튼형태의 시간 추가 컨트롤러는 없앤다.
                                            <button type="button" onClick={()=>{ this.props.onIncrease(); }}className="ico btn-map-plus mar-r5"><span className="blind">시간추가</span></button>
                                            {(this.props.selectHour > this.props.limitHour) ? 
                                                (<button type="button" onClick={()=>{ this.props.onDecrease(); }} className="ico btn-map-delete"><span className="blind">시간삭제</span></button>) : 
                                                (<button type="button" className="ico btn-map-delete"><span className="blind">시간삭제</span></button>)}
                                             */}
                                        </div>
                                        <div className="col timeadd">
                                            <i className="ico price-add">할증</i>
                                            {/* <span>{ Util.getStrDoubleDigit(this.props.selectHour)}시간 00분</span>   -10분-*/}
                                            
                                            <span>
                                                
                                                {/* { moment('2021-01-01 00:00').add(this.props.selectHour * 10, 'minutes').format('HH시간 mm분') } */}
                                                <TimePicker
                                                    showSecond={false}
                                                    value={this.props.timeAddMoment}
                                                    onChange={(e)=>{
                                                        this.props.onTimeAddMomentChange(e)
                                                    }}
                                                    format={'HH:mm'}
                                                    // disabledHours={() => (this.state.startVisibleHours)}                 // 당일 예약 불가가 되었기 때문에 24시간 선택 가능
                                                    // disabledMinutes={() => (this.state.startVisibleMinute)}              // 당일 예약 불가가 되었기 때문에 24시간 선택 가능
                                                    minuteStep={MINUTE_STEP}
                                                    hideDisabledOptions={true}
                                                    focusOnOpen={true}
                                                    inputReadOnly={true}
                                                />

                                            </span>

                                        </div>
                                    </div> 
                                )}


                                <div className="item-row grid crocss-center mar-t2">
                                    <div className="col left">
                                        <strong>총예약시간</strong>
                                    </div>
                                    <div className="col right">
                                        <strong>{this.props.totalTime}</strong>
                                    </div>
                                </div>                                                                  
                            </li>                                                                                                                                                        
                        </ul>
                    </div>

                    {this.props.detailState ? (
                        <div className="reserve-list">                       
                            <ul className="list inner-list">
                                <li className="item">
                                    <div className="item-row grid crocss-center">
                                        <div className="col left">
                                            <span>운행요금</span>
                                        </div>
                                        <div className="col right">
                                            <span>{Util.numberWithCommas(String(this.props.moneyViewObj.drivingCharge))}원</span>
                                        </div>
                                    </div>

                                    <div className="item-row grid crocss-center">
                                        <div className="col left">
                                            <span>출발지할증</span>
                                            {/* <NavLink to="#" className="tooltip">
                                                <i className="ico ico-quest"></i>
                                                <span className="tooltip-content">
                                                    <p>경기도 30,000원</p>
                                                    <button className="tooltip-close">
                                                        <span className="blind">툴팁 닫기</span>
                                                    </button>
                                                </span>
                                            </NavLink>                                          */}
                                        </div>
                                        <div className="col right">
                                            <i className="ico price-add">할증</i>
                                            <span>{Util.numberWithCommas(String(this.props.moneyViewObj.startAreaFee))}원</span>
                                        </div>
                                    </div> 
                                    
                                    <div className="item-row grid crocss-center">
                                        <div className="col left">
                                            <span>지역할증</span>
                                            {/* <NavLink to="#" className="tooltip">
                                                <i className="ico ico-quest"></i>
                                                <span className="tooltip-content">
                                                    <p>충청도 50,000원</p>
                                                    <button className="tooltip-close">
                                                        <span className="blind">툴팁 닫기</span>
                                                    </button>
                                                </span>
                                            </NavLink>                                          */}
                                        </div>
                                        <div className="col right">
                                            <i className="ico price-add">할증</i>
                                            <span>{Util.numberWithCommas(String(this.props.moneyViewObj.areaFee))}원</span>
                                        </div>
                                    </div>

                                    <div className="item-row grid crocss-center">
                                        <div className="col left">
                                            <span>심야할증</span>
                                            {/* <NavLink to="#" className="tooltip">
                                                <i className="ico ico-quest"></i>
                                                <span className="tooltip-content">
                                                    <p>충청도 50,000원</p>
                                                    <button className="tooltip-close">
                                                        <span className="blind">툴팁 닫기</span>
                                                    </button>
                                                </span>
                                            </NavLink>                                          */}
                                        </div>
                                        <div className="col right">
                                            <i className="ico price-add">할증</i>
                                            <span>{Util.numberWithCommas(String(this.props.moneyViewObj.nightFee))}원</span>
                                        </div>
                                    </div>

                                    {(this.props.type === RESERVE_TYPE_GOLF_FLAG && this.props.ReserveDiscountAmount !== 0) && (
                                        <div className="item-row grid crocss-center">
                                            <div className="col left">
                                                <span>예약할인</span>
                                                {/* <NavLink to="#" className="tooltip">
                                                    <i className="ico ico-quest"></i>
                                                    <span className="tooltip-content">
                                                        <p>충청도 50,000원</p>
                                                        <button className="tooltip-close">
                                                            <span className="blind">툴팁 닫기</span>
                                                        </button>
                                                    </span>
                                                </NavLink>                                          */}
                                            </div>
                                            <div className="col right">
                                                <i className="ico price-discount">할증</i>
                                                <span>{Util.numberWithCommas(String(this.props.ReserveDiscountAmount))}원</span>
                                            </div>
                                        </div>
                                    )}
                                    

                                    <div className="item-row grid crocss-center">
                                        <div className="col left">
                                            <strong>이용예상금액</strong>
                                        </div>
                                        <div className="col right">
                                            <strong>{Util.numberWithCommas(String(this.props.moneyViewObj.allMoney))}원</strong>
                                        </div>
                                    </div>                                                                                                  
                                </li>                                                                                                                                                                      
                            </ul>
                        </div> 

                    ) : null}
                    
                    <div class="viewmore-content">
                    
                        <button type="button" className={this.props.detailState ? "btn btn-viewmore on" : "btn btn-viewmore"} onClick={()=>{
                            this.props.onDetailToggle();
                        }}>
                            <span>금액 상세보기</span>
                        </button>
                    </div> 

                </span>
          

            </>
        );
    }
};

export default TimeSelector2;
