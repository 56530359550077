import React, { Component } from 'react';
import IntroCss from '../../css/CustomGlobalStyle/Intro'
import RnInterface from '../../lib/RnInterface'

import '../../css/default.css';
import '../../css/member.css';

import welcom from '../../img/mypage/welcom.svg'
class FirstStepCarInfo1View extends Component {
    async componentDidMount() {
        try {
            await RnInterface.AndroidURLChange(this.props.location.pathname)
        }
        catch(e){
            console.log(e)
        }
    }
    

    handleHome = () => {
        this.props.history.replace('/main')
    }
    handleNext = () => {
        this.props.history.replace('/first_1/0')
    }

    render() {
        return (
            <>
            <IntroCss />
            <div class="intro_box">
                <span class="TXST_t1">환영합니다!</span>
                <span class="TXST_t2">
                    더 나은 서비스 제공을 하기 위해<br />
                    추가 정보를 등록해 주세요.
                </span>
                
                <span class="logo_box"><img src={welcom} class="welcom" alt="" /></span>
                
                {/* <span class="btn_box">
                    <button class="btn_type1" onClick={this.handleHome}>나중에</button>
                    <button class="btn_type2" onClick={this.handleNext}>지금 할게요!</button>
                </span> */}
            </div>
            <footer>
            <button class="btn_type1" onClick={this.handleHome}>나중에</button>
                    <button class="btn_type2" onClick={this.handleNext}>지금 할게요!</button>
            </footer>


            </>
        );
    }
};

export default FirstStepCarInfo1View;
