import { connect } from "react-redux";
import TimeSelectorNew from "../../components/Reserve/TimeSelectorNew";
import { 
    init_time_all_selector_service,
    init_time_start_selector_service,
    init_time_end_selector_service,
    change_start_time_service,
    change_end_time_service,
    change_date_service,
    change_addtime_service,
} from '../../modules/Reserve/TimeSelector_ReduxModule';


export default connect(
    state => {
        return {
            startTime: state.TimeSelector_ReduxModule.startTime,
            endTime: state.TimeSelector_ReduxModule.endTime,
            date: state.TimeSelector_ReduxModule.date,
            fixedStartTime: state.TimeSelector_ReduxModule.fixedStartTime,
        }
    },
    
    dispatch => ({
        onInit: ()=>dispatch(init_time_all_selector_service()),
        onChangeStartTime: (data)=>dispatch(change_start_time_service(data)),       // 매개변수는 객체 형태 {hour:10, minute:10}
        onChangeEndTime: (data)=>dispatch(change_end_time_service(data)),           // 매개변수는 객체 형태 {hour:10, minute:10}
        onChangeDate: (data)=>dispatch(change_date_service(data)),                  // 매개변수는 객체 형태 {hour:10, minute:10}
        onChangeAddtime: (data)=>dispatch(change_addtime_service(data)),
        
    })
    
)(TimeSelectorNew);


/**,
dispatch  => ({
    checkbox_service: ()=> dispatch(checkbox_service()) // <- "../../modules/MemberJoin/agreebutton"에 있는 type값을 dispatch
}) */
