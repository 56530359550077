import axios from 'axios';
import { Token, Timeout } from '../constantData'
import Util from './Util';

const DrivingApi = {};

DrivingApi.GetLiveDrivings = (customerNo, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/v2/GetLiveDrivings?customerNo=${customerNo}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });

}

DrivingApi.GetDrivingLastLocation = (drivingNo, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/GetDrivingLastLocation?DrivingNo=${drivingNo}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });

}

//export const DRIVING_BLOB_BASE_URL = "https://mosilergps.blob.core.windows.net/lclog/";

// DrivingApi.GetLiveDrivings = (drivingNo, ostype='', deviceid='', loginEncrypt) => {
//     return new Promise((resolve, reject)=>{
//         axios({
//             url : `/GetDrivingLastLocation?DrivingNo=${drivingNo}`,
//             method: 'GET',
//             timeout: Timeout,
//             headers: {
//                 'Content-Type':'application/json',
//                 'OsType': ostype,
//                 'DeviceId': deviceid,
//                 'Token': Token,
//                 'Authorize' : loginEncrypt,
//             },
//         }).then(res => {
//             resolve(res.data);
//         }).catch( (err) => {
//             return reject(err);
//         });
//     });

// }

export default DrivingApi;