import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { NavLink } from 'react-router-dom';
import compareVersions  from 'compare-versions'

import AppSettingApi from '../../lib/AppSettingApi';
import AccountApi from '../../lib/AccountApi';
import Header from '../../components/Common/Header'
import store from '../../reduxStore'
import { global_data_async } from '../../modules/Global/global_data'
import CookieApi from '../../lib/CookieApi';
import { global_data_logout_async } from '../../modules/Global/global_data'
import Loading from '../../components/Common/Loading'
import {calertPromise} from '../../lib/Util'
import RnInterface from '../../lib/RnInterface'
import AppSettingCss from '../../css/CustomGlobalStyle/AppSetting'

import '../../css/default.css';
import '../../css/member.css';

import icon_more from '../../img/setting/icon_more.svg'




const DEVICE_VER_HIGH = 1;      // deviceVer가 더 크면 1
const SERVER_VER_HIGH = -1;     // serverVer가 더 크면 -1
const SAME_VER = 0;             // 버전이 서로 같다면 0
class AppSettingView extends Component {

    state = {
        emailid: '',
        pushstatus: false,
        update: 1,
        loading:0,
        latestVersion:{
            Version:'0.0.0'
        },
        deviceVersion:'0.0.0',
        deviceid:'',
        ostype:'',
    }

    
    async componentDidMount() {
        try {
            
            this.setState({loading:0})
            const { loginedData } = store.getState().global_data
            let { cookies } = this.props;

            await RnInterface.AndroidURLChange(this.props.location.pathname)
            
            let deviceinfoMain = await RnInterface.getData('deviceinfo')
            if (deviceinfoMain === false) {
                deviceinfoMain = ['PC_TEST', 'PC_TEST']
            } 
            else {
                deviceinfoMain = deviceinfoMain.split('@@')
            }

            let ver = localStorage.getItem('device_app_version') === null ? false : ((localStorage.getItem('device_app_version') === 'false') ? false : localStorage.getItem('device_app_version'))
            

            let tmpVersionCheck = await AppSettingApi.GetVersionCheck(
                (ver === false ? '0.0.0' : ver),
                cookies.get('ostype'),
                cookies.get('deviceid'),
                cookies.get('login_encrypt')
            )

            this.setState({
                emailid: loginedData.UserId,
                pushstatus: (loginedData.PushYn === 'Y' ? true : false),
                latestVersion:tmpVersionCheck.Item ,
                deviceVersion:(ver === false ? '0.0.0' : ver),
                loading:1,
                deviceid:   deviceinfoMain[1],
                ostype:     deviceinfoMain[0],
            })
            
            
            
        }
        catch(e){
            console.log(e)
            this.setState({loading:1})
        }
        
    }
    
    async componentWillUnmount() { }

    getOsTypeFullName = () => {
        const {ostype} = this.state;
        let retData = ''
        if (ostype==='A') retData = 'Android'
        else if (ostype==='I') retData = 'iOS'
        else retData = 'PC_TEST'
        return retData;
    }
    handleHome = () => {
        this.props.history.replace('/main')
    }

    handleBack = () => {
        this.props.history.replace('/main')
    }

    chkVersion = (deviceVer, serverVer) => {
        // deviceVer가 더 크면 1
        // serverVer가 더 크면 -1
        // 버전이 서로 같다면 0
        console.log(compareVersions(deviceVer, serverVer))
        return compareVersions(deviceVer, serverVer);
    }

    handleChkChange = async (e) => {
        try {
            
            const { loginedData } = store.getState().global_data;
            let { cookies } = this.props;
            this.setState({ 
                pushstatus: e.target.checked,
                loading:0
            })



            let tmpData = await AppSettingApi.UpdatePushYN(
                loginedData.CustomerNo,
                (e.target.checked ? 'Y' : 'N'),
                cookies.get('ostype'),
                cookies.get('deviceid'),
                cookies.get('login_encrypt')
            )

            

            if (tmpData.Result === 'success') {
                
                let loginResult = await AccountApi.requestLogin(cookies.get('email_id'), cookies.get('hashcode'), cookies.get('account_type'), cookies.get('ostype'), cookies.get('deviceid'));
                if (loginResult.Result === 'success') {
                    await store.dispatch(global_data_async(loginResult.Item));
                    CookieApi.SettingCookie(cookies, {
                        UserId: loginResult.Item.UserId,
                        AccountType: loginResult.Item.AccountType,
                        hash: cookies.get('hashcode'),
                        LoginEncrypt: loginResult.Item.LoginEncrypt,
                        OsType: loginResult.Item.OsType,
                        DeviceId: loginResult.Item.DeviceId
                    }, Number(cookies.get('auto_login_chk')))

                    // localStorage에 갱신
                    localStorage.setItem('mosiler_cookie', JSON.stringify({
                        account_type: loginResult.Item.AccountType,
                        deviceid: loginResult.Item.DeviceId,
                        email_id: loginResult.Item.UserId,
                        login_encrypt: loginResult.Item.LoginEncrypt,
                        ostype: loginResult.Item.OsType,
                        auto_login_chk: Number(cookies.get('auto_login_chk')),
                        hashcode: cookies.get('hashcode'),
                    }))

                    this.setState({ 
                        loading:1
                    })

                }
                else {
                    await calertPromise('데이터를 갱신하지 못했습니다.')
                    this.setState({
                        loading:1,
                        pushstatus: !e.target.checked,
                    })
                }
            } else {
                await calertPromise('앱 푸시 알림을 설정하지 못했습니다.')
                this.setState({
                    loading:1,
                    pushstatus: !e.target.checked,
                })
            }           
        } 
        catch(e) {
            await calertPromise('앱 푸시 알림 설정도중 오류가 발생되었습니다. 관리자에게 문의해주세요.')
            this.setState({
                loading:1,
                pushstatus: !e.target.checked,
            })
        }
    }

    handleLogout = async (e) => {
        try {
            e.preventDefault();
            this.setState({loading:0})
            const { cookies } = this.props;
            let logoutResult = await AccountApi.SetLogOut(
                store.getState().global_data.loginedData.UserId,
                cookies.get('ostype'),
                cookies.get('deviceid'),
                cookies.get('login_encrypt')
            );
    
            if (logoutResult.Result === 'success') {
                // 로그인 정보 삭제
                await store.dispatch(global_data_logout_async());
                // 쿠키 정보 삭제
                CookieApi.RemoveCookie(cookies);
                // localStorage상의 쿠키 삭제
                localStorage.removeItem('mosiler_cookie')
                // 현재 FCM Token삭제
                localStorage.removeItem('currentFcmToken')
                // 로그인 화면으로 이동
                this.props.history.replace('/login');
            } else {
                await calertPromise('로그아웃되지 않았습니다.')
                this.setState({loading:1})
            }
        }
        catch(err) {
            await calertPromise('로그아웃되지 않았습니다.')
            console.log('handleLogoutError')
            console.log(err)
            this.setState({loading:1})
        }
        

    }

    handleGotoNavigate = (url) => {
        this.props.history.push(url)
    }

    render() {

        const {latestVersion, deviceVersion} = this.state
        
        return (
            <>
                {!this.state.loading ? (<Loading />) : ''}
                <AppSettingCss />
                
                
                <Header
                    title="설정"
                    onBackButton={this.handleBack}
                    onHomeButton={this.handleHome}
                />
                <main id="Contents">
                    <div className="component-wrap">
                        <ul className="list appset">
                            <li className="item header">로그인</li>
                            <li className="item grid cross-center">
                                <div className="col left">로그인정보</div>
                                <div className="col right">{this.state.emailid}</div>
                            </li>
                            <li className="item grid cross-center">
                                <div className="col left">기기ID</div>
                                <div className="col right">{this.state.deviceid}</div>
                            </li>
                            <li className="item grid cross-center">
                                <div className="col left">OS정보</div>
                                <div className="col right">{ this.getOsTypeFullName()}</div>
                            </li>
                            <li className="item header">알람설정</li>
                            <li className="item grid cross-center">
                                <div className="col left">앱푸시알림</div>
                                <div className="col right">
                                    <span className="setup_check setup_check2">
                                        <span className="check_box">
                                            <input type="checkbox" id="cbx1" checked={this.state.pushstatus} onChange={this.handleChkChange} style={{ display: 'none' }} />
                                            <label for="cbx1" className="setup_toggle"><span></span></label>
                                        </span>
                                    </span>                                    
                                </div>
                            </li>
                            <li className="item header">약관</li>
                            <li className="item">
                                <div class="item-link has-arw" onClick={()=>{this.handleGotoNavigate("/setting/service")}}>
                                    <span>이용약관</span>
                                    <i class="arw arw-more1"></i>
                                </div>
						    </li>
                            <li className="item">
                                <div class="item-link has-arw" onClick={()=>{this.handleGotoNavigate("/setting/privacy")}}>
                                    <span>개인정보처리방침</span>
                                    <i class="arw arw-more1"></i>
                                </div>
						    </li>
                            <li className="item header">편의기능</li>
                            <li className="item">
                                <div class="item-link has-arw" onClick={()=>{this.handleGotoNavigate("/setting/location")}}>
                                    <span>위치정보수집약관</span>
                                    <i class="arw arw-more1"></i>
                                </div>
						    </li>
                            <li className="item header">버전</li>
                            <li className="item grid cross-center">
                                <div className="col left">최신앱버전 {latestVersion.Version}</div>
                                <div className="col right">
                                    {(this.chkVersion(deviceVersion, latestVersion.Version) === SERVER_VER_HIGH) ? (
                                        <span className="st_con">
                                            <a href={latestVersion.StoreUrl} className="btn_update">업데이트</a>
                                        </span>
                                    ) : null}    
                                </div>
                            </li>
                            <li className="item grid cross-center">
                                <div className="col left">현재앱버전 {deviceVersion}</div>
                                <div className="col right text-primary">{ (this.chkVersion(deviceVersion, latestVersion.Version) === SAME_VER) ? '최신 버전입니다.' : '이전 버전입니다.'}</div>
                            </li>
                            <li className="item align-c line-b0">                                
                                <button type="button" className="btn medium primary" onClick={this.handleLogout}><span>로그아웃</span></button>
                            </li>
                        </ul>
                    </div>                    
                </main>


                {/* <b>로그인</b> <br />
                로그인 정보: {this.state.emailid}<br /><br />
                <b>알림설정</b> <br />
                앱푸시알림: <input type="checkbox" checked={this.state.pushstatus} onChange={this.handleChkChange} /><br /><br />
                <b>약관</b> <br />
                <NavLink to={{
                    pathname: '/setting/service',
                }} >이용약관</NavLink><br />
                
                <NavLink to={{
                    pathname: '/setting/privacy',
                }} >개인정보취급방침</NavLink><br />
                <b>편의기능</b> <br />
                
                <NavLink to={{
                    pathname: '/setting/location',
                }} >위치정보수집약관</NavLink><br /> */}
            </>
        );
    }
};

export default withCookies(AppSettingView);
