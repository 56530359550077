import { connect } from "react-redux";
import LocationSelectorAir from "../../components/Reserve/LocationSelectorAir";
import { 
    step_over_add_service, 
    step_over_del_service, 
    start_location_air_service,
    end_location_air_service,
    switching_location_air_service,
} from '../../modules/Reserve/LocationSelector_ReduxModule';
// import { checkbox_service } from "../../modules/MemberJoin/agreebutton";

export default connect(
    state => {

        return {
            AirOptionState: state.LocationSelector_ReduxModule.AirOptionState,
            airStartEndState: state.LocationSelector_ReduxModule.airStartEndState,

            startLocationAddress: state.LocationSelector_ReduxModule.startLocationAddress,
            startLocationLat: state.LocationSelector_ReduxModule.startLocationLat,
            startLocationLng: state.LocationSelector_ReduxModule.startLocationLng,
            
            stopOverList: state.LocationSelector_ReduxModule.stopOverList,
            
            endLocationAddress: state.LocationSelector_ReduxModule.endLocationAddress,
            endLocationLat: state.LocationSelector_ReduxModule.endLocationLat,
            endLocationLng: state.LocationSelector_ReduxModule.endLocationLng
        }
    },
    
    dispatch => ({
        onChangeStartAirport: (val) => dispatch(start_location_air_service(val.target.value)),
        onChangeEndAirport: (val) => dispatch(end_location_air_service(val.target.value)),
        onChangeSwitchingStartEnd: ()=>dispatch(switching_location_air_service()),
        onClickAdd: (data)=>dispatch(step_over_add_service(data)), 
        onClickDel: (idx)=>dispatch(step_over_del_service(idx))
    })
    
    )(LocationSelectorAir);


    


/**,
dispatch  => ({
    checkbox_service: ()=> dispatch(checkbox_service()) // <- "../../modules/MemberJoin/agreebutton"에 있는 type값을 dispatch
}) */
