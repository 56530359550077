import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
body {background:#1fafd2;}

/*Intro */
.intro_box {width:300px; height:125px; top:50%; left:50%; margin:-150px 0 0 -150px; text-align:center; position:absolute;}
.intro_box span.TXST_t1 { font-size:1.3em; font-weight:bold; color:#fff;}
.intro_box span.TXST_t2 { font-size:1.1em; color:#fff; line-height:160%;}
.intro_box span.logo_box { display:inline-block; width:100%; text-align:center; padding:20px 0;}
.intro_box span.logo_box img.logo { width:200px;}
`;