import React, { Component } from 'react';
import { createGlobalStyle } from "styled-components";
import Util, { calert, calertPromise } from '../../lib/Util';
import store from '../../reduxStore'
import moment from 'moment';
import ReserveApi from '../../lib/ReserveApi'
import {
    start_location_service,
    step_over_update_service,
    end_location_service,
} from '../../modules/Reserve/LocationSelector_ReduxModule'
import {
    ADDRESS_SEARCH_STATE_RESERVE_START,
    ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_UPDATE,
    ADDRESS_SEARCH_STATE_RESERVE_END,
    // ADDRESS_SEARCH_STATE_MYINFO,
} from '../../constantData';
import RnInterface from '../../lib/RnInterface'
import Loading from '../../components/Common/Loading'

import marker from '../../img/booking/icon_navi.svg';
import btn_cancel_bold from '../../img/btn/btn_cancel_bold.svg'
// import $script from 'scriptjs';

import '../../css/default.css';
import '../../css/booking.css';

const GlobalStyle = createGlobalStyle`
/* Head */
.head { position:absolute; z-index:1000;}
.head button.btn_style1 { background:rgba(0, 0, 0, 0) !important;}

/* Map Content */
.map_box {padding:0px;}
.map_box li.sch_map { position:absolute; z-index:100px; top:0%; left:0%;}
.map_box li.sch_map iframe { width:100vw; height:calc(100vh - 280px);}

.map_info_box { position:absolute; z-index:1000; background:#fff; width:100vw; height:300px; padding:30px; bottom:0%; border-radius:20px 20px 0 0;}
.map_info_box span.search_result_title {display:inline-block; width:100%; font-size:1.5em; padding-bottom:5px;}
.map_info_box span.address {display:inline-block; width:100%; font-size:1.2em; color:#999;}
.map_info_box span.input_search {display:inline-block; width:100%; padding:20px 0;}
.map_info_box span.input_search input.input_sch { display:inline-block; width:100%; border:1px solid #ddd; height:51px; line-height:51px; padding:15px; font-size:1.2em;}
.map_info_box span. {display:inline-block; width:100%;}

#parent_container { display:inline-block; width:100vw; height:calc(100vh - 280px); background:#ff0000;}
#container { position:relative; display:inline-block; width:100%; height:100%; background:#000;}
#parent_container .marker_center {width:4px; height:4px; line-height:4px; text-align:center; background:#ffff00; z-index:1000; position:absolute; top:50%; left:50%; margin:-2px 0px 0 -2px;}
#parent_container .marker_center img { position:absolute; width:30px; bottom:0%; left:0%; margin:0px 0 1px -13px;}

#parent_container .x_btn {width:4px; height:4px; line-height:4px; text-align:center; background:#ffff00; z-index:1000; position:absolute; top:40px; left:30px; margin:-2px 0px 0 -2px; }
#parent_container .x_btn img { position:absolute; width:25px; bottom:0%; left:0%; margin:0px 0 1px -13px; -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .35));   filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .35)); }

`;

const INIT_LATLNG = {
    lat: 37.566907531463585,
    lng: 126.97857866109217
}

class MapSelect extends Component {

    state = {
        lat: 0,
        lng: 0,
        address: '',
        redirectUri: '',
        state: '',
        loading:1,

    }


    /*
    이 화면은 향후 map picker로 활약하게 될지도?
    redux에 맵의 위도경도 정보와 주소 정보를 집어넣을 수 있도록.
    */

    handleClick = () => {
        let building_name = window.jQuery('#buildingname_hidden').val();
        let new_address = window.jQuery('#new_address_hidden').val();
        let old_address = window.jQuery('#old_address_hidden').val();
        let detail_address = window.jQuery('#detail_address').val();

        let lat = parseFloat(window.jQuery('#lat').val());
        let lng = parseFloat(window.jQuery('#lng').val());
        let resultAddressData = 
            (new_address.length ? new_address : old_address) + 
            ' ' + 
            (building_name !== '' ? building_name + ' ' : '') + 
            
            detail_address;      
        
        
        let tmpHistoryAddressList = localStorage.getItem('history_address_search');
        let resultData = [];

        if (tmpHistoryAddressList === null) {
            tmpHistoryAddressList = []
        } else {
            tmpHistoryAddressList = JSON.parse(tmpHistoryAddressList)
        }

        let newData = {
            searchKeyword: '(지도로 선택)',
            new_address: resultAddressData,
            old_address: resultAddressData,
            place_name: '', 
            lat,
            lng,
            date: moment().format()
        }      
        
        resultData = [newData, ...tmpHistoryAddressList];


        const { state } = this.state;
        // 출발지
        if (state === ADDRESS_SEARCH_STATE_RESERVE_START) {
            
            if (ReserveApi.isChkLimitLocal(resultAddressData)) {
                store.dispatch(start_location_service({
                    startLocationAddress: resultAddressData,
                    startLocationLat: lat,
                    startLocationLng: lng,
                }))
            }
            else {
                calert('출발지는 서울, 경기, 인천만 가능합니다.');
                return;
            }
            
        }
        // 종료지
        else if (state === ADDRESS_SEARCH_STATE_RESERVE_END) {
            store.dispatch(end_location_service({
                endLocationAddress: resultAddressData,
                endLocationLat: lat,
                endLocationLng: lng,
            }))
        }
        // 경유지 변경
        else if (state.indexOf(ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_UPDATE) !== -1) {
            let idxData = parseInt(state.split('@@')[1])
            store.dispatch(step_over_update_service(idxData, {
                waypointAddress: resultAddressData,
                waypointLat: lat,
                waypointLng: lng,
            }))
        }



        localStorage.setItem('history_address_search', JSON.stringify(resultData))

        this.props.history.replace(`${this.state.redirectUri}`);
        //state상태에 따라서 처리하도록한다.

    }

    handleDetailAddress = (e) => {
        this.setState({ detailAddress: e.target.value })
    }

    async componentDidMount() {
        // let mylatlng = {};
        const {
            startLocationLat,
            startLocationLng,
            stopOverList,
            endLocationLat,
            endLocationLng
        } = store.getState().LocationSelector_ReduxModule

        try {
            let data = Util.getQueryStringObject();
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            // // 현재 위치를 native로부터 받아온다.
            // let mylatlng = JSON.parse(await RnInterface.getData('getDeviceLatLng'))

            // // native에서 데이터를 잘 받아왔다면?
            // if (mylatlng !== false) {

            // } 
            // // native에서 데이터를 잘 받아왔다면?
            // else {

            // }
           
            this.setState({
                redirectUri: data.redirect,
                state: data.state
            })
            // console.log(window.kakao);
            // console.log(window.Kakao);
            // alert(JSON.stringify(mylatlng))
            
            let mylatlng = false;
            let latlngParse = {
                lat:INIT_LATLNG.lat,
                lng:INIT_LATLNG.lng
            };


            let initLat = INIT_LATLNG.lat
            let initLng = INIT_LATLNG.lng

            // 출발지
            if (data.state === ADDRESS_SEARCH_STATE_RESERVE_START) {
                // 출발지가 지정안되어있을 경우에는 디바이스의 위/경도 데이터를 취득한다.
                // 출발지가 지정되어있을 경우는 처리하지 않는다.
                if (startLocationLat === 0) {
                    this.setState({ loading: 0 })
                    // 디바이스에서 latlng정보 취득 및 초기화
                    try {
                        mylatlng = await RnInterface.getData('getDeviceLatLng');
                        if (mylatlng !== false) { latlngParse = JSON.parse(mylatlng) }
                    } catch (e) { console.log(e) }
                    this.setState({ loading: 1 })
                }
                


                initLat = startLocationLat !== 0 ? startLocationLat : (mylatlng !== false ? latlngParse.lat : INIT_LATLNG.lat)
                initLng = startLocationLng !== 0 ? startLocationLng : (mylatlng !== false ? latlngParse.lng : INIT_LATLNG.lng)
            }
            // 종료지
            else if (data.state === ADDRESS_SEARCH_STATE_RESERVE_END) {
                // 종료지가 지정안되어있을 경우에는 디바이스의 위/경도 데이터를 취득한다.
                // 종료지가 지정되어있을 경우는 처리하지 않는다.
                if (endLocationLat === 0) {
                    this.setState({ loading: 0 })
                    // 디바이스에서 latlng정보 취득 및 초기화
                    try {
                        mylatlng = await RnInterface.getData('getDeviceLatLng');
                        if (mylatlng !== false) { latlngParse = JSON.parse(mylatlng) }
                    } catch (e) { console.log(e) }
                    this.setState({ loading: 1 })
                }

                initLat = endLocationLat !== 0 ? endLocationLat : (mylatlng !== false ? latlngParse.lat : INIT_LATLNG.lat)
                initLng = endLocationLng !== 0 ? endLocationLng : (mylatlng !== false ? latlngParse.lng : INIT_LATLNG.lng)
            }
            // 경유지 변경
            else if (data.state.indexOf(ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_UPDATE) !== -1) {
                let idxData = parseInt(data.state.split('@@')[1])

                // 경유지가 지정안되어있을 경우에는 디바이스의 위/경도 데이터를 취득한다.
                // 경유지가 지정되어있을 경우는 처리하지 않는다.
                if (stopOverList[idxData].waypointLat === '0') {
                    this.setState({ loading: 0 })
                    // 디바이스에서 latlng정보 취득 및 초기화
                    try {
                        mylatlng = await RnInterface.getData('getDeviceLatLng');
                        if (mylatlng !== false) { latlngParse = JSON.parse(mylatlng) }
                    } catch (e) { console.log(e) }
                    this.setState({ loading: 1 })
                }
                
                initLat = stopOverList[idxData].waypointLat!=='0' ? stopOverList[idxData].waypointLat : (mylatlng !== false ? latlngParse.lat : INIT_LATLNG.lat)
                initLng = stopOverList[idxData].waypointLng!=='0' ? stopOverList[idxData].waypointLng : (mylatlng !== false ? latlngParse.lng : INIT_LATLNG.lng)
            } else {
                initLat = INIT_LATLNG.lat
                initLng = INIT_LATLNG.lng
            }


            // 만약 맵이 표시 안될 경우 가장 첫번째로 확인해야할 것은
            // kakao 개발자 페이지에 URL이 등록되어있는지 꼭 확인해야한다.
            let KAKAO = window.kakao;
            var geocoder = new KAKAO.maps.services.Geocoder();

            var mapContainer = document.getElementById('container'), // 지도를 표시할 div 
                mapOption = {
                    center: new KAKAO.maps.LatLng(initLat, initLng), // 지도의 중심좌표
                    level: 3 // 지도의 확대 레벨
                };

            var map = new KAKAO.maps.Map(mapContainer, mapOption); // 지도를 생성합니다

            geocoder.coord2Address(initLng, initLat, function (result, status) {
                if (status === KAKAO.maps.services.Status.OK) {
                    try {
                        document.getElementById('new_address').innerHTML = result[0].road_address.address_name
                        document.getElementById('building_name').innerHTML = result[0].road_address.building_name
                        document.getElementById('old_address').innerHTML = result[0].address.address_name

                        window.jQuery('#old_address_hidden').val(result[0].address.address_name)
                        window.jQuery('#new_address_hidden').val(result[0].road_address.address_name)
                        window.jQuery('#buildingname_hidden').val(result[0].road_address.building_name);
                        window.jQuery('#lat').val(initLat);
                        window.jQuery('#lng').val(initLng);
                    }
                    catch (e) {
                        console.log(e)
                        document.getElementById('new_address').innerHTML = ''
                        document.getElementById('building_name').innerHTML = result[0].address.address_name
                        document.getElementById('old_address').innerHTML = ''

                        window.jQuery('#old_address_hidden').val(result[0].address.address_name)
                        window.jQuery('#new_address_hidden').val('')
                        window.jQuery('#buildingname_hidden').val('');
                        window.jQuery('#lat').val(initLat);
                        window.jQuery('#lng').val(initLng);
                    }
                }
            });


            // 마우스 드래그로 지도 이동이 완료되었을 때 마지막 파라미터로 넘어온 함수를 호출하도록 이벤트를 등록합니다
            KAKAO.maps.event.addListener(map, 'dragend', function () {
                // 지도 중심좌표를 얻어옵니다 
                var latlng = map.getCenter();
                var callback = function (result, status) {
                    if (status === KAKAO.maps.services.Status.OK) {
                        try {
                            document.getElementById('new_address').innerHTML = result[0].road_address.address_name
                            document.getElementById('building_name').innerHTML = result[0].road_address.building_name
                            document.getElementById('old_address').innerHTML = result[0].address.address_name

                            window.jQuery('#old_address_hidden').val(result[0].address.address_name)
                            window.jQuery('#new_address_hidden').val(result[0].road_address.address_name)
                            window.jQuery('#buildingname_hidden').val(result[0].road_address.building_name);
                        }
                        catch (e) {
                            console.log(e)
                            document.getElementById('new_address').innerHTML = ''
                            document.getElementById('building_name').innerHTML = result[0].address.address_name
                            document.getElementById('old_address').innerHTML = ''

                            window.jQuery('#old_address_hidden').val(result[0].address.address_name)
                            window.jQuery('#new_address_hidden').val('')
                            window.jQuery('#buildingname_hidden').val('');
                        }
                    }
                };

                window.jQuery('#lat').val(latlng.getLat());
                window.jQuery('#lng').val(latlng.getLng());
                geocoder.coord2Address(latlng.getLng(), latlng.getLat(), callback);

                // var message = '변경된 지도 중심좌표는 ' + latlng.getLat() + ' 이고, ';
                // message += '경도는 ' + latlng.getLng() + ' 입니다';

                // var resultDiv = document.getElementById('result');
                // resultDiv.innerHTML = message;

            });

            // 지도가 확대 또는 축소되면 마지막 파라미터로 넘어온 함수를 호출하도록 이벤트를 등록합니다
            KAKAO.maps.event.addListener(map, 'zoom_changed', function () {

                // 지도 중심좌표를 얻어옵니다 
                var latlng = map.getCenter();
                var callback = function (result, status) {
                    if (status === KAKAO.maps.services.Status.OK) {
                        try {
                            document.getElementById('new_address').innerHTML = result[0].road_address.address_name
                            document.getElementById('building_name').innerHTML = result[0].road_address.building_name
                            document.getElementById('old_address').innerHTML = result[0].address.address_name

                            window.jQuery('#old_address_hidden').val(result[0].address.address_name)
                            window.jQuery('#new_address_hidden').val(result[0].road_address.address_name)
                            window.jQuery('#buildingname_hidden').val(result[0].road_address.building_name);

                        }
                        catch (e) {
                            console.log(e)
                            document.getElementById('new_address').innerHTML = ''
                            document.getElementById('building_name').innerHTML = result[0].address.address_name
                            document.getElementById('old_address').innerHTML = ''

                            window.jQuery('#old_address_hidden').val(result[0].address.address_name)
                            window.jQuery('#new_address_hidden').val('')
                            window.jQuery('#buildingname_hidden').val('');
                        }


                    }
                };
                window.jQuery('#lat').val(latlng.getLat());
                window.jQuery('#lng').val(latlng.getLng());

                geocoder.coord2Address(latlng.getLng(), latlng.getLat(), callback);

                // var message = '변경된 지도 중심좌표는 ' + latlng.getLat() + ' 이고, ';
                // message += '경도는 ' + latlng.getLng() + ' 입니다';

                // var resultDiv = document.getElementById('result');
                // resultDiv.innerHTML = message;

            });

        } catch (e) {
            await calertPromise(JSON.stringify(e))
            this.setState({ loading:1 })
        }


        // const kakaomap_url = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=886a0aa4b33e8a2538fdbf6ed97c7722&libraries=services,clusterer,drawing`;
        // // 이처리를 넣은 이유는 index.html에 넣어보니 한번 로드되고 화면 전환한 다음 다시 로그인 화면으로 돌아올때 apple login 화면이 나오지 않아서 
        // // 로그인화면이 로드 될때 본 스크립트를 호출해서 아이콘이 표시되도록 한다.
        // $script(kakaomap_url, ()=>{


        //     // 지도를 확대 축소할때도 이벤트 처리가 필요하다.
        // });






    }

    render() {
        return (
            <>
                <GlobalStyle />
                {!this.state.loading ? (<Loading />) : ''}
                <div className="booking_sch">

                    <ul className="map_box">
                        <li className="sch_map">
                            <div id="parent_container">
                                <div id="container">
                                    <div className="x_btn" onClick={()=>{this.props.history.replace(`${this.state.redirectUri}`);}}><img src={btn_cancel_bold} class="hd_icon" alt="" /></div>
                                    <div className="marker_center"><img src={marker} alt="" /></div>
                                    
                                </div>
                            </div>
                        </li>

                        <li className="map_info_box">
                            <span id="building_name" className="search_result_title">학동역 7호선</span>
                            <span className="address">
                                <span id="new_address">서울 서대문구 증고로1길 40-17</span><br />
                                <span id="old_address">(지번) 논현동 279-67</span>
                            </span>
                            <span className="input_search">
                                <input
                                    type="text"
                                    id="detail_address"
                                    className="input_sch"
                                    placeholder="상세주소를 입력하세요 (건물명, 동/호수 등)"
                                />
                            </span>
                            <span className="btn_box">
                                <button className="btn_st_1fafd2" onClick={this.handleClick}>선택한 위치로 설정</button>
                            </span>

                        </li>
                    </ul>
                    <input type="hidden" id="lat" value="" />
                    <input type="hidden" id="lng" value="" />
                    <input type="hidden" id="old_address_hidden" value="" />
                    <input type="hidden" id="new_address_hidden" value="" />
                    <input type="hidden" id="buildingname_hidden" value="" />
                </div>
            </>
        );
    }
};

export default MapSelect;

