import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import axios from 'axios'
import Util from '../lib/Util'
import Header from '../components/Common/Header'
import querystring from 'qs'
import { Redirect } from 'react-router-dom';
import store from '../reduxStore'
import CookieApi from '../lib/CookieApi'
import AccountApi from '../lib/AccountApi'
import {global_data_async} from '../modules/Global/global_data'
import {calert, calertConfirm} from '../lib/Util'


const KAKAO_AUTH = 'https://kauth.kakao.com';
const KAKAO_API = 'https://kapi.kakao.com'
const REDIRECT_URI = 'http://192.168.219.100:3000/oauth'
// const REDIRECT_URI = 'https://devclient.mosiler.com/oauth'



class oauthTest extends Component {

    state = {
        redirectUri:'',
        flag:0
    }

    redirectJoinView = (params) => {
        return new Promise ((resolve) => {
            this.setState({redirectUri: '/join/2', flag:1},()=>{resolve(true)})
        })   
    }

    redirectLoginView = (params) => {
        return new Promise ((resolve) => {
            this.setState({redirectUri: '/login', flag:1},()=>{resolve(true)})
        })   
    }

    redirectMainView = (params) => {
        return new Promise ((resolve) => {
            this.setState({redirectUri: '/main', flag:1},()=>{resolve(true)})
        })   
    }

    getKakaoData = (params) => {
        return new Promise ((resolve, reject) => {
            window.Kakao.API.request({
                url: '/v2/user/me',
                success: function(response) {
                    resolve(response)
                },
                fail: function(error) {
                    return reject(error)
                }
            });
        })
    }

    async componentDidMount () {


        /*
        * 처리 흐름 설명
        1. LoginView.js에서 "카카오로 시작하기"를 누르면 Kakao.Auth.authorize함수가 호출되어 지정한 리다이렉트 위치로 이동된다. 그 곳이 이곳이다.
        2. 리다이랙트시 http://~~~/oauth?code=sdsdlfkjsdlkfjslkj 이런형태로 넘어오는 것을 Util.getQueryStringObject() 함수로 객체화시킨다.
        3. tmpData에 요청할 데이터를 가공하고 querystring.stringify(tmpdata)로 변환한뒤, /oauth/token로 access token을 요청한다.
        4. status === 200 이라면 kakao 객체에 setAccessToken 함수를 통해 세팅한다. (REST방식으로 요청하면 CORS문제가 발생)
        5. getKakaoData() 함수를 통해 로그인 요청한 사람의 정보를 취득한다.
        6. 고유 id값이 존재한다면 모시러 API를 통해 kakao login을 시도한다.
        7. 로그인이 되었다면 redux store에 로그인정보를 등록하고, 쿠키 세팅실시, main화면으로 리다이렉트
        
        
        */
        try {
            let data = Util.getQueryStringObject();
            
            if (data.code !== undefined && data.code.length) {
                let tmpdata = {
                    grant_type: 'authorization_code',
                    client_id: '15f53afbd4c5f3d702733ae729538c9e',
                    redirect_uri: process.env.REACT_APP_KAKAO_REDIRECT_URI,
                    code: data.code,
                }
                

                let auth1Result = await axios({
                    url: `${KAKAO_AUTH}/oauth/token`,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                    },
                    data: querystring.stringify(tmpdata)
                })
                

                if (auth1Result.status === 200) {
                    // access token을 세팅하는 단계에서는 웹상에서 REST API 처리하면 CORS문제가 발생한다. 
                    // 따라서 여기서부터는 JS SDK를 활용한다.
                    window.Kakao.Auth.setAccessToken(auth1Result.data.access_token);
                    let final_result = await this.getKakaoData()

                    if (final_result.id !== undefined) {
                        let login_result = await AccountApi.requestLogin('kakao@' + final_result.id, '', 'kakao')
            
                        // 로그인 성공시
                        if (login_result.Result === 'success') {
                            let {cookies} = this.props;
                            await store.dispatch(global_data_async(login_result.Item));

                            CookieApi.SettingCookie(cookies, {
                                UserId : login_result.Item.UserId,
                                AccountType : login_result.Item.AccountType,
                                hash : '',
                                LoginEncrypt : login_result.Item.LoginEncrypt,
                                OsType : login_result.Item.OsType,
                                DeviceId : login_result.Item.DeviceId
                            })
                            await this.redirectMainView()
                        } 
                        // 로그인 실패시는 회원가입 화면으로 이동
                        else {
                            localStorage.setItem('AccountType','kakao');
                            localStorage.setItem('c_emailid', ('kakao@' + final_result.id) );
                            localStorage.setItem('c_password',"" );
                            localStorage.setItem('emailaddress', final_result.kakao_account.email);
                            await this.redirectJoinView()
                        }
                        
                    } 
                    // getKakaoData() 처리 실패시
                    else {
                        await calertConfirm('카카오 사용자 정보를 받을 수 없습니다. 다시 시도해주세요.');
                        await this.redirectLoginView()
                    }
                }
                // /oauth/token REST_API 요청 처리 실패시 
                else {
                    await calertConfirm('카카오 인증에 실패하였습니다. 다시 시도해주세요.');
                    await this.redirectLoginView()
                }
            } 
            // code(인가토큰)값을 못받아왔을때
            else {
                await calertConfirm('잘못된 접근입니다. 로그인화면으로 이동합니다.');
                await this.redirectLoginView()
            }

        } catch(e) {}
    }

    render() {
        if (!this.state.flag) return (<></>);
        return (
            <Redirect to={this.state.redirectUri} />
        );
    }
};

export default withCookies(oauthTest);
