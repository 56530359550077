import React, { Component } from "react";
import { withCookies } from "react-cookie";
import Header from "../../components/Common/Header";
import Iframe from "react-iframe";
import { createGlobalStyle } from "styled-components";
import RnInterface from "../../lib/RnInterface";
import Util, { calert, calertPromise, isChkfeatureSupportVersion } from '../../lib/Util'

// todocss
const GlobalStyle = createGlobalStyle`

.iframe_area { 
    display: block;
    
    border: 0px;
    width: 100%;
    height: calc(100vh - 53px);    
}
/*
.appsettingview_container { display:flex; width:100%; height:100vh; overflow-y:hidden }
.iframe_area::-webkit-scrollbar {
    width:5px;
}
.iframe_area::-webkit-scrollbar-thumb {
    background-color: #2f3542;
    border-radius: 15px;
}
*/
`;


class CommonLinkView extends Component {
    state = {
        link:'',
        title:'',
    }
    async componentDidMount() {
        try {
            await RnInterface.AndroidURLChange(this.props.location.pathname);
            
            let data = Util.getQueryStringObject();

            this.setState({ 
                link: data.redirect, 
                title: data.title, 
            });

            // 메시지 수신 받는 eventListener 등록
            window.addEventListener( 'message', this.receiveMsgFromChild );
       
        } catch (e) {
            console.log(e);
        }
    }

    // 자식으로부터 메시지 수신
    receiveMsgFromChild = async (e) => {
        try {
            if (e.data && e.data.indexOf('GOTO_RESERVATION_GOLF||SUCCESS') !== -1) {
                this.props.history.replace('/main')
                this.props.history.push('/reserve_renew/3/1')
            }
            else if (e.data && e.data.indexOf('GOTO_KAKAO_CHANNEL||SUCCESS') !== -1) {             
                const { cookies } = this.props;
                // 카톡 링크
                const kakaoTalkLinkiOS = 'https://apps.apple.com/kr/app/%EC%B9%B4%EC%B9%B4%EC%98%A4%ED%86%A1-kakaotalk/id362057947'
                const kakaoTalkLinkAndroid = 'https://play.google.com/store/apps/details?id=com.kakao.talk'
                
                // 모시러 링크
                const mosilerLinkiOS = 'https://apps.apple.com/kr/app/%EB%AA%A8%EC%8B%9C%EB%9F%AC-%EC%9A%B4%EC%A0%84-%EB%8F%99%ED%96%89-%EC%84%9C%EB%B9%84%EC%8A%A4/id1366011185'
                const mosilerLinkAndroid = 'https://play.google.com/store/apps/details?id=com.mosiler.client'
                
                let osversion = await RnInterface.getData('devicetype')
                
                //1.3.20 버전 이후일 경우
                if (isChkfeatureSupportVersion('1.3.20')) {
                    try {       
                        // 카톡채널 딥링크 오픈
                        let result = await RnInterface.getData('showkakaotalkchannel');
                        let resultJSON = JSON.parse(result);
                        
                        // 결과가 OK가 아닐경우(열리지 않았을 경우)
                        if (resultJSON.result !== 'OK') {
                            await calertPromise('현재 카카오톡이 미설치 되어 있습니다. 설치 후 다시 시도해주세요.')
                            // 카톡 링크를 OS별로 표시한다.
                            if (osversion === 'I') {
                                await RnInterface.callReactNative(`NewIntentLinkBrowsing${kakaoTalkLinkiOS}`)
                            }
                            else {
                                await RnInterface.callReactNative(`NewIntentLinkBrowsing${kakaoTalkLinkAndroid}`)
                            }
                        }
                    }
                    catch(e) {
                        // 딥링크로 카톡 오픈 중 문제 발생시.
                        await calertPromise('카카오톡을 열 수 없습니다.')
                    }
                }
                // 1.3.20 이전 버전일때
                else {
                    await calertPromise('고객앱 1.3.20 업데이트 후 사용 가능합니다. 고객앱을 업데이트 해주세요.')
                    // OS별로 모시러 링크로 이동.
                    if (osversion === 'I') {
                        await RnInterface.callReactNative(`NewIntentLinkBrowsing${mosilerLinkiOS}`)
                    }
                    else {
                        await RnInterface.callReactNative(`NewIntentLinkBrowsing${mosilerLinkAndroid}`)
                    }
                }
            }
        }
        catch(e) {
            console.log(e)
            
        }
    }

    componentWillUnmount () {
        window.removeEventListener("message", this.receiveMsgFromChild);
    }

    handleBack = () => {
        this.props.history.goBack();
    };

    handleHome = () => {
        this.props.history.replace("/main");
    };

    render() {
        return (
            <>
                <GlobalStyle />
                <Header
                    title={this.state.title}
                    onBackButton={this.handleBack}
                    // onHomeButton={this.handleHome}
                />
                <main id="Contents">
                    <div className="component-wrap  detail-view">
                        <Iframe 
                            url={this.state.link} 
                            id="iframe_id" 
                            className="iframe_area " />
                    </div>
                
                </main>

                {/* <div className="appsettingview_container hd_margin_top" >
                    
                </div> */}
            </>
        );
    }
}

export default withCookies(CommonLinkView);
