import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
/* payment.css */
@charset "utf-8";

/* 결제하기 */
.payment {display:inline-block; width:100%; min-height:calc(100vh - 53px); background:#ebebed; padding:0px;}
.payment li.record_box { display:inline-block; width:100%; margin-bottom:0px;}

.payment span.record_hd { display:inline-block; width:100%; padding:10px 20px 10px 20px; border-bottom:1px solid #eee; margin-bottom:6px; background:#fff;}
.payment span.record_hd_color1 {border-left:5px solid #1fafd2 !important}
.payment span.record_hd_color2 {border-left:5px solid #718ae4 !important}
.payment span.record_hd span.date { display:inline-block; width:100%; font-size:1.1em; padding-bottom:0px;}
.payment span.record_hd span.cancel {color:#ff0000 !important}
.payment span.record_hd span.subject { display:inline-block; width:100%; padding:10px 0;}
.payment span.record_hd span.subject span.kind { display:inline-block; font-size:1.5em; font-weight:bold; padding-right:20px;}
.payment span.record_hd span.subject span.number { display:inline-block; font-size:1.2em; font-weight:bold; margin-top:-5px;}
.payment span.record_hd span.subject span.number span.line { display:inline-block; width:1px; height:10px; margin:0 7px; border-right:1px solid #ddd;}
.payment span.record_hd span.subject button.btn_more { display:inline-block; float:right; background:#fff; border:0px;}
.payment span.record_hd span.subject button.btn_more img {width:10px;}

.payment table.rtable {width:100%; font-size:1.2em;}
.payment table.rtable th {width:90px; padding:0px 0; font-weight:normal; color:#999; vertical-align:middle;}
.payment table.rtable td {padding:0px 0; vertical-align:middle;}
.payment table.rtable th.price {color:#718ae4 !important; font-weight:bold;}
.payment table.rtable td.price {color:#718ae4; font-weight:bold; font-size:1.3em; text-align:right;}
.payment table.rtable td.cnumber {color:#999; padding:0px 0 5px 0;}

.payment span.navi_info2 { display:inline-block; width:100%; padding:5px 0 0px 0; margin:0px;}

.payment span.record_pay { display:inline-block; width:100%; padding:15px 25px;}


/* 이용내역 - 자세히보기 */
.payment span.record_detail2 { display:inline-block; width:100%; padding:10px 0px 0px 0px; border-bottom:1px solid #eee; background:#fff; margin-top:6px;}
.payment span.record_detail2 span.section_title_payment { display:inline-block; width:100%; font-size:1.3em; padding:10px 20px 15px 20px; border-bottom:1px solid #eee;}
.payment span.record_detail2 span.section_title_payment button.btn_sel { display:inline-block; height:25px; line-height:25px; float:right; font-size:0.8em; background:#fff; border:0px; margin:-3px 0 0 0;}
.payment span.record_detail2 span.section_title_payment button.btn_sel img.sel_arrow {width:10px; margin-top:-4px; margin-left:5px; transform:rotate(90deg)}
.payment span.record_detail2 span.section_title_payment button {}

.payment span.record_detail2 span.place_box { display:inline-block; width:100%; border-top:1px solid #eee; border-bottom:1px solid #eee; margin:-4px 0 0 0; background:#fff;}
.payment span.record_detail2 span.place_box span.place_start { display:inline-block; width:50%; float:left; padding:20px; border-right:1px solid #eee;}
.payment span.record_detail2 span.place_box span.place_end {display:inline-block; width:50%; float:left; padding:20px;}
.payment span.record_detail2 span.place_box span.place_title { display:block; font-size:1.2em; color:#999;}
.payment span.record_detail2 span.place_box span.place_address { display:block; padding:10px 0 0 0;}
.payment span.record_detail2 span.place_box span.place_address img {width:10px; margin-right:5px;}

.payment span.record_detail2 span.sheet { display:inline-block; width:100%; padding:15px 20px;}
.payment span.record_detail2 span.sheet span.notice_service { display:inline-block; width:100%; text-align:right; color:#1fafd2;}

.payment table.rtable2 {width:100%; font-size:1.2em;}
.payment table.rtable2 th {width:90px; padding:5px 0; font-weight:normal; color:#999;}
.payment table.rtable2 td {padding:5px 0; text-align:left;}
.payment table.rtable2 td.price {font-size:1em; font-weight:bold;}
.payment table.rtable2 th.result {color:#fff !important;}
.payment table.rtable2 td.result_price {font-size:1.3em; font-weight:bold;}
.payment table.rtable2 td.result_kind {font-size:0.9em; padding:10px 0 5px 0;}
.payment table.rtable2 td.result_sprice {font-size:0.9em; padding:0 0 5px 0;}
.payment table.rtable2 td.result_date {font-size:0.9em; color:#9397a2;}

.payment table.rtable3 {width:100%; font-size:1.1em;}
.payment table.rtable3 td {padding:0px 0 10px 0;}
.payment table.rtable3 td.first { vertical-align:top; width:20px; padding:7px 0;}
.payment table.rtable3 td.first img {width:10px;}
.payment table.rtable3 td:nth-child(2) {padding-bottom:0px !important;}

.payment span.navi_info { display:inline-block; width:100vw; background:#f2f8fe; padding:20px; margin:0px; margin-top:-3px;}

.payment li.price_result { display:inline-block; width:100%; background:#4d5467; padding:20px; color:#fff;}


/* Form Type */
input.input_fm1 { display:inline-block; width:100%; border:1px solid #ddd; font-size:1.2em; height:41px; line-height:41px; padding:0 15px;}
select.input_fm1{ display:inline-block; width:100%; border:1px solid #ddd; font-size:1em; height:41px; line-height:41px; padding:0 15px;}
textarea.frm1 { display:inline-block; width:100%; height:200px; padding:20px; box-sizing:border-box; border:1px solid #ddd;}
.btn_st_2e303f { display:inline-block; width:100%; background:#2e303f; height:50px; line-height:50px; font-size:1.3em; border:0px; color:#fff;}
.btn_st_1fafd2 { display:inline-block; width:100%; background:#1fafd2; height:50px; line-height:50px; font-size:1.3em; border:0px; color:#fff;}
.btn_st_ffffff { display:inline-block; width:100%; background:#fff; height:50px; line-height:50px; font-size:1.3em; border:0px; color:#000;}
.btn_st2_ffffff { display:inline-block; width:100%; background:#fff; height:50px; line-height:50px; font-size:1.3em; border:1px solid #000; color:#000;}
.btn_st1_1fafd2 { display:inline-block; background:#1fafd2; height:45px; line-height:45px; font-size:1.1em; border:0px; color:#fff; padding:0 30px; border-radius:50px; font-weight:bold;}

.btn_st_49 {width:49% !important; margin-right:2%; float:left;}
.btn_st_50 {width:49% !important; float:left;}

/* Button Box */
.payment li.btn_box { display:inline-block; width:100%; padding:20px; background:#fff; position:relative; margin-top:-4px;} /* 12.24 */
`;