import React, { Component } from 'react';

import { ADDRESS_SEARCH_STATE_MYINFO } from '../../constantData'
import store from '../../reduxStore'
import AccountApi from '../../lib/AccountApi'
import {withCookies} from 'react-cookie'
import Loading from '../../components/Common/Loading'
import {calert, calertPromise} from '../../lib/Util'
import RnInterface from '../../lib/RnInterface'
import HeaderJoin2 from '../../components/Common/HeaderJoin2'
import MemberJoinCustomCss from '../../css/CustomGlobalStyle/MemberJoinCustom'
// import {address_del_service} from '../../modules/Global/Address_ReduxModule'


import '../../css/default.css';
import '../../css/member.css';

//view_~~~ 로 시작하는 파일은 html inline에 style로 정해놓은 것을 css화 시킨 것이다.
// import '../css/view_login.css';

import btn_prev from '../../img/btn/btn_prev.svg';


/*

><
\n<

검색 : <input.+?>
치환 : $0>>

검색 : >>>
치환 : />

검색 : <img.+?>
치환 : $0>>

검색 : >>
치환 :  alt=""/>

*/

class MainMenuMemberChangeInfoChangeAddress extends Component {
    state = {
        oldaddress:'',
        address:'',
        detailAddress:'',
        loading:0
    }

    async componentDidMount() {
        try {
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            const { addressData } = store.getState().Address_ReduxModule;

            this.setState({
                oldaddress: store.getState().global_data.loginedData.HomeAddress,
                address: addressData,
                loading: 1
            })
        }
        catch (e) {
            console.log(e)
        }

    }
    
    handleZipCode = (params) => { this.setState({zipCode : params.target.value}) }
    handleAddress = (params) => { this.setState({address : params.target.value}) }
    handleDetailAddress = (params) => { this.setState({detailAddress : params.target.value}) }
    handleAddressPicker = (params) => { this.props.history.replace(`/address_search?redirect=myinfo_changeaddress&state=${ADDRESS_SEARCH_STATE_MYINFO}`) }


    
    handleNext = async () => {
        this.setState({loading:0})
        if (this.state.address.length === 0) {
            await calertPromise('기본주소를 입력해주세요.')
            this.setState({loading:1})
            return ;
        }
        if (this.state.detailAddress.length === 0) {
            await calertPromise('상세주소를 입력해주세요.')
            this.setState({loading:1})
            return ;
        }

        let {cookies} = this.props;
        let { address, detailAddress } = this.state;
        
        //로직 구현
        let CustomerNo = store.getState().global_data.loginedData.CustomerNo
        let CustomerName = store.getState().global_data.loginedData.CustomerName
        let Email = store.getState().global_data.loginedData.Email
        let PhoneNumber = store.getState().global_data.loginedData.PhoneNumber
        let CarName = store.getState().global_data.loginedData.CarName
        let CarNo = store.getState().global_data.loginedData.CarNo

        // 변경 데이터 세팅
        let formData = {
            CustomerNo,
            CustomerName,
            Email,
            PhoneNumber,
            HomeAddress: `${address} ${detailAddress}`,
            CarName,
            CarNo,
        }

        try {
            // 업데이트 API 실행
            let updateResult = await AccountApi.UpdateCustomer(
                formData, 
                cookies.get('ostype'),
                cookies.get('deviceid'),
                cookies.get('login_encrypt')
            )

            // 업데이트 성공시
            if (updateResult.Result === 'success') {
                // 로그인 시도(로그인 성공시 데이터 취득함.)
                let loginResult = await AccountApi.requestLogin(
                    cookies.get('email_id'), 
                    cookies.get('hashcode'), 
                    cookies.get('account_type'), 
                    cookies.get('ostype'), 
                    cookies.get('deviceid')
                );
                    
                if (loginResult.Result === 'success') {
                    // localStorage에 갱신
                    localStorage.setItem('mosiler_cookie', JSON.stringify({
                        account_type: loginResult.Item.AccountType,
                        deviceid: loginResult.Item.DeviceId,
                        email_id: loginResult.Item.UserId,
                        login_encrypt: loginResult.Item.LoginEncrypt,
                        ostype: loginResult.Item.OsType,
                        auto_login_chk: Number(cookies.get('auto_login_chk')),
                        hashcode: cookies.get('hashcode'),
                    }))
                    
                    // 사용자 정보를 redux, cookie에 세팅한다.
                    AccountApi.updateMyInfo(loginResult, cookies.get('hashcode'), cookies);
                    await calertPromise('설정이 완료 되었습니다.')
                    this.props.history.goBack()
                    
                } 
                // 로그인 실패시
                else {
                    await calertPromise('데이터 취득에 실패하였습니다.')
                    this.setState({loading:1})
                }
            } 
            // 업데이트 실패시
            else {
                await calertPromise('업데이트에 실패하였습니다.')
                this.setState({loading:1})
            }
        } 
        catch(e) {
            await calertPromise(JSON.stringify(e));
            this.setState({loading:1})
        }
        

    }
    handlePrev = () => {
        
        this.props.history.goBack();
    }
    

    render() {
        return (
            <>
            {!this.state.loading ? (<Loading />) : ''}
            <MemberJoinCustomCss />
                <HeaderJoin2
                    onBackButton={this.handlePrev}
                    title={''} />
                <main id="Contents">
                    <div className="key-visual-wrap">
                        <div className="key-visual-container">
                            <div className="key-contents">
                                <div className="key-text">
                                    <strong>회원님의 주소를 변경해주세요.</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="component-wrap inner-container">
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">현재 주소</label>									
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <input type="text" placeholder="기본주소" className="input type1" value={this.state.oldaddress} onChange={this.handleAddress} disabled/>
                                    </div>
                                </div>	
                            </div>                                                 
                        </div>
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">변경할 주소</label>									
                                </div>
                                <div className="form-cont">
                                    <div class="form-controls type-address">
                                        <div class="row">
                                            <input type="text" placeholder="기본주소" className="input type1" value={this.state.address} onChange={this.handleAddress}/>
                                            <button type="button" class="btn medium primary" onClick={this.handleAddressPicker}><span>주소검색</span></button>
                                        </div>
                                        <div class="row">
                                            <input type="text" placeholder="상세주소" className="input type1" value={this.state.detailAddress} onChange={this.handleDetailAddress}/>
                                        </div>
                                    </div>
                                </div>	
                            </div>                                                 
                        </div>                                               
                    </div>
                    <div className="component-wrap">
                        <div className="btn-area btn-group-fixed vertical">
                            <button type="button" className="btn large secondary" onClick={this.handleNext}><span>확인</span></button>
                        </div>
                    </div>                     
                </main>
            </>
        );
    }
};

export default  withCookies(MainMenuMemberChangeInfoChangeAddress);
