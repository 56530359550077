import React, { Component } from "react";
import { withLastLocation } from "react-router-last-location";
import { withCookies } from "react-cookie";
import Header from "../../../components/Common/Header";
import TimeSelectorNewContainer from '../../../containers/Reserve/TimeSelectorNewContainer'
import ReserveApi from "../../../lib/ReserveApi";
import {
    getStrAmPm,
    getDayKorean,
    getStryyyymmdd
} from '../../../lib/TimeApi'

import moment from 'moment'
import { calertPromise, setLocalStorage, getLocalStorage, calertConfirmBtnCustom } from "../../../lib/Util";
import { getChauffeurDrivenApplyCountDate, getChauffeurDrivenLimitCount } from "../../../lib/GoogleSheetApi";
import { getReserveAvaliableDate } from '../../../lib/TimeApi'
import RnInterface from "../../../lib/RnInterface";
import store from '../../../reduxStore'
import Loading from '../../../components/Common/Loading'

import "../../../css/default.css";
import "../../../css/booking.css";
import "react-datepicker/dist/react-datepicker.css";

const RESERVE_TYPE_GENERAL_FLAG = 1
const RESERVE_TYPE_GOLF_FLAG = 3


const RESERVATION_BASE_TIME = (window.location.href.indexOf('devclient.mosiler.com') !== -1) ? 0 : 0;                   // 예약 기준 시간 (현재일로부터 29시간 이후로 시작/종료시간을 산정.)  devclient면 30시간(테스트용) hybclient는 29시간
const RESERVATION_GOLF_BASE_TIME = 10;              // 골프 기본 시간 (10시간)

const CLOSE_HOUR = 17;                              // 예약 종료 시간 19:00. 만약 18:00로 변경할 경우  18로 변경한다.
const LIMIT_HOUR = 0;


/*
    * Reserve Confirm View에서 아래의 데이터를 넘겨야 한다.
    - localStorage name : reserve_view_all_state  
    - Property            
            date                    date: "2022-01-04T15:00:00.000Z"
            startTime               startTime: "2022-01-03T22:00:00.000Z"
            endTime                 endTime: "2022-01-04T05:20:55.939Z"
            reserveType             reserveType: 1
            startTimeString         startTimeString: "2022-01-05 07:00:00.000"
            endTimeString           endTimeString: "2022-01-05 10:00:00.000"
            carLocation             
            comment                 
            passengerName           
            passengerPhone          
            carNo                   
            carModel                
            covidKitStatus                     
*/


class ReserveStep2View extends Component {
    // 원 페이지로 가는게 좋을까.
    // 페이지마다 js로 만들어야 하나...
    // 우선 원페이지로 시도해볼까?

    state = {
        loading: 1,
        

        // phase 1
        reserveType: Number(this.props.match.params.state),

        // phase 2
        calcComplateFlag:false,
        
        
        date: moment().add(RESERVATION_BASE_TIME, 'hours').toDate(),                   // 현재 시각에서 24시간 이후 시점의 날짜로 봐야한다.
        fixedStartTime: moment().add(RESERVATION_BASE_TIME, 'hours'),                  // 현재 시각에서 24시간 이후 시점의 날짜로 봐야한다.
        
        // calcItem:{
        //     ReseveCharge:'0',
        //     DrivingTimes:'',
        //     startAreaFee:'0',
        //     areaFee:'0',
        //     drivingCharge:'0',
        //     nightFee:'0',
        //     ReserveDiscountAmount:'0',
        // },

        // phase 3
        carLocation: '',
        carNo:'',
        carModel:'',
        comment: '',
        CustomerNo: 0,
        passengerName: '',
        passengerPhone: '',
        passengerCheckStatus: false,
        covidKitStatus: false,

        // 탑승자 상세 내용.
        passInfant:0,
        passAdult:0,
        passinfirm:0,
        passGravida:0,
        

        // hourView:'',
        selectHour: LIMIT_HOUR,
        // detailViewState: false,
        predictingTimeStr:'',

        totalTime:'',      

        displayEndTime:moment(),
        trustNowTime: moment().toDate(),
        timeAddMoment : moment().startOf('days'),
        nextBtnDisableStat : false,

        selectedRevStartDt: '',
        selectedRevEndDt: '',
    };

    componentWillUnmount() {
        // setLocalStorage('reserve_step2_calc_flag', this.state.calcComplateFlag ? '1' : '0')
        
        
    }

    async componentDidMount() {
        await RnInterface.AndroidURLChange(this.props.location.pathname)
        let ls = getLocalStorage('reserve_step2_calc_flag')

        this.setState({
            reserveType: Number(this.props.match.params.state),
            
            // calcComplateFlag: (ls !== null) ? ((ls === '1') ? true : false) : false
            // reserveType: isBackupAllStateResult ? Number(backupAllState.reserveType) : Number(this.props.match.params.state),
        });


    }

    handleGotoBack = async () => {
        this.props.history.goBack();
    };
    handleGotoHome = async () => {
        this.props.history.replace('/main');
        this.props.history.push('/main');
    };

    setStateOnce = (key, val) => {
        return new Promise((resolve) => {
            this.setState({
                [key]:val
            }, ()=>{
                resolve(true)
            }) 
        })
    }


    handleStep2ValidateBtn = async () => {
        const {calcComplateFlag, selectedRevStartDt, selectedRevEndDt} = this.state;
        if (this.state.reserveType === RESERVE_TYPE_GOLF_FLAG) {
            let eventFlag = document.querySelector("input[name=same11]").checked;
            if (eventFlag) {
                if (moment(selectedRevEndDt).diff(moment(selectedRevStartDt),'hours') >= 13) {
                    await calertPromise('쇼퍼드리븐 이용은 최대 12시간까지입니다.')
                }
                else {
                    if (calcComplateFlag) {
                        let msg = `[예약 날짜 정보]\n
                        ${getStryyyymmdd(selectedRevStartDt)} (${getDayKorean(selectedRevStartDt)})\n
                        시작 : ${getStrAmPm(selectedRevStartDt)} ${moment(selectedRevStartDt).format('hh:mm')}
                        종료 : ${getStrAmPm(selectedRevEndDt)} ${moment(selectedRevEndDt).format('hh:mm')}\n`
                        if (await calertConfirmBtnCustom(msg,'아닙니다', '맞습니다.')) {
                            this.props.history.push(`/reserve_renew/${this.state.reserveType}/3`)
                        }  
                    }
                    else {
                        await calertPromise('시간 설정 후 요금이\n계산 되었는지 확인해주세요.')
                    }
                }
            }
            else {
                if (calcComplateFlag) {
                    let msg = `[예약 날짜 정보]\n
                    ${getStryyyymmdd(selectedRevStartDt)} (${getDayKorean(selectedRevStartDt)})\n
                    시작 : ${getStrAmPm(selectedRevStartDt)} ${moment(selectedRevStartDt).format('hh:mm')}
                    종료 : ${getStrAmPm(selectedRevEndDt)} ${moment(selectedRevEndDt).format('hh:mm')}\n`
                    if (await calertConfirmBtnCustom(msg,'아닙니다', '맞습니다.')) {
                        this.props.history.push(`/reserve_renew/${this.state.reserveType}/3`)
                    }  
                }
                else {
                    await calertPromise('시간 설정 후 요금이\n계산 되었는지 확인해주세요.')
                }
            }
        }
        else {
            if (calcComplateFlag) {
                let msg = `[예약 날짜 정보]\n
                ${getStryyyymmdd(selectedRevStartDt)} (${getDayKorean(selectedRevStartDt)})\n
                시작 : ${getStrAmPm(selectedRevStartDt)} ${moment(selectedRevStartDt).format('hh:mm')}
                종료 : ${getStrAmPm(selectedRevEndDt)} ${moment(selectedRevEndDt).format('hh:mm')}\n`
                if (await calertConfirmBtnCustom(msg,'아닙니다', '맞습니다.')) {
                    this.props.history.push(`/reserve_renew/${this.state.reserveType}/3`)
                }  
            }
            else {
                await calertPromise('시간 설정 후 요금이\n계산 되었는지 확인해주세요.')
            }
        }
        
        
    }

    
    getCalcDrivingReservationNew = (timeObjData) => {
        return new Promise(async (resolve, reject)=>{
            const { cookies } = this.props;
            const {
                startLocationAddress,
                startLocationLat,
                startLocationLng,
                endLocationAddress,
                endLocationLat,
                endLocationLng,
                stopOverList,
                checkSwitch,
            } = store.getState().LocationSelector_ReduxModule;

            const { 
                loginedData 
            } = store.getState().global_data;
    
            let tmpObj = {
                CustomerNo: loginedData.CustomerNo,
                DrivingType: this.state.reserveType,
                
                PaymentType: '2',
                
                RevStartDt: timeObjData.startTime,
                RevEndDt: timeObjData.endTime,
                
                StartAddress: startLocationAddress,
                StartLat: startLocationLat,
                StartLng: startLocationLng,
    
                EndAddress: endLocationAddress,
                EndLat: endLocationLat,
                EndLng: endLocationLng,
    
                ...ReserveApi.getWaypointGenerate2(stopOverList)
            }
            // this.setState({ loading: 0 })	// 로딩중
    
            try {
                let result = await ReserveApi.CalcDrivingReservation(
                    tmpObj,
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                )

                /* 쇼퍼드리븐 데이터취득 */
                let cdLimit = await getChauffeurDrivenLimitCount(moment(timeObjData.startTime).toDate())
                let cdComerCount = await getChauffeurDrivenApplyCountDate(moment(timeObjData.startTime).toDate())

                if (result.Result === 'success') {
                    result.Item.cdLimit = cdLimit;
                    result.Item.cdComerCount = cdComerCount;
                    // this.setState({ calcItem: result.Item, })
                    this.setState({ 
                        // loading: 1 ,
                        calcComplateFlag:true,
                        selectedRevStartDt: timeObjData.startTime,
                        selectedRevEndDt: timeObjData.endTime,
                    })	// 로딩완료
                    resolve(result.Item)
    
                } else {
                    await calertPromise('금액을 계산 하지 못했습니다.')
                    // this.setState({ loading: 1 })	// 로딩완료
                    resolve({})
                }
            } catch (e) {
                console.log(e)
                await calertPromise('금액 계산 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
                // this.setState({ loading: 1 })	// 로딩완료
                resolve({})
            }
        })

    }
    
    handleEnableBtn = async () => {
        await this.setStateOnce('nextBtnDisableStat', false)
    }
    handleDisableBtn = async () => {
        await this.setStateOnce('nextBtnDisableStat', true)
    }

    render() {

        return (
            <>
                {!this.state.loading ? <Loading /> : ""}
                <Header
                    // title={`${ReserveApi.getDrivingTypeStr(this.state.reserveType)} 예약 (2/5)`}
                    title={`${ReserveApi.getDrivingTypeStr(this.state.reserveType)} 예약 (2/4)`}
                    onBackButton={this.handleGotoBack}
                    onHomeButton={this.handleGotoHome}
                />

                <div className="booking_box2 hd_margin_top">
                    <TimeSelectorNewContainer
                        history={this.props.history}
                        nowTime={this.state.trustNowTime}
                        closeHour={CLOSE_HOUR}
                        limitHour={LIMIT_HOUR}
                        type={this.state.reserveType}
                        onCalc={this.getCalcDrivingReservationNew}
                        onEnableBtn={this.handleEnableBtn}
                        onDisableBtn={this.handleDisableBtn}
                        nextBtnDisableStat={this.state.nextBtnDisableStat}
                    />
                    {this.state.calcComplateFlag ? (
                        <div className="component-wrap inner-container">
                            <div class="btn-area btn-group-fixed horizontal">
                                <button
                                    type="button"
                                    className="btn large secondary"
                                    onClick={this.handleStep2ValidateBtn}
                                    disabled={this.state.nextBtnDisableStat}
                                >
                                    <span>다음</span>
                                </button>
                            </div>
                        </div>

                    ) : null}

                    {/* <div className="component-wrap inner-container">
                        <div className="btn-area vertical">
                            <button
                                type="button"
                                className="btn large secondary"
                                onClick={this.handleStep2ValidateBtn}
                            >
                                <span>다음</span>
                            </button>
                        </div>
                    </div> */}

                </div>
            </>
        );
    }
}

export default withLastLocation(withCookies(ReserveStep2View));
