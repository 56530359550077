import React, { Component } from 'react';
import axios from 'axios'
import AddressApi from '../lib/AddressApi';
import Util from '../lib/Util';

class AddressTest extends Component {

    state = {
        redirectUri:'',
        addressName:'',
        addressList:[]
    }

    componentDidMount () {
        let {redirect} = Util.getQueryStringObject();
        this.setState({redirectUri: '/' + redirect})       
    }

    setStateAsyncAddressName = (_addressName) =>{
        return new Promise((resolve)=>{
            this.setState({addressName: _addressName}, ()=>{
                resolve(true)
            })
        })
    }

    setStateAsyncAddressList = (_addressList) =>{
        return new Promise((resolve)=>{
            this.setState({addressList: _addressList}, ()=>{
                resolve(true)
            })
        })
    }


    asyncAddress = async (_addressName) => {
        await this.setStateAsyncAddressName(_addressName)
        let adr_result = await AddressApi.requestAddress(_addressName)
        await this.setStateAsyncAddressList(adr_result.documents);
    }

    handleChangeAddress = (e) => {
        this.asyncAddress(e.target.value);
    }

    /*
    curl -X GET "https://dapi.kakao.com/v2/local/search/keyword.json?page=1&size=15&sort=accuracy&query=%EC%84%9C%EC%9A%B8+%EC%84%9C%EB%8C%80%EB%AC%B8%EA%B5%AC+%EC%A6%9D%EA%B0%80%EB%A1%9C+2%EA%B8%B8+40-16" \
	-H "Authorization: KakaoAK {REST_API_KEY}"
    
    */

    
    
    


    render() {
        let testComponent = (tmpArr) =>{
            return tmpArr.map((data, idx) => {
                return (
                <>
                    <span className="list_t1" key={idx} onClick={()=>{
                        AddressApi.setPickAddressData(data.road_address_name,data.y,data.x)
                        this.props.history.replace(this.state.redirectUri);
                    }}>
                        신 주소: {data.road_address_name} <br/>
                        구 주소: {data.address_name} <br/>
                        위/경도 : {data.y} | {data.x} <br/>
                    </span>
                    <br/>
                </>
                
                
                )
            });
        }
        return (
            <>
                <div id="address">
                    <input 
                        type="text"
                        value={this.state.addressName}
                        onChange={this.handleChangeAddress}
                    />
                </div>
                <div id="result2">
                    {testComponent(this.state.addressList)}
                </div>
            </>
        );
    }
};

export default AddressTest;
