import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'; // 패키지 추가
import moment from 'moment'
import { setLocalStorage, getLocalStorage } from '../../lib/Util';




const TextTimePicker = ({onChangeTime, reserveType}) => {
    let tmpLS = getLocalStorage('reserve_step2_backup2')
    
    const [timeValue, setTimeValue] = useState('12:00')
    const [textHour, setTextHour] = useState(tmpLS !== null ? tmpLS.initHour : '00')
    const [textMinute, setTextMinute] = useState(tmpLS !== null ? tmpLS.initMinute : '00')
    const [ampmFlag, setAmpmFlag] = useState(tmpLS !== null ? tmpLS.initAmpm : true)      // true : AM, false : PM
    const [dateData, setDateData] = useState(null)      // true : AM, false : PM
    const [firstViewFlag, setFirstViewFlag] = useState(false)      // true : AM, false : PM
    
    let minute2 = null
    
    // textHour, textMinute,ampmFlag가 변경되었을때의 처리
    useEffect(()=>{
        //console.log(textHour, textMinute,ampmFlag)
        setDateData( moment().set('hours', changeAmpm(ampmFlag,textHour)).set('minutes', textMinute).set('seconds',0).toDate() )
        setLocalStorage('reserve_step2_backup2', {initHour:textHour, initMinute:textMinute, initAmpm: ampmFlag  })
    }, [textHour, textMinute,ampmFlag])   

    // dateData가 변경되었을때의 처리
    useEffect(()=>{
        if (!firstViewFlag) {
            setFirstViewFlag(true)
        }
        else {
            onChangeTime(dateData)
        }
        
    }, [dateData])   

    const changeTime = (e) =>{
        console.log(e)
        setTimeValue(e)
        // onChangeTime()
    }

    const changeAmpm = (ampmFlag, hour) => {
        let retData = 0
        if (Number(hour) === 12) {
            retData = hour;
        }
        else {
            retData = ampmFlag ? Number(hour) : Number(hour) + 12
        }
        return retData;
    }

    const processHour = (e) => {
        if (Number(e.target.value) >= 0 && Number(e.target.value) <= 24) {
            setTextHour(e.target.value)
            // changeMoment()
            if (e.target.value.length >= 2) {
                minute2.select()
            }

            if ( !ampmFlag && ( Number(e.target.value) >= 0 && Number(e.target.value) <= 11) ) {
                setTextHour(e.target.value)    
            }
            else if (Number(e.target.value) >= 12 && Number(e.target.value) <= 24) {
                if (Number(e.target.value) === 12) {
                    setTextHour(e.target.value)    
                    setAmpmFlag(false)
                    // changeMoment()
                }
                else if (Number(e.target.value) === 24){
                    setTextHour(Number(e.target.value) % 12+'')
                    setAmpmFlag(true)
                    // changeMoment()
                }
                else {
                    setTextHour(Number(e.target.value) % 12+'')
                    setAmpmFlag(false)
                    // changeMoment()
                }
            }
            else {
                setAmpmFlag(true)
            }
            
        }
        else {

        }       
    }

    const processMinute = (e) => {
        if (e.target.value.length <= 2) {
            if (Number(e.target.value) >= 0 && Number(e.target.value) <= 59) {
                setTextMinute(e.target.value)
            }
        }
        
    }

    // <ReactTimePicker 
    //             value={timeValue}
    //             onChange={changeTime}
    //             clockIcon={false}
    //             disableClock={true}
    //         />

    return (
        <div >
            <button className={ampmFlag ? `time-controller-btn time-enable_${reserveType}` : 'time-controller-btn'} onClick={e=>setAmpmFlag(true)}>오전</button>
            <button className={ampmFlag ? 'time-controller-btn' : `time-controller-btn time-enable_${reserveType}`} onClick={e=>setAmpmFlag(false)}>오후</button>
            <input type="number" onClick={e=>{e.currentTarget.select();}} maxLength={2} className='time-controller-input' value={textHour} onChange={e=>processHour(e)} />
            <strong>:</strong>
            <input type="number" ref={e=>minute2 = e} onClick={e=>{e.currentTarget.select();}} maxLength={2} className='time-controller-input' value={textMinute} onChange={e=>processMinute(e)}/>
        </div>
    )
}

TextTimePicker.propTypes = {
    reserveType: PropTypes.number,
    onChangeTime: PropTypes.func.isRequired
}

export default TextTimePicker; 
