import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
/* Map Content */
.map_box {padding:0px;}
.map_box li.sch_map { position:absolute; z-index:100px; top:0%; left:0%;}
.map_box li.sch_map iframe { width:100vw; height:calc(100vh - 280px);}

.guide_info_box { position:absolute; z-index:1000; background:#f2f8fe; width:100vw; height:300px; padding:0px; bottom:0%; border-radius:20px 20px 0 0;}
/* .guide_info_box span.profile { display:inline-block; width:100%; padding:20px 30px 15px 30px; border-bottom:1px solid #eee; background:#fff; border-radius:20px 20px 0 0;} */
.guide_info_box span.profile { display:inline-block; width:100%; padding:15px 30px 10px 30px; border-bottom:1px solid #eee; background:#fff; border-radius:20px 20px 0 0;}
.guide_info_box table.profile_table {width:100%;}
.guide_info_box table.profile_table td {}
.guide_info_box table.profile_table td.user_ph {width:50px;}
.guide_info_box table.profile_table td.user_ph img {width:50px; height:50px;}
.guide_info_box table.profile_table td.user_name {padding-left:10px; font-size:1.2em;}
.guide_info_box table.profile_table td.user_name span.grade { display:inline-block; width:100%;}
.guide_info_box table.profile_table td.user_name span.grade img {width:12px;}
.guide_info_box table.profile_table td.user_tel {}
.guide_info_box table.profile_table td.user_tel a { display:inline-block; padding:7px 10px; color:#1fafd2; border:1px solid #1fafd2; border-radius:30px;}
.guide_info_box table.profile_table td.user_tel a img {width:15px;}

.guide_info_box span.place_box { display:inline-block; width:100%; border-top:1px solid #eee; border-bottom:1px solid #eee; margin:-4px 0 0 0; background:#fff;}
.guide_info_box span.place_box span.place_start { display:inline-block; width:50%; float:left; padding:20px; border-right:1px solid #eee;}
.guide_info_box span.place_box span.place_end {display:inline-block; width:50%; float:left; padding:20px;}
.guide_info_box span.place_box span.place_title { display:block; font-size:1.2em; color:#999;}
.guide_info_box span.place_box span.place_address { display:block; padding:10px 0 0 0;}
.guide_info_box span.place_box span.place_address img {width:10px; margin-right:5px;}

.guide_info_box table.rtable3 {width:100%; font-size:1.1em;}
.guide_info_box table.rtable3 td {padding:0px 0 10px 0;}
.guide_info_box table.rtable3 td.first { vertical-align:top; width:20px; padding:4px 0;}
.guide_info_box table.rtable3 td.first img {width:10px;}
.guide_info_box table.rtable3 td:nth-child(2) {padding-bottom:0px !important;}

.guide_info_box table.rtable4 {width:100%; font-size:1.1em;}
.guide_info_box table.rtable4 td {padding:0px 0 10px 0;}
.guide_info_box table.rtable4 td.first { vertical-align:middle; width:20px; }
.guide_info_box table.rtable4 td.first img {width:10px;}
/* .guide_info_box table.rtable4 td:nth-child(2) {padding-bottom:0px !important;} */

.guide_info_box span.navi_info { display:inline-block; width:100vw; background:#f2f8fe; padding:10px 20px 0 20px; margin:-4px 0 0 0;}

#parent_container { display:inline-block; width:100vw; height:calc(100vh - 280px); background:#ff0000;}
#container { position:relative; display:inline-block; width:100%; height:100%; background:#000;}
#parent_container .marker_center {width:4px; height:4px; line-height:4px; text-align:center; background:#ffff00; z-index:1000; position:absolute; top:50%; left:50%; margin:-2px 0px 0 -2px;}
#parent_container .marker_center img { position:absolute; width:30px; bottom:0%; left:0%; margin:0px 0 1px -13px;}

/* 20210130 */
.live_container { }
.rect { display: inline-block;   height: 30px; line-height:30px; border-top-left-radius:5px; border-top-right-radius:5px; border-bottom-right-radius:5px; font-size:1.1em; font-weight:bold;}
.rect .rect_left { display:inline-block; background:white; width:80px; height:30px; text-align:center; border-top-left-radius:5px;} 
.rect .rect_right { display:inline-block; background:black; width:55px; height:30px; text-align:center; color:white;  border-top-right-radius:5px; border-bottom-right-radius:5px;}
.tri { width: 0; height: 0; border-left: -15px solid transparent; border-right: 20px solid transparent; border-top: 20px solid white; z-index:10000;}

.redmarker_container{position:absolute; z-index:-1; left:-7px; top:84%; }
.red_marker{ background: red; }
.circle { width: 15px; height: 15px; border-radius: 50%; box-shadow: 0px 0px 1px 1px #0000001a;  border:1px solid #a12830;}
.pulse { animation: pulse-animation 2s infinite; }

@keyframes pulse-animation {
  0% { box-shadow: 0 0 0 0px rgba(202, 0, 42, 0.6); }
  100% { box-shadow: 0 0 0 20px rgba(202, 0, 42, 0); }
}

/* https://reactgo.com/css-pulse-animation/ */
`;