const GLOBAL_DATA_SERVICE = "global_data/GLOBAL_DATA";
const GLOBAL_DATA_LOGOUT_SERVICE = "global_data/GLOBAL_DATA_LOGOUT";

const global_data_service = (payload) => ({ type: GLOBAL_DATA_SERVICE, payload:payload });
const global_data_logout_service = () => ({ type: GLOBAL_DATA_LOGOUT_SERVICE });

//Init data
const initialState = {
    loginedData: {},
    test:{}
}

export const global_data_async = (payload) => dispatch => {
    return new Promise((resolve,reject)=>{
        try {
            dispatch(global_data_service(payload));
            resolve('done');
        } catch(e) {
            return reject(e)
        }
    })
}

export const global_data_logout_async = () => dispatch => {
    return new Promise((resolve,reject)=>{
        try {
            dispatch(global_data_logout_service());
            resolve('done');
        } catch(e) {
            return reject(e)
        }
    })
}



//Reducer
export default (state = initialState, action) => {
    
    switch (action.type) {
        case GLOBAL_DATA_SERVICE:
            return {
                ...state,
                loginedData: action.payload
            };
        case GLOBAL_DATA_LOGOUT_SERVICE:
            return {
                ...state,
                loginedData: {}
            };
        default:
            return state;
    }
};