import React, { Component } from 'react';
import { withCookies } from 'react-cookie'
import Header from '../../components/Common/Header';
import Loading from '../../components/Common/Loading'
import CouponApi from '../../lib/CouponApi'
import Util, { calert, calertPromise } from '../../lib/Util'
import store from '../../reduxStore'
import moment from 'moment'
import CouponCss from '../../css/CustomGlobalStyle/Coupon'
import RnInterface from '../../lib/RnInterface'


import '../../css/default.css';
import '../../css/member.css';

import welcom from '../../img/mypage/welcom.svg'
import icon_notice from '../../img/coupon/icon_notice.svg'



const AVAILABLE_COUPON = 0;             // 이용 가능
const UNAVAILABLE_COUPON = 1;           // 이용 완료

/*
화면 처리 흐름
1. 처음 화면을 열었을때 현재 쿠폰 리스트를 취득하는 API를 호출하여 데이터를 뿌려준다.
   1) Loading화면을 띄우고
   2) CouponApi.GetMyCoupons 취득
    (ItemList[]의 IsUsedYn여부에 따라 사용가능 리스트, 사용 완료 리스트를 나눈다.)
2. 

*/


class CouponView extends Component {
    state = {
        viewState: AVAILABLE_COUPON,
        couponAvailableList: [],
        couponUnavailableList: [],
        loading: 0,
        couponCode: ''
    }

    handleChangeViewState = (params) => {
        this.setState({ viewState: params })
    }

    handleHome = () => {
        this.props.history.replace('/main')
    }

    getSortCouponData = (arr) => {
        return arr.sort((a, b) => {
            let aa = new Date(a.ActivatedDt).getTime(),
                bb = new Date(b.ActivatedDt).getTime()
            if (aa > bb)
                return -1
            if (aa < bb)
                return 1
            return 0;
        })
    }

    getCouponData = () => {
        return new Promise(async (resolve, reject) => {
            const { cookies } = this.props;
            const { CustomerNo } = store.getState().global_data.loginedData;

            try {
                // let avaliableCouponResult = await CouponApi.GetAvailaleCoupons(
                //     CustomerNo,
                //     cookies.get('ostype'),
                //     cookies.get('deviceid'),
                //     cookies.get('login_encrypt')
                // )
                
                // let expiredCouponResult = await CouponApi.GetExpiredCoupons(
                //     CustomerNo,
                //     cookies.get('ostype'),
                //     cookies.get('deviceid'),
                //     cookies.get('login_encrypt')
                // )

                // if (avaliableCouponResult.Result === 'success' && 
                //     expiredCouponResult.Result ==='success') {
                //         this.setState({
                //             couponAvailableList: avaliableCouponResult.ItemList,
                //             couponUnavailableList: expiredCouponResult.ItemList
                //         }, () => {
                //             resolve(true)
                //         })


                // } else {
                //     await calertPromise('쿠폰 정보를 얻어올 수 없습니다. 잠시후 다시 시도해주세요.')
                //     this.setState({
                //         couponAvailableList: [],
                //         couponUnavailableList:[]
                //     }, () => {
                //         resolve(false)
                //     })
                // }



                let resultData = await CouponApi.GetMyCoupons(
                    CustomerNo,
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                )

                // console.log(moment(data.ExpiredDt).toDate().getTime())
                // console.log(moment().toDate().getTime())

                // 쿠폰 취득 성공시
                if (resultData.ItemList && resultData.Result === 'success') {
                    let nowDate = moment().toDate();
                    let tmpAvaliableList = resultData.ItemList.filter((data) => ((data.IsUsedYn === 'N') &&  (data.IsExpiredYn === 'N')   ))        // 사용 가능
                    let tmpUnavaliableList = resultData.ItemList.filter((data) => ((data.IsUsedYn === 'Y') || (data.IsExpiredYn === 'Y')  ))      // 사용 완료

                    this.setState({
                        couponAvailableList: this.getSortCouponData(tmpAvaliableList),
                        couponUnavailableList: this.getSortCouponData(tmpUnavaliableList)
                    }, () => {
                        resolve(true)
                    })


                } else {
                    this.setState({
                        couponAvailableList: [],
                        couponUnavailableList: []
                    }, () => {
                        resolve(false)
                    })
                }
            } catch (e) {
                await calertPromise('쿠폰 취득 도중 오류가 발생했습니다. 고객센터에 문의해주세요.')
                console.log(e);
                return reject(e);
            }
        })
    }

    // GetCouponByRedeemCode API(쿠폰 등록 API) 사용 이후 취득하는 데이터를 바탕으로 갱신 처리할때 쓰인다.
    // GetMyCoupons API를 다시 호출할 필요 없음
    getUpdateCouponData = (updateDataArr) => {
        return new Promise(async (resolve, reject) => {
            try {
                // 쿠폰 취득 성공시
                if (updateDataArr.length) {
                    let nowDate = moment().toDate();
                    let tmpAvaliableList = updateDataArr.filter((data) => ((data.IsUsedYn === 'N') &&  (moment(data.ExpiredDt).toDate().getTime() > nowDate.getTime())   ))        // 사용 가능
                    let tmpUnavaliableList = updateDataArr.filter((data) => ((data.IsUsedYn === 'Y') || (moment(data.ExpiredDt).toDate().getTime() < nowDate.getTime())  ))      // 사용 완료

                    this.setState({
                        couponAvailableList: this.getSortCouponData(tmpAvaliableList),
                        couponUnavailableList: this.getSortCouponData(tmpUnavaliableList)
                    }, () => {
                        resolve(true)
                    })
                } else {
                    this.setState({
                        couponAvailableList: [],
                        couponUnavailableList: []
                    }, () => {
                        resolve(false)
                    })
                }
            } catch (e) {
                console.log(e);
                return reject(e);
            }
        })
    }


    async componentDidMount() {
        
        try {
            this.setState({ loading: 0 })
            await this.getCouponData();
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            this.setState({ loading: 1 })
        }
        catch (e) {
            await calertPromise('쿠폰 데이터 취득 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
            this.setState({ loading: 1 })
        }

        // let tmpArr = [
        //     {dateTime : new Date('2020-12-21T19:51:44.457')},
        //     {dateTime : new Date('2020-12-23T19:51:44.457')},
        //     {dateTime : new Date('2020-11-29T19:51:44.457')},
        //     {dateTime : new Date('2020-10-29T19:51:44.457')},
        //     {dateTime : new Date('2020-11-20T19:51:44.457')}
        // ]

        // // 복사본을 만들지 않음.. concat등으로 이뮤터블 지켜야함.
        // let test = tmpArr.sort( (a,b)=> {
        //     if (a.dateTime.getTime() > b.dateTime.getTime())
        //         return -1
        //     if (a.dateTime.getTime() < b.dateTime.getTime())
        //         return 1
        //     return 0;
        // } );

        // for (let i in test) {
        //     console.log(moment(test[i].dateTime).format('YYYY-MM-DD HH:mm'))
        // }

        // let tmpArr2 = [1000,100,5,110000,5565,35323,2341]

        // console.log(tmpArr2.sort( (a,b)=> {
        //     if (a > b)
        //         return -1
        //     if (a < b)
        //         return 1
        //     return 0;
        // } ))

        // let tmptmpData  = [
        //     {
        //         ActivatedDt: "2020-12-29T19:51:44.457",
        //     DiscountAmount: null,
        //     DiscountRate: 10,
        //     ExpiredDt: "2020-12-29T19:51:44.457",
        //     Id: 1669,
        //     IsExpiredYn: "Y",
        //     IsRate: true,
        //     IsUsedYn: "N",
        //     PromotionName: "1시간 무료이용",
        //     RedeemCode: "FY53F17VFJ",
        //     RuleCode: null,
        //     RuleName: null},

        //     {ActivatedDt: "2020-12-30T19:51:44.457",
        //     DiscountAmount: null,
        //     DiscountRate: 10,
        //     ExpiredDt: "2020-12-29T19:51:44.457",
        //     Id: 1669,
        //     IsExpiredYn: "Y",
        //     IsRate: true,
        //     IsUsedYn: "Y",
        //     PromotionName: "1시간 무료이용",
        //     RedeemCode: "FY53F17VFJ",
        //     RuleCode: null,
        //     RuleName: null},

        //     {ActivatedDt: "2020-11-29T19:51:44.457",
        //     DiscountAmount: null,
        //     DiscountRate: 10,
        //     ExpiredDt: "2020-12-29T19:51:44.457",
        //     Id: 1669,
        //     IsExpiredYn: "Y",
        //     IsRate: true,
        //     IsUsedYn: "N",
        //     PromotionName: "1시간 무료이용",
        //     RedeemCode: "FY53F17VFJ",
        //     RuleCode: null,
        //     RuleName: null}
        // ]

        // console.log(tmptmpData.filter((data) => (data.IsUsedYn === 'Y')))
        // console.log(tmptmpData.sort( (a,b)=> {
        //     let aa = new Date(a.ActivatedDt).getTime(),
        //         bb = new Date(b.ActivatedDt).getTime()

        //     if (aa > bb)
        //         return -1
        //     if (aa < bb)
        //         return 1
        //     return 0;
        // } ))

    }


    handleChangeCoupon = (e) => {
        this.setState({ couponCode: e.target.value })
    }

    getExpiredUsedStr = (isExpiredYn, isUsedYn) => {
        let retData = ''
        if (isUsedYn === 'Y') {
            retData = '사용완료';
        } else if (isExpiredYn === 'Y') {
            retData = '기간만료';
        } else {
            retData = ''
        }
        return retData;
    }


    handleRegistCoupon = async () => {
        const { cookies } = this.props;
        const { CustomerNo } = store.getState().global_data.loginedData;

        if (this.state.couponCode.length >= 8) {
            try {
                this.setState({loading:0})
                let resultData = await CouponApi.GetCouponByRedeemCode(
                    CustomerNo,
                    this.state.couponCode,
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                )
    
                if (resultData.Result === 'success') {
                    await calertPromise('쿠폰 등록이 완료 되었습니다.')
                    await this.getCouponData();
                    // await RnInterface.callReactNative(`ScreenViewAnalyticsCouponRegistration`)
                    this.setState({
                        loading:1,
                        couponCode:'',
                    })
                } else {
                    await calertPromise('쿠폰이 존재하지 않습니다.')
                    this.setState({loading:1})
                }
            } catch (e) {
                this.setState({loading:1})
            }
        } 
        else {
            await calertPromise('쿠폰 번호 8~10자리를 입력해주세요.')
        }



                /*
                * 성공시 GetCouponByRedeemCode
                ItemList[0] > 

                ActivatedDt: "2020-12-29T19:51:44.457"
                DiscountAmount: null
                DiscountRate: 10
                ExpiredDt: "2020-12-29T19:51:44.457"
                Id: 1669
                IsExpiredYn: "Y"
                IsRate: true
                IsUsedYn: "N"
                PromotionName: "1시간 무료이용"
                RedeemCode: "FY53F17VFJ"
                RuleCode: null
                RuleName: null

                * 실패시
                Item: 0
                ItemList: null
                LiveList: []
                Message: "Coupon is not available!"
                Result: "fail"
                ResultCode: null
                SharedCodeList: null
                TokenStatus: 1
                */

                /*
                Item: 1 GetMyCoupons
                ItemList: [{Id: 1669, ActivatedDt: "2020-12-29T19:51:44.457", RedeemCode: "FY53F17VFJ",…}]
                0: {Id: 1669, ActivatedDt: "2020-12-29T19:51:44.457", RedeemCode: "FY53F17VFJ",…}
                ActivatedDt: "2020-12-29T19:51:44.457"
                DiscountAmount: null
                DiscountRate: 10
                ExpiredDt: "2020-12-29T19:51:44.457"
                Id: 1669
                IsExpiredYn: "Y"
                IsRate: true
                IsUsedYn: "N"
                PromotionName: "1시간 무료이용"
                RedeemCode: "FY53F17VFJ"
                RuleCode: null
                RuleName: null
                LiveList: []
                Message: ""
                Result: "success"
                ResultCode: null
                SharedCodeList: []
                TokenStatus: 1


            // <span className="coupon_box">
            //     <span className="coupon_title">
            //         <span className="ct_title">{data.PromotionName}</span>
            //         <span className="ct_info">{CouponApi.GetCouponTypeToStr(data.DiscountRate, data.DiscountAmount)}</span>
            //     </span>
            //     <span className="seperator_container">
            //         <span className="seperator"></span>
            //     </span>
            //     <span className="coupon_number">
            //         <b>쿠폰코드 {data.RedeemCode}</b><br />
            //             발급일 {moment(data.ActivatedDt).format('YYYY-MM-DD')}<br />
            //             만료일 {moment(data.ExpiredDt).format('YYYY-MM-DD')}<br />
            //     </span>
            // </span>


                */


    }

    render() {
        const { loading, viewState, couponAvailableList, couponUnavailableList } = this.state;


        let componentCouponAvailableList = (arr) => (arr.map((data, idx) => (
            <>
                {/* 쿠폰목록 */}                         
                <div className="box coupon">
                    <div className="coupon-header">
                        <h3 className="coupon-title">{data.PromotionName}</h3>
                        <strong className="coupon-price">{CouponApi.GetCouponTypeToStr(data.DiscountRate, data.DiscountAmount)}</strong>
                    </div>
                    <div className="coupon-footer">
                        <strong>쿠폰코드 {data.RedeemCode}</strong>
                        <p>{moment(data.ActivatedDt).format('YYYY-MM-DD')} ~ {moment(data.ExpiredDt).format('YYYY-MM-DD')}</p>
                    </div>
                </div>
            </>

        )))

        let componentCouponUnavailableList = (arr) => (arr.map((data, idx) => (
            <>
                {/* 쿠폰목록 */}                         
                <div className="box coupon off">
                    <div className="coupon-header">
                        <strong className="state-txt">{this.getExpiredUsedStr(data.IsExpiredYn, data.IsUsedYn)}</strong>
                        <h3 className="coupon-title">{data.PromotionName}</h3>
                        <strong className="coupon-price">{CouponApi.GetCouponTypeToStr(data.DiscountRate, data.DiscountAmount)}</strong>
                    </div>
                    <div className="coupon-footer">
                        <strong>쿠폰코드 {data.RedeemCode}</strong>
                        <p>{moment(data.ActivatedDt).format('YYYY-MM-DD')} ~ {moment(data.ExpiredDt).format('YYYY-MM-DD')}</p>
                    </div>
                </div> 
            </>

        )))


        return (
            <>
                
                {!loading ? <Loading /> : ''}
                <Header
                    title="쿠폰관리"
                    onBackButton={this.handleHome}
                    onHomeButton={this.handleHome}
                />
                <main id="Contents" className="">
                    <div className="inner-container bg-gray">
                        <div class="form vertical">
                            <div class="form-body">
                                <div class="form-title">
                                    <strong>발급 받으신 쿠폰번호를 입력해주세요.</strong>
                                </div>
                                <div class="form-cont mar-t10">
                                    <div class="form-controls flexible">
                                        <input type="text" placeholder="8~10자리 영문 및 숫자" className="input type1" onChange={this.handleChangeCoupon} value={this.state.couponCode} />
                                        <button type="button" class="btn medium primary" onClick={this.handleRegistCoupon}><span className="text-bold">등록</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>              
                    </div>
                    {/* 탭 */}
                    <div className="tabs-container">
                        <div className="tabs-nav">
                            <ul className="tabs-group">
                                <li style={{width:"50%"}}>   
                                    <button className={(viewState === AVAILABLE_COUPON) ? 'tab tab-on' : 'tab'} onClick={() => { this.handleChangeViewState(AVAILABLE_COUPON) }}>쿠폰목록</button>
                                </li>
                                <li style={{width:"50%"}}>
                                    <button className={(viewState === UNAVAILABLE_COUPON) ? 'tab tab-on' : 'tab'} onClick={() => { this.handleChangeViewState(UNAVAILABLE_COUPON) }}>이용내역</button>
                                </li>
                            </ul>
                        </div>
                        <div class="tabs-content"> 
                            {/* 쿠폰목록 */}                         
                            <div className="inner-container">
                                {viewState === AVAILABLE_COUPON ?
                                    (
                                        <>
                                            {couponAvailableList.length ?
                                                componentCouponAvailableList(couponAvailableList)
                                                : (
                                                    <div class="no-data"><p class="text-main">쿠폰이 없습니다.</p></div>
                                                )}
                                            
                                        </>
                                    ) : (
                                        <>                                            
                                            {couponUnavailableList.length ?
                                                componentCouponUnavailableList(couponUnavailableList)
                                                : (
                                                    
                                                    <div class="no-data"><p class="text-main">이용 내역이 없습니다.</p></div>
                                                )}
                                            
                                        </>
                                    )
                                }                        
                            </div>
                        </div>                        
                    </div>  

                    {/* 안내문구 */}
                    <div className="component-wrap space-top">
                        <section className="information-text on">
                            <h1 className="information-head">
                                <button type="button" className="btn-viewmore">
                                    <i className="ico ico-info"></i>
                                    꼭 확인해주세요.
                                    <i className="icon arrow"></i>
                                </button>
                            </h1>
                            <div className="information-body">
                                <div className="info-desc">
                                    <h3 className="info-stit">이용안내</h3>
                                    <ul className="text-sub">
                                        <li>쿠폰의 종류, 사용조건, 유효기간을 꼭 확인해주세요.</li>
                                        <li className="text-darkblue">쿠폰 적용은 예약 시 예약확인 화면에서 쿠폰 선택 가능합니다.</li>
                                        <li className="text-darkblue">쿠폰 적용 시 운행요금에 대해서만 할인이 적용되며, 할증 및 연장요금, 수수료 등은 할인이 적용되지 않습니다.</li>
                                        <li className="text-import">골프예약의 경우 예약할인 행사중에는 쿠폰 사용이 불가합니다. (단, 일부 쿠폰에 한해서는 골프예약시에도 쿠폰 사용이 가능합니다.)</li>
                                        {/* <li className="text-import">레저예약의 경우 예약할인 행사중에는 쿠폰 사용이 불가합니다. (단, 일부 쿠폰에 한해서는 레저예약시에도 쿠폰 사용이 가능합니다.)</li> */}
                                        <li>결제 및 탑승이 완료된 건에는 쿠폰을 적용할 수 없습니다.</li>
                                        <li>할인 금액보다 운행 요금이 적은 경우 차액은 환불되지 않습니다.</li>
                                        <li>쿠폰별 사용 기간이 상이하며, 사용기간이 만료된 쿠폰은 자동 소멸됩니다.</li>
                                    </ul>
                                </div>                                        
                            </div>
                        </section> 
                    </div>
                </main>

                {/* 

                <div className="coupon hd_margin_top">

                    <ul className="ma_box">
                        <li className="ma_title">
                            <b>쿠폰발급</b>
                        </li>
                        <li className="info_form">
                            <span className="form_section">
                                <span className="section_title">발급 받으신 쿠폰번호를 입력해주세요</span>
                                <span className="section_input">
                                    <input type="text" placeholder="8~10자리 영문 및 숫자" className="input_fm1" onChange={this.handleChangeCoupon} value={this.state.couponCode} />
                                    <input type="button" value="등록" className="certp_st1" onClick={this.handleRegistCoupon} />
                                    <span className="notice">
                                        <span className="info">
                                            쿠폰의 종류, 사용조건, 유효기간을 꼭 확인해주세요.<br />
                                            등록한 쿠폰은 결제시 사용할 수 있습니다.
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </li>
                    </ul>

                    <ul className="coupon_detail">
                        <li className="hd_tap">
                            <button className={(viewState === AVAILABLE_COUPON) ? 'tap tap_on' : 'tap'} onClick={() => { this.handleChangeViewState(AVAILABLE_COUPON) }}>사용 가능</button>
                            <button className={(viewState === UNAVAILABLE_COUPON) ? 'tap tap_on' : 'tap'} onClick={() => { this.handleChangeViewState(UNAVAILABLE_COUPON) }}>사용 완료</button>
                        </li>

                        {viewState === AVAILABLE_COUPON ?
                            (
                                <>
                                    <li className="coupon_list_box">
                                        {couponAvailableList.length ?
                                            tmpComponent(couponAvailableList)
                                            : (
                                                <span className="coupon_box">
                                                    <span className="no_coupon">
                                                        <span className="img"><img src={icon_notice} alt="" /></span>
                                                        <span className="info">사용가능한 쿠폰이 없습니다.</span>
                                                    </span>
                                                </span>
                                            )}
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li className="coupon_list_box">
                                        {couponUnavailableList.length ?
                                            tmpComponent(couponUnavailableList)
                                            : (
                                                <span className="coupon_box">
                                                    <span className="no_coupon">
                                                        <span className="img"><img src={icon_notice} alt="" /></span>
                                                        <span className="info">사용 완료된 쿠폰이 없습니다.</span>
                                                    </span>
                                                </span>
                                            )}
                                    </li>
                                </>
                            )
                        }
                    </ul>
                </div> */}

            </>
        );
    }
};

export default withCookies(CouponView);
