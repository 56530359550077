import React, { Component } from 'react';
import RnInterface from '../../lib/RnInterface'
import { ADDRESS_SEARCH_STATE_JOIN } from '../../constantData'
import store from '../../reduxStore'
import AccountApi from '../../lib/AccountApi'
import Loading from '../../components/Common/Loading'
import {calert, calertPromise} from '../../lib/Util'
import HeaderJoin2 from '../../components/Common/HeaderJoin2'
import MemberJoinCustomCss from '../../css/CustomGlobalStyle/MemberJoinCustom'

import '../../css/default.css';
import '../../css/member.css';

//view_~~~ 로 시작하는 파일은 html inline에 style로 정해놓은 것을 css화 시킨 것이다.
// import '../css/view_login.css';

import btn_prev from '../../img/btn/btn_prev.svg';


/*

><
\n<

검색 : <input.+?>
치환 : $0>>

검색 : >>>
치환 : />

검색 : <img.+?>
치환 : $0>>

검색 : >>
치환 :  alt=""/>

*/

class MemberJoin5View extends Component {
    state = {
        zipCode:'',
        address:'',
        detailAddress:'',

        CarNo:'',
        CarName:'',

        AccountType: '',
        CustomerName: '',
        PhoneNumber: '',
        UserId: '',
        UserPw: '',
        Email:'',
        DeviceId: '',
        RegId: '',
        OsType: '',
        loading:0
    }

    // RN(React Native)의 DeviceInfo를 WebView측으로 받는 처리
    settingDeviceInfo = async () => {
        try {
            let deviceData = await RnInterface.getData('deviceinfo')
            let fcmtoken = await RnInterface.getData('fcmtoken')
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            let deviceInfoSplit = '';
    
            // false로 왔을때는 기본값 osType:A deviceid:1로 지정 (PC)
            // 차후에는 지워야할듯
            deviceData = (deviceData === false) ? 'A@@1' : deviceData;
            fcmtoken = (fcmtoken === false) ? 'PC_TEST' : fcmtoken
    
            // deviceData가 false가 아니면 @@를 split한다.
            if (deviceData) {
                deviceInfoSplit = deviceData.split('@@')
            }
            //세팅
            this.setState({
                DeviceId: deviceInfoSplit[1],
                RegId: fcmtoken,
                OsType: deviceInfoSplit[0],
                loading:1
            })
        }
        catch(e) {
            console.log(e)
        }
        
    }

    componentDidMount() {
        const { addressData } = store.getState().Address_ReduxModule;

        let tmpArrayJoinData = [
            localStorage.getItem('AccountType'),            // idx : 0
            localStorage.getItem('name'),                   // idx : 1
            localStorage.getItem('phoneNum'),               // idx : 2
            localStorage.getItem('c_emailid'),              // idx : 3
            localStorage.getItem('c_password'),             // idx : 4
            localStorage.getItem('emailaddress'),           // idx : 5
            localStorage.getItem('memberjoin_parentcode')   // idx : 6
        ]

        this.setState({
            AccountType: (tmpArrayJoinData[0] !== null) ? tmpArrayJoinData[0] : '',
            CustomerName: (tmpArrayJoinData[1] !== null) ? tmpArrayJoinData[1] : '',
            PhoneNumber: (tmpArrayJoinData[2] !== null) ? tmpArrayJoinData[2] : '',
            UserId: (tmpArrayJoinData[3] !== null) ? tmpArrayJoinData[3] : '',
            UserPw: (tmpArrayJoinData[4] !== null) ? tmpArrayJoinData[4] : '',
            Email: (tmpArrayJoinData[5] !== null) ? tmpArrayJoinData[5] : '',
            address: addressData,
            ParentCode: (tmpArrayJoinData[6] !== null) ? tmpArrayJoinData[6] : '',
        })

        this.settingDeviceInfo()

    }
    
    handleZipCode = (params) => { this.setState({zipCode : params.target.value}) }
    handleAddress = (params) => { this.setState({address : params.target.value}) }
    handleDetailAddress = (params) => { this.setState({detailAddress : params.target.value}) }
    handleAddressPicker = (params) => { this.props.history.replace(`/address_search?redirect=join/5&state=${ADDRESS_SEARCH_STATE_JOIN}&menuid=0`) }
    handleCarNo = (params) => { this.setState({CarNo : params.target.value}) }
    handleCarName = (params) => { this.setState({CarName : params.target.value}) }

    handleNext = async () => {
        if (this.state.address.length === 0) {
            await calertPromise('기본주소를 입력해주세요.')
            return ;
        }
        if (this.state.detailAddress.length === 0) {
            await calertPromise('상세주소를 입력해주세요.')
            return ;
        }
        // 차량 정보, 차량번호는 체크하지 않는다.
        // 차후 필요성을 느끼면 넣자.

        const { 
            AccountType,
            CustomerName,
            PhoneNumber,
            UserId,
            UserPw,
            CarName,
            CarNo,
            Email,
            DeviceId,
            RegId,
            OsType,
            address,
            detailAddress,
            ParentCode

        } = this.state;
        
        let joinFormData = {
            CustomerNo: 1,
            UserId,
            UserPw,
            AccountType,
            CustomerName,
            Email: AccountType !== 'email' ? Email : UserId,       // Account Type이 email이 아닐경우는 apple or kakao이므로 Email정보를 넣어주고, email일 경우에는 UserId값을 넣어준다.
            PhoneNumber,
            ParentCode,  // 초대코드
            HomeAddress: `${address} ${detailAddress}`,
            CarName,
            CarNo,
            DeviceId,
            RegId,
            OsType
        }

        try {
            this.setState({loading:0})		// 로딩중
            let joinResult = await AccountApi.JoinForm(joinFormData, OsType, DeviceId)
    
            if (joinResult.Result !== 'success') {
                await calertPromise(joinResult.Message)
                this.setState({loading:1})	// 로딩완료
                // 회원 가입 실패
            } else {
                localStorage.setItem('osType', OsType);
                localStorage.setItem('deviceId', DeviceId);
                // 회원 가입 성공
                this.props.history.push('/join/end');
            }
            
            console.log(joinFormData)
            // await calertPromise(`${this.state.address} ${this.state.detailAddress}`)
        } catch(e) {
            await calertPromise('회원가입처리 도중 오류가 발생하였습니다. 관리자에게 문의해주세요.');
            this.setState({loading:1})	// 로딩완료
        }
        
        
    }
    handlePrev = () => {
        this.props.history.goBack();
    }
    

    render() {
        return (
            <>
            {!this.state.loading ? (<Loading />) : ''}
            <MemberJoinCustomCss />
                <HeaderJoin2
                    onBackButton={this.handlePrev}
                    title={''} />
                <main id="Contents"> 
                    <div className="key-visual-wrap">
                        <div className="key-visual-container">
                            <div className="key-contents">
                                <div className="key-text">
                                    <strong>서비스 이용에 필요한</strong><br />회원님의 주소를 입력해주세요.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="component-wrap inner-container">
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">주소</label>									
                                </div>
                                <div className="form-cont">
                                    <div class="form-controls type-address">
                                        <div class="row">
                                            <input type="text" placeholder="기본주소" className="input type1" value={this.state.address} onChange={this.handleAddress}/>
                                            <button type="button" className="btn medium primary" onClick={this.handleAddressPicker}><span>주소검색</span></button>
                                        </div>
                                        <div class="row">
                                            <input type="text" placeholder="상세주소" className="input type1" value={this.state.detailAddress} onChange={this.handleDetailAddress}/>
                                        </div>
                                    </div>
                                </div>	
                            </div>                                                 
                        </div> 
                    </div>
                    <div className="component-wrap">
                        <div className="btn-area btn-group-fixed vertical">
                            <button type="button" className="btn large secondary" onClick={this.handleNext}><span>다음</span></button>
                        </div>
                    </div>                     
                </main>    

            </>
        );
    }
};

export default MemberJoin5View;
