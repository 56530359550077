const ADDRESS_PICK = "global/ADDRESS_PICK";
const ADDRESS_DEL = "global/ADDRESS_DEL";

export const address_pick_service = (data) => ({ type: ADDRESS_PICK, addressObj: data });
export const address_del_service = () => ({ type: ADDRESS_DEL, addressObj: '' });
  
const initialState = {
    addressData:''
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ADDRESS_PICK:
            return {
                ...state,
                addressData: action.addressObj
            };
        case ADDRESS_DEL:
            return {
                ...state,
                addressData: action.addressObj
            };

        default:
            return state;
    }
};

// arr.filter((data, idx)=>{console.log(data, idx); return this})
// arr.filter((data, i)=>(i !== idx))

/*
{
type: 'LocationSelector/STEP_OVER_ADD',
stopOverItem: '1'
}

{
type: 'LocationSelector/STEP_OVER_DEL',
stopOverDelItemIdx: '1'
}

{
type: 'LocationSelector/STEP_OVER_UPDATE',
stopOverUpdateData: 'test01', 
stopOverUpdateIdx: 1
}
*/