// <Route exact path="/use_state_detail/:drivingno" component={UseStateView} />

import React, { Component } from 'react';
import {NavLink} from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { createGlobalStyle } from "styled-components";
import store from '../../reduxStore'
import Util, { calert, calertConfirm, calertPromise, customMoment, customerCommentStrProcess } from '../../lib/Util'
import ReserveApi from '../../lib/ReserveApi'
// import icon_arrow from "../../img/record/icon_arrow.svg"
import icon_point from "../../img/record/icon_point.svg"
import icon_point_waypoint from "../../img/record/icon_point_waypoint.svg"
import icon_arrow from "../../img/record/icon_arrow.svg"
import icon_location from "../../img/record/icon_location.svg"
import RnInterface from '../../lib/RnInterface'
import receiptImg from '../../img/receipt_logo_capture.png'

import Header from '../../components/Common/Header'
import {calertReceiptPopup} from '../../lib/Util'
import moment from 'moment'


const GlobalStyle = createGlobalStyle`
/* booking_state.css */
@charset "utf-8";

/* 이용내역 */
/*.record {display:inline-block; width:100%; min-height:calc(100vh - 53px); background:#ebebed; padding:0px;}*/
.record {display:inline-block; width:100%; min-height:calc(100vh - 53px); background:#fff; padding:0px;}

.record li.record_box { display:inline-block; width:100%; background:#fff; margin-bottom:15px;}
.record span.record_hd { display:inline-block; width:100%; padding:20px 20px 10px 20px; border-bottom:1px solid #eee;}
.record span.record_hd_color1 {border-left:5px solid #1fafd2 !important}
.record span.record_hd_color2 {border-left:5px solid #718ae4 !important}
.record span.record_hd span.date { display:inline-block; width:100%; font-size:1.1em; padding-bottom:0px;}
.record span.record_hd span.cancel {color:#ff0000 !important}
.record span.record_hd span.subject { display:inline-block; width:100%; padding:10px 0;}
.record span.record_hd span.subject span.kind { display:inline-block; font-size:1.5em; font-weight:bold; padding-right:20px;}
.record span.record_hd span.subject span.number { display:inline-block; font-size:1.2em; font-weight:bold;}
.record span.record_hd span.subject button.btn_more { display:inline-block; float:right; background:#fff; border:0px;}
.record span.record_hd span.subject button.btn_more img {width:10px;}

.record span.record_detail { display:inline-block; width:100%; padding:10px 20px 10px 25px; border-bottom:none;}
/*.record span.record_detail { display:inline-block; width:100%; padding:10px 20px 10px 25px; border-bottom:1px solid #eee;}*/

.record table.rtable {width:100%; font-size:1.2em;}
.record table.rtable th {width:90px; padding:5px 0; font-weight:normal; color:#999;}
.record table.rtable td {padding:5px 0;}
.record table.rtable th.price {color:#718ae4 !important; font-weight:bold;}
.record table.rtable td.price {color:#718ae4; font-weight:bold; font-size:1.3em;}
.record table.rtable td.cnumber {color:#999; padding:0px 0 5px 0;}

.record span.navi_info2 { display:inline-block; width:100%; padding:10px 20px 10px 25px; margin:0px;}
/*.record span.navi_info2 { display:inline-block; width:100%; padding:5px 0 0px 0; margin:0px;}*/

/*.record table.rtable4 {width:100%; font-size:1.1em; border-top:1px solid #eee;}*/
.record table.rtable4 {width:100%; font-size:1.1em;}
.record table.rtable4 td {padding:0px 0 10px 0;}
.record table.rtable4 td.first { vertical-align:top; width:60px; padding:10px 0;}
.record table.rtable4 td.first img {width:10px;}
/*.record table.rtable4 td:nth-child(2) {padding-bottom:0px !important;}*/

.record span.record_pay { display:inline-block; width:100%; padding:10px 25px;}


/* 이용내역 - 자세히보기 */
.record li.hd_notice { display:inline-block; width:100%; padding:20px; color:#999;}
.record span.record_hd span.btn { display:inline-block; width:100%; padding:5px 0;}
.record span.record_hd span.btn a.btn_grade { display:inline-block; background:#000; padding:8px 20px; color:#fff; font-size:1.2em;}

.record span.record_detail2 { display:inline-block; width:100%; padding:10px 20px; border-bottom:1px solid #eee;}
.record span.record_detail2 span.section_title_beforereserve { display:inline-block; width:100%; font-size:1.3em; padding:10px 0 10px 0; border-bottom:1px solid #eee; margin-bottom:10px;}

.record table.rtable2 {width:100%; font-size:1.2em;}
.record table.rtable2 th {width:90px; padding:5px 0; font-weight:normal; color:#999; vertical-align:top;}
.record table.rtable2 td {padding:5px 0;}
.record table.rtable2 td.price {font-size:1.3em; font-weight:bold;}
.record table.rtable2 th.result {color:#fff !important;}
.record table.rtable2 td.result_price {font-size:1.3em; font-weight:bold;}
.record table.rtable2 td.result_kind {font-size:0.9em; padding:10px 0 5px 0;}
.record table.rtable2 td.result_sprice {font-size:0.9em; padding:0 0 5px 0;}
.record table.rtable2 td.result_date {font-size:0.9em; color:#9397a2;}

.record table.rtable3 {width:100%; font-size:1.1em;}
.record table.rtable3 td {padding:0px 0 10px 0;}
.record table.rtable3 td.first { vertical-align:top; width:20px; padding:4px 0;}
.record table.rtable3 td.first img {width:10px;}
.record table.rtable3 td:nth-child(2) {padding-bottom:0px !important;}

.record span.navi_info { display:inline-block; width:100vw; background:#f2f8fe; padding:20px; margin:10px 0 10px -20px;}

.record li.price_result { display:inline-block; width:100%; background:#4d5467; padding:20px; color:#fff;}

.hd_tap { display:inline-block; width:100%; background:#fff; padding:0px 20px; text-align:center; margin-bottom:1px;}
.hd_tap button.tap { display:inline-block; width:50%; float:left; padding:15px 0; background:#fff; font-size:1.3em; border:0px; border-bottom:2px solid #ebebed; margin-bottom:-2px;}
.hd_tap button.tap_on { font-weight:bold; border-bottom:2px solid #000;}
.hd_tap {}
.hd_tap {}


/* record.css */
/* 이용내역 */
/*.record {display:inline-block; width:100%; min-height:calc(100vh - 53px); background:#ebebed; padding:0px;}*/
.record {display:inline-block; width:100%; min-height:calc(100vh - 53px); background:#fff; padding:0px;}
.record li.record_box { display:inline-block; width:100%; background:#fff; margin-bottom:0px;}
/*.record li.record_box { display:inline-block; width:100%; background:#fff; margin-bottom:15px;}*/
.record span.record_hd { display:inline-block; width:100%; padding:10px 20px; border-bottom:1px solid #eee;}
.record span.record_hd_paddingadd { padding-top: 20px;}
.record span.record_hd_color1 {border-left:5px solid #1fafd2 !important}
.record span.record_hd_color3 {border-left:5px solid #718ae4 !important}
.record span.record_hd span.date { display:inline-block; width:100%; font-size:1.1em; padding-bottom:0px;}
.record span.record_hd span.subject { display:inline-block; width:100%; padding:10px 0;}
.record span.record_hd span.subject span.kind { display:inline-block; font-size:1.5em; font-weight:bold; padding-right:20px;}
.record span.record_hd span.subject span.number { display:inline-block; font-size:1.2em; font-weight:bold;}
.record span.record_hd span.subject button.btn_more { display:inline-block; float:right; background:#fff; border:0px;}
.record span.record_hd span.subject button.btn_more img {width:10px;}

/*.record span.record_detail { display:inline-block; width:100%; padding:10px 20px 10px 25px; border-bottom:1px solid #eee;}*/
.record span.record_detail { display:inline-block; width:100%; padding:10px 20px 10px 25px;}

.record table.rtable {width:100%; font-size:1.2em;}
.record table.rtable th {width:90px; padding:5px 0; font-weight:normal; color:#999;}
.record table.rtable td {padding:5px 0;}
.record table.rtable th.price {color:#718ae4 !important; font-weight:bold;}
.record table.rtable td.price {color:#718ae4; font-weight:bold; font-size:1.3em;}
.record table.rtable td.cnumber {color:#999; padding:0px 0 5px 0;}

.record span.record_pay { display:inline-block; width:100%; padding:10px 25px;}


/* 이용내역 - 자세히보기 */
.record li.hd_notice { display:inline-block; width:100%; padding:20px; color:#999;}
.record span.record_hd span.btn { display:inline-block; width:100%; padding:5px 0;}
.record span.record_hd span.btn a.btn_grade { display:inline-block; background:#000; padding:8px 20px; color:#fff; font-size:1.2em;}

.record span.record_detail2 { display:inline-block; width:100%; padding:10px 20px; border-bottom:1px solid #eee;}
.record span.record_detail2 span.section_title_beforereserve { display:inline-block; width:100%; font-size:1.3em; padding:10px 0 10px 0; border-bottom:1px solid #eee; margin-bottom:10px;}

.record table.rtable2 {width:100%; font-size:1.2em;}
.record table.rtable2 th {width:90px; padding:5px 0; font-weight:normal; color:#999;}
.record table.rtable2 td {padding:5px 0;}
.record table.rtable2 td.price {font-size:1.3em; font-weight:bold;}
.record table.rtable2 th.result {color:#fff !important;}
.record table.rtable2 td.result_price {font-size:1.3em; font-weight:bold;}
.record table.rtable2 td.result_kind {font-size:0.9em; padding:10px 0 5px 0;}
.record table.rtable2 td.result_sprice {font-size:0.9em; padding:0 0 5px 0;}
.record table.rtable2 td.result_date {font-size:0.9em; color:#9397a2;}

.record table.rtable3 {width:100%; font-size:1.1em;}
.record table.rtable3 td {padding:0px 0 10px 0;}
.record table.rtable3 td.first { vertical-align:top; width:20px; padding:4px 0;}
.record table.rtable3 td.first img {width:10px;}
.record table.rtable3 td:nth-child(2) {padding-bottom:0px !important;}

.record span.navi_info { display:inline-block; width:100vw; background:#f2f8fe; padding:20px; margin:10px 0 10px -20px;}

.record li.price_result { display:inline-block; width:100%; background:#4d5467; padding:20px; color:#fff;}

.btn_st_2e303f { display:inline-block; width:50%; background:#2e303f; height:50px; line-height:50px; font-size:1.3em; border:0px; color:#fff;}
.btn_st_f1f0f5 { display:inline-block; width:50%; background:#cecece; height:50px; line-height:50px; font-size:1.3em; border:0px; color:#565656;}

`;

class UseHistoryDetailView extends Component {
    state = {
        info: {},
        stopOverList: [],
    }
    async componentDidMount() {
        try {
            const { getinfo } = store.getState().UseHistory_ReduxModule
            const { drivingno } = this.props.match.params;
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            
            let data = getinfo.filter((data)=>{ return data.DrivingNo === parseInt(drivingno) }  )
            let waypointArr = [data[0].WaypointAddress01, data[0].WaypointAddress02, data[0].WaypointAddress03]
            
            waypointArr = waypointArr.filter((data1)=>{console.log(data1); return !(data1 === undefined || data1 === null || data1.length === 0)})
            
            console.log('data[0].RevStartDt');
            console.log(data[0].RevStartDt);
            console.log(data[0].RevEndDt);
            console.log(data[0].StartDate);
            console.log(data[0].EndDate);
            this.setState({
                info:  data[0],
                stopOverList: waypointArr
            })
        } catch(e) {

        }              
    }


    handleBack = (params) => {
        this.props.history.goBack();
    }

    handleHome = (params) => {
        this.props.history.replace('/main')
    }

    gotoEstimate = (url) => {
        this.props.history.push(url)
    }

    // 실비 정보 취득
    getExpenseMoney = (expensesList) => {
        let retData = 0;
        let sum = 0;
        try {
            if (expensesList.length) {
                for(let i in expensesList) {
                    sum += expensesList[i].UseAmount;
                }
                retData = sum;
            }
        }
        catch(e) {
            console.log(e);
        }
        
        return retData;
    }

    getDayKorean = (data) => {
        let retData = '일';
        switch(data) {
            case '1':
                retData = '월';
                break;
            case '2':
                retData = '화';
                break;
            case '3':
                retData = '수';
                break;
            case '4':
                retData = '목';
                break;
            case '5':
                retData = '금';
                break;
            case '6':
                retData = '토';
                break;
            default:
                retData = '일';
                break;
        }
        return retData;
    }

    render() {
        const { info } = this.state
        let examComponent = (arr) => {
            return arr.map((data, idx) => {
                return (
                    <>
                        <li><i className="ico ico-vial1">경유지</i>{data}</li>
                    </>
                )
            });
        }
        
        let serviceList = (arr)=>{
            try {
                let tmpArr = [];
                if (arr.length) {
                    for (let i in arr) {
                        tmpArr.push(arr[i].ItemName)
                    }
                    return tmpArr.toString();
                } 
                return '신청한 내역이 없습니다.';
            }
            catch(e) {
                return '신청한 내역이 없습니다.';
            }           
        }

        let getStrPayment = (type)=>{           
            let retData = '';
            try {
                if (type !== '') {
                    switch(Number(type)) {
                        case 1:
                            retData='현금결제'
                            break;
                        case 2:
                            retData='카드결제'
                            break;
                        case 3:
                            retData='충전권'
                            break;
                    }
                } else {
                    retData = '결제수단 알수없음'
                }
            }
            catch(e) {
                retData = '결제수단 알수없음'
            }
            return retData;
        }

        
        //return (<></>)
        return (
            <>
            <GlobalStyle/>
                <Header
                    title="이용기록 상세"
                    onBackButton={this.handleBack}
                    onHomeButton={this.handleHome}
                />

                <main id="Contents">
                    <div className="component-wrap  detail-view">
                        <div class="text-group text-group-inner bg-gray">
                            <p class="text-sub text-gray">최근 1년동안의 이용 기록을 확인할 수 있습니다.</p>
                            <p class="text-sub text-gray">이용평가는 완료 후 3일 이내에만 가능합니다.</p>
                        </div> 
                        <div className="data-list-container">
                            <ul className="data-list">
                                <li className="data-list-item">
                                    <div className="data-header">
                                        <div className="desc-title" onClick="">
                                            {info.ReserveType === 5 ? (
                                                <strong>월간모시러</strong>  
                                            ) : (
                                                <strong>{ReserveApi.getDrivingTypeStr(info.DrivingType)}</strong>  
                                            )}
                                            
                                            <span className="desc-day">{info.RevStartDt === '' ? '날짜 정보 없음' : customMoment(info.RevStartDt).format('YYYY-MM-DD') }</span>                                              
                                        </div>
                                        <div className="btn-area">
                                            
                                            {/* 파트너 평가 여부가 공백이 아닐경우 */}
                                            {info.DriverEstimateYn !== '' && (
                                                <>
                                                    {info.DriverEstimateYn === 'N' ? (
                                                        <button 
                                                            type="button" 
                                                            className="btn small pressed" 
                                                            onClick={()=>{ this.gotoEstimate(`/set_estimate/${info.DrivingNo}`) }}
                                                        >
                                                            <span>평가하기</span>
                                                        </button>
                                                    ) : (
                                                        <button 
                                                            type="button" 
                                                            className="btn small pressed"
                                                            onClick={()=>{ this.gotoEstimate(`/get_estimate/${info.DrivingNo}`) }}
                                                        >
                                                            <span>평가보기</span>
                                                        </button>
                                                    )}
                                                </>
                                            )}

                                            {info.ReserveType === 5 ? null : (
                                                <button 
                                                    type="button" 
                                                    className="btn small white"
                                                    onClick={async ()=>{ await calertReceiptPopup(info) }}
                                                >
                                                    <span>영수증</span>
                                                </button>
                                            )}
                                            
                                            
                                        </div>                                        
                                    </div>                                     
                                </li>
                            </ul>
                            <div className="data-view">
                                <ul className="desc-list">
                                    <li className="desc-item">
                                        <div className="tit"><strong>운행일자</strong></div>
                                        <div className="desc"><strong>{customMoment(info.RevStartDt).format('YYYY-MM-DD')}</strong></div>
                                    </li>
                                    <li className="desc-item">
                                        <div className="tit"><strong>운행시간</strong></div>
                                        <div className="desc"><strong>{`${customMoment(info.RevStartDt).format('HH:mm')} ~ ${customMoment(info.RevEndDt).format('HH:mm')}`} ({ReserveApi.getTimeDifferenceHourToStr(info.RevStartDt, info.RevEndDt)})</strong></div>
                                    </li>
                                </ul>    
                                <div className="route-box">
                                    <ul>
                                        <li><i className="ico ico-start">출발지</i>{info.StartAddress}</li>
                                        {examComponent(this.state.stopOverList)}
                                        <li><i className="ico ico-goal">종료지</i>{info.EndAddress}</li>
                                    </ul>
                                </div> 
                                <ul className="desc-list">
                                    <li className="desc-item">
                                        <div className="tit"><span>예약자</span></div>
                                        <div className="desc"><span>{info.CustomerName}</span></div>
                                    </li>
                                    <li className="desc-item">
                                        <div className="tit"><span>탑승자</span></div>
                                        <div className="desc"><span>{info.Passenger}</span></div>
                                    </li>     
                                    <li className="desc-item">
                                        <div className="tit"><span>차량위치</span></div>
                                        <div className="desc"><span>{info.CarMemo && info.CarMemo.length !== undefined ? info.CarMemo : '없음'}</span></div>
                                    </li>
                                    <li className="desc-item">
                                        <div className="tit"><span>요청사항</span></div>
                                        <div className="desc"><span>{customerCommentStrProcess(info.CustomerComment)}</span></div>
                                    </li>

                                    {/* 방역키트 지급 축소
                                    <li className="desc-item">
                                        <div className="tit"><span>방역키트</span></div>
                                        <div className="desc"><span>{info.RequestCovid19Kit ? '추가' : '미추가'}</span></div>
                                    </li> */}

                                    <li className="desc-item">
                                        <div className="tit"><span>파트너</span></div>
                                        <div className="desc"><span>{info.DriverName !== null ? info.DriverName : '미지정'}</span></div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        {info.ReserveType === 5 ? null : (
                            <div className="accordion-container">
                                {/* 결제정보 */}
                                <section className="accordion on space-top">
                                    <h1 className="accordion-head">
                                        <button type="button">
                                            <span className="grid cross-center mar-r25">
                                                <div className="col title">결제정보</div>
                                                <div className="col right"></div>
                                            </span>
                                            {/* <i className="arrow"></i> */}
                                        </button>
                                    </h1>
                                    <div className="accordion-body">
                                        <ul className="desc-list">
                                        <li className="desc-item">
                                                <div className="tit"><span>운행요금</span></div>
                                                <div className="desc price"><span>{Util.numberWithCommas(String(info.DrivingCharge))}원</span></div>
                                            </li>
                                            <li className="desc-item">
                                                <div className="tit"><span>출발지할증</span></div>
                                                <div className="desc price"><span>{Util.numberWithCommas(String(info.StartAreaFee))}원</span></div>
                                            </li>                   
                                            <li className="desc-item">
                                                <div className="tit"><span>지역할증</span></div>
                                                <div className="desc price"><span>{Util.numberWithCommas(String(info.AreaFee))}원</span></div>
                                            </li>
                                            <li className="desc-item">
                                                <div className="tit"><span>심야할증</span></div>
                                                <div className="desc price"><span>{Util.numberWithCommas(String(info.NightFee))}원</span></div>
                                            </li>
                                            <li className="desc-item">
                                                <div className="tit"><span>연장운행</span></div>
                                                <div className="desc price"><span>{Util.numberWithCommas(String( info.ExtraTimeFee ))}원</span></div>
                                            </li>
                                            <li className="desc-item">
                                                <div className="tit"><span>실비</span></div>
                                                <div className="desc price"><span>{Util.numberWithCommas(String( this.getExpenseMoney(info.expensesList) ))}원</span></div>
                                            </li>
                                            <li className="desc-item">
                                                <div className="tit"><span>지명비</span></div>
                                                <div className="desc price"><span>{Util.numberWithCommas(String( info.SelectDriverFee ))}원</span></div>
                                            </li>
                                            <li className="desc-item">
                                                <div className="tit"><span>기타</span></div>
                                                <div className="desc price"><span>{Util.numberWithCommas(String( info.BillingOtherAmount ))}원</span></div>
                                            </li>
                                            {/* 할인금액 : 쿠폰 할인, 예약 할인 */}
                                            <li className="desc-item">
                                                <div className="tit"><span>할인금액</span></div>
                                                <div className="desc price"><span>{Util.numberWithCommas(String(info.DiscountAmount + info.ReserveDiscountAmount))}원</span></div>
                                            </li>     
                                            
                                            {/* <li className="desc-item">
                                                <div className="tit"><span>충전권사용</span></div>
                                                <div className="desc price"><span>???원</span></div>
                                            </li>
                                            <li className="desc-item">
                                                <div className="tit"><span>충전권사용</span></div>
                                                <div className="desc price"><span>{info.PrePaidTicketYN === 'Y' ? '사용' : '미사용'}</span></div>
                                            </li> */}
                                        </ul>  
                                    </div>
                                    <div className="data-amount">                                          
                                        <ul className="desc-list">                                             
                                            <li className="desc-item">
                                                <div className="amount-tit"><strong>결제금액</strong></div>
                                                <div className="amount-desc price">
                                                    <p><strong className="text-money">{Util.numberWithCommas(String(info.ReseveCharge))}원</strong></p>
                                                    {(info.PrePaidTicketYN === 'Y') && (
                                                        <p><span className="mar-r5">충전권 결제</span><strong className="text-money">{Util.numberWithCommas(String(info.PrePaidTicketAmount))}원</strong></p>
                                                    )}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </section>
                            </div>  
                        )}
                        
                    </div>  
                </main>
                    
                
                {/* 평상시에는 디스플레이 하지 않고, 영수증 저장시만 잠시 block으로 변경후 캡쳐한 다음 다시 none으로 변경한다. display 컨트롤은 영수증 팝업에서 한다. */}
                <div id="receipt-wrap" className="receipt-wrap" style={{ display:'none' }}>
                    <div className="desc-header">
                        <div className="desc-title"><img src={`${receiptImg}`} alt=""/></div>
                        <p className="text-sub"><span>{info.CustomerName}</span>님, 모시러를 이용해주셔서 감사합니다.</p>
                    </div>
                    <div className="desc-list">
                        <div className="text-bold text-main text-black">
                            <span>{ReserveApi.getDrivingTypeStr(info.DrivingType)}</span>&nbsp;
                            <span>{customMoment(info.RevStartDt).format('yyyy년 MM월 DD일')} ({this.getDayKorean(customMoment(info.RevStartDt).format('d'))})</span>
                        </div>            
                    </div> 
                    <ul className="desc-list">
                        <li className="desc-item">
                            <div className="tit"><span>출발</span></div>
                            <div className="desc"><span>{info.StartAddress}</span></div>
                        </li>
                        <li className="desc-item">
                            <div className="tit"><span>도착</span></div>
                            <div className="desc"><span>{info.EndAddress}</span></div>
                        </li>
                        <li className="desc-item">
                            <div className="tit"><span>운행시간</span></div>
                            <div className="desc"><span>{customMoment(info.RevStartDt).format('HH:mm')} ~ {customMoment(info.RevEndDt).format('HH:mm')}</span></div>
                        </li>
                        <li className="desc-item">
                            <div className="tit"><span>파트너명</span></div>
                            <div className="desc"><span>{info.DriverName}</span></div>
                        </li>                        
                    </ul> 
                    <ul className="desc-list">
                        <li className="desc-item">
                            <div className="tit"><span>운행요금</span></div>
                            <div className="desc price"><span>{Util.numberWithCommas(String(info.DrivingCharge))}원</span></div>
                        </li>
                        <li className="desc-item">
                            <div className="tit"><span>출발지할증</span></div>
                            <div className="desc price"><span>{Util.numberWithCommas(String(info.StartAreaFee))}원</span></div>
                        </li>
                        <li className="desc-item">
                            <div className="tit"><span>지역할증</span></div>
                            <div className="desc price"><span>{Util.numberWithCommas(String(info.AreaFee))}원</span></div>
                        </li>
                        <li className="desc-item">
                            <div className="tit"><span>심야할증</span></div>
                            <div className="desc price"><span>{Util.numberWithCommas(String(info.NightFee))}원</span></div>
                        </li>
                        <li className="desc-item">
                            <div className="tit"><span>연장운행</span></div>
                            <div className="desc price"><span>{Util.numberWithCommas(String(info.ExtraTimeFee))}원</span></div>
                        </li>
                        <li className="desc-item">
                            <div className="tit"><span>실비</span></div>
                            <div className="desc price"><span>{Util.numberWithCommas(String( this.getExpenseMoney(info.expensesList)  ))}원</span></div>
                        </li>
                        <li className="desc-item">
                            <div className="tit"><span>지명비</span></div>
                            <div className="desc price"><span>{Util.numberWithCommas(String(info.SelectDriverFee))}원</span></div>
                        </li>
                        <li className="desc-item">
                            <div className="tit"><span>기타</span></div>
                            <div className="desc price"><span>{Util.numberWithCommas(String(info.BillingOtherAmount))}원</span></div>
                        </li>  
                        <li className="desc-item">
                            <div className="tit"><span>할인금액</span></div>
                            <div className="desc price"><span>{Util.numberWithCommas(String( info.DiscountAmount + info.ReserveDiscountAmount ))}원</span></div>
                        </li>                                                
                    </ul> 
                    <div className="data-amount">                                          
                        <ul className="desc-list">                                             
                            <li className="desc-item">
                                <div className="amount-tit"><strong>결제금액</strong></div>
                                <div className="amount-desc price">
                                    <p><strong className="text-money">{Util.numberWithCommas(String(info.ReseveCharge))}원</strong></p>
                                    {(info.PrePaidTicketYN === 'Y') ? (<p><span className="mar-r5">충전권 결제</span><strong className="text-money">{Util.numberWithCommas(String(info.PrePaidTicketAmount))}원</strong></p>) : '' }
                                </div>
                            </li>                                                                                                                                                                                 
                        </ul>
                    </div>  

                
                    {/* <ul className="desc-list">
                        <li className="desc-item">
                            <div className="tit"><span>결제수단</span></div>
                            <div className="desc price"><span>국민 12345678****1234</span><span>(개인)</span></div>
                        </li>                                           
                    </ul> */}
                </div>















































                {/* <div className="record hd_margin_top">
                    <ul>
                    <span className="record_hd" style={{paddingBottom:'10px', background:'#f3f4f8', color:'#a5a6a8', fontSize:'1.1em'}}>
                    <span style={{display:'inline-block', marginTop:'10px', marginBottom:'10px'}}>최근 1년 동안의 이용 기록을 확인할 수 있습니다.</span>
                    <span style={{display:'inline-block', marginBottom:'10px'}}>이용 평가는 완료 후 3일 이내에만 가능합니다.</span>
                    </span>
                    <span className="record_hd record_hd_paddingadd">
                            <span className="date">{info.RevStartDt === '' ? '날짜 정보 없음' : customMoment(info.RevStartDt).format('YYYY-MM-DD') }</span>
                            <span className="subject">
                                <span className="kind">{ReserveApi.getDrivingTypeStr(info.DrivingType)}</span>
                            </span>
                            <span className="btn">
                                {info.DriverEstimateYn === 'N' ? (
                                    <NavLink to={`/set_estimate/${info.DrivingNo}`} className="btn_grade">평가하기</NavLink>
                                ) : (
                                    <NavLink to={`/get_estimate/${info.DrivingNo}`} className="btn_grade">평가보기</NavLink>
                                )}
                                
                            </span>
                            
                        </span>
                        <li className="record_box">
                            <span class="record_detail2">
                                <span class="section_title_beforereserve"><b>운행 정보</b></span>
                                <table class="rtable2">
                                    <tr>
                                        <th>운행일자</th>
                                        <td>{customMoment(info.RevStartDt).format('YYYY-MM-DD')}</td>
                                    </tr>
                                    <tr>
                                        <th>운행시간</th>
                                        <td>{`${customMoment(info.RevStartDt).format('HH:mm')} ~ ${customMoment(info.RevEndDt).format('HH:mm')}`}</td>
                                    </tr>
                                </table>
                            </span>

                            <span className="navi_info2" style={{ background: '#f2f8fe' }}>
                                <table className="rtable4">
                                    <tr>
                                        <td className="first" colSpan="2"><img src={icon_point} alt="" />&nbsp;&nbsp;{info.StartAddress}</td>
                                    </tr>
                                    {examComponent(this.state.stopOverList)}
                                    <tr>
                                        <td className="first" colSpan="2"><img src={icon_location} alt="" />&nbsp;&nbsp;{info.EndAddress}</td>
                                    </tr>
                                </table>
                            </span>

                            <span class="record_detail2">
                                <table class="rtable2">
                                    <tr>
                                        <th>예약자</th>
                                        <td>{info.CustomerName}</td>
                                    </tr>
                                    <tr>
                                        <th>탑승자</th>
                                        <td>{info.Passenger}</td>
                                    </tr>
                                    <tr>
                                        <th>차량위치</th>
                                        <td>{info.CarMemo && info.CarMemo.length !== undefined ? info.CarMemo : '없음'}</td>
                                    </tr>
                                    <tr>
                                        <th>요청사항</th>
                                        <td>{info.CustomerComment && info.CustomerComment.length !== undefined ? info.CustomerComment : '없음'}</td>
                                        
                                    </tr>
                                </table>
                            </span>

                            <span class="record_detail2">
                                <span class="section_title_beforereserve"><b>부가 서비스</b></span>
                                <table class="rtable2">
                                    <tr>
                                        <th>서비스</th>
                                        <td>{serviceList(info.expensesList)}</td>
                                    </tr>
                                    <tr>
                                        <th>파트너</th>
                                        <td>{info.DriverName}</td>
                                    </tr>
                                </table>
                            </span>

                            <span class="record_detail2">
                                <span class="section_title_beforereserve"><b>결제 정보</b></span>
                                <table class="rtable2">
                                    <tr>
                                        <th>기본요금</th>
                                        <td>{Util.numberWithCommas(info.DrivingCharge+'')}원</td>
                                    </tr>
                                    <tr>
                                        <th>시간추가</th>
                                        <td>{ReserveApi.getTimeAddToStr(
                                            info.RevStartDt, 
                                            info.RevEndDt, 
                                            info.StartDate,
                                            info.EndDate
                                             )}분</td>
                                    </tr>
                                    <tr>
                                        <th>쿠폰할인</th>
                                        <td>{ReserveApi.getCouponDiscountToStr(info.CouponId, info.DiscountAmount, info.DiscountRate )}</td>
                                    </tr>
                                </table>
                            </span>
                        </li>

                        <li class="price_result">
                            <table class="rtable2">
                                <tr>
                                    <th class="result">결제금액</th>
                                    <td class="result_price">
                                        {Util.numberWithCommas(info.PredictTotalCharge+'')}원</td>
                                </tr>
                                {info.PaymentType !== '' ? (
                                    <tr>
                                        <td colspan="2" class="result_kind">{getStrPayment(info.PaymentType)}</td>
                                    </tr>
                                ) : null}
                                
                                {info.BillingDate !== null ? (
                                    <tr>
                                        <td colspan="2" class="result_date">결제일시 {info.BillingDate}</td>
                                    </tr>
                                ) : (
                                    ''
                                )}
                                

                            </table>
                        </li>
                        

                    </ul>
                </div> */}

            </>
        );
    }
};

export default withCookies(UseHistoryDetailView);
