import React, { Component } from 'react';


import { withCookies } from 'react-cookie';

import store from '../../reduxStore'

import ReserveApi from '../../lib/ReserveApi'
import LocationSelectorContainer from '../../containers/Reserve/LocationSelectorContainer'
import {
    plusSecondsMomentWithFormatCustom
} from '../../lib/TimeApi'
import Header from '../../components/Common/Header'
import Util from '../../lib/Util'
import Loading from '../../components/Common/Loading'
import RnInterface from '../../lib/RnInterface'

import TimeSelector2 from '../../components/Reserve/TimeSelector2';
import { calert, calertPromise } from '../../lib/Util'
import moment from 'moment';

import '../../css/default.css';
import '../../css/booking.css';
import "react-datepicker/dist/react-datepicker.css";

import icon_notice from '../../img/booking/icon_notice.svg'
import icon_arrow_down from '../../img/booking/icon_arrow_down.svg'

// import { NavLink } from 'react-router-dom';
// import AddressApi from '../../lib/AddressApi';
// import TimeSelectorContainter from '../../containers/Reserve/TimeSelectorContainer'

// import btn_prev from '../../img/btn/btn_prev.svg'
// import btn_home from '../../img/btn/btn_home.svg'

// import icon_arrow_down from '../../img/booking/icon_arrow_down.svg'

// import DateSelector from '../../components/Reserve/DateSelector'
// import TimeSelector from '../../components/Reserve/TimeSelector'

// import icon_arrow_right from '../../img/booking/icon_arrow_right.svg'

// import icon_point from '../../img/booking/icon_point.svg'         // 2
// import icon_delete from '../../img/booking/icon_delete.svg'
// import icon_location from '../../img/booking/icon_location.svg'



const TEXTAREA_MAX_LENGTH = 100;

/*
검색 : <input.*[^>]
치환 : $0/

검색 : <img.*[^>]
치환 : $0 alt=""/
*/

class ReserveGeneralView extends Component {

    state = {
        date: moment().add(2, 'hours').toDate(),            // 현재 시각에서 2시간 이후 시점의 날짜로 봐야한다.
        startTime: moment().add(2, 'hours'),
        endTime: moment().add(3, 'hours'),
        fixedStartTime: moment().add(2, 'hours'),
        carLocation: '',
        comment:'',
        CustomerNo: 0,
        passengerName: '',
        passengerPhone:'',
        passengerCheckStatus:true,
        reserveType: 1,
        hourView: '',
        typeView: '일반 ',
        moneyView: 0,
        loading: 1,
        

        // 쿠폰 관련
        CouponId: '',               // 쿠폰 ID
        partnerUseYn: false,        // 파트너 카드 존재 유무
        mallReserved: '',           // 파트너 카드의 정보(?)
        paymentCardId: '',          // default card의 id
        sales10CouponId: '',        // 기본 10% 할인 쿠폰 id
        myDefaultCardYn: false,     // 개인 default card 존재 유무
        calcItem:{},                // 계산 결과 전체 값
    }

    // 현재 등록되어있는 Default 카드 정보를 취득해서 그 카드가 일반카드인지 파트너카드인지에 따라서
    // 쿠폰 확인 후 쿠폰이 존재한다면 적용하는 처리를 담당한다.
    // 화면 load시 한번만 호출된다.
    getCustomerDefaultCard = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const { cookies } = this.props;
                let { CustomerNo } = store.getState().global_data.loginedData
                let defaultPaymentCardData = await ReserveApi.GetBasicPaymentCards(
                    CustomerNo,
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                )

                // 기본카드가 있을 경우
                if (defaultPaymentCardData.Item && 
                    defaultPaymentCardData.Result === 'success') {
                    const smallReserved = defaultPaymentCardData.Item.MallReserved;
                    const spaymentCardId = defaultPaymentCardData.Item.Id;

                    // MallReserved값이 존재하면 (MallReserved값은 아마도 파트너 카드인지 아닌지 여부를 판별하는 프로퍼티인것 같다.)
                    if (smallReserved !== "") {
                        console.log("Payment CustomerDefaultCard....");
                        // 파트너 카드이고 , 쿠폰 1599 값을 넣어준다.
                        this.setState({ partnerUseYn: true, CouponId: '1599', mallReserved: smallReserved, paymentCardId: spaymentCardId, sales10CouponId: "" });
                        // 다시 계산 
                        // this.calcDrivingReservation();
                        resolve(true)
                    }
                    // MallReserved값이 없다면
                    else {
                        console.log('else')
                        // 10% 쿠폰이 있는지 재확인
                        let coupon10Data = await ReserveApi.get_check_10Coupon(
                            CustomerNo,
                            cookies.get('ostype'),
                            cookies.get('deviceid'),
                            cookies.get('login_encrypt')
                        )
                        console.log(coupon10Data)

                        if (coupon10Data.Item && coupon10Data.Result === 'success') {
                            const ssales10CouponId = coupon10Data.Item;
                            // get_check_10Coupon 취득한 값이 공값이 아니라 1661이라면
                            if (ssales10CouponId !== "" && ssales10CouponId === "1661") {
                                console.log("Payment get_check_10Coupon....");
                                // 파트너 카드는 아니지만 쿠폰 1661을 넣어준다.
                                this.setState({ partnerUseYn: false, CouponId: '1661', mallReserved: smallReserved, paymentCardId: spaymentCardId, sales10CouponId: ssales10CouponId });
                                resolve(true)
                                // 재계산
                                // this.calcDrivingReservation();
                            }
                            // get_check_10Coupon가 없다면 쿠폰 id는 없다고 봐야.
                            else {
                                this.setState({ partnerUseYn: false });
                                resolve(true)
                            }
                        }
                        // coupon10Data.Item가 존재하지 않으면
                        else {
                            this.setState({ partnerUseYn: false });
                            resolve(true)
                        }
                    }
                }
                // 기본카드가 없을 경우
                else {
                    resolve(false)
                }
            }
            catch (e) {
                return reject(e);
            }
        })
    }

    handleGotoBack = () => {
        this.props.history.replace('/main');
    }

    asyncProcess = async () => {
        try {
            this.setState({ loading: 0 })	// 로딩중
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            // Default 카드 정보 취득 (쿠폰 유무도 확인)
            // let tmpDetaultCardYn = await this.getCustomerDefaultCard();
    
            let tmpInitReserveBackupData = [
                localStorage.getItem('reserve_temp_date'),                          // 0
                localStorage.getItem('reserve_temp_startTime'),                     // 1
                localStorage.getItem('reserve_temp_endTime'),                       // 2
                localStorage.getItem('reserve_temp_carLocation'),                   // 3
                localStorage.getItem('reserve_temp_comment'),                       // 4
                localStorage.getItem('reserve_temp_type')                           // 5
            ]
	
	        let {loginedData} = store.getState().global_data
	        this.setState({
	            date: tmpInitReserveBackupData[0]           !== null ? new Date(tmpInitReserveBackupData[0]) : moment().add(2, 'hours').toDate(),
	            startTime: tmpInitReserveBackupData[1] !== null ? moment(tmpInitReserveBackupData[1]) : moment().add(2, 'hours'),
                endTime: tmpInitReserveBackupData[2] !== null ? moment(tmpInitReserveBackupData[2]) : moment().add(3, 'hours'),
	            carLocation: tmpInitReserveBackupData[3]    !== null ? tmpInitReserveBackupData[3] : '',
	            comment:tmpInitReserveBackupData[4]         !== null ? tmpInitReserveBackupData[4] : '',
	            reserveType: tmpInitReserveBackupData[5]    !== null ? Number(tmpInitReserveBackupData[5]) : 1,
	            passengerName: loginedData.CustomerName     !== undefined ? loginedData.CustomerName : '',
	            passengerPhone:loginedData.PhoneNumber      !== undefined ? loginedData.PhoneNumber : '',
	            CustomerNo: store.getState().global_data.loginedData.CustomerNo,
	            typeView: ReserveApi.getDrivingTypeStr(1)
	        }, () => {
	            this.getCalcDrivingReservation();
	        })
        } 
        catch(e) {
            console.log(e);
        }
    }

    componentDidMount() {
        // 초기값 세팅
        this.asyncProcess();
        // Load될때 

        // 출발지의 정보를 받아야한다.
        // 1. 기기로부터 위도, 경도 정보를 얻어온다
        // 2. getConvertLatlngToAddress를 호출하여 주소를 반환시킨다.
        // 위도 경도 정보를 못받아올 경우에는 GPS기능이 꺼져있어서 표시되지 않습니다.로 하고 위경도 정보를 0,0로 한다.
        // 3. 위, 경도, 주소값을 setState한다.
    }

    componentWillUnmount() {
        localStorage.setItem('reserve_temp_type', this.state.reserveType)           // 제휴(마스터카드, 하나카드) 예약은 일반 예약 Driving Type인 1값을 넣는다.       
        localStorage.setItem('reserve_temp_date', this.state.date)             
        localStorage.setItem('reserve_temp_startTime', this.state.startTime.toDate())
        localStorage.setItem('reserve_temp_endTime', this.state.endTime.toDate())    
        localStorage.setItem('reserve_temp_carLocation', this.state.carLocation)
        localStorage.setItem('reserve_temp_comment', this.state.comment)       
        localStorage.setItem('reserve_temp_passengername', this.state.passengerName)       
        localStorage.setItem('reserve_temp_passengerphone', this.state.passengerPhone) 
        
    }
    
    changeHourView = (data) => {
        return data.replace('시간','시간 ')
    }

    handleDelStopover = (e) => {
        let tmpArr = [];

        for (let i in this.state.stopOverList) {
            if (e.target.id === i) continue;
            else tmpArr.push(this.state.stopOverList[i]);
        }

        this.setState({
            stopOverList: tmpArr
        })
    }

    handleSaveDate = (data) => {
        return new Promise((resolve)=>{
            this.setState({ date: data }, () => {
                this.getCalcDrivingReservation();        
                resolve(true)

            });
        })
        
        
    }


    handleUpdateStartTimeGeneral = (data) => {
        this.setState({
            startTime: data,
            endTime: moment(data).add(1, 'hours'),
        }, () => {




            this.getCalcDrivingReservation();
        });
    }

    handleUpdateStartTimeGolf = (data) => {
        this.setState({
            startTime: data,
            endTime: moment(data).add(10, 'hours'),
        }, () => {
            this.getCalcDrivingReservation();
        });
    }

    handleUpdateEndtime = (data) => {
        this.setState({ endTime: data }, () => {
            this.getCalcDrivingReservation();
        });
    }

    handleCarLocation = (e) => {
        if (Util.getStringLength(e.target.value) <= TEXTAREA_MAX_LENGTH) {
            this.setState({ carLocation: e.target.value })
        }
    }

    handleComment = (e) => {
        if (Util.getStringLength(e.target.value) <= TEXTAREA_MAX_LENGTH) {
            this.setState({ comment: e.target.value })
        }
    }

    getCalcDrivingReservation = async (e) => {
        try {
            // "CustomerNo" : 12534,
            // "PaymentType": "2",
            // "DrivingType": 1,
            // "RevStartDt": "2020-12-16 00:00:00.000",
            // "RevEndDt" : "2020-12-16 02:30:00.000"
            const { cookies } = this.props;
            let tmpDate = this.state.date
            let tmpStartDate = this.state.startTime.toDate()
            let tmpEndDate = this.state.endTime.toDate()

            let tmpStartDateFormat = '';
            let tmpEndDateFormat = '';

            let testStart = Number(moment(tmpStartDate).format('YYYYMMDD'))                 // start date
            let testEnd = Number(moment(tmpEndDate).format('YYYYMMDD'));                     //end date1111111111

            const {
                startLocationAddress,
                startLocationLat,
                startLocationLng,
                endLocationAddress,
                endLocationLat,
                endLocationLng
            } = store.getState().LocationSelector_ReduxModule;


            // 아래 값에 변경이 이루어졌을때 계산중을 띄운다.
            if (this.state.moneyView !== 0 && this.state.hourView !== '일반 ') {
                this.setState({
                    moneyView: '(계산중...)',
                    hourView: '(계산중...)',
                })
            }

            // start date를 먼저 세팅해주고
            tmpStartDateFormat = plusSecondsMomentWithFormatCustom(
                tmpDate.getDate(),
                (tmpDate.getMonth() + 1),
                tmpDate.getFullYear(),
                tmpStartDate.getHours(),
                tmpStartDate.getMinutes(),
            )

            // 아래 코드를 넣는 이유 : 23:30 ~ 0:30 시간대일경우에는 0:30는 다음날로 처리해야하는데 당일로 처리하고 있어서
            // 계산 결과가 23시간 이렇게 나온다. 이 문제를 방지하고자 처리하는 것이다.
            // end가 start의 날짜를 넘어간다면 
            if (testStart < testEnd) {
                let tmpAfterDay = moment(this.state.date).add(1, 'days').toDate();

                tmpEndDateFormat = plusSecondsMomentWithFormatCustom(
                    tmpAfterDay.getDate(),
                    (tmpAfterDay.getMonth() + 1),
                    tmpAfterDay.getFullYear(),
                    tmpEndDate.getHours(),
                    tmpEndDate.getMinutes(),
                )
            }

            else {
                tmpEndDateFormat = plusSecondsMomentWithFormatCustom(
                    tmpDate.getDate(),
                    (tmpDate.getMonth() + 1),
                    tmpDate.getFullYear(),
                    tmpEndDate.getHours(),
                    tmpEndDate.getMinutes(),
                )
            }
            

            // 시작시간이 종료시간보다 작다면(timestamp로 비교)
            if (tmpStartDate.getTime() < tmpEndDate.getTime()) {
                let tmpObj = {
                    CustomerNo: this.state.CustomerNo,
                    PaymentType: '2',
                    DrivingType: this.state.reserveType,
                    RevStartDt: tmpStartDateFormat,
                    RevEndDt: tmpEndDateFormat,
                    // CouponId: this.state.CouponId,
                    StartAddress: startLocationAddress,
                    StartLat: startLocationLat,
                    StartLng: startLocationLng,

                    EndAddress: endLocationAddress,
                    EndLat: endLocationLat,
                    EndLng: endLocationLng,
                }
                this.setState({ loading: 0 })	// 로딩중
                let result = await ReserveApi.CalcDrivingReservation(
                    tmpObj,
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                )
                if (result.Result === 'success') {
                    localStorage.setItem('reserve_temp_ReseveCharge', result.Item.ReseveCharge)

                    this.setState({
                        calcItem: result.Item,
                        moneyView: result.Item.ReseveCharge,
                        hourView: result.Item.DrivingTimes,
                        loading: 1
                    })
                } else {
                    await calertPromise('금액을 계산 하지 못했습니다.')
                    this.setState({ loading: 1 })	// 로딩완료
                }

                console.log(result)

            } else {
                console.log('시작시간이 종료시간보다 클 수 없습니다.')
                this.setState({ loading: 1 })	// 로딩완료
            }
        } catch (e) {
            console.log(e)
            await calertPromise('금액 계산 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
            this.setState({ loading: 1 })	// 로딩완료
        }
    }

    handlePassengerCheckStatus = (e) => {
        let { loginedData } = store.getState().global_data

        this.setState({
            passengerCheckStatus: e.target.checked,
            passengerName: e.target.checked ? loginedData.CustomerName : '',
            passengerPhone: e.target.checked ? loginedData.PhoneNumber : ''
        })
    }

    handlePassengerName = (e) => {
        this.setState({
            passengerName: e.target.value
        })
    }

    handlePassengerPhone = (e) => {
        this.setState({
            passengerPhone: e.target.value
        })
    }

    handleNextBtnValidate = async () => {
        try {
            const {
                startTime,
                endTime,
                passengerName,
                passengerPhone,
                carLocation,
                comment,
                reserveType
            } = this.state;
            const {
                startLocationAddress,
                startLocationLat,
                startLocationLng,
                stopOverList,
                endLocationAddress,
                endLocationLat,
                endLocationLng
            } = store.getState().LocationSelector_ReduxModule;
            let retData = true;

            // 출발지 체크
            if (startLocationAddress.length === 0 ||
                startLocationLat === 0 ||
                startLocationLng === 0) {
                    await calertPromise('출발지를 입력해주세요.')
                return;
            }

            // 도착지 체크
            if (endLocationAddress.length === 0 ||
                endLocationLat === 0 ||
                endLocationLng === 0) {
                    await calertPromise(`${ReserveApi.getChangeStrJongryoji(this.state.reserveType)}를 입력해주세요.`)
                return;
            }

            // 경유지 체크 (1개 이상 추가시 체크대상)
            if (stopOverList.length >= 1) {
                for (let i in stopOverList) {
                    console.log(stopOverList[i]);
                    if (
                        (stopOverList[i].waypointAddress.length === 0) ||
                        (stopOverList[i].waypointLat.length === 0) ||
                        (stopOverList[i].waypointLng.length === 0)
                    ) {
                        await calertPromise(`${Number(i) + 1} 번째 경유지를 입력해주세요.`)
                        return;
                    }
                }
            }

            // 예약일(시작, 종료시간) 체크 (시작시간이 종료시간보다 더 크거나 같을때)
            if (startTime.toDate().getTime() >= endTime.toDate().getTime()) {
                await calertPromise('시작시간이나 종료시간을 확인해주세요.')
                return;
            }

            // 탑승자 정보 체크
            if (passengerName.length === 0 || passengerPhone.length === 0) {
                await calertPromise('탑승자정보 이름이나 전화번호를 입력해주세요.')
                return;
            }

            if (retData) {
                this.props.history.push('/reserve_confirm')
            } else {
                await calertPromise('입력 실패')
            }


        } catch (e) {
            console.log(e);
        }
    }

    render() {



        // const StartTimeSelectCustomComponent = ({ value, onClick }) => (
        //     <span className="ts1" >시작시간 <b onClick={onClick}>{value}</b></span>
        // );

        // const EndTimeSelectCustomComponent = ({ value, onClick }) => (
        //     <span className="ts1" >종료시간 <b onClick={onClick}>{value}</b></span>
        // );

        // const filterPassedTime = time => {
        //     const currentDate = new Date();
        //     const selectedDate = new Date(time);

        //     return currentDate.getTime() < selectedDate.getTime();
        // }

        // const filterPassedEndTime = time => {
        //     // 날짜를 한번 선택하면 이 함수도 호출되나? yes
        //     let retData = false;
        //     let startTime = new Date(this.state.startTime);
        //     let endTime = new Date(this.state.endTime);
        //     let listVisibleTime = new Date(time);

        //     // 같은 날이라면 시작시간 이후의 시간을 선택 가능하도록 처리하고
        //     if (
        //         startTime.getFullYear() === endTime.getFullYear() &&
        //         startTime.getMonth() === endTime.getMonth() &&
        //         startTime.getDay() === endTime.getDay()
        //     ) {
        //         retData = startTime.getTime() < listVisibleTime.getTime();

        //     }
        //     // 같은날의 다음날이라면 무조건 표시
        //     else {
        //         retData = true;
        //     }

        //     return retData;
        // }



        return (
            <>
            {!this.state.loading ? (<Loading />) : ''}
                <Header 
                    title={ReserveApi.getPartnershipStr(this.props.match.params.state)}
                    onBackButton={this.handleGotoBack}
                    onHomeButton={this.handleGotoBack}
                    />

                <div className="booking_box2 hd_margin_top">
                    <ul className={`bk_type1 bk_type_c${1}`}>
                        <li className="bk_head">
                            <span className="bk_title">{this.state.typeView}<img src={icon_arrow_down} className="icon_arrow" alt=""/></span>
                            
                            <span className="bk_info">
                                {ReserveApi.getDrivingTypeDescStr(1)}
                            </span>
                        </li>
                    </ul>


                    <ul className="bk_section">
                        <li className="section_box">

                            <LocationSelectorContainer history={this.props.history} endPointStr={ReserveApi.getChangeStrJongryoji(this.state.reserveType)}/>

                            <span className="list_notice">
                                <span className="list_notice_section">
                                    <span className="icon">
                                        <img src={icon_notice} className="icon_notice" alt="" />
                                    </span>
                                    <span className="text">계속해서 더 많은 경유지를 추가하실 경우, 요청사항에 메모해주세요.</span>
                                </span>
                                <span className="list_notice_section">
                                    <span className="icon">
                                        <img src={icon_notice} className="icon_notice" alt="" />
                                    </span>
                                    <span className="text">경유지는 최대 3개까지 등록하실 수 있습니다.</span>
                                </span>
                            </span>
                        </li>

                        <li className="section_box">
                            <span className="section_box_deatil">
                                <span className="section_title_booking">
                                    <span className="bline">예약일</span>
                                </span>
                                
                                <TimeSelector2
                                    startTime={this.state.startTime}
                                    endTime={this.state.endTime}
                                    selectedDate={this.state.date}
                                    type={this.state.typeView}
                                    onChangeStart={this.handleUpdateStartTimeGeneral}
                                    onChangeEnd={this.handleUpdateEndtime}
                                    onDateChange={this.handleSaveDate}
                                    fixedStartTime={this.state.fixedStartTime}
                                    hourView={this.changeHourView(this.state.hourView)}
                                />
                                
                                
                            </span>
                        </li>

                        <li className="section_box_title">차량위치 
                            {/* <a href="#" className="btn_modify">수정</a>  */}
                        </li>
                        <li className="section_box2">
                            <span className="section_box_deatil2">
                                <textarea 
                                    className="" 
                                    placeholder="차량위치를 입력해주세요."
                                    value={this.state.carLocation}
                                    onChange={this.handleCarLocation}
                                    >
                                 </textarea>
                                 {Util.getStringLength(this.state.carLocation)}/{TEXTAREA_MAX_LENGTH}
                                 {Util.getStringLength(this.state.carLocation) >= TEXTAREA_MAX_LENGTH ? (<b> (100자 이상 입력할 수 없습니다.)</b>) : ''}
                            </span>
                        </li>

                        <li className="section_box_title">탑승자정보</li>
                        <li className="section_box2 section_box2_padding">
                            <span className="partner_info">
                                <span className="pti_section1">
                                    
                                    <label className="chk_box">
                                        <input type="checkbox" checked={this.state.passengerCheckStatus} onChange={this.handlePassengerCheckStatus}/>
                                        <span></span>
                                         고객정보와 동일(다른 경우 입력 및 수정)
                                    </label>

                                </span>
                                <span className="pti_section2">
                                    <span className="form">
                                        <span className="form_name">탑승자 이름</span>
                                        <input type="text" className="input_fm1" value={this.state.passengerName} onChange={this.handlePassengerName}/>
                                    </span>
                                    <span className="form">
                                        <span className="form_name">휴대전화번호</span>
                                        <input type="text" className="input_fm1" value={this.state.passengerPhone} onChange={this.handlePassengerPhone}/>
                                    </span>
                                </span>
                            </span>
                        </li>


                        <li className="section_box_title">요청사항 
                        {/* <NavLink to="#" className="btn_modify">수정</NavLink> */}
                        </li>
                        <li className="section_box2">
                            <span className="section_box_deatil2">
                                <textarea 
                                    className="" 
                                    placeholder="요청사항을 입력해주세요."
                                    value={this.state.comment}
                                    onChange={this.handleComment}>
                                </textarea>
                                {Util.getStringLength(this.state.comment)}/{TEXTAREA_MAX_LENGTH}
                                {Util.getStringLength(this.state.comment) >= TEXTAREA_MAX_LENGTH ? (<b> (100자 이상 입력할 수 없습니다.)</b>) : ''}
                                
                            </span>
                        </li>
                    </ul>

                    <ul className="btn_fixed_box">
                        <span className="bk_infrom">
                            <span className="bki_f1">{this.state.typeView}&nbsp;{this.changeHourView(this.state.hourView)}</span>
                            <span className="bki_f2">이용예상금액 <b>{Util.numberWithCommas(this.state.moneyView)}원</b>
                            </span>
                        </span>
                        <span className="btn_box">
                            <button className="btn_st_2e303f" onClick={this.handleNextBtnValidate}>다음</button>
                        </span>
                    </ul>
                </div>
            </>
        );
    }
};

export default withCookies(ReserveGeneralView);
