import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
@charset "utf-8";

/* Rating */
.invite_form {width:100%;}
.invite_form ul { display:inline-block; width:100%;}
.invite_form li {}
.invite_form ul.ma_box { padding:20px; background:#f8f8f8; min-height:calc(100vh - 53px);}
.invite_form li.ma_notice {width:100vw; margin-left:-20px; background:#f5f6f7; padding:20px; font-size:1.1em; letter-spacing:-1px;}

.invite_form li.info_form { display:inline-block; width:100%; margin:30px 0 20px 0;}
.invite_form li.info_form span.invite_box { display:inline-block; width:100%; text-align:center;}
.invite_form li.info_form span.invite_box span.name {display:inline-block; width:100%; font-size:2.1em; padding:20px 0 0 0; margin-bottom:30px;}
.invite_form li.info_form span.invite_box span.code_box { display:inline-block; width:80%; text-align:center;}
.invite_form li.info_form span.invite_box span.code_box span.box_s1 { display:inline-block; background:#1fafd2; width:100%; padding:30px 10px; border-radius:20px 20px 20px 20px; border-bottom:2px dashed #339fba;}
.invite_form li.info_form span.invite_box span.code_box span.box_s2 { display:inline-block; background:#1fafd2; width:100%; border-radius:20px 20px 0 0; padding:30px 10px; color:#fff;}
.invite_form li.info_form span.invite_box span.code_box span.logo { display:inline-block; width:100%; padding:0px 0 20px 0;}
.invite_form li.info_form span.invite_box span.code_box span.logo img {width:100px;}
.invite_form li.info_form span.invite_box span.code_box span.title {display:inline-block; width:100%; color:#fff; font-size:1.5em; padding-bottom:10px;}
.invite_form li.info_form span.invite_box span.code_box span.code {display:inline-block; width:100%; font-size:2.2em; font-weight:bold; color:#fff;}




/* Member Information */
span.form_section { display:inline-block; width:100%; margin-bottom:30px;}


/* Form Type */
textarea.frm1 { display:inline-block; width:100%; height:150px; padding:20px; box-sizing:border-box; border:1px solid #ddd; line-height:150%; font-size:1.1em;}

/* Button Box */
.invite_form li.btn_box {width:calc(100% - 40px); position:absolute; bottom:20px;} /* 12.24 */
.invite_form li.btn_box2 {width:100%; padding:0px; position:relative; margin:50px 0 0px 0; left:0%;} /* 12.24 */

/* Button Type */
.btn_st_bmargin {margin-bottom:10px;}
.btn_st_f1f0f5 { display:inline-block; width:100%; background:#f1f0f5; height:50px; line-height:50px; font-size:1.3em; border:0px; color:#9a9a9a;}
.btn_st_2e303f { display:inline-block; width:100%; background:#2e303f; height:50px; line-height:50px; font-size:1.3em; border:0px; color:#fff;}
.btn_st_ffffff { display:inline-block; width:100%; background:#fff; height:50px; line-height:50px; font-size:1.3em; border:0px; color:#000;}
.btn_st2_ffffff { display:inline-block; width:100%; background:#fff; height:50px; line-height:50px; font-size:1.3em; border:1px solid #000; color:#000;}
.btn_st1_1fafd2 { display:inline-block; background:#1fafd2; height:45px; line-height:45px; font-size:1.1em; border:0px; color:#fff; padding:0 30px; border-radius:50px; font-weight:bold;}

.btn_st_49 {width:49% !important; margin-right:2%; float:left;}
.btn_st_50 {width:49% !important; float:left;}

`;