import ReserveApi from '../../lib/ReserveApi'

const INIT_DRIVING_TYPE = "global/INIT_DRIVING_TYPE";

export const init_driving_type_service = (data) => ({ type: INIT_DRIVING_TYPE, payload:data });
  
const initialState = {
    drivingTypeData:[]
}

export const getDrivingTypeRedux = (ostype, deviceid, loginEncrypt) =>  dispatch => {
    return new Promise(async (resolve, reject) => {
        try {
            let result = await ReserveApi.GetDrivingTypes(ostype, deviceid, loginEncrypt)
            if (result.Result === 'success') {
                dispatch(init_driving_type_service(result.ItemList));
                resolve(true)
            }
        } catch(e) {
            console.log(e)
            resolve(false);
        }
    })
    
}


export default (state = initialState, action) => {
    switch (action.type) {
        case INIT_DRIVING_TYPE:
            return {
                ...state,
                drivingTypeData: action.payload
            };

        default:
            return state;
    }
};

// arr.filter((data, idx)=>{console.log(data, idx); return this})
// arr.filter((data, i)=>(i !== idx))

/*
{
type: 'LocationSelector/STEP_OVER_ADD',
stopOverItem: '1'
}

{
type: 'LocationSelector/STEP_OVER_DEL',
stopOverDelItemIdx: '1'
}

{
type: 'LocationSelector/STEP_OVER_UPDATE',
stopOverUpdateData: 'test01', 
stopOverUpdateIdx: 1
}
*/