// 전역 Library 선언
import React, { Component } from 'react';
// import querystring from 'qs';
// import Iframe from 'react-iframe'
// import { API_BASE_URL, Token, PAYMENT_BASE_URL, PAYMENT_INTERFACE_URL, PARTNER_BASE_URL } from "../../constantData";
import { createGlobalStyle } from "styled-components";
import { withCookies } from 'react-cookie'

// Custom Library 선언
import RnInterface from '../../lib/RnInterface'
import Header from "../../components/Common/Header";

import store from '../../reduxStore'
import PaymentApi from '../../lib/PaymentApi'
import Loading from '../../components/Common/Loading'

import Util, {calert, calertPromise} from '../../lib/Util'

import '../../css/default.css';

const GlobalStyle = createGlobalStyle`
.iframe_area { display:block; width:100%; height:100vh; border:1px; }
`;


class ManageCardPartnerRegistView extends Component {
    state = {
        formData: null,
        ostype: 'A',
        outer_html: '<html><body>helloworld</body></html>',
        loading: 0
    }

    setUpFormData = async () => {
        // const { cookies } = this.props;
        // let type = await RnInterface.getData('devicetype');
        // Android일때는 native쪽에 결제모듈 화면을 띄우라는 메시지를 전송한다.
        // native는 MallUserId라는 메시지가 존재하는지를 확인하고 존재한다면 결제용 새로운 웹뷰를 띄우도록 처리하고 있다.     

        try {
            // 아래 데이터는 반드시 파트너 카드일때만 처리한다.
            const { cookies } = this.props;
            const {ebridgeUrl, mallReserved, paymentCardId} = this.props.history.location.state;

            await RnInterface.AndroidURLChange(this.props.location.pathname)

            await RnInterface.callReactNative(
                `PaymentCardPartner${ebridgeUrl}@@${mallReserved}@@${paymentCardId}`
            );

            // 결제 결과를 native -> webview측으로 보내서 
            // 이곳에서 받게된다.
            let resultData = await RnInterface.getDataOnlyListener()
            // alert(JSON.stringify(resultData));
            if (resultData === 'cancel') {
                this.props.history.replace('/card');
            } 
            else {
                let partnerCardResult = JSON.parse(resultData);

                if (partnerCardResult.Result === 'success') {
                    let setMallReservedResult = await PaymentApi.SetMallReserved(
                        partnerCardResult.paymentCardId,
                        partnerCardResult.mallReserved,
                        cookies.get('ostype'),
                        cookies.get('deviceid'),
                        cookies.get('login_encrypt')
                    )
    
                    if (setMallReservedResult && setMallReservedResult.Result === 'success') {
                        await calertPromise('제휴카드 등록이 완료되었습니다.')      //모시러 공항 홈발렛 제휴카드가 \n정상 등록되었습니다.
                        this.props.history.replace('/card');
                    } else {
                        await calertPromise('제휴카드 등록 실패하였습니다.')        //제휴카드 홈발렛 쿠폰처리 중 오류발생하였습니다.
                        this.props.history.replace('/card');
                    }
                } else {
                    await calertPromise(partnerCardResult.Message)      //모시러 공항 홈발렛 제휴카드가 \n정상 등록되었습니다.
                    this.props.history.replace('/card');
                }               
            }
        } catch (e) {
            await calertPromise(JSON.stringify(e));
            this.setState({loading:1})
            this.props.history.replace('/card');
            // calert(JSON.stringify(e))
            // JSON.parse에서 에러가 발생하면 이쪽에서 처리한다.
            // 결제부분에서 실패하거나 성공하면 RN쪽으로 json형태로 반환하고, 
            // 그 값을 web단으로 보낸다. 만약에 그 값이 json형태가 아닐경우
            // 예외처리를 실시해야한다.
            // calert('혹시 여기?')
            // calert(JSON.stringify(e))
        }

    }

    componentDidMount() {
        this.setUpFormData();
    }

    handleBack = () => {
        this.props.history.goBack();
    }

    handleHome = () => {
        this.props.history.replace('/main')
    }

    render() {
        return (
            <>
                {!this.state.loading ? (<Loading />) : ''}
                <GlobalStyle />
                <Header
                    title="결제등록하기"
                    onBackButton={this.handleBack}
                    onHomeButton={this.handleHome}
                />


            </>
        );
    }
};

export default withCookies(ManageCardPartnerRegistView);
/*
{this.state.ostype !== 'I' ?
                (<>
                거기냐
                <Iframe
                    url={`http://smilepay.mosiler.com/mainBillPay.aspx?MallUserId=${store.getState().global_data.loginedData.CustomerNo}`}
                    id="iframe_id"
                    className="iframe_area"
                />

                </>) :
                ('여기냐?')
                }

*/