// 전역 Library 선언
import React, { Component } from 'react';
// import querystring from 'qs';
// import Iframe from 'react-iframe'
// import { API_BASE_URL, Token, PAYMENT_BASE_URL, PAYMENT_INTERFACE_URL, PARTNER_BASE_URL } from "../../constantData";

import { withCookies } from 'react-cookie'

// Custom Library 선언
import RnInterface from '../../lib/RnInterface'
import Header from "../../components/Common/Header";

import store from '../../reduxStore'
import PaymentApi from '../../lib/PaymentApi'
import Loading from '../../components/Common/Loading'

import Util, {calert, calertPromise} from '../../lib/Util'

import '../../css/default.css';

class MainPaymentView extends Component {
    state = {
        loading: 0
    }

    setUpFormData = async () => {
        /*
        0. localStorage에 현재 데이터 임시 저장
        1. main_payment화면으로 이동 (이때 Redirect 정보도 같이 전달한다.)
        2. react native측으로 메시지 전달 (이때 web측은 메시지 리스너를 기동시킨다.)
            - 메시지 전달 : MainPayment{JSON.stringlfy처리한 state를 전달한다.}
            - ※이때, MID, BayerName 등의 정보도 미리 취득해서 전달해야한다. 이 정보가 없으면 동작보증할수없다.
        3. native측에서 메시지를 받는다.
        4. 메시지를 파싱하여 MainPayment라면 MainPayment WebView를 띄운다.
        5. 데이터를 JSON화 시키고, 내부 처리를 실시
        6. 처리가 끝나게 되면 메시지를 받는다.
        7. 메시지 가공후, native -> web로 데이터 전송

        8. web에서 메시지를 받게 되면 메시지 정보와 함께 본 화면으로 전달한다.
        9. 본 화면에 다시 와서, componentdidmount에서 this.props.location.state.result값을 체크한다.
        10. 존재하면 localStorage 데이터를 load하고, 계산 처리 실시, callback함수를 호출한다.(이때 메시지 정보도 전달한다.)
        11. callback 처리를 실시한다.
        12. callback 처리 실시 후 localstorage 삭제
        */
        try {
            // 아래 데이터는 반드시 파트너 카드일때만 처리한다.
            const { cookies } = this.props;
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            await RnInterface.callReactNative(
                `MainPayment${JSON.stringify(this.props.history.location.state)}`
            );

            // 결제 결과를 native -> webview측으로 보내서 
            // 이곳에서 받게된다.
            let resultData = await RnInterface.getDataOnlyListener()
            // alert(JSON.stringify(resultData));
            if (resultData === 'cancel') {
                let obj = {};
                await calertPromise('MainPayment 작업에 실패했습니다.')
                try {
                    obj = Util.getQueryStringObject()
                    if (obj.redirect !== undefined) {
                        this.props.history.replace({
                            pathname: `/${obj.redirect}`,
                            state : {
                                result_mainpayment: 'fail'
                            }
                        });
                    } else {
                        this.props.history.replace('/card');
                    }
                } catch(e) {
                    this.props.history.replace('/card');
                }
                
            } 
            else {
                let formData = JSON.parse(resultData);

                let setPaymentResult = await PaymentApi.SetPayment(
                    formData,
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                )

                if (setPaymentResult && setPaymentResult.Result === 'success') {
                    let obj = {};
                    await calertPromise('SetPayment 처리 완료')      //모시러 공항 홈발렛 제휴카드가 \n정상 등록되었습니다.
                    try {
                        obj = Util.getQueryStringObject()
                        if (obj.redirect !== undefined) {
                            this.props.history.replace({
                                pathname: `/${obj.redirect}`,
                                state : {
                                    result_mainpayment: 'success',
                                    data : formData
                                }
                            });
                        } else {
                            this.props.history.replace('/card');
                        }
                    } catch(e) {
                        this.props.history.replace('/card');
                    }
                    
                    
                    // this.props.history.replace('/card');
                } else {
                    let obj = {};
                    await calertPromise('SetPayment 처리 실패')      //모시러 공항 홈발렛 제휴카드가 \n정상 등록되었습니다.
                    try {
                        obj = Util.getQueryStringObject()
                        if (obj.redirect !== undefined) {
                            this.props.history.replace({
                                pathname: `/${obj.redirect}`,
                                state : {
                                    result_mainpayment: 'fail'
                                }
                            });
                        } else {
                            this.props.history.replace('/card');
                        }
                    } catch(e) {
                        this.props.history.replace('/card');
                    }
                    // await calertPromise('제휴카드 등록 실패하였습니다.')        //제휴카드 홈발렛 쿠폰처리 중 오류발생하였습니다.
                    // this.props.history.replace('/card');
                }
            }
        } catch (e) {
            await calertPromise(JSON.stringify(e));
            this.setState({loading:1})
            this.props.history.replace('/card');
            // calert(JSON.stringify(e))
            // JSON.parse에서 에러가 발생하면 이쪽에서 처리한다.
            // 결제부분에서 실패하거나 성공하면 RN쪽으로 json형태로 반환하고, 
            // 그 값을 web단으로 보낸다. 만약에 그 값이 json형태가 아닐경우
            // 예외처리를 실시해야한다.
            // calert('혹시 여기?')
            // calert(JSON.stringify(e))
        }

    }

    componentDidMount() {
        this.setUpFormData();
    }

    handleBack = () => {
        this.props.history.goBack();
    }

    handleHome = () => {
        this.props.history.replace('/main')
    }

    render() {
        return (
            <>
                {!this.state.loading ? (<Loading />) : ''}
                <Header
                    title="MainPayment"
                    onBackButton={this.handleBack}
                    onHomeButton={this.handleHome}
                />


            </>
        );
    }
};

export default withCookies(MainPaymentView);
