import React, { Component } from 'react';


import AccountApi from '../../lib/AccountApi';
import { calert, calertPromise } from '../../lib/Util'
import HeaderSimple from '../../components/Common/HeaderSimple';
import Loading from '../../components/Common/Loading'
import RnInterface from '../../lib/RnInterface'
import MemberJoinCustomCss from '../../css/CustomGlobalStyle/MemberJoinCustom'
import HeaderJoin2 from '../../components/Common/HeaderJoin2'


import '../../css/default.css';
import '../../css/member.css';


class MemberJoinAgreeView extends Component {
    state = {
        phone: '',
        email: '',
        loading:1
    }

    async componentDidMount() {
        try {
            await RnInterface.AndroidURLChange(this.props.location.pathname)
        }
        catch(e){
            console.log(e)
        }
    }

    handleBack = () => {
        this.props.history.goBack();
    }

    handleOnProcess = async () => {
        try {

            if (this.state.email.length && this.state.phone.length) {
                this.setState({loading:0})	// 로딩중
                // 디바이스 정보를 취득
                let deviceData = await RnInterface.getData('deviceinfo')
                let deviceInfoSplit = '';
        
                // false로 왔을때는 기본값 osType:A deviceid:1로 지정 (PC)
                // 차후에는 지워야할듯
                deviceData = (deviceData === false) ? 'I@@1' : deviceData;
        
                // deviceData가 false가 아니면 @@를 split한다.
                if (deviceData) {
                    deviceInfoSplit = deviceData.split('@@')
                }
    
                let result = await AccountApi.FindPassword(this.state.email, this.state.phone, deviceInfoSplit[0], deviceInfoSplit[1])
                if (result.Result === 'success') {
                    await calertPromise(result.Message);
                    this.setState({loading:1})	// 로딩완료
                    this.props.history.goBack()
                } else {
                    await calertPromise(result.Message);
                    this.setState({loading:1})	// 로딩완료
                }
            } else {
                await calertPromise('이메일 또는 전화번호가 입력되어있는지 확인해주세요.');
            }
            
            


        } catch(e) {
            await calertPromise('비밀번호 찾는 도중 오류가 발생했습니다. 관리자에게 문의해주세요.');
            this.setState({loading:1})	// 로딩완료
        }
        
    }

    handleChangePhone = (e) => {
        this.setState({ phone: e.target.value })
    }
    handleChangeEmail = (e) => {
        this.setState({ email: e.target.value })
    }

    render() {
        return (
            <>
                {!this.state.loading ? (<Loading />) : ''}
                
                <MemberJoinCustomCss />
                <HeaderJoin2
                    onBackButton={this.handleBack} />
                <main id="Contents"> 
                    <div className="key-visual-wrap">
                        <div className="key-visual-container">
                            <div className="key-contents">
                                <div className="key-text">
                                    회원 가입시 인증한<br /><strong>휴대전화번호를 입력하세요.</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="component-wrap inner-container">
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">휴대전화번호</label>									
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <input type="tel" placeholder="- 없이 숫자만 입력" className="input type1" value={this.state.phone} onChange={this.handleChangePhone} />
                                    </div>
                                </div>	
                            </div>                                                
                        </div>
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">아이디</label>									
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <input type="email" placeholder="이메일을 입력해주세요." className="input type1" value={this.state.email} onChange={this.handleChangeEmail} />
                                    </div>
                                </div>	
                            </div>                                                
                        </div>                        
                    </div> 
                    <div className="component-wrap">
                        <div className="btn-area btn-group-fixed vertical">
                            <button type="button" className="btn large secondary" onClick={this.handleOnProcess}><span>확인</span></button>
                        </div>
                    </div>                                            
                </main>

            </>
        );
    }
};

export default MemberJoinAgreeView;
