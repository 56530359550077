import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LoginViewCss from '../css/CustomGlobalStyle/LoginView'

import $script from 'scriptjs';

import sha1 from 'sha1';
import AccountApi from '../lib/AccountApi';
import RnInterface from '../lib/RnInterface'
import {global_data_async} from '../modules/Global/global_data'

import CookieApi from '../lib/CookieApi'
import { withCookies } from 'react-cookie';

import {calert, calertConfirm, calertNoticePopup, calertPromise} from '../lib/Util';
import store from '../reduxStore'
// import ChannelService from '../class/ChannelService'
// import {CHANNEL_PLUGIN} from '../constantData'


import axios from 'axios';

import '../css/default.css';
import '../css/member.css';
import 'sweetalert2/dist/sweetalert2.css'
import '../css/message.css';

//view_~~~ 로 시작하는 파일은 html inline에 style로 정해놓은 것을 css화 시킨 것이다.
// import '../css/view_login.css';

import logo_w from '../img/logo/logo_w.svg';
import login_kakao from "../img/login/login_kakao.svg";
import Loading from '../components/Common/Loading'

import {withTranslation} from "react-i18next";
import i18next from "../config/language/i18n";


/*
로그인 이후에 처리해야할 것(실기 입장에서)
1. 아래의 정보를 쿠키에 넣는다.
  - ID
  - hashcode(Password) - SHA1로 암호화 (Account Type이 Email일때만 체크한다.)
  - AccountType   -- kakao, email, apple
  - login_encrypt  -- login이후에 취득
  - ostype           -- login이후에 취득
  - deviceid         -- login이후에 취득

LoginView에서 해야할 일
※이 화면으로 들어오는 경우는
처음 앱을 켰을때나 쿠키가 존재하지 않아 화면으로 넘어온 케이스거나 로그아웃 되었을때이다.

로그인은 3종류가 있다.
Email Login
Kakao Login 
Apple Login

기본값은 Email이다.

kakao, apple는 주로 버튼을 눌러서 로그인하게 되는 방식이다.

Account Type에 따라 로그인 방식이 다르다.

* Email Login시의 로직
    정보 입력후 로그인 클릭시
    accountType체크 후 Email이라면



* Kakao Login시의 로직
    accountType -> kakao로 변경

* Apple Login시의 로직
    accountType -> apple로 변경

*/

class LoginView extends Component {

    state = {
        emailId:'',
        pw:'',
        accountType:'email',
        ostype:'',
        loading:0,
        autoLoginState: true
    }

    asyncProcess = async () => {
        let deviceOsType = await RnInterface.getData('devicetype')
        await RnInterface.callReactNative('loginStatusBarChange')
        await RnInterface.AndroidURLChange(this.props.location.pathname)
        this.setState({ostype: deviceOsType,loading:1})
    }

    componentDidMount() {
        console.log(process.env)
        localStorage.setItem('autoLoginState_Apple', this.state.autoLoginState ? 1 : 0);
        this.asyncProcess();
        // 회원가입 관련 삭제
        AccountApi.removeJoinLocalStorageData();
        
        const apple_url = `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/ko_KR/appleid.auth.js`;
        // 이처리를 넣은 이유는 index.html에 넣어보니 한번 로드되고 화면 전환한 다음 다시 로그인 화면으로 돌아올때 apple login 화면이 나오지 않아서 
        // 로그인화면이 로드 될때 본 스크립트를 호출해서 아이콘이 표시되도록 한다.
        $script(apple_url, ()=>{
            window.AppleID.auth.init({
                clientId : 'com.mosiler.applelogin',
                scope : 'name email',
                redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URI,
                // redirectURI: 'http://mkikm2.ddns.net:8080/api/redirect_apple',
                state : 'g123'
            });
        });
    }



    /*
   ********************** 로그인 성공시 response (isforce : N)  **********************
    WebView에서는 Blank가 안뜬다.. 해결 방안이 있을까?
    componentDidMount() {
        console.log(process.env)
        localStorage.setItem('autoLoginState_Apple', this.state.autoLoginState);
        this.asyncProcess();
        // 회원가입 관련 삭제
        AccountApi.removeJoinLocalStorageData();
        
        const apple_url = `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/ko_KR/appleid.auth.js`;
        // 이처리를 넣은 이유는 index.html에 넣어보니 한번 로드되고 화면 전환한 다음 다시 로그인 화면으로 돌아올때 apple login 화면이 나오지 않아서 
        // 로그인화면이 로드 될때 본 스크립트를 호출해서 아이콘이 표시되도록 한다.
        $script(apple_url, ()=>{
            window.AppleID.auth.init({
                clientId : 'com.mosiler.applelogin',
                scope : 'name email',
                redirectURI: window.location.href,
                state : 'g123',
                usePopup : true
            });
            //https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
            //https://kedric-me.tistory.com/entry/Apple%EB%A1%9C-%EB%A1%9C%EA%B7%B8%EC%9D%B8-%EA%B5%AC%ED%98%84web-and-other-platforms
        });

        //Listen for authorization success
        document.addEventListener('AppleIDSignInOnSuccess', (data) => {
            console.log(data);
            alert('성공')
        });
        //Listen for authorization failures
        document.addEventListener('AppleIDSignInOnFailure', (error) => {
            console.log(error);
            alert('실패')
        });
        
    }
    */



    handleLoginClick = async () =>{
        try {
            this.setState({loading:0})
            
            let {cookies} = this.props
            // id, pw validate chk
    
            let resultData = await AccountApi.requestLogin(this.state.emailId, sha1(this.state.pw), this.state.accountType)
            if (resultData.Result === 'success') {
                //resultData.Item
                await store.dispatch(global_data_async(resultData.Item));
    
                CookieApi.SettingCookie(cookies, {
                    UserId : resultData.Item.UserId,
                    AccountType : resultData.Item.AccountType,
                    hash : sha1(this.state.pw),
                    LoginEncrypt : resultData.Item.LoginEncrypt,
                    OsType : resultData.Item.OsType,
                    DeviceId : resultData.Item.DeviceId
                }, this.state.autoLoginState ? 1 : 0)

                // localStorage에 갱신
                localStorage.setItem('mosiler_cookie',JSON.stringify({
                    account_type        : resultData.Item.AccountType,
                    deviceid            : resultData.Item.DeviceId,
                    email_id            : resultData.Item.UserId, 
                    login_encrypt       : resultData.Item.LoginEncrypt,
                    ostype              : resultData.Item.OsType,
                    auto_login_chk      : this.state.autoLoginState ? 1 : 0,
                    hashcode            : sha1(this.state.pw),
                }))
                
                this.props.history.replace('/main');
            } else {                
                await calertPromise(resultData.Message)
                this.setState({loading:1})
            }
        }
        catch(e){
            await calertPromise('로그인도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
            this.setState({loading:1})
            console.log(e)
        }
        
    }

    // 현재 사용 안함
    // handleClickKakao = async () => {
    //     let {store} = this.props
    //     let {cookies} = this.props
        
    //     try {
    //         let kakao_result = await AccountApi.kakaoLoginAuth();
    //         let login_result = await AccountApi.requestLogin('kakao@' + kakao_result.id, '', 'kakao')
            
    //         console.log(kakao_result)
    //         console.log(login_result)
    //         console.log('kakao@' + kakao_result.id);
            
    //         // 로그인 성공시
    //         if (login_result.Result === 'success') {
    //             //resultData.Item
    //             await store.dispatch(global_data_async(login_result.Item));

    //             CookieApi.SettingCookie(cookies, {
    //                 UserId : login_result.Item.UserId,
    //                 AccountType : login_result.Item.AccountType,
    //                 hash : sha1(this.state.pw),
    //                 LoginEncrypt : login_result.Item.LoginEncrypt,
    //                 OsType : login_result.Item.OsType,
    //                 DeviceId : login_result.Item.DeviceId
    //             }, this.state.autoLoginState ? 1 : 0)

    //             this.props.history.replace('/main');
    //         } 
    //         // 로그인 실패시는 회원가입 화면으로 이동
    //         else {
    //             localStorage.setItem('AccountType','kakao');
    //             localStorage.setItem('c_emailid', ('kakao@' + kakao_result.id) );
    //             localStorage.setItem('c_password',"" );
    //             localStorage.setItem('emailaddress', kakao_result.kakao_account.email);
    //             this.props.history.push('/join/2')

    //             // fail이라면 회원가입이 되어있지 않다.
    //         }
            
    //         // console.log(await this.processKakaoLogin('kimpaksayosi@kakao.com', 'Y'));
    //         /*
    //         * Email 동의를 거부했을 경우의 데이터
    //         kakao_account:
    //             email_needs_agreement: true
    //             has_email: true

    //             => 이 경우
            
    //         * Email 동의했을 경우의 데이터
    //         kakao_account:
    //             email: "kimpaksayosi@kakao.com"
    //             email_needs_agreement: false
    //             has_email: true
    //             is_email_valid: true
    //             is_email_verified: true

    //             email이 존재할때 SnsLogin?id={id}&isforce={isforce} 시도한다.
    //             없다면 회원가입 폼으로 이동한다.
    //         */
    //     }
    //     catch(e) {
    //         console.log(e);
    //     }
    // }

    // 미사용
    // handleClickKakao2 = async () => {
    //     this.setState({loading:0})
    //     window.Kakao.Auth.authorize({
    //         redirectUri: process.env.REACT_APP_KAKAO_REDIRECT_URI
    //     });
    //     // GET /oauth/authorize?client_id={REST_API_KEY}&redirect_uri={REDIRECT_URI}&response_type=code HTTP/1.1
    //     // Host: kauth.kakao.com
    // }

    // handleCilckKakaoLogout = () => {
    //     try {
    //         window.Kakao.API.request({
    //             url: '/v1/user/unlink',
    //             success: function(response) {
    //               console.log(response);
    //             },
    //             fail: function(error) {
    //               console.log(error);
    //             },
    //           });
    //     }
    //     catch(e) {
    //         console.log(e);
    //     }
    // }

    handlePreJoinProcess = (e) => {
        e.preventDefault();
        localStorage.setItem('AccountType','email');
        this.props.history.push('/join/1')
    }

    handleEmail = (e) => {this.setState({emailId: e.target.value})}
    handlePw = (e) => {this.setState({pw: e.target.value})}


    handleFreeClick2 = async () => {
        let dat = await this.processFreeMember(12532)
        console.log(dat);
    }

    handleClickKakaoNativeLogin = async () => {
        try {
            this.setState({loading:0})
            
            // kakao login react native test
            let kakaoNativeResult = await RnInterface.getData('kakaoNativeLogin')           // Native측에서 kakao 인증 처리를 실시한다.
            let final_result = JSON.parse(kakaoNativeResult)
            

            if (final_result.id !== undefined) {
                let login_result = await AccountApi.requestLogin('kakao@' + final_result.id, '', 'kakao')

                // 로그인 성공시
                if (login_result.Result === 'success') {
                    let { cookies } = this.props;
                    await store.dispatch(global_data_async(login_result.Item));

                    CookieApi.SettingCookie(cookies, {
                        UserId: login_result.Item.UserId,
                        AccountType: login_result.Item.AccountType,
                        hash: '',
                        LoginEncrypt: login_result.Item.LoginEncrypt,
                        OsType: login_result.Item.OsType,
                        DeviceId: login_result.Item.DeviceId
                    }, this.state.autoLoginState ? 1 : 0)

                    // localStorage에 갱신
                    localStorage.setItem('mosiler_cookie', JSON.stringify({
                        account_type: login_result.Item.AccountType,
                        deviceid: login_result.Item.DeviceId,
                        email_id: login_result.Item.UserId,
                        login_encrypt: login_result.Item.LoginEncrypt,
                        ostype: login_result.Item.OsType,
                        auto_login_chk: this.state.autoLoginState ? 1 : 0,
                        hashcode: '',
                    }))
                    
                    // 메인 화면으로 이동
                    this.props.history.push('/main')
                }
                // 로그인 실패시는 회원가입 화면으로 이동
                else {
                    localStorage.setItem('AccountType', 'kakao');
                    localStorage.setItem('c_emailid', ('kakao@' + final_result.id));
                    localStorage.setItem('c_password', "");
                    
                    this.props.history.push('/join/1')
                }

            }
            // getKakaoData() 처리 실패시
            else {
                await calertConfirm('카카오 사용자 정보를 받을 수 없습니다. 다시 시도해주세요.');
                this.setState({loading:1})
                // await this.redirectLoginView()
            }
        }
        catch (e) {
            await calertConfirm('카카오 로그인 도중 오류가 발생했습니다. 관리자에게 문의해주세요.');
            console.log(e)
            this.setState({loading:1})
        }


    }



    // handleTestClick2 = () => {
    //     calert(window.getDeviceType());
    //     calert(window.getDeviceId());
    // }

    // handleTestClick3 = () => {
    //     this.props.history.push('/test_payment')
    // }

    handleSearchId = (e) => {
        e.preventDefault();
        this.props.history.push('/search_id')
    }

    handleSearchPw = (e) => {
        e.preventDefault();
        this.props.history.push('/search_pw')
    }

    handleAutoLogin = (e) => {
        // localStorage.setItem('autoLoginState_Apple', e.target.checked ? 1 : 0);
        this.setState({autoLoginState: e.target.checked});
        // console.log(localStorage.getItem('autoLoginState_Apple'));
    }
    handleAutoLogin2 = () => {
        // localStorage.setItem('autoLoginState_Apple', e.target.checked ? 1 : 0);
        this.setState((prevState)=>{return {autoLoginState: !prevState.autoLoginState}});
        // console.log(localStorage.getItem('autoLoginState_Apple'));
    }

    // handleTestClick1 = ( ) => {
    //     // Boot Channel as a registered user
    //     const settings = {
    //         userId: 'kimpaksayosi@naver.com',
    //         pluginKey: CHANNEL_PLUGIN,
    //         mobileMessengerMode :'iframe',
    //         profile: {
    //           name: '김상우',
    //           mobileNumber: '01087223834',
    //           email: 'kimpaksayosi@naver.com',
    //         },
    //         locale: "ko",
    //       };

    //     ChannelService.boot(settings);
    // }

    // handleTestClick11 = ( ) => {
    //     ChannelService.shutdown();
    // }

    render() {
        const {t} = this.props;
        
        return (
            <>


                {!this.state.loading ? (<Loading />) : ''}
                <LoginViewCss/>
                
                <div className="login_box">
                
                    <span className="logo_box"><img src={logo_w} className="logo" alt="" /></span>
                    { (window.location.href.indexOf('devclient.mosiler.com') !== -1) && '개발버전'}
                    <span className="login_form">
                        
                        <input type="email" placeholder={t('LoginView_placeholder_email')} className="frm_st1" onChange={this.handleEmail} value={this.state.emailId}/>
                        <input type="password" placeholder={t('LoginView_placeholder_password')} className="frm_st1" onChange={this.handlePw} value={this.state.pw}/>
                        <button className="frm_login" onClick={this.handleLoginClick}>{t('LoginView_btn_Login')}</button>
                        
                        {/* <span className="frm_auto">
                            <span className="auto">
                                <label className="chk_box">
                                    <input type="checkbox" checked={this.state.autoLoginState} onChange={this.handleAutoLogin} />
                                    <span></span> 
                                    {t('LoginView_chkbox_autologin')}
                                </label>
                            </span>
                            <span className="search">
                                <Link to="#" onClick={this.handleSearchId}>{t('LoginView_chkbox_searchid')}</Link>
                                <a class="section_bar">|</a>
                                <Link to="#" onClick={this.handleSearchPw}>{t('LoginView_chkbox_searchpw')}</Link>
                            </span>
                        </span> */}

                        <span className="auto_login_container">
                            <span className="auto_login_flex_container">
                                <span className="auto_login_flex_item_login">
                                    <label className="chk_box2">
                                        <input type="checkbox" checked={this.state.autoLoginState} onChange={this.handleAutoLogin} />
                                        <span></span> 
                                    </label>
                                </span>
                                <span className="auto_login_flex_item_login" onClick={this.handleAutoLogin2}>
                                &nbsp;<span>{t('LoginView_chkbox_autologin')}</span>
                                </span>                                
                                <span className="auto_login_flex_item_blank">
                                    <span></span>
                                </span>
                                <span className="auto_login_flex_item_search">
                                <Link to="#" onClick={this.handleSearchId}>{t('LoginView_chkbox_searchid')}</Link>
                                <a class="section_bar">|</a>
                                <Link to="#" onClick={this.handleSearchPw}>{t('LoginView_chkbox_searchpw')}</Link>
                                </span>
                            </span>
                        </span>


                        
                        <span className="sns_login">
                            <button className="sns_login_kakao" onClick={this.handleClickKakaoNativeLogin}><img src={login_kakao} alt=""/><b>{t('LoginView_btn_kakao')}</b></button>

                            
                            {this.state.ostype !== 'A' ? (<div onClick={()=>{
                                this.setState({loading:0}); 
                                setTimeout(()=>{
                                    this.setState({loading:1}); 
                                },25000)
                                localStorage.setItem('autoLoginState_Apple', this.state.autoLoginState ? 1 : 0);}} 
                                
                                id="appleid-signin" 
                                className="signin-button" 
                                data-color="white" 
                                data-height="55" 
                                data-border="false" 
                                data-type="sign-in"></div>) : ('')}                           

                            {/* <button className="sns_login_kakao" onClick={this.handleTestClick1}><img src={login_kakao} alt=""/>RN측 Kakao Login Test</button>
                            <button className="sns_login_kakao" onClick={this.handleTestClick11}><img src={login_kakao} alt=""/>RN측 Kakao Login Test</button> */}
                            

                            {/* <button className="sns_login_kakao" onClick={this.handleClickKakao2}><img src={login_kakao} alt=""/>kakao 로그인테스트</button> */}
                            
                            {/* <button className="sns_login_kakao" onClick={this.handleCilckKakaoLogout}><img src={login_kakao} alt=""/>카카오 연결끊기(삭제예정)</button>
                            <button className="sns_login_kakao" onClick={this.handleFreeClick2}><img src={login_kakao} alt=""/>탈퇴</button>
                            <button className="sns_login_kakao" onClick={this.handleTestClick1}><img src={login_kakao} alt=""/>RN 위경도취득</button>
                            
                            <button className="sns_login_kakao" onClick={this.handleTestClick3}><img src={login_kakao} alt=""/>TestPayment 화면</button> */}
                            {/* <button className="sns_login_kakao"><img src={login_kakao} alt=""/>기기정보값 확인</button> */}
                        </span>
                        <span className="join_guide">
                        {t('LoginView_text_firstmosiler')} <Link to="#" onClick={this.handlePreJoinProcess}><u><b>{t('LoginView_link_join')}</b></u></Link>
                        </span>
                    </span>
                </div>
            </>
        );
    }
};

/*
Encountered an error loading page 
{"canGoBack": false, "canGoForward": false, "code": -10, "description": "net::ERR_UNKNOWN_URL_SCHEME", "loading": false, "target": 7, "title": "React App", "url": "intent:#Intent;action=com.kakao.talk.intent.action.CAPRI_LOGGED_IN_ACTIVITY;launchFlags=0x08880000;S.com.kakao.sdk.talk.appKey=886a0aa4b33e8a2538fdbf6ed97c7722;S.com.kakao.sdk.talk.redirectUri=http://192.168.219.100:3000/oauth;S.com.kakao.sdk.talk.kaHeader=sdk/1.39.10 os/javascript sdk_type/javascript lang/ko-KR device/Linux_aarch64 origin/http%3A%2F%2F192.168.219.100%3A3000;S.com.kakao.sdk.talk.extraparams=%7B%22client_id%22%3A%22886a0aa4b33e8a2538fdbf6ed97c7722%22%2C%22redirect_uri%22%3A%22http%3A%2F%2F192.168.219.100%3A3000%2Foauth%22%2C%22response_type%22%3A%22code%22%2C%22auth_tran_id%22%3A%22vol3tiv8fun886a0aa4b33e8a2538fdbf6ed97c7722kixexf84%22%2C%22is_popup%22%3Atrue%7D;S.browser_fallback_url=https%3A%2F%2Fkauth.kakao.com%2Foauth%2Fauthorize%3Fclient_id%3D886a0aa4b33e8a2538fdbf6ed97c7722%26redirect_uri%3Dhttp%253A%252F%252F192.168.219.100%253A3000%252Foauth%26response_type%3Dcode%26auth_tran_id%3Dvol3tiv8fun886a0aa4b33e8a2538fdbf6ed97c7722kixexf84%26ka%3Dsdk%252F1.39.10%2520os%252Fjavascript%2520sdk_type%252Fjavascript%2520lang%252Fko-KR%2520device%252FLinux_aarch64%2520origin%252Fhttp%25253A%25252F%25252F192.168.219.100%25253A3000%26is_popup%3Dfalse;end;"}

"url":"intent:#Intent;action=com.kakao.talk.intent.action.CAPRI_LOGGED_IN_ACTIVITY;
launchFlags=0x08880000;
S.com.kakao.sdk.talk.appKey=886a0aa4b33e8a2538fdbf6ed97c7722;
S.com.kakao.sdk.talk.redirectUri=http://192.168.219.100:3000/oauth;
S.com.kakao.sdk.talk.kaHeader=sdk/1.39.10 os/javascript sdk_type/javascript lang/ko-KR device/Linux_aarch64 origin/http%3A%2F%2F192.168.219.100%3A3000;
S.com.kakao.sdk.talk.extraparams=%7B%22client_id%22%3A%22886a0aa4b33e8a2538fdbf6ed97c7722%22%2C%22redirect_uri%22%3A%22http%3A%2F%2F192.168.219.100%3A3000%2Foauth%22%2C%22response_type%22%3A%22code%22%2C%22auth_tran_id%22%3A%22vol3tiv8fun886a0aa4b33e8a2538fdbf6ed97c7722kixexf84%22%2C%22is_popup%22%3Atrue%7D;
S.browser_fallback_url=https%3A%2F%2Fkauth.kakao.com%2Foauth%2Fauthorize%3Fclient_id%3D886a0aa4b33e8a2538fdbf6ed97c7722%26redirect_uri%3Dhttp%253A%252F%252F192.168.219.100%253A3000%252Foauth%26response_type%3Dcode%26auth_tran_id%3Dvol3tiv8fun886a0aa4b33e8a2538fdbf6ed97c7722kixexf84%26ka%3Dsdk%252F1.39.10%2520os%252Fjavascript%2520sdk_type%252Fjavascript%2520lang%252Fko-KR%2520device%252FLinux_aarch64%2520origin%252Fhttp%25253A%25252F%25252F192.168.219.100%25253A3000%26is_popup%3Dfalse;end;"


*/

export default withTranslation()(withCookies(LoginView));
