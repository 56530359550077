import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Progress } from 'react-sweet-progress';

// redux
import store from '../../reduxStore'
import { init_location_selector_service } from '../../modules/Reserve/LocationSelector_ReduxModule'
import { init_time_all_selector_service } from '../../modules/Reserve/TimeSelector_ReduxModule'
import { empty_use_state_service } from '../../modules/UseState/UseState_ReduxModule'
import { empty_use_history_service } from '../../modules/UseHistory/UseHistory_ReduxModule'
import { getDrivingTypeRedux } from '../../modules/Global/DrivingType_ReduxModule';
import { withCookies } from 'react-cookie'
import { NOTICE_ALERT_RESULT_CODE, MAX_COOKIE_DATE } from '../../constantData'

import RnInterface from '../../lib/RnInterface'
import ReserveApi from '../../lib/ReserveApi'
import NoticeApi from '../../lib/NoticeApi'
import AccountApi from '../../lib/AccountApi'
import MonthlyMosilerApi from '../../lib/MonthlyMosilerApi';
import Loading from '../../components/Common/Loading'

// import { calertPromise } from '../../lib/Util';
// import {calertConfirm, calertNoticePopup2} from '../../lib/Util'
import Util, { calert, calertConfirm, calertNoticePopup2, calertPromise, isChkMonthlyMosilerCustomer, getCustomerCategoryViewTarget, getUserInfoEncrypt } from '../../lib/Util'


import 'react-slideshow-image/dist/styles.css'
import "react-sweet-progress/lib/style.css";

// import axios from 'axios'
// import moment from 'moment'

import '../../css/default.css';
import '../../css/booking.css';

// import service_car from "../../img/booking/service_car.svg";
// import icon_card_hana from "../../img/booking/icon_card_hana.svg";
// import icon_card_master from "../../img/booking/icon_card_master.svg";
// import btn_prev from '../../img/btn/btn_prev.svg'
// import btn_home from '../../img/btn/btn_home.svg'
// import icon_car_search from '../../img/booking/icon_car_search.png'
// import icon_chat from '../../img/booking/icon_chat.svg'

import icon_map_car from '../../img/booking/icon_map_car.png'
import HeaderMain from '../../components/Common/HeaderMain';

import icon_m1 from '../../img/menu/icon_m1.svg';
import icon_m2 from '../../img/menu/icon_m2.svg';
import icon_m3 from '../../img/menu/icon_m3.svg';
import icon_m4 from '../../img/menu/icon_m4.svg';
import icon_m5 from '../../img/menu/icon_m5.svg';
// import icon_m6 from '../../img/menu/icon_m6.svg';
import icon_m7 from '../../img/menu/icon_m7.svg';
import icon_m8 from '../../img/menu/icon_m8.svg';
import icon_m9 from '../../img/menu/icon_m9.svg';
// import icon_m11 from '../../img/menu/icon_m11.svg';
import icon_m12 from '../../img/menu/icon_m12.svg';
import icon_m13 from '../../img/menu/icon_m13.png';
import icon_m15 from '../../img/menu/icon_m15.svg';

import arrow_detail from '../../img/arrow_detail.svg'

import icon_monthly_faq from '../../img/menu/new_icon/icon-monthly-faq.svg'
import icon_monthly_manual from '../../img/menu/new_icon/icon-monthly-manual.svg'
import icon_monthly_reserve from '../../img/menu/new_icon/icon-monthly-reserve.svg'
import icon_monthly_reservelist from '../../img/menu/new_icon/icon-monthly-reservelist.svg'
import icon_monthly_header_01 from '../../img/menu/new_icon/icon-monthly-header-01.svg'
import icon_monthly_header_02 from '../../img/menu/new_icon/icon-monthly-header-02.svg'

import icon_general_golfreserve from '../../img/menu/new_icon/icon-general-golfreserve.svg'
import icon_general_mm from '../../img/menu/new_icon/icon-general-mm.svg'
import icon_general_reserve from '../../img/menu/new_icon/icon-general-reserve.svg'
import icon_general_yearmm from '../../img/menu/new_icon/icon-general-yearmm.svg'
import icon_general_header_01 from '../../img/menu/new_icon/icon-general-header-01.svg'
import icon_general_header_02 from '../../img/menu/new_icon/icon-general-header-02.svg'






import btn_cancel from "../../img/menu/btn_cancel.svg"
import btn_arrow2 from "../../img/menu/btn_arrow2.svg"
import btn_arrow from "../../img/menu/btn_arrow.svg"

import MainMenuMyInfoCss from '../../css/CustomGlobalStyle/MainMenuMyInfo'

// import visual_general from '../../img/img_booking_general.jpg'
import visual_golf from '../../img/img_booking_golf.jpg'
import visual_black from '../../img/img_booking_black.jpg'
import visual_rev_confirm from '../../img/booking/list_img6_2.png'


// 2021/12/7 추가
import { Fade, Slide  } from 'react-slideshow-image';
import visual_ski from '../../img/img_booking_ski.png'
import visual_horse from '../../img/img_booking_horse.png'
import visual_golf2 from '../../img/img_booking_golf2.png'

import banner_hansung from '../../img/banner/banner_hansung.png'
import banner_hilton from '../../img/banner/banner_hilton2.png'


import AppSettingApi from '../../lib/AppSettingApi';
import compareVersions  from 'compare-versions'

// import event_monthly_mosiler from '../../img/menuevent/monthlymosiler/top_monthly_mobile.png'
import genesis_intro_image from '../../img/banner/genesis_intro_image.png'
import mm_bg_header from '../../img/banner/mm-bg-header.jpg'

const NOTICE_LINK_STATE_MAIN_MENU = '0';
// const NOTICE_LINK_STATE_NOTICE = '1';

// const DEVICE_VER_HIGH = 1;      // deviceVer가 더 크면 1
const SERVER_VER_HIGH = -1;     // serverVer가 더 크면 -1
// const SAME_VER = 0;             // 버전이 서로 같다면 0

class ReserveView extends Component {
    state = {

        loading: 0,
        visibleReserve: false,
        visibleLive: false,
        name: store.getState().global_data.loginedData.CustomerName,
        phone: store.getState().global_data.loginedData.PhoneNumber,
        CustomerCategory: 0,
        UseTime:0, RemainTime:0, AvaliableTime:0, UsePercent:0,
    }

    intervalData = null;

    chkVersion = (deviceVer, serverVer) => {
        // deviceVer가 더 크면 1
        // serverVer가 더 크면 -1
        // 버전이 서로 같다면 0
        console.log(compareVersions(deviceVer, serverVer))
        return compareVersions(deviceVer, serverVer);
    }


    async componentDidMount() {
        try {
            this.setState({ loading: 0 })
            
            // CustomerCategory
            let { cookies } = this.props;
            let { drivingTypeData } = store.getState().DrivingType_ReduxModule
            let { CustomerNo, CustomerCategory } = store.getState().global_data.loginedData
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            this.setState({ CustomerCategory })
            let fcmtoken = await RnInterface.getData('fcmtoken')

            // 1.3.20부터 이부분 처리가 무의미하다.
            // 카톡앱을 딥링크로 열기만 하기 때문에 고객앱측에서 카메라, 마이크 권한을 물어볼 필요가 없다.
            // this.intervalData = setInterval(()=>{
            //     console.log('setInterval()');
            //     if (document.querySelector('#ch-plugin-launcher') !== null) {
            //         clearInterval(this.intervalData);
            //          document.querySelector('#ch-plugin-launcher').onclick= async ()=>{
            //              await RnInterface.callReactNative('channelIOCameraPermissionRequest')
            //          }
            //     }
            // },1000)
            
            
            await RnInterface.callReactNative('loginedStatusBarChange') // 메뉴 화면으로 들어갔다면 native측에 스테이터스 바 색 변경을 진행하라고 요청 보낸다.
            

            // 예약 종류 데이터 취득. (일반, 골프, 공항홈발렛 등등..)
            // 메인 메뉴 화면을 API호출받아서 표시하는 것에 의존하다보면
            // 정작 API호출 안되었을때는 데이터가 취득이 안될 것이 염려되어
            // 메인화면에서는 사용하지 않고, 다른 곳에서 쓰일 예정.
            if (!drivingTypeData.length) {
                await store.dispatch(getDrivingTypeRedux(
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                ));
            }

            let data = await ReserveApi.GetDrivingReservationList(
                CustomerNo,
                1, 10, 10,
                cookies.get('ostype'),
                cookies.get('deviceid'),
                cookies.get('login_encrypt')
            )
            if (data && data.Result === 'success') {
                this.setState({
                    visibleReserve: data.ItemList.length ? true : false,
                    visibleLive: data.LiveList.length ? true : false
                })
            }

            // 월간모시러 고객일때 항상 fcm token을 업데이트하여 
            // 이용자를 특정할 수 있도록 한다.
            if (isChkMonthlyMosilerCustomer(CustomerCategory)) {
                let tmpAvaliablePercent = await MonthlyMosilerApi.GetMmAvaliablePercent(
                    CustomerNo,
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                )
                
                if (tmpAvaliablePercent && tmpAvaliablePercent.Result === 'success') {
                    this.setState({
                        UseTime:tmpAvaliablePercent.Item.UseTime, 
                        RemainTime:tmpAvaliablePercent.Item.RemainTime, 
                        AvaliableTime:tmpAvaliablePercent.Item.AvaliableTime, 
                        UsePercent:tmpAvaliablePercent.Item.UsePercent,
                    })
                    // 0으로 딱 떨어진다면 "약"을 붙이지 않는다.
                    if (tmpAvaliablePercent.Item.AvaliableTime % 60 === 0) {
                        document.getElementsByClassName('react-sweet-progress-symbol')[0].textContent = tmpAvaliablePercent.Item.AvaliableTime/60 + '\n시간'
                    }
                    else {
                        document.getElementsByClassName('react-sweet-progress-symbol')[0].textContent = '약' + Math.floor(tmpAvaliablePercent.Item.AvaliableTime/60)+'\n시간'
                    }
                    
                }
                else {
                    await calertPromise(tmpAvaliablePercent.Message)
                }
            
            }

            // FCM Token, deviceid를 항상 업데이트
            let deviceinfoMain = await RnInterface.getData('deviceinfo')
            if (deviceinfoMain === false) {
                deviceinfoMain = ['A', '1']
            } 
            else {
                deviceinfoMain = deviceinfoMain.split('@@')
            }
                            
            await AccountApi.setFcmToken(CustomerNo, fcmtoken, deviceinfoMain[0], deviceinfoMain[1], cookies.get('login_encrypt'))
            
            const expires = new Date()
            expires.setDate(expires.getDate() + MAX_COOKIE_DATE)
        
            cookies.set('ostype',   deviceinfoMain[0],  { path: '/', expires });
            cookies.set('deviceid', deviceinfoMain[1],  { path: '/', expires });

            
            // 예약 화면을 나가고 메인화면일때 주소, 시간 내용을 지워야함.
            store.dispatch(init_location_selector_service());       // 메인 화면 > 일반/골프 예약 > 출발지 종료지 경유지 선택 관련 store
            store.dispatch(init_time_all_selector_service());       // 메인 화면 > 일반/골프 예약 > 시간 선택 store
            store.dispatch(empty_use_state_service());              // 메인 화면 > 메뉴 > 예약현황 store
            store.dispatch(empty_use_history_service());            // 메인 화면 > 메뉴 > 이용기록 store

            // 예약 화면을 나가고 메인화면일때 아래 항목을 지워야함.
            localStorage.removeItem('reserve_temp_date')
            localStorage.removeItem('reserve_temp_startTime')
            localStorage.removeItem('reserve_temp_endTime')
            localStorage.removeItem('reserve_temp_carLocation')
            localStorage.removeItem('reserve_temp_comment')
            localStorage.removeItem('reserve_temp_passengerphone')
            localStorage.removeItem('reserve_temp_passengername')
            localStorage.removeItem('reserve_temp_type')
            localStorage.removeItem('reserve_temp_ReseveCharge')
            localStorage.removeItem('reserve_temp_carNo')
            localStorage.removeItem('reserve_temp_carModel')
            localStorage.removeItem('reserve_temp_selectHour')
            localStorage.removeItem('reserve_temp_covidKit')
            localStorage.removeItem('reserve_view_all_state')
            localStorage.removeItem('reserve_step2_backup')
            localStorage.removeItem('reserve_step2_backup2')
            
            
            
            

            // 예) 모바일에서 모시러 홈페이지 접속 > 메인화면에서 "골프예약하러가기" 클릭 > 딥링크 실행 > 앱 실행 > http://{URL}/linktoappview=reserve/3 페이지로 이동 > 
            // LoginCheck후 linktoappview라는 qs가 존재하면 우선 /main들렸다가 /reserve/3으로 이동해야하는데 ("페이지 이동시 main을 거쳐야 하는 이유" 를 참고.)
            // 이때, /main에서는 공지사항, 앱 업데이트 alert가 뜬다. 이부분을 alert안뜨게 처리해야한다.
            // 안그러면 reserve/3으로 이동했을때 main화면의 await alert에 의해 reserve/3 동작이 되지 않기 때문.. 
            // reserve/3 페이지가 뜰때 골프 얼리버드 alert가 떠야 하는데 그걸 씹어버리고, main의 공지 alert가 표시되어버린다.
            // reserve/3 입장에서는 는 골프 얼리버드 alert를 닫기전까지 무한로딩 상태가 된다.
            // 그걸 치고 main의 공지 alert가 표시되어버린다.
            
            // * 페이지 이동시 main을 거쳐야 하는 이유
            // 이렇게 처리하는 이유는 history를 남기기 위해서이다. 만약, main화면으로 안가고 바로 reserve/3으로 갈경우, 뒤로가기 눌렀을때 어디로 가야할지 알 수 없게 된다.
            // 따라서 어디 이동할때는 main을 거쳐서 이동하는 처리가 반드시 필요하다.

            let deeplinkProcessFlag = 0;
            try {
                // const {linktoappviewflag} = this.props.location.state
                deeplinkProcessFlag = 1;
            }
            catch(e){   }
            
            if (deeplinkProcessFlag) {
                // 공지사항 팝업
                /*
                1. 확인버튼 누르면 세션이 유지될때까지는 다시 표시되면 안됨
                2. 다시 보지 않기를 누르면 localStorage에 현재 noticeno값을 저장한다.
                */
                let noticePopupLSFlag = 
                    localStorage.getItem('mosiler_mainmenu_noticepopup_noticeno') !== null ? 
                    Number(localStorage.getItem('mosiler_mainmenu_noticepopup_noticeno')) : -1;

                let noticePopupSSFlag = 
                    sessionStorage.getItem('mosiler_mainmenu_noticepopup_noticeno') !== null ? 
                    Number(sessionStorage.getItem('mosiler_mainmenu_noticepopup_noticeno')) : -1;
                
                let noticeResult = await NoticeApi.CustomerNotice(
                    getCustomerCategoryViewTarget(CustomerCategory),
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                )
                
                if (noticeResult && noticeResult.Result === 'success') {
                    // 다시 보지 않기를 눌렀을 때에는 다시 표시되어선 안된다.
                    if (noticePopupLSFlag === noticeResult.Item.NotiNo) { }
                    // 최초 앱 실행시 공지 팝업 떴을때 확인 or 배경을 클릭한 이후일때에도 표시되어선 안된다.
                    else if (noticePopupSSFlag !== -1) { }
                    else {
                        // 다시 보지 않기 클릭시 false, 확인 및 배경 클릭시 true
                        let noticeAlertResult = await calertNoticePopup2(noticeResult.Item.Contents, noticeResult.Item.Link, `/notice_link?link=${noticeResult.Item.Link}&viewstate=${NOTICE_LINK_STATE_MAIN_MENU}`, noticeResult.Item.NotiNo);
                        if (noticeAlertResult === NOTICE_ALERT_RESULT_CODE.CONFIRM) {
                            if (noticeResult.Item.Link !== null) {
                                this.props.history.push(`/notice_link?link=${noticeResult.Item.Link}&viewstate=${NOTICE_LINK_STATE_MAIN_MENU}`)
                            }
                            else {
                            sessionStorage.setItem('mosiler_mainmenu_noticepopup_noticeno',noticeResult.Item.NotiNo )
                        } 
                        }
                        else if (noticeAlertResult === NOTICE_ALERT_RESULT_CODE.DONTVIEW) {
                            localStorage.setItem('mosiler_mainmenu_noticepopup_noticeno',noticeResult.Item.NotiNo )
                        }
                        else {
                            sessionStorage.setItem('mosiler_mainmenu_noticepopup_noticeno',noticeResult.Item.NotiNo )
                        }
                    }               
                } 
                // 공지사항이 없거나 그 외의 문제
                else {}
                // 기기에서 디바이스 버전을 취득한다.
                let deviceVer = await RnInterface.getData('getDeviceVersion');

                // localStorage에 기기 디바이스 버전을 저장한다.
                localStorage.setItem('device_app_version',deviceVer)
                
                // 서버에서 기기버전이 최신버전인지 여부를 확인한다.
                let tmpVersionCheck = await AppSettingApi.GetVersionCheck(
                    (deviceVer === false ? '0.0.0' : deviceVer),
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                )
                
                let applatestVerFlag = 
                    sessionStorage.getItem('appLatestVersionFlag') !== null ? 
                        Number(sessionStorage.getItem('appLatestVersionFlag')) : -1;

                // tmpVersionCheck = {
                //     Item : {
                //         Version:'1.3.10'
                //     }
                // }
                // deviceVer = '1.3.00'
                
                // 서버 버전이 높다면 업데이트가 필요하다.
                if ( tmpVersionCheck.Item !== undefined && (this.chkVersion(deviceVer, tmpVersionCheck.Item.Version) === SERVER_VER_HIGH)  ) {
                    //sessionStorage가 존재하지 않다면
                    if (applatestVerFlag === -1) {
                        // alert 표시
                        if (await calertConfirm(`현재 앱 최신 버전은 ${tmpVersionCheck.Item.Version} 입니다.\n앱을 업데이트하면\n원활한 이용이 가능합니다.\n앱 업데이트를 진행하겠습니까?\n(업데이트가 안될경우 앱 제거후 다시 설치해주세요.)`)) {
                            window.open(tmpVersionCheck.Item.StoreUrl);
                        }
                        // 앱이 꺼질때까지 alert가 표시되지 않는다.
                        sessionStorage.setItem('appLatestVersionFlag', '1');
                    }
                    
                }
            }           

            this.setState({ loading: 1 })
        } catch (e) {
            this.setState({ loading: 1 })
        }


    }

    handleCallMenu = () => {
        // this.props.history.replace('/myinfo')
        document.getElementById('slide_trigger').style.marginLeft = '0vw';

    }

    handleCloseMenu = (e) => {
        e.preventDefault();
        document.getElementById('slide_trigger').style.marginLeft = '-100vw';
    }

    handleClose = (e) => {
        e.preventDefault();
        this.props.history.replace('/main');
    }

    handleGotoMemberInfoChange = (e) => {
        e.preventDefault();
        this.props.history.push('/changeinfo');
    }

    handleGotoReserve = (e) => {
        e.preventDefault();
        this.props.history.push('/reserve');
    }
    
    handleGotoUseState = (e) => {
        e.preventDefault();
        this.props.history.replace('/use_state');
    }
    
    handleGotoUseHistory = (e) => {
        e.preventDefault();
        this.props.history.replace('/use_history');
    }

    handleGotoCard = (e) => {
        e.preventDefault();
        this.props.history.push('/card');
    }

    handleGotoNotice = (e) => {
        e.preventDefault();
        this.props.history.replace('/notice');
    }
    
    handleGotoSetting = (e) => {
        e.preventDefault();
        this.props.history.replace('/setting');
    }

    handleGotoCoupon = (e) => {
        e.preventDefault();
        // calert('대응중')
        this.props.history.replace('/coupon');
    }

    handleGotoTicket = (e) => {
        e.preventDefault();
        // calert('대응중')
        this.props.history.replace('/ticket');
    
    }
    
    handleGotoFriendShare = (e) => {
        e.preventDefault();
        // calert('대응중')
        this.props.history.replace('/invite');
    }

    handleCallCenter = async (e) => {
        e.preventDefault();
        await RnInterface.callReactNative('callTelephone15224556')
    }

    handleGotoHome = () => { this.props.history.replace('/main') }

    getPercentStatusColor = (flag) => { 
        const {UsePercent} = this.state;
        let retData = '#fff'
        if (flag === 'b') {
            if (UsePercent >= 0 && UsePercent <= 70) {
                retData = '#f1f1fa'
            }
            else if (UsePercent >= 71 && UsePercent <= 90) { 
                retData = '#f1f1fa'
            }
            else {
                retData = '#f1f1fa'  
            }
        }
        else {
            if (UsePercent >= 0 && UsePercent <= 70) {
                retData = '#706ed0'
            }
            else if (UsePercent >= 71 && UsePercent <= 90) { 
                retData = '#706ed0'
            }
            else {
                retData = '#706ed0'
            }
        }
        return retData;

        
    }

    MmStatusComponent = () => {
        return (
            <NavLink to='report_monthly' className="box booking">
                <div className='monthly-container'>
                    <div className='sub-monthly2' style={{ padding: '15px 20px' }}>
                        <div style={{ padding: '15px 15px' }}>
                            <div style={{ fontWeight: 'bold', paddingBottom: '15px', }}>
                                {this.state.name}님의 이용현황
                                <span style={{ float: 'right' }}>
                                    상세보기 {'>'}
                                </span>
                            </div>

                            <div style={{ fontWeight: 'bold', paddingBottom: '2px' }}>
                                <Progress
                                    percent={this.state.UsePercent >= 101 ? 100 : this.state.UsePercent}
                                    status="active"
                                    theme={{
                                        active: {
                                            trailColor: this.getPercentStatusColor('b'),  // 배경
                                            color: this.getPercentStatusColor('c')        // 컬러
                                        },
                                    }}
                                />
                            </div>

                            {this.state.RemainTime % 60 === 0 ?
                                <div style={{ fontWeight: 'bold', fontSize: '1em', textAlign: 'right' }}> 잔여 {this.state.RemainTime / 60}시간</div> :
                                <div style={{ fontWeight: 'bold', fontSize: '1em', textAlign: 'right' }}> 잔여 약 {Math.floor(this.state.RemainTime / 60)}시간</div>
                            }

                            {/* <div style={{ fontWeight: 'bold', fontSize: '0.9em', textAlign:'center' }}>자세히보기 </div> */}
                        </div>
                    </div>
                </div>
            </NavLink>
        )
    }

    MmStatusComponent2 = () => {
        return (
            <NavLink to='report_monthly' className="box booking" style={{marginTop:'10px'}}>
                <div className='monthly-container'>
                    <div className='sub-monthly3-2'>
                        <div className='item1'>
                            <div className='UseTime'>{Math.floor(this.state.UseTime/60)}시간 사용</div>
                            <Progress
                                percent={this.state.UsePercent >= 101 ? 100 : this.state.UsePercent}
                                // percent={50}
                                status="active"
                                theme={{
                                    active: {
                                        trailColor: this.getPercentStatusColor('b'),  // 배경
                                        color: this.getPercentStatusColor('c')        // 컬러
                                    },
                                }}
                            />
                        </div>
                        <div className='item2'>
                            <span className='Avaliable'>{Math.floor(this.state.UseTime/60)}</span>&nbsp; / &nbsp;
                            <span className='RemainTime'>{this.state.AvaliableTime/60}시간</span>
                        </div>
                    </div>
                    <div className='sub-monthly3-3'>
                        <div className='item1'>
                            상세 보기&nbsp;&nbsp;<img src={arrow_detail} />
                        </div>
                    </div>
                </div>
            </NavLink>
        )
    }


    render() {
        // let { CustomerNo } = store.getState().global_data.loginedData
        return (<>
            <MainMenuMyInfoCss />
            <div id="slide_trigger" className="slide_menu_container">
                <div className="menu_container" >
                    <div className="menu_containter_header">
                        <span className="btn_setup" onClick={this.handleCloseMenu}>
                            <NavLink to="#" className="btn_link">
                                <img src={btn_cancel} alt="" /></NavLink>
                        </span>
                        <span className="member_info">
                            <span className="name">
                                {this.state.name}님
                                </span>
                            <span className="tel">
                                {Util.getPhoneNumToHypen(this.state.phone)}
                            </span>
                        </span>
                        <span className="mb_modify">
                            <NavLink to="#" onClick={this.handleGotoMemberInfoChange} >
                                회원정보변경
                                    <img src={btn_arrow2} alt="" />
                            </NavLink>
                        </span>
                    </div>
                    <div className="menu_seperator">

                    </div>
                    <div className="menu_containter_menu">
                        {!isChkMonthlyMosilerCustomer(this.state.CustomerCategory) ?
                            <NavLink to="#" className="sl_menu" onClick={async (e) => {
                                e.preventDefault();
                                let tokenData = getUserInfoEncrypt({
                                    UserName: this.state.name,
                                    UserPhone: this.state.phone,
                                    UserEmail: store.getState().global_data.loginedData.Email,                                        
                                })
                                let linkAddress = 'http://mosiler.com/monthly/introduce?inflow=app_side&token=' + tokenData
                                let result = await RnInterface.callReactNative(`NewIntentLinkBrowsing${linkAddress}`)
                                // PC 환경일때는
                                if (!result) {
                                    window.open(linkAddress, '_blank')
                                }
                            }}>
                                <img src={icon_m15} className="m_icon" alt="" /> 구독형상품
                                <img src={btn_arrow} className="btn_arrow" alt="" />
                            </NavLink> : null}

                        <NavLink to="/use_state" className="sl_menu">
                            <img src={icon_m1} className="m_icon" alt="" /> 예약현황               
                            <img src={btn_arrow} className="btn_arrow" alt="" />
                        </NavLink>
                        <NavLink to="/use_history" className="sl_menu">
                            <img src={icon_m2} className="m_icon" alt="" /> 이용기록               
                            <img src={btn_arrow} className="btn_arrow" alt="" />
                        </NavLink>
                        <NavLink to="/card" className="sl_menu">
                            <img src={icon_m3} className="m_icon" alt="" /> 카드관리               
                            <img src={btn_arrow} className="btn_arrow" alt="" />
                        </NavLink>
                        <NavLink to="/coupon" className="sl_menu">
                            <img src={icon_m4} className="m_icon" alt="" /> 쿠폰관리               
                            <img src={btn_arrow} className="btn_arrow" alt="" />
                        </NavLink>

                        {/* 월간모시러 고객은 비노출. */}
                        {/* {(Number(this.state.CustomerCategory) === 3 || Number(this.state.CustomerCategory) === 4) ? null : (
                            <NavLink to="/ticket" className="sl_menu">
                                <img src={icon_m5} className="m_icon" alt="" /> 충전권구매               
                                <img src={btn_arrow} className="btn_arrow" alt="" />
                            </NavLink>
                        )} */}
                        
                        {/* 친구 코드 등록 기능은 2019년 일시적인 이벤트였던 모양. 애덤이 지우라해서 지움.
                        <NavLink to="/invite" className="sl_menu">
                            <img src={icon_m6} className="m_icon" alt="" /> 친구초대               
                            <img src={btn_arrow} className="btn_arrow" alt="" />
                        </NavLink> */}
                        <NavLink to="/voc" className="sl_menu">
                            <img src={icon_m7} className="m_icon" alt="" /> 고객의소리               
                            <img src={btn_arrow} className="btn_arrow" alt="" />
                        </NavLink>
                        <NavLink to="/notice" className="sl_menu">
                            <img src={icon_m8} className="m_icon" alt="" /> 공지사항               
                            <img src={btn_arrow} className="btn_arrow" alt="" />
                        </NavLink>                        
                        { (Number(this.state.CustomerCategory) === 3 || Number(this.state.CustomerCategory) === 4) ? (
                            <>
                                <NavLink to="/info_monthly" className="sl_menu">
                                    <img src={icon_m12} className="m_icon" alt="" /> 월간모시러 가입정보               
                                    <img src={btn_arrow} className="btn_arrow" alt="" />
                                </NavLink>
                                <NavLink to="/report_monthly" className="sl_menu">
                                    <img src={icon_m13} className="m_icon" alt="" /> 월간모시러 리포트
                                    <img src={btn_arrow} className="btn_arrow" alt="" />
                                </NavLink>
                                <NavLink to="/policy_price_monthly?state=1" className="sl_menu">
                                    <img src={icon_m5} className="m_icon" alt="" /> 이용안내
                                    <img src={btn_arrow} className="btn_arrow" alt="" />
                                </NavLink>
                            </>
                        ) : (
                            <NavLink to="/policy_price?state=1" className="sl_menu">
                                <img src={icon_m5} className="m_icon" alt="" /> 요금안내
                                <img src={btn_arrow} className="btn_arrow" alt="" />
                            </NavLink>
                        )}
                        
                        <NavLink to="/setting" className="sl_menu">
                            <img src={icon_m9} className="m_icon" alt="" /> 설정                   
                            <img src={btn_arrow} className="btn_arrow" alt="" />
                        </NavLink>
                    </div>
                    
                    {/* 요청에 의해 주석처리했다. 차후에 부활할 가능성이 있기 때문에 주석처리 해놓는다.
                    <div className="menu_containter_footer">
                        <span className="cs_box"><span className="title">고객센터</span><span className="tel"><Link to='#' onClick={this.handleCallCenter}>1522-4556</Link></span></span>
                        <span className="cs_box"><span className="title">운영시간</span><span className="time">평일 10:00~18:00(주말, 공휴일 제외)</span></span>
                    </div> */}

                </div>
            </div>

            <HeaderMain
                title="모시러예약"
                onBackButton={this.handleCallMenu}
                onHomeButton={this.handleGotoHome}
            />
            {!this.state.loading ? <Loading /> : ''}

            {/* 
                <NavLink to="/common_link?redirect=http://mosiler.com/genesis/index_app.html&title=쇼퍼드리븐 골프" className="box booking">
                    <div className='monthly-container'>
                        <img src={genesis_intro_image} style={{width:'100%'}}/>
                        <div className='sub-04'>
                            <div style={{fontWeight:'bold',paddingBottom:'5px', textAlign:'center', fontSize:'1.4em'}}><span style={{color:'#e2cb24'}}>MOSILER X GENESIS</span></div>
                            <div style={{fontWeight:'bold', textAlign:'center',fontSize:'0.9em'}}>제네시스 쇼퍼드리븐 행사</div>
                        </div>
                    </div>
                </NavLink>  */}

            <main id="Contents" className="">
                <div className="booking_box">
                    <ul>
                        <li>
                            <section className='new-menu top-main'>
                                <div className='sub-title'>MOSILER</div>
                                <div className='sub-title'>고객의 마음까지 운전합니다.</div>
                            </section>

                            {/* Live먼저 체크 후 true면 라이브 표시 */}
                            {this.state.visibleLive ? (
                                <NavLink to="/realtime_drive" className="box booking" style={{marginBottom:'15px'}}>
                                    <span class="bk_head">
                                        <span class="bk_name">실시간 위치확인</span>
                                        <span class="btn_style2"><img src={icon_map_car} alt="" /></span>
                                    </span>
                                    {/* <span class="bk_bg2 bg_img0"></span> */}
                                </NavLink>
                            ) : (
                                <>
                                    {this.state.visibleReserve ? (
                                        <>
                                        {isChkMonthlyMosilerCustomer(this.state.CustomerCategory) ? <></> : 
                                            <NavLink to="/use_state" className="box booking" style={{marginBottom:'15px'}}>
                                                <div className="box-inner">
                                                    <div className="grid cross-center">
                                                        <div className="col col-8">
                                                            <h2 className="text-larger text-bold">예약 정보 확인</h2>
                                                            <p className="text-medium">예악 현황을 확인하세요</p>
                                                        </div>
                                                        <div className="col right">
                                                            <button type="button" className="btn medium primary text-bold"><span>예약확인</span></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        }
                                        </>
                                        
                                    ) : null}
                                </>
                            )}

                            {isChkMonthlyMosilerCustomer(this.state.CustomerCategory) ? (
                                <>
                                    <section className='new-menu'>
                                        <div className='sub-title sub-header-02'>
                                            <img src={icon_monthly_header_01} />
                                            이용현황
                                        </div>
                                        {this.MmStatusComponent2()}
                                    </section>

                                    <section className='new-menu'>
                                        <div className='sub-title sub-header-02'>
                                            <img src={icon_monthly_header_02} />
                                            서비스 이용
                                        </div>
                                        <div className="box booking4" style={{ marginTop: '10px' }}>
                                            <div className='item' style={{ background: '#fff' }}>
                                                <div className='sub-menu-container' onClick={() => {
                                                    this.props.history.push('/reserve_monthlyonce/1/1')
                                                }}>
                                                    <div className='item-sub1'><img src={icon_monthly_reserve} alt='' /></div>
                                                    <div className='item-sub2'>예약하기</div>
                                                </div>
                                            </div>
                                            <div className='item'>
                                                <div className='sub-menu-container'>
                                                    <div className='item2' onClick={() => {
                                                        this.props.history.push('/use_state')
                                                    }}>
                                                        <span className='sub-item'>예약 내역</span>
                                                        <img src={icon_monthly_reservelist} />
                                                    </div>
                                                    <div className='item2' onClick={() => {
                                                        this.props.history.push('/common_link?redirect=http://mosiler.com/mm_service/index_app.html&title=월간모시러 서비스 이용 안내')
                                                    }}>
                                                        <span className='sub-item'>이용 메뉴얼</span>
                                                        <img src={icon_monthly_manual} />
                                                    </div>
                                                    {/* <div className='item2' onClick={ async () => {
                                                        await calertPromise('?')
                                                    }}>
                                                        <span className='sub-item'>FAQ</span>
                                                        <img src={icon_monthly_faq} />
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </section>


                                    
                                </>


                            ) : <>
                                <section className='new-menu'>
                                    <div className='sub-title sub-header-02'>
                                        <img src={icon_general_header_01} />
                                        예약하기
                                    </div>
                                    <div className="box booking3">
                                        <div className='item' style={{ background: '#fff' }} onClick={()=>{
                                            this.props.history.push('/reserve_renew/1/1')
                                        }}>
                                            <div className='sub-menu-container'>
                                                <div className='item-sub1'><img src={icon_general_reserve} alt='' /></div>
                                                <div className='item-sub2'>일반</div>
                                            </div>
                                        </div>
                                        <div className='item' style={{ background: '#fff' }} onClick={()=>{
                                            this.props.history.push('/reserve_renew/3/1')
                                        }}>
                                            <div className='sub-menu-container'>
                                                <div className='item-sub1'><img src={icon_general_golfreserve} alt='' /></div>
                                                <div className='item-sub2'>골프</div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section className='new-menu'>
                                    <div className='sub-title sub-header-02'>
                                        <img src={icon_general_header_02} />
                                        구독형 상품 알아보기
                                    </div>
                                    <NavLink to="#" className="box booking" onClick={async (e)=>{
                                        e.preventDefault();
                                        // this.props.history.push("/common_link?redirect=https://www.hansung.co.kr/passengercars/startpage.html&title=한성자동차")
                                        let tokenData = getUserInfoEncrypt({
                                            UserName: this.state.name,
                                            UserPhone: this.state.phone,
                                            UserEmail: store.getState().global_data.loginedData.Email,                                        
                                        })
                                        let linkAddress = 'http://mosiler.com/monthly/introduce?inflow=app_monthly&token=' + tokenData
                                        let result = await RnInterface.callReactNative(`NewIntentLinkBrowsing${linkAddress}`)
                                        // PC 환경일때는
                                        if (!result) {
                                            window.open(linkAddress, '_blank')
                                        }
                                    }}>
                                        <div className="box-inner">
                                            <div className="grid cross-center">
                                                <div className="col col-8">
                                                    <h2 className="text-larger text-bold">월간모시러</h2>
                                                    <p className="text-medium">월 단위 정기 구독 서비스</p>
                                                </div>
                                                <div className="col right">
                                                    <img src={icon_general_mm} alt='' style={{ width: '40px' }} />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="box-img"><img src={visual_golf} alt="" /></div> */}
                                    </NavLink>
                                    <NavLink to="#" className="box booking" onClick={async (e)=>{
                                        e.preventDefault();
                                        let tokenData = getUserInfoEncrypt({
                                            UserName: this.state.name,
                                            UserPhone: this.state.phone,
                                            UserEmail: store.getState().global_data.loginedData.Email,                                        
                                        })
                                        let linkAddress = 'http://mosiler.com/annual/introduce?inflow=app_annual&token=' + tokenData
                                        let result = await RnInterface.callReactNative(`NewIntentLinkBrowsing${linkAddress}`)
                                        // PC 환경일때는
                                        if (!result) {
                                            window.open(linkAddress, '_blank')
                                        }
                                    }}>
                                        <div className="box-inner">
                                            <div className="grid cross-center">
                                                <div className="col col-8">
                                                    <h2 className="text-larger text-bold">연간모시러</h2>
                                                    <p className="text-medium">1년 단위 정액권 서비스</p>
                                                </div>
                                                <div className="col right">
                                                    <img src={icon_general_yearmm} alt='' style={{ width: '40px' }} />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="box-img"><img src={visual_golf} alt="" /></div> */}
                                    </NavLink>
                                </section>
                                {/* <div className="margintopbottom">
                                    &nbsp;
                                </div> */}
                                
                            </>}
                            
                            {/* 협의 완료될때까지 보류 */}
                            {false ? (
                                <div className="box booking2 slide-component">
                                    <Slide easing="cubic" arrows={false} indicators={true}>
                                        <div
                                            onClick={async (e) => {
                                                e.preventDefault();
                                                // this.props.history.push("/common_link?redirect=https://www.hansung.co.kr/passengercars/startpage.html&title=한성자동차")
                                                let linkAddress = 'https://www.hansung.co.kr/passengercars/startpage.html'
                                                let result = await RnInterface.callReactNative(`NewIntentLinkBrowsing${linkAddress}`)
                                                // PC 환경일때는
                                                if (!result) {
                                                    window.open(linkAddress, '_blank')
                                                }
                                            }}>
                                            <img src={banner_hansung} style={{ width: '100%' }} alt="" />
                                        </div>
                                        <div
                                            onClick={async (e) => {
                                                e.preventDefault();
                                                // this.props.history.push("/common_link?redirect=https://www.hiltongrandvacations.co.kr&title=힐튼 베케이션")
                                                let linkAddress = 'https://www.hiltongrandvacations.co.kr/'
                                                let result = await RnInterface.callReactNative(`NewIntentLinkBrowsing${linkAddress}`)
                                                // PC 환경일때는
                                                if (!result) {
                                                    window.open(linkAddress, '_blank')
                                                }
                                            }}>
                                            <img src={banner_hilton} style={{ width: '100%' }} alt="" />
                                        </div>
                                    </Slide>
                                </div>
                            ) : (
                                <></>
                            )}
                            

                        </li>
                    </ul>
                </div>
            </main>
        </>
        );
    }
};

export default withCookies(ReserveView);
