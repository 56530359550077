import React, { Component } from 'react';
import AddressApi from '../../lib/AddressApi';
// import { createGlobalStyle } from "styled-components";
import Util, { 
    calertPromise, 
    calertConfirm,
    getAddressFavoriteDataLS,
    setAddressFavoriteDataLS,
    getRandomKey
} from '../../lib/Util';
import RnInterface from '../../lib/RnInterface'
import store from '../../reduxStore';
import ReserveApi from '../../lib/ReserveApi'
import ScrollTopBtn from '../../components/Common/ScrollTopBtn'

import axios from 'axios'
import querystring from 'qs'

import {
    start_location_service,
    end_location_service,
    step_over_add_service,
    step_over_update_service,
    all_location_save
} from '../../modules/Reserve/LocationSelector_ReduxModule'

import {
    address_pick_service
} from '../../modules/Global/Address_ReduxModule'

import {
    ADDRESS_SEARCH_STATE_RESERVE_START,             // 예약 > 출발지
    ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_ADD,      // 예약 > 경유지 (추가)
    ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_UPDATE,   // 예약 > 경유지 (갱신)
    ADDRESS_SEARCH_STATE_RESERVE_END,               // 예약 > 종료지
    ADDRESS_SEARCH_STATE_MYINFO,                    // 내 정보 
    ADDRESS_SEARCH_STATE_JOIN,                      // 회원가입시

    STATE_FAVORITE_HOME_ADD,
    STATE_FAVORITE_HOME_MODIFY,
    STATE_FAVORITE_COMPANY_ADD,
    STATE_FAVORITE_COMPANY_MODIFY,


    KAKAO_PLACE_SEARCH_URL,
    KAKAO_PLACE_SEARCH_KEY,
    GOOGLE_PLACE_KEY,
    GOOGLE_PLACE_URL,
    PARTNER_INTERFACE_URL,

    BUTLER_SERVICE_LIST

} from '../../constantData';

// import Header from '../components/Common/Header'
// import marker from '../img/booking/icon_navi.svg';

import '../../css/default.css';
import '../../css/booking.css';

import btn_cancel from '../../img/btn/btn_cancel.svg'
import icon_search from '../../img/booking/icon_search.svg'
import icon_search_cancel from '../../img/booking/icon_search_cancel.svg'
import moment from 'moment';
import LoadingInner from '../../components/Common/LoadingInner'


const PLACE_FLAG = 0;                   // 장소별
const NEW_OLD_ADDRESS_FLAG = 1;         // 주소(신주소, 구주소)별

const MENU_BTN_LATEST_SEARCH = '0';       // 최근장소 클릭시
const MENU_BTN_LATEST_LOCATION = '1';     // 최근경로 클릭시

const DEFAULT_CENTER_COORDS = {
    latitude: 37.3595669,
    longitude: 127.1054065,
};

const NO_ROAD_ADDRESS_STR = '(도로명없음)';
const NO_JIBUN_ADDRESS_STR = '(지번없음)';

class ButlerServiceSearch extends Component {

    state = {
        redirectUri: '',
        state: '',
        addressName: '',
        addressList: [],
        historyAddressList: [],         // 최근 장소 리스트(LocalStorage)
        historyLocationList: [],         // 최근 경로 리스트(LocalStorage)
        addressType : PLACE_FLAG,
        menuBtnState: MENU_BTN_LATEST_SEARCH,
        addressListLoading: 1,
        menuIdFlag : 0,                 // menuIdFlag가 0일때는 최근장소, 최근경로, 주소창 전부 표시, 1일때는 최근경로만 표시
        
    }

    typingTimer = null;
    doneTypingInterval = 500;

    

    
    setStateAsyncAddressName = (_addressName) => {
        return new Promise((resolve) => {
            this.setState({ addressName: _addressName }, () => {
                resolve(true)
            })
        })
    }

    setStateAsyncAddressList = (_addressList) => {
        return new Promise((resolve) => {
            this.setState({ addressList: _addressList }, () => {
                resolve(true)
            })
        })
    }

    setStateAddressListLoading = () => {
        return new Promise((resolve) => {
            this.setState({ addressListLoading: 0 }, () => {
                resolve(true)
            })
        })
    }
    setStateAddressListLoadingEnd = () => {
        return new Promise((resolve) => {
            this.setState({ addressListLoading: 1 }, () => {
                resolve(true)
            })
        })
    }

    getHeaderTitleAddressSearch = () => {
        let data = Util.getQueryStringObject();
        let retData = '';
        try {
            if (data.state === ADDRESS_SEARCH_STATE_RESERVE_START) {
                retData = '출발지입력';
            }
            else if ( (data.state.indexOf(ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_ADD) > -1) || (data.state.indexOf(ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_UPDATE) > -1)) {
                retData = '경유지입력';
            }
            else if (data.state === ADDRESS_SEARCH_STATE_RESERVE_END) {
                retData = '종료지입력';
            }
            else {
                retData = '주소입력';
            }
        }
        catch(e) {
            console.log(e)
            retData = '';
        }
        return retData;
    }


    // 검색 개선버전.
    // 검색시 타이머 적용. 콜수 최소화
    asyncAddress2 = async (_addressName) => {
        await this.setStateAsyncAddressName(_addressName)
        // await this.setStateAddressListLoading()
        // if (!this.state.addressName.length) {
        //     await this.setStateAsyncAddressList([])
        // }

        // // 변경이 있을때마다 기존의 타임아웃을 지워버리고, 아래에서 다시 타임아웃을 등록하고,
        // // 입력이 없을때 timeout이 발생한 다음 timeout종료.
        // clearTimeout(this.typingTimer)

        // if (this.state.addressName) {
            
        //     // addressName이 존재할때 timeout을 건다.
        //     this.typingTimer = setTimeout(async()=>{
        //         // 장소별은 kakao api 사용
        //         if (this.state.addressType === PLACE_FLAG) {
        //             let adr_result = await AddressApi.requestPlace(_addressName)
                    
        //             // 장소 검색 결과 0이라면
        //             if (adr_result.meta.total_count === 0) {
        //                 // 주소로 검색
        //                 adr_result = await AddressApi.requestAddress(_addressName)
        //             }
                    
        //             await this.setStateAsyncAddressList(adr_result.documents);
        //             await this.setStateAddressListLoadingEnd()
        //         }
        //     }, this.doneTypingInterval)

        // }
    }

    asyncAddress = async (_addressName) => {
        await this.setStateAsyncAddressName(_addressName)
        

        // 장소별은 kakao api 사용
        if (this.state.addressType === PLACE_FLAG) {
            let adr_result = await AddressApi.requestPlace(_addressName)
            
            // 장소 검색 결과 0이라면
            if (adr_result.meta.total_count === 0) {
                // 주소로 검색
                adr_result = await AddressApi.requestAddress(_addressName)
            }
            
            await this.setStateAsyncAddressList(adr_result.documents);
        }
        
    }

    handleChangeAddress = (e) => {
        this.asyncAddress(e.target.value);
    }

    handleChangeAddress2 = (e) => {
        this.asyncAddress2(e.target.value);
    }

    handleDelAddressName = (e) => {
        this.setState({
            addressName: '',
            addressList: [],
        })
    }

    // 검색결과 place_name이 없을때에는 road_address_name 표시. 
    // road_address_name가 없을때에는 address_name 표시
    strongName = (data) => {
        let retData = '';
        try {
            // data.place_name
            if (data.place_name !== undefined) {
                retData = data.place_name
            } else if (data.road_address_name !== undefined) {
                retData = data.road_address_name
            } else {
                retData = data.address_name;
            }
        }
        catch(e){
            console.log(e)
            retData = '';
        }
        return retData;
        
    }

    getRoadAddressStr = (data) => {
        let retData = NO_ROAD_ADDRESS_STR;
        try {
            // data.address_type값이 존재한다면 address.json response.
            if (data.address_type !== undefined) {
                // 해당되면 값 갱신
                if (data.address_type === 'ROAD_ADDR') {
                    retData = data.address_name;
                } else if (data.road_address.address_name !== undefined) {
                    retData = data.road_address.address_name;
                }
                // 해당되지 않으면 기본값으로 리턴
            } 
            // keyword.json response처리를 하고 있는가?
            else {
                // 해당되면 값 갱신
                if (data.road_address_name !== undefined && data.road_address_name !== '') {
                    retData = data.road_address_name;
                }
                // 해당되지 않으면 기본값으로 리턴
            }
        }
        catch(e){
            console.log(e)
            retData = NO_ROAD_ADDRESS_STR;
        }
        return retData;       
    }

    getJibunAddressStr = (data) => {
        let retData = NO_JIBUN_ADDRESS_STR;
        try {
            // address.json response 처리
            if (data.address_type !== undefined) {
                // 해당되면 값 갱신
                if (data.address_type === 'REGION_ADDR') {
                    retData = data.address_name;
                }
                else if (data.address.address_name !== undefined) {
                    retData = data.address.address_name;
                }
                // 해당되지 않으면 기본값으로 리턴
            } 
            // keyword.json response처리
            else {
                // 해당되면 값 갱신
                if (data.address_name !== undefined && data.address_name !== '') {
                    retData = data.address_name;
                }
                // 해당되지 않으면 기본값으로 리턴
            }

            
        }
        catch(e){
            console.log(e)
            retData = NO_JIBUN_ADDRESS_STR;
        }
        return retData;       
    }
    

    setFinishAddressStr = (data) => {
        let retData = '';
        let tmpPlaceName = ( data.place_name !== undefined ) ? data.place_name : ''
        console.log(this.state.addressName)
        // 도로명이 있을 경우
        if (this.getRoadAddressStr(data) !== NO_ROAD_ADDRESS_STR) {
            retData = `${this.getRoadAddressStr(data)} ${tmpPlaceName}`;
        } 
        // 도로명이 없고 지번이 있을 경우
        else if (this.getJibunAddressStr(data) !== NO_JIBUN_ADDRESS_STR) {
            retData = `${this.getJibunAddressStr(data)} ${tmpPlaceName}`;
        } 
        // 도로명도 없고, 지번도 없을 경우
        else {
            retData = `${tmpPlaceName}`;
        }
        
        return retData;
        
    }   


    setFinishAddressStrHistory = (data) => {
        let retData = '';
        let tmpPlaceName = ( data.place_name !== undefined ) ? data.place_name : ''
        // 도로명이 있을 경우
        if (data.new_address !== NO_ROAD_ADDRESS_STR) {
            retData = `${data.new_address} ${tmpPlaceName}`;
        } 
        // 도로명이 없고 지번이 있을 경우
        else if (data.old_address !== NO_JIBUN_ADDRESS_STR) {
            retData = `${data.old_address} ${tmpPlaceName}`;
        } 
        // 도로명도 없고, 지번도 없을 경우
        else {
            retData = `${tmpPlaceName}`;
        }
        
        return retData;
        
    }   

    async componentDidMount() {
        try {
            await RnInterface.AndroidURLChange(this.props.location.pathname)

            let tmpHistoryAddressList = localStorage.getItem('history_address_search');     // 최근 장소 리스트 취득
            let tmpHistoryLocationList = localStorage.getItem('history_location_list');     // 최근 경로 리스트 취득

            let data = Util.getQueryStringObject();

            console.log(this.props.location.search)


            this.setState({
                historyAddressList: (tmpHistoryAddressList !== null) ? JSON.parse(tmpHistoryAddressList) : [],
                historyLocationList : (tmpHistoryLocationList !== null) ? JSON.parse(tmpHistoryLocationList) : [],
                redirectUri: data.redirect,
                state: data.state,
                menuBtnState: data.menuid,
                menuIdFlag: data.menuid,
            })

        } catch (e) {
            console.log(e)
        }

        // console.log(JSON.parse(localStorage.getItem('history_address_search')));
    }

    handleAddressSearchResultClickEvent = async (data) => {
        const { state } = this.state;
        let tmpHistoryAddressList = localStorage.getItem('history_address_search');
        let newData = [];
        let resultData = [];
        let endFlag = 1;

        if (tmpHistoryAddressList === null) {
            tmpHistoryAddressList = []
        } else {
            tmpHistoryAddressList = JSON.parse(tmpHistoryAddressList)
        }
        
        newData = {
            searchKeyword: this.state.addressName,
            new_address: this.getRoadAddressStr(data),
            old_address: this.getJibunAddressStr(data),
            place_name: (data.place_name !== undefined) ? data.place_name : '',                 //장소명 추가
            lat: data.y,
            lng: data.x,
            date: moment().format()
        }
        
        resultData = [newData, ...tmpHistoryAddressList];
        
        if (state === ADDRESS_SEARCH_STATE_RESERVE_START) {
            if (ReserveApi.isChkLimitLocal(this.setFinishAddressStr(data))) {
                store.dispatch(start_location_service({
                    startLocationAddress: this.setFinishAddressStr(data),
                    startLocationLat: parseFloat(data.y),
                    startLocationLng: parseFloat(data.x),
                }))
                endFlag = 1;
            } 
            else {
                await calertPromise('출발지는 서울, 경기, 인천만 가능합니다.')
                endFlag = 0;
            }           
        } 
        else if (state === ADDRESS_SEARCH_STATE_RESERVE_END) {
            store.dispatch(end_location_service({
                endLocationAddress: this.setFinishAddressStr(data),
                endLocationLat: parseFloat(data.y),
                endLocationLng: parseFloat(data.x),
            }))
            endFlag = 1;

        }
        // 경유지 추가
        else if (state.indexOf(ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_ADD) !== -1) {
            store.dispatch(step_over_add_service({
                waypointAddress: this.setFinishAddressStr(data),
                waypointLat: parseFloat(data.y),
                waypointLng: parseFloat(data.x),
            }))
            endFlag = 1;
        }
        // 경유지 변경
        else if (state.indexOf(ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_UPDATE) !== -1) {
            let idxData = parseInt(state.split('@@')[1])
            store.dispatch(step_over_update_service(idxData, {
                waypointAddress: this.setFinishAddressStr(data),
                waypointLat: parseFloat(data.y),
                waypointLng: parseFloat(data.x),
            }))
            endFlag = 1;
        } 
        else if (state === ADDRESS_SEARCH_STATE_JOIN || state === ADDRESS_SEARCH_STATE_MYINFO) {
            store.dispatch(address_pick_service(`${this.setFinishAddressStr(data)}`))
            endFlag = 1;
            // console.log(data);
            // address_pick_service
            // store.dispatch(address_pick_service({
            //     waypointAddress: (data.new_address) ? data.new_address : data.old_address,
            //     waypointLat: data.lat,
            //     waypointLng: data.lng,
            // }))
            
        }

        if (endFlag) {
            // 최근 검색란에 표시하기 위해 localStorage에 set한다.
            localStorage.setItem('history_address_search', JSON.stringify(resultData))
            this.props.history.replace(`${this.state.redirectUri}`);
        }

        
    }

    // 예약화면 > 최근경로 클릭 > 최근장소 탭 클릭시의 동작
    handleLatestBtnProcess = async ()=>{
        let data = Util.getQueryStringObject();
        // 최근경로를 선택한 상태에서 최근장소를 클릭하려할때는 이동불가.
        // (최근경로는 경로만 선택되어야 하고, 최근장소로 이동하려하면 출발지인지 종료지인지 특정할 수 없기 때문. => 차후에 시간이 되면 팝업으로 출발지인지 종료지인지를 선택하는 화면을 띄우는건?) 
        if (data.menuid === MENU_BTN_LATEST_LOCATION) {
            await calertPromise('최근경로만 선택 가능합니다.')
        } else {
            this.setState({menuBtnState: MENU_BTN_LATEST_SEARCH})
        }                                  
    }
    
    // 최근장소/최근경로 전체삭제 기능
    handleDeleteHistory = async () => {
        const { menuBtnState } = this.state
        let favoriteDataLS = getAddressFavoriteDataLS();

        if (menuBtnState === MENU_BTN_LATEST_SEARCH) {
            let result = await calertConfirm('최근장소의 모든 데이터를 삭제하시겠습니까? (추가되어있는 즐겨찾기 목록도 전체삭제됩니다.)')
            if (result) {
                favoriteDataLS.placeList = [];
                this.setState({
                    historyAddressList:[]
                }, ()=>{
                    localStorage.removeItem('history_address_search')
                    setAddressFavoriteDataLS(favoriteDataLS)
                })
                await calertPromise('최근장소의 리스트가 삭제되었습니다.')
            }
        }
        else {
            let result = await calertConfirm('최근경로의 모든 데이터를 삭제하시겠습니까? (추가되어있는 즐겨찾기 목록도 전체삭제됩니다.)')
            if (result) {
                favoriteDataLS.locationList = [];
                this.setState({
                    historyLocationList:[]
                }, ()=>{
                    localStorage.removeItem('history_location_list')
                    setAddressFavoriteDataLS(favoriteDataLS)
                })
                await calertPromise('최근경로의 리스트가 삭제되었습니다.')
            }
        }
    }


    viewDate = (data) => {
        if (data.date === undefined) {
            return '';
        }
        return moment(data.date).format('MM.DD');        
    }

    gotoFavorite = () => {
        this.props.history.push(`/location_favorite${this.props.location.search}`)
    }
    
    render() {
        // 최근 장소 history 리스트
        let tmpLatestHistoryAddressSearchListComponent = (arr) => {
            if (arr.length) {
                return arr.map((data, idx) => {
                    let favoriteFlag = data.favoriteId === undefined ? false : true;
                    
                    return (
                        
                        <li className="item">
                            <div className="grid cross-center">
                                <div className="col col-8" onClick={ async () => {
                                    const { state } = this.state;
                                    let endFlag = 1;
    
                                    // 출발지
                                    if (state === ADDRESS_SEARCH_STATE_RESERVE_START) {
    
                                        if (ReserveApi.isChkLimitLocal(this.setFinishAddressStrHistory(data))) {
                                            store.dispatch(start_location_service({
                                                startLocationAddress: this.setFinishAddressStrHistory(data),
                                                startLocationLat: parseFloat(data.lat),
                                                startLocationLng: parseFloat(data.lng),
                                            }))
                                            endFlag = 1;
                                        }
                                        else {
                                            await calertPromise('출발지는 서울, 경기, 인천만 가능합니다.')
                                            endFlag = 0;
                                        }
                                        
                                    } 
                                    // 종료지
                                    else if (state === ADDRESS_SEARCH_STATE_RESERVE_END) {
                                        store.dispatch(end_location_service({
                                            endLocationAddress: this.setFinishAddressStrHistory(data),
                                            endLocationLat: parseFloat(data.lat),
                                            endLocationLng: parseFloat(data.lng),
                                        }))
                                        endFlag = 1;
                                    } 
                                    // 경유지 추가
                                    else if (state.indexOf(ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_ADD) !== -1) {
                                        store.dispatch(step_over_add_service({
                                            waypointAddress: this.setFinishAddressStrHistory(data),
                                            waypointLat: parseFloat(data.lat),
                                            waypointLng: parseFloat(data.lng),
                                        }))
                                        endFlag = 1;
                                    }
                                    // 경유지 변경
                                    else if (state.indexOf(ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_UPDATE) !== -1) {
                                        let idxData = parseInt(state.split('@@')[1])
                                        store.dispatch(step_over_update_service(idxData, {
                                            waypointAddress: this.setFinishAddressStrHistory(data),
                                            waypointLat: parseFloat(data.lat),
                                            waypointLng: parseFloat(data.lng),
                                        }))
                                        endFlag = 1;
                                    } 
                                    else if (state === ADDRESS_SEARCH_STATE_JOIN || state === ADDRESS_SEARCH_STATE_MYINFO) {
                                        store.dispatch(address_pick_service(`${this.setFinishAddressStrHistory(data)}`))
                                        endFlag = 1;
                                    }
    
                                    if (endFlag) {
                                        this.props.history.replace(`${this.state.redirectUri}`);
                                    }
                                    
                                }}> 
                                    <span className="route-tit">
                                        {/* {(data.place_name !== undefined && data.place_name !== '') ? data.place_name : '(장소명없음)'} */}
                                        {(data.searchKeyword !== undefined && data.searchKeyword !== '') ? data.searchKeyword : '(검색어없음)'}
                                    </span>
    
                                    <p>{this.setFinishAddressStrHistory(data)}</p>
                                </div>
                                <div className="col right"><span>{this.viewDate(data)}</span></div>
                                
                            </div>
                        </li>
                    )
                });
            }

            return (
                <div class="no-data">
                    <p class="text-main">최근장소가 없습니다.</p>
                    <p class="text-main">주소를 검색하여 선택하면 저장됩니다.</p>
                </div>
                
            )
        }

        let testComponent11 = (addressName) => {
            
            let searchResult = [];
            let arr = BUTLER_SERVICE_LIST.categoryList
            
            // 1. 검색어 입력
            // setstate에 의해 컴포넌트 호출
            // 이미 선택한건 
            for (let i in arr) {
                let tmpData = arr[i].list.filter((value,index)=> value.serviceName.indexOf(addressName) !== -1)
                if (tmpData.length) {
                    searchResult.push({
                        category: arr[i].category,
                        list: tmpData
                    })
                }
            }

            return searchResult.map((data, idx) => {
                return (
                    <li className="item">
                        <div className="grid cross-center">
                            <div className="col col-12" onClick={ async () => { }}> 
                                <span className="route-tit" style={{fontSize:'1.5em'}}>
                                    <strong>{data.category}</strong>
                                </span>
                                {data.list.map((sub)=>(
                                    <p 
                                        style={{paddingTop:'10px', paddingBottom:'10px',  borderTop:'1px solid #efefef'}}
                                        onClick={()=>{alert(sub.uniqueServiceCode)}}
                                        >{sub.serviceName}
                                    </p>
                                ))}   
                            </div>
                        </div>
                    </li>
                )
            });           
        }


        // 최근 경로 history 리스트
        let tmpLatestHistoryLocationListComponent = (arr) => {
            if (arr.length) {
                return arr.map((data, idx) => {
                    let favoriteFlag = data.favoriteId === undefined ? false : true;
                    
                    return (
                        <li className="item">
                            <div className="grid cross-center">
                                <div className="col btn-favorite-info">
                                    {/* 즐찾은 차후에 개발. */}
                                    <button type="button" onClick={(e)=>{this.handleFavoriteTouch(data,idx)}}>
                                        {/* <i className="ico ico-favorite-on"></i><span className="blind">즐겨찾기 해제</span> */}
                                        <i className={favoriteFlag ? "ico ico-favorite-on" : "ico ico-favorite"}></i><span className="blind">즐겨찾기 등록</span>
                                    </button>
                                </div>
                                <div className="col col-8 juso-list" onClick={()=>{
                                    store.dispatch(all_location_save(this.state.historyLocationList[idx]))
                                    this.props.history.replace(`${this.state.redirectUri}`);
                                }}>
                                    {/* 출발지 */}
                                    <span className="start">{data.startLocation}</span>
                                    {/* 경유지 */}
                                    {data.stopOverList.map((data2, idx) => (
                                        <span className="vial">{data2.waypointAddress}</span>
                                    ))}
                                    {/* 종료지 */}
                                    <span className="goal">{data.endLocation}</span>
                                </div>
                                <div className="col right"><span>{this.viewDate(data)}</span></div>
                                
                            </div>
                        </li>
                    )
                });    
            }

            return (
                <div class="no-data">
                    <p class="text-main">최근경로가 없습니다.</p>
                    <p class="text-main">예약이 완료되면 예약시 지정한 경로가 저장됩니다.</p>
                </div>

            )
            
        }

        // 검색 결과 리스트
        let tmpAddressSearchListComponent = (arr) => {
            return arr.map((data, idx) => {
                return (
                    /*
                        결과값 클릭시 수행해야할 사항
                        1. 주소 관련 redux쪽으로 데이터를 전달해야함
                        2. localStorage - history_address_search에 맨 첫번째 배열로 넘겨야한다.
                    */

                    <li className="item" onClick={()=>{
                        this.handleAddressSearchResultClickEvent(data)
                    }}>
                        <div className="grid cross-center">
                            <div className="col left">
                                {/* route-tit에 검색어와 매칭되는 부분은 강조되도록 <em></em> 안에 내용을 집어넣는다.(이부분은 공수가 조금 걸릴것으로 예상.)  */}
                                <strong class="route-tit"> {this.strongName(data)} </strong>
                                <p>{ this.getRoadAddressStr(data)}</p>
                                <p>(지번) {this.getJibunAddressStr(data)}</p>
                            </div>
                        </div>
                    </li>                    
                )
            });
        }


        return (
            <>
                {/* Header */}
                <header id="Header" className={this.state.menuIdFlag !== '1' ? "no-line" : ""}>
                    <button type="button" className="btn-top-close" onClick={() => { 
                        this.props.history.replace(`${this.state.redirectUri}`) 
                    }}>
                        <span className="blind">닫기</span>
                    </button>
                    {this.state.menuIdFlag !== '1' ? (<h1>{this.getHeaderTitleAddressSearch()}</h1>) : (<h1 className="">최근경로</h1>)}
                </header>    

                <main id="Contents">
                    <div className="search-box">
                        <div className="form-controls flexible">
                            <span className="input-group has-prepend has-append">
                                <span className="prepend"><button type="button"><span><i className="ico ico-search">search</i></span></button></span>
                                <input 
                                    type="text" 
                                    placeholder="장소, 주소 검색" 
                                    className="input type1 search" 
                                    value={this.state.addressName} 
                                    onChange={this.handleChangeAddress2} 
                                    // onKeyUp={this.handleChangeAddress2} 
                                    />
                                <span className="append"><button type="button" onClick={this.handleDelAddressName}><span><i className="ico ico-search-close">close</i></span></button></span>
                            </span>
                        </div>        
                    </div>
                    
                    {/* 검색어 목록 영역 begin */}
                    <div className="component-wrap space-top"  style={{overflowY:'scroll', position:'relative'}}>
                        <div className="route-list-container">
                            <ul className="list route">
                                {testComponent11(this.state.addressName)}
                            </ul>
                        </div>	
                    </div>  
                    {/* 검색어 목록 영역 end */}

                </main>

                <ScrollTopBtn position={'normal'}/>
                

            </>
        );
    }
};



export default ButlerServiceSearch;
