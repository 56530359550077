import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withLastLocation } from "react-router-last-location";
import { withCookies } from "react-cookie";
import Header from "../../../components/Common/Header";
import RnInterface from "../../../lib/RnInterface";

import ReserveApi from "../../../lib/ReserveApi";

import Util, { calertPromise, getLocalStorage } from "../../../lib/Util";
import { getChauffeurDrivenEventAvailableDate } from "../../../lib/GoogleSheetApi";

import store from '../../../reduxStore'
import Loading from '../../../components/Common/Loading'

import CarInfoModal from '../../../components/Reserve/Modal/CarInfoModal'
import AnimatedCheckbox from '../../../components/Common/AnimatedCheckbox'
// import AnimatedCheckbox from '../Common/AnimatedCheckbox';

import "../../../css/default.css";
import "../../../css/booking.css";
import "react-datepicker/dist/react-datepicker.css";

import icon_arrow_down from '../../../img/booking/icon_arrow_down.svg'

const PRESET_NAME_MAX_LENGTH = 16;
const TEXTAREA_MAX_LENGTH = 100;
const TEXTAREA_REQUEST_MAX_LENGTH = 300;

const CAR_INFO_TYPE_CAR_NO = 'CAR_NO';
const CAR_INFO_TYPE_CAR_MODEL = 'CAR_MODEL';

const RESERVE_TYPE_GENERAL_FLAG = 1
const RESERVE_TYPE_GOLF_FLAG = 3



/*
    * Reserve Confirm View에서 아래의 데이터를 넘겨야 한다.
    - localStorage name : reserve_view_all_state  
    - Property            
            date                    date: "2022-01-04T15:00:00.000Z"
            startTime               startTime: "2022-01-03T22:00:00.000Z"
            endTime                 endTime: "2022-01-04T05:20:55.939Z"
            reserveType             reserveType: 1
            startTimeString         startTimeString: "2022-01-05 07:00:00.000"
            endTimeString           endTimeString: "2022-01-05 10:00:00.000"
            carLocation             
            comment                 
            passengerName           
            passengerPhone          
            carNo                   
            carModel                
            covidKitStatus                     
*/


class ReserveStep3View extends Component {
    state = {
        loading: 1,
        

        // phase 1
        reserveType: 1,

        
        // phase 3
        carLocation: '',
        carNo:'',
        carModel:'',
        comment: '',
        CustomerNo: 0,
        passengerName: '',
        passengerPhone: '',
        passengerCheckStatus: false,
        covidKitStatus: false,

        // 탑승자 상세 내용.
        passInfant:0,
        passAdult:0,
        passinfirm:0,
        passGravida:0,
        
        totalTime:'',      

        // Modal
        carInfoModalFlag: false,
        twoHours:false,
        eventFlag:false,
        
    };

    async componentDidMount() {
        await RnInterface.AndroidURLChange(this.props.location.pathname)
        let { loginedData } = store.getState().global_data
        let reserveAllState = getLocalStorage('reserve_view_all_state');       
        let reserveStep2BackupData = getLocalStorage('reserve_step2_backup');       
        let tmpCarNo = loginedData.CarNo === null ? '' : loginedData.CarNo;
        let tmpCarName = loginedData.CarName === null ? '' : loginedData.CarName;

        this.setState({
            reserveType: Number(this.props.match.params.state),
            passengerCheckStatus: (reserveAllState !== null) ? reserveAllState.passengerCheckStatus : true,

            passengerName:  (reserveAllState !== null) ? reserveAllState.passengerName : loginedData.CustomerName,
            passengerPhone: (reserveAllState !== null) ? reserveAllState.passengerPhone : loginedData.PhoneNumber,
            // carNo:          (reserveAllState !== null) ? reserveAllState.carNo : tmpCarNo,
            // carModel:       (reserveAllState !== null) ? reserveAllState.carModel : tmpCarName,

            carLocation:(reserveAllState !== null) ? reserveAllState.carLocation : '',
            comment:    (reserveAllState !== null) ? reserveAllState.comment : '',
            passInfant: (reserveAllState !== null) ? reserveAllState.passInfant : 0,
            passAdult:  (reserveAllState !== null) ? reserveAllState.passAdult : 0,
            passinfirm: (reserveAllState !== null) ? reserveAllState.passinfirm : 0,
            passGravida:(reserveAllState !== null) ? reserveAllState.passGravida : 0,
            twoHours:(reserveAllState !== null) ? reserveAllState.twoHours : 0,
            eventFlag: (reserveStep2BackupData !== null) ? reserveStep2BackupData.eventFlag: false,

        });

        //쇼퍼드리븐 신청란에 체크했을 경우
        if (reserveStep2BackupData !== null && reserveStep2BackupData.eventFlag === true) {
            this.setState({
                carNo:          '쇼퍼드리븐',
                carModel:       'Genesis G90',
            });
        }
        // 체크 안했거나 골프 예약이 아닐경우.
        else {
            this.setState({
                carNo:          (reserveAllState !== null) ? reserveAllState.carNo : tmpCarNo,
                carModel:       (reserveAllState !== null) ? reserveAllState.carModel : tmpCarName,
            });
        }
        

    }

    handleGotoBack = async () => {
        this.props.history.goBack();
        
    };
    handleGotoHome = async () => {
        this.props.history.replace('/main');
        this.props.history.push('/main');
    };

    setStateOnce = (key, val) => {
        return new Promise((resolve) => {
            this.setState({
                [key]:val
            }, ()=>{
                resolve(true)
            }) 
        })
    }

    handleStep3ValidateBtn = async () => {
        const {date, startTime, endTime} = store.getState().TimeSelector_ReduxModule;
        const {reserveType, carLocation, carNo, carModel, comment, passengerName, passengerPhone, covidKitStatus} = this.state
        const { passInfant, passAdult, passinfirm, passGravida, passengerCheckStatus, twoHours, eventFlag } = this.state;

        // 차량정보 부분 체크 추가(이번 리뉴얼때 차량정보 입력 부분이 필수항목이 되었다.)
        // if (carLocation.length === 0 || carNo.length === 0 || carModel.length === 0) {
        if (carNo.length === 0 || carModel.length === 0) {
            
            await calertPromise(`차량정보 항목에 누락된 정보를\n입력해주세요.`)
            this.setState({ carInfoAreaFlag:false });
            return;
        }
        // 탑승자 정보 체크
        if (passengerName.length === 0 || passengerPhone.length === 0) {
            await calertPromise('탑승자정보 이름이나 전화번호를 입력해주세요.')
            this.setState({ passengerAreaFlag:false });
            return;
        }
        
        /*
    * Reserve Confirm View에서 아래의 데이터를 넘겨야 한다.
    - localStorage name : reserve_view_all_state  
    - Property            
            date                    date: "2022-01-04T15:00:00.000Z"
            startTime               startTime: "2022-01-03T22:00:00.000Z"
            endTime                 endTime: "2022-01-04T05:20:55.939Z"
            reserveType             reserveType: 1
            startTimeString         startTimeString: "2022-01-05 07:00:00.000"
            endTimeString           endTimeString: "2022-01-05 10:00:00.000"
            carLocation             
            comment                 
            passengerName           
            passengerPhone          
            carNo                   
            carModel                
            covidKitStatus                     
*/
        // let commentAdd = `${`**탑승자정보**\n`}${passengerCheckStatus ? `본인 1명\n` : ''}${passInfant ? `영유아 ${passInfant}명\n` : ''}${passAdult ? `성인 ${passAdult}명\n` : ''}${passinfirm ? `노약자 ${passinfirm}명\n` : ''}${passGravida ? `임산부 ${passGravida}명\n` : ''}${`총 ${this.totalPassengerCount()} 명\n\n`}`
        let commentAdd = `${`**탑승자정보**\n`}${passInfant ? `영유아 ${passInfant}명\n` : ''}${passAdult ? `성인 ${passAdult}명\n` : ''}${passinfirm ? `노약자 ${passinfirm}명\n` : ''}${passGravida ? `임산부 ${passGravida}명\n` : ''}${`총 ${this.totalPassengerCount()} 명\n\n**end**`}`
        console.log(commentAdd.trim())

        let reserveAllState = getLocalStorage('reserve_view_all_state');       
        let objData = {}
        // local storage에 정보가 없을때 신규 추가
        if (reserveAllState === null) {
            objData = {
                date,
                startTime,
                endTime,
                reserveType,
                startTimeString : startTime.format('yyyy-MM-DD HH:mm:00.000'),
                endTimeString : endTime.format('yyyy-MM-DD HH:mm:00.000'),
                carLocation,
                comment,
                passengerName,
                passengerPhone,
                passengerCheckStatus,
                carNo,
                carModel,
                covidKitStatus,
                commentAdd,
                passInfant, 
                passAdult, 
                passinfirm, 
                passGravida,
                twoHours,
                eventFlag,
            }
        }
        else {
            objData = {
                ...reserveAllState,
                date,
                startTimeString : startTime.format('yyyy-MM-DD HH:mm:00.000'),
                endTimeString : endTime.format('yyyy-MM-DD HH:mm:00.000'),
                carLocation,
                comment,
                passengerName,
                passengerPhone,
                passengerCheckStatus,
                carNo,
                carModel,
                covidKitStatus,
                commentAdd,
                passInfant, 
                passAdult, 
                passinfirm, 
                passGravida,
                twoHours,
                eventFlag,
            }
        }        
        
        localStorage.setItem('reserve_view_all_state', JSON.stringify(objData))
        
        
        
        this.props.history.push(`/reserve_renew_ski/${this.state.reserveType}/5`)
    }


    

    changeInput = (e) => {
        let name = e.target.name;
        let value = e.target.value
        this.setState({
            [name]: value
        })
    }

    changeInputLimit = (e, maxlength) => {
        let name = e.target.name;
        let value = e.target.value
        
        if (Util.getStringLength(value) <= maxlength) {
            this.setState({
                [name]: value
            })
        }
        
    }

    handlePassengerCheckStatus = (e) => {
        let { loginedData } = store.getState().global_data

        this.setState({
            passengerCheckStatus: e.target.checked,
            passengerName: e.target.checked ? loginedData.CustomerName : '',
            passengerPhone: e.target.checked ? loginedData.PhoneNumber : ''
        })
    }

    handlePassengerName = (e) => {
        this.setState({
            passengerName: e.target.value
        })
    }

    handlePassengerPhone = (e) => {
        this.setState({
            passengerPhone: e.target.value
        })
    }

    // 차량번호, 차량모델 취득하는 메소드
    // 로그인시에 차량번호, 모델을 등록해놨다면 
    // 해당 정보를 취득하도록하고,
    // 상기 정보를 등록하지 않았다면
    getCarNoCarModel = (type) => {
        const { loginedData } = store.getState().global_data
        let retData = '';
        try {
            switch(type) {
                case CAR_INFO_TYPE_CAR_NO:
                    retData = loginedData.CarNo !== null ? loginedData.CarNo : '';
                    break;
                case CAR_INFO_TYPE_CAR_MODEL:
                    retData = loginedData.CarName !== null ? loginedData.CarName : '';
                    break;
                defalut:
                    retData = '';
                    break;
            }
        }
        catch(e) {
            console.log('getCarNoCarModel err : ', e)
        }
        
      
        return retData;
    }

    totalPassengerCount = () => {
        const {
            passInfant,
            passAdult,
            passinfirm,
            passGravida,
            passengerCheckStatus
        } = this.state;
        let ret = passInfant + passAdult + passinfirm + passGravida;
        // return passengerCheckStatus ? (ret + 1) : ret
        return ret
    }

    handleCarInfoModalOpen = () => { this.setStateOnce('carInfoModalFlag', true) }
    handleCarInfoModalClose = () => { this.setStateOnce('carInfoModalFlag', false) }

    eventCheckBox = () => {
        const {twoHours, reserveType} = this.state;
        return (
            <div className={`btn-checkbox ${(reserveType === RESERVE_TYPE_GOLF_FLAG) ? 'golf' : ''}`}>
                <input type="checkbox" id="same1" checked={twoHours} onChange={()=>{
                    this.setStateOnce('twoHours', !twoHours)
                }}/>
                <label for="same1" style={{lineHeight:'26px'}}><b>있음</b></label>
            </div> 
        )
    }

    render() {
        const {
            startLocationAddress,
            startLocationLat,
            startLocationLng,
            endLocationAddress,
            endLocationLat,
            endLocationLng,
            stopOverList,
            checkSwitch
        } = store.getState().LocationSelector_ReduxModule;

        const {
            passInfant,
            passAdult,
            passinfirm,
            passGravida,
            passengerCheckStatus
        } = this.state;
        

        
        const increasePassenger = (e, name) => {
            this.setState((prevState)=>{
                let ret = {}
                if (prevState[name] <= 4) 
                    ret = {
                        [name]: prevState[name] +=1
                    }
                return ret;
            })
        }
        const decreasePassenger = (e, name) => {
            this.setState((prevState)=>{
                let ret = {}
                if (prevState[name] >= 1) 
                    ret = {
                        [name]: prevState[name] -=1
                    }
                return ret;
            })
        }
        
        let carInfoAreaDynamicClassName = (flag, value) => {
            let retData = 'input type1';
            try {
                
                /* 
                    최초 화면에 들어갔다면 input type1이 나와야 함
                    다음 눌렀을 때 입력안된 영역은 input type1 fail이 나와야 함
                    입력 안된 영역에 1자라도 입력시에는 input type1이 나와야 함.
                */
                if (flag && !value.length) {
                    retData = 'input type1';
                }
                else if (!flag && !value.length) {
                    retData = 'input type1 fail';
                }
                else if (!flag && value.length) {
                    retData = 'input type1';
                }
            }
            catch(e) {

            }
            
            return retData;
        }
        
        const customLineHeight = {
            lineHeight:'34px'         
        }

        return (
            <>
            {!this.state.loading ? (<Loading />) : ''}
                <Header
                    // title={`${ReserveApi.getDrivingTypeStr(this.state.reserveType)} 예약 (3/5)`}
                    title={`스키 예약 (3/4)`}
                    onBackButton={this.handleGotoBack}
                    onHomeButton={this.handleGotoHome}
                />

                <div className="booking_box2 hd_margin_top">

                <div className="reserve-list">
                    <div className="search-box">
                        <div className="form-controls flexible"  style={{justifyContent:'space-between', paddingBottom:'10px'}}>
                            <h1 className='h1' style={{display:'inline'}}>탑승자 정보<i className="ico required2"><span className="blind">필수입력항목</span></i></h1>
                            
                            <div style={{display:'flex', alignItems:'center'}}>
                                <h1 className='h2' style={{display:'inline'}}>본인</h1>&nbsp;    
                                <AnimatedCheckbox
                                    reserveType={this.state.reserveType}
                                    checkSwitch={this.state.passengerCheckStatus}
                                    onChangeCheckdata={this.handlePassengerCheckStatus} />
                            </div>
                        </div>

                        <div className="form-controls type-passenger" >
                            <input type="text" disabled={this.state.passengerCheckStatus} className={`${carInfoAreaDynamicClassName(this.state.passengerAreaFlag, this.state.passengerName)} W100`}  placeholder="탑승자 이름" value={this.state.passengerName} onChange={this.handlePassengerName} />
                            <input type="text" disabled={this.state.passengerCheckStatus} className={`${carInfoAreaDynamicClassName(this.state.passengerAreaFlag, this.state.passengerPhone)} W100`} placeholder="탑승자 전화번호" value={this.state.passengerPhone} onChange={this.handlePassengerPhone} />
                        </div>        
                        
                    </div>
                </div>

                {/* {!getChauffeurDrivenEventAvailableDate() && (this.state.reserveType === 3) ? (
                    <div className="reserve-list">
                        <div className="search-box">
                        <h1 className='h1' style={{paddingBottom:'10px'}}>이벤트<i className="ico required2"><span className="blind">필수입력항목</span></i></h1>
                            <div className="grid cross-center flex-between"  style={{fontSize:'1.1em', paddingBottom:'10px'}}>   
                                <div className="col">
                                    Genesis 쇼퍼드리븐 신청
                                </div>
            
                                <div className="col right">
                                    {this.eventCheckBox()}
                                </div>
                            </div>   
                            <div className="bul notice">
                                <p>쇼퍼드리븐 신청에 성공하신 경우 운행 종료후 이용금액 <b>150,000원</b>이 후청구됩니다.</p>
                                <p>본 이벤트는 <b>2022-06-30</b>까지 진행합니다.</p>
                            </div>
                        </div>
                    </div>
                ) : <></>}
                 */}

                


                <div className="reserve-list">
                    <div className="search-box">
                        
                        <h1 className='h1' style={{paddingBottom:'10px'}}>추가 탑승자</h1>
                        <div className="grid cross-center flex-between"  style={{fontSize:'1.1em', paddingBottom:'10px'}}>   
                            <div className="col">
                                영유아
                            </div>
        
                            <div className="col right">
                                <button className="btn-option3" onClick={(e)=>{ decreasePassenger(e,'passInfant')   }}><i className="ico ico-minus"></i></button>
                                <span style={{paddingLeft:'10px', paddingRight:'10px'}}>{passInfant} 명</span>
                                <button className="btn-option3" onClick={(e)=>{ increasePassenger(e,'passInfant')   }}><i className="ico ico-plus"></i></button>
                            </div>
                        </div>

                        <div className="grid cross-center flex-between"  style={{fontSize:'1.1em', paddingBottom:'10px'}}>   
                            <div className="col">
                                성인
                            </div>
                            <div className="col right">
                                <button className="btn-option3" onClick={(e)=>{  decreasePassenger(e,'passAdult')  }}><i className="ico ico-minus"></i></button>
                                <span style={{paddingLeft:'10px', paddingRight:'10px'}}>{passAdult} 명</span>
                                <button className="btn-option3" onClick={(e)=>{ increasePassenger(e,'passAdult')   }}><i className="ico ico-plus"></i></button>
                            </div>
                        </div>

                        <div className="grid cross-center flex-between"  style={{fontSize:'1.1em', paddingBottom:'10px'}}>   
                            <div className="col">
                                노약자
                            </div>
                            <div className="col right">
                                <button className="btn-option3" onClick={(e)=>{ decreasePassenger(e,'passinfirm')   }}><i className="ico ico-minus"></i></button>
                                <span style={{paddingLeft:'10px', paddingRight:'10px'}}>{passinfirm} 명</span>
                                <button className="btn-option3" onClick={(e)=>{ increasePassenger(e,'passinfirm')    }}><i className="ico ico-plus"></i></button>
                            </div>
                        </div>

                        <div className="grid cross-center flex-between"  style={{fontSize:'1.1em', paddingBottom:'10px'}}>   
                            <div className="col">
                                임산부
                            </div>
                            <div className="col right">
                                <button className="btn-option3" onClick={(e)=>{ decreasePassenger(e,'passGravida')   }}><i className="ico ico-minus"></i></button>
                                <span style={{paddingLeft:'10px', paddingRight:'10px'}}>{passGravida} 명</span>
                                <button className="btn-option3" onClick={(e)=>{ increasePassenger(e,'passGravida')   }}><i className="ico ico-plus"></i></button>
                            </div>
                        </div>
                        <div className="grid cross-center flex-between"  style={{fontSize:'1.1em', paddingBottom:'10px'}}>   

                            <div className="col right">
                                총 {this.totalPassengerCount()} 명
                            </div>
                        </div>
                    </div>
                </div>

                
                <div className="reserve-list">
                    <div className="search-box">
                        <div className="grid cross-center flex-between" style={customLineHeight}>
                            <div className="col grid cross-center">
                                <div className="col">
                                    <h1 className='h1' style={{display:'inline'}}>이용연장</h1>    
                                </div>
                            </div>
                            
                            <div className="col right">
                            {this.eventCheckBox()}
                            </div>
                        </div>
                        <div className="bul notice">
                            <p>서비스 이용연장은 <b>최대 2시간</b> 까지 가능합니다.</p>
                        </div>
                    </div>
                    
                    
                </div>


                    <div className="reserve-list">
                        <div className="search-box">
                            <h1 className='h1' style={{paddingBottom:'10px'}}>차량정보<i className="ico required2"><span className="blind">필수입력항목</span></i></h1>
                            <div className="form-controls type-carinfo">
                                <div className="row">
                                    <input type="text" name="carModel" id="" onChange={this.changeInput} value={this.state.carModel} placeholder="차량 모델 ex: BMW 7" title="" className={carInfoAreaDynamicClassName(this.state.carInfoAreaFlag, this.state.carModel)} />
                                    <input type="text" name="carNo" id="" onChange={this.changeInput} value={this.state.carNo} placeholder="차량 번호 ex: 12가 1234" title="" className={carInfoAreaDynamicClassName(this.state.carInfoAreaFlag, this.state.carNo)} />
                                    {/* <button
                                        type="button"
                                        className="btn large secondary"
                                        onClick={this.handleCarInfoModalOpen}>등록</button> */}
                                </div>
                                
                                {/* <div className="row">
                                    <textarea className={carInfoAreaDynamicClassName(this.state.carInfoAreaFlag, this.state.carLocation)} rows="3" placeholder="파트너와 만날 장소를 입력해주세요." name="carLocation" value={this.state.carLocation} onChange={(e)=>this.changeInputLimit(e, TEXTAREA_MAX_LENGTH)} ></textarea>
                                </div> */}

                                {/* <div className="row text-gray">
                                    {Util.getStringLength(this.state.carLocation)}/{TEXTAREA_MAX_LENGTH} 
                                    {Util.getStringLength(this.state.carLocation) >= TEXTAREA_MAX_LENGTH ? (<b> (100자 이상 입력할 수 없습니다.)</b>) : ''}
                                </div> */}
                            </div>
                        </div>
                    </div>   
                    
                    <div className="reserve-list">
                        <div className="search-box">
                            <h1 className='h1' style={{paddingBottom:'10px'}}>파트너(드라이버)에게 알려주세요.</h1>
                            <div className="form-controls type-carinfo">
                                <div className="row">
                                    <div className="placeholder_sim">
                                        ex)  <br/>
                                        1. 105동 지하2층에서 만나요. <br/>
                                        2. 난폭 운전 싫어요! 안전 운전 부탁드려요. <br/>
                                        3. 담배 냄새를 싫어해요. <br/>
                                    </div>
                                    <textarea 
                                        className="input type1" 
                                        rows="6"  
                                        name="comment" 
                                        value={this.state.comment} 
                                        onFocus={()=>{ document.querySelector('.placeholder_sim').style.display='none' }} 
                                        onBlur={()=>{ document.querySelector('.placeholder_sim').style.display='block' }} 
                                        onChange={(e)=>this.changeInputLimit(e, TEXTAREA_REQUEST_MAX_LENGTH)} >
                                    </textarea>
                                </div>
                                <div className="row text-gray">
                                    {Util.getStringLength(this.state.comment)}/{TEXTAREA_REQUEST_MAX_LENGTH} 
                                    {Util.getStringLength(this.state.comment) >= TEXTAREA_REQUEST_MAX_LENGTH ? (<b> (300자 이상 입력할 수 없습니다.)</b>) : ''}
                                    
                                </div>
                            </div>
                        </div>
                    </div>   


                    <div className="component-wrap inner-container">
                        <div className="btn-area vertical">
                            <button
                                type="button"
                                className="btn large secondary"
                                onClick={this.handleStep3ValidateBtn}
                            >
                                <span>다음</span>
                            </button>
                        </div>
                    </div>
                        
                </div>

                <CarInfoModal 
                    isOpen={this.state.carInfoModalFlag}
                    onRequestClose={this.handleCarInfoModalClose}
                />
                    
                
            </>
        );
    }
}

export default withLastLocation(withCookies(ReserveStep3View));


