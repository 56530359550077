import React, { Component } from 'react';
import RnInterface from '../../lib/RnInterface'
import '../../css/app.ui.css';

let search = '';
let params = '';
let menuState = '';

class PolicyPrice extends Component {

    state = {
        menuType: ''
    }

    async componentDidMount() {
        // 초기값 세팅
        // Load될때 
        await RnInterface.AndroidURLChange(this.props.location.pathname)
        console.log(this.props.location)
        this.setState({
            menuType: menuState
        })
    }

    tabClick = (e) => {
        this.setState({
            menuType: e
        })
    }

    render() {
        search = this.props.location.search.slice(1);
        params = new URLSearchParams(search);
        menuState = params.get('state');

        return (
            <>
                <header id="Header" className="">
                    <button type="button" className="btn-top-close" onClick={this.props.history.goBack}><span className="blind">닫기</span></button>
                    <h1>전체요금 확인하기</h1>
                </header>
                
                <main id="Contents">
                    <div className="tabs-container">
                        <div className="tabs-nav">
                            <ul className="tabs-group">
                                <li style={{width:"33.33%"}}>
                                    <button className={this.state.menuType === '1' ? 'tab tab-on':'tab'} onClick={()=>{ this.tabClick('1') }}>일반요금</button>
                                </li>
                                <li style={{width:"33.33%"}}>
                                    <button className={this.state.menuType === '2' ? 'tab tab-on':'tab'} onClick={()=>{ this.tabClick('2') }}>골프요금</button>
                                </li>
                                <li style={{width:"33.33%"}}>
                                    <button className={this.state.menuType === '3' ? 'tab tab-on':'tab'} onClick={()=>{ this.tabClick('3') }}>지역할증</button>
                                </li>
                            </ul>
                        </div>


                        
                        <div className="tabs-content">
                        {(this.state.menuType === '1') && (
                            <>
                            
                            {/* 운행요금 */}
                            <div className="count-filter-group grid cross-center">
                                <div className="col"><strong>최소 이용시간 2시간</strong></div>
                                <div className="col right">2022-06-01</div>
                            </div>
                            <div className="inner-container">
                                <div className="tbl type1">                                        
                                    <table>
                                        <thead>
                                            <tr>
                                                <th scope="col">이용시간</th>
                                                {/* <th scope="col" className="point">요금 구간</th> */}
                                                <th scope="col" className="point">부과 요금</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>기본 2시간</td>
                                                {/* <td className="point">30,000원<span className="dis-b">~</span>55,000원</td> */}
                                                <td className="point">60,000원</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    1시간 추가마다 30,000원씩 부과
                                                </td>
                                            </tr>
                                            {/* <tr>
                                                <td>2시간(120분) 이상<span className="dis-b">~</span>5시간(300분) 미만</td>
                                                <td className="point">40,800원<span className="dis-b">~</span>96,600원</td>
                                                <td>3,400원/10분</td>
                                            </tr>
                                            <tr>
                                                <td>5시간(300분) 이상<span className="dis-b">~</span>10시간(600분) 미만</td>
                                                <td className="point">90,000원<span className="dis-b">~</span>177,000원</td>
                                                <td>3,000원/10분</td>
                                            </tr>
                                            <tr>
                                                <td>10시간(600분) 이상</td>
                                                <td className="point">168,000원<span className="dis-b">~</span></td>
                                                <td>2,800원/10분</td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="price-box mar-t20">
                                    <div className="price-head">
                                        <div className="price-title"><strong>추가요금</strong></div>
                                    </div>
                                    <div class="grid gut-guide flex-wrap">
										<div class="col col-6">
                                            <div class="price-item">
                                                <ul class="list inner-list">
                                                    <li class="item header">연장 운행요금</li>
                                                    {/* <li class="item mar-t15"> */}
                                                    <li class="item">
                                                        <strong className="point-item">5,000원/10분</strong> 
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
										<div class="col col-6">
                                            <div class="price-item">
                                                <ul class="list inner-list">
                                                    <li class="item header">심야 할증요금</li>
                                                    <li class="item">
                                                        <p>23시~06시에<br />시작/종료시</p>
                                                        <strong className="point-item">(각각 20,000원)</strong> 
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>                                        
									</div>                                      
                                </div>

                                <div className="price-box mar-t20">
                                    <div className="price-head">
                                        <div className="price-title"><strong>취소수수료</strong></div>
                                    </div>
                                    <div class="grid gut-guide flex-wrap">
										<div class="col col-6">
                                            <div class="price-item">
                                                <ul class="list inner-list">
                                                    <li class="item header">전일</li>
                                                    {/* <li class="item mar-t15"> */}
                                                    <li class="item">
                                                        <strong className="point-item">30,000원</strong> 
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
										<div class="col col-6">
                                            <div class="price-item">
                                                <ul class="list inner-list">
                                                    <li class="item header">당일</li>
                                                    <li class="item">
                                                        <strong className="point-item">예약금 기준 50%</strong> 
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
									</div>
                                    <div class="grid gut-guide flex-wrap">
										<div class="col col-12">
                                            <div class="price-item">
                                                <ul class="list inner-list">
                                                    <li class="item header">예약시간 이후</li>
                                                    {/* <li class="item mar-t15"> */}
                                                    <li class="item">
                                                        <strong className="point-item">예약금 기준 100%</strong> 
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
										
									</div>
                                </div>
                            </div>
                            <div className="component-wrap space-top">
                                <section className="information-text on">
                                    <h1 className="information-head">
                                        <button type="button" className="btn-viewmore">
                                            <i className="ico ico-info"></i>
                                            꼭 확인해주세요.
                                            <i className="icon arrow"></i>
                                        </button>
                                    </h1>
                                    <div className="information-body">
                                        <div className="info-desc">
                                            <ul className="text-sub">
                                                <li className="text-import">요금은 기본 2시간(120분)이며, 예약시 60,000원 으로 부과됩니다.</li>
                                                <li>예약한 시간보다 많이 사용했을 경우 연장 운행요금이 적용되어 10분당 5,000원이 추가 부과 됩니다.</li>
                                                <li>시작시간과 종료시간이 23:00~06:00 범위 내에 있을 경우 심야할증이 부과됩니다.</li>
                                                {/* <li>예약 시 지정한 '출발지'-'경유지'-'종료지' 이동 <p>예상소요시간을 기준으로 금액이 산정됩니다.</p></li> */}
                                            </ul>
                                        </div>  
                                    </div>
                                </section> 
                            </div>     
                            </>    
                        )}

                        {(this.state.menuType === '2') && (
                            <>
                            
                            <div className="count-filter-group grid cross-center">
                                <div className="col"><strong>최소 이용시간 10시간</strong></div>
                                <div className="col right">2022-06-01</div>
                            </div>
                            <div className="inner-container">
                                <div className="tbl type1">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th scope="col">이용시간</th>
                                                <th scope="col" className="point">요금</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>기본 10시간</td>
                                                <td className="point">200,000원</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    1시간 추가마다 20,000원씩 부과
                                                </td>
                                            </tr>
                                            
                                        </tbody>
                                    </table>
                                </div>

                                <div className="price-box mar-t20">
                                    <div className="price-head">
                                        <div className="price-title"><strong>추가요금</strong></div>
                                    </div>
                                    <div class="grid gut-guide flex-wrap">
                                        <div class="col col-6">
                                            <div class="price-item">
                                                <ul class="list inner-list">
                                                    <li class="item header">연장 운행요금</li>
                                                    {/* <li class="item mar-t15"> */}
                                                    <li class="item">
                                                        <strong className="point-item">3,300원/10분</strong> 
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col col-6">
                                            <div class="price-item">
                                                <ul class="list inner-list">
                                                    <li class="item header">심야 할증요금</li>
                                                    <li class="item">
                                                        <p>23시~06시에<br />시작/종료시</p>
                                                        <strong className="point-item">(각각 20,000원)</strong> 
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>                                        
                                    </div>                                      
                                </div>
                                <div className="price-box mar-t20">
                                    <div className="price-head">
                                        <div className="price-title"><strong>취소수수료</strong></div>
                                    </div>
                                    <div class="grid gut-guide flex-wrap">
										<div class="col col-6">
                                            <div class="price-item">
                                                <ul class="list inner-list">
                                                    <li class="item header">전일</li>
                                                    {/* <li class="item mar-t15"> */}
                                                    <li class="item">
                                                        <strong className="point-item">30,000원</strong> 
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
										<div class="col col-6">
                                            <div class="price-item">
                                                <ul class="list inner-list">
                                                    <li class="item header">당일</li>
                                                    <li class="item">
                                                        <strong className="point-item">예약금 기준 50%</strong> 
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
									</div>
                                    <div class="grid gut-guide flex-wrap">
										<div class="col col-6">
                                            <div class="price-item">
                                                <ul class="list inner-list">
                                                    <li class="item header">예약시간 이후</li>
                                                    {/* <li class="item mar-t15"> */}
                                                    <li class="item">
                                                        <strong className="point-item">예약금 기준 100%</strong> 
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col col-6">
                                            <div class="price-item">
                                                <ul class="list inner-list">
                                                    <li class="item header">운행중 우천시 취소</li>
                                                    {/* <li class="item mar-t15"> */}
                                                    <li class="item">
                                                        <strong className="point-item">예약금 기준 50%</strong> 
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
										
									</div>
                                </div>
                            </div>
                            <div className="component-wrap space-top">
                                <section className="information-text on">
                                    <h1 className="information-head">
                                        <button type="button" className="btn-viewmore">
                                            <i className="ico ico-info"></i>
                                            꼭 확인해주세요.
                                            <i className="icon arrow"></i>
                                        </button>
                                    </h1>
                                    <div className="information-body">
                                        <div className="info-desc">
                                            <ul className="text-sub">
                                                <li className="text-import">요금은 기본 10시간(600분)이며, 예약시 200,000원이 부과됩니다. 1시간 추가할 때마다 20,000원이 부과됩니다.</li>
                                                <li>예약한 시간보다 많이 사용했을 경우 연장 운행요금이 적용되어 10분당 3,300원이 추가 부과 됩니다.</li>
                                                <li>시작시간과 종료시간이 23:00~06:00 범위 내에 있을 경우 심야할증이 부과됩니다.</li>
                                            </ul>
                                        </div>  
                                    </div>
                                </section> 
                            </div>                              
                            {/* 정액권 */}   
                            </>    
                        )}

                        {(this.state.menuType === '3') && (
                            <>
                            {/* 지역할증 */}
                            <div className="count-filter-group grid cross-center">
                                <div className="col">출발 가능지역 : 서울, 경기, 인천</div>
                                <div className="col right">도착 가능지역 : 전국</div>
                            </div>                              
                            <div className="inner-container">
                                <div className="tbl type1">                                        
                                    <table>
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{width:"25%"}}>지역</th>
                                                <th scope="col" style={{width:"25%"}} className="point">할증요금</th>
                                                <th scope="col" style={{width:"25%"}}>지역</th>
                                                <th scope="col" style={{width:"25%"}} className="point">할증요금</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>경기/인천</td>
                                                <td className="point">30,000원</td>
                                                <td>충청</td>
                                                <td className="point">50,000원</td>
                                            </tr>
                                            <tr>
                                                <td>강원</td>
                                                <td className="point">70,000원</td>
                                                <td>전북/경북</td>
                                                <td className="point">60,000원</td>
                                            </tr>   
                                            <tr>
                                                <td>전남/경남</td>
                                                <td className="point">90,000원</td>
                                                <td>기타지역</td>
                                                <td className="point">120,000원</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="bul notice">
                                    <p>기타지역 : 부산, 남해, 여수, 통영, 울산, 목포, 순천, 광양, 거제, 고흥, 보성 등</p>
                                </div>  
                                <div className="price-box mar-t20">
                                    <div className="price-head">
                                        <div className="price-title">지역할증 요금 예시(지역기준 : 충청)</div>
                                    </div>
                                    <div class="grid gut-guide flex-wrap">
                                        <div className="col col-12">
                                            <div className="price-stitle">[ 왕복운행시 - 출발지, 종료지 동일 ]</div>
                                        </div>
										<div class="col col-6">
                                            <div class="price-item">
                                                <ul className="list inner-list">
                                                    <li className="item header">서울 → 충청 → 서울</li>
                                                    <li className="item">
                                                        <div className="item-row grid crocss-center">
                                                            <div className="col left"><span>출발지할증</span></div>
                                                            <div className="col right"><span>0</span></div>
                                                        </div>
                                                        <div className="item-row grid crocss-center">
                                                            <div className="col left"><span>지역할증</span></div>
                                                            <div className="col right"><span>0</span></div>
                                                        </div>
                                                        <div className="item-row grid crocss-center">
                                                            <div className="col left"><strong className="point-item">합계</strong></div>
                                                            <div className="col right"><strong className="point-item">0</strong></div>
                                                        </div>                                                                                                        
                                                    </li>
                                                </ul>                                                
                                            </div>
                                        </div>
										<div class="col col-6">
                                            <div class="price-item">
                                                <ul className="list inner-list">
                                                    <li className="item header">경기 → 충청 → 경기</li>
                                                    <li className="item">
                                                        <div className="item-row grid crocss-center">
                                                            <div className="col left"><span>출발지할증</span></div>
                                                            <div className="col right"><span>30,000</span></div>
                                                        </div>
                                                        <div className="item-row grid crocss-center">
                                                            <div className="col left"><span>지역할증</span></div>
                                                            <div className="col right"><span>0</span></div>
                                                        </div>
                                                        <div className="item-row grid crocss-center">
                                                            <div className="col left"><strong className="point-item">합계</strong></div>
                                                            <div className="col right"><strong className="point-item">30,000</strong></div>
                                                        </div>                                                                                                        
                                                    </li>
                                                </ul>                                                
                                            </div>
                                        </div>                                        
									</div>

                                    <div class="grid gut-guide flex-wrap mar-t10">
                                        <div className="col col-12">
                                            <div className="price-stitle">[ 편도운행시 ]</div>
                                        </div>
										<div class="col col-6">
                                            <div class="price-item">
                                                <ul className="list inner-list">
                                                    <li className="item header">서울 → 경기</li>
                                                    <li className="item">
                                                        <div className="item-row grid crocss-center">
                                                            <div className="col left"><span>출발지할증</span></div>
                                                            <div className="col right"><span>0</span></div>
                                                        </div>
                                                        <div className="item-row grid crocss-center">
                                                            <div className="col left"><span>지역할증</span></div>
                                                            <div className="col right"><span>30,000</span></div>
                                                        </div>
                                                        <div className="item-row grid crocss-center">
                                                            <div className="col left"><strong className="point-item">합계</strong></div>
                                                            <div className="col right"><strong className="point-item">30,000</strong></div>
                                                        </div>                                                                                                        
                                                    </li>
                                                </ul>                                                
                                            </div>
                                        </div>
										<div class="col col-6">
                                            <div class="price-item">
                                                <ul className="list inner-list">
                                                    <li className="item header">서울 → 충청 → 경기</li>
                                                    <li className="item">
                                                        <div className="item-row grid crocss-center">
                                                            <div className="col left"><span>출발지할증</span></div>
                                                            <div className="col right"><span>0</span></div>
                                                        </div>
                                                        <div className="item-row grid crocss-center">
                                                            <div className="col left"><span>지역할증</span></div>
                                                            <div className="col right"><span>30,000</span></div>
                                                        </div>
                                                        <div className="item-row grid crocss-center">
                                                            <div className="col left"><strong className="point-item">합계</strong></div>
                                                            <div className="col right"><strong className="point-item">30,000</strong></div>
                                                        </div>                                                                                                        
                                                    </li>
                                                </ul>                                                
                                            </div>
                                        </div> 
										<div class="col col-6">
                                            <div class="price-item">
                                                <ul className="list inner-list">
                                                    <li className="item header">경기(분당) → 경기(수원)</li>
                                                    <li className="item">
                                                        <div className="item-row grid crocss-center">
                                                            <div className="col left"><span>출발지할증</span></div>
                                                            <div className="col right"><span>30,000</span></div>
                                                        </div>
                                                        <div className="item-row grid crocss-center">
                                                            <div className="col left"><span>지역할증</span></div>
                                                            <div className="col right"><span>30,000</span></div>
                                                        </div>
                                                        <div className="item-row grid crocss-center">
                                                            <div className="col left"><strong className="point-item">합계</strong></div>
                                                            <div className="col right"><strong className="point-item">60,000</strong></div>
                                                        </div>                                                                                                        
                                                    </li>
                                                </ul>                                                
                                            </div>
                                        </div>
										<div class="col col-6">
                                            <div class="price-item">
                                                <ul className="list inner-list">
                                                    <li className="item header">경기 → 충청 → 전남</li>
                                                    <li className="item">
                                                        <div className="item-row grid crocss-center">
                                                            <div className="col left"><span>출발지할증</span></div>
                                                            <div className="col right"><span>30,000</span></div>
                                                        </div>
                                                        <div className="item-row grid crocss-center">
                                                            <div className="col left"><span>지역할증</span></div>
                                                            <div className="col right"><span>90,000</span></div>
                                                        </div>
                                                        <div className="item-row grid crocss-center">
                                                            <div className="col left"><strong className="point-item">합계</strong></div>
                                                            <div className="col right"><strong className="point-item">120,000</strong></div>
                                                        </div>                                                                                                        
                                                    </li>
                                                </ul>                                                
                                            </div>
                                        </div>                                                                                                                       
									</div>
                                </div>                                                              
                            </div>

                            <div className="component-wrap space-top">
                                <section className="information-text on">
                                    <h1 className="information-head">
                                        <button type="button" className="btn-viewmore">
                                            <i className="ico ico-info"></i>
                                            꼭 확인해주세요.
                                            <i className="icon arrow"></i>
                                        </button>
                                    </h1>
                                    <div className="information-body">
                                        <div className="info-desc">
                                            <ul className="text-sub">
                                                <li className="text-import">서울/경기/인천에서 출발 가능하며, 출발 지역이 경기/인천일 경우 출발지 할증 30,000원이 과금됩니다.</li>
                                                <li className="text-import">기존/신규 요금제 공통적용됩니다.</li>
                                                <li>왕복운행시 출발지할증이 과금됩니다.</li>
                                                <li>편도운행시 출발지할증 + 지역할증이 과금됩니다.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </section> 
                            </div>                             
                            {/* 지역할증 */}                                                                        
                            </>    
                        )}                        
                        </div>    
                    </div>
                </main>
            </>
        );
    }
};

export default PolicyPrice;
