import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';

// redux
import store from '../../reduxStore'
import { init_location_selector_service } from '../../modules/Reserve/LocationSelector_ReduxModule'
import { init_time_all_selector_service } from '../../modules/Reserve/TimeSelector_ReduxModule'
import { empty_use_state_service } from '../../modules/UseState/UseState_ReduxModule'
import { empty_use_history_service } from '../../modules/UseHistory/UseHistory_ReduxModule'
import { getDrivingTypeRedux } from '../../modules/Global/DrivingType_ReduxModule';
import { withCookies } from 'react-cookie'
import {calertConfirm, calertNoticePopup} from '../../lib/Util'
import RnInterface from '../../lib/RnInterface'
import ReserveApi from '../../lib/ReserveApi'
import NoticeApi from '../../lib/NoticeApi'
import AccountApi from '../../lib/AccountApi'
import Loading from '../../components/Common/Loading'
import { calertPromise } from '../../lib/Util';

import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

import axios from 'axios'
import moment from 'moment'

import '../../css/default.css';
import '../../css/booking.css';

import service_car from "../../img/booking/service_car.svg";
import icon_card_hana from "../../img/booking/icon_card_hana.svg";
import icon_card_master from "../../img/booking/icon_card_master.svg";

import btn_prev from '../../img/btn/btn_prev.svg'
import btn_home from '../../img/btn/btn_home.svg'

import icon_map_car from '../../img/booking/icon_map_car.png'
import icon_car_search from '../../img/booking/icon_car_search.png'

import icon_chat from '../../img/booking/icon_chat.svg'

import HeaderMain from '../../components/Common/HeaderMain';

import icon_m1 from '../../img/menu/icon_m1.svg';
import icon_m2 from '../../img/menu/icon_m2.svg';
import icon_m3 from '../../img/menu/icon_m3.svg';
import icon_m4 from '../../img/menu/icon_m4.svg';
import icon_m5 from '../../img/menu/icon_m5.svg';
import icon_m6 from '../../img/menu/icon_m6.svg';
import icon_m7 from '../../img/menu/icon_m7.svg';
import icon_m8 from '../../img/menu/icon_m8.svg';
import icon_m9 from '../../img/menu/icon_m9.svg';
import icon_m11 from '../../img/menu/icon_m11.svg';
import icon_m12 from '../../img/menu/icon_m12.svg';




import btn_cancel from "../../img/menu/btn_cancel.svg"
import btn_arrow2 from "../../img/menu/btn_arrow2.svg"
import btn_arrow from "../../img/menu/btn_arrow.svg"

import MainMenuMyInfoCss from '../../css/CustomGlobalStyle/MainMenuMyInfo'

import visual_general from '../../img/img_booking_general.jpg'
import visual_golf from '../../img/img_booking_golf.jpg'
import visual_black from '../../img/img_booking_black.jpg'

// 2021/12/7 추가
import visual_ski from '../../img/img_booking_ski.png'
import visual_horse from '../../img/img_booking_horse.png'
import visual_golf2 from '../../img/img_booking_golf2.png'

import Util, { calert } from '../../lib/Util'

import AppSettingApi from '../../lib/AppSettingApi';
import compareVersions  from 'compare-versions'

import event_monthly_mosiler from '../../img/menuevent/monthlymosiler/top_monthly_mobile.png'

import ico_alcohol from '../../img/ico_alcohol.svg'
import ico_sale from '../../img/ico_sale.svg'
import ico_general from '../../img/ico_general.svg'
import ico_map_bookmark from '../../img/ico_map_bookmark.svg'

import icon_kids from '../../img/icon_kids.png'
import icon_leisure from '../../img/icon_leisure.svg'
import icon_silver from '../../img/icon_silver.png'

import iu from '../../img/partner/iu.png'
import Wonbin from '../../img/partner/Wonbin.png'






const NOTICE_LINK_STATE_MAIN_MENU = '0';
const NOTICE_LINK_STATE_NOTICE = '1';

const DEVICE_VER_HIGH = 1;      // deviceVer가 더 크면 1
const SERVER_VER_HIGH = -1;     // serverVer가 더 크면 -1
const SAME_VER = 0;             // 버전이 서로 같다면 0

class ReserveView extends Component {
    state = {

        loading: 0,
        visibleReserve: false,
        visibleLive: false,
        name: store.getState().global_data.loginedData.CustomerName,
        phone: store.getState().global_data.loginedData.PhoneNumber,
    }

    intervalData = null;

    chkVersion = (deviceVer, serverVer) => {
        // deviceVer가 더 크면 1
        // serverVer가 더 크면 -1
        // 버전이 서로 같다면 0
        console.log(compareVersions(deviceVer, serverVer))
        return compareVersions(deviceVer, serverVer);
    }


    async componentDidMount() {
        try {
            this.setState({ loading: 0 })

            let { cookies } = this.props;
            let { drivingTypeData } = store.getState().DrivingType_ReduxModule
            let { CustomerNo } = store.getState().global_data.loginedData
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            let fcmtoken = await RnInterface.getData('fcmtoken')

            // 1.3.20부터 이부분 처리가 무의미하다.
            // 카톡앱을 딥링크로 열기만 하기 때문에 고객앱측에서 카메라, 마이크 권한을 물어볼 필요가 없다.
            // this.intervalData = setInterval(()=>{
            //     console.log('setInterval()');
            //     if (document.querySelector('#ch-plugin-launcher') !== null) {
            //         clearInterval(this.intervalData);
            //          document.querySelector('#ch-plugin-launcher').onclick= async ()=>{
            //              await RnInterface.callReactNative('channelIOCameraPermissionRequest')
            //          }
            //     }
            // },1000)
            
            
            await RnInterface.callReactNative('loginedStatusBarChange') // 메뉴 화면으로 들어갔다면 native측에 스테이터스 바 색 변경을 진행하라고 요청 보낸다.
            

            // 예약 종류 데이터 취득. (일반, 골프, 공항홈발렛 등등..)
            // 메인 메뉴 화면을 API호출받아서 표시하는 것에 의존하다보면
            // 정작 API호출 안되었을때는 데이터가 취득이 안될 것이 염려되어
            // 메인화면에서는 사용하지 않고, 다른 곳에서 쓰일 예정.
            if (!drivingTypeData.length) {
                await store.dispatch(getDrivingTypeRedux(
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                ));
            }

            let data = await ReserveApi.GetDrivingReservationList(
                CustomerNo,
                1, 10, 10,
                cookies.get('ostype'),
                cookies.get('deviceid'),
                cookies.get('login_encrypt')
            )
            if (data && data.Result === 'success') {
                this.setState({
                    visibleReserve: data.ItemList.length ? true : false,
                    visibleLive: data.LiveList.length ? true : false
                })
            }

            // Native측에서 토큰 처리를 async await하게 되면 먹통이 될 가능성이 있어서
            // client에서 처리하는 것이 나아보인다. 더 좋은 방안이 있다면 검토후 진행. 
            // device에서 온 토큰 값이 false가 아닐때 (false로 오는경우 - PC에서 테스트할경우)
            if (fcmtoken !== false) {
                let lstoken = localStorage.getItem('currentFcmToken')
                
                // 로그아웃하면, currentFcmToken 삭제된다. 그 이후, 로그인시
                // 아래 조건이 참이되어 setFcmToken을 통해 한번만 토큰을 갱신해준다.
                // 이후, 다른 화면 들렸다가 다시 메인으로 갔을 경우에는 호출되지 않도록 조치.
                if (fcmtoken !== lstoken) {
                    try {
                        // fcmtoken -> setFcmToken으로 함수명 변경
                        let fcmtokenResult = await AccountApi.setFcmToken(CustomerNo,fcmtoken,cookies.get('ostype'),cookies.get('deviceid'),cookies.get('login_encrypt'))
                        if (fcmtokenResult.Result === 'success') localStorage.setItem('currentFcmToken', fcmtoken)
                    }
                    catch(ee){
                        console.log(ee)
                    }
                }
                
            }
            
            // 예약 화면을 나가고 메인화면일때 주소, 시간 내용을 지워야함.
            store.dispatch(init_location_selector_service());       // 메인 화면 > 일반/골프 예약 > 출발지 종료지 경유지 선택 관련 store
            store.dispatch(init_time_all_selector_service());       // 메인 화면 > 일반/골프 예약 > 시간 선택 store
            store.dispatch(empty_use_state_service());              // 메인 화면 > 메뉴 > 예약현황 store
            store.dispatch(empty_use_history_service());            // 메인 화면 > 메뉴 > 이용기록 store

            // 예약 화면을 나가고 메인화면일때 아래 항목을 지워야함.
            localStorage.removeItem('reserve_temp_date')
            localStorage.removeItem('reserve_temp_startTime')
            localStorage.removeItem('reserve_temp_endTime')
            localStorage.removeItem('reserve_temp_carLocation')
            localStorage.removeItem('reserve_temp_comment')
            localStorage.removeItem('reserve_temp_passengerphone')
            localStorage.removeItem('reserve_temp_passengername')
            localStorage.removeItem('reserve_temp_type')
            localStorage.removeItem('reserve_temp_ReseveCharge')
            localStorage.removeItem('reserve_temp_carNo')
            localStorage.removeItem('reserve_temp_carModel')
            localStorage.removeItem('reserve_temp_selectHour')
            localStorage.removeItem('reserve_temp_covidKit')
            localStorage.removeItem('reserve_view_all_state')
            localStorage.removeItem('reserve_step2_backup')
            localStorage.removeItem('reserve_step2_backup2')
            
            
            
            

            // 예) 모바일에서 모시러 홈페이지 접속 > 메인화면에서 "골프예약하러가기" 클릭 > 딥링크 실행 > 앱 실행 > http://{URL}/linktoappview=reserve/3 페이지로 이동 > 
            // LoginCheck후 linktoappview라는 qs가 존재하면 우선 /main들렸다가 /reserve/3으로 이동해야하는데 ("페이지 이동시 main을 거쳐야 하는 이유" 를 참고.)
            // 이때, /main에서는 공지사항, 앱 업데이트 alert가 뜬다. 이부분을 alert안뜨게 처리해야한다.
            // 안그러면 reserve/3으로 이동했을때 main화면의 await alert에 의해 reserve/3 동작이 되지 않기 때문.. 
            // reserve/3 페이지가 뜰때 골프 얼리버드 alert가 떠야 하는데 그걸 씹어버리고, main의 공지 alert가 표시되어버린다.
            // reserve/3 입장에서는 는 골프 얼리버드 alert를 닫기전까지 무한로딩 상태가 된다.
            // 그걸 치고 main의 공지 alert가 표시되어버린다.
            
            // * 페이지 이동시 main을 거쳐야 하는 이유
            // 이렇게 처리하는 이유는 history를 남기기 위해서이다. 만약, main화면으로 안가고 바로 reserve/3으로 갈경우, 뒤로가기 눌렀을때 어디로 가야할지 알 수 없게 된다.
            // 따라서 어디 이동할때는 main을 거쳐서 이동하는 처리가 반드시 필요하다.

            let deeplinkProcessFlag = 0;
            try {
                const {linktoappviewflag} = this.props.location.state
                deeplinkProcessFlag = 1;
            }
            catch(e){   }
            
            if (!deeplinkProcessFlag) {
                // 공지사항 팝업
                /*
                1. 확인버튼 누르면 세션이 유지될때까지는 다시 표시되면 안됨
                2. 다시 보지 않기를 누르면 localStorage에 현재 noticeno값을 저장한다.
                */
                let noticePopupLSFlag = 
                    localStorage.getItem('mosiler_mainmenu_noticepopup_noticeno') !== null ? 
                    Number(localStorage.getItem('mosiler_mainmenu_noticepopup_noticeno')) : -1;

                let noticePopupSSFlag = 
                    sessionStorage.getItem('mosiler_mainmenu_noticepopup_noticeno') !== null ? 
                    Number(sessionStorage.getItem('mosiler_mainmenu_noticepopup_noticeno')) : -1;
                
                let noticeResult = await NoticeApi.CustomerNotice(
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                )
                
                // console.log('공지사항 팝업 result : ',noticeResult);
                if (noticeResult && noticeResult.Result === 'success') {
                    
                    // 다시 보지 않기를 눌렀을 때에는 다시 표시되어선 안된다.
                    if (noticePopupLSFlag === noticeResult.Item.NotiNo) {

                    }
                    // 최초 앱 실행시 공지 팝업 떴을때 확인 or 배경을 클릭한 이후일때에도 표시되어선 안된다.
                    else if (noticePopupSSFlag !== -1) {
                        
                    }
                    else {
                        // 다시 보지 않기 클릭시 false, 확인 및 배경 클릭시 true
                        let booleanResult = await calertNoticePopup(noticeResult.Item.Contents, noticeResult.Item.Link, `/notice_link?link=${noticeResult.Item.Link}&viewstate=${NOTICE_LINK_STATE_MAIN_MENU}`, noticeResult.Item.NotiNo);
                        if (booleanResult) {
                            sessionStorage.setItem('mosiler_mainmenu_noticepopup_noticeno',noticeResult.Item.NotiNo )
                        } 
                        else {
                            localStorage.setItem('mosiler_mainmenu_noticepopup_noticeno',noticeResult.Item.NotiNo )
                        }
                    }               
                } 
                // 공지사항이 없거나 그 외의 문제
                else {

                }
                // 기기에서 디바이스 버전을 취득한다.
                let deviceVer = await RnInterface.getData('getDeviceVersion');

                // localStorage에 기기 디바이스 버전을 저장한다.
                localStorage.setItem('device_app_version',deviceVer)
                
                // 서버에서 기기버전이 최신버전인지 여부를 확인한다.
                let tmpVersionCheck = await AppSettingApi.GetVersionCheck(
                    (deviceVer === false ? '0.0.0' : deviceVer),
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                )
                
                let applatestVerFlag = 
                    sessionStorage.getItem('appLatestVersionFlag') !== null ? 
                        Number(sessionStorage.getItem('appLatestVersionFlag')) : -1;

                // tmpVersionCheck = {
                //     Item : {
                //         Version:'1.3.10'
                //     }
                // }
                // deviceVer = '1.3.00'
                
                // 서버 버전이 높다면 업데이트가 필요하다.
                if ( tmpVersionCheck.Item !== undefined && (this.chkVersion(deviceVer, tmpVersionCheck.Item.Version) === SERVER_VER_HIGH)  ) {
                    //sessionStorage가 존재하지 않다면
                    if (applatestVerFlag === -1) {
                        // alert 표시
                        if (await calertConfirm(`현재 앱 최신 버전은 ${tmpVersionCheck.Item.Version} 입니다.\n앱을 업데이트하면\n원활한 이용이 가능합니다.\n앱 업데이트를 진행하겠습니까?\n(업데이트가 안될경우 앱 제거후 다시 설치해주세요.)`)) {
                            window.open(tmpVersionCheck.Item.StoreUrl);
                        }
                        // 앱이 꺼질때까지 alert가 표시되지 않는다.
                        sessionStorage.setItem('appLatestVersionFlag', '1');
                    }
                    
                }
            }           

            this.setState({ loading: 1 })
        } catch (e) {
            this.setState({ loading: 1 })
        }


    }

    handleCallMenu = () => {
        // this.props.history.replace('/myinfo')
        document.getElementById('slide_trigger').style.marginLeft = '0vw';

    }

    handleCloseMenu = (e) => {
        e.preventDefault();
        document.getElementById('slide_trigger').style.marginLeft = '-100vw';
    }

    handleClose = (e) => {
        e.preventDefault();
        this.props.history.replace('/main');
    }

    handleGotoMemberInfoChange = (e) => {
        e.preventDefault();
        this.props.history.push('/changeinfo');
    }

    handleGotoReserve = (e) => {
        e.preventDefault();
        this.props.history.push('/reserve');
    }
    
    handleGotoUseState = (e) => {
        e.preventDefault();
        this.props.history.replace('/use_state');
    }
    
    handleGotoUseHistory = (e) => {
        e.preventDefault();
        this.props.history.replace('/use_history');
    }

    handleGotoCard = (e) => {
        e.preventDefault();
        this.props.history.push('/card');
    }

    handleGotoNotice = (e) => {
        e.preventDefault();
        this.props.history.replace('/notice');
    }
    
    handleGotoSetting = (e) => {
        e.preventDefault();
        this.props.history.replace('/setting');
    }

    handleGotoCoupon = (e) => {
        e.preventDefault();
        // calert('대응중')
        this.props.history.replace('/coupon');
    }

    handleGotoTicket = (e) => {
        e.preventDefault();
        // calert('대응중')
        this.props.history.replace('/ticket');
    
    }
    
    handleGotoFriendShare = (e) => {
        e.preventDefault();
        // calert('대응중')
        this.props.history.replace('/invite');
    }

    handleCallCenter = async (e) => {
        e.preventDefault();
        await RnInterface.callReactNative('callTelephone15224556')
    }

    handleGotoHome = () => { this.props.history.replace('/main') }

    render() {
        const linkStyle={
            'a:active': ''
//             * 마우스를 클릭하고 있는 동안 : active
// * 마우스를 한번 클릭 한 이후 : visited



        }
        const nodeStyle = {
            width:'80px', 
            height:'80px',
            textAlign:'center',             
            display:'inline-block'
        }
        
        const nodeStyleHidden = {
            visibility: 'hidden',
            width:'80px', 
            height:'80px',
            textAlign:'center',             
            display:'inline-block'
        }
        
        const componentStyle = {
            display:'flex',
            justifyContent:'space-between',
            padding:'10px 0px'
        }

        const iconMenuCom = (name, iconfile, link) => {
            const iconStyle = {borderRadius:'10px', background:'#efefef', padding:'5px', width:'40px', height:'40px'}
            if (name === 'Dummy') {
                return <div style={nodeStyleHidden}>
                <div style={{paddingBottom:'7px'}}>
                    <img src={iconfile} style={iconStyle}/>
                </div>
                <div style={{fontSize:'0.85em'}}>{name}</div>
            </div>
            }
            return <NavLink to={`${link}`} >
            <div style={nodeStyle}>
                <div style={{paddingBottom:'7px'}}>
                    <img src={iconfile} style={iconStyle}/>
                </div>
                <div style={{fontSize:'0.85em'}}>{name}</div>
            </div>
        </NavLink>
            
        }
        

        return (<>
            <MainMenuMyInfoCss />
            <div id="slide_trigger" className="slide_menu_container">
                <div className="menu_container" >
                    <div className="menu_containter_header">
                        <span className="btn_setup" onClick={this.handleCloseMenu}>
                            <NavLink to="#" className="btn_link">
                                <img src={btn_cancel} alt="" /></NavLink>
                        </span>
                        <span className="member_info">
                            <span className="name">
                                {this.state.name}님
                                </span>
                            <span className="tel">
                                {Util.getPhoneNumToHypen(this.state.phone)}
                            </span>
                        </span>
                        <span className="mb_modify">
                            <NavLink to="#" onClick={this.handleGotoMemberInfoChange} >
                                회원정보변경
                                    <img src={btn_arrow2} alt="" />
                            </NavLink>
                        </span>
                    </div>
                    <div className="menu_seperator">

                    </div>
                    <div className="menu_containter_menu">
                        <NavLink to="/use_state" className="sl_menu">
                            <img src={icon_m1} className="m_icon" alt="" /> 예약현황               
                            <img src={btn_arrow} className="btn_arrow" alt="" />
                        </NavLink>
                        <NavLink to="/use_history" className="sl_menu">
                            <img src={icon_m2} className="m_icon" alt="" /> 이용기록               
                            <img src={btn_arrow} className="btn_arrow" alt="" />
                        </NavLink>
                        <NavLink to="/card" className="sl_menu">
                            <img src={icon_m3} className="m_icon" alt="" /> 카드관리               
                            <img src={btn_arrow} className="btn_arrow" alt="" />
                        </NavLink>
                        <NavLink to="/coupon" className="sl_menu">
                            <img src={icon_m4} className="m_icon" alt="" /> 쿠폰관리               
                            <img src={btn_arrow} className="btn_arrow" alt="" />
                        </NavLink>
                        <NavLink to="/ticket" className="sl_menu">
                            <img src={icon_m5} className="m_icon" alt="" /> 충전권구매               
                            <img src={btn_arrow} className="btn_arrow" alt="" />
                        </NavLink>
                        <NavLink to="/invite" className="sl_menu">
                            <img src={icon_m6} className="m_icon" alt="" /> 친구초대               
                            <img src={btn_arrow} className="btn_arrow" alt="" />
                        </NavLink>
                        <NavLink to="/voc" className="sl_menu">
                            <img src={icon_m7} className="m_icon" alt="" /> 고객의소리               
                            <img src={btn_arrow} className="btn_arrow" alt="" />
                        </NavLink>
                        <NavLink to="/notice" className="sl_menu">
                            <img src={icon_m8} className="m_icon" alt="" /> 공지사항               
                            <img src={btn_arrow} className="btn_arrow" alt="" />
                        </NavLink>
                        <NavLink to="/setting" className="sl_menu">
                            <img src={icon_m9} className="m_icon" alt="" /> 설정                   
                            <img src={btn_arrow} className="btn_arrow" alt="" />
                        </NavLink>
                        <NavLink to="/terms" className="sl_menu">
                            <img src={icon_m12} className="m_icon" alt="" /> 모시러 약관
                            <img src={btn_arrow} className="btn_arrow" alt="" />
                        </NavLink>
                    </div>
                    
                    {/* 요청에 의해 주석처리했다. 차후에 부활할 가능성이 있기 때문에 주석처리 해놓는다.
                    <div className="menu_containter_footer">
                        <span className="cs_box"><span className="title">고객센터</span><span className="tel"><Link to='#' onClick={this.handleCallCenter}>1522-4556</Link></span></span>
                        <span className="cs_box"><span className="title">운영시간</span><span className="time">평일 10:00~18:00(주말, 공휴일 제외)</span></span>
                    </div> */}

                </div>
            </div>

            <HeaderMain
                title="모시러예약"
                onBackButton={this.handleCallMenu}
                onHomeButton={this.handleGotoHome}
            />
            {!this.state.loading ? <Loading /> : ''}

            <main id="Contents" className="">
                <div className="booking_box_new">
                    <ul>
                        <li>
                            <NavLink to="/common_link?redirect=http://mosiler.com/monthly_app.html&title=월간 모시러 소개" className="box booking">
                                <div className="box-inner2">
                                    <div className="view_text_absolute">
                                        <span className="text_title">월간 모시러 소개</span><br/>
                                        <span className="text_sub">(Monthly Mosiler)</span>
                                        
                                    </div>
                                    <div className="box-img"><img src={event_monthly_mosiler} alt="" /></div>
                                </div>
                            </NavLink>

                            {/* Live먼저 체크 후 true면 라이브 표시 */}
                            {this.state.visibleLive ? (
                                <NavLink to="/realtime_drive"  className="box booking">
                                    <span class="bk_head">
                                        <span class="bk_name">실시간 위치확인</span>
                                        <span class="btn_style2"><img src={icon_map_car} alt="" /></span>
                                    </span>
                                    <span class="bk_bg2 bg_img0"></span>
                                </NavLink>
                            ) : (
                                <>
                                    {this.state.visibleReserve ? (
                                        <NavLink to="/use_state" class="box booking">
                                            <span class="bk_head">
                                                <span class="text-large text-bold">예약 정보 확인</span>
                                                <span class="btn_style2"><img src={icon_car_search} alt="" /></span>
                                            </span>
                                            <span class="bk_bg2 bg_img6"></span>
                                        </NavLink>
                                    ) : null}

                                </>
                            )}


                            
                            
                            <h2 className="text-larger text-bold" style={{paddingTop:'20px'}}>아이들, 어르신을 모시러!</h2>
                            <div style={componentStyle}>
                                {iconMenuCom('키즈예약', icon_kids, '/reserve_renew/1/1')}
                                {iconMenuCom('실버예약', icon_silver, '/reserve_renew/1/1')}
                                {iconMenuCom('일반예약', ico_alcohol, '/reserve_renew/1/1')}
                                {iconMenuCom('레저예약', icon_leisure, '/reserve_renew/3/1')}
                            </div>
                            <h2 className="text-larger text-bold">자유로운 고객들을 모시러!</h2>
                            <div style={componentStyle}>
                                {iconMenuCom('일반예약', ico_alcohol, '/reserve_renew/1/1')}
                                {iconMenuCom('레저예약', icon_leisure, '/reserve_renew/3/1')}
                                {iconMenuCom('Dummy')}
                                {iconMenuCom('Dummy')}
                            </div>
                            <h2 className="text-larger text-bold">출국, 귀국 고객들을 모시러!</h2>
                            <div style={componentStyle}>
                                {iconMenuCom('공항홈발렛', ico_alcohol, '/reserve_renew/1/1')}
                                {iconMenuCom('Dummy')}
                                {iconMenuCom('Dummy')}
                                {iconMenuCom('Dummy')}
                            </div>
                            
                            <h2 className="text-larger text-bold">이달의 파트너!!</h2>

                            <NavLink to="/common_link?redirect=http://mosiler.com/monthly_app.html&title=월간 모시러 소개" className="box booking">
                                <div className="box-inner2">
                                    <div className="view_text_absolute">
                                        <span style={{
                                            textAlign:'right',
                                            display:'block',
                                            color:'#fff',
                                            fontSize:'1.8em',
                                            fontWeight: 'bold',
                                            textShadow: '1px 1px 2px black',
                                            marginBottom:'-13px'
                                        }}>어르신에게 편안함을!</span><br/>
                                        <span style={{
                                            textAlign:'right',
                                            display:'block',
                                            color:'#fff',
                                            fontSize:'1.3em',
                                            fontWeight: 'bold',
                                            textShadow: '1px 1px 2px black',
                                            
                                        }}>아이유 파트너 인터뷰</span>
                                        
                                    </div>
                                    <div className="box-img"><img src={iu} alt="" /></div>
                                </div>
                            </NavLink>
                            <NavLink to="/common_link?redirect=http://mosiler.com/monthly_app.html&title=월간 모시러 소개" className="box booking">
                                <div className="box-inner2">
                                    <div className="view_text_absolute">
                                        <span style={{
                                            textAlign:'right',
                                            display:'block',
                                            color:'#fff',
                                            fontSize:'1.8em',
                                            fontWeight: 'bold',
                                            textShadow: '1px 1px 2px black',
                                            marginBottom:'-13px'
                                        }}>키즈는 나에게 맡겨!</span><br/>
                                        <span style={{
                                            textAlign:'right',
                                            display:'block',
                                            color:'#fff',
                                            fontSize:'1.3em',
                                            fontWeight: 'bold',
                                            textShadow: '1px 1px 2px black',
                                            
                                        }}>원빈 파트너 인터뷰</span>
                                        
                                    </div>
                                    <div className="box-img"><img src={Wonbin} alt="" /></div>
                                </div>
                            </NavLink>
{/*                             
                            

                            <NavLink to="/reserve_renew/3/1" className="box booking">
                                <div className="box-inner">
                                    <div className="grid cross-center">
                                        <div className="col col-8"> <h2 className="text-larger text-bold">레저 테스트</h2> </div>
                                        <div className="col right"> <button type="button" className="btn medium primary text-bold"><span>예약하기</span></button> </div>
                                    </div>
                                </div>
                            </NavLink>
                            
                            <NavLink to="/reserve/1" className="box booking">
                                <div className="box-inner">
                                    <div className="grid cross-center">
                                        <div className="col col-8">
                                            <h2 className="text-larger text-bold">일반</h2>
                                            <p className="text-medium">고객의 마음까지 운전합니다.</p>
                                        </div>
                                        <div className="col right">
                                            <button type="button" className="btn medium primary text-bold"><span>예약하기</span></button>
                                        </div>
                                    </div>						
                                </div>
                                <div className="box-img"><img src={visual_general} alt="" /></div>
                            </NavLink>
                            

                            <NavLink to="/reserve/3" className="box booking">
                                <div className="box-inner">
                                    <div className="grid cross-center">
                                        <div className="col col-8">
                                            <h2 className="text-large text-bold">레저(스키,골프,승마)</h2>
                                            <p className="text-medium">레저 운행 최고의 파트너</p>
                                        </div>
                                        <div className="col right">
                                            <button type="button" className="btn medium primary text-bold"><span>예약하기</span></button>
                                        </div>
                                    </div>						
                                </div>
                                <div className="box-img">
                                    <Fade easing="ease" arrows={false} duration={3000} slidesToShow={1} slidesToScroll={1}>
                                        <div> <img src={visual_ski} style={{width:'100%'}}  alt="" /> </div>
                                        <div> <img src={visual_horse} style={{width:'100%'}}  alt="" /> </div>
                                        <div> <img src={visual_golf2} style={{width:'100%'}}  alt="" /> </div>
                                    </Fade>
                                </div>
                            </NavLink>
                            
                            <NavLink to="#" className="box booking" onClick={async (e)=>{
                                e.preventDefault();
                                await calertPromise('오픈 준비중입니다.')
                            }}>
                                <div className="box-inner">
                                    <div className="grid cross-center">
                                        <div className="col col-8">
                                            <h2 className="text-larger text-bold">블랙</h2>
                                            <p className="text-medium">모시러 프리미엄 서비스</p>
                                        </div>
                                        <div className="col right">
                                            <button type="button" className="btn medium primary text-bold"><span>예약하기</span></button>
                                        </div>
                                    </div>						
                                </div>
                                <div className="box-img"><img src={visual_black} alt="" /></div>
                            </NavLink>                              */}
                        
                        </li>
                    </ul>
                </div>
            </main>
        </>
        );
    }
};

export default withCookies(ReserveView);
