import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

// import AgreeButton from '../components/MemberJoin/AgreeButton';
// import AgreeButtonContainer from '../containers/MemberJoin/AgreeButtonContainer';
import icon_arrow from '../../img/login/icon_arrow.svg';
import {calert, calertPromise} from '../../lib/Util';
import RnInterface from '../../lib/RnInterface'
import HeaderJoin2 from '../../components/Common/HeaderJoin2'
import MemberJoinCustomCss from '../../css/CustomGlobalStyle/MemberJoinCustom'
import Modal from 'react-modal'

import '../../css/default.css';
import '../../css/member.css';

import btn_prev from '../../img/btn/btn_prev.svg';
import ico_quest from '../../img/ico_quest.svg';




class MemberJoin1View extends Component {
    state = {
        check_status: [false,false,false,false, false],
        modalOpenFlag: false,
    };

    handleChangeModalStatusTrue = () => {
        this.setState((prevState) => ({ modalOpenFlag : true }));
    }

    handleChangeModalStatusFalse = () => {
        this.setState((prevState) => ({ modalOpenFlag : false }));
    }

    convertStrToBool = (splitArr) => {
        let tmpArr = [];
        for(let i in splitArr) {
            tmpArr.push(splitArr[i] === 'true' ? true : false)
        }
        return tmpArr;
    }

    async componentDidMount() {
        try {
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            let lStoData = localStorage.getItem('check_status');
            if (lStoData !== null) this.setState({check_status: this.convertStrToBool(lStoData.split(','))})
            
        }
        catch(e){
            console.log(e)
        }
        
        
    }

    handleChangeAllCheck = (e) => {
        let checkResult = e.target.checked;
        this.setState((prevState)=>{
            return {
                check_status: [checkResult,checkResult,checkResult,checkResult, checkResult]
            }
        })

    }

    handleChangeCheck = (e) => {
        let stateCopy = this.state.check_status;
        let idx = parseInt(e.target.id);

        stateCopy[idx] = e.target.checked;

        
        if (stateCopy[1] && stateCopy[2] && stateCopy[3] && stateCopy[4]) {
            stateCopy[0] = true;
        } else {
            stateCopy[0] = false;
        }

        this.setState({
            check_status: stateCopy
        })
    }

    handleAgree = async () => {
        let stateCopy = this.state.check_status;
        if (stateCopy[1] && stateCopy[2] && stateCopy[3] && stateCopy[4]) {
            localStorage.setItem('check_status', this.state.check_status);
            // 친구 코드 등록 기능은 2019년 일시적인 이벤트였던 모양. 애덤이 지우라해서 지움.
            // this.props.history.push('/join/2');
            
            this.props.history.push('/join/3');
        } else {
            
            await calertPromise('필수 약관에 동의해 주세요.')
            
        }
    }

    handlePrevBtn = () => {
        this.props.history.goBack();
    }


    render() {
        return (
            <>
                <MemberJoinCustomCss />
                <HeaderJoin2
                    onBackButton={this.handlePrevBtn}
                    title={''} />
                <main id="Contents" className="member_form"> 
                    <div className="key-visual-wrap">
                        <div className="key-visual-container">
                            <div className="key-contents">
                                <div className="key-text">
                                    회원가입을 위해 <strong>약관에 동의해 주세요.</strong>
                                </div>
                            </div>
                        </div>
                    </div>  
                    <ul className="ma_box2">
                        <li className="agree_form">  
                            <span className="af_1 af_1_bline">
                                <span className="member_join_1_main_container">
                                    <span className="member_join_1_flex_container">
                                        <span className="item1">
                                            <label className="chk_box2">
                                                <input ref={input => this.inputAll = input} type="checkbox" onChange={this.handleChangeAllCheck} value={this.state.check_status[0]} checked={this.state.check_status[0]} />
                                                <span></span>
                                            </label>
                                        </span>
                                        <span className="item1" onClick={()=>{this.inputAll.click()}}>
                                            &nbsp;&nbsp;모든 이용약관 확인 및 전체동의
                                        </span>
                                        <span className="item2_hidden">
                                        <NavLink to="/join_terms/service" className="btn_arrow">
                                            <img src={icon_arrow} alt="" />
                                        </NavLink>
                                        </span>
                                    </span>
                                </span>
                            </span>

                            {/* <AgreeButtonContainer />
                            <AgreeButton title="(필수) 서비스 이용약관 동의"/> */}

                            <span className="af_1">
                                <span className="member_join_1_main_container">
                                    <span className="member_join_1_flex_container">
                                        <span className="item1 nobold">
                                            <label className="chk_box2 ">
                                            <input ref={input => this.inputone = input} type="checkbox" id="1" onChange={this.handleChangeCheck} value={this.state.check_status[1]} checked={this.state.check_status[1]}/>
                                                <span></span>
                                            </label>
                                        </span>
                                        <span className="item1 nobold" onClick={()=>{this.inputone.click()}}>
                                            &nbsp;&nbsp;(필수) 서비스 이용약관 동의
                                        </span>
                                        <span className="item2">
                                        <NavLink to="/join_terms/service" className="btn_arrow">
                                            <img src={icon_arrow} alt="" />
                                        </NavLink>
                                        </span>
                                    </span>
                                </span>
                            </span>

                            <span className="af_1">
                                <span className="member_join_1_main_container">
                                    <span className="member_join_1_flex_container">
                                        <span className="item1 nobold">
                                            <label className="chk_box2">
                                            <input ref={input => this.inputtwo = input} type="checkbox" id="2" onChange={this.handleChangeCheck} value={this.state.check_status[2]} checked={this.state.check_status[2]} />
                                                <span></span>
                                            </label>
                                        </span>
                                        <span className="item1 nobold" onClick={()=>{this.inputtwo.click()}}>
                                            &nbsp;&nbsp;(필수) 개인정보 수집 및 이용동의
                                        </span>
                                        <span className="item2">
                                        {/* <NavLink to="/join_terms/privacy" className="btn_arrow">
                                            <img src={icon_arrow} alt="" />
                                        </NavLink> */}
                                        <NavLink to="#" className="btn_arrow" onClick={this.handleChangeModalStatusTrue}>
                                            <img src={icon_arrow} alt="" />
                                        </NavLink>
                                        </span>
                                    </span>
                                </span>

                            </span>
                            <span className="af_2">
                                <span className="member_join_1_main_container">
                                    <span className="member_join_1_flex_container" style={{paddingRight:'0px'}}>
                                        
                                        <span className="item3 nobold">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <b>개인정보 수집</b>
                                                        </td>
                                                        <td style={{float:'right'}}>
                                                            {/* <NavLink to="#" onClick={this.handleChangeModalStatusTrue}>
                                                                <img src={ico_quest} alt="" style={{width:'20px'}}/>
                                                            </NavLink> */}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>목적</b></td>
                                                        <td>개인식별, 계약(서비스) 이행</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>항목</b></td>
                                                        <td>이름, 전화번호, 아이디, 비밀번호, 이메일, 주소</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>보유기간</b></td>
                                                        <td>회원탈퇴 즉시 또는 이용 목적 달성 즉시 파기</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </span>
                                        
                                    </span>
                                </span>

                            </span>
                            
                            <span className="af_1">
                            <span className="member_join_1_main_container">
                                    <span className="member_join_1_flex_container">
                                        <span className="item1 nobold">
                                            <label className="chk_box2">
                                            <input ref={input => this.inputthree = input} type="checkbox" id="3" onChange={this.handleChangeCheck} value={this.state.check_status[3]} checked={this.state.check_status[3]}/>
                                                <span></span>
                                            </label>
                                        </span>
                                        <span className="item1 nobold" onClick={()=>{this.inputthree.click()}}>
                                        &nbsp;&nbsp;(필수) 위치정보 수집 동의
                                        </span>
                                        <span className="item2">
                                        <NavLink to="/join_terms/location" className="btn_arrow">
                                            <img src={icon_arrow} alt="" />
                                        </NavLink>
                                        </span>
                                    </span>
                                </span>

                            </span>
                            <span className="af_1">
                            <span className="member_join_1_main_container">
                                    <span className="member_join_1_flex_container">
                                        <span className="item1 nobold">
                                            <label className="chk_box2">
                                            <input ref={input => this.inputfour = input} type="checkbox" id="4" onChange={this.handleChangeCheck} value={this.state.check_status[4]} checked={this.state.check_status[4]}/>
                                                <span></span>
                                            </label>
                                        </span>
                                        <span className="item1 nobold" onClick={()=>{this.inputfour.click()}}>
                                        &nbsp;&nbsp;(필수) 본인은 만 14세 이상입니다.
                                        </span>
                                        <span className="item2">
                                        <NavLink to="#" className="btn_arrow">
                                            
                                        </NavLink>
                                        
                                        </span>
                                    </span>
                                </span>

                            </span>
                        </li>
                        {/* <li className="btn_box">
                            <button className="btn_st_f1f0f5 btn_st_bmargin" onClick={this.handlePrevBtn}>이전</button>
                            <button className="btn_st_2e303f" onClick={this.handleAgree}>동의하기</button>
                        </li> */}
                    </ul>
                    <div className="component-wrap">
                        <div className="btn-area btn-group-fixed vertical">
                            <button type="button" className="btn large secondary" onClick={this.handleAgree}><span>동의하기</span></button>
                        </div>
                    </div>                      
                </main>  
                <Modal
                    isOpen={this.state.modalOpenFlag}
                    onRequestClose={this.handleChangeModalStatusFalse}
                    contentLabel="Example Modal" 
                    overlayClassName="modal-overlay"
                    className="modal-box-top"
                    bodyOpenClassName="modal-scrolllock"
                >
                    <section className="modal-container">
                        <div className="modal">
                            <div className="modal-head">
                                <h1 className="modal-tit" tabindex="0">개인정보 수집 및 이용동의(필수)</h1>
                            </div>
                            <div className="modal-body">
                                <div className="modal-contents">
                                    <div className="modal-contents-body">
                                        <div className="component-wrap">
                                            <div className="inner-container">
                                                <div className="tbl type1 mar-t10">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" style={{width:"40%"}}>수집 목적</th>
                                                                <th scope="col" style={{width:"30%"}} className='leftline'>수집 항목</th>
                                                                <th scope="col" style={{width:"30%"}} className='leftline'>보유 기간</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr >
                                                                <td>개인 식별 및 본인 여부 확인
                                                                    계약(서비스) 이행
                                                                    약관 변경 등의 고지를 위한 연락</td>
                                                                <td className='leftline'>이름, 전화번호, 아이디, 비밀번호(카카오 로그인 시 카카오 닉네임), 이메일</td>
                                                                <td rowSpan={5} className='leftline'>회원탈퇴 즉시 또는 이용 목적 달성 즉시 파기
                                                                    부정이용기록 탈퇴 후 1년간 보관</td>
                                                            </tr>
                                                            <tr>
                                                                <td>위치 기반 서비스 제공</td>
                                                                <td className='leftline'>주소, 위치정보(선택), IP주소</td>
                                                            </tr>   
                                                            <tr>
                                                                <td>부정 이용 방지, 비인가 사용 방지</td>
                                                                <td className='leftline'>방문일시, 부정 이용 기록, 서비스 이용 기록
                                                                    계약, 청약철회, 대금결제, 전자금융 거래, 분쟁처리 기록</td>
                                                            </tr>
                                                            <tr>
                                                                <td>차량번호 </td>
                                                                <td className='leftline'>차량번호 입력 시</td>
                                                            </tr>
                                                            <tr>
                                                                <td>사업자 회원 서비스 제공</td>
                                                                <td className='leftline'>대표자명, 사업자등록번호, 연락처</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                                           
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className="btn-top-close" onClick={this.handleChangeModalStatusFalse}>
                                <span className="blind">팝업 닫기</span>
                            </button>
                        </div>
                    </section>
                </Modal>
            </>
        );
    }
};

export default MemberJoin1View;
