import React, { Component } from 'react';
import {NavLink} from 'react-router-dom'
import NoticeApi from '../../lib/NoticeApi';
import { withCookies } from 'react-cookie';
import Header from '../../components/Common/Header'
import Loading from '../../components/Common/Loading'
import Util, { calert, calertPromise, isChkResultSuccess } from '../../lib/Util'
import store from '../../reduxStore'
import ReserveApi from '../../lib/ReserveApi';
import EstimateCss from '../../css/CustomGlobalStyle/Estimate'
import RnInterface from '../../lib/RnInterface'

import '../../css/default.css';


import icon_user from '../../img/rating/icon_user.svg'
import icon_tel from '../../img/rating/icon_tel.svg'
import icon_star from '../../img/rating/icon_star.svg'
import icon_sms from '../../img/rating/icon_sms.svg'

const STATE_SET_ESTIMATE = 0;
const STATE_GET_ESTIMATE = 1;
const TEXTAREA_MAX_LENGTH = 100;

/*
별 5개 줬으면
customerno: 12547
drivingno: 130627
drivingpoint: 5
counselpoint: 5
dresspoint: 5
opinion: ㅋㅋㅋㅋㅋ


customerno: 12547
drivingno: 130626
drivingpoint: 1
counselpoint: 1
dresspoint: 1
opinion: 전부 1점 줬습니다.

*/

class EstimateView extends Component {

    state = {
        itemList: [],
        loading: 0,
        driverNo: 0,
        grade: '5',
        text: '',
        CustomerNo: 0,
        drivingno: 0,
        detailData:{},
        drivingEstimateData:{}
    }

    isChkEstimatePath = () => {
        let retData = STATE_SET_ESTIMATE;
        if (this.props.history.location.pathname.indexOf('/get_estimate') !== -1) {
            retData = STATE_GET_ESTIMATE;
        }
        return retData;
    }

    async componentDidMount() {
        try {
            const { getinfo } = store.getState().UseHistory_ReduxModule
            const { CustomerNo } = store.getState().global_data.loginedData
            const { drivingno } = this.props.match.params;
            const { cookies } = this.props;

            await RnInterface.AndroidURLChange(this.props.location.pathname)

            let data = getinfo.filter((data) => { return data.DrivingNo === parseInt(drivingno) })
            let DriverNo = data[0].DriverNo
   
            let driverInfoResult = await ReserveApi.GetDriverInfo( DriverNo, cookies.get('ostype'), cookies.get('deviceid'), cookies.get('login_encrypt') )
            let drivingEstimateResult = await ReserveApi.DrivingEstimate( drivingno, cookies.get('ostype'), cookies.get('deviceid'), cookies.get('login_encrypt') )

            if (isChkResultSuccess(driverInfoResult) && isChkResultSuccess(drivingEstimateResult)) {
                if (this.isChkEstimatePath() === STATE_SET_ESTIMATE) {
                    this.setState({
                        itemList: driverInfoResult.Item,
                        driverNo: DriverNo,
                        loading: 1,
                        CustomerNo: CustomerNo,
                        drivingno: drivingno,
                        detailData: data[0],
                    })
                } else {
                    this.setState({
                        itemList: driverInfoResult.Item,
                        driverNo: DriverNo,
                        loading: 1,
                        CustomerNo: CustomerNo,
                        drivingno: drivingno,
                        detailData: data[0],
                        grade: Math.floor(driverInfoResult.Item.EstimateAvgPoint / 2),
                        text: drivingEstimateResult.Item.CustomerOpinion
                    })
                }
            } 
            else {
                if (isChkResultSuccess(driverInfoResult)) await calertPromise(driverInfoResult.Message)
                else if (isChkResultSuccess(drivingEstimateResult)) await calertPromise(drivingEstimateResult.Message)
                this.setState({ loading: 1, })
            }

        }
        catch (e) {
            await calertPromise('정보 취득 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
            console.log(e)
            this.setState({ loading: 1, })
        }

    }
    handleGrade = (e) => {
        this.setState({ grade: e.target.id })
    }
    handleText = (e) => {
        if (Util.getStringLength(e.target.value) <= TEXTAREA_MAX_LENGTH) {
            this.setState({ text: e.target.value })
        }
    }
    handleBack = () => {
        this.props.history.goBack();
    }
    handleHome = () => {
        this.props.history.replace('/main')
    }

    // EvaluationPaymentStateActions > OperationalDetailView > 
    // 
    handleSetEstimate = async () => {
        try {
            const { driverNo, grade, text, CustomerNo, drivingno, detailData } = this.state;
            const { cookies } = this.props;
            this.setState({loading:0})
            // 아래는 이전 코드의 addestimate 
            //const response = await instance.get(`/AddEstimate?customerno=${userInfo.CustomerNo}&drivingno=${drivingNo}&drivingpoint=${drivingPoint}&counselpoint=${drivingPoint}&dresspoint=${dressPoint}&opinion=no`);
            // opinion : 고객의견(text 사용)
            // drivingpoint, counselpoint, dresspoint : grade 사용

            let objData = {
                customerno: CustomerNo,
                drivingno: drivingno,
                drivingpoint: grade,
                opinion: text
            }

            let estimateResult = await ReserveApi.AddEstimate(
                objData,
                cookies.get('ostype'),
                cookies.get('deviceid'),
                cookies.get('login_encrypt')
            )

            if (estimateResult.Result === 'success') {
                await calertPromise('평가가 완료되었습니다.')


                // this.props.history.goBack();              // 이걸 할경우는 redux update해줘야하겠네.. 상세화면 로딩을 시켜줘야...
                this.props.history.replace('/main');      // 이걸 할경우는 크게 문제 없을듯.
            } else {
                await calertPromise(estimateResult.Message)
                this.setState({loading:1})
            }
        }
        catch (e) {
            console.log(e)
            await calertPromise('평가 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
            this.setState({loading:1})
        }

    }
    render() {
        const { itemList, loading, driverNo, text, grade, detailData } = this.state
        return (
            <>
                {!loading ? (<Loading />) : ''}
                <EstimateCss />
                <Header
                    title={this.isChkEstimatePath() === STATE_SET_ESTIMATE ? "평가하기" : '평가보기'}
                    onBackButton={this.handleBack}
                    onHomeButton={this.handleHome}
                />

                <div className="rating_form hd_margin_top">
                    <ul className="ma_box">
                        <li className="ma_notice">
                            본 평가는 모시러 품질 향상을 위해 익명으로 수집되며<br/>
                            파트너에게 전달되지 않습니다.
                        </li>
                        <li className="info_form">

                            <span className="user_profile">
                                <span className="profile">
                                    <img 
                                        style={{
                                            width:'170px',
                                            height:'170px',
                                            objectFit:'cover',
                                            borderRadius:'50%'
                                        }}
                                        src={itemList.ProfileImgName} 
                                        alt="" onError={(e)=>{e.target.src = icon_user}} />
                                    {/* {itemList.ProfileImgName} */}
                                    <span className="name">{itemList.UserName} 파트너</span>
                                    {/* <span className="number">보험번호 : {detailData.DriverSSN === null ? '없음' : detailData.DriverSSN}</span> */}

                                    <span className="quest">파트너의 서비스는 어떠셨나요?</span>
                                    {this.isChkEstimatePath() === STATE_SET_ESTIMATE ? (
                                        <span className="rating_box">
                                            <button value="1" className="rating_btn" onClick={this.handleGrade}><img id="1" src={icon_star} className={Number(grade) >= 1 ? '' : 'nostar'} alt="" /></button>
                                            <button value="2" className="rating_btn" onClick={this.handleGrade}><img id="2" src={icon_star} className={Number(grade) >= 2 ? '' : 'nostar'} alt="" /></button>
                                            <button value="3" className="rating_btn" onClick={this.handleGrade}><img id="3" src={icon_star} className={Number(grade) >= 3 ? '' : 'nostar'} alt="" /></button>
                                            <button value="4" className="rating_btn" onClick={this.handleGrade}><img id="4" src={icon_star} className={Number(grade) >= 4 ? '' : 'nostar'} alt="" /></button>
                                            <button value="5" className="rating_btn" onClick={this.handleGrade}><img id="5" src={icon_star} className={Number(grade) >= 5 ? '' : 'nostar'} alt="" /></button>
                                        </span>
                                    ) : (
                                        <span className="rating_box">
                                            <span className="rating_btn"><img id="1" src={icon_star} className={Number(grade) >= 1 ? '' : 'nostar'} alt="" /></span>
                                            <span className="rating_btn"><img id="2" src={icon_star} className={Number(grade) >= 2 ? '' : 'nostar'} alt="" /></span>
                                            <span className="rating_btn"><img id="3" src={icon_star} className={Number(grade) >= 3 ? '' : 'nostar'} alt="" /></span>
                                            <span className="rating_btn"><img id="4" src={icon_star} className={Number(grade) >= 4 ? '' : 'nostar'} alt="" /></span>
                                            <span className="rating_btn"><img id="5" src={icon_star} className={Number(grade) >= 5 ? '' : 'nostar'} alt="" /></span>
                                        </span>
                                    )}
                                </span>
                            </span>

                            {/* {this.isChkEstimatePath() === STATE_GET_ESTIMATE ? (
                                <span className="form_section">
                                    <span className="tel_box">
                                        <a href={`tel:${detailData.DriverPhoneNumber}`} className="btn_mobile"><img src={icon_tel} className="" alt="" /> 전화하기</a>
                                        &nbsp;&nbsp;
                                        <a href={`sms:${detailData.DriverPhoneNumber}`} className="btn_mobile"><img src={icon_sms} className="" alt="" /> 문자하기</a>
                                    </span>
                                </span>
                            ) : null} */}
                            

                            <span className="user_profile2">
                            <textarea 
                                value={text} 
                                onChange={this.handleText} 
                                placeholder={this.isChkEstimatePath() === STATE_SET_ESTIMATE ? "파트너의 서비스는 어땠는지 적어주세요." : ""} 
                                disabled={this.isChkEstimatePath() === STATE_GET_ESTIMATE} /> <br/>
                                
                                {this.isChkEstimatePath() === STATE_SET_ESTIMATE ? (
                                <span>
                                    {Util.getStringLength(text)}/{TEXTAREA_MAX_LENGTH} 
                                    {Util.getStringLength(text) >= TEXTAREA_MAX_LENGTH ? (<b> (100자 이상 입력할 수 없습니다.)</b>) : ''}
                                </span>
                                ) : null}
                                

                            </span>
                        </li>
                        

                        
                    </ul>
                </div>


                <footer>    
                {this.isChkEstimatePath() === STATE_SET_ESTIMATE ? (
                    <button className="btn_st_2e303f" onClick={this.handleSetEstimate}>확인</button>
                ) : (
                    <button className="btn_st_2e303f" onClick={this.handleBack}>확인</button>
                )}
                </footer>
            </>
        );
    }
};

export default withCookies(EstimateView);

