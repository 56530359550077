import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import axios from 'axios';
import { global_data_async, global_data_logout_async } from '../modules/Global/global_data'
import { getPost } from '../modules/Communication/post';
import AccountApi from '../lib/AccountApi'
import CookieApi from '../lib/CookieApi'
import Loading from '../components/Common/Loading'
import Intro2Css from '../css/CustomGlobalStyle/Intro2'
import store from '../reduxStore'
import Util from '../lib/Util'



import logo_w from '../img/logo/logo_w.svg'

/*
로그인 이후에 처리해야할 것(실기 입장에서)
1. 아래의 정보를 쿠키에 넣는다.
  - ID
  - hashcode(Password) - SHA1로 암호화 (Account Type이 Email일때만 체크한다.)
  - AccountType   -- kakao, email, apple
  - login_encrypt  -- login이후에 취득
  - ostype           -- login이후에 취득
  - deviceid         -- login이후에 취득

※ LoginCheck.js에서 해야할일
쿠키가 존재한다면?
    cookie정보를 취득
    쿠키 정보를 토대로 로그인 실시
        Account Type이 Email이라면?
            hashcode를 포함해서 아래를 요청
            GET EmailLogin?id={id}&pw={pw}&isforce={isforce}	
        Account Type이 kakao라면?
            id정보만을 활용해서 아래를 요청
            GET SnsLogin?id={id}&isforce={isforce}
        Account Type이 apple이라면?
            id정보만을 활용해서 아래를 요청
            GET AppleLogin?id={id}&isforce={isforce}	
    로그인에 성공 했다면
        로그인 response정보중 아래 정보를 쿠키에 삽입한다.    
        (login_encrypt, ostype, deviceid)
        메인 화면으로 이동 (/main)

    로그인에 실패 했다면
        로그인 화면으로 이동 (/login)

쿠키가 존재하지 않는다면?
    로그인 화면으로 이동 (/login)

*/
class LoginCheck extends Component {
    state = {
        initFlag: false,
        loginFlag: false,
        redirectUrl: '/login',
        loading: 0
    }

    isChkCookies = () => {
        let { cookies } = this.props.cookies;
        let ret = false;
        if (Object.keys(cookies).length >= 1) {
            ret = true;
        }
        return ret;
    }

    // 알림에 의해 native에서 url 변경을 요청할때 이곳에 들려서 체크하게 된다.
    // 로그인 되어 있다는 전제하에
    gotoRedirectUrl = () => {
        return new Promise((resolve, reject) => {
            try {
                let {redirect, linktoappview} = Util.getQueryStringObject();
                
                if(redirect !== undefined && redirect.length) {
                    resolve(`/${redirect}`)
                } else if (linktoappview !== undefined && linktoappview.length){
                    resolve(`linktoappview::/${linktoappview}`)
                } else {
                    resolve('/main')
                }
            }
            catch(e) {
                return reject(e)
            }
        })
        
    }


    asyncTest = async () => {
        let { cookies } = this.props.cookies;

        console.log('loginCheck cookie 확인');
        console.log(cookies);
        /* 
        {
            account_type: "email",
            auto_login_chk: "1",
            ch-session-10614: "eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzZXMiLCJrZXkiOiIxMDYxNC01ZmZkMDczOGIyZmJlMWViYmI1NiIsImlhdCI6MTYxMDYxMjk1MywiZXhwIjoxNjEzMjA0OTUzfQ.FE-PZSibFDooeAfFX5f2Es6RF4qPDew0IGu6I4JmOSI",
            ch-veil-id: "5de36f079b2f2d89",
            deviceid: "",
            email_id: "test4@test.com",
            hashcode: "7caa22a00c6c0b25f3058dbd015361a080addde1",
            login_encrypt: "0zXLGT8Ase2fdu3BfB6s52raXG1HaeBoUX+3/PebGJ5/IGgEJOP8Q8Kj6WpFjS1hw2N7AW2VBYJiyZHJIbDD1ZxivTKIsLVlTJHg9sVHyJInvEG3Ob6WNaLvYKPFmKN9a+Bve0pv87oEDNQKx2xeXA==",
            ostype: "A",
        }
            [전제] AccountApi.requestLogin를 사용하는 곳에서는 무조건 redux, localStorage, cookie 갱신을 해줘야한다. 상황에 따라 데이터를 다르게 줘야할 경우도 생김.

            * 로그인에 성공했을 경우
                1. 쿠키를 생성한다.
                2. localStorage에 쿠키 정보를 저장한다. 이름 : mosiler_cookie

            * 만약 쿠키가 존재하지 않을경우.
                1. localStorage에 mosiler_cookie가 null인지 아닌지를 확인
                2. null이라면 로그인 화면으로 이동
                3. null이 아니라면 login check -> 이상이 없으면 쿠키정보를 저장하고, localStorage에도 저장

            * 쿠키가 존재할경우
                1. 기존대로 처리 실시

            * 로그아웃했을 경우
                1. cookie 삭제
                2. localStorage에 있는 mosiler_cookie 삭제
        
        */
        try {
            // 쿠키가 존재하면
            if (this.isChkCookies()) {
                console.log('login 체크 실시')
                // 자동 로그인 flag가 1이라면
                if (Number(cookies.auto_login_chk)) {
                    // 로그인 체크
                    let loginResult = await AccountApi.requestLogin(cookies.email_id, cookies.hashcode, cookies.account_type, cookies.ostype, cookies.deviceid);

                    if (loginResult.Result === 'success') {
                        console.log(loginResult);
                        
                        // 로그인 성공시 쿠키 6개 값(자동로그인 체크 포함)은 그대로 집어넣는다. 단, loginEncrypt만 빼고
                        // 리덕스는 걍 그대로 통째로 넣는다.
                        // localStorage도 쿠키와 동일하게. 넣어놓는다.
                        AccountApi.LoginSuccessUpdateLoginInfo(loginResult, this.props.cookies)
                        
                        // this.props.history.replace('/main')
                        let url = await this.gotoRedirectUrl();

                        // 모바일에서 링크를 클릭하여 앱을 실행했을때 리다이렉트 하기 위한 조치.
                        if (url.indexOf('linktoappview::') > -1) {
                            this.props.history.replace({
                                pathname:'/main',
                                state: {
                                    linktoappviewflag:1
                                }
                            })
                            this.props.history.push(url.replace('linktoappview::',''))
                        } 
                        else {
                            this.props.history.replace(url)
                        }
                        
                    }
                    else {
                        console.log('Login Failed');
                        this.props.history.replace('/login')
                    }
                } 
                // 자동 로그인 flag가 0이라면
                else {
                    // 로그인 관련 데이터 전부 삭제
                    await store.dispatch(global_data_logout_async())
                    CookieApi.RemoveCookie(this.props.cookies)
                    localStorage.removeItem('mosiler_cookie')
                    this.props.history.replace('/login')
                    //   global_data_logout_service
                }
                

            } 
            // 만약 쿠키가 존재하지 않을경우. LocalStorage에 cookie 데이터가 있는지를 체크해야함.
            else {
                let tmpLocalStorageCookieData = localStorage.getItem('mosiler_cookie');
                
                // localStorage에 mosiler_cookie가 null인지 아닌지를 확인    
                if (tmpLocalStorageCookieData !== null) {
                    tmpLocalStorageCookieData = JSON.parse(tmpLocalStorageCookieData);
                    

                    if (Number(tmpLocalStorageCookieData.auto_login_chk)) {
                        // null이 아니라면 login check
                        let loginResult = await AccountApi.requestLogin(
                            tmpLocalStorageCookieData.email_id,
                            tmpLocalStorageCookieData.hashcode,
                            tmpLocalStorageCookieData.account_type,
                            tmpLocalStorageCookieData.ostype,
                            tmpLocalStorageCookieData.deviceid);

                        if (loginResult.Result === 'success') {
                            console.log(loginResult);
                            // 이상이 없으면 쿠키정보를 redux, localStorage, Cookie Set으로 저장
                            AccountApi.updateMyInfoWithLocalStorageLoginCheck(loginResult, tmpLocalStorageCookieData, this.props.cookies)
                            
                            let url = await this.gotoRedirectUrl();
                            
                            // 모바일에서 링크를 클릭하여 앱을 실행했을때 리다이렉트 하기 위한 조치.
                            if (url.indexOf('linktoappview::') > -1) {
                                // Main으로 이동하고 linktoappview에 지정된 위치로 이동하도록 한다.
                                this.props.history.replace('/main')
                                this.props.history.push(url.replace('linktoappview::',''))
                            } 
                            else {
                                this.props.history.replace(url)
                            }
                            // this.props.history.replace('/main')
                        }
                        else {
                            console.log('Login Failed');
                            this.props.history.replace('/login')
                        }
                    }
                    else {
                        // 로그인 관련 데이터 전부 삭제
                        await store.dispatch(global_data_logout_async())
                        CookieApi.RemoveCookie(this.props.cookies)
                        localStorage.removeItem('mosiler_cookie')
                        this.props.history.replace('/login')
                    }
                } 
                //null이면 정보가 없으므로 로그인 화면으로 이동.
                else {
                    console.log('쿠키 없어서 localStorage 탐색. null값이라 login 화면으로 이동')
                    this.props.history.replace('/login')
                }
                    
            }
        } catch (e) {
            console.log('exception 발생')
            console.log(e)
            this.props.history.replace('/login')
        }
    }

    // 로그인 체크하는 구간
    componentDidMount() {
        
        this.asyncTest();

    }

    render() {

        return (<>
            <Loading />
            <Intro2Css />
            <div class="intro_box">
                <span class="TXST_t1">운전동행서비스 모시러.</span>
                <span class="logo_box"><img src={logo_w} class="logo" alt="" /></span>
                <span class="TXST_t2">
                    고객의 마음까지 운전합니다!<br />
		                    내게 꼭 맞는 운전동행서비스를 바로바로 이용
                        </span>
            </div></>);
    }
};

export default withCookies(LoginCheck);
