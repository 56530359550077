import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
.mypage_box { display:inline-block; width:100%; min-height:100vh; background:#f3f4f8; padding:30px 20px;} /* 12.24 수정 */
.mypage_box ul { display:inline-block; width:100%;}
.mypage_box li.name_box { display:inline-block; width:100%; margin-bottom:10px;}
.mypage_box li.name_box span.profile_ph { display:inline-block; width:40px; height:40px; float:left;}
.mypage_box li.name_box span.profile_ph img {width:100%;}
.mypage_box li.name_box span.profile_name { display:inline-block; float:left; line-height:40px; padding-left:10px; font-size:1.3em;}
.mypage_box li.section_box { background:#fff; padding:0 20px 20px 20px; margin:10px 0; border-radius:10px;}
.mypage_box li.section_box2 { background:#fff; padding:20px; margin:10px 0; border-radius:10px;}
.mypage_box li.section_box2 a.my_link_more { display:inline-block; width:100%; font-size:1.3em; font-weight:bold;}
.mypage_box li.section_box2 a.my_link_more img.my_btn_arrow {width:8px; float:right; vertical-align:bottom; margin-top:3px;}
.mypage_box span.account_delete { display:inline-block; width:100%; margin:20px 0;}
.mypage_box span.account_delete a.btn_delete { color:#1fafd2; font-size:1em;}
`;