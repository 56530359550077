import React, { Component } from 'react';

import axios from 'axios';
import sha1 from 'sha1';

import Util from '../../lib/Util';
import { Token } from '../../constantData'
import store from "../../reduxStore";
import { withCookies } from "react-cookie";
import CookieApi from '../../lib/CookieApi';
import Loading from '../../components/Common/Loading'
import {calert, calertPromise} from '../../lib/Util'
import RnInterface from '../../lib/RnInterface'
import HeaderJoin from '../../components/Common/HeaderJoin'
import HeaderJoin2 from '../../components/Common/HeaderJoin2'
import MemberJoinCustomCss from '../../css/CustomGlobalStyle/MemberJoinCustom'

import '../../css/default.css';
import '../../css/member.css';

import btn_prev from '../../img/btn/btn_prev.svg';


/*

><
\n<

검색 : <input.+?>
치환 : $0>>

검색 : >>>
치환 : />



검색 : <img.+?>
치환 : $0>>

검색 : >>
치환 :  alt=""/>


*/

class MainMenuMemberChangeInfoChangePw extends Component {
    state = {
        oldpw: '',
        pw: '',
        pwConfirm: '',
        deviceId: '',
        osType: '',
        loginEncrypt:'',
        customerNo:0,
        loading:1,
        oldPasswordChkStr:'',
        passwordChkStr:'',
        passwordConfirmChkStr:'',
        apiResultStr:''
    }

    async componentDidMount() {
        try {
            const { cookies } = this.props;
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            console.log(store.getState().global_data.loginedData)
            this.setState({
                deviceId: cookies.get('deviceid'),
                osType: cookies.get('ostype'),
                customerNo: store.getState().global_data.loginedData.CustomerNo,
                loginEncrypt: cookies.get('login_encrypt')
            })

        }
        catch (e) {
            console.log(e)
        }


    }


    /* Promise 정의 */
    // 내가 스크랩한 Business card의 List 출력 처리 Begin
    promissChangePassword = () => {
        return new Promise((resolve, reject) => {
            axios({
                url: `/UpdateCustomerPassword`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'OsType':  this.state.osType,
                    'DeviceId': this.state.deviceId,
                    'Authorize' : this.state.loginEncrypt,
                    'Token': Token,
                },
                data: {
                    CustomerNo: this.state.customerNo,
                    CustomerPw: sha1(this.state.oldpw),
                    NewCustomerPw: sha1(this.state.pw),
                }
            }).then(res => {
                resolve(res.data);
            }).catch((err) => {
                return reject(err);
            });
        });
    }

    handleOldPw = (e) => { this.setState({ oldpw: e.target.value }) }
    handlePassword = (e) => { this.setState({ pw: e.target.value }) }
    handlePasswordConfirm = (e) => { this.setState({ pwConfirm: e.target.value }) }

    handlePrevBtn = (e) => { this.props.history.goBack(); }
    handleNextBtn = async (e) => {
        let chkFlag = true;

        /*********** 현재 비밀번호 입력란 체크 **************/
        // [20220630]
        // 모시러쪽에서 초기비밀번호를 전화번호형태로 고객에게 보내는 케이스가 있기 때문에
        // 기존 비밀번호 체크하는 처리를 없앤다.
        // 어차피 서버에서 기존 비밀번호가 맞는지 여부를 체크를 하고 있기 때문에 주석처리한다.
        // if (!Util.isChkPw(this.state.oldpw)) {
        //     // 6자리 숫자가 아니면(임시비밀번호 숫자 6자리 관련 체크 처리)
        //     if (!Util.isChkTempNumber(this.state.oldpw)) {
        //         this.setState({
        //             oldPasswordChkStr: '비밀번호 형식에 맞게 입력해주세요.',
        //             passwordChkStr: '',
        //             passwordConfirmChkStr: '',
        //         })
        //         chkFlag = false;
        //     } else {
        //         // 6자리 숫자가 맞으면 일단 공백으로 두고 다음처리를 실시
        //     }
        // }
        // else {
        //     this.setState({
        //         oldPasswordChkStr: '',
        //         passwordChkStr: '',
        //         passwordConfirmChkStr: '',
        //     })
        // }


        if (!Util.isChkPw(this.state.pw) || !Util.isChkPw(this.state.pwConfirm)) {
            if (!Util.isChkPw(this.state.pw)) {
                this.setState({
                    passwordChkStr: '비밀번호 형식에 맞게 입력해주세요.',
                    apiResultStr:'',
                })
                chkFlag = false;
            } else {
                this.setState({
                    passwordChkStr: '',
                    apiResultStr:'',
                })
            }
            if (!Util.isChkPw(this.state.pwConfirm)) {
                this.setState({
                    passwordConfirmChkStr: '비밀번호 형식에 맞게 입력해주세요.',
                    apiResultStr:'',
                })
                chkFlag = false;
            } else {
                this.setState({
                    passwordConfirmChkStr: '',
                    apiResultStr:'',
                })
            }
            // await calertPromise('비밀번호 형식에 맞게 입력해주세요.')
            return;
        }
        

        if (this.state.pw !== this.state.pwConfirm) {
            // calert('비밀번호가 맞지 않습니다 다시 입력해주세요.')
            this.setState({
                passwordChkStr: '비밀번호가 맞지 않습니다 다시 입력해주세요.',
                passwordConfirmChkStr: '비밀번호가 맞지 않습니다 다시 입력해주세요.',
                apiResultStr:'',
            })
            chkFlag = false;
            
        }
        
        if (chkFlag) {
            this.setState({loading:0})
            let changePwResult = await this.promissChangePassword();
            console.log(changePwResult);

            if (changePwResult.Result !== 'success') {
                this.setState({
                    loading:1,
                    oldPasswordChkStr:changePwResult.Message,
                    passwordChkStr:'',
                    passwordConfirmChkStr:'',
                })
                
            } else {
                this.setState({
                    oldPasswordChkStr:'',
                    passwordChkStr:'',
                    passwordConfirmChkStr:'',
                })
                await calertPromise('비밀번호 변경이 완료되었습니다. 다시 로그인해주세요.')
                // 쿠키 삭제
                CookieApi.RemoveCookie(this.props.cookies);
                this.props.history.replace('/');
            }
        
        }
    }
    /*

     */
    render() {
        return (
            <>
            {!this.state.loading ? (<Loading />) : ''}
            <MemberJoinCustomCss />
                <HeaderJoin2
                    onBackButton={this.handlePrevBtn}
                    title={''} />
                <main id="Contents"> 
                    <div className="key-visual-wrap">
                        <div className="key-visual-container">
                            <div className="key-contents">
                                <div className="key-text">
                                    <strong>현재 비밀번호와 새 비밀번호를 입력하고,<br />새 비밀번호를 다시 입력하십시오.</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="component-wrap inner-container">
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">현재 비밀번호</label>									
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <input type="password" placeholder="현재 사용중인 비밀번호를 입력하세요." className="input type1" onChange={this.handleOldPw} value={this.state.oldpw} />
                                    </div>
                                </div>	
                            </div>
                            <div class="form-alert">
                                <p className="is-errored">{this.state.oldPasswordChkStr}</p>
                            </div>                                                  
                        </div>
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">변경할 비밀번호</label>	
                                    <span className="text-sub text-gray pad-l5">(설정 가능한 특수문자 : @ $ ! % * # ? &)</span>								
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <input type="password" placeholder="영문, 숫자, 특수문자 조합으로 8~10자 설정" className="input type1" onChange={this.handlePassword} value={this.state.pw} />
                                    </div>
                                </div>	
                            </div>
                            <div class="form-alert">
                                <p className="is-errored">{this.state.passwordChkStr}</p>
                            </div>                                                  
                        </div>  
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">변경할 비밀번호 확인</label>									
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <input type="password" placeholder="영문, 숫자, 특수문자 조합으로 8~10자 설정" className="input type1" onChange={this.handlePasswordConfirm} value={this.state.pwConfirm} />
                                    </div>
                                </div>	
                            </div>
                            <div class="form-alert">
                                <p className="is-errored">{this.state.passwordConfirmChkStr}</p>
                                <p className="is-errored">{this.state.apiResultStr}</p>
                            </div>                                                  
                        </div>                                                
                    </div>
                    <div className="component-wrap">
                        <div className="btn-area btn-group-fixed vertical">
                            <button type="button" className="btn large secondary" onClick={this.handleNextBtn}><span>확인</span></button>
                        </div>
                    </div> 
                </main>
 
            </>
        );
    }
};

export default withCookies(MainMenuMemberChangeInfoChangePw);
