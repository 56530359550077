import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

import axios from 'axios';
import Util from '../../lib/Util'
import { MobileAuthLimitTime } from '../../constantData'

import store from "../../reduxStore";
import { withCookies } from "react-cookie";
import CookieApi from '../../lib/CookieApi';
import { Token } from '../../constantData'
import AccountApi from '../../lib/AccountApi'
import {global_data_async} from '../../modules/Global/global_data'
import Loading from '../../components/Common/Loading'
import {calert, calertPromise} from '../../lib/Util'
import RnInterface from '../../lib/RnInterface'
import HeaderJoin from '../../components/Common/HeaderJoin'
import HeaderJoin2 from '../../components/Common/HeaderJoin2'
import MemberJoinCustomCss from '../../css/CustomGlobalStyle/MemberJoinCustom'

import '../../css/default.css';
import '../../css/member.css';

//view_~~~ 로 시작하는 파일은 html inline에 style로 정해놓은 것을 css화 시킨 것이다.
// import '../css/view_login.css';

import btn_prev from '../../img/btn/btn_prev.svg';


class MainMenuMemberChangeInfoChangePhone extends Component {
    state = {
        phoneNum: '',               // 휴대폰 번호
        authNum:'',                 // 이용자가 입력한 인증번호
        responseAuthNum:'',         // sms 응답 받은 인증번호
        authCheckFlag: 1,           // 1: 비활성화  0: 활성화
        authChkResult: 0,           // 인증 성공 여부 0 : 실패, 1 : 성공
        deviceId: '',
        osType: '',
        loginEncrypt:'',
        customerNo:0,
        name: '',
        email : '',
        homeAddress : '',
        carName : '',
        carNo : '',
        id:'',
        hash: '',
        accountType:'',
        loading:0
    };
    
    /*
        * 사용할 API
          - http://apitest.client.mosiler.com/Help/Api/GET-SetVerifySms_phone 이걸써야하는지
          - http://api.mosiler.com/help/operations/SetVerifySms#response-json 이걸써야하는지..
    */

    async componentDidMount() {

        try {
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            window.timer = null;
            const { cookies } = this.props;
            this.setState({
                id: cookies.get('email_id'),
                hash: cookies.get('hashcode'),
                accountType: cookies.get('account_type'),
                deviceId: cookies.get('deviceid'),
                osType: cookies.get('ostype'),
                loginEncrypt: cookies.get('login_encrypt'),
                customerNo: store.getState().global_data.loginedData.CustomerNo,
                name: store.getState().global_data.loginedData.CustomerName,
                email: store.getState().global_data.loginedData.Email,
                homeAddress: store.getState().global_data.loginedData.HomeAddress,
                carName: store.getState().global_data.loginedData.CarName,
                carNo: store.getState().global_data.loginedData.CarNo,
                loading: 1,
            })
        }
        catch (e) {
            console.log(e)
        }

    }

    componentWillUnmount() {
        clearInterval(window.timer);
    }

    /* Promise 정의 */
    // 내가 스크랩한 Business card의 List 출력 처리 Begin
    promissChkVerifySms = () => {
        return new Promise((resolve, reject)=>{
            axios({
                url : `/SetVerifySms?phone=${this.state.phoneNum}`,
                method: 'GET',
				timeout: 5000,
                headers: {
                    'Content-Type':'application/json',
                    'OsType': this.state.osType,
                    'DeviceId': this.state.deviceId,
                    'Token': '4D006D0B3FF2019759A6FD719AE8B291E2B2A4935F0CC42D72534C494C95AAFD'
                }
            }).then(res => {
                resolve(res.data);
            }).catch( (err) => {
                return reject(err);
            });
        });
    }

    /* Promise 정의 */
    // 내가 스크랩한 Business card의 List 출력 처리 Begin
    promissChangePhone = () => {
        return new Promise((resolve, reject)=>{
            axios({
                url : `/UpdateCustomer`,
                method: 'POST',
                headers: {
                    'Content-Type':'application/json',
                    'OsType':  this.state.osType,
                    'DeviceId': this.state.deviceId,
                    'Authorize' : this.state.loginEncrypt,
                    'Token': Token,
                }, 
                data : {
                    CustomerNo : this.state.customerNo,
                    CustomerName : this.state.name,
                    Email : this.state.email,
                    PhoneNumber : this.state.phoneNum,
                    HomeAddress : this.state.homeAddress,
                    CarName : this.state.carName,
                    CarNo : this.state.carNo,
                }
            }).then(res => {
                resolve(res.data);
            }).catch( (err) => {
                return reject(err);
            });
        });
    }

    /*
    
    http://apitest.client.mosiler.com/TestVerifySms?phone=01087223834 response
    성공시
    {
        "Result": "success",
        "Message": "23488",
        "TokenStatus": 1,
        "ResultCode": "{\"result_code\":\"1\",\"message\":\"success\",\"msg_id\":\"163202715\",\"success_cnt\":1,\"error_cnt\":0,\"msg_type\":\"SMS\"}"
    }
    실패시
    {
        "Result": "fail",
        "Message": "",
        "TokenStatus": 1,
        "ResultCode": null
    }
    */
    // 이전 버튼, 상단 뒤로가기 버튼
    handlePrevBtn = () => {
        this.props.history.goBack();
    }
    
    // 다음
    handleNextBtn = async () => {       
        try {
            this.setState({loading:0})
            // 인증 최종 성공시
            if (this.state.authChkResult) {
                window.timer = undefined;
                const { cookies } = this.props;
    
                // 휴대폰 번호만 변경처리한다.
                let changeResult = await this.promissChangePhone();
                console.log(changeResult)
                if (changeResult.Result === 'success') {
                    let loginResult = await AccountApi.requestLogin(
                        this.state.id,
                        this.state.hash,
                        this.state.accountType
                    )
                    
                    let hashData = '';
                    if (this.state.hash === '' && this.state.hash.length === 0) {
                        hashData = cookies.get('hashcode');
                    } else {
                        hashData = this.state.hash
                    }
                    

                    // localStorage에 갱신
                    localStorage.setItem('mosiler_cookie', JSON.stringify({
                        account_type: loginResult.Item.AccountType,
                        deviceid: loginResult.Item.DeviceId,
                        email_id: loginResult.Item.UserId,
                        login_encrypt: loginResult.Item.LoginEncrypt,
                        ostype: loginResult.Item.OsType,
                        auto_login_chk: Number(cookies.get('auto_login_chk')),
                        hashcode: hashData,
                    }))
    
                    CookieApi.SettingCookie(cookies, {
                        UserId : loginResult.Item.UserId,
                        AccountType : loginResult.Item.AccountType,
                        hash : hashData,
                        LoginEncrypt : loginResult.Item.LoginEncrypt,
                        OsType : loginResult.Item.OsType,
                        DeviceId : loginResult.Item.DeviceId
                    }, Number(cookies.get('auto_login_chk')))
    
                    // let result = 
                    await store.dispatch(global_data_async(loginResult.Item));
                    this.props.history.goBack()
                } else {
                    await calertPromise(changeResult.Result);
                    this.setState({loading:1})
                }
            } else {           
                await calertPromise('본인 인증이 완료 되지 않았습니다. 인증을 진행해주세요. ')
                this.setState({loading:1})
            }
        } catch(e) {
            await calertPromise('오류가 발생하였습니다. 관리자에게 문의해주십시오.')
            this.setState({loading:1})
        }
        
        
    }

    limitTimer = () => {
        let time = MobileAuthLimitTime;
        let min = '';
        let sec = '';
        window.timer = setInterval(()=>{
            min = parseInt(time / 60);
            sec = time % 60;
            document.querySelector('.text-darkblue').innerHTML = `${Util.getNumber2Ciphers(min)}:${Util.getNumber2Ciphers(sec)}`;
            time --;
    
            if (time < 0) {
                this.setState({
                    responseAuthNum: '',
                    authCheckFlag:1
                })
                clearInterval(window.timer)
                document.querySelector('.is-successed').style.display="none"
                document.querySelector('.is-successed').innerHTML = '';
                document.querySelector('.is-errored').style.display=""
                document.querySelector('.is-errored').innerHTML = '인증시간이 초과되었습니다.';
                document.querySelector('.text-darkblue').innerHTML = '';
            }
        }, 1000);
    }

    // 인증 요청, 인증 재요청
    handleRequestVerifySMS = async () => {
        try {
            // 휴대폰 입력되어있는지 체크
            // 입력되어 있으면 API 호출
            // 입력 안되어 있으면 calert 표시
            if (this.state.phoneNum.length >= 10) {
                this.setState({loading:0})
                let resultData = await this.promissChkVerifySms();

                if (resultData.Result !== 'success') {
                    await calertPromise('인증 요청에 실패하였습니다. 휴대폰 번호를 확인해주세요.');
                    this.setState({loading:1})
                } else {
                    this.limitTimer();

                    this.setState({
                        responseAuthNum: resultData.Message,
                        authCheckFlag: 0,
                        loading:1
                    })
                    await calertPromise('문자 메시지를 전송하였습니다. 인증번호를 입력해주세요.');


                    // 이 시점에 timer 처리가 필요하다.
                    // 타이머 2분 초과되면 responseAuthNum
                    // 인증확인 버튼은 2분동안 활성화
                    // 2분 지나면 인증확인은 비활성화

                }

            } else {
                await calertPromise('휴대폰 번호를 입력해주세요.')
                this.setState({loading:1})
            }
        } catch (e) {
            await calertPromise('휴대폰 인증 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
            console.log(e)
            this.setState({loading:1})
        }
    }

    // 인증 확인
    handleCheckAuthNum = () => {
        if (this.state.responseAuthNum === this.state.authNum) {
            this.setState({
                authChkResult: 1
            })
            document.querySelector('.is-successed').style.display=""
            document.querySelector('.is-successed').innerHTML = '인증이 완료되었습니다.';
            document.querySelector('.is-errored').style.display="none"
            document.querySelector('.is-errored').innerHTML = '';
            

            // calert('인증이 완료되었습니다.')
            clearInterval(window.timer);
        } else {
            document.querySelector('.is-successed').style.display="none"
            document.querySelector('.is-successed').innerHTML = '';
            document.querySelector('.is-errored').style.display=""
            document.querySelector('.is-errored').innerHTML = '인증에 실패하였습니다.';
            // calert(' 확인 부탁드립니다.')
        }
    }

    // 이름
    handleChangeName = (e) => {
        this.setState({
            name: e.target.value
        })
    }
    // 휴대폰 번호
    handleChangePhoneNum = (e) => {
        this.setState({
            phoneNum: e.target.value
        })
    }
    // 인증번호
    handleChangeAuthNum = (e) => {
        this.setState({
            authNum: e.target.value
        })
    }

    render() {
        return (
            <>
                {!this.state.loading ? (<Loading />) : ''}
                
                <MemberJoinCustomCss />
                <HeaderJoin2
                    onBackButton={this.handlePrevBtn}
                    title={''} />
                <main id="Contents"> 
                    <div className="key-visual-wrap">
                        <div className="key-visual-container">
                            <div className="key-contents">
                                <div className="key-text">
                                    <strong>휴대폰 번호 변경을 위해 본인인증을 진행합니다.</strong><br />인증번호 요청 후 인증확인해주세요.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="component-wrap inner-container">
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">휴대전화번호</label>									
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <span className="input-group has-append">
                                            <input type="tel" placeholder="- 없이 숫자만 입력" className="input type1" onChange={this.handleChangePhoneNum} value={this.state.phoneNum} />
                                            <span className="append">
                                                <button type="button" className={!this.state.authCheckFlag ? "btn small" : "btn small primary"} disabled={!this.state.authCheckFlag} onClick={this.handleRequestVerifySMS}><span>인증요청</span></button>
                                            </span>  
                                        </span>
                                    </div>
                                    <div className="form-controls flexible mar-t5">
                                        <span className="input-group has-append">
                                            <input type="tel" placeholder="인증번호 5자리 입력" className="input type1" onChange={this.handleChangeAuthNum} value={this.state.authNum}/>
                                            <span className="append">
                                                <button type="button" value="인증확인" className={this.state.authCheckFlag ? "btn small" : "btn small primary"} disabled={this.state.authCheckFlag} onClick={this.handleCheckAuthNum}><span>인증확인</span></button>
                                            </span> 
                                        </span>
                                    </div>
                                </div>	
                            </div>
                            <div class="form-alert">
                                <div className="col">
                                    <p className="is-errored"></p>
                                    <p className="is-successed"></p>
                                </div>
                                {/* (<> <h1>남는시간</h1></>) */}
                                <div className="col right">
                                    {!this.state.authCheckFlag ? '남은시간 ' : ''}
                                    <strong className="text-darkblue"></strong>
                                </div>                                
                            </div>                                                  
                        </div>
                    </div>   
                    <div className="component-wrap">
                        <div className="btn-area btn-group-fixed vertical">
                            <button type="button" className="btn large secondary" onClick={this.handleNextBtn}><span>확인</span></button>
                        </div>
                    </div>                      
                </main>
                {/* 
                    동적 처리 할때 아래 class 대로 변경필요함.
                    <p className="is-errored"></p>    에러
                    <p className="is-successed"></p>  성공
                    text-darkblue                     시간
                */}
            </>
        );
    }
};

export default withCookies(MainMenuMemberChangeInfoChangePhone);
