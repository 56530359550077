// GET v2/GetLiveDrivings?customerNo={customerNo}
// DrivingView.js : DrivingView component
// 현재 위치 정보 취득하는 함수 : getLiveDrivings
// DrivingState
// DrivingContainer
// DrivingMap
// 메뉴 목록 표시 : drivingList > DrivingListViewContainer > DrivingListView
// MainScreenNavigator
// Navigator.js : 등록된 네비게이션 확인 가능
// 10초마다 폴링.
// fetchBlob > rn-fetch-blob


import DrivingApi from '../../lib/DrivingApi'
import ReserveApi from '../../lib/ReserveApi'
import axios from 'axios'
import { calert } from '../../lib/Util';
import {
    DRIVING_BLOB_BASE_URL,
    NAVER_KEY,
    NAVER_CLIENT_ID


} from "../../constantData";

const RESET_DRIVER_POSITION = "RealtimeLiveDriving/RESET_DRIVER_POSITION";

const FETCH_DIRECTION_SUCCEED = "RealtimeLiveDriving/FETCH_DIRECTION_SUCCEED";
const FETCH_DIRECTION_FAILED = "RealtimeLiveDriving/FETCH_DIRECTION_FAILED";

const GET_LIVE_DRIVINGS_SUCCEEDED = "RealtimeLiveDriving/GET_LIVE_DRIVINGS_SUCCEEDED";
const GET_LIVE_DRIVINGS_FAILED = "RealtimeLiveDriving/GET_LIVE_DRIVINGS_FAILED";

const GET_WAYPOINT_SUCCEED = "RealtimeLiveDriving/GET_WAYPOINT_SUCCEED";
const GET_WAYPOINT_FAILED = "RealtimeLiveDriving/GET_WAYPOINT_FAILED";

export const resetDriverPosition = () => ({
    type: RESET_DRIVER_POSITION,
});

export const fetchDirectionSucceedAction = (data) => ({
    type: FETCH_DIRECTION_SUCCEED,
    payload: data,
});

export const fetchDirectionFailedAction = (error) => ({
    type: FETCH_DIRECTION_FAILED,
    payload: error,
});


export const getLiveDrivingsSucceededAction = (response) => ({
    type: GET_LIVE_DRIVINGS_SUCCEEDED,
    payload: response,
});
export const getLiveDrivingsFailedAction = (error) => ({
    type: GET_LIVE_DRIVINGS_FAILED,
    payload: error,
});

export const getWaypointSucceededAction = (response) => ({
    type: GET_WAYPOINT_SUCCEED,
    payload: response,
});

export const getWaypointFailedAction = (error) => ({
    type: GET_WAYPOINT_FAILED,
    payload: error,
});


// redux Thunk에 대한 설명
// https://velog.io/@eomttt/Redux-%EC%A0%81%EC%9A%A9%ED%95%98%EA%B8%B0-Thunk-Saga
/*
async function fetchDirections(payload) {
    try {
        const response = await Axios.get(
            `https://naveropenapi.apigw.ntruss.com/map-direction/v1/driving?start=${payload[0].longitude},${payload[0].latitude}&goal=${payload[1].longitude},${payload[1].latitude}`,
            {
                headers: {
                    "X-NCP-APIGW-API-KEY-ID": NAVER_KEY,
                    "X-NCP-APIGW-API-KEY": NAVER_CLIENT_ID,
                },
            }
        );
        return fetchDirectionSucceedAction(
            response &&
            response.data &&
            response.data.route &&
            response.data.route.traoptimal[0].path
        );
    } catch (err) {
        return fetchDirectionFailedAction();
    }
}
*/

export const getWaypoint = (cn, drivingno, ostype, deviceid, loginEncrypt) => async dispatch => {
    try {       
        let waypointResult = await ReserveApi.Get_DRVG_STOP_AREA(cn, drivingno, ostype, deviceid, loginEncrypt)
        if (waypointResult.Result === 'success') {
            dispatch(getWaypointSucceededAction(waypointResult.Item))
        } 
        else {
            dispatch(getWaypointFailedAction(waypointResult))
        }
    } catch(e) {
        console.log(e)
        dispatch(getWaypointFailedAction(e))
    }
}


export const getLiveDrivings = (cn, drivingno, ostype, deviceid, loginEncrypt, payload) => async dispatch => {
    try {
        var response = undefined;
        //Init driver position from api once, and next time request we don't need this request anymore
        //Just request fetchBlob
        var tmpDrivers = undefined;
        let isOnlyLatLong = false;

        (payload.previousDrivings || []).map((previousDriving) => {
            if (Object.keys(previousDriving || {}).length <= 2) {
                isOnlyLatLong = true;
            }
        });
        if (
            !payload.previousDrivings ||
            Boolean(payload.init) ||
            (payload.previousDrivings || []).length === 0 ||
            Boolean(isOnlyLatLong)
        ) {
            //Check if is the first time request

            response = await DrivingApi.GetLiveDrivings(cn, ostype, deviceid, loginEncrypt)
            
            // 경유지 취득 begin
            try {       
                let waypointResult = await ReserveApi.Get_DRVG_STOP_AREA(cn, response.Item[0].DrivingNo, ostype, deviceid, loginEncrypt)
                if (waypointResult.Result === 'success') {
                    let testArr = [];
                    // Object 형태를 배열형태로 처리
                    for(let i = 1; i <= 10; i++) {
                        let no = '01'
                        if (i <= 9) no=`0${i}`
                        else no=`${i}`
                        if ((waypointResult.Item[`WaypointAddress${no}`] !== undefined && waypointResult.Item[`WaypointAddress${no}`] !== null) &&
                            (waypointResult.Item[`WaypointLat${no}`] !== undefined && waypointResult.Item[`WaypointLat${no}`] !== null) &&
                            (waypointResult.Item[`WaypointLng${no}`] !== undefined && waypointResult.Item[`WaypointLng${no}`] !== null)) {
                            testArr.push({
                                WaypointAddress: waypointResult.Item[`WaypointAddress${no}`],
                                WaypointLat: waypointResult.Item[`WaypointLat${no}`],
                                WaypointLng: waypointResult.Item[`WaypointLng${no}`]
                            })
            
                        }
                        else break;
                    }
                    dispatch(getWaypointSucceededAction(testArr))
                } 
                else {
                    dispatch(getWaypointFailedAction(waypointResult))
                }
            } catch(e) {
                console.log(e)
                dispatch(getWaypointFailedAction(e))
            }
            // 경유지 취득 end

            console.log(response);
            tmpDrivers = [
                ...(response && response.Item),
                // {
                //     DrivingNo: 12,
                //     Lng: -122.08400000000001,
                //     Lat: 37.42149833433534,
                //     DrivingStatus: "4",
                //     DrivingStatusName: "운행중",
                //     DrivingStartDt: "2020-03-27T09:10:56.523",
                //     RevStartAddress:
                //         "서울특별시 강남구 압구정동 압구정로29길 69 현대아파트",
                //     RevStartLat: "37.53151096287975",
                //     RevStartLng: "127.0259852910155",
                //     RevStartDt: "2020-03-27T10:10:00",
                //     RevEndAddress:
                //         "서울특별시 강남구 압구정동 압구정로29길 69 현대아파트",
                //     RevEndLat: "37.53151096287975",
                //     RevEndLng: "127.0259852910155",
                //     RevEndDt: "2020-03-27T11:10:00",
                //     DriverName: "test1",
                // },
                // {
                //     DrivingNo: 51246,
                //     Lng: -122.08400000000001,
                //     Lat: 37.42149833333434,
                //     DrivingStatus: "4",
                //     DrivingStatusName: "운행중",
                //     DrivingStartDt: "2020-03-27T09:10:56.523",
                //     RevStartAddress:
                //         "서울특별시 강남구 압구정동 압구정로29길 69 현대아파트",
                //     RevStartLat: "37.53151096287975",
                //     RevStartLng: "127.0259852910155",
                //     RevStartDt: "2020-03-27T10:10:00",
                //     RevEndAddress:
                //         "서울특별시 강남구 압구정동 압구정로29길 69 현대아파트",
                //     RevEndLat: "37.53151096287975",
                //     RevEndLng: "127.0259852910155",
                //     RevEndDt: "2020-03-27T11:10:00",
                //     DriverName: "test2",
                // },
            ];
        } else {
            tmpDrivers = [...payload.previousDrivings];
        }
        console.log(response);
        console.log(tmpDrivers);

        var arr = [];
        for (let i = 0; i < (tmpDrivers || []).length; i++) {
            let tmpDriver = (tmpDrivers || [])[i];
            //Next fetch real coords position from Azure Blob cache
            //https://mosilergps.blob.core.windows.net/lclog/{{DrivingNo}}

            let drivingBlob = await axios.get(`/lclog/${tmpDriver.DrivingNo}`)
            console.log(`drivingBlob`, drivingBlob.data);
            
            if (drivingBlob.data && drivingBlob.data.Lat && drivingBlob.data.Lng) {
                tmpDriver.Lat = drivingBlob.data.Lat;
                tmpDriver.Lng = drivingBlob.data.Lng;
            }

            if (`${tmpDriver.Lat}` !== `0` && `${tmpDriver.Lng}` !== `0`) {
                arr.push({ ...tmpDriver });
            }
        }

        console.log(arr);
        dispatch(getLiveDrivingsSucceededAction(arr))
    } catch (err) {
        console.log(err);
        // return getLiveDrivingsFailedAction(err);
    }
}


const initialState = {
    error: false,
    path: [],
    position: {},
    drivers: [],
    waypoint:[],
}

export default (state = initialState, action) => {
    switch (action.type) {

        case FETCH_DIRECTION_SUCCEED:
            return {
                ...state,
                error: false,
                path: action.payload
            };

        case FETCH_DIRECTION_FAILED:
            return {
                ...state,
                error: true,
                path: []
            };

        case RESET_DRIVER_POSITION:
            return {
                ...state,
                position: [],
                drivers: [],
                waypoint:{},
            };

        case GET_LIVE_DRIVINGS_SUCCEEDED:
            return {
                ...state,
                error: false,
                drivers: action.payload
            };
        case GET_LIVE_DRIVINGS_FAILED:
            return {
                ...state,
                error: true,
                drivers: action.payload
            };

        case GET_WAYPOINT_SUCCEED:
            return {
                ...state,
                error: false,
                waypoint: action.payload
            };
        case GET_WAYPOINT_FAILED:
            return {
                ...state,
                error: true,
            };
        default:
            return state;
    }
};
