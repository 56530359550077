import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import App from './App';

import { Provider } from "react-redux";   // 추가
import { CookiesProvider } from 'react-cookie';

import { persistStore } from 'redux-persist'
import {PersistGate} from 'redux-persist/integration/react'

import store from './reduxStore';

const persistor = persistStore(store)

const Root = () => {
    
    return (
        <BrowserRouter>
            <CookiesProvider>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <App store={store} />
                    </PersistGate>
                </Provider>
            </CookiesProvider>
        </BrowserRouter>
    );
};

export default Root;