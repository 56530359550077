import React, { Component } from 'react';

import axios from 'axios';
// import sha1 from 'sha1';

import Util from '../../lib/Util';
import { Token } from '../../constantData'
import Loading from '../../components/Common/Loading'
import {calert, calertPromise} from '../../lib/Util'
import RnInterface from '../../lib/RnInterface'
import HeaderJoin2 from '../../components/Common/HeaderJoin2'
import MemberJoinCustomCss from '../../css/CustomGlobalStyle/MemberJoinCustom'

import '../../css/default.css';
import '../../css/member.css';

import btn_prev from '../../img/btn/btn_prev.svg';


/*
SNS로 회원가입시에 사용되는 화면이다.
비밀번호 입력은 하지 않고, Email정보만 입력한다. 
(MemberJoin4View와의 차이는 비밀번호 입력란, validate 체크 부분이다. diff툴을 사용하여 확인.)
MemberJoin4View.js 한화면에서 처리하면 좋지만 
현 시점에서 시간이 걸리기에 MemberJoin4SnsView로 대체한다 
차후 유지보수시, MemberJoin4View로 합칠지의 여부는 개발자에게 맡긴다.



\n<

검색 : <input.+?>
치환 : $0>>

검색 : >>>
치환 : />



검색 : <img.+?>
치환 : $0>>

검색 : >>
치환 :  alt=""/>


*/

class MemberJoin4SnsView extends Component {
    state = {
        email: '',
        pw: '',
        pwConfirm: '',
        joinData: {},            // step1~3까지의 데이터
        loading:1
    }

    async componentDidMount() {
        try {
            await RnInterface.AndroidURLChange(this.props.location.pathname)
        }
        catch(e){
            console.log(e)
        }
    }

    /* Promise 정의 */
    // 내가 스크랩한 Business card의 List 출력 처리 Begin
    promissEmailChk = () => {
        return new Promise((resolve, reject) => {
            axios({
                url: `/CheckEmail?userId=${this.state.email}`,
                method: 'GET',
                timeout:10000,
                headers: {
                    'Content-Type': 'application/json',
                    'Token': Token,
                    'OsType' : this.state.osType,
                    'DeviceId' : this.state.deviceId,
                }
            }).then(res => {
                resolve(res.data);
            }).catch((err) => {
                return reject(err);
            });
        });
    }


    handleEmail = (e) => { this.setState({ email: e.target.value }) }
    handlePassword = (e) => { this.setState({ pw: e.target.value }) }
    handlePasswordConfirm = (e) => { this.setState({ pwConfirm: e.target.value }) }

    handlePrevBtn = (e) => { this.props.history.goBack(); }
    handleNextBtn = async (e) => {
        let chkFlag = 0;

        if (!Util.isChkEmail(this.state.email)) {
            await calertPromise('이메일 형식에 맞게 입력해주세요.')
            return;
        }
        chkFlag++;

        // if (!Util.isChkPw(this.state.pw) || !Util.isChkPw(this.state.pwConfirm)) {
        //     calert('비밀번호 형식에 맞게 입력해주세요.')
        //     return;
        // }
        // chkFlag++;

        // if (this.state.pw !== this.state.pwConfirm) {
        //     calert('비밀번호가 맞지 않습니다 다시 입력해주세요.')
        //     return;
        // }
        // chkFlag++;

        
        try {
            // if (chkFlag === 3) {
            if (chkFlag) {
                localStorage.setItem('emailaddress', this.state.email);
                this.props.history.push('/join/5');

                // this.setState({loading:0})
                // let resultData = await this.promissEmailChk();
                // // success가 아니라면?
                // if (resultData.Result !== 'success') {
                //     calert(resultData.Message)
                //     this.setState({loading:1})
                // }
                // // Success라면?
                // else {
                    
                //     // localStorage.setItem('c_emailid', this.state.email);
                //     // localStorage.setItem('c_password', sha1(this.state.pw));
                    
                //     // 주소 입력 화면으로 이동
                    
                // }
            }
        } catch(e) {
            calert('ID 체크 도중 오류가 발생하였습니다. 관리자에게 문의해주세요.')
            this.setState({loading:1})

        }
        
    }
    
    render() {
        return (
            <>
            {!this.state.loading ? (<Loading />) : ''}
                <MemberJoinCustomCss />
                <HeaderJoin2
                    onBackButton={this.handlePrevBtn}
                    title={''} />
                <main id="Contents"> 
                    <div className="key-visual-wrap">
                        <div className="key-visual-container">
                            <div className="key-contents">
                                <div className="key-text">
                                    <strong>이메일로</strong> 회원가입을 진행합니다.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="component-wrap inner-container">
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">이메일</label>									
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <input type="email" placeholder="이메일 주소를 입력해주세요." className="input type1" onChange={this.handleEmail} value={this.state.email} />
                                    </div>
                                </div>	
                            </div>
                            <div class="form-alert">
                                <p className="is-errored">{this.state.emailChkStr}</p>
                            </div>                                                 
                        </div> 
                    </div>
                    <div className="component-wrap">
                        <div className="btn-area btn-group-fixed vertical">
                            <button type="button" className="btn large secondary" onClick={this.handleNextBtn}><span>다음</span></button>
                        </div>
                    </div>   
                </main>    

            </>
        );
    }
};

export default MemberJoin4SnsView;
