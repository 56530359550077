// 리스트 취득할때 메뉴 -> 예약현황을 열었을때만 리스트를 취득하게 하고, 
// 상세화면 -> 예약현황으로 들어갔을때에도 데이터를 취득하지 않고 유지되도록 재설계가 필요함.
// 데이터 저장소는 redux를 사용. 
// 데이터가 존재하면 기존 데이터 활용
// 데이터가 하나도 없으면 API 통해 취득
// 삭제 했을 경우는 삭제 API 실행 후 success라고 나왔다면 현재redux에 저장된 데이터중 drivingno와 일치하는 노드를 삭제한다.
// 삭제 실패했을 경우에는 데이터를 유지한다. 삭제시, 경유지도 신경쓴다.
// 스크롤할때 페이징 처리가 필요하다. (witive때 코드 참고한다.)
// useState가 완료되면 바로 history도 적용한다. (구조는 비슷하기에 조금만 수정하면될듯.)

// import moment from 'moment';


// end 시간 등록

import ReserveApi from '../../lib/ReserveApi'
import { calertPromise } from '../../lib/Util';

const EMPTY_USE_HISTORY = "UseHistory/EMPTY_USE_HISTORY";
const INIT_USE_HISTORY = "UseHistory/INIT_USE_HISTORY";
const GET_USE_HISTORY = "UseHistory/GET_USE_HISTORY"; 
const PAGING_USE_HISTORY = "UseHistory/PAGING_USE_HISTORY";
const DEL_USE_HISTORY = "UseHistory/DEL_USE_HISTORY";

const INIT_LOADING_USE_HISTORY = "UseHistory/INIT_LOADING_USE_HISTORY";
const INIT_LOADING_END_USE_HISTORY = "UseHistory/INIT_LOADING_END_USE_HISTORY";
const LOADING_USE_HISTORY = "UseHistory/LOADING_USE_HISTORY";
const LOADING_END_USE_HISTORY = "UseHistory/LOADING_END_USE_HISTORY";

const INIT_USE_HISTORY_NATIVE = "UseHistory/INIT_USE_HISTORY_NATIVE";

const MAX_PAGE = 4;

export const init_use_history_native_service = (_obj) => ({ type: INIT_USE_HISTORY_NATIVE, payload: {obj: _obj} });

export const empty_use_history_service = () => ({ type: EMPTY_USE_HISTORY });
export const init_use_history_service = (_obj, allpage) => ({ type: INIT_USE_HISTORY, payload: {obj: _obj, totalpage: allpage} });
export const get_use_history_service = () => ({ type: GET_USE_HISTORY });
export const paging_use_history_service = (_obj) => ({ type: PAGING_USE_HISTORY, payload: {obj: _obj} });
export const del_use_history_service = (no) => ({ type: DEL_USE_HISTORY, payload: no });


export const init_loading_use_history_service = () => ({ type: INIT_LOADING_USE_HISTORY });
export const init_loading_end_use_history_service = () => ({ type: INIT_LOADING_END_USE_HISTORY });
export const loading_use_history_service = () => ({ type: LOADING_USE_HISTORY });
export const loading_end_use_history_service = () => ({ type: LOADING_END_USE_HISTORY });

// redux Thunk에 대한 설명
// https://velog.io/@eomttt/Redux-%EC%A0%81%EC%9A%A9%ED%95%98%EA%B8%B0-Thunk-Saga


export const getEndListItemPromise = (cn, DrivingNo, ostype, deviceid, loginEncrypt) => dispatch => {
    return new Promise( async (resolve,reject)=>{
        try {
            let result={
                
            };
            let waypointResult = await ReserveApi.Get_DRVG_STOP_AREA(cn, DrivingNo, ostype, deviceid, loginEncrypt)
            if (waypointResult.Result === 'success') {
                let tmpWaypointData = {
                    ...waypointResult.Item,
                    // RegDt: result.ItemList[i].RegDt
                }
                result = Object.assign(result, tmpWaypointData)
            }
            let detailResult = await ReserveApi.GetDrivingEndDetail(DrivingNo, ostype, deviceid, loginEncrypt);
            if (detailResult.Result === 'success') {
                result = Object.assign(result, detailResult.Item)
            }

            // 예약현황 상세 데이터
            let drivingDetailResult = await ReserveApi.DrivingDetail(DrivingNo, ostype, deviceid, loginEncrypt);
            if (drivingDetailResult.Result === 'success') {
                result =
                    Object.assign(result, drivingDetailResult.Item)
            }


            // 부가서비스 데이터
            let expensesResult = await ReserveApi.GetDrivingExpensesByDrivingNo(DrivingNo, ostype, deviceid, loginEncrypt);
            console.log(expensesResult);
            if (expensesResult.Result === 'success') {

                result = Object.assign(result, { expensesList: expensesResult.ItemList })
            }

            dispatch(init_use_history_native_service(result))
            resolve(true)

        } catch(e) {
            return reject(e)
        }
    })
}

export const getEndListRedux = (cn, page, ostype, deviceid, loginEncrypt) => async dispatch => {
    try {
        dispatch(init_loading_use_history_service());
        let result = await ReserveApi.GetDrivingEndList(cn, page, 0, MAX_PAGE, ostype, deviceid, loginEncrypt)
        if (result.Result === 'success') {
            for (let i in result.ItemList) {
                let tmpDrivingNo = result.ItemList[i].DrivingNo;

                let waypointResult = await ReserveApi.Get_DRVG_STOP_AREA(cn, tmpDrivingNo, ostype, deviceid, loginEncrypt)
                if (waypointResult.Result === 'success') {
                    let tmpWaypointData = {
                        ...waypointResult.Item,
                        RegDt: result.ItemList[i].RegDt
                    }
                    result.ItemList[i] = Object.assign(result.ItemList[i], tmpWaypointData)
                }
                let detailResult = await ReserveApi.GetDrivingEndDetail(tmpDrivingNo, ostype, deviceid, loginEncrypt);
                if (detailResult.Result === 'success') {
                    result.ItemList[i] = Object.assign(result.ItemList[i], detailResult.Item)
                }

                // 예약현황 상세 데이터
                let drivingDetailResult = await ReserveApi.DrivingDetail(tmpDrivingNo, ostype, deviceid, loginEncrypt);
                if (drivingDetailResult.Result === 'success') {
                    result.ItemList[i] = 
                        Object.assign(result.ItemList[i], drivingDetailResult.Item)
                }

                
                // 부가서비스 데이터
                let expensesResult = await ReserveApi.GetDrivingExpensesByDrivingNo(tmpDrivingNo, ostype, deviceid, loginEncrypt);
                if (expensesResult.Result === 'success') {
                    
                    result.ItemList[i] = Object.assign(result.ItemList[i], {expensesList: expensesResult.ItemList})
                }


            }
            dispatch(init_use_history_service(result.ItemList, result.Item.ItemCount));
            dispatch(init_loading_end_use_history_service());
            
        } else {
            await calertPromise(result.Message)
            dispatch(init_loading_end_use_history_service());
        }
    } catch(e) {
        console.log(e)
        dispatch(init_loading_end_use_history_service());
    }
}

export const getEndListPagingRedux = (cn, page, ostype, deviceid, loginEncrypt) => async dispatch => {
    try {
        dispatch(loading_use_history_service());          // 로딩중
        let result = await ReserveApi.GetDrivingEndList(cn, page, 0, MAX_PAGE, ostype, deviceid, loginEncrypt)
        if (result.Result === 'success') {
            for (let i in result.ItemList) {
                let tmpDrivingNo = result.ItemList[i].DrivingNo;
                let waypointResult = await ReserveApi.Get_DRVG_STOP_AREA(cn, tmpDrivingNo, ostype, deviceid, loginEncrypt)
                if (waypointResult.Result === 'success') {
                    let tmpWaypointData = {
                        ...waypointResult.Item,
                        RegDt: result.ItemList[i].RegDt
                    }
                    result.ItemList[i] = Object.assign(result.ItemList[i], tmpWaypointData)
                }
                let detailResult = await ReserveApi.GetDrivingEndDetail(tmpDrivingNo, ostype, deviceid, loginEncrypt);
                if (detailResult.Result === 'success') {
                    result.ItemList[i] = Object.assign(result.ItemList[i], detailResult.Item)
                }

                // 예약현황 상세 데이터
                let drivingDetailResult = await ReserveApi.DrivingDetail(tmpDrivingNo, ostype, deviceid, loginEncrypt);
                if (drivingDetailResult.Result === 'success') {
                    result.ItemList[i] = 
                        Object.assign(result.ItemList[i], drivingDetailResult.Item)
                }

                // 부가서비스 데이터
                let expensesResult = await ReserveApi.GetDrivingExpensesByDrivingNo(tmpDrivingNo, ostype, deviceid, loginEncrypt);
                if (expensesResult.Result === 'success') {
                    result.ItemList[i] = Object.assign(result.ItemList[i], {expensesList: expensesResult.ItemList})
                }
                
            }
            dispatch(paging_use_history_service(result.ItemList));
            dispatch(loading_end_use_history_service());      // 로딩완료
            
        } else {
            await calertPromise(result.Message)
            dispatch(loading_end_use_history_service());      // 로딩완료
        }
    } catch(e) {
        console.log(e)
        dispatch(loading_end_use_history_service());      // 로딩완료
    }
}

// export const cancelReservationRedux = (cn, no, ostype, deviceid, loginEncrypt) => async dispatch => {
//     try {
//         dispatch(loading_use_history_service());          // 로딩중
//         // let cancelResult = await ReserveApi.CancelDrivingReservation( cn, no, ostype, deviceid, loginEncrypt )
//         dispatch(del_use_history_service(no));
//         dispatch(loading_end_use_history_service());      // 로딩완료
//     } catch(e) {
//         console.log(e)
//         dispatch(loading_end_use_history_service());      // 로딩완료
//     }
// }

const initialState = {
    page:1,
    getinfo:[],
    getwaypoint:[],
    loading:0,
    loadingMain:0,
    scrollDataSyncFlag:1,
    totalPage:0,
    getinfoNative:[],
    getwaypointNative:[],
}

export default (state = initialState, action) => {

    switch (action.type) {
        case EMPTY_USE_HISTORY:
            return {
                ...state,
                ...initialState
            };
        case INIT_USE_HISTORY:
            return {
                ...state,
                getinfo: action.payload.obj,
                page: state.page + 1,
                totalPage: action.payload.totalpage
            };
        case GET_USE_HISTORY:
            return {
                ...state,
                ...initialState
            };
        case PAGING_USE_HISTORY:
            return {
                ...state,
                getinfo: state.getinfo.concat(action.payload.obj),
                page: state.page + 1
            };

        case DEL_USE_HISTORY:
            return {
                ...state,
                getinfo: state.getinfo.filter( (data) => {return data.DrivingNo !== action.payload} )
            };
        
        
        case INIT_LOADING_USE_HISTORY:
            return {
                ...state,
                loading:0,
                loadingMain:0
            };

        case INIT_LOADING_END_USE_HISTORY:
            return {
                ...state,
                loading:1,
                loadingMain:1
            };
    
        case LOADING_USE_HISTORY:
            return {
                ...state,
                loading:0,
                scrollDataSyncFlag:0
            };

        case LOADING_END_USE_HISTORY:
            return {
                ...state,
                loading:1,
                scrollDataSyncFlag: (state.getinfo.length >= state.totalPage) ? 0 : 1       // 더이상 불러올 것이 없으면 페이징 처리 하지 않는다.
            };

        case INIT_USE_HISTORY_NATIVE:
            return {
                ...state,
                getinfoNative: action.payload.obj,
            };

        default:
            return state;
    }
};

/*

※참고: 아래는 이전에 UseStateView에서 Cancel할때의 처리이다.
try {
                                        
    this.setState({ loading: 0 })	// 로딩중
    e.preventDefault();
    let { CustomerNo } = store.getState().global_data.loginedData
    let { cookies } = this.props;

    // 예약 취소 API
    let cancelResult = await ReserveApi.CancelDrivingReservation(
        CustomerNo,
        data.DrivingNo,
        cookies.get('ostype'),
        cookies.get('deviceid'),
        cookies.get('login_encrypt')
    )

    // 예약 취소 성공시
    if (cancelResult.Result === 'success') {
        await calertPromise('예약이 취소되었습니다.')

        // 예약 데이터 재 취득(갱신)
        let tmpReserveResult = await ReserveApi.GetDrivingReservationList(
            CustomerNo,
            1,
            0,
            MAX_PAGE,
            cookies.get('ostype'),
            cookies.get('deviceid'),
            cookies.get('login_encrypt')
        );
        if (tmpReserveResult.Result === 'success') {
            this.setState({
                reserveList: tmpReserveResult.ItemList,
                loading: 1
            })
        } else {
            await calertPromise(tmpReserveResult.Message)
            this.setState({ loading: 1 })	// 로딩완료
            this.props.history.replace('/main')
        }

    } else {
        await calertPromise(cancelResult.Message)
        this.setState({ loading: 1 })	// 로딩완료
    }
} catch (e) {
    await calertPromise('예약 취소 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
    this.setState({ loading: 1 })	// 로딩완료
}

*/


/*import moment from 'moment';
import {calert} from '../../lib/Util'

// end 시간 등록

// 구현해야 할 것
// 1. 날짜 선택하게 되면
// 시작시간 종료시간 계산해서 선택 가능한 시간 계산해서 집어넣기
// 2. 시작시간 선택하게 되면 종료시간은

// 초기화
// localStorage 데이터 존재하면 시간 세팅
// 화면 닫혀질때 localStorage에 저장
// time update 메소드
// getCalcDrivingReservation() 함수에 현재 시간 취득
// 시간 Validate

const INIT_TIME_ALL_SELECTOR = "TimePicker/INIT_TIME_ALL_SELECTOR";
const INIT_START_TIME_SELECTOR = "TimePicker/INIT_START_TIME_SELECTOR";
const INIT_END_TIME_SELECTOR = "TimePicker/INIT_END_TIME_SELECTOR";
const CHANGE_START_TIME = "TimePicker/CHANGE_START_TIME";
const CHANGE_END_TIME = "TimePicker/CHANGE_END_TIME";

export const init_time_all_selector_service = () => ({ type: INIT_TIME_ALL_SELECTOR });
export const init_time_start_selector_service = () => ({ type: INIT_START_TIME_SELECTOR });
export const init_time_end_selector_service = () => ({ type: INIT_END_TIME_SELECTOR });
export const change_start_time_service = (obj) => ({ type: CHANGE_START_TIME, payload:{Hour:obj.hour, Minute:obj.minute } });
export const change_end_time_service = (obj) => ({ type: CHANGE_END_TIME, payload:{Hour:obj.hour, Minute:obj.minute } });

const initMoment = moment()

const initialState = {
    startTimeFormat:initMoment.format('HH:mm:00.000'),            //11:11:00.000
    startTimeValue:initMoment.format('A h:mm'),                   //AM 7:30
    startTimeId:'start',                                        // 시간 만들어주는 input 태그의 id값

    endTimeFormat:initMoment.format('HH:mm:00.000'),
    endTimeValue:initMoment.format('A h:mm'),
    endTimeId:'end',
}

export default (state = initialState, action) => {

    switch (action.type) {
        case INIT_TIME_ALL_SELECTOR:
            return {
                ...state,
                ...initialState
            };
        case INIT_START_TIME_SELECTOR:
            return {
                ...state,
                startTimeFormat: initMoment.format('HH:mm:00.000'),
                startTimeValue: initMoment.format('A h:mm'),
            };
        case INIT_END_TIME_SELECTOR:
            return {
                ...state,
                endTimeFormat: initMoment.format('HH:mm:00.000'),
                endTimeValue: initMoment.format('A h:mm'),
            };

        case CHANGE_START_TIME:
            // start 시간 등록
            // start 시간 등록시 end시간에는 start시간 + 1시간 더하기 로직 필요
            return {
                ...state,
                startTimeFormat: moment(`2020-12-25 ${action.payload.Hour}:${action.payload.Minute}`).format('HH:mm:00.000'),
                startTimeValue: moment(`2020-12-25 ${action.payload.Hour}:${action.payload.Minute}`).format('A h:mm'),

                endTimeFormat:moment(`2020-12-25 ${action.payload.Hour}:${action.payload.Minute}`).add(1, 'hours').format('HH:mm:00.000'),
                endTimeValue:moment(`2020-12-25 ${action.payload.Hour}:${action.payload.Minute}`).add(1, 'hours').format('A h:mm'),
            };
        case CHANGE_END_TIME:
            return {
                ...state,
                endTimeFormat: moment(`2020-12-25 ${action.payload.Hour}:${action.payload.Minute}`).format('HH:mm:00.000'),
                endTimeValue: moment(`2020-12-25 ${action.payload.Hour}:${action.payload.Minute}`).format('A h:mm'),
            };
        default:
            return state;
    }
};
*/
// arr.filter((data, idx)=>{console.log(data, idx); return this})
// arr.filter((data, i)=>(i !== idx))

/*
{
type: 'LocationSelector/STEP_OVER_ADD',
stopOverItem: '1'
}

{
type: 'LocationSelector/STEP_OVER_DEL',
stopOverDelItemIdx: '1'
}

{
type: 'LocationSelector/STEP_OVER_UPDATE',
stopOverUpdateData: 'test01',
stopOverUpdateIdx: 1
}
*/

// 배열 저장하는 함수
// function updateObjectInArray(array, action) {
//     return array.map((item, index) => {
//       if (index !== action.stopOverUpdateIdx) {
//           console.log('index !== action.stopOverUpdateIdx result' + item);
//         // This isn't the item we care about - keep it as-is
//         return item
//       }

//       // Otherwise, this is the one we want - return an updated value
//       return action.stopOverUpdateData;


//       /*{
//         ...item,
//         ...action.stopOverUpdateData
//       }*/
//     })
//   }


// handleAddStopover = (e) => {
//     let tmpArr = this.state.stopOverList;
//     let tmpArrLen = this.state.stopOverList.length;

//     if (tmpArrLen >= LIMIT_STOPOVER_COUNT) {
//         alert('경유지는 3개까지 등록 가능합니다.');
//     } else {
//         tmpArr.push(1);
//         this.setState({
//             stopOverList: tmpArr
//         })
//     }
// }

// handleDelStopover = (e) => {
//     let tmpArr = [];

//     for (let i in this.state.stopOverList) {
//         if (e.target.id === i) continue;
//         else tmpArr.push(this.state.stopOverList[i]);
//     }

//     this.setState({
//         stopOverList: tmpArr
//     })
// }

