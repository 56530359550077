// 구글 스프레드시트 연동 API이다.
// 구글 스프레드시트를 활용하여 기능을 추가할 경우 이곳에 추가한다.
import axios from 'axios';
import moment from 'moment';

const parseGoogleSheet = (str) => {
    const firstSplit = str.split('google.visualization.Query.setResponse(')[1]
    const jsonData = JSON.parse(firstSplit.slice(0, firstSplit.length - 2)) 
    return jsonData.table;
}

/*************************************************************
 * 제네시스 쇼퍼드리븐 이벤트
 * 2022-06-01 ~ 2022-06-30
 ************************************************************/

// 07/01 부터는 서비스 하지 않는다.
export const getChauffeurDrivenEventAvailableDate = () => {
    //return moment().isAfter('2022-07-01')   // true는 지났다는 것을 의미.
    return false;
 }


//예약 완료시 쇼퍼드리븐 Reserve 시트에 내용 추가
export const setChauffeurDrivenReserveComplate = (date, Customerno, Drivingno) => {
    let form = new FormData()

    form.append('예약일', moment().format('yyyy-MM-DD HH:mm'))
    form.append('이용일', moment(date).format('yyyy-MM-DD'))
    form.append('고유고객번호', Customerno)
    form.append('고유운행번호', Drivingno)

    return new Promise( (resolve, reject)=>{
        axios({
            url : `https://script.google.com/macros/s/AKfycbzi_nkudpB7YQW2CIbNv-FiUFDy2x-k9qcktcNL9_pSuShmOpqVnWnjYUxZ6tAbNABz/exec?savesheetname=Reserve`,
            method: 'POST',
            timeout: 10000,
            headers:{
                'Content-type': 'application/x-www-form-urlencoded'
            },
            data: form
        }).then( async res => {
            resolve(res.data.result)
            //res.data.result === 'success'
        }).catch( (err) => {
            resolve('fail')
        });
    })
}

// 예약 취소시 쇼퍼드리븐 Cancel 시트에 내용 추가
export const setChauffeurDrivenCancelComplate = (Customerno, Drivingno) => {
    let form = new FormData()

    form.append('취소일', moment().format('yyyy-MM-DD HH:mm'))
    form.append('고유고객번호', Customerno)
    form.append('고유운행번호', Drivingno)

    return new Promise( (resolve, reject)=>{
        axios({
            url : `https://script.google.com/macros/s/AKfycbzi_nkudpB7YQW2CIbNv-FiUFDy2x-k9qcktcNL9_pSuShmOpqVnWnjYUxZ6tAbNABz/exec?savesheetname=Cancel`,
            method: 'POST',
            timeout: 10000,
            headers:{
                'Content-type': 'application/x-www-form-urlencoded'
            },
            data: form
        }).then( async res => {
            resolve(res.data.result)
            //res.data.result === 'success'
        }).catch( (err) => {
            resolve('fail')
        });
    })    
}

// 당일 몇명이 쇼퍼드리븐 신청을 완료했는지의 Count
export const getChauffeurDrivenApplyCountToday = () => {
    // new Date
    let today = moment().format('yyyy-MM-DD')
    let query = `select * where A = DATE'${today}'`;
    return new Promise( async (resolve, reject)=>{
        let result = await axios.get(`https://docs.google.com/spreadsheets/d/1mQxN2S2NdnOgpP_HOf_mVyeBlJAlzAwlDdRFW9JES0I/gviz/tq?sheet=Result&tq=${query}`)
        resolve(parseGoogleSheet(result.data).rows.length)
    })    
}

// 쇼퍼드리븐 신청 가능한 대수
export const getChauffeurDrivenLimitCount = (selDate) => {
    let today = moment(selDate).format('yyyy-MM-DD')
    let query = `select B where A = DATE'${today}'`;
    return new Promise( async (resolve, reject)=>{
        let result = await axios.get(`https://docs.google.com/spreadsheets/d/1mQxN2S2NdnOgpP_HOf_mVyeBlJAlzAwlDdRFW9JES0I/gviz/tq?sheet=AdminSetting&tq=${query}`)
        resolve(parseGoogleSheet(result.data).rows[0].c[0].v)
    })    
}

export const getChauffeurDrivenApplyCountDate = (date) => {
    // new Date
    let today = moment(date).format('yyyy-MM-DD')
    let query = `select * where A = DATE'${today}'`;
    return new Promise( async (resolve, reject)=>{
        let result = await axios.get(`https://docs.google.com/spreadsheets/d/1mQxN2S2NdnOgpP_HOf_mVyeBlJAlzAwlDdRFW9JES0I/gviz/tq?sheet=Result&tq=${query}`)
        resolve(parseGoogleSheet(result.data).rows.length)
    })    
}

// 해당 DrivingNo가 쇼퍼드리븐을 신청했는지 여부 체크
export const getChauffeurDrivenApplyStatus = (DrivingNo) => {
    // new Date
    let query = `select * where C = ${DrivingNo}`;
    return new Promise( async (resolve, reject)=>{
        let result = await axios.get(`https://docs.google.com/spreadsheets/d/1mQxN2S2NdnOgpP_HOf_mVyeBlJAlzAwlDdRFW9JES0I/gviz/tq?sheet=Result&tq=${query}`)
        resolve(parseGoogleSheet(result.data).rows.length)
    })    
}