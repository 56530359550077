import React, { Component } from 'react';
import { createGlobalStyle } from "styled-components";
import {global_data_async} from '../../modules/Global/global_data'
import store from '../../reduxStore'
import {withCookies} from 'react-cookie'
import AccountApi from '../../lib/AccountApi'
import Loading from '../../components/Common/Loading'
import {calert, calertPromise} from '../../lib/Util'
import RnInterface from '../../lib/RnInterface'
import HeaderJoin from '../../components/Common/HeaderJoin'
import HeaderJoinCancel from '../../components/Common/HeaderJoinCancel'
import { withLastLocation } from 'react-router-last-location';

import '../../css/default.css';
import '../../css/member.css';

import btn_cancel from "../../img/btn/btn_cancel.svg"
const GlobalStyle = createGlobalStyle`

body {background-color: #f3f4f8;}

`;


// 이 화면은 첫 로그인때만 표시되고 이후에는 표시되면 안된다.
// 회원가입 이후 자동 로그인을 클릭하는 시점에 cookie에 firstlogin flag를 추가하고,
// 이 

/*

검색 : class=
치환 : className=

검색 : br
치환 : br/

검색 : <input.*[^>]
치환 : $0/

검색 : <img.*[^>]
치환 : $0 alt=""/
*/

// 

const CREATE_FLAG = 0;
const MODIFY_FLAG = 1;

class FirstStepCarInfo1View extends Component {
    state = {
        carInfo:'',
        carNo: '',
        loading:1
    }

    async componentDidMount() {
        try {
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            const { flag } = this.props.match.params;
            if (Number(flag) === MODIFY_FLAG) {
                const { CarNo, CarName } = store.getState().global_data.loginedData
                this.setState({
                    carInfo: CarName,
                    carNo: CarNo,
                    loading: 1
                })
            }
        }
        catch (e) {
            console.log(e)
        }

    }


    changeCarInfo = (e) => {
        this.setState({carInfo: e.target.value})
    }
    changeCarNo = (e) => {
        this.setState({carNo: e.target.value})
    }

    handleNext = async (params) => {
        this.setState({loading:0})
        let {cookies} = this.props;
        const {flag} = this.props.match.params;
        
        //로직 구현
        let CustomerNo = store.getState().global_data.loginedData.CustomerNo
        let CustomerName = store.getState().global_data.loginedData.CustomerName
        let Email = store.getState().global_data.loginedData.Email
        let PhoneNumber = store.getState().global_data.loginedData.PhoneNumber
        let HomeAddress = store.getState().global_data.loginedData.HomeAddress

        // 변경 데이터 세팅
        let formData = {
            CustomerNo,
            CustomerName,
            Email,
            PhoneNumber,
            HomeAddress,
            CarName: this.state.carInfo,    // 이 값만 변경한다.
            CarNo: this.state.carNo         // 이 값만 변경한다.
        }

        try {
            // 업데이트 API 실행
            let updateResult = await AccountApi.UpdateCustomer(
                formData, 
                cookies.get('ostype'),
                cookies.get('deviceid'),
                cookies.get('login_encrypt')
            )

            // 업데이트 성공시
            if (updateResult.Result === 'success') {
                // 로그인 시도(로그인 성공시 데이터 취득함.)
                let loginResult = await AccountApi.requestLogin(
                    cookies.get('email_id'), 
                    cookies.get('hashcode'), 
                    cookies.get('account_type'), 
                    cookies.get('ostype'), 
                    cookies.get('deviceid')
                );
                    
                if (loginResult.Result === 'success') {
                    // 사용자 정보를 redux, cookie에 세팅한다.
                    if (Number(flag) === MODIFY_FLAG) {
                        this.props.history.goBack()
                        // localStorage에 갱신
                        localStorage.setItem('mosiler_cookie', JSON.stringify({
                            account_type: loginResult.Item.AccountType,
                            deviceid: loginResult.Item.DeviceId,
                            email_id: loginResult.Item.UserId,
                            login_encrypt: loginResult.Item.LoginEncrypt,
                            ostype: loginResult.Item.OsType,
                            auto_login_chk: Number(cookies.get('auto_login_chk')),
                            hashcode: cookies.get('hashcode'),
                        }))
                        AccountApi.updateMyInfo(loginResult, cookies.get('hashcode'), cookies);

                        // 이전 화면이 예약 화면이라면 removeItem을 지워줘야 다시 예약화면으로 돌아갔을 때 데이터가 빈값이 안나오게 된다.
                        if (this.props.lastLocation.pathname.indexOf('reserve') !== -1) {
                            localStorage.removeItem('reserve_temp_carNo');
                            localStorage.removeItem('reserve_temp_carModel');
                        }
                        

                        await calertPromise('설정이 완료 되었습니다.')
                    } else {
                        AccountApi.updateMyInfo(loginResult, '', cookies);
                        this.props.history.replace('/first_end/0')
                    }
                } 
                // 로그인 실패시
                else {
                    await calertPromise('데이터 취득에 실패하였습니다.')
                    this.setState({loading:1})
                }
            } 
            // 업데이트 실패시
            else {
                await calertPromise('업데이트에 실패하였습니다.')
                this.setState({loading:1})
            }
        } 
        catch(e) {
            await calertPromise(JSON.stringify(e));
            this.setState({loading:1})
        }
        
       
        console.log(formData)
        // this.props.history.replace('/first_end')
    }

    handleBack = (params) => {
        const {flag} = this.props.match.params;
        if (Number(flag) === MODIFY_FLAG) {
            this.props.history.goBack()
        }
        else {
            this.props.history.replace('/main')
        }
        
    }

    render() {
        const { flag } = this.props.match.params;
        return (
            <>
                <GlobalStyle />
                {!this.state.loading ? (<Loading />) : ''}
                {Number(flag) === MODIFY_FLAG ? (
                    <HeaderJoin
                        onBackButton={this.handleBack}
                        title={'차량정보 변경'}
                    />
                ) : (
                        <HeaderJoinCancel
                            onBackButton={this.handleBack}
                            title={'차량정보'}
                        />
                    )}

                <main id="Contents">
                    <div className="component-wrap inner-container">
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">차량모델</label>									
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <input type="text" onClick={this.handleClickAddress} onChange={this.changeCarInfo} value={this.state.carInfo} placeholder="차량의 모델명 ex: BMW 7" className="input type1" />
                                    </div>
                                </div>	
                            </div>                                               
                        </div>
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">차량번호</label>									
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <input type="text" onChange={this.changeCarNo} value={this.state.carNo} placeholder="차량의 번호판 ex: 123가 4567" className="input type1" />
                                    </div>
                                </div>	
                            </div>                                               
                        </div>                        
                    </div>    
                    <div className="component-wrap">
                        <div className="btn-area btn-group-fixed horizontal">
                            <button type="button" className="btn large secondary liner text-bold" onClick={this.handleBack}><span>나중에</span></button>
                            <button type="button" className="btn large secondary text-bold" onClick={this.handleNext}><span>저장</span></button>
                        </div>
                    </div>
                </main>
            </>
        );
    }
};

export default withLastLocation(withCookies(FirstStepCarInfo1View));
