import axios from 'axios';
import { NAVER_KEY, NAVER_CLIENT_ID } from "../../constantData";
import { calert, calertPromise } from '../../lib/Util';

const INIT_LOCATION_SELECTOR = "LocationSelector/INIT_LOCATION_SELECTOR";

const START_LOCATION_AIR = "LocationSelector/START_LOCATION_AIR";       // 공항 홈 발렛 전용
const END_LOCATION_AIR = "LocationSelector/END_LOCATION_AIR";           // 공항 홈 발렛 전용
const SWITCHING_LOCATION_AIR = "LocationSelector/SWITCHING_LOCATION_AIR";           // 공항 홈 발렛 전용

const START_LOCATION = "LocationSelector/START_LOCATION";
const STEP_OVER_ADD = "LocationSelector/STEP_OVER_ADD";
const STEP_OVER_UPDATE = "LocationSelector/STEP_OVER_UPDATE";
const STEP_OVER_DEL = "LocationSelector/STEP_OVER_DEL";
const END_LOCATION = "LocationSelector/END_LOCATION";

const COPY_ADDRESS = "LocationSelector/COPY_ADDRESS"; //출발지, 종료지 동일_jane

const ALL_LOCATION_SAVE = "LocationSelector/ALL_LOCATION_SAVE";     // 최근장소/경로

const INIT_WAYPOINT = {
    waypointAddress:'',
    waypointLat:'0',
    waypointLng:'0'
}

export const start_location_air_service = (data) => ({ type: START_LOCATION_AIR, optionState: data });    // 공항 홈 발렛 전용 (시작지점)
export const end_location_air_service = (data) => ({ type: END_LOCATION_AIR, optionState: data });      // 공항 홈 발렛 전용    (종료지점)
export const switching_location_air_service = () => ({ type: SWITCHING_LOCATION_AIR });                 // 공항 홈 발렛 전용    (공항 선택의 시작지 종료지 스위칭)

export const init_location_selector_service = () => ({ type: INIT_LOCATION_SELECTOR });
export const start_location_service = (data) => ({ type: START_LOCATION, startData: data });
export const step_over_add_service = (data) => ({ type: STEP_OVER_ADD, stopOverItem: data });
export const step_over_update_service = (idx, data) => ({ type: STEP_OVER_UPDATE, stopOverUpdateData: data, stopOverUpdateIdx: idx });
export const step_over_del_service = (idx) => ({ type: STEP_OVER_DEL, stopOverDelItemIdx: idx });
export const end_location_service = (data) => ({ type: END_LOCATION, endData: data });

export const copy_address_service = (data) => ({ type: COPY_ADDRESS, chkData: data }); //출발지, 종료지 동일_jane

export const all_location_save = (data) => ({ type: ALL_LOCATION_SAVE, allLocationData: data }); // 최근장소/경로

const LIMIT_STOPOVER_COUNT = 3;

let jtest = (textData) => {
    window.jQuery('#lat').text(textData); 
}

function updateObjectInArray(array, action) {
    return array.map((item, index) => {
      if (index !== action.stopOverUpdateIdx) {
          console.log('index !== action.stopOverUpdateIdx result' + item);
        // This isn't the item we care about - keep it as-is
        return item
      }
      
      // Otherwise, this is the one we want - return an updated value
      return action.stopOverUpdateData;
      
      
      /*{
        ...item,
        ...action.stopOverUpdateData
      }*/
    })
  }
  
function airSelectorStart (optionState) {
    let retObj = {
        startLocationAddress: '',
        startLocationLat: '',
        startLocationLng: '',
    };
/* 2020/12/30 카톡 대화
[최진원 대표] [오전 10:59] 
김포공항국내선청사 37.55953000539242, 126.8030046953531
김포공항국제선청사 37.565364022875, 126.8007516395725

인천공항1터미널 37.44953875913173, 126.45091876367836
인천공항2터미널 37.486662409022735, 126.43970559444405
*/
    switch(optionState) {
        case '0':
            retObj = {
                startLocationAddress: '',
                startLocationLat: '',
                startLocationLng: '',
            };
            break;
        case '1':         // 김포공항 1청사
            retObj = {
                startLocationAddress: '김포공항 국내선 청사',
                startLocationLat: 37.55953000539242,
                startLocationLng: 126.8030046953531,
            };
            break;
        case '2':         // 김포공항 2청사
            retObj = {
                startLocationAddress: '김포공항 국제선 청사',
                startLocationLat: 37.565364022875,
                startLocationLng: 126.8007516395725,
            };
            break;
        case '3':         // 인천공항 1청사
            retObj = {
                startLocationAddress: '인천공항 1터미널',
                startLocationLat: 37.44953875913173,
                startLocationLng: 126.45091876367836,
            };
            break;
        case '4':         // 인천공항 2청사
            retObj = {
                startLocationAddress: '인천공항 2터미널',
                startLocationLat: 37.486662409022735,
                startLocationLng: 126.43970559444405,
            };
            break;
        default:
            break;
    }
    return retObj;
}


function airSelectorEnd (optionState) {
    let retObj = {
        endLocationAddress: '',
        endLocationLat: '',
        endLocationLng: '',
    };
/* 2020/12/30 카톡 대화
[최진원 대표] [오전 10:59] 
김포공항국내선청사 37.55953000539242, 126.8030046953531
김포공항국제선청사 37.565364022875, 126.8007516395725

인천공항1터미널 37.44953875913173, 126.45091876367836
인천공항2터미널 37.486662409022735, 126.43970559444405
*/
    switch(optionState) {
        case '0':
            retObj = {
                endLocationAddress: '',
                endLocationLat: '',
                endLocationLng: '',
            };
            break;
        case '1':         // 김포공항 1청사
            retObj = {
                endLocationAddress: '김포공항 국내선 청사',
                endLocationLat: 37.55953000539242,
                endLocationLng: 126.8030046953531,
            };
            break;
        case '2':         // 김포공항 2청사
            retObj = {
                endLocationAddress: '김포공항 국제선 청사',
                endLocationLat: 37.565364022875,
                endLocationLng: 126.8007516395725,
            };
            break;
        case '3':         // 인천공항 1청사
            retObj = {
                endLocationAddress: '인천공항 1터미널',
                endLocationLat: 37.44953875913173,
                endLocationLng: 126.45091876367836,
            };
            break;
        case '4':         // 인천공항 2청사
            retObj = {
                endLocationAddress: '인천공항 2터미널',
                endLocationLat: 37.486662409022735,
                endLocationLng: 126.43970559444405,
            };
            break;
        default:
            break;
    }
    return retObj;
}

const initialState = {
    AirOptionState:'0',                     // 공항 홈 발렛
    airStartEndState : true,                // 공항 홈 발렛 공항 선택 여부를 스위칭. 
                                            // true : 시작지를 공항선택으로 대체, false 종료지를 공항선택으로 대체

    startLocationAddress: '',
    startLocationLat: 0,
    startLocationLng: 0,

    stopOverList: [],

    endLocationAddress: '',
    endLocationLat: 0,
    endLocationLng: 0,

    //경유지 정보_jane
    waypointAddress: '',
    waypointLat: 0,
    waypointLng: 0,
    wayPoint: [],

    //출발지, 종료지 동일_jane
    checkSwitch: false,
    endSaveAddress: '',
    endSaveLat: 0,
    endSaveLng: 0
}
// handleAddStopover = (e) => {
//     let tmpArr = this.state.stopOverList;
//     let tmpArrLen = this.state.stopOverList.length;

//     if (tmpArrLen >= LIMIT_STOPOVER_COUNT) {
//         alert('경유지는 3개까지 등록 가능합니다.');
//     } else {
//         tmpArr.push(1);
//         this.setState({
//             stopOverList: tmpArr
//         })
//     }
// }

// handleDelStopover = (e) => {
//     let tmpArr = [];
    
//     for (let i in this.state.stopOverList) {
//         if (e.target.id === i) continue;
//         else tmpArr.push(this.state.stopOverList[i]);
//     }

//     this.setState({
//         stopOverList: tmpArr
//     })
// }

function switchLocationData (state) {
    // true => false일때 (출발 공항 선택 => 도착 공항 선택으로 변경될때 )
    if (state.airStartEndState === true) {
        return {
            // EndLocation 데이터를 StartLocation데이터로 이동
            startLocationAddress: state.endLocationAddress,
            startLocationLat: state.endLocationLat,
            startLocationLng: state.endLocationLng,
            // EndLocation 데이터는 초기화.
            endLocationAddress: state.startLocationAddress,
            endLocationLat: state.startLocationLat,
            endLocationLng: state.startLocationLng,
        }
    }
    // false => true일때 (도착 공항 선택 => 출발 공항 선택)
    return {
            // StartLocation 데이터는 초기화.    
            startLocationAddress: state.endLocationAddress,
            startLocationLat: state.endLocationLat,
            startLocationLng: state.endLocationLng,
            
            // StartLocation 데이터를 EndLocation데이터로 이동
            endLocationAddress: state.startLocationAddress,
            endLocationLat: state.startLocationLat,
            endLocationLng: state.startLocationLng,
    }
}

//출발지, 종료지 동일_jane
function copyAddress (state, chkData) {
    // 체크일 때
    if (chkData === true) {
        if (state.stopOverList.length < 1) {
            if(!state.startLocationAddress){
                calert('왕복일 경우 출발지 필수입니다.');
                return {
                    checkSwitch: ''
                }
            }

            return {
                endLocationAddress: state.startLocationAddress,
                endLocationLat: state.startLocationLat,
                endLocationLng: state.startLocationLng,
                stopOverList: [...state.stopOverList, INIT_WAYPOINT]
            }
        }
        
        return {
            endLocationAddress: state.startLocationAddress,
            endLocationLat: state.startLocationLat,
            endLocationLng: state.startLocationLng
        }
    // 체크를 해제할 경우
    }
    return {
        stopOverList: [],
        endLocationAddress: state.endSaveAddress,
        endLocationLat: state.endSaveLat,
        endLocationLng: state.endSaveLng
    }
}

export default (state = initialState, action) => {
    
    switch (action.type) {
        case INIT_LOCATION_SELECTOR:
            return {
                ...state,
                ...initialState
            };
        case SWITCHING_LOCATION_AIR:
            return {
                ...state,
                ...switchLocationData(state),
                airStartEndState: !state.airStartEndState,
                AirOptionState: state.AirOptionState,
            };

        case START_LOCATION_AIR:
            return {
                ...state,
                AirOptionState: action.optionState,
                ...airSelectorStart(action.optionState)
            };
        case END_LOCATION_AIR:
            return {
                ...state,
                AirOptionState: action.optionState,
                ...airSelectorEnd(action.optionState)
            };

        case START_LOCATION:
            if (state.checkSwitch){
                return {
                    ...state,
                    startLocationAddress: action.startData.startLocationAddress,
                    startLocationLat: action.startData.startLocationLat,
                    startLocationLng: action.startData.startLocationLng,
                    endLocationAddress: action.startData.startLocationAddress,
                    endLocationLat: action.startData.startLocationLat,
                    endLocationLng: action.startData.startLocationLng
                };
            } else{
                return {
                    ...state,
                    startLocationAddress: action.startData.startLocationAddress,
                    startLocationLat: action.startData.startLocationLat,
                    startLocationLng: action.startData.startLocationLng
                };
            }
        
        case STEP_OVER_ADD:
            if (state.stopOverList.length >= LIMIT_STOPOVER_COUNT) {
                
                return state;
            }
            return {
                ...state,
                stopOverList: [...state.stopOverList, action.stopOverItem]
            };

        case STEP_OVER_UPDATE:
            return {
                ...state,
                waypointLat: action.stopOverUpdateData.waypointLat,
                waypointLng: action.stopOverUpdateData.waypointLng,
                stopOverList: updateObjectInArray(state.stopOverList, action)
            };

        case STEP_OVER_DEL:
            if (state.checkSwitch){
                if (state.stopOverList.length < 2) {
                    calert('왕복일 경우 경유지 필수입니다.');
                    return {
                        ...state
                    }
                }
                return {
                    ...state,
                    stopOverList: state.stopOverList.filter( (data, i) => {return i !== action.stopOverDelItemIdx} )
                };
            }
            return {
                ...state,
                stopOverList: state.stopOverList.filter( (data, i) => {return i !== action.stopOverDelItemIdx} )
            }; 
        
        case END_LOCATION:
            if (state.checkSwitch){
                return {
                    ...state,
                    endLocationAddress: action.endData.endLocationAddress,
                    endLocationLat: action.endData.endLocationLat,
                    endLocationLng: action.endData.endLocationLng,
                    startLocationAddress: action.endData.endLocationAddress,
                    startLocationLat: action.endData.endLocationLat,
                    startLocationLng: action.endData.endLocationLng
                };
            } else{
                return {
                    ...state,
                    endLocationAddress: action.endData.endLocationAddress,
                    endLocationLat: action.endData.endLocationLat,
                    endLocationLng: action.endData.endLocationLng
                };
            }

        //출발지, 종료지 동일_jane
        case COPY_ADDRESS:
            return{
                ...state,
                checkSwitch: action.chkData,
                endSaveAddress: state.endLocationAddress,
                endSaveLat: state.endLocationLat,
                endSaveLng: state.endLocationLng,
                ...copyAddress(state, action.chkData)
            };

        //최근 장소 경로
        case ALL_LOCATION_SAVE:
            return{
                ...state,
                startLocationAddress: action.allLocationData.startLocation,
                startLocationLat: action.allLocationData.startLat,
                startLocationLng: action.allLocationData.startLng,
                endLocationAddress: action.allLocationData.endLocation,
                endLocationLat: action.allLocationData.endLat,
                endLocationLng: action.allLocationData.endLng,
                stopOverList: action.allLocationData.stopOverList,
                checkSwitch: action.allLocationData.checkSwitch,
                
            };

        default:
            return state;
    }
};

// arr.filter((data, idx)=>{console.log(data, idx); return this})
// arr.filter((data, i)=>(i !== idx))

/*
{
type: 'LocationSelector/STEP_OVER_ADD',
stopOverItem: '1'
}

{
type: 'LocationSelector/STEP_OVER_DEL',
stopOverDelItemIdx: '1'
}

{
type: 'LocationSelector/STEP_OVER_UPDATE',
stopOverUpdateData: 'test01', 
stopOverUpdateIdx: 1
}
*/