// 리스트 취득할때 메뉴 -> 예약현황을 열었을때만 리스트를 취득하게 하고, 
// 상세화면 -> 예약현황으로 들어갔을때에도 데이터를 취득하지 않고 유지되도록 재설계가 필요함.
// 데이터 저장소는 redux를 사용. 
// 데이터가 존재하면 기존 데이터 활용
// 데이터가 하나도 없으면 API 통해 취득
// 삭제 했을 경우는 삭제 API 실행 후 success라고 나왔다면 현재redux에 저장된 데이터중 drivingno와 일치하는 노드를 삭제한다.
// 삭제 실패했을 경우에는 데이터를 유지한다. 삭제시, 경유지도 신경쓴다.
// 스크롤할때 페이징 처리가 필요하다. (witive때 코드 참고한다.)
// useState가 완료되면 바로 history도 적용한다. (구조는 비슷하기에 조금만 수정하면될듯.)

// import moment from 'moment';


// end 시간 등록

import ReserveApi from '../../lib/ReserveApi'
import CouponApi from '../../lib/CouponApi'
import { calertPromise } from '../../lib/Util';

const EMPTY_USE_STATE = "UseState/EMPTY_USE_STATE";
const INIT_USE_STATE = "UseState/INIT_USE_STATE";
const GET_USE_STATE = "UseState/GET_USE_STATE";
const PAGING_USE_STATE = "UseState/PAGING_USE_STATE";
const DEL_USE_STATE = "UseState/DEL_USE_STATE";
const INIT_PAGE_USE_STATE = "UseState/INIT_PAGE_USE_STATE";
const INIT_CARD_DATA_USE_STATE = "UseState/INIT_CARD_DATA";


const INIT_LOADING_USE_STATE = "UseState/INIT_LOADING_USE_STATE";
const INIT_LOADING_END_USE_STATE = "UseState/INIT_LOADING_END_USE_STATE";
const LOADING_USE_STATE = "UseState/LOADING_USE_STATE";
const LOADING_END_USE_STATE = "UseState/LOADING_END_USE_STATE";

const LOADING_ONLY_USE_STATE = "UseState/LOADING_ONLY_USE_STATE";
const LOADING_END_ONLY_USE_STATE = "UseState/LOADING_END_ONLY_USE_STATE";

const INIT_USE_STATE_NATIVE = "UseState/INIT_USE_STATE_NATIVE";

const MAX_PAGE = 4;

export const init_use_state_native_service = (_obj) => ({ type: INIT_USE_STATE_NATIVE, payload: {obj: _obj} });

export const empty_use_state_service = () => ({ type: EMPTY_USE_STATE });
export const init_use_state_service = (_obj, allpage) => ({ type: INIT_USE_STATE, payload: {obj: _obj, totalpage: allpage} });
export const get_use_state_service = () => ({ type: GET_USE_STATE });
export const paging_use_state_service = (_obj) => ({ type: PAGING_USE_STATE, payload: {obj: _obj} });
export const del_use_state_service = (no) => ({ type: DEL_USE_STATE, payload: no });
export const init_page_user_state_service = () => ({ type: INIT_PAGE_USE_STATE });
export const init_card_data_use_state_service = (data) => ({ type: INIT_CARD_DATA_USE_STATE, payload: data });


export const init_loading_use_state_service = () => ({ type: INIT_LOADING_USE_STATE });
export const init_loading_end_use_state_service = () => ({ type: INIT_LOADING_END_USE_STATE });
export const loading_use_state_service = () => ({ type: LOADING_USE_STATE });
export const loading_end_use_state_service = () => ({ type: LOADING_END_USE_STATE });

export const loading_only_use_state_service = () => ({ type: LOADING_ONLY_USE_STATE });
export const loading_end_only_use_state_service = () => ({ type: LOADING_END_ONLY_USE_STATE });



// redux Thunk에 대한 설명
// https://velog.io/@eomttt/Redux-%EC%A0%81%EC%9A%A9%ED%95%98%EA%B8%B0-Thunk-Saga

const timeout = () => {
    return new Promise((resolve)=>{
        setTimeout(()=>{
            resolve(true)
        }, 1000);
    })
}

const getCustomerDefaultCard = (cn, page, ostype, deviceid, loginEncrypt) => {
    return new Promise(async (resolve, reject) => {
        try {
            let defaultPaymentCardData = await ReserveApi.GetBasicPaymentCards(
                cn, ostype, deviceid, loginEncrypt
            )
            // 기본카드가 있을 경우
            if (defaultPaymentCardData.Item && defaultPaymentCardData.Result === 'success') {
                resolve(defaultPaymentCardData.Item)
            }
            // 기본카드가 없을 경우
            else {
                resolve({})
            }
        }
        catch (e) {
            return reject(e);
        }
    })
}

export const getReservationListItemPromise = (cn, DrivingNo, ostype, deviceid, loginEncrypt) => dispatch => {
    return new Promise( async (resolve,reject)=>{
        try {
            let result={
                
            };
            


            // 예약현황 상세 데이터
            let drivingDetailResult = await ReserveApi.DrivingDetail(DrivingNo, ostype, deviceid, loginEncrypt);
            if (drivingDetailResult.Result === 'success') {
                result =
                    Object.assign(result, drivingDetailResult.Item)
            }

            let waypointResult = await ReserveApi.Get_DRVG_STOP_AREA(cn, DrivingNo, ostype, deviceid, loginEncrypt)
            if (waypointResult.Result === 'success') {
                let tmpWaypointData = {
                    ...waypointResult.Item,
                    // RegDt: result.ItemList[i].RegDt
                }
                result = Object.assign(result, tmpWaypointData)
            }


            // 부가서비스 데이터
            let expensesResult = await ReserveApi.GetDrivingExpensesByDrivingNo(DrivingNo, ostype, deviceid, loginEncrypt);
            console.log(expensesResult);
            if (expensesResult.Result === 'success') {

                result = Object.assign(result, { expensesList: expensesResult.ItemList })
            }

            dispatch(init_use_state_native_service(result))
            resolve(true)

        } catch(e) {
            return reject(e)
        }
    })
}

export const getReservationListRedux = (cn, page, ostype, deviceid, loginEncrypt) => async dispatch => {
    try {
        dispatch(init_loading_use_state_service());
        dispatch(init_card_data_use_state_service(await getCustomerDefaultCard(cn, page, ostype, deviceid, loginEncrypt)));

        let result = await ReserveApi.GetDrivingReservationList(cn, page, 0, MAX_PAGE, ostype, deviceid, loginEncrypt)
        if (result.Result === 'success') {
            for (let i in result.ItemList) {
                
                // 예약현황 상세 데이터
                let detailResult = await ReserveApi.DrivingDetail(result.ItemList[i].DrivingNo, ostype, deviceid, loginEncrypt);
                if (detailResult.Result === 'success') {
                    result.ItemList[i] = Object.assign(result.ItemList[i], detailResult.Item)
                }

                // 경유지 데이터
                let waypointResult = await ReserveApi.Get_DRVG_STOP_AREA(cn, result.ItemList[i].DrivingNo, ostype, deviceid, loginEncrypt)
                if (waypointResult.Result === 'success') {
                    let tmpWaypointData = {
                        ...waypointResult.Item,
                        RegDt: result.ItemList[i].RegDt
                    }
                    result.ItemList[i] = Object.assign(result.ItemList[i], tmpWaypointData)
                }

                // 부가서비스 데이터
                let expensesResult = await ReserveApi.GetDrivingExpensesByDrivingNo(result.ItemList[i].DrivingNo, ostype, deviceid, loginEncrypt);
                if (expensesResult.Result === 'success') {
                    result.ItemList[i] = Object.assign(result.ItemList[i], {expensesList: expensesResult.ItemList})
                }

                // 파트너 리스트 표시
                let getDriverReqListResult = await ReserveApi.GetDrivingRequestList(result.ItemList[i].DrivingNo, ostype, deviceid, loginEncrypt);
                if (expensesResult.Result === 'success') {
                    result.ItemList[i] = Object.assign(result.ItemList[i], {driverRequestList: getDriverReqListResult.ItemList})
                }

            }
            dispatch(init_use_state_service(result.ItemList, result.Item.ItemCount));
            dispatch(init_loading_end_use_state_service());
            
        } else {
            await calertPromise(result.Message)
            dispatch(init_loading_end_use_state_service());
        }
    } catch(e) {
        console.log(e)
        dispatch(init_loading_end_use_state_service());
    }
}

export const getReservationListPagingRedux = (cn, page, ostype, deviceid, loginEncrypt) => async dispatch => {
    try {
        dispatch(loading_use_state_service());          // 로딩중
        let result = await ReserveApi.GetDrivingReservationList(cn, page, 0, MAX_PAGE, ostype, deviceid, loginEncrypt)
        if (result.Result === 'success') {
            for (let i in result.ItemList) {
                
                // 예약현황 상세 데이터
                let detailResult = await ReserveApi.DrivingDetail(result.ItemList[i].DrivingNo, ostype, deviceid, loginEncrypt);
                if (detailResult.Result === 'success') {
                    result.ItemList[i] = Object.assign(result.ItemList[i], detailResult.Item)
                }
                // 경유지 데이터
                let waypointResult = await ReserveApi.Get_DRVG_STOP_AREA(cn, result.ItemList[i].DrivingNo, ostype, deviceid, loginEncrypt)
                if (waypointResult.Result === 'success') {
                    let tmpWaypointData = {
                        ...waypointResult.Item,
                        RegDt: result.ItemList[i].RegDt
                    }
                    result.ItemList[i] = Object.assign(result.ItemList[i], tmpWaypointData)
                }
                // 부가서비스 데이터
                let expensesResult = await ReserveApi.GetDrivingExpensesByDrivingNo(result.ItemList[i].DrivingNo, ostype, deviceid, loginEncrypt);
                if (expensesResult.Result === 'success') {
                    result.ItemList[i] = Object.assign(result.ItemList[i], {expensesList: expensesResult.ItemList})
                }

                // 파트너 리스트 표시
                let getDriverReqListResult = await ReserveApi.GetDrivingRequestList(result.ItemList[i].DrivingNo, ostype, deviceid, loginEncrypt);
                if (expensesResult.Result === 'success') {
                    result.ItemList[i] = Object.assign(result.ItemList[i], {driverRequestList: getDriverReqListResult.ItemList})
                }
            }
            dispatch(paging_use_state_service(result.ItemList));
            dispatch(loading_end_use_state_service());      // 로딩완료
            
        } else {
            await calertPromise(result.Message)
            dispatch(loading_end_use_state_service());      // 로딩완료
        }
    } catch(e) {
        console.log(e)
        dispatch(loading_end_use_state_service());      // 로딩완료
    }
}

// cancelReservationRedux의 프로미스 버전. 
// UseStateDetailView 측에는 Redux방식의 뷰 방식을 사용하고 있지 않기 때문에 수동적으로 처리해야할 필요가 있다.
// (Detail화면에서 예약 취소 때문에 container만들고 이것저것 만들려면 공수도 많이 소요되고, 취소를 눌렀을때는 어차피 이전 화면으로 돌아가기 때문에)
export const cancelReservationReduxPromise = (cn, no, data, carddata, ostype, deviceid, loginEncrypt) => dispatch => {
    return new Promise( async (resolve,reject)=>{
        try {
            dispatch(loading_use_state_service());          // 로딩중
            
            // 제휴카드이며 1599 쿠폰으로 할인 받았을 경우.
            if (carddata.PsCouponId !== null && carddata.MallReserved !== '' && data.CouponId === 1599) {
                let cancelCoupon = await CouponApi.CancelCouponEBridge(
                    cn, carddata.PsCouponId, carddata.MallReserved, ostype, deviceid, loginEncrypt
                )

                if (cancelCoupon && cancelCoupon.success) {
                    // 성공시 로직
                    let cancelResult = await ReserveApi.CancelDrivingReservation( cn, no, ostype, deviceid, loginEncrypt )
                    if (cancelResult.Result === 'success') {
                        await calertPromise('예약 취소가 완료되었습니다.')
                        
                        // 페이지를 초기화시킨다.
                        dispatch(init_page_user_state_service())
                        // 예약이 취소되면 1페이지 부터 리스트를 갱신하도록 처리한다.
                        dispatch( getReservationListRedux(cn, 1, ostype, deviceid, loginEncrypt) );
                        // 기존에 해당 노드만 삭제하는 처리는 하지 않는다.
                        // dispatch(del_use_state_service(no));
                        
                        dispatch(loading_end_use_state_service());      // 로딩완료
                        resolve(true)
                    } 
                    else {
                        if (cancelResult.Message.indexOf('운행중 예약')) {
                            await calertPromise('운행을 시작한 예약건은 취소하실 수 없습니다.')
                        } else {
                            await calertPromise(cancelResult.Message)
                        }
                        
                        dispatch(loading_end_use_state_service());      // 로딩완료
                        resolve(false)
                    }       
                } 
                else {
                    // 실패시 로직
                    await calertPromise('예약시 적용된 쿠폰 취소 처리에 실패했습니다. 잠시후 다시 시도해주세요.')
                    dispatch(loading_end_use_state_service());      // 로딩완료
                    resolve(false)
                }
            } 
            // 상기 이외
            else {
                let cancelResult = await ReserveApi.CancelDrivingReservation(cn, no, ostype, deviceid, loginEncrypt)
                if (cancelResult.Result === 'success') {
                    await calertPromise('예약 취소가 완료되었습니다.')

                    // 페이지를 초기화시킨다.
                    dispatch(init_page_user_state_service())
                    // 예약이 취소되면 1페이지 부터 리스트를 갱신하도록 처리한다.
                    dispatch( getReservationListRedux(cn, 1, ostype, deviceid, loginEncrypt) );
                    // 기존에 해당 노드만 삭제하는 처리는 하지 않는다.
                    // dispatch(del_use_state_service(no));

                    dispatch(loading_end_use_state_service());      // 로딩완료
                    resolve(true)
                }
                else {
                    if (cancelResult.Message.indexOf('운행중 예약')) {
                        await calertPromise('운행을 시작한 예약건은 취소하실 수 없습니다.')
                    } else {
                        await calertPromise(cancelResult.Message)
                    }
                    dispatch(loading_end_use_state_service());      // 로딩완료
                    resolve(false)
                }
            }      
        } catch(e) {
            console.log(e)
            dispatch(loading_end_use_state_service());      // 로딩완료
            return reject(e)
        }
    })
}


// 파트너 선택 처리
export const setDriverSelectedRedux = (cn, drivingNo, driverNo, ostype, deviceid, loginEncrypt) => async dispatch => {
    try {
        dispatch(loading_use_state_service());          // 로딩중
        let result = await ReserveApi.ChoiceDrivingDriver(drivingNo, driverNo, ostype, deviceid, loginEncrypt)
        if (result.Result === 'success') {
            dispatch(loading_end_use_state_service());      // 로딩완료
            await calertPromise('파트너 배정이 완료되었습니다.')
            // 예약이 취소되면 1페이지 부터 리스트를 갱신하도록 처리한다.
            dispatch( getReservationListRedux(cn, 1, ostype, deviceid, loginEncrypt) );
            
        } else {
            await calertPromise(result.Message)
            dispatch(loading_end_use_state_service());      // 로딩완료
        }
    } catch(e) {
        console.log(e)
        dispatch(loading_end_use_state_service());      // 로딩완료
    }
}

export const cancelReservationRedux = (cn, no, data, carddata, ostype, deviceid, loginEncrypt) => async dispatch => {
    try {
        dispatch(loading_use_state_service());          // 로딩중
        
        // 제휴카드이며 1599 쿠폰으로 할인 받았을 경우.
        if (carddata.PsCouponId !== null && carddata.MallReserved !== '' && data.CouponId === 1599) {
            let cancelCoupon = await CouponApi.CancelCouponEBridge(
                cn, carddata.PsCouponId, carddata.MallReserved, ostype, deviceid, loginEncrypt
            )
            if (cancelCoupon && cancelCoupon.success) {
                // 성공시 로직
                let cancelResult = await ReserveApi.CancelDrivingReservation( cn, no, ostype, deviceid, loginEncrypt )
                if (cancelResult.Result === 'success') {
                    await calertPromise('예약 취소가 완료되었습니다.')
                    
                    // 페이지를 초기화시킨다.
                    dispatch(init_page_user_state_service())
                    // 예약이 취소되면 1페이지 부터 리스트를 갱신하도록 처리한다.
                    dispatch( getReservationListRedux(cn, 1, ostype, deviceid, loginEncrypt) );
                    // 기존에 해당 노드만 삭제하는 처리는 하지 않는다.
                    // dispatch(del_use_state_service(no));

                    dispatch(loading_end_use_state_service());      // 로딩완료
                } 
                else {
                    if (cancelResult.Message.indexOf('운행중 예약')) {
                        await calertPromise('운행을 시작한 예약건은 취소하실 수 없습니다.')
                    } else {
                        await calertPromise(cancelResult.Message)
                    }
                    
                    dispatch(loading_end_use_state_service());      // 로딩완료
                }       
            } 
            else {
                // 실패시 로직
                await calertPromise('예약시 적용된 쿠폰 취소 처리에 실패했습니다. 잠시후 다시 시도해주세요.')
                dispatch(loading_end_use_state_service());      // 로딩완료
            }
        } else {
            let cancelResult = await ReserveApi.CancelDrivingReservation(cn, no, ostype, deviceid, loginEncrypt)
            if (cancelResult.Result === 'success') {
                await calertPromise('예약 취소가 완료되었습니다.')
                
                // 페이지를 초기화시킨다.
                dispatch(init_page_user_state_service())
                // 예약이 취소되면 1페이지 부터 리스트를 갱신하도록 처리한다.
                dispatch( getReservationListRedux(cn, 1, ostype, deviceid, loginEncrypt) );
                // 기존에 해당 노드만 삭제하는 처리는 하지 않는다.
                // dispatch(del_use_state_service(no));
                
                dispatch(loading_end_use_state_service());      // 로딩완료
            }
            else {
                if (cancelResult.Message.indexOf('운행중 예약')) {
                    await calertPromise('운행을 시작한 예약건은 취소하실 수 없습니다.')
                } else {
                    await calertPromise(cancelResult.Message)
                }
                dispatch(loading_end_use_state_service());      // 로딩완료
            }       
        }

    } catch(e) {
        console.log(e)
        dispatch(loading_end_use_state_service());      // 로딩완료
    }
}

const initialState = {
    page:1,
    getinfo:[],
    defaultCardInfo:{},
    getwaypoint:[],
    loading:0,
    loadingMain:0,
    scrollDataSyncFlag:1,
    totalPage:0,
    getinfoNative:[],
    getwaypointNative:[],
}

export default (state = initialState, action) => {

    switch (action.type) {
        case EMPTY_USE_STATE:
            return {
                ...state,
                ...initialState
            };

        case INIT_CARD_DATA_USE_STATE:
            return {
                ...state,
                defaultCardInfo: action.payload
            };
        case INIT_USE_STATE:
            return {
                ...state,
                getinfo: action.payload.obj,
                page: state.page + 1,
                totalPage: action.payload.totalpage
            };
        case INIT_PAGE_USE_STATE:
            return {
                ...state,
                page: initialState.page,
                scrollDataSyncFlag: initialState.scrollDataSyncFlag,
            };
        case GET_USE_STATE:
            return {
                ...state,
                ...initialState
            };
        case PAGING_USE_STATE:
            return {
                ...state,
                getinfo: state.getinfo.concat(action.payload.obj),
                page: state.page + 1
            };

        case DEL_USE_STATE:
            return {
                ...state,
                getinfo: state.getinfo.filter( (data) => {return data.DrivingNo !== action.payload} )
            };
        
        
        case INIT_LOADING_USE_STATE:
            return {
                ...state,
                loading:0,
                loadingMain:0
            };

        case INIT_LOADING_END_USE_STATE:
            return {
                ...state,
                loading:1,
                loadingMain:1
            };
    
        case LOADING_USE_STATE:
            return {
                ...state,
                loading:0,
                scrollDataSyncFlag:0
            };

        case LOADING_END_USE_STATE:
            return {
                ...state,
                loading:1,
                scrollDataSyncFlag: (state.getinfo.length >= state.totalPage) ? 0 : 1       // 더이상 불러올 것이 없으면 페이징 처리 하지 않는다.
            };

        case INIT_USE_STATE_NATIVE:
            return {
                ...state,
                getinfoNative: action.payload.obj,
            };

        case LOADING_ONLY_USE_STATE:
            return {
                ...state,
                loading:0,
            };

        case LOADING_END_ONLY_USE_STATE:
            return {
                ...state,
                loading:1,
            };


             
             



        default:
            return state;
    }
};

/*

※참고: 아래는 이전에 UseStateView에서 Cancel할때의 처리이다.
try {
                                        
    this.setState({ loading: 0 })	// 로딩중
    e.preventDefault();
    let { CustomerNo } = store.getState().global_data.loginedData
    let { cookies } = this.props;

    // 예약 취소 API
    let cancelResult = await ReserveApi.CancelDrivingReservation(
        CustomerNo,
        data.DrivingNo,
        cookies.get('ostype'),
        cookies.get('deviceid'),
        cookies.get('login_encrypt')
    )

    // 예약 취소 성공시
    if (cancelResult.Result === 'success') {
        await calertPromise('예약이 취소되었습니다.')

        // 예약 데이터 재 취득(갱신)
        let tmpReserveResult = await ReserveApi.GetDrivingReservationList(
            CustomerNo,
            1,
            0,
            MAX_PAGE,
            cookies.get('ostype'),
            cookies.get('deviceid'),
            cookies.get('login_encrypt')
        );
        if (tmpReserveResult.Result === 'success') {
            this.setState({
                reserveList: tmpReserveResult.ItemList,
                loading: 1
            })
        } else {
            await calertPromise(tmpReserveResult.Message)
            this.setState({ loading: 1 })	// 로딩완료
            this.props.history.replace('/main')
        }

    } else {
        await calertPromise(cancelResult.Message)
        this.setState({ loading: 1 })	// 로딩완료
    }
} catch (e) {
    await calertPromise('예약 취소 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
    this.setState({ loading: 1 })	// 로딩완료
}

*/


/*import moment from 'moment';
import {calert} from '../../lib/Util'

// end 시간 등록

// 구현해야 할 것
// 1. 날짜 선택하게 되면
// 시작시간 종료시간 계산해서 선택 가능한 시간 계산해서 집어넣기
// 2. 시작시간 선택하게 되면 종료시간은

// 초기화
// localStorage 데이터 존재하면 시간 세팅
// 화면 닫혀질때 localStorage에 저장
// time update 메소드
// getCalcDrivingReservation() 함수에 현재 시간 취득
// 시간 Validate

const INIT_TIME_ALL_SELECTOR = "TimePicker/INIT_TIME_ALL_SELECTOR";
const INIT_START_TIME_SELECTOR = "TimePicker/INIT_START_TIME_SELECTOR";
const INIT_END_TIME_SELECTOR = "TimePicker/INIT_END_TIME_SELECTOR";
const CHANGE_START_TIME = "TimePicker/CHANGE_START_TIME";
const CHANGE_END_TIME = "TimePicker/CHANGE_END_TIME";

export const init_time_all_selector_service = () => ({ type: INIT_TIME_ALL_SELECTOR });
export const init_time_start_selector_service = () => ({ type: INIT_START_TIME_SELECTOR });
export const init_time_end_selector_service = () => ({ type: INIT_END_TIME_SELECTOR });
export const change_start_time_service = (obj) => ({ type: CHANGE_START_TIME, payload:{Hour:obj.hour, Minute:obj.minute } });
export const change_end_time_service = (obj) => ({ type: CHANGE_END_TIME, payload:{Hour:obj.hour, Minute:obj.minute } });

const initMoment = moment()

const initialState = {
    startTimeFormat:initMoment.format('HH:mm:00.000'),            //11:11:00.000
    startTimeValue:initMoment.format('A h:mm'),                   //AM 7:30
    startTimeId:'start',                                        // 시간 만들어주는 input 태그의 id값

    endTimeFormat:initMoment.format('HH:mm:00.000'),
    endTimeValue:initMoment.format('A h:mm'),
    endTimeId:'end',
}

export default (state = initialState, action) => {

    switch (action.type) {
        case INIT_TIME_ALL_SELECTOR:
            return {
                ...state,
                ...initialState
            };
        case INIT_START_TIME_SELECTOR:
            return {
                ...state,
                startTimeFormat: initMoment.format('HH:mm:00.000'),
                startTimeValue: initMoment.format('A h:mm'),
            };
        case INIT_END_TIME_SELECTOR:
            return {
                ...state,
                endTimeFormat: initMoment.format('HH:mm:00.000'),
                endTimeValue: initMoment.format('A h:mm'),
            };

        case CHANGE_START_TIME:
            // start 시간 등록
            // start 시간 등록시 end시간에는 start시간 + 1시간 더하기 로직 필요
            return {
                ...state,
                startTimeFormat: moment(`2020-12-25 ${action.payload.Hour}:${action.payload.Minute}`).format('HH:mm:00.000'),
                startTimeValue: moment(`2020-12-25 ${action.payload.Hour}:${action.payload.Minute}`).format('A h:mm'),

                endTimeFormat:moment(`2020-12-25 ${action.payload.Hour}:${action.payload.Minute}`).add(1, 'hours').format('HH:mm:00.000'),
                endTimeValue:moment(`2020-12-25 ${action.payload.Hour}:${action.payload.Minute}`).add(1, 'hours').format('A h:mm'),
            };
        case CHANGE_END_TIME:
            return {
                ...state,
                endTimeFormat: moment(`2020-12-25 ${action.payload.Hour}:${action.payload.Minute}`).format('HH:mm:00.000'),
                endTimeValue: moment(`2020-12-25 ${action.payload.Hour}:${action.payload.Minute}`).format('A h:mm'),
            };
        default:
            return state;
    }
};
*/
// arr.filter((data, idx)=>{console.log(data, idx); return this})
// arr.filter((data, i)=>(i !== idx))

/*
{
type: 'LocationSelector/STEP_OVER_ADD',
stopOverItem: '1'
}

{
type: 'LocationSelector/STEP_OVER_DEL',
stopOverDelItemIdx: '1'
}

{
type: 'LocationSelector/STEP_OVER_UPDATE',
stopOverUpdateData: 'test01',
stopOverUpdateIdx: 1
}
*/

// 배열 저장하는 함수
// function updateObjectInArray(array, action) {
//     return array.map((item, index) => {
//       if (index !== action.stopOverUpdateIdx) {
//           console.log('index !== action.stopOverUpdateIdx result' + item);
//         // This isn't the item we care about - keep it as-is
//         return item
//       }

//       // Otherwise, this is the one we want - return an updated value
//       return action.stopOverUpdateData;


//       /*{
//         ...item,
//         ...action.stopOverUpdateData
//       }*/
//     })
//   }


// handleAddStopover = (e) => {
//     let tmpArr = this.state.stopOverList;
//     let tmpArrLen = this.state.stopOverList.length;

//     if (tmpArrLen >= LIMIT_STOPOVER_COUNT) {
//         alert('경유지는 3개까지 등록 가능합니다.');
//     } else {
//         tmpArr.push(1);
//         this.setState({
//             stopOverList: tmpArr
//         })
//     }
// }

// handleDelStopover = (e) => {
//     let tmpArr = [];

//     for (let i in this.state.stopOverList) {
//         if (e.target.id === i) continue;
//         else tmpArr.push(this.state.stopOverList[i]);
//     }

//     this.setState({
//         stopOverList: tmpArr
//     })
// }