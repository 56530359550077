import axios from 'axios';
import { Token, PARTNER_BASE_URL, Timeout } from '../constantData'

const PaymentApi = {};

PaymentApi.setPaymentCard = (formData, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : '/SetPaymentCard',
            method: 'POST',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
            data: formData
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}

PaymentApi.GetMyPaymentCards = (customerNo, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/GetMyPaymentCards?customerNo=${customerNo}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}

PaymentApi.SetDeletePaymentCard = (CustomerNo, CardId,  ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/SetDeletePaymentCard`,
            method: 'POST',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
            data: {
                'CardId': CardId,
                'CustomerNo' : CustomerNo
            }
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}

PaymentApi.SetDefaultPaymentCard = (CustomerNo, CardId,  ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/SetDefaultPaymentCard`,
            method: 'POST',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
            data: {
                'CardId': CardId,
                'CustomerNo' : CustomerNo
            }
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}

PaymentApi.SetPayment = (formData,  ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/SetPayment`,
            method: 'POST',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
            data: formData
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}

PaymentApi.getPartnerCardAuth = (CardNo, CustomerNo, ostype='', deviceid='', loginEncrypt) => {
    const binNum = CardNo.substring(0, 6); // 임시 "451842";
    const includeHeaders = {
        'Content-Type': 'application/json',
        'OsType': ostype,
        'DeviceId': deviceid,
        'Token': Token,
        'Authorize': loginEncrypt,
    }
    
    return new Promise((resolve, reject) => {
        axios({
            url: `/binNumCheck.vm?bin_num=${binNum}&member_id=${CustomerNo}`,
            method: 'GET',
            headers: includeHeaders,
            timeout: Timeout,
        })
        .then(res => {
            if (res && res.data && res.data.success) {
                const resDataTmp = JSON.parse(res.data.data);
                // alert(JSON.stringify(resDataTmp) )
                return axios({
                    url: `/SetPsBinnumChk?CustomerNo=${CustomerNo}&DisplayCardNo=${CardNo}&PsBinnumChk=${resDataTmp.uid}`,
                    method: 'GET',
                    headers: includeHeaders,
                    timeout: Timeout,
                })
            } else {
                resolve(false);
            }
        })
        .then(res => {
            // alert(JSON.stringify(res.data) )
            if (res && res.data && res.data.Result === "success") {
                resolve(true);
            } else {
                resolve(false);
            }
        })
        .catch((err) => {
            return reject(err);
        });
            
    });
}

PaymentApi.CardInfoEBridge = (CustomerNo,CustomerName,Email, CardNo,  ostype='', deviceid='', loginEncrypt) => {
    const binNum = CardNo.substring(0, 6); // 임시 "451842";
    return new Promise((resolve, reject)=>{
        axios({
            url : `/cardInfo.vm?bin_num=${binNum}&member_id=${CustomerNo}&member_name=${CustomerName}&member_email_address=${Email}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}

PaymentApi.cardDeleteEBridge = (CustomerNo,card_user_uid, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/cardDelete.vm?member_id=${CustomerNo}&card_user_uid=${card_user_uid}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}

// 제휴카드 인증 화면에서 인증 성공시 MallReserved값을 Native로부터 받아와서 본 API를 통해 저장한다.
PaymentApi.SetMallReserved = (paymentCardId,mallReserved,  ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/SetMallReserved?PaymentCard_id=${paymentCardId}&MallReserved=${mallReserved}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}


////       `/SetMallReserved?PaymentCard_id=${this.paymentCardId}&MallReserved=${mallReserved}`



PaymentApi.test01 = (CardNo, CustomerNo, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        const binNum = CardNo.substring(0, 6); // 임시 "451842";
        const includeHeaders = {
            'Content-Type': 'application/json',
            'OsType': ostype,
            'DeviceId': deviceid,
            'Token': Token,
            'Authorize': loginEncrypt,
        }

        axios({
            url: `/binNumCheck.vm?bin_num=${binNum}&member_id=${CustomerNo}`,
            method: 'GET',
            headers: includeHeaders,
            timeout: Timeout,
        })
        .then(res => {
            resolve(res.data);
        })
        .catch((err) => {
            return reject(err);
        });
            
    });
}


export default PaymentApi;