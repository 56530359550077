import axios from 'axios';
import { Token } from '../constantData'

const TicketApi = {};

TicketApi.GetCustomerTicketList = (CustomerNo, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/GetCustomerTicketList?customerNo=${CustomerNo}`,
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}

// 이용 가능 금액 취득
TicketApi.GetCustomerRemainTicket = (CustomerNo, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/GetCustomerRemainTicket?customerno=${CustomerNo}`,
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}

// 충전권 리스트 표시
TicketApi.GetPrePaidTicketList = (ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/GetPrePaidTicketList`,
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}

// payticketlist
// status : 0 사용
// status : 1 취소
// 충전권 리스트 표시
// 구매 환불등등 : patricketlist

// 이용내역 > 사용내역 리스트
TicketApi.GetCustomerUseTicketList = (customerNo, page, pageSize, dataRange, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/GetCustomerUseTicketList?customerNo=${customerNo}&page=${page}&pageSize=${pageSize}&dataRange=${dataRange}`,
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}

// 이용내역 > 충전내역 리스트
TicketApi.GetCustomerPayTicketList = (customerNo, page, pageSize, dataRange, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/GetCustomerPayTicketList?customerNo=${customerNo}&page=${page}&pageSize=${pageSize}&dataRange=${dataRange}`,
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}

// 충전권 충전 결제
TicketApi.PurchasePrePaidTicket = (customerNo, productNo, purchaseCount, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/PurchasePrePaidTicket?customerNo=${customerNo}&productNo=${productNo}&purchaseCount=${purchaseCount}`,
            method: 'POST',
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}


export default TicketApi;