import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
/* Intro Rolling */
.slide_menu_container {
    margin-left:-100vw;
    position:fixed;
    width:100vw;
    z-index:100000000;
    overflow:hidden;
    transition: 0.5s;
    top: 0;
    left: 0;
    height: 100%;
    --webkit-transform: translateZ(0);
}
.menu_container {
  background-color:yellow;
  display:flex;
  flex-direction: column;
  height:100vh;
  width:100%;
  justify-content: center;
}
.menu_container .menu_containter_header {
  flex:3;
  background:#42475a;
  padding:20px 20px 30px 20px;
  width:100%;
}

.menu_container .menu_containter_header { display:inline-block; width:100%; background:#42475a; padding:20px 20px 30px 20px;}
.menu_container .menu_containter_header span.btn_setup { display:inline-block; width:100%; margin-bottom:10px;}
.menu_container .menu_containter_header span.btn_setup a.btn_link { display:inline-block; float:right;}
.menu_container .menu_containter_header span.btn_setup a.btn_link img {width:20px;}
.menu_container .menu_containter_header span.member_info { display:inline-block; width:100%;}
.menu_container .menu_containter_header span.member_info span.name {display:inline-block; width:100%; color:#fff; font-weight:bold; font-size: 18px; padding-bottom:7px;}
.menu_container .menu_containter_header span.member_info span.tel {display:inline-block; width:100%; color:#dadada; padding-bottom:15px;}
.menu_container .menu_containter_header span.mb_modify { display:inline-block; width:100%; color:#fff; vertical-align:bottom; line-height:20px;}
.menu_container .menu_containter_header span.mb_modify a {color:#fff; font-weight:bold;}
/* [MosilerAPP-User]TestCheck_List.xlsx H10셀에 의해 수정 
.menu_container .menu_containter_header span.mb_modify a img { font-size:1.1em; width:5px; margin:2px 0 0 3px;}
*/
.menu_container .menu_containter_header span.mb_modify a img { font-size:1.1em; width:5px; margin-left: 5px;}

.menu_seperator {
  display:inline-block;
  height:8px;
  background:#f8f8f8;
  /* background:black; */
}


.menu_container .menu_containter_menu {
  flex:90;  
  overflow-y:scroll;
  overflow-x:hidden;
  background:#fff;
  
  /* padding:0px 0px; */
}

.menu_container .menu_containter_menu a.sl_menu { color: #5a5a5a;display:inline-block; width:100%; border-bottom:1px solid #eee; padding:0px 25px; font-weight:bold; vertical-align:bottom; line-height:46px;}

/* [MosilerAPP-User]TestCheck_List.xlsx H10셀에 의해 수정 
.menu_container .menu_containter_menu a.sl_menu img.m_icon {width:20px; margin-right:8px; margin-bottom:-2px;}
*/
.menu_container .menu_containter_menu a.sl_menu img.m_icon {width:20px; margin-right:8px; margin-bottom:2px;}

.menu_container .menu_containter_menu a.sl_menu img.btn_arrow {width:7px; float:right; margin:20px 0 0 0; padding-right:45px;}


.menu_container .menu_containter_footer {
  flex:1;
  background:#fff;
  bottom:0%; 
  left:0%; 
  padding:10px 30px;
  
}

.menu_container .menu_containter_footer span.cs_box { display:inline-block; width:100%; margin-bottom:8px; line-height:15px;}
.menu_container .menu_containter_footer span.cs_box span.title { display:inline-block; padding-right:10px; border-right:1px solid #ddd; float:left;}
.menu_container .menu_containter_footer span.cs_box a { display:inline-block; float:left; padding-left:10px; color:#1e7efa; font-weight:bold;}
.menu_container .menu_containter_footer span.cs_box span.time {display:inline-block; float:left; padding-left:10px; font-weight:bold;}

`;