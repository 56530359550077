import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
body {background:#1fafd2;}

// .login_box {width:100%; margin:20% 0 0 0px; text-align:center;}
.login_box {width:100%;text-align: center;position: absolute !important;top: 50%;-webkit-transform: translateY(-50%);-ms-transform: translateY(-50%);transform: translateY(-50%);}
.login_box span.logo_box { display:inline-block; width:100%; text-align:center; padding:20px 0; margin-bottom:20px;}
.login_box span.logo_box img.logo { width:200px;}

.login_box span.login_form { display:inline-block; width:100%; padding:0 20px;}
.login_box span.login_form input.frm_st1 { display:inline-block; width:100%; border:0px; background:#fff; padding:0 15px; line-height:47px; height:47px; margin-bottom:7px; border-radius:2px;}
.login_box span.login_form button.frm_login {background:#2e303f; color:#fff; width:100%; text-align:center; border:0px; font-size:18px; line-height:50px; height:50px; border-radius:2px; font-weight:bold; margin-top:8px;}
.login_box span.login_form span.frm_auto { display:inline-block; width:100%; margin:0px 0 0 0; font-size:1.1em; color:#fff; line-height:50px;}
.login_box span.login_form span.auto { display:inline-block; float:left;}
.login_box span.login_form span.search { display:inline-block; float:right;}
.login_box span.login_form span.search a {color:#fff; margin:0 0 0 0px;}
.login_box span.login_form span.search a.section_bar { display:inline-block; padding:0 5px; margin:0px 0 0 0; color:#ccc;}


.login_box span.login_form span.sns_login { display:inline-block; width:100%; margin:40px 0 0 0;}
.login_box span.login_form button.sns_login_kakao { display:inline-block; width:100%; background:#f9e000; border:0px; font-size:18px; line-height:55px; height:55px; border-radius:2px; margin-bottom:5px}
.login_box span.login_form button.sns_login_kakao img {width:30px; margin-right:10px;}


.login_box span.login_form span.join_guide {display:inline-block; width:100%; color:#fff; text-align:center; margin:30px 0 0 0;}
.login_box span.login_form span.join_guide a {color:#fff;}



`;