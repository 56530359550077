//

const RnInterface = {};

/**
 * @brief React Native측에서 정보를 WebView측에서 받아오도록 처리하는 함수이다.
 * @param {name} name 취득하고자 하는 이름 
 * @detail deviceinfo - 디바이스의 정보를 취득한다. ostype과 deviceid를 취득하며 구분자는 '@@' 이다.
 * @ fcmtoken - 푸시에 필요한 TOKEN값을 디바이스로부터 취득한다.
 * @ getDeviceLatLng - 디바이스의 현재 위도, 경도 값을 취득한다. 데이터를 받게 되면 JSON.parse()로 JSON형태로 만들어줘야한다.  {lat:xx.xxxxxx, lng: xxx.xxxxxx}
 * @ devicetype - Android인지 IOS인지를 취득한다.
 */

/*
    RnInterface.getData(name)

    name 목록                       설명
    deviceinfo          - 디바이스의 정보를 취득한다. ostype과 deviceid를 취득하며 구분자는 '@@'이다.
    fcmtoken            - 푸시에 필요한 TOKEN값을 디바이스로부터 취득한다.
    getDeviceLatLng     - 디바이스의 현재 위도, 경도 값을 취득한다. 
                          데이터를 받게 되면 JSON.parse()로 JSON형태로 만들어줘야한다. 
                          아래와 같은 형태의 데이터가 온다.
                          {lat:xx.xxxxxx, lng: xxx.xxxxxx}
    devicetype          - Android인지 IOS인지를 취득한다.
    kakaoNativeLogin    - Native측에서 KakaoLogin을 진행한다. 로그인 완료후 Profile 정보를 취득한다.
    getDeviceVersion    - Native측에서 디바이스 버전 정보를 취득한다.

 */
RnInterface.getData = (name) => {
    return new Promise((resolve, reject)=>{
        // React Native에서 켰다면
        if (window.ReactNativeWebView) {
            // userAgent 취득
            var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
            // Android인가?
            if ( varUA.indexOf('android') > -1) {
                try {
                    let foo = (e)=>{
                        resolve(e.data);
                        document.removeEventListener("message",foo, {capture: true, once:true});
                    }
                    document.addEventListener('message', foo);    
                    window.ReactNativeWebView.postMessage(name);
                } catch(e){
                    return reject(e);
                }
            // IOS인가
            } else {
                try {
                    let foo = (e)=>{
                        resolve(e.data);
                        window.removeEventListener("message",foo, {capture: true, once:true});
                    }
                    window.addEventListener('message', foo);    
                    window.ReactNativeWebView.postMessage(name);
                } catch(e){
                    return reject(e);
                }
            } 
        } else {
            resolve(false);
        }
    });
}

/*
    RnInterface.callReactNative(name)
    주로 android기기에서 native측으로 메시지를 전달하고자 할 때 사용되는 함수이다.

    name 목록                                       설명
    loginStatusBarChange                        - 로그인 화면의 상단 스테이터스바를 변경한다. (색에 대한 확인은 Native측에서 loginStatusBarChange를 검색)
    loginedStatusBarChange                      - 로그인 이외의 화면의 상단 스테이터스바를 변경한다.    (색에 대한 확인은 Native측에서 loginedStatusBarChange 검색)
    MallUserIdXXXXX                             - Native측에서 결제모듈 화면을 띄운다. XXXXX는 로그인되어있는 사람의 CustomerNo이다. (Android 전용)
                                                 (참고: Native에서의 처리는 MallUserId라는 문자열이 있는지 체크하고 존재하면 MallUserId 문자열을 지우고
                                                 남은 XXXXX에 대해서 결제모듈 URL에 쿼리스트링으로 포함시킨다. 그리고, 새로운 WebView를 만들어서 결제모듈 URL을 띄운다.)
    PaymentCardPartnerXXXXX@@XXXXX@@XXXXX       - Native측에서 제휴카드 인증 화면을 띄운다. (동작방식은 MallUserIdXXXXX과 동일)
                                                  @@로 split 한다. 총 3개의 XXXXX값을 전달하게 된다.
                                                  1번째 XXXXX : ebridgeUrl
                                                  2번째 XXXXX : mallReserved
                                                  3번째 XXXXX : paymentCardId

    MainPaymentXXXXX                             - Native측에서 결제 요청 화면을 띄운다. (동작방식은 MallUserIdXXXXX과 동일)
                                                  XXXXX값은 아래 값을 JSON.stringify하고 있다.
                                                  {
                                                    amt:`${Number(drvPrice) + Number(addAmount)}`,
                                                    TargetType: TargetType_Reservation,
                                                    Status: Payment_Insert,
                                                  }
    shareInviteCodeXXXXX                         - 디바이스측 공유기능을 사용한다. (동작방식은 MallUserIdXXXXX과 동일)
                                                    XXXXX값은 아래 값을 JSON.stringify하고 있다.
                                                  {
                                                    message:'',
                                                    url:''
                                                  }
    callTelephoneXXXXX                         - 디바이스측 전화걸기 기능을 사용한다. (동작방식은 MallUserIdXXXXX과 동일)
                                                    XXXXX값은 - 없는 전화번호값이다(string)
                                                  ex) 15881234
    

 */
RnInterface.callReactNative = (name) => {
    return new Promise((resolve, reject)=>{
        // React Native에서 켰다면
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(name);
            resolve(true);
        } else {
            resolve(false);
        }
    });
}

/*
    RnInterface.getDataOnlyListener()

    RN에서 메시지를 대기하다가 데이터를 받게되면 그 데이터를 resolve한다.
    본 함수는 주로 payment관련 화면에 이 함수를 호출하여 처리된다.
    
 */
RnInterface.getDataOnlyListener = () => {
    return new Promise((resolve, reject)=>{
        // React Native에서 켰다면
        if (window.ReactNativeWebView) {
            // userAgent 취득
            var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
            // Android인가?
            if ( varUA.indexOf('android') > -1) {
                try {
                    let foo = (e)=>{
                        resolve(e.data);
                        document.removeEventListener("message",foo);
                    }
                    document.addEventListener('message', foo);    
                    
                } catch(e){
                    return reject(e);
                }
            // IOS인가
            } else {
                
                try {
                    let foo = (e)=>{
                        resolve(e.data);
                        window.removeEventListener("message",foo);
                    }
                    window.addEventListener('message', foo);    
                    
                } catch(e){
                    return reject(e);
                }
            } 
        } else {
            resolve(false);
        }
    });
}

/*
    RnInterface.AndroidURLChange()

    RnInterface.callReactNative()를 커스텀한 함수
    Android에서는 React Native의 onNavigationStateChange가 통하질 않아서 
    현재 URL을 Native에서는 알수가 없다. 
    임시조치로 본 함수를 호출해서 현재 나의 URL위치를 Native에게 알려주는 함수이다. (SPA라서 감지를 못하는걸지도.. IOS는 잘 감지하던데.)
    SinglePageApplication에 대해서 onNavigationStateChange를 호출하는 방법을 알고 있다면 조치를 부탁드립니다.
*/
RnInterface.AndroidURLChange = (pathname) => {
    return new Promise((resolve, reject)=>{
        var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
        // React Native에서 켰다면
        if (window.ReactNativeWebView && varUA.indexOf('android') > -1) {
            window.ReactNativeWebView.postMessage(`AndroidURLChange${pathname}`);
            resolve(true);
        } else {
            resolve(false);
        }
    });
}


/* 백업
RnInterface.getDataOnlyListener = () => {
    return new Promise((resolve, reject)=>{
        // React Native에서 켰다면
        if (window.ReactNativeWebView) {
            // userAgent 취득
            var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
            // Android인가?
            if ( varUA.indexOf('android') > -1) {
                try {
                    let foo = (e)=>{
                        resolve(e.data);
                        document.removeEventListener("message",foo, {capture: true, once:true});
                    }
                    document.addEventListener('message', foo);    
                    
                } catch(e){
                    return reject(e);
                }
            // IOS인가
            } else {
                
                try {
                    let foo = (e)=>{
                        resolve(e.data);
                        window.removeEventListener("message",foo);
                    }
                    window.addEventListener('message', foo, {capture: true, once:true});    
                    
                } catch(e){
                    return reject(e);
                }
            } 
        } else {
            resolve(false);
        }
    });
}
*/





export default RnInterface;