import axios from 'axios';
import { Token, Timeout } from '../constantData'

const AppSettingApi = {};

AppSettingApi.UpdatePushYN = (customerno, pushyn, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/UpdatePushYN?customerno=${customerno}&pushyn=${pushyn}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });

}

AppSettingApi.GetVersionCheck = (version, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/GetVersionCheck?clientVer=${version}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });

}



export default AppSettingApi;