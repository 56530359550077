import React, { Component } from 'react';
import NoticeApi from '../../lib/NoticeApi';
import { withCookies } from 'react-cookie';
import Header from '../../components/Common/Header'
import { createGlobalStyle } from "styled-components";
import Loading from '../../components/Common/Loading'
import {calert, calertPromise} from '../../lib/Util'
import RnInterface from '../../lib/RnInterface'
import store from '../../reduxStore'
import {getCustomerCategoryViewTarget} from '../../lib/Util'

import '../../css/default.css';

import img_arrow_down from '../../img/notice/img_arrow_down.svg'
import img_arrow_up from '../../img/notice/img_arrow_up.svg'

const GlobalStyle = createGlobalStyle`
/* cs.css */

/* 고객센터 - 기본 */
.faq-content {border-bottom: 1px solid #e0e0e0;}
.question {padding:10px 20px; border:none; outline:none; background:none; width: 100%; text-align:left; box-sizing:border-box;}

.question span.title { display:inline-block; width:100%; font-size:1.3em; padding-top:.5em; padding-bottom:.5em}
.question span.title span.date { display:inline-block; width:100%; font-size:0.8em; color:#999; padding:5px 0 0 0;}

/* 수정 전 값 (주석 처리)
.question span.title { display:inline-block; width:100%; font-size:1.3em;}
.question span.title span.date { display:inline-block; width:100%; font-size:0.8em; color:#999; padding:5px 0 0 0;}
*/
.answer {display:none; width:100%; padding:20px 20px; background:#f5f6f7; line-height:180%; font-size:1.2em;}
.answer img {width:100% !important; }

[id$="-toggle-down"] { position:absolute; right:23px; font-size:1.5em; padding-top:.25em}
[id$="-toggle-up"] { position:absolute; right:23px; font-size:1.5em; padding-top:.25em}
`;

const NOTICE_LINK_STATE_MAIN_MENU = '0';
const NOTICE_LINK_STATE_NOTICE = '1';


class NoticeView extends Component {

    state = {
        itemList: [],
        loading:0
    }

    getNotice = async () => {
        try {
            this.setState({loading:0})	// 로딩중
            const { cookies } = this.props
            let { CustomerNo, CustomerCategory } = store.getState().global_data.loginedData
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            let tmpData = await NoticeApi.GetNoticeList(
                getCustomerCategoryViewTarget(CustomerCategory),
                cookies.get('ostype'),
                cookies.get('deviceid'),
                cookies.get('login_encrypt')
            )
            if (tmpData.Result === 'success') {
                this.setState({ itemList: tmpData.ItemList, loading:1 })
            } else {
                await calertPromise('데이터를 취득하지 못했습니다.')
                this.setState({loading:1})	// 로딩완료
            }
        } catch(e) {
            await calertPromise('공지사항 취득 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
        }
        
    }

    componentDidMount() {
        this.getNotice();
    }

    handleHome = () => {
        this.props.history.replace('/main')
    }

    handleBack = () => {
        this.props.history.replace('/main')
    }

    render() {
        const tmpComponent = (arr) => {
            return arr.map((data, idx) => {
                let tmpData = '';
                if (data.Link !== null) {
                    tmpData = `<a href="/notice_link?link=${data.Link}&viewstate=${NOTICE_LINK_STATE_NOTICE}">${data.Contents}</a>`
                } else {
                    tmpData = data.Contents;
                }

                return (
                    <>
                        <div className="faq-content">
                            <button className="question" id={`que-${idx}`} onClick={(e) => {
                                console.log(e);
                                // const answerId = this.id.replace('que', 'ans');
                                const answerId = `ans-${idx}`
                                const thisId = `que-${idx}`
    
                                if (document.getElementById(answerId).style.display === 'block') {
                                    document.getElementById(answerId).style.display = 'none';
                                    document.getElementById(thisId + '-toggle-down').style.display = 'block';
                                    document.getElementById(thisId + '-toggle-up').style.display = 'none';
                                } else {
                                    document.getElementById(answerId).style.display = 'block';
                                    document.getElementById(thisId + '-toggle-down').style.display = 'none';
                                    document.getElementById(thisId + '-toggle-up').style.display = 'block';
                                }
                            }}>
                                <span id={`que-${idx}-toggle-down`}>
                                    <img src={img_arrow_down} alt="" style={{width:'18px', height:'9px'}}/>
                                </span>
                                <span id={`que-${idx}-toggle-up`} style={{display:'none'}}>
                                    <img src={img_arrow_up} alt="" style={{width:'18px', height:'9px'}}/>
                                </span>
                                <span className="title">
                                    {data.Title}
                                </span>
                            </button>
    
                            <div className="answer" id={`ans-${idx}`} >
                                <div dangerouslySetInnerHTML={{__html: tmpData}}></div>
                                {/* {data.Contents} */}
                            </div>
                        </div>
                    </>
                    )
            })

        }
        return (
            <>
            {!this.state.loading ? (<Loading />) : ''}
                <GlobalStyle />
                <Header
                    title="공지사항"
                    onBackButton={this.handleBack}
                    onHomeButton={this.handleHome}
                />

                <div className="cs_box hd_margin_top">
                    <ul>
                        <li>
                            {tmpComponent(this.state.itemList)}
                        </li>
                    </ul>
                </div>

            </>
        );
    }
};

export default withCookies(NoticeView);
