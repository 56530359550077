import React, { Component } from 'react';
import { createGlobalStyle } from "styled-components";

import marker from '../img/booking/icon_navi.svg';

import '../css/default.css';
import '../css/booking.css';

const GlobalStyle = createGlobalStyle`
/* Head */
.head { position:absolute; z-index:1000;}
.head button.btn_style1 { background:rgba(0, 0, 0, 0) !important;}

/* Map Content */
.map_box {padding:0px;}
.map_box li.sch_map { position:absolute; z-index:100px; top:0%; left:0%;}
.map_box li.sch_map iframe { width:100vw; height:calc(100vh - 280px);}

.map_info_box { position:absolute; z-index:1000; background:#fff; width:100vw; height:300px; padding:30px; bottom:0%; border-radius:20px 20px 0 0;}
.map_info_box span.search_result_title {display:inline-block; width:100%; font-size:1.5em; padding-bottom:5px;}
.map_info_box span.address {display:inline-block; width:100%; font-size:1.2em; color:#999;}
.map_info_box span.input_search {display:inline-block; width:100%; padding:20px 0;}
.map_info_box span.input_search input.input_sch { display:inline-block; width:100%; border:1px solid #ddd; height:51px; line-height:51px; padding:15px; font-size:1.2em;}
.map_info_box span. {display:inline-block; width:100%;}

#parent_container { display:inline-block; width:100vw; height:calc(100vh - 280px); background:#ff0000;}
#container { position:relative; display:inline-block; width:100%; height:100%; background:#000;}
#parent_container .marker_center {width:4px; height:4px; line-height:4px; text-align:center; background:#ffff00; z-index:1000; position:absolute; top:50%; left:50%; margin:-2px 0px 0 -2px;}
#parent_container .marker_center img { position:absolute; width:30px; bottom:0%; left:0%; margin:0px 0 1px -13px;}
`;

class MapTest2 extends Component {

    state = {
        lat:0,
        lng:0,
        address: '',
    }
    /*
    이 화면은 향후 map picker로 활약하게 될지도?
    redux에 맵의 위도경도 정보와 주소 정보를 집어넣을 수 있도록.

    
    */

    handleClick=()=>{
        // alert();
    }

    /*

    // 선을 구성하는 좌표 배열입니다. 이 좌표들을 이어서 선을 표시합니다



    

    

    

    
    */
    componentDidMount() {
        let KAKAO = window.kakao;
        var geocoder = new KAKAO.maps.services.Geocoder();

        var mapContainer = document.getElementById('container'), // 지도를 표시할 div 
            mapOption = {
                center: new KAKAO.maps.LatLng(37.566907531463585, 126.97857866109217), // 지도의 중심좌표
                level: 3 // 지도의 확대 레벨
            };

        var map = new KAKAO.maps.Map(mapContainer, mapOption); // 지도를 생성합니다

        var linePath = [
            new KAKAO.maps.LatLng(37.567058234212986, 126.97742973008874),
            new KAKAO.maps.LatLng(37.567004500828226, 126.9792068424843),
            new KAKAO.maps.LatLng(37.566220632420624, 126.9791957414049), 
            new KAKAO.maps.LatLng(37.56494108818026, 126.97847169585253), 
        ];

        var polyline= new KAKAO.maps.Polyline({
            path: linePath,
            strokeWeight:3
        })

        polyline.setMap(map);

        // 지도를 확대 축소할때도 이벤트 처리가 필요하다.

    }

    render() {
        return (
            <>
                <GlobalStyle />
                <div className="booking_sch">
                    <ul className="map_box">
                        <li className="sch_map">
                            <div id="parent_container">
                                <div id="container">
                                    <div className="marker_center"><img src={marker} alt="" /></div>
                                </div>
                            </div>
                        </li>

                        <li className="map_info_box">
                            <span id="building_name" className="search_result_title">학동역 7호선</span>
                            <span className="address">
                                <span id="new_address">서울 서대문구 증고로1길 40-17</span><br />
				                <span id="old_address">(지번) 논현동 279-67</span>
                            </span>
                            <span className="input_search">
                                <input className="input_sch" placeholder="상세주소를 입력하세요 (건물명, 동/호수 등)" />
                            </span>
                            <span className="btn_box">
                                <span id="lat_id">11</span><br/>
                                <span id="lng_id">22</span>
                                <button className="btn_st_1fafd2" onClick={this.handleClick}>선택한 위치로 설정</button>
                            </span>
                        </li>
                    </ul>
                </div>
            </>
        );
    }
};

export default MapTest2;
