import React , {useState, useEffect} from 'react'
import moment from 'moment'
import {
	SetConfirmApplication,
	SetDrivePrepareStart,
	SetDriveStart,
	SetDriveEnd
} from '../lib/TEST_driverAPI'


function getLocalStorage (id)  {
	return localStorage.getItem(id) !== null ? JSON.parse(localStorage.getItem(id)) : null;
}

function setLocalStorage (id, jsondata)  {
	localStorage.setItem(id, JSON.stringify(jsondata));
}


function App() {
	const [data, setData] = useState({
		driverno:'7609',
		drivingno:'160',
		startLat:'37.611666',
		startLng:'126.9395111',
		endLat:'37.611666',
		endLng:'126.9395111',
		date: moment().format('yyyy-MM-DD'),
		time: moment().format('HH:mm'),
	})
	const [log, setLog] = useState('')

	const updateTextForm = (event) => {
		let name = event.name
		setData({
			...data,
			[name] : event.value
		})

		setLocalStorage('tempdata', {
			...data,
			[name] : event.value
		})

	}
	
	useEffect(()=>{
		async function init() {
			let lsData = getLocalStorage('tempdata');
			if (lsData !== null) {
				setData(lsData)
			}
		}
		init();
	},[])

	const sleep = () => {
		return new Promise((resolve)=>{
			setTimeout(()=>{resolve(true)},500)
		})
	}

	const processFuncAllinOne = async () => {
		let funcArr = [
			{ func: SetConfirmApplication, str: '배차확인', },
			{ func: SetDrivePrepareStart, str: '출발준비', },
			{ func: SetDriveStart, str: '운행시작', },
			{ func: SetDriveEnd, str: '운행종료', },
		]

		for(let i in funcArr) {
			let result = await funcArr[i].func(data);
			setLog(`${log}\n${result.Result === 'success' ? funcArr[i].str + ' 완료' : funcArr[i].str + ' 실패'}`)
			if (result.Result !== 'success') {
				break;
			}
			await sleep();
		}
	}

	const processFunc = async (flag) => {
		let result = {}
		switch(flag) {
			case 1:
				result = await SetConfirmApplication(data)
				setLog(`${log}\n${result.Result === 'success' ? '배차확인 완료' : '배차확인 실패'}`)
				break;
			case 2:
				result = await SetDrivePrepareStart(data)
				setLog(`${log}\n${result.Result === 'success' ? '출발준비 완료' : '출발준비 실패'}`)
				break;
			case 3:
				result = await SetDriveStart(data)
				setLog(`${log}\n${result.Result === 'success' ? '운행시작 완료' : '운행시작 실패'}`)
				break;
			case 4:
				result = await SetDriveEnd(data)
				setLog(`${log}\n${result.Result === 'success' ? '운행종료 완료' : '운행종료 실패'}`)
				break;
		}
	
	}

	return (
		<main id="Contents">
			<table className='tbl type2'>
				<tbody>
					<tr>
						<td>
							파트너번호
						</td>
						<td>
							<input type="text" value={data.driverno} onChange={(e)=>updateTextForm(e.target)} name="driverno" placeholder='드라이버 번호' />
						</td>
						<td>
							운행번호
						</td>
						<td>
							<input type="text" value={data.drivingno} onChange={(e)=>updateTextForm(e.target)} name="drivingno" placeholder='Driving No' />
						</td>
					</tr>
					<tr>
						<td>
							출발지위도
						</td>
						<td>
							<input type="text" value={data.startLat} onChange={(e)=>updateTextForm(e.target)} name="startLat" placeholder='출발지 위도' />
						</td>
						<td>
							출발지경도
						</td>
						<td>
							<input type="text" value={data.startLng} onChange={(e)=>updateTextForm(e.target)} name="startLng" placeholder='출발지 경도' />
						</td>
					</tr>
					<tr>
						<td>
							종료지위도
						</td>
						<td>
							<input type="text" value={data.endLat} onChange={(e)=>updateTextForm(e.target)} name="endLat" placeholder='종료지 위도' />
						</td>
						<td>
							종료지경도
						</td>
						<td>
							<input type="text" value={data.endLng} onChange={(e)=>updateTextForm(e.target)} name="endLng" placeholder='종료지 경도' />
						</td>
					</tr>
					<tr>
						<td>
							운행종료날짜
						</td>
						<td>
							<input type="text" value={data.date} onChange={(e)=>updateTextForm(e.target)} name="date" placeholder='date' />
						</td>
						<td>
							운행종료시분
						</td>
						<td>
							<input type="text" value={data.time} onChange={(e)=>updateTextForm(e.target)} name="time" placeholder='time' />
						</td>
					</tr>
					<tr>
						<td colSpan={4}>
							<button style={{padding:'0px 7px',height:'40px', fontWeight:'bold', fontSize:'1.2em', border:'1px solid black'}} onClick={()=>processFuncAllinOne()}>
								모든 프로세스 일괄 실행
							</button>
						</td>
					</tr>
					<tr>
						<td colSpan={4}>
							<button style={{padding:'0px 7px',height:'40px', fontWeight:'bold', fontSize:'1.2em', border:'1px solid black'}} onClick={()=>processFunc(1)}>배차확인</button>
						</td>
					</tr>
					<tr>
						<td colSpan={4}>
							<button style={{padding:'0px 7px',height:'40px', fontWeight:'bold', fontSize:'1.2em', border:'1px solid black'}} onClick={()=>processFunc(2)}>출발준비</button>
						</td>
					</tr>
					<tr>						
						<td colSpan={4}>
							<button style={{padding:'0px 7px',height:'40px', fontWeight:'bold', fontSize:'1.2em', border:'1px solid black'}} onClick={()=>processFunc(3)}>운행시작</button>
						</td>
					</tr>
					<tr>
						<td colSpan={4}>
							<button style={{padding:'0px 7px',height:'40px', fontWeight:'bold', fontSize:'1.2em', border:'1px solid black'}} onClick={()=>processFunc(4)}>운행종료</button>
						</td>
					</tr>
					
				</tbody>
			</table>
			<div style={{width:'100%', marginTop:'10px'}}>
				<textarea style={{width:'90%', height:'400px'}} value={log}>

				</textarea>
			</div>
			
        </main>
	);
}

export default App;
