import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`


/* setting.css 데이터  */
@charset "utf-8";



/* 설정 - 기본 */
.setting_box { display:inline-block; width:100%; min-height:calc(100vh - 53px); background:#ebebed; padding:0px;}
.setting_box ul { display:inline-block; width:100%;}
.setting_box li { display:inline-block; width:100%;}

.setting_box span.section_title_setting { display:inline-block; width:100%; font-size:1.2em; padding:11px 0 11px 20px; font-weight:bold;}
.setting_box span.st_list { display:inline-block; width:100%; font-size:1.2em; padding:20px; background:#fff;}
.setting_box span.st_list span.st_name { display:inline-block;}
.setting_box span.st_list span.st_con { position:relative; display:inline-block; float:right;}
.setting_box span.st_list span.st_con span.point {color:#1fafd2;}
/* .setting_box span.st_list span.st_con a.more { position:absolute; display:inline-block; width:30px; height:30px; line-height:30px; text-align:center; top:-7px; right:-10px;}
.setting_box span.st_list span.st_con a.more img.icon_more {width:8px;} */
.setting_box span.st_list span.st_con img.icon_more {width:8px;}
span.st_con a.btn_update {display:inline-block; float:right; color:#1fafd2; border:1px solid #1fafd2; padding:0px 10px; font-size:0.8em; border-radius:30px;}
.setting_box span.st_list_bline {border-bottom:1px solid #eee;}


/* 설정 - 페이지 더보기 */
.setting_box li.white_box { display:inline-block; width:100%; background:#fff; min-height:calc(100vh - 53px); padding:20px;}
.setting_box li.white_box span.more_title { display:inline-block; width:100%; padding-bottom:20px; font-size:1.7em;}
.setting_box li.white_box span.more_info { display:inline-block; width:100%; line-height:160%;}


/* style 태그 내부  */
/* 앱푸시알림 체크 효과 */

#cbx0:checked + .setup_toggle:before {
  background: rgba(30, 148, 236, 0.5);
}
#cbx0:checked + .setup_toggle span {
  background: #1e94ec;
  transform: translateX(20px);
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
  box-shadow: 0 3px 8px rgba(79, 46, 220, 0.2);
}
#cbx0:checked + .setup_toggle span:before {
  transform: scale(1);
  opacity: 0;
  transition: all 0.4s ease;
}

#cbx1:checked + .setup_toggle:before {
  background: #1fafd2;
}
#cbx1:checked + .setup_toggle span {
  background: white;
  transform: translateX(25px);
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
  box-shadow: 0 3px 8px rgba(79, 46, 220, 0.2);
}
#cbx1:checked + .setup_toggle span:before {
  transform: scale(1);
  opacity: 0;
  transition: all 0.4s ease;
}

#cbx2:checked + .setup_toggle:before {
  background: rgba(30, 148, 236, 0.5);
}
#cbx2:checked + .setup_toggle span {
  background: #1e94ec;
  transform: translateX(20px);
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
  box-shadow: 0 3px 8px rgba(79, 46, 220, 0.2);
}
#cbx2:checked + .setup_toggle span:before {
  transform: scale(1);
  opacity: 0;
  transition: all 0.4s ease;
}

#cbx3:checked + .setup_toggle:before {
  background: rgba(30, 148, 236, 0.5);
}
#cbx3:checked + .setup_toggle span {
  background: #1e94ec;
  transform: translateX(20px);
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
  /* box-shadow: 0 3px 8px rgba(79, 46, 220, 0.2); */
}
#cbx3:checked + .setup_toggle span:before {
  transform: scale(1);
  opacity: 0;
  transition: all 0.4s ease;
}

.setup_toggle {
  position: relative;
  display: block;
  width: 40px;
  height: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
}
.setup_toggle:before {
  content: "";
  position: relative;
  top: -8px;
  left: -15px;
  width: 62px;
  height: 36px;
  display: block;
  background: #e9e9eb;
  border-radius: 25px;
  transition: background 0.2s ease;
}
.setup_toggle span {
  position: absolute;
  top: -5px;
  left: -12px;
  width: 30px;
  height: 30px;
  display: block;
  background: white;
  border-radius: 20px;
  /* box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2); */
  transition: all 0.2s ease;
}
.setup_toggle span:before {
  content: "";
  position: absolute;
  display: block;
  margin: -18px;
  width: 56px;
  height: 56px;
  background: rgba(30, 148, 236, 0.5);
  border-radius: 50%;
  transform: scale(0);
  opacity: 1;
  pointer-events: none;
}

.setup_center {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 20px);
}
`;


