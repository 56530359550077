import React, { Component } from 'react';
import { withCookies } from 'react-cookie'
import { createGlobalStyle } from "styled-components";
import Header from '../../components/Common/Header'
import ReserveApi from '../../lib/ReserveApi'
import store from '../../reduxStore'
import Loading from '../../components/Common/Loading'
import { connect } from 'react-redux';
import { getEndListRedux, getEndListPagingRedux } from '../../modules/UseHistory/UseHistory_ReduxModule'
import RnInterface from '../../lib/RnInterface'

import Util, { calert, calertConfirm, customMoment } from '../../lib/Util'

import icon_arrow from "../../img/record/icon_arrow.svg"
import icon_point from "../../img/record/icon_point.svg"
import icon_location from "../../img/record/icon_location.svg"
import MessageCss from '../../css/CustomGlobalStyle/Message'
import icon_calendar from "../../img/message/icon_calendar2.svg"

import { NavLink } from 'react-router-dom';


class UseHistoryView extends Component {
    state = {
        endList: [],
        loading: 0
    }

    asyncTest = async () => {
        try {
            let { CustomerNo } = store.getState().global_data.loginedData
            let { cookies } = this.props;
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            console.log(this.props)

            if (!this.props.getinfo.length) {
                this.props.initData(
                    CustomerNo,
                    this.props.page,
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                )
            } 

        } catch(e) {
            alert('데이터를 취득 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
            console.log(e)
            this.setState({loading:1})	// 로딩완료
        }
    }

    componentDidMount() {
        this.asyncTest();
        window.addEventListener('scroll', this.onScroll);
    }

    handleBack = () => {
        this.props.history.replace('/main');
    }

    handleHome = () => {
        this.props.history.replace('/main');
    }

    handleDetailView = (params) => {
        this.props.history.push(`/use_history_detail/${params}`);
    }

    // component를 닫게 되면 이벤트도 닫아줘야한다.
    componentWillUnmount() {       
        window.removeEventListener("scroll", this.onScroll);
    }
    
    // 스크롤 로딩 with redux
    onScroll = (e) => {
        const { innerHeight } = window;
        const { scrollHeight } = document.body;
        const myScroll = e.srcElement.scrollingElement.scrollTop

        if ((innerHeight + myScroll) >= scrollHeight - 1) {
            
            if (this.props.scrollDataSyncFlag === 1) {
                let { CustomerNo } = store.getState().global_data.loginedData
                let { cookies } = this.props;
        
                this.props.getPagingData(
                    CustomerNo,
                    this.props.page,
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                )
            }
            
        }
    };



    render() {
       

        let reserveEndList = (arr) => {
            return arr.map((data, idx) => {
                let classHeaderColor = (Number(data.DrivingType) === 3) ? 'record_hd record_hd_color3' : 'record_hd record_hd_color1';
                return (
                    <li className="record_box">
                        <span className={classHeaderColor}>
                            <span className="date">{data.RevStartDt === '' ? '날짜 정보 없음' : customMoment(data.RevStartDt).format('YYYY-MM-DD') }</span>
                            <span className="subject"  onClick={()=>{this.handleDetailView(data.DrivingNo)}}>
                                <span className="kind">{  ReserveApi.getDrivingTypeStr(data.DrivingType)}</span>
                                {/* <span className="number">예약번호 {customMoment(data.RegDt).format('YYYYMMDDHHmm')}</span> */}
                                <button className="btn_more">
                                    <img src={icon_arrow} className="hd_icon" alt="" />
                                </button>
                            </span>
                        </span>
                        <span className="record_detail">
                            <table className="rtable">
                                <tr>
                                    <th>출발</th>
                                    <td>{data.StartAddress}</td>
                                </tr>
                                <tr>
                                    <th>종료</th>
                                    <td>{data.EndAddress}</td>
                                </tr>
                                <tr>
                                    <th>운행시간</th>
                                    <td>{`${customMoment(data.RevStartDt).format('HH:mm')} ~ ${customMoment(data.RevEndDt).format('HH:mm')}`}</td>
                                    {/* <td>{`${customMoment(data.StartDate).format('HH:mm')} ~ ${customMoment(data.EndDate).format('HH:mm')}`}</td> */}
                                </tr>
                                <tr>
                                    <th>파트너</th>
                                    <td>{data.DriverName !== null ? data.DriverName : '미지정'}</td>
                                </tr>
                            </table>
                        </span>
                        <span className="record_pay">
                            <table className="rtable">
                                <tr>
                                    <th className="price">결제금액</th>
                                    <td className="price">{Util.numberWithCommas(''+data.PredictTotalCharge)}원</td>
                                </tr>
                                {/* <tr>
                                    <td colspan="2" className="cnumber">신용카드 **** **** **** 1234(API에 안보임)</td>
                                </tr> */}
                            </table>
                        </span>
                    </li>
                )
            })
        }

        let reserveEndListNew = (arr) => {
            return arr.map((data, idx) => {
                return (
                    <li className="data-list-item">
                        <div className="data-header">
                            <div className="desc-title" onClick={()=>{this.handleDetailView(data.DrivingNo)}}>
                                {data.ReserveType === 5 ? (
                                    <strong>월간모시러</strong>  
                                ) : (
                                    <strong>{  ReserveApi.getDrivingTypeStr(data.DrivingType)}</strong>
                                )}
                                <span className="desc-day">{data.RevStartDt === '' ? '날짜 정보 없음' : customMoment(data.RevStartDt).format('YYYY-MM-DD') }</span>                                              
                            </div>
                        </div>  
                        <ul className="desc-list">                                       
                            <li className="desc-item">
                                <div className="tit"><span>출발지</span></div>
                                <div className="desc"><span>{data.StartAddress}</span></div>
                            </li>
                            <li className="desc-item">
                                <div className="tit"><span>종료지</span></div>
                                <div className="desc"><span>{data.EndAddress}</span></div>
                            </li>  
                            <li className="desc-item">
                                <div className="tit"><span>운행시간</span></div>
                                <div className="desc"><span>{`${customMoment(data.RevStartDt).format('HH:mm')} ~ ${customMoment(data.RevEndDt).format('HH:mm')}`}</span></div>
                            </li>                                           
                            <li className="desc-item">
                                <div className="tit"><span>파트너</span></div>
                                <div className="desc"><span>{data.DriverName}</span></div>
                            </li>
                        </ul>  
                        {data.ReserveType === 5 ? null : (
                            <div className="data-amount">
                                <ul className="desc-list">    
                                    <li className="desc-item">
                                        <div className="amount-tit"><strong>결제금액</strong></div>
                                        <div className="amount-desc price">
                                            {data.ReseveCharge > 1 && (<p><strong className="text-money">{Util.numberWithCommas(String(data.ReseveCharge))}원</strong></p>)}
                                            {(data.PrePaidTicketYN === 'Y') && (
                                                <p><span className="mar-r5">충전권 결제</span><strong className="text-money">{Util.numberWithCommas(String(data.PrePaidTicketAmount))}원</strong></p>
                                            )}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        )}
                        
                    </li> 


                )
            })
        }
        
        if (!this.props.loadingMain) return (
            <>
                <Loading />
                <MessageCss />
                <Header
                    title="이용기록"
                    onBackButton={this.handleBack}
                    onHomeButton={this.handleHome}
                />
            </>
        );
        return (
            <>
                {!this.props.loading ? (<Loading />) : ''}
                <MessageCss />
                <Header
                    title="이용기록"
                    onBackButton={this.handleBack}
                    onHomeButton={this.handleHome}
                />

                {this.props.getinfo.length ? (
                    <main id="Contents">
                        <div className="count-filter-group grid cross-center">
                            <div className="col left count-area">총 <strong className="text-prime">{this.props.totalPage}</strong>건</div>
                            <div className="col right"></div>  
                        </div>

                        <div className="component-wrap">
                            <div className="data-list-container">
                                <ul className="data-list">
                                    {reserveEndListNew(this.props.getinfo)}
                                </ul>
                            </div>            
                        </div>  
                    </main>
                ) : (
                    <div className="message_box">
                        <span className="icon_box"><img src={icon_calendar} className="icon_check" alt="" /></span>
                        <span className="TXST_t1"><b>이용하신 내역</b>이 없습니다.</span>
                    </div>
                )}
                
            </>
        );
    }
};


const mapStateToProps = (state) => ({
    getinfo: state.UseHistory_ReduxModule.getinfo,
    page: state.UseHistory_ReduxModule.page,
    loading: state.UseHistory_ReduxModule.loading,
    loadingMain: state.UseHistory_ReduxModule.loadingMain,
    scrollDataSyncFlag: state.UseHistory_ReduxModule.scrollDataSyncFlag,
    totalPage: state.UseHistory_ReduxModule.totalPage
});

const mapDispatchToProps = (dispatch) => ({
    initData: (cn, page, ostype, deviceid, loginEncrypt) => dispatch(getEndListRedux(cn, page, ostype, deviceid, loginEncrypt)),
    getPagingData: (cn, page, ostype, deviceid, loginEncrypt) => dispatch(getEndListPagingRedux(cn, page, ostype, deviceid, loginEncrypt)),
    // decreaseStoreCount: () => dispatch(actions.decreaseCount()),
    // increaseCountAsyncThunk: () => dispatch(actions.increaseCountAsyncThunk()),
    // decreaseCountAsyncThunk: () => dispatch(actions.decreaseCountAsyncThunk()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(UseHistoryView));