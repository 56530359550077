import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { createGlobalStyle } from "styled-components";
import MessageCss from '../../css/CustomGlobalStyle/Message'

import axios from 'axios';
import CookieApi from '../../lib/CookieApi'
import { withCookies } from 'react-cookie';
import { Token } from '../../constantData'
import store from '../../reduxStore'
import RnInterface from '../../lib/RnInterface'

import icon_check from "../../img/login/icon_check.svg"

/*
검색 : <input.*[^>]
치환 : $0/

검색 : <img.*[^>]
치환 : $0 alt=""/
*/

class MemberFreeView extends Component {
    state = {
        CustomerNo: store.getState().global_data.loginedData.CustomerNo,
        loginEncrypt: '',
        cookieOsType: '',
        cookieDeviceId: '',
    }

    async componentDidMount() {
        try {
            const { cookies } = this.props;
            this.setState({
                cookieOsType: cookies.get('ostype'),
                cookieDeviceId: cookies.get('deviceid'),
                loginEncrypt: cookies.get('login_encrypt'),
            })
            await RnInterface.AndroidURLChange(this.props.location.pathname)
        }
        catch(e){
            console.log(e)
        }
        
        
    }
    

    promiseMemberFree = () => {
        // FreeCustomer?customerno={customerno}
        return new Promise((resolve, reject) => {
            axios({
                url: `/FreeCustomer?customerno=${this.state.CustomerNo}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'OsType':  this.state.osType,
                    'DeviceId': this.state.deviceId,
                    'Token': Token,
                    'Authorize' : this.state.loginEncrypt,
                },
            }).then(res => {
                resolve(res.data);
            }).catch((err) => {
                return reject(err);
            });
        });
    }

    handleMemberFree = async () =>{
        let freeResult = await this.promiseMemberFree();
        if (freeResult.Result === 'success') {
            CookieApi.RemoveCookie(this.props.cookies);
            this.props.history.replace('/member_free_complate');
        }       
    }

    render() {
        return (
            <>
                <MessageCss />
                <div className="message_box">
                    <span className="icon_box"><img src={icon_check} className="icon_check" alt="" /></span>
                    <span className="TXST_t1"><b>정말로 탈퇴하시겠습니까?</b></span>
                    <span className="TXST_t2">
                        탈퇴 요청 시 금액 정산 등 절차 진행 후 탈퇴 완료 처리<br />
                        (쿠폰, 충전권, 정기권 정산)
                    </span>
                    <span className="ms_btn_box">
                        {/* <button className="btn_st1_939393" onClick="">홈</button> */}
                        <button className="btn_st1_1fafd2" onClick={this.handleMemberFree}>탈퇴요청</button>
                    </span>
                </div>

            </>
        );
    }
};

export default withCookies(MemberFreeView);

