import React from 'react';
import {createGlobalStyle} from 'styled-components'
import PropTypes from 'prop-types'; // 패키지 추가

const ChangeSwitchColorGolfStyle = createGlobalStyle`
    .switch input:checked + label:before {background-color: #718ae4; }
`;

const AnimatedCheckbox = (props) => {
    return (
        <>
            {/* 골프예약일 경우에는  */}
            {props.reserveType === 3 ? (<ChangeSwitchColorGolfStyle />) :(<></>)}
            <span className="switch">
                <input type="checkbox" id="cbx1" checked={props.checkSwitch} onChange={props.onChangeCheckdata} style={{ display: 'none' }} />
                <label for="cbx1" className="setup_toggle"><span></span></label>
            </span>
        </>
    );
}

AnimatedCheckbox.propTypes = {
    reserveType: PropTypes.number,
    checkSwitch: PropTypes.bool,
    onChangeCheckdata: PropTypes.func,
}

export default AnimatedCheckbox;