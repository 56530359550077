import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import Header from '../../components/Common/Header'
import RnInterface from '../../lib/RnInterface'
import AppSettingCss from '../../css/CustomGlobalStyle/AppSetting'

import '../../css/default.css';
import '../../css/member.css';

class TermsMainView extends Component {

    state = {
        emailid: '',
        pushstatus: false,
        update: 1,
        loading:0,
        latestVersion:{
            Version:'0.0.0'
        },
        deviceVersion:'0.0.0',
    }

    
    async componentDidMount() {
        try {
            await RnInterface.AndroidURLChange(this.props.location.pathname)            
        }
        catch(e){
            console.log(e)
            this.setState({loading:1})
        }
        
    }
    
    async componentWillUnmount() { }


    handleHome = () => {
        this.props.history.replace('/main')
    }

    handleBack = () => {
        this.props.history.replace('/main')
    }

    handleGotoNavigate = (url) => {
        this.props.history.push(url)
    }

    render() {

        return (
            <>
                <AppSettingCss />               
                
                <Header
                    title="모시러 약관"
                    onBackButton={this.handleBack}
                    onHomeButton={this.handleHome}
                />
                <main id="Contents">
                    <div className="component-wrap">
                        <ul className="list appset">
                            <li className="item header">약관</li>
                            <li className="item">
                                <div class="item-link has-arw" onClick={()=>{this.handleGotoNavigate("/setting/service")}}>
                                    <span>이용약관</span>
                                    <i class="arw arw-more1"></i>
                                </div>
						    </li>
                            <li className="item">
                                <div class="item-link has-arw" onClick={()=>{this.handleGotoNavigate("/setting/privacy")}}>
                                    <span>개인정보처리방침</span>
                                    <i class="arw arw-more1"></i>
                                </div>
						    </li>
                            <li className="item header">편의기능</li>
                            <li className="item">
                                <div class="item-link has-arw" onClick={()=>{this.handleGotoNavigate("/setting/location")}}>
                                    <span>위치정보수집약관</span>
                                    <i class="arw arw-more1"></i>
                                </div>
						    </li>
                            
                        </ul>
                    </div>                    
                </main>

            </>
        );
    }
};

export default withCookies(TermsMainView);
