import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
@charset "utf-8";

/* Rating */
.rating_form {width:100%; min-height: calc(100vh - 120px);}
.rating_form ul { display:inline-block; width:100%;}
.rating_form li {}
.rating_form ul.ma_box { padding:0px 30px 20px 30px;}
.rating_form li.ma_notice {width:100vw; margin-left:-30px; background:#f5f6f7; color:#a5a6a8; padding:10px 20px; font-size:1.1em; letter-spacing:-1px; line-height:1.62;}

.rating_form li.info_form { display:inline-block; width:100%; margin:20px 0 0 0;}
.rating_form li.info_form span.user_profile { display:inline-block; width:100%; text-align:center;}
.rating_form li.info_form span.user_profile span.profile {display:inline-block; width:100%;}
.rating_form li.info_form span.user_profile span.profile img {width:170px;}
.rating_form li.info_form span.user_profile span.name {display:inline-block; width:100%; font-size:1.2em; padding:20px 0 0 0; font-weight:bold;}
.rating_form li.info_form span.user_profile span.number {display:inline-block; width:100%; font-size:1.1em; color:#999; padding:8px 0 0 0;}
.rating_form li.info_form span.user_profile span.quest {display:inline-block; width:100%; margin:25px 0 0 0; font-size:1.3em; font-weight:bold;}
.rating_form li.info_form span.user_profile span.rating_box {display:inline-block; width:100%; margin:8px 0 30px 0;}
.rating_form li.info_form span.user_profile span.rating_box .rating_btn {background:none; border:none; margin-right:5px;} 
.rating_form li.info_form span.user_profile span.rating_box .rating_btn img {width:36px;}
.rating_form li.info_form span.user_profile span.rating_box .rating_btn img.nostar {opacity:0.2;}

.rating_form li.info_form span.user_profile2 { display:inline-block; width:100%; text-align:center;}
.rating_form li.info_form span.user_profile2 textarea {display:inline-block; width:100%; height:100px; resize:none; border:1px solid #dadada; padding: 15px; box-sizing: border-box; font-size:1.1em;}
.rating_form li.info_form span.user_profile2 span {display:inline-block; width:100%; text-align:left;}


/* Member Information */
.member_form li.info_form { display:inline-block; width:100%; margin:10px 0 0 0;}
span.form_section { display:inline-block; width:100%; margin-bottom:0px;}
span.form_section span.tel_box { display:inline-block; width:100%; text-align:center;}
span.form_section span.tel_box a.btn_mobile { display:inline-block; border:1px solid #ccc; height:25px; line-height:25px; padding:10px 20px; border-radius:50px; margin-bottom:15px; }
span.form_section span.tel_box a.btn_mobile img {width:20px; margin-right:10px;}

/* Form Type */
textarea.frm1 { display:inline-block; width:100%; height:150px; padding:20px; box-sizing:border-box; border:1px solid #ddd; line-height:150%; font-size:1.1em;}

/* Button Box */
.rating_form li.btn_box {width:100%; position:relative; margin-top:5px;} /* 12.24 */
.rating_form li.btn_box2 {width:100%; padding:0px; position:relative; margin:50px 0 0px 0; left:0%;} /* 12.24 */

/* Button Type */
.btn_st_bmargin {margin-bottom:10px;}
.btn_st_f1f0f5 { display:inline-block; width:100%; background:#f1f0f5; height:50px; line-height:50px; font-size:1.3em; border:0px; color:#9a9a9a;}
.btn_st_2e303f { display:inline-block; width:100%; background:#2e303f; height:50px; line-height:50px; font-size:1.3em; border:0px; color:#fff;}
.btn_st_ffffff { display:inline-block; width:100%; background:#fff; height:50px; line-height:50px; font-size:1.3em; border:0px; color:#000;}
.btn_st2_ffffff { display:inline-block; width:100%; background:#fff; height:50px; line-height:50px; font-size:1.3em; border:1px solid #000; color:#000;}
.btn_st1_1fafd2 { display:inline-block; background:#1fafd2; height:45px; line-height:45px; font-size:1.1em; border:0px; color:#fff; padding:0 30px; border-radius:50px; font-weight:bold;}

.btn_st_49 {width:49% !important; margin-right:2%; float:left;}
.btn_st_50 {width:49% !important; float:left;}


footer { bottom:20px; padding:0px 30px 20px 30px; text-align:center;}
footer .btn_st_2e303f { display:inline-block; width:100%; background:#2e303f; height:50px; line-height:50px; font-size:1.3em; border:0px; color:#fff;}

`;