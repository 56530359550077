import React, { Component } from 'react';
import {withCookies} from 'react-cookie'

import AccountApi from '../lib/AccountApi';
import RnInterface from '../lib/RnInterface';
import CookieApi from '../lib/CookieApi'

import {global_data_async} from '../modules/Global/global_data'
import store from '../reduxStore'
import {calertPromise} from '../lib/Util'

import Loading from '../components/Common/Loading'

class AppleLoginRedirect extends Component {
    state = {
        loading:0
    }

    //이제 backend에서 넘어오는 값은 아래 형태가 된다.
    //001283.502b2071dbf947ba8b96ae4b01dc05d9.1302
    // apple@001283.502b2071dbf947ba8b96ae4b01dc05d9.1302 이런형태로 회원가입을 하게 될것이다.
    // 1/11 수정할것
    asyncProcessApple = async () => {
        try {
            const { id } = this.props.match.params;
            const { cookies } = this.props;
            // React Native로부터 ostype, deviceid 취득
            let deviceData = await RnInterface.getData('deviceinfo')
            let deviceInfoSplit = '';
    
            // false로 왔을때는 기본값 osType:A deviceid:1로 지정 (PC)
            // 차후에는 지워야할듯
            deviceData = (deviceData === false) ? 'I@@1' : deviceData;
    
            // deviceData가 false가 아니면 @@를 split한다.
            if (deviceData) {
                deviceInfoSplit = deviceData.split('@@')
            }
    
            let appleLoginId = 'apple@' + id
    
    
            // 로그인 시도
            let loginResult = await AccountApi.requestLogin(appleLoginId, '', 'apple', deviceInfoSplit[0], deviceInfoSplit[1]);
            
            // 로그인 시도 결과 성공시 
            if (loginResult.Result === 'success') {
                // redux store에 저장
                await store.dispatch(global_data_async(loginResult.Item));
                let autoLoginData = localStorage.getItem('autoLoginState_Apple') !== null ? Number(localStorage.getItem('autoLoginState_Apple')) : 1;
    
    
                this.setState({loading:1})
                // 데이터를 쿠키에 저장
                CookieApi.SettingCookie(cookies, {
                    UserId : loginResult.Item.UserId,
                    AccountType : loginResult.Item.AccountType,
                    hash : '',
                    LoginEncrypt : loginResult.Item.LoginEncrypt,
                    OsType : loginResult.Item.OsType,
                    DeviceId : loginResult.Item.DeviceId
                }, autoLoginData)

                // localStorage에 갱신
                localStorage.setItem('mosiler_cookie',JSON.stringify({
                    account_type        : loginResult.Item.AccountType,
                    deviceid            : loginResult.Item.DeviceId,
                    email_id            : loginResult.Item.UserId, 
                    login_encrypt       : loginResult.Item.LoginEncrypt,
                    ostype              : loginResult.Item.OsType,
                    auto_login_chk      : autoLoginData,
                    hashcode            : '',
                }))
            
    
                // main화면으로 이동
                this.props.history.replace('/main')
    
            } 
            // 로그인 시도 결과 실패시
            else {
                // 회원가입창으로 이동.
                localStorage.setItem('AccountType','apple');
                localStorage.setItem('c_emailid', appleLoginId);
                this.props.history.replace('/join/1')
            }
        }
        catch(e) {
            console.log(e)
            await calertPromise('애플 로그인 처리 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
            this.props.history.replace('/login')
        }
        
    }

    async componentDidMount (){
        try {
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            const { id } = this.props.match.params;
            if (id === 'error') {
                await calertPromise('애플 로그인에 실패하였습니다. 다시 시도해주세요.')
                this.props.history.replace('/login')
            } else {
                this.asyncProcessApple()
            }
            
        } catch(e) {
            await calertPromise('애플 로그인에 실패하였습니다. 다시 시도해주세요.')
            this.props.history.replace('/login')
        }
        
    }

    render() {
        if (!this.state.loading)
            return (
                <Loading />
            );
        return (
            <>
            </>
        );
    }
};

export default withCookies(AppleLoginRedirect);
