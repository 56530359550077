import RnInterface from "../lib/RnInterface";
import { calertPromise, isChkfeatureSupportVersion } from '../lib/Util'
class KakaoChannel {
    constructor() {
        this.loadScript();
    }

    loadScript() {
        console.log('loadScript호출')
        // DOM 생성
        let container = document.createElement('div');
        container.setAttribute("class","scroll-to-top")
        container.setAttribute("id","kakao_channel")
        
        let talkIcon = document.createElement('div');
        
        // 아이콘 터치시 버전을 체크하고 버전에 따라 분기 처리를 실시한다.
        talkIcon.onclick = async () => {
            // 카톡 링크
            const kakaoTalkLinkiOS = 'https://apps.apple.com/kr/app/%EC%B9%B4%EC%B9%B4%EC%98%A4%ED%86%A1-kakaotalk/id362057947'
            const kakaoTalkLinkAndroid = 'https://play.google.com/store/apps/details?id=com.kakao.talk'
            
            // 모시러 링크
            const mosilerLinkiOS = 'https://apps.apple.com/kr/app/%EB%AA%A8%EC%8B%9C%EB%9F%AC-%EC%9A%B4%EC%A0%84-%EB%8F%99%ED%96%89-%EC%84%9C%EB%B9%84%EC%8A%A4/id1366011185'
            const mosilerLinkAndroid = 'https://play.google.com/store/apps/details?id=com.mosiler.client'
            
            let osversion = await RnInterface.getData('devicetype')

            //1.3.20 버전 이후일 경우
            if (isChkfeatureSupportVersion('1.3.20')) {
                try {       
                    // 카톡채널 딥링크 오픈
                    let result = await RnInterface.getData('showkakaotalkchannel');
                    let resultJSON = JSON.parse(result);
                    
                    // 결과가 OK가 아닐경우(열리지 않았을 경우)
                    if (resultJSON.result !== 'OK') {
                        await calertPromise('현재 카카오톡이 미설치 되어 있습니다. 설치 후 다시 시도해주세요.')
                        // 카톡 링크를 OS별로 표시한다.
                        if (osversion === 'I') {
                            await RnInterface.callReactNative(`NewIntentLinkBrowsing${kakaoTalkLinkiOS}`)
                        }
                        else {
                            await RnInterface.callReactNative(`NewIntentLinkBrowsing${kakaoTalkLinkAndroid}`)
                        }
                    }
                }
                catch(e) {
                    // 딥링크로 카톡 오픈 중 문제 발생시.
                    await calertPromise('카카오톡을 열 수 없습니다.')
                }
            }
            // 1.3.20 이전 버전일때
            else {
                await calertPromise('고객앱 1.3.20 업데이트 후 사용 가능합니다. 고객앱을 업데이트 해주세요.')
                // OS별로 모시러 링크로 이동.
                if (osversion === 'I') {
                    await RnInterface.callReactNative(`NewIntentLinkBrowsing${mosilerLinkiOS}`)
                }
                else {
                    await RnInterface.callReactNative(`NewIntentLinkBrowsing${mosilerLinkAndroid}`)
                }
            }   
        }

        let button = document.createElement('button');
        button.setAttribute("class","btn-kakao-qna")
        
        talkIcon.append(button)
        container.append(talkIcon)

        // body에 붙인다.
        document.body.appendChild(container)    

        // 생성한 dom을 숨긴다.
        document.getElementById('kakao_channel').style.display = 'none'
    }

    // boot(settings) {
    //     // alert('kakao channel');
    // }

    // shutdown() {
        
    // }

    show() {
        document.getElementById('kakao_channel').style.display = 'block'
    }

    hide() {
        document.getElementById('kakao_channel').style.display = 'none'
    }
}

export default new KakaoChannel();