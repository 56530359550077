import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { createGlobalStyle } from "styled-components";
import icon_check from "../../img/login/icon_check.svg"
import CookieApi from '../../lib/CookieApi'
import { withCookies } from 'react-cookie';
import RnInterface from '../../lib/RnInterface'

const GlobalStyle = createGlobalStyle`
@charset "utf-8";

/* Intro Rolling */
.head {width:100%; position:fixed; top:0%; background: #fff; z-index:1000;}
.head ul.type1 { display:inline-block; position:relative; width:100%; text-align:center; height:50px; line-height:50px; padding:0px 0; margin-top:10px; box-sizing:border-box;}
.head ul.type1 li.btn {position:absolute; top:0%; left:0%; margin-left:10px;}
.head ul.type1 li.head_title {width:100%; text-align:center; font-size:1.5em; font-weight:bold;}
.head ul.type1 li.home {position:absolute; top:0%; right:0%; margin-right:15px;}
.head ul.type1 img.hd_icon {width:25px; height:25px;}
.head ul.type1 .btn_style1 {border:0px; background:#fff;}

/* Message result */
.message_box {width:300px; height:125px; top:50%; left:50%; margin:-250px 0 0 -150px; text-align:center; position:absolute;}
.message_box span.icon_box { display:inline-block; width:100%; text-align:center; padding:20px 0;}
.message_box span.icon_box img.icon_check { width:80px;}
.message_box span.TXST_t1 { display:inline-block; width:100%; font-size:1.5em;}
.message_box span.TXST_t2 { display:inline-block; width:100%; font-size:1.1em; color:#666; line-height:160%; margin:50px 0;}
.message_box span.ms_btn_box { display:inline-block; width:100%; text-align:center;}
.message_box span.ms_btn_box img.icon {width:15px;}


/* Button Type */
.btn_st_bmargin {margin-bottom:10px;}
.btn_st_f1f0f5 { display:inline-block; width:100%; background:#f1f0f5; height:50px; line-height:50px; font-size:1.3em; border:0px; color:#9a9a9a;}
.btn_st_2e303f { display:inline-block; width:100%; background:#2e303f; height:50px; line-height:50px; font-size:1.3em; border:0px; color:#fff;}
.btn_st_ffffff { display:inline-block; width:100%; background:#fff; height:50px; line-height:50px; font-size:1.3em; border:0px; color:#000;}

.btn_st1_1fafd2 { display:inline-block; background:#1fafd2; width:110px; height:45px; line-height:45px; font-size:1.1m; border:0px; color:#fff; padding:0; border-radius:50px; font-weight:bold; text-align:center;}
.btn_st1_939393 { display:inline-block; background:#939393; width:110px; height:45px; line-height:45px; font-size:1.1em; border:0px; color:#fff; padding:0; border-radius:50px; font-weight:bold; text-align:center;}
`;

/*
검색 : <input.*[^>]
치환 : $0/

검색 : <img.*[^>]
치환 : $0 alt=""/
*/

class MemberFreeComplateView extends Component {
    async componentDidMount() {
        try {
            await RnInterface.AndroidURLChange(this.props.location.pathname)
        }
        catch(e){
            console.log(e)
        }
        
    }
    
    handleGotoHome = () => {

        this.props.history.replace('/');
    }

    render() {
        return (
            <>
                <GlobalStyle />
                <div className="message_box">
                    <span className="icon_box"><img src={icon_check} className="icon_check" alt="" /></span>
                    <span className="TXST_t1"><b>회원 탈퇴</b>가 안전하게 <br/>완료되었습니다.</span>
                    <span className="TXST_t2">
                        그동안 이용해 주셔서 감사합니다.<br />
                        보다 나은 모시러 서비스로 다시 만나뵙길 바랍니다.
                    </span>
                    <span className="ms_btn_box">
                        {/* <button className="btn_st1_939393" onClick="">홈</button> */}
                        <button className="btn_st1_1fafd2" onClick={this.handleGotoHome}>홈</button>
                    </span>
                </div>

            </>
        );
    }
};

export default withCookies(MemberFreeComplateView);

