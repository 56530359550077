import React, { Component } from "react";
import { withLastLocation } from "react-router-last-location";
import { withCookies } from "react-cookie";
import Header from "../../../components/Common/Header";
import RnInterface from "../../../lib/RnInterface";
import store from '../../../reduxStore'
import Loading from '../../../components/Common/Loading'
import moment from 'moment'
import { calertPromise, setLocalStorage } from "../../../lib/Util";

import {
    RESERVE_TYPE_GENERAL_FLAG,
    RESERVE_TYPE_GOLF_FLAG,
    RESERVATION_BASE_TIME,
    RESERVATION_GOLF_BASE_TIME,
    RESERVATION_BASE_PRICE,
    RESERVATION_GOLF_PRICE,
} from '../../../constantData'


const NONE = 'NONE';
const OK = 'OK';
const FIFTEEN_DAYS_AFTER_FLAG = 'FIFTEEN_DAYS_AFTER_FLAG';



class ReserveIntroView extends Component {

    state = {
        reserveType: Number(this.props.match.params.state),        
        menuType: '1'
    };

    async componentDidMount() {
        await RnInterface.AndroidURLChange(this.props.location.pathname)
    }

    handleNextPage =  () => {
        const { reserveType } = this.state;
        this.props.history.replace('/main')
        this.props.history.push(`/reserve_renew/${reserveType}/1`)
    }
    handleGotoBack = () => {this.props.history.goBack();}
    handleGotoHome = () => {this.props.history.replace('/main')}
    tabClick = (e) => { this.setState({ menuType: e }) }
    getReserveTypeTime = (type) => {
        if (Number(type) === RESERVE_TYPE_GOLF_FLAG) return RESERVATION_GOLF_BASE_TIME;
        return RESERVATION_BASE_TIME;
    }

    render() {
        const {reserveType,menuType} = this.state;
        const subNoticePriceGeneral = () => (
            <ul className="text-sub">
                <li className="text-import">최소 이용시간은 2시간(120분)이며 예약시 60,000원(1시간당 30,000원)으로 부과됩니다.</li>
                <li className="text-import">예약한 시간보다 적게 이용했을 경우, 남은 시간에 대한 환불은 하지 않습니다.</li>
                <li>예약한 시간보다 많이 사용했을 경우 연장 운행요금이 적용되어 10분당 5,000원이 추가 부과 됩니다.</li>
            </ul>
        )

        const subNoticePriceGolf = () => (
            <ul className="text-sub">
                <li className="text-import">요금은 기본 10시간(600분)이며, 예약시 200,000원이 부과됩니다. 1시간 추가할 때마다 30,000원이 부과됩니다.</li>
                <li className="text-import">예약한 시간보다 적게 이용했을 경우, 남은 시간에 대한 환불은 하지 않습니다.</li>
                <li>예약한 시간보다 많이 사용했을 경우 연장 운행요금이 적용되어 10분당 5,000원이 추가 부과 됩니다.</li>
            </ul>
        )

        const generalReservePriceComponent = () => (
            <div className="inner-container">
                <div className="tbl type1">
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">이용시간</th>
                                <th scope="col" className="point">요금</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>기본 2시간</td>
                                <td className="point">60,000원</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    1시간 추가마다 30,000원씩 부과
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
                <div className="price-box mar-t20">
                    <div className="price-head">
                        <div className="price-title">추가요금</div>
                    </div>
                    <div class="grid gut-guide flex-wrap">
                        <div class="col col-6">
                            <div class="price-item">
                                <ul class="list inner-list">
                                    <li class="item header">연장 운행요금</li>
                                    {/* <li class="item mar-t15"> */}
                                    <li class="item">
                                        <strong className="point-item">5,000원/10분</strong> 
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col col-6">
                            <div class="price-item">
                                <ul class="list inner-list">
                                    <li class="item header">심야 할증요금</li>
                                    <li class="item">
                                        <p>23시~06시에<br />시작/종료시</p>
                                        <strong className="point-item">(각각 20,000원)</strong> 
                                    </li>
                                </ul>
                            </div>
                        </div>                                        
                    </div>                                      
                </div>
            </div>
        )

        const golfReservePriceComponent = () => (
            <div className="inner-container">
                <div className="tbl type1">
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">이용시간</th>
                                <th scope="col" className="point">요금</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>기본 10시간</td>
                                <td className="point">200,000원</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    1시간 추가마다 30,000원씩 부과
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
                <div className="price-box mar-t20">
                    <div className="price-head">
                        <div className="price-title">추가요금</div>
                    </div>
                    <div class="grid gut-guide flex-wrap">
                        <div class="col col-6">
                            <div class="price-item">
                                <ul class="list inner-list">
                                    <li class="item header">연장 운행요금</li>
                                    {/* <li class="item mar-t15"> */}
                                    <li class="item">
                                        <strong className="point-item">5,000원/10분</strong> 
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col col-6">
                            <div class="price-item">
                                <ul class="list inner-list">
                                    <li class="item header">심야 할증요금</li>
                                    <li class="item">
                                        <p>23시~06시에<br />시작/종료시</p>
                                        <strong className="point-item">(각각 20,000원)</strong> 
                                    </li>
                                </ul>
                            </div>
                        </div>                                        
                    </div>                                      
                </div>
            </div>
        )

        return (
        <>
            <Header
                title={`요금 안내`}
                onBackButton={this.handleGotoBack}
                onHomeButton={this.handleGotoHome}
            />

            <main id="Contents">
                <div className="tabs-container">
                    <div className="tabs-nav">
                        <ul className="tabs-group">
                            <li style={{width:"50%"}}>
                                <button className={this.state.menuType === '1' ? 'tab tab-on':'tab'} onClick={()=>{ this.tabClick('1') }}>운행요금</button>
                            </li>
                            <li style={{width:"50%"}}>
                                <button className={this.state.menuType === '3' ? 'tab tab-on':'tab'} onClick={()=>{ this.tabClick('3') }}>지역할증</button>
                            </li>
                        </ul>
                    </div>

                    <div className="tabs-content">
                    {(this.state.menuType === '1') && (
                        <>
                        
                        {/* 운행요금 */}
                        <div className="count-filter-group grid cross-center">
                            <div className="col">최소 이용시간 {this.getReserveTypeTime(reserveType)}시간</div>
                            <div className="col right">2022-03-15</div>
                        </div>

                        {(reserveType === RESERVE_TYPE_GENERAL_FLAG) ? generalReservePriceComponent() : golfReservePriceComponent()}

                        <div className="component-wrap space-top">
                            <section className="information-text on">
                                <h1 className="information-head">
                                    <button type="button" className="btn-viewmore">
                                        <i className="ico ico-info"></i>
                                        꼭 확인해주세요.
                                        <i className="icon arrow"></i>
                                    </button>
                                </h1>
                                <div className="information-body">
                                    <div className="info-desc">
                                        {(reserveType === RESERVE_TYPE_GENERAL_FLAG) ? subNoticePriceGeneral() : subNoticePriceGolf()}
                                    </div>  
                                </div>
                            </section> 
                        </div>     
                        </>    
                    )}

                    {(this.state.menuType === '2') && (
                        <>
                        {/* 충전권 */}
                        <div className="count-filter-group grid cross-center">
                            <div className="col left">금액기준 충전방식으로 충전금액별 혜택이 적용됩니다.</div>
                        </div>                              
                        <div className="inner-container">
                            <div className="tbl type1">                                        
                                <table>
                                    <thead>
                                        <tr>
                                            <th scope="col">구분</th>
                                            <th scope="col" className="subpoint">충전금액</th>
                                            <th scope="col" className="point">충전혜택</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>충전60</td>
                                            <td className="subpoint">600,000원</td>
                                            <td className="point">+30,000원</td>
                                        </tr>  
                                        <tr>
                                            <td>충전100</td>
                                            <td className="subpoint">1,000,000원</td>
                                            <td className="point">+70,000원</td>
                                        </tr>  
                                        <tr>
                                            <td>충전500</td>
                                            <td className="subpoint">5,000,000원</td>
                                            <td className="point">+500,000원</td>
                                        </tr>                                                                                                                                                                 
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="component-wrap space-top">
                            <section className="information-text on">
                                <h1 className="information-head">
                                    <button type="button" className="btn-viewmore">
                                        <i className="ico ico-info"></i>
                                        꼭 확인해주세요.
                                        <i className="icon arrow"></i>
                                    </button>
                                </h1>
                                <div className="information-body">
                                    <div className="info-desc">
                                        <h3 className="info-stit">충전권 이용안내</h3>
                                        <ul className="text-sub">
                                            <li>실제 사용금액으로 차감됩니다.</li>
                                            <li className="text-darkblue">이용금액을 초과할 경우, 초과된 금액만큼의 비용은 재충전 또는 카드결제가 가능합니다.</li>
                                            <li className="text-import">쿠폰과 정액권 중복 사용 불가합니다.</li>
                                            <li>현금 결제를 원하시는 고객님은 카카오톡채널(화면 우측 하단 노란색 아이콘)으로 문의해 주시기 바랍니다.</li>
                                        </ul>
                                    </div>  
                                    <div className="info-desc">  
                                        <h3 className="info-stit">충전권 환불안내</h3>
                                        <ul className="text-sub">
                                            <li>앱내에서 직접 취소가 불가하오니, 카카오톡채널(화면 우측 하단 노란색 아이콘)으로 문의해 주시기 바랍니다.</li>
                                            <li className="text-darkblue">미사용시 : 결제금액의 100% 환불(단, 구입후 7일 이내)</li>
                                            <li className="text-darkblue">사용시 : 결제금액의 10% 환불수수료 + 사용금액과 기간에 따른 추가수수료를 공제한 나머지 환불</li>
                                            <li>환불방식은 카카오톡채널(화면 우측 하단 노란색 아이콘)으로 문의해 주시기 바랍니다.</li>
                                        </ul>                                            
                                    </div>
                                </div>
                            </section> 
                        </div>                              
                        {/* 정액권 */}   
                        </>    
                    )}

                    {(this.state.menuType === '3') && (
                        <>
                        {/* 지역할증 */}
                        <div className="count-filter-group grid cross-center">
                            <div className="col">출발 가능지역 : 서울, 경기, 인천</div>
                            <div className="col right">도착 가능지역 : 전국</div>
                        </div>                              
                        <div className="inner-container">
                            <div className="tbl type1">                                        
                                <table>
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{width:"25%"}}>지역</th>
                                            <th scope="col" style={{width:"25%"}} className="point">할증요금</th>
                                            <th scope="col" style={{width:"25%"}}>지역</th>
                                            <th scope="col" style={{width:"25%"}} className="point">할증요금</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>경기/인천</td>
                                            <td className="point">30,000원</td>
                                            <td>충청</td>
                                            <td className="point">50,000원</td>
                                        </tr>
                                        <tr>
                                            <td>강원</td>
                                            <td className="point">70,000원</td>
                                            <td>전북/경북</td>
                                            <td className="point">60,000원</td>
                                        </tr>   
                                        <tr>
                                            <td>전남/경남</td>
                                            <td className="point">90,000원</td>
                                            <td>기타지역</td>
                                            <td className="point">120,000원</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="bul notice">
                                <p>기타지역 : 부산, 남해, 여수, 통영, 울산, 목포, 순천, 광양, 거제, 고흥, 보성 등</p>
                            </div>  
                            <div className="price-box mar-t20">
                                <div className="price-head">
                                    <div className="price-title">지역할증 요금 예시(지역기준 : 충청)</div>
                                </div>
                                <div class="grid gut-guide flex-wrap">
                                    <div className="col col-12">
                                        <div className="price-stitle">[ 왕복운행시 - 출발지, 종료지 동일 ]</div>
                                    </div>
                                    <div class="col col-6">
                                        <div class="price-item">
                                            <ul className="list inner-list">
                                                <li className="item header">서울 → 충청 → 서울</li>
                                                <li className="item">
                                                    <div className="item-row grid crocss-center">
                                                        <div className="col left"><span>출발지할증</span></div>
                                                        <div className="col right"><span>0</span></div>
                                                    </div>
                                                    <div className="item-row grid crocss-center">
                                                        <div className="col left"><span>지역할증</span></div>
                                                        <div className="col right"><span>0</span></div>
                                                    </div>
                                                    <div className="item-row grid crocss-center">
                                                        <div className="col left"><strong className="point-item">합계</strong></div>
                                                        <div className="col right"><strong className="point-item">0</strong></div>
                                                    </div>                                                                                                        
                                                </li>
                                            </ul>                                                
                                        </div>
                                    </div>
                                    <div class="col col-6">
                                        <div class="price-item">
                                            <ul className="list inner-list">
                                                <li className="item header">경기 → 충청 → 경기</li>
                                                <li className="item">
                                                    <div className="item-row grid crocss-center">
                                                        <div className="col left"><span>출발지할증</span></div>
                                                        <div className="col right"><span>30,000</span></div>
                                                    </div>
                                                    <div className="item-row grid crocss-center">
                                                        <div className="col left"><span>지역할증</span></div>
                                                        <div className="col right"><span>0</span></div>
                                                    </div>
                                                    <div className="item-row grid crocss-center">
                                                        <div className="col left"><strong className="point-item">합계</strong></div>
                                                        <div className="col right"><strong className="point-item">30,000</strong></div>
                                                    </div>                                                                                                        
                                                </li>
                                            </ul>                                                
                                        </div>
                                    </div>                                        
                                </div>

                                <div class="grid gut-guide flex-wrap mar-t10">
                                    <div className="col col-12">
                                        <div className="price-stitle">[ 편도운행시 ]</div>
                                    </div>
                                    <div class="col col-6">
                                        <div class="price-item">
                                            <ul className="list inner-list">
                                                <li className="item header">서울 → 경기</li>
                                                <li className="item">
                                                    <div className="item-row grid crocss-center">
                                                        <div className="col left"><span>출발지할증</span></div>
                                                        <div className="col right"><span>0</span></div>
                                                    </div>
                                                    <div className="item-row grid crocss-center">
                                                        <div className="col left"><span>지역할증</span></div>
                                                        <div className="col right"><span>30,000</span></div>
                                                    </div>
                                                    <div className="item-row grid crocss-center">
                                                        <div className="col left"><strong className="point-item">합계</strong></div>
                                                        <div className="col right"><strong className="point-item">30,000</strong></div>
                                                    </div>                                                                                                        
                                                </li>
                                            </ul>                                                
                                        </div>
                                    </div>
                                    <div class="col col-6">
                                        <div class="price-item">
                                            <ul className="list inner-list">
                                                <li className="item header">서울 → 충청 → 경기</li>
                                                <li className="item">
                                                    <div className="item-row grid crocss-center">
                                                        <div className="col left"><span>출발지할증</span></div>
                                                        <div className="col right"><span>0</span></div>
                                                    </div>
                                                    <div className="item-row grid crocss-center">
                                                        <div className="col left"><span>지역할증</span></div>
                                                        <div className="col right"><span>30,000</span></div>
                                                    </div>
                                                    <div className="item-row grid crocss-center">
                                                        <div className="col left"><strong className="point-item">합계</strong></div>
                                                        <div className="col right"><strong className="point-item">30,000</strong></div>
                                                    </div>                                                                                                        
                                                </li>
                                            </ul>                                                
                                        </div>
                                    </div> 
                                    <div class="col col-6">
                                        <div class="price-item">
                                            <ul className="list inner-list">
                                                <li className="item header">경기(분당) → 경기(수원)</li>
                                                <li className="item">
                                                    <div className="item-row grid crocss-center">
                                                        <div className="col left"><span>출발지할증</span></div>
                                                        <div className="col right"><span>30,000</span></div>
                                                    </div>
                                                    <div className="item-row grid crocss-center">
                                                        <div className="col left"><span>지역할증</span></div>
                                                        <div className="col right"><span>30,000</span></div>
                                                    </div>
                                                    <div className="item-row grid crocss-center">
                                                        <div className="col left"><strong className="point-item">합계</strong></div>
                                                        <div className="col right"><strong className="point-item">60,000</strong></div>
                                                    </div>                                                                                                        
                                                </li>
                                            </ul>                                                
                                        </div>
                                    </div>
                                    <div class="col col-6">
                                        <div class="price-item">
                                            <ul className="list inner-list">
                                                <li className="item header">경기 → 충청 → 전남</li>
                                                <li className="item">
                                                    <div className="item-row grid crocss-center">
                                                        <div className="col left"><span>출발지할증</span></div>
                                                        <div className="col right"><span>30,000</span></div>
                                                    </div>
                                                    <div className="item-row grid crocss-center">
                                                        <div className="col left"><span>지역할증</span></div>
                                                        <div className="col right"><span>90,000</span></div>
                                                    </div>
                                                    <div className="item-row grid crocss-center">
                                                        <div className="col left"><strong className="point-item">합계</strong></div>
                                                        <div className="col right"><strong className="point-item">120,000</strong></div>
                                                    </div>                                                                                                        
                                                </li>
                                            </ul>                                                
                                        </div>
                                    </div>                                                                                                                       
                                </div>
                            </div>                                                              
                        </div>

                        <div className="component-wrap space-top">
                            <section className="information-text on">
                                <h1 className="information-head">
                                    <button type="button" className="btn-viewmore">
                                        <i className="ico ico-info"></i>
                                        꼭 확인해주세요.
                                        <i className="icon arrow"></i>
                                    </button>
                                </h1>
                                <div className="information-body">
                                    <div className="info-desc">
                                        <ul className="text-sub">
                                            <li className="text-import">서울/경기/인천에서 출발 가능하며, 출발 지역이 경기/인천일 경우 출발지 할증 30,000원이 과금됩니다.</li>
                                            <li className="text-import">기존/신규 요금제 공통적용됩니다.</li>
                                            <li>왕복운행시 출발지할증이 과금됩니다.</li>
                                            <li>편도운행시 출발지할증 + 지역할증이 과금됩니다.</li>
                                        </ul>
                                    </div>
                                </div>
                            </section> 
                        </div>                             
                        {/* 지역할증 */}                                                                        
                        </>    
                    )}                        
                    </div>    
                </div>
            </main>
            {/* 해당 페이지의 스크롤을 맨 밑으로 내렸을때, 버튼 영역을 공백 공간으로 놔야 한다. 버튼은 fixed로 되어있다. */}
            <div style={{paddingBottom:'calc(1.25em + 3.375rem)'}}></div>
            <div className="component-wrap">
                <div className="btn-area btn-group-fixed horizontal">
                    <button type="button" className="btn large secondary text-bold" onClick={()=>this.handleNextPage(OK)}>
                        <span>예약하기</span>
                    </button>
                </div>
            </div>


        
        </>
        )
        return (
            <>
                {/* 왠지 이 화면 쓸 것 같아서 유지한다. */}
                {!this.state.loading ? (<Loading />) : ''}
                <Header
                    // title={`${ReserveApi.getDrivingTypeStr(this.state.reserveType)} 예약 (1/5)`}
                    title={`월간 모시러 소개`}
                    onBackButton={this.handleGotoBack}
                    onHomeButton={this.handleGotoHome}
                />
                <div className="booking_box2 hd_margin_top">
                    <div className="component-wrap inner-container">
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">월간 모시러 안내 페이지</label>
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    </div>
                                </div>	
                            </div>                                               
                        </div>
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">사용해 보시는건 어떨까요?</label>
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    </div>
                                </div>	
                            </div>                                               
                        </div>
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">여러분과 함께하는 월간 모시러</label>
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    </div>
                                </div>	
                            </div>                                               
                        </div>
                    </div>    
                   
                    {/* 해당 페이지의 스크롤을 맨 밑으로 내렸을때, 버튼 영역을 공백 공간으로 놔야 한다. 버튼은 fixed로 되어있다. */}
                    <div style={{paddingBottom:'calc(1.25em + 3.375rem)'}}></div>
                    <div className="component-wrap">
                        <div className="btn-area btn-group-fixed horizontal">
                            <button type="button" className="btn large secondary liner text-bold"   onClick={()=>this.handleGotoMainPage(FIFTEEN_DAYS_AFTER_FLAG)}>
                                <span>7일동안 보지않기</span>
                            </button>
                            <button type="button" className="btn large secondary text-bold"         onClick={()=>this.handleGotoMainPage(OK)}>
                                <span>확인</span>
                            </button>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}
//1.25 + 3.375

export default withLastLocation(withCookies(ReserveIntroView));
