import React, { Component } from 'react';
import '../css/app.ui.css';



class TestComponent extends Component {

    render() {
        /*
        const chkCarNum = (data) => {
            // 서울 63보3607
            // 40부 8969
            // 230수 1743
            // 외교114-001
            return new RegExp('^\\d{2,3}[가-힣]{1}\\d{4}$', 'g').test(data.trim()) ? "" : "실패"
        }
        const testCom = () => {
            return carNumData.map((data,idx)=>{
                if (chkCarNum(data) === "실패") {
                    return (<tr>
                        <td>{data}</td>
                        <td>{idx}</td>
                        <td>{chkCarNum(data)}</td>
                    </tr>)
                }
                return (<></>)
                
            })
        }
        */

        let per = 60
        return (
            <>
                

                <main id="Contents">
                    
                </main>

                
            </>
        );
    }
};

export default TestComponent;
