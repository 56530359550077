import ReactDOMServer from 'react-dom/server';

import Swal from 'sweetalert2';
import moment from 'moment'
import RnInterface from './RnInterface'
import ReserveApi from './ReserveApi'

import ic_10sales from '../img/coupon/ic_10sales.png'
import icon_user from '../img/rating/icon_user.svg'

import receiptImg from '../img/receipt_logo.svg'
import { Link } from 'react-router-dom';
import html2canvas from 'html2canvas'
import compareVersions  from 'compare-versions'

import randomKey from 'random-key'

import {
    NOTICE_ALERT_RESULT_CODE
} from '../constantData'

const Util = {};
Util.test = () => {
    alert();
}

Util.generateHtmlTagSpacenbsp = (count) => {
    let retData = '';
    for (let i=0; i<count-1; i++) {
        retData += '&nbsp;'
    }
    return (<div dangerouslySetInnerHTML={{__html: retData}}>{}</div>)
}

Util.dataURItoBlob = (dataURI) => {

    let  bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
        atob(dataURI.split(',')[1]) :
        unescape(dataURI.split(',')[1]);
    let  mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    let  max = bytes.length;
    let  ia = new Uint8Array(max);
    for (let  i = 0; i < max; i++)
        ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], { type: 'image/png' });

}

Util.numberWithCommas = (x) => {
    
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
}

// 숫자형태이며 6자리 형태이면 임시비밀번호 형태이다.
Util.isChkTempNumber = (data) => {
    let exptext = /^[0-9]*$/;
    return data.length == 6 && exptext.test(data);
}

// Email Address
Util.isChkEmail = (data) => {
    let exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
    return exptext.test(data);
}

Util.isChkPw = (data) => {
    let exptext = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,10}$/;
    return exptext.test(data);
}

Util.getNumber2Ciphers = (data) => {
    let intData = parseInt(data);
    let retData = ''

    if (intData >= 0 && intData <= 9) {
        retData = `0${intData}`
    } else {
        retData = `${data}`
    }
    return retData;
}

Util.getQueryStringObject = () => {
    var a = window.location.search.substr(1).split('&');
    if (a === "") return {};
    var b = {};
    for (var i = 0; i < a.length; ++i) {
        var p = a[i].split('=', 2);
        if (p.length === 1)
            b[p[0]] = "";
        else
            b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
    }
    return b;
}

// 1, 2 바이트 체크
Util.getStringLength = (str) => {
    var retCode = 0;
    var strLength = 0;

    for (var i = 0; i < str.length; i++) {
        var code = str.charCodeAt(i)
        var ch = str.substr(i, 1).toUpperCase()

        code = parseInt(code)

        if ((ch < "0" || ch > "9") && (ch < "A" || ch > "Z") && ((code > 255) || (code < 0)))
            strLength = strLength + 2;
        else
            strLength = strLength + 1;
    }
    return strLength;
}

// 01012341234 => 010-1234-1234로 변경
Util.getPhoneNumToHypen = (str) => {
    return str.replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "-");
}

// localStorage에서 앱 버전을 취득.
Util.getAppVersion = () => {
    return localStorage.getItem('device_app_version') === null ? false : ((localStorage.getItem('device_app_version') === 'false') ? false : localStorage.getItem('device_app_version'));
}

export const getAddressFavoriteDataLS = () => {
    return localStorage.getItem('address_favorite') !== null ? JSON.parse(localStorage.getItem('address_favorite')) : {};
}

export const setAddressFavoriteDataLS = (obj) => {
    localStorage.setItem('address_favorite', JSON.stringify(obj));
    return true;
}

export const getLocalStorage = (id) => {
    return localStorage.getItem(id) !== null ? JSON.parse(localStorage.getItem(id)) : null;
}

export const setLocalStorage = (id, jsondata) => {
    localStorage.setItem(id, JSON.stringify(jsondata));
}

export const getRandomKey = () => {
    return randomKey.generate(10)
}


/*
    호환성을 위한 체크메소드이다.
    해당 기능이 Native 업데이트가 필요한 경우에는 해당 기능을 막아놔야한다.
    ex) 영수증 이미지 저장 기능(고객앱 버전 1.3.10에 추가됨.)
    1. 영수증 이미지 저장기능을 고객앱쪽에 배포
    2. 현재 고객앱 업데이트 미실시 (1.3.10버전에 영수증 다운로드가 구현되어있지만 현재 버전은 1.3.00버전이다.)
    3. 영수증 저장 기능 수행
    => isChkfeatureSupportVersion 메소드에서 
        - 현재 앱 버전이 요구버전 이상이면? -> 기능 수행
        - 현재 앱 버전이 요구버전 미만이면? -> 기능 미수행
*/

export const isChkfeatureSupportVersion = (requiredVer) => {
    let nowAppVersion = Util.getAppVersion();
    let retData = false;

    if (nowAppVersion !== false) {
        let tmpResult = compareVersions(nowAppVersion, requiredVer);
        // requiredVer가 더 크면 업뎃이 안된것이므로 false
        // nowAppVersion이 더 크거나 requiredVer와 같을 때에는 true
        retData = tmpResult === -1 ? false : true
    }
    return retData;
}
/*
    요청사항의 String 처리를 담당한다.
    2022년 3월 리뉴얼 이후에 탑승자 추가 정보 입력이 추가됨에 따라
    **탑승자정보** 영유아1명 총1명 **end** 이러한 형태의 문자열이 추가 되었다.
    상기 정보는 관리시스템, 파트너앱에서만 표시하며, 고객앱에서는 표시되면 안된다.
    본 함수는 추가된 탑승자 정보에 대한 내용을 지우고, 고객이 요청사항에 기입한 내용만 출력되도록 한다.
*/
export const customerCommentStrProcess = (cc) => {
    let retData = '없음'
    if (cc && cc.length !== undefined) {
        let textFlag1 = cc.indexOf('**탑승자정보**')
        let textFlag2 = cc.indexOf('**end**')
        // 각각의 textFlag가 -1가 아닐 경우 (리뉴얼 예약을 실시한 경우)
        if (textFlag1 !== -1 && textFlag2 !== -1) {
            let tmpstr = cc.substring(textFlag1, textFlag2 + '**end**'.length)
            let tmpstr2 = cc.replace(tmpstr, '').trim()
            retData = tmpstr2.length ? tmpstr2 : '없음'
        }
        // 리뉴얼 전의 예약을 한 경우
        else {
            retData = cc;
        }
    }
    return retData
}




export const calert = (msg) => {
    let resultMsg = msg.replace(/\n/gi,'<br/>')
    // Todo replaceAll을 통해 \n문자를 <br/>로 변경해야한다.
    Swal.fire({
        width : 320,
        
        // icon: 'error',
        html: resultMsg,
        confirmButtonText: '확인',
        cancelButtonText: '아니오',
        confirmButtonColor: '#1fafd2',
        cancelButtonColor: '#939393',
        
        customClass: {
            popup:'sweet_popup_topleftrightradius',
            content: 'sweet_title_custom',
            confirmButton: 'sweet_confirmbutton_only',
            cancelButton: 'sweet_cancelbuttonImportant',
            actions: 'swal2_actions_custom'
        }
        
    })
    //btn_st2_939393
    //btn_st2_1fafd2
}

export const calertPromise = (msg) => {
    return new Promise(async (resolve) => {
        try {
            let resultMsg = msg.replace(/\n/gi,'<br/>')
            // Todo replaceAll을 통해 \n문자를 <br/>로 변경해야한다.
            await RnInterface.callReactNative('alertInStatusBarChange')
                    
            let result = await Swal.fire({
                width : 320,
                
                // icon: 'error',
                html: resultMsg,
                confirmButtonText: '확인',
                cancelButtonText: '아니오',
                confirmButtonColor: '#1fafd2',
                cancelButtonColor: '#939393',
                
                customClass: {
                    popup:'sweet_popup_topleftrightradius',
                    content: 'sweet_title_custom',
                    confirmButton: 'sweet_confirmbutton_only',
                    cancelButton: 'sweet_cancelbuttonImportant',
                    actions: 'swal2_actions_custom'
                }
            })
            await RnInterface.callReactNative('alertOutStatusBarChange')
            resolve(true)
        }
        catch(e){
            await RnInterface.callReactNative('alertOutStatusBarChange')
            resolve(true)
        }
    })
    
    //btn_st2_939393
    //btn_st2_1fafd2
}

export const calertPromise2 = (msg) => {
    return new Promise(async (resolve) => {
        try {
            let resultMsg = msg.replace(/\n/gi,'<br/>')
            // Todo replaceAll을 통해 \n문자를 <br/>로 변경해야한다.
            await RnInterface.callReactNative('alertInStatusBarChange')
                    
            let result = await Swal.fire({
                width : 320,
                
                // icon: 'error',
                html: resultMsg,
                confirmButtonText: '확인',
                cancelButtonText: '아니오',
                confirmButtonColor: '#1fafd2',
                cancelButtonColor: '#939393',
                
                customClass: {
                    popup:'sweet_popup_topleftrightradius',
                    content: 'sweet_title_custom2',
                    confirmButton: 'sweet_confirmbutton_only2',
                    cancelButton: 'sweet_cancelbuttonImportant',
                    actions: 'swal2_actions_custom'
                }
            })
            await RnInterface.callReactNative('alertOutStatusBarChange')
            resolve(true)
        }
        catch(e){
            await RnInterface.callReactNative('alertOutStatusBarChange')
            resolve(true)
        }
    })
    
    //btn_st2_939393
    //btn_st2_1fafd2
}

export const calertPromise3 = (msg) => {
    return new Promise(async (resolve) => {
        try {
            let resultMsg = msg.replace(/\n/gi,'<br/>')
            // Todo replaceAll을 통해 \n문자를 <br/>로 변경해야한다.
            await RnInterface.callReactNative('alertInStatusBarChange')
                    
            let result = await Swal.fire({
                width : 320,
                
                // icon: 'error',
                html: resultMsg,
                confirmButtonText: '확인',
                cancelButtonText: '아니오',
                confirmButtonColor: '#1fafd2',
                cancelButtonColor: '#939393',
                
                customClass: {
                    popup:'sweet_popup_topleftrightradius2',
                    content: 'sweet_title_custom3',
                    confirmButton: 'sweet_confirmbutton_only2',
                    cancelButton: 'sweet_cancelbuttonImportant',
                    actions: 'swal2_actions_custom'
                }
            })
            await RnInterface.callReactNative('alertOutStatusBarChange')
            resolve(true)
        }
        catch(e){
            await RnInterface.callReactNative('alertOutStatusBarChange')
            resolve(true)
        }
    })
    
    //btn_st2_939393
    //btn_st2_1fafd2
}

export const calertPromiseCustomMsg = (msg, confirmMsg) => {
    return new Promise( async (resolve) => {
        try {
            let resultMsg = msg.replace(/\n/gi,'<br/>')
            // Todo replaceAll을 통해 \n문자를 <br/>로 변경해야한다.
            await RnInterface.callReactNative('alertInStatusBarChange')
            
            await Swal.fire({
                width : 320,
                
                // icon: 'error',
                html: resultMsg,
                confirmButtonText: confirmMsg,
                cancelButtonText: '아니오',
                confirmButtonColor: '#1fafd2',
                cancelButtonColor: '#939393',
                
                customClass: {
                    popup:'sweet_popup_topleftrightradius',
                    content: 'sweet_title_custom',
                    confirmButton: 'sweet_confirmbutton_only',
                    cancelButton: 'sweet_cancelbuttonImportant',
                    actions: 'swal2_actions_custom'
                }
                
            })
            await RnInterface.callReactNative('alertOutStatusBarChange')
            
            resolve(true)
        }
        catch(e) {
            await RnInterface.callReactNative('alertOutStatusBarChange')
            resolve(true)
        }
        
        
    })
    
    //btn_st2_939393
    //btn_st2_1fafd2
}



export const calertConfirm = (msg) => {
    return new Promise(async (resolve)=>{
        try {
            let resultMsg = msg.replace(/\n/gi,'<br/>')
            // Todo replaceAll을 통해 \n문자를 <br/>로 변경해야한다.
            await RnInterface.callReactNative('alertInStatusBarChange')
            let result = await Swal.fire({
                width : 320,
                
                // icon: 'error',
                html: resultMsg,
                confirmButtonText: '확인',
                cancelButtonText: '아니오',
                confirmButtonColor: '#1fafd2',
                cancelButtonColor: '#939393',
                showCancelButton: true,
                customClass: {
                    popup:'sweet_popup_topleftrightradius',
                    content: 'sweet_title_custom',
                    confirmButton: 'sweet_confirmbutton_right',
                    cancelButton: 'sweet_cancelbutton_left',
                    actions: 'swal2_actions_custom'
                }
            })
            
            await RnInterface.callReactNative('alertOutStatusBarChange')
            if (result.isConfirmed) {
                resolve(true)
            } else {
                resolve(false)
            }
        }
        catch(e){
            await RnInterface.callReactNative('alertOutStatusBarChange')
            resolve(false)
        }
    })
    
    //btn_st2_939393
    //btn_st2_1fafd2
}

export const calertConfirmBtnCustom = (msg, noMsg, yesMsg) => {
    return new Promise(async (resolve)=>{
        try {
            let resultMsg = msg.replace(/\n/gi,'<br/>')
            // Todo replaceAll을 통해 \n문자를 <br/>로 변경해야한다.
            await RnInterface.callReactNative('alertInStatusBarChange')
            let result = await Swal.fire({
                width : 320,
                
                // icon: 'error',
                html: resultMsg,
                confirmButtonText: yesMsg,
                cancelButtonText: noMsg,
                confirmButtonColor: '#1fafd2',
                cancelButtonColor: '#939393',
                showCancelButton: true,
                customClass: {
                    popup:'sweet_popup_topleftrightradius',
                    content: 'sweet_title_custom',
                    confirmButton: 'sweet_confirmbutton_right',
                    cancelButton: 'sweet_cancelbutton_left',
                    actions: 'swal2_actions_custom'
                }
            })
            
            await RnInterface.callReactNative('alertOutStatusBarChange')
            if (result.isConfirmed) {
                resolve(true)
            } else {
                resolve(false)
            }
        }
        catch(e){
            await RnInterface.callReactNative('alertOutStatusBarChange')
            resolve(false)
        }
    })
    
    //btn_st2_939393
    //btn_st2_1fafd2
}

export const calertNoticePopup = (msg, link, complateLink, notino) => {
    // 메인 화면 처음 이동시 표시되는 공지내용의 이미지 클릭 후 내부에 화면 이동하는 처리를 넣었을 때
    // 메인 메뉴 팝업을 닫도록 한다.
    sessionStorage.setItem('mosiler_mainmenu_noticepopup_noticeno',notino )
    
    return new Promise(async (resolve)=>{
        try {
            let resultMsg = '';            

            // 링크가 있다면
            if (link !== null) {
                resultMsg = `<a href="${complateLink}">${msg}</a>`
            } 
            // 링크가 없다면
            else {
                resultMsg = msg
            }

            // let resultMsg = msg;
            // let addedLink = ``;

            await RnInterface.callReactNative('alertInStatusBarChange')
            // Todo replaceAll을 통해 \n문자를 <br/>로 변경해야한다.
            
            let result = await Swal.fire({
                width : 320,
                
                // icon: 'error',
                html: resultMsg,
                confirmButtonText: '확인',
                cancelButtonText: '다시 보지 않기',
                confirmButtonColor: '#1fafd2',
                cancelButtonColor: '#939393',
                showCancelButton: true,
                customClass: {
                    popup:'sweet_popup_notice',
                    content: 'sweet_content_notice',
                    confirmButton: 'sweet_btn_right_notice',
                    cancelButton: 'sweet_btn_left_notice',
                    actions: 'swal2_actions_custom'
                }
            })
            
            

            await RnInterface.callReactNative('alertOutStatusBarChange')
            if (result.dismiss !== undefined && (result.dismiss === 'backdrop')) {
                resolve(true)
            } else {
                if (result.isConfirmed) {
                    resolve(true)    
                } else {
                    resolve(false)
                }
            }
        }
        catch(e){
            await RnInterface.callReactNative('alertOutStatusBarChange')
            resolve(false)
        }
        
    })
    
    //btn_st2_939393
    //btn_st2_1fafd2
}

export const calertNoticePopup2 = (msg, link, complateLink, notino) => {
    // 메인 화면 처음 이동시 표시되는 공지내용의 이미지 클릭 후 내부에 화면 이동하는 처리를 넣었을 때
    // 메인 메뉴 팝업을 닫도록 한다.
    sessionStorage.setItem('mosiler_mainmenu_noticepopup_noticeno',notino )   
    
    return new Promise(async (resolve)=>{
        try {
            
            let resultMsg = '';            

            // 링크가 있다면
            if (link !== null) {
                resultMsg = `<a href="${complateLink}">${msg}</a>`
            } 
            // 링크가 없다면
            else {
                resultMsg = msg
            }

            // let resultMsg = msg;
            // let addedLink = ``;

            await RnInterface.callReactNative('alertInStatusBarChange')
            // Todo replaceAll을 통해 \n문자를 <br/>로 변경해야한다.
            
            let result = await Swal.fire({
                width : 320,
                html: resultMsg,
                confirmButtonText: (link !== null) ? '더 알아보기' : '확인',
                cancelButtonText: '',
                denyButtonText:'다시 보지 않기',
                confirmButtonColor: '#1fafd2',
                cancelButtonColor: '#939393',
                
                showCancelButton: true,
                showDenyButton:true,
                customClass: {
                    popup:'sweet_popup_notice',
                    content: 'sweet_content_notice',
                    confirmButton: 'sweet_btn_moreview_100_notice',
                    cancelButton: 'sweet_btn_cancel_icon_notice',
                    actions: 'swal2_actions_custom',
                    denyButton:'sweet_deny_btn'
                }
            })
            
            // true & false 방식에서 변경한다.
            // confirm : 링크 이동
            // cancel & backdrop : 화면 닫기
            // 다시 보지 않기
            await RnInterface.callReactNative('alertOutStatusBarChange')
            if (result.dismiss !== undefined && (result.dismiss === 'backdrop')) {
                resolve(NOTICE_ALERT_RESULT_CODE.CLOSE_MODAL)
            } else {
                if (result.isConfirmed) {
                    resolve(NOTICE_ALERT_RESULT_CODE.CONFIRM)
                } 
                else if(result.isDenied) {
                    resolve(NOTICE_ALERT_RESULT_CODE.DONTVIEW)   
                }
                else {
                    resolve(NOTICE_ALERT_RESULT_CODE.CLOSE_MODAL)
                }
            }
        }
        catch(e){
            await RnInterface.callReactNative('alertOutStatusBarChange')
            resolve(NOTICE_ALERT_RESULT_CODE.CLOSE_MODAL)
        }
        
    })
    
    //btn_st2_939393
    //btn_st2_1fafd2
}

export const calertReceiptPopup = (info) => {
    
    return new Promise(async (resolve)=>{
        try {
            let getExpenseMoney = (expensesList) => {
                let retData = 0;
                let sum = 0;
                try {
                    if (expensesList.length) {
                        for(let i in expensesList) {
                            sum += expensesList[i].UseAmount;
                        }
                        retData = sum;
                    }
                }
                catch(e) {
                    console.log(e);
                }
                
                return retData;
            }

            let getDayKorean = (data) => {
                let retData = '일';
                switch(data) {
                    case '1':
                        retData = '월';
                        break;
                    case '2':
                        retData = '화';
                        break;
                    case '3':
                        retData = '수';
                        break;
                    case '4':
                        retData = '목';
                        break;
                    case '5':
                        retData = '금';
                        break;
                    case '6':
                        retData = '토';
                        break;
                    default:
                        retData = '일';
                        break;
                }
                return retData;
            }


            let resultMsg = 
            `<div class="receipt-wrap">
                <div class="desc-header">
                    <div class="desc-title"><img src=${receiptImg}></div>
                    <p class="text-sub"><span>${info.CustomerName}</span>님, 모시러를 이용해주셔서 감사합니다.</p>
                </div>
                <div class="desc-list">
                    <div class="text-bold text-main text-black">
                        <span>${ReserveApi.getDrivingTypeStr(info.DrivingType)}</span>
                        <span>${customMoment(info.RevStartDt).format('yyyy년 MM월 DD일')} (${getDayKorean(customMoment(info.RevStartDt).format('d'))})</span>
                    </div>            
                </div> 
                <ul class="desc-list">
                    <li class="desc-item">
                        <div class="tit"><span>출발</span></div>
                        <div class="desc"><span>${info.StartAddress}</span></div>
                    </li>
                    <li class="desc-item">
                        <div class="tit"><span>도착</span></div>
                        <div class="desc"><span>${info.EndAddress}</span></div>
                    </li>
                    <li class="desc-item">
                        <div class="tit"><span>운행시간</span></div>
                        <div class="desc"><span>${customMoment(info.RevStartDt).format('HH:mm')} ~ ${customMoment(info.RevEndDt).format('HH:mm')}</span></div>
                    </li>
                    <li class="desc-item">
                        <div class="tit"><span>파트너명</span></div>
                        <div class="desc"><span>${info.DriverName}</span></div>
                    </li>                        
                </ul> 
                <ul class="desc-list">
                    <li class="desc-item">
                        <div class="tit"><span>운행요금</span></div>
                        <div class="desc price"><span>${Util.numberWithCommas(String(info.DrivingCharge))}원</span></div>
                    </li>
                    <li class="desc-item">
                        <div class="tit"><span>출발지할증</span></div>
                        <div class="desc price"><span>${Util.numberWithCommas(String(info.StartAreaFee))}원</span></div>
                    </li>
                    <li class="desc-item">
                        <div class="tit"><span>지역할증</span></div>
                        <div class="desc price"><span>${Util.numberWithCommas(String(info.AreaFee))}원</span></div>
                    </li>
                    <li class="desc-item">
                        <div class="tit"><span>심야할증</span></div>
                        <div class="desc price"><span>${Util.numberWithCommas(String(info.NightFee))}원</span></div>
                    </li>
                    <li class="desc-item">
                        <div class="tit"><span>연장운행</span></div>
                        <div class="desc price"><span>${Util.numberWithCommas(String(info.ExtraTimeFee))}원</span></div>
                    </li>
                    <li class="desc-item">
                        <div class="tit"><span>실비</span></div>
                        <div class="desc price"><span>${Util.numberWithCommas(String( getExpenseMoney(info.expensesList)  ))}원</span></div>
                    </li>
                    <li class="desc-item">
                        <div class="tit"><span>지명비</span></div>
                        <div class="desc price"><span>${Util.numberWithCommas(String(info.SelectDriverFee))}원</span></div>
                    </li>
                    <li class="desc-item">
                        <div class="tit"><span>기타</span></div>
                        <div class="desc price"><span>${Util.numberWithCommas(String(info.BillingOtherAmount))}원</span></div>
                    </li>  
                    <li class="desc-item">
                        <div class="tit"><span>할인금액</span></div>
                        <div class="desc price"><span>${Util.numberWithCommas(String( info.DiscountAmount + info.ReserveDiscountAmount ))}원</span></div>
                    </li>                                                
                </ul> 
                <div class="data-amount">                                          
                    <ul class="desc-list">                                             
                        <li class="desc-item">
                            <div class="amount-tit"><strong>결제금액</strong></div>
                            <div class="amount-desc price">
                                <p><strong class="text-money">${Util.numberWithCommas(String(info.ReseveCharge))}원</strong></p>
                                ${(info.PrePaidTicketYN === 'Y') ? `<p><span class="mar-r5">충전권 결제</span><strong class="text-money">${Util.numberWithCommas(String(info.PrePaidTicketAmount))}원</strong></p>` : '' }
                            </div>
                        </li>                                                                                                                                                                                 
                    </ul>
                </div>  

                <!-- 결제수단 우선 비표시.
                <ul class="desc-list">
                    <li class="desc-item">
                        <div class="tit"><span>결제수단</span></div>
                        <div class="desc price"><span>국민 12345678****1234</span><span>(개인)</span></div>
                    </li>                                           
                </ul>   -->
            </div>`
            ;

            
            await RnInterface.callReactNative('alertInStatusBarChange')
            // Todo replaceAll을 통해 \n문자를 <br/>로 변경해야한다.
            
            let result = await Swal.fire({
                width : 340,
                
                // icon: 'error',
                html: resultMsg,
                confirmButtonText: isChkfeatureSupportVersion('1.3.10') ? '영수증저장' : '확인',
                cancelButtonText: '닫기',
                confirmButtonColor: '#1fafd2',
                cancelButtonColor: '#939393',
                showCancelButton: true,
                customClass: {
                    popup:'sweet_popup_notice2',
                    content: 'sweet_content_notice2',
                    // confirmButton: 'sweet_confirmbutton_only',
                    confirmButton: 'sweet_btn_right_notice2',
                    cancelButton: 'sweet_btn_left_notice2',
                    actions: 'swal2_actions_custom2'
                }
            })
            
            await RnInterface.callReactNative('alertOutStatusBarChange')
            if (result.dismiss !== undefined && (result.dismiss === 'backdrop')) {
                resolve(true)
            } else {
                if (result.isConfirmed) {
                    if (isChkfeatureSupportVersion('1.3.10')) {
                        // UseHistoryDetailView.js 안에 있는 영수증 관련 div를 잠시 열어둔다.
                        document.getElementById('receipt-wrap').style.display='block';
                                            
                        // 캡쳐후 canvas형태로 저장.
                        let captureResult  = await html2canvas(document.querySelector('#receipt-wrap'));

                        // 캡쳐본을 base64형태로 변환.
                        let base64Img = captureResult.toDataURL('image/png').replace('data:image/png;base64,','')

                        // base64변환된 이미지를 web->native에 전송.
                        await RnInterface.callReactNative('captureReceipt'+base64Img)

                        // 캡쳐도중에 none시켜서 이미지가 짤려버리는 현상이 있어서 500ms이후에 끝내도록 처리한다.
                        setTimeout(()=>{
                            document.getElementById('receipt-wrap').style.display='none';
                        },500)
                    }
                    resolve(true)    
                } else {
                    resolve(false)
                }
            }
        }
        catch(e){
            await RnInterface.callReactNative('alertOutStatusBarChange')
            resolve(false)
        }
        
    })
    
    //btn_st2_939393
    //btn_st2_1fafd2
}



export const calertImageLinkPopup = (imgPath, complateLink) => {
    return new Promise(async (resolve)=>{
        try {
            let resultMsg = '';
            
            resultMsg = ReactDOMServer.renderToString(
                <a href={`${complateLink}`}>
                    <img src={`${imgPath}`} alt=""/>
                </a>
            );

            console.log(resultMsg)          

            await RnInterface.callReactNative('alertInStatusBarChange')
            // Todo replaceAll을 통해 \n문자를 <br/>로 변경해야한다.
            
            let result = await Swal.fire({
                width : 320,
                
                // icon: 'error',
                html: resultMsg,
                confirmButtonText: '확인',
                cancelButtonText: '다시 보지 않기',
                confirmButtonColor: '#1fafd2',
                cancelButtonColor: '#939393',
                showCancelButton: true,
                customClass: {
                    popup:'sweet_popup_notice',
                    content: 'sweet_content_notice',
                    confirmButton: 'sweet_btn_right_notice2',
                    cancelButton: 'sweet_btn_left_notice2',
                    actions: 'swal2_actions_custom'
                }
            })
            
            await RnInterface.callReactNative('alertOutStatusBarChange')
            if (result.dismiss !== undefined && (result.dismiss === 'backdrop')) {
                resolve(true)
            } else {
                if (result.isConfirmed) {
                    resolve(true)    
                } else {
                    resolve(false)
                }
            }
        }
        catch(e){
            console.log(e)
            await RnInterface.callReactNative('alertOutStatusBarChange')
            resolve(false)
        }
        
    })
    
    //btn_st2_939393
    //btn_st2_1fafd2
}

export const calert10CouponPopup = () => {
    return new Promise(async (resolve)=>{
        try {
            let resultMsg = `<img src=${ic_10sales} alt="" />`
            await RnInterface.callReactNative('alertInStatusBarChange')
            // Todo replaceAll을 통해 \n문자를 <br/>로 변경해야한다.
            
            let result = await Swal.fire({
                width : 320,
                
                // icon: 'error',
                html: resultMsg,
                confirmButtonText: '확인',
                confirmButtonColor: '#1fafd2',                
                showCancelButton: false,
                customClass: {
                    popup:'sweet_popup_notice',
                    content: 'sweet_content_notice',
                    confirmButton: 'sweet_confirmbutton_only',
                    
                    actions: 'swal2_actions_custom'
                }
            })
            
            await RnInterface.callReactNative('alertOutStatusBarChange')
            if (result.dismiss !== undefined && (result.dismiss === 'backdrop')) {
                resolve(true)
            } else {
                if (result.isConfirmed) {
                    resolve(true)    
                } else {
                    resolve(false)
                }
            }
        }
        catch(e){
            await RnInterface.callReactNative('alertOutStatusBarChange')
            resolve(false)
        }
        
    })
    
    //btn_st2_939393
    //btn_st2_1fafd2
}

export const calertImagePopup = (imageUrl, name, ssn) => {
    return new Promise(async (resolve)=>{
        try {
            let resultMsg = `<img src=${imageUrl} onerror="this.src='${icon_user}'" alt="" />`
            await RnInterface.callReactNative('alertInStatusBarChange')
            // Todo replaceAll을 통해 \n문자를 <br/>로 변경해야한다.
            
            let result = await Swal.fire({
                width : 320,
                
                // icon: 'error',
                html: resultMsg,
                confirmButtonText: '확인',
                confirmButtonColor: '#1fafd2',
                showCancelButton: false,
                
                customClass: {
                    popup:'sweet_popup_notice',
                    content: 'sweet_content_notice',
                    confirmButton: 'sweet_confirmbutton_only2',
                    
                    actions: 'swal2_actions_custom'
                }
            })
            
            await RnInterface.callReactNative('alertOutStatusBarChange')
            if (result.dismiss !== undefined && (result.dismiss === 'backdrop')) {
                resolve(true)
            } else {
                if (result.isConfirmed) {
                    resolve(true)    
                } else {
                    resolve(false)
                }
            }
        }
        catch(e){
            await RnInterface.callReactNative('alertOutStatusBarChange')
            resolve(false)
        }
        
    })
    
    //btn_st2_939393
    //btn_st2_1fafd2
}

// IOS에서는 년월일T시분(2020-10-10T13:00) 형식으로 변환해야 invalid date에러가 발생하지 않는다.
// https://stackoverflow.com/questions/13363673/javascript-date-is-invalid-on-ios
export const customMoment = (date) => {
    let tmpData = '';
    try {
        tmpData = date.split(' ');

        // 2020-10-10 (목) 10:30 이런 형태로 오는지를 확인.
        if (tmpData.length >= 3) {
            tmpData = `${tmpData[0]}${'T'}${tmpData[2]}`
        }
        // 위의 형식이 아니라면 있는 그대로 반영.
        else {
            tmpData = date;
        }
    } catch (e) {
        tmpData = date;
    }

    return moment(tmpData)
}

export function isUnvalidTokenStatus(response) {
    if (response && (Number(response.TokenStatus) === Number(0))) {
        console.log("TokenStatus", "true")
        return true;
    }
    else
        return false;
}
export function isChkResultSuccess(response) {
    if (response && response.Result === 'success') {
        console.log("Result", "success")
        return true;
    }
    else
        return false;
}
  

// 1~9까지의 숫자 형태를 01~09 이런형태로 반환한다.
Util.getStrDoubleDigit = (digit) => {
    let data = parseInt(digit);
    return (data <= 9 ? '0'+data : data)
}


// 현재 요일값을 받아 그 다음주 월요일이 되는 일수를 취득한다.
export const getMondayDays = (daysName) => {
    let retData = 0
    switch(daysName) {
        case 'Mon':
            retData = 7
            break;
        case 'Tue':
            retData = 6
            break;
        case 'Wed':
            retData = 5
            break;
        case 'Thu':
            retData = 4
            break;
        case 'Fri':
            retData = 3
            break;
        case 'Sat':
            retData = 2
            break;
        case 'Sun':
            retData = 1
            break;
        default:
            retData = 0
            break;
    }
    return retData;
}

export const getEnglishDayToKorean = (daysName) => {
    let retData = ''
    switch(daysName) {
        case 'Mon':
            retData = '월'
            break;
        case 'Tue':
            retData = '화'
            break;
        case 'Wed':
            retData = '수'
            break;
        case 'Thu':
            retData = '목'
            break;
        case 'Fri':
            retData = '금'
            break;
        case 'Sat':
            retData = '토'
            break;
        case 'Sun':
            retData = '일'
            break;
        default:
            retData = '0'
            break;
    }
    return retData;
}

export const isChkMonthlyMosilerCustomer = (CustomerCategory) => {
    let retData = false
    if (Number(CustomerCategory) === 3 || Number(CustomerCategory) === 4)     retData = true;
    return retData;
}
export const getCustomerCategoryViewTarget = (CustomerCategory) => {
    let retData = 1
    if (Number(CustomerCategory) === 3 || Number(CustomerCategory) === 4)     retData = 2;
    return retData;
}


export const getUserInfoEncrypt = (info1) => {
    /*
    info1
    {
        UserName: name,
        UserPhone: phone,
        UserEmail: email,
    }
    */
    let saveData = JSON.stringify(info1)
    return window.btoa(unescape(encodeURIComponent(saveData)))
}



export default Util;