import React, { Component } from 'react';
import RnInterface from '../../../lib/RnInterface'
import '../../../css/app.ui.css';

import reserveImg from '../../../img/monthly/policyprice/01.jpg'
import drivingImg from '../../../img/monthly/policyprice/02.jpg'
import priceImg from '../../../img/monthly/policyprice/03.jpg'


let search = '';
let params = '';
let menuState = '';

class PolicyPriceMonthly extends Component {

    state = {
        menuType: ''
    }

    async componentDidMount() {
        // 초기값 세팅
        // Load될때 
        await RnInterface.AndroidURLChange(this.props.location.pathname)
        console.log(this.props.location)
        this.setState({
            menuType: menuState
        })
    }

    tabClick = (e) => {
        this.setState({
            menuType: e
        })
    }

    render() {
        search = this.props.location.search.slice(1);
        params = new URLSearchParams(search);
        menuState = params.get('state');

        return (
            <>
                <header id="Header" className="">
                    <button type="button" className="btn-top-close" onClick={this.props.history.goBack}><span className="blind">닫기</span></button>
                    <h1>월간모시러 서비스 이용 안내</h1>
                </header>
                
                <main id="Contents">
                    <div className="tabs-container">
                        <div className="tabs-nav">
                            <ul className="tabs-group">
                                <li style={{width:"33.33%"}}>
                                    <button className={this.state.menuType === '1' ? 'tab tab-on':'tab'} onClick={()=>{ this.tabClick('1') }}>예약</button>
                                </li>
                                <li style={{width:"33.33%"}}>
                                    <button className={this.state.menuType === '2' ? 'tab tab-on':'tab'} onClick={()=>{ this.tabClick('2') }}>운행</button>
                                </li>
                                <li style={{width:"33.33%"}}>
                                    <button className={this.state.menuType === '3' ? 'tab tab-on':'tab'} onClick={()=>{ this.tabClick('3') }}>요금</button>
                                </li>
                            </ul>
                        </div>

                        <div className="tabs-content">
                            {(this.state.menuType === '1') && (
                                <div>
                                    <img src={reserveImg} style={{width:'100%'}} />
                                </div>
                            )}

                            {(this.state.menuType === '2') && (
                                <div>
                                    <img src={drivingImg} style={{width:'100%'}} />
                                </div>
                            )}

                            {(this.state.menuType === '3') && (
                                <div>
                                    <img src={priceImg} style={{width:'100%'}} />
                                </div>
                            )}            
                        </div>    
                    </div>
                </main>
            </>
        );
    }
};

export default PolicyPriceMonthly;
