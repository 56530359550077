import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
body {background:#f5f6fb;  }

/*Intro */
.intro_box {width:100vw; text-align:center; position: relative; min-height: calc(100vh - 90px); padding-top:70px;}
.intro_box span.TXST_t1 { display:inline-block; width:100%; font-size:2.5em; color:#000; padding-bottom:35px;}
.intro_box span.TXST_t2 { font-size:1.4em; color:#999; line-height:160%;}
.intro_box span.logo_box { display:inline-block; width:100%; text-align:center; padding:20px 0;}
.intro_box span.logo_box img.welcom { width:90%; max-width:300px;}
.intro_box span.btn_box { display:inline-block; width:100%; margin:30px 0 0 0;}
/* .intro_box span.btn_box button.btn_type1 { display:inline-block; width:49%; height:45px; min-height:45px; font-size:1.3em; float:left; margin-right:2px; border:1px solid #000; background:#fff;}
.intro_box span.btn_box button.btn_type2 { display:inline-block; width:49%; height:45px; min-height:45px; font-size:1.3em; float:left; border:1px solid #000;; background:#000; color:#fff;} */

footer { bottom:20px; padding:0 20px; text-align:center;}
footer button.btn_type1 { display:inline-block; width:49%; height:50px; min-height:50px; font-size:1.3em; font-weight:bold; float:left; margin-right:2px; border:1px solid #000; background:#fff;}
footer button.btn_type2 { display:inline-block; width:49%; height:50px; min-height:50px; font-size:1.3em; font-weight:bold; float:left; border:1px solid #000;; background:#000; color:#fff;}
`;