import React, { Component } from 'react';
import '../../css/default.css';
import RnInterface from '../../lib/RnInterface'
import store from '../../reduxStore';
import ReserveApi from '../../lib/ReserveApi'
import Header from '../../components/Common/Header'
import Util, { 
    calertPromise, 
    calertConfirm,
    getAddressFavoriteDataLS,
    setAddressFavoriteDataLS,
    getRandomKey
} from '../../lib/Util';

import {
    start_location_service,
    end_location_service,
    step_over_add_service,
    step_over_update_service,
    all_location_save
} from '../../modules/Reserve/LocationSelector_ReduxModule'

import {
    address_pick_service
} from '../../modules/Global/Address_ReduxModule'

import {
    ADDRESS_SEARCH_STATE_RESERVE_START,             // 예약 > 출발지
    ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_ADD,      // 예약 > 경유지 (추가)
    ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_UPDATE,   // 예약 > 경유지 (갱신)
    ADDRESS_SEARCH_STATE_RESERVE_END,               // 예약 > 종료지
    ADDRESS_SEARCH_STATE_MYINFO,                    // 내 정보 
    ADDRESS_SEARCH_STATE_JOIN,                      // 회원가입시

    STATE_FAVORITE_HOME_ADD,
    STATE_FAVORITE_HOME_MODIFY,
    STATE_FAVORITE_COMPANY_ADD,                     // 회사 추가
    STATE_FAVORITE_COMPANY_MODIFY,                  // 회사 편집
    STATE_FAVORITE_HOME,                            // 집 선택
    STATE_FAVORITE_COMPANY,                         // 회사 선택
    FAVORITE_INIT_TEMPLATE
} from '../../constantData';

import ScrollTopBtn from '../../components/Common/ScrollTopBtn'

const PLACE_FLAG = 0;                   // 장소별
const NEW_OLD_ADDRESS_FLAG = 1;         // 주소(신주소, 구주소)별

const MENU_BTN_LATEST_SEARCH = '0';       // 장소 클릭시
const MENU_BTN_LATEST_LOCATION = '1';     // 경로 클릭시

const NO_ROAD_ADDRESS_STR = '(도로명없음)';
const NO_JIBUN_ADDRESS_STR = '(지번없음)';

const LOCATION_FAVORITE_MANAGE_STR = '/location_favorite_manage';



class LocationFavorite extends Component {   
    state = {
        redirectUri: '',
        state: '',
        addressName: '',
        addressList: [],
        favoriteData:{
            homeAddress:'',
            homeLat: '',
            homeLng: '',
            companyAddress:'',
            companyLat: '',
            companyLng: '',       
            placeList : [],
            locationList : [],        
        },
        
        addressType: PLACE_FLAG,
        menuBtnState: MENU_BTN_LATEST_SEARCH,
        addressListLoading: 1,
        menuIdFlag: 0, // menuIdFlag가 0일때는 최근장소, 최근경로, 주소창 전부 표시, 1일때는 최근경로만 표시
        headerTitleName:'',
    }

    async componentDidMount() {
        try {
            await RnInterface.AndroidURLChange(this.props.location.pathname)

            // 즐겨찾기 데이터 취득
            let tmpFavoriteLS = localStorage.getItem('address_favorite'); 
            
            // 쿼리스트링 취득
            let data = Util.getQueryStringObject();

            console.log(this.props.location.pathname)

            // 초기화
            this.setState({
                favoriteData: (tmpFavoriteLS !== null) ? JSON.parse(tmpFavoriteLS) : FAVORITE_INIT_TEMPLATE,
                redirectUri: data.redirect,
                state: data.state,
                headerTitleName: this.isChkLocationFavoriteManage() ? '즐겨찾기관리' : this.getCustomHeaderName(data.state)
            })

        } catch (e) {
            console.log(e)
        }
    }

    // 현재 화면이 즐겨찾기 관리 화면인지를 판별하는 메소드이다. true: 즐겨찾기 화면. false: 즐겨찾기 선택화면
    isChkLocationFavoriteManage = () => {
        return (this.props.location.pathname === LOCATION_FAVORITE_MANAGE_STR)
    }

    // state에 따른 주소지 설정(공통사용)
    setAddressState = (state, data) => {
        return new Promise(async (resolve) => {
            let endFlag = 0;
            if (state === ADDRESS_SEARCH_STATE_RESERVE_START) {
                if (ReserveApi.isChkLimitLocal(data.address)) {
                    store.dispatch(
                        start_location_service({
                            startLocationAddress: data.address,
                            startLocationLat: parseFloat(data.lat),
                            startLocationLng: parseFloat(data.lng),
                        })
                    );
                    endFlag = 1;
                } else {
                    await calertPromise('출발지는 서울, 경기, 인천만 가능합니다.')
                    endFlag = 0;
                }
            } else if (state === ADDRESS_SEARCH_STATE_RESERVE_END) {
                store.dispatch(end_location_service({
                    endLocationAddress: data.address,
                    endLocationLat: parseFloat(data.lat),
                    endLocationLng: parseFloat(data.lng),
                }))
                endFlag = 1;
            }
            // 경유지 추가
            else if (state.indexOf(ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_ADD) !== -1) {
                store.dispatch(step_over_add_service({
                    waypointAddress: data.address,
                    waypointLat: parseFloat(data.lat),
                    waypointLng: parseFloat(data.lng),
                }))
                endFlag = 1;
            }
            // 경유지 변경
            else if (state.indexOf(ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_UPDATE) !== -1) {
                let idxData = parseInt(state.split('@@')[1])
                store.dispatch(step_over_update_service(idxData, {
                    waypointAddress: data.address,
                    waypointLat: parseFloat(data.lat),
                    waypointLng: parseFloat(data.lng),
                }))
                endFlag = 1;
            } else if (state === ADDRESS_SEARCH_STATE_JOIN || state === ADDRESS_SEARCH_STATE_MYINFO) {
                store.dispatch(address_pick_service(`${data.address}`))
                endFlag = 1;
            }
            resolve(endFlag)
        })
    }

    handleBack = () => {
        this.props.history.goBack();
    }

    handleMenuStateChange = async (stateId)=>{
        this.setState({menuBtnState: stateId})
    }

    // 최종 주소 문자열을 가공한다. (최근검색측 처리(localStorage))
    // 도로명 O || 지번 X  => 도로명 + 장소명
    // 도로명 X || 지번 O  => 지번명 + 장소명
    // 도로명 O || 지번 O  => 도로명 + 장소명
    // 도로명 X || 지번 X  => 장소명
    setFinishAddressStrHistory = (data) => {
        let retData = '';
        let tmpPlaceName = ( data.place_name !== undefined ) ? data.place_name : ''
        // 도로명이 있을 경우
        if (data.new_address !== NO_ROAD_ADDRESS_STR) {
            retData = `${data.new_address} ${tmpPlaceName}`;
        } 
        // 도로명이 없고 지번이 있을 경우
        else if (data.old_address !== NO_JIBUN_ADDRESS_STR) {
            retData = `${data.old_address} ${tmpPlaceName}`;
        } 
        // 도로명도 없고, 지번도 없을 경우
        else {
            retData = `${tmpPlaceName}`;
        }
        
        return retData;
        
    }   
    
    getCustomHeaderName = (state) => {
        let tmpStr = '즐겨찾기';
        
        if (state === ADDRESS_SEARCH_STATE_RESERVE_START) {
            tmpStr += '(출발지)'
        } 
        else if (state === ADDRESS_SEARCH_STATE_RESERVE_END) {
            tmpStr += '(종료지)'
        }
        // 경유지
        else if (state.indexOf(ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_UPDATE) !== -1) {
            let idxData = parseInt(state.split('@@')[1])
            tmpStr += `(경유지 ${idxData+1})`
        }
        else {
            tmpStr = '즐겨찾기관리';
        }
        console.log('헤더이름 : ', tmpStr)
        return tmpStr;
    }   
    
    handleSelectHomeCompany = (flag) => {

    }

    isChkFavoriteHome = () => {
        let tmpFavoData = getAddressFavoriteDataLS();
        let retData = false;
        
        if (tmpFavoData.homeAddress !== undefined && 
            tmpFavoData.homeLat !== undefined && 
            tmpFavoData.homeLng !== undefined) {
            if (tmpFavoData.homeAddress.length && 
                tmpFavoData.homeLat.length && 
                tmpFavoData.homeLng.length) {

                    retData = true;
            }
        }
        return retData;
    }

    isChkFavoriteCompany = () => {
        let tmpFavoData = getAddressFavoriteDataLS();
        let retData = false;
        
        if (tmpFavoData.companyAddress !== undefined && 
            tmpFavoData.companyLat !== undefined && 
            tmpFavoData.companyLng !== undefined) {
            if (tmpFavoData.companyAddress.length && 
                tmpFavoData.companyLat.length && 
                tmpFavoData.companyLng.length) {
                    retData = true;
            }
        }
        return retData;
    }
    
    // 즐겨찾기 관리 화면에서 주소 요소를 삭제했을때 해당 favoriteId가 최근장소 / 최근경로에 일치하는 것이 있는지를 확인하여.
    // 일치한다면 해당 idx값을 리턴한다.
    getFavoriteIdx = (id, arr) => {
        let retData = -1;
        try {
            for (let i in arr) {                
                if (arr[i].favoriteId !== undefined && arr[i].favoriteId === id) {
                    retData = i;
                    break;
                }
            }
        }
        catch(e) {
            console.log('getFavoriteIdx Error - ', e)
        }
        return retData;        
    }

    // 즐겨찾기 요소를 삭제한다.
    // 최근장소/최근경로의 데이터에는 영향을 주지 않는다.
    handleDeleteElement = async (data,idx) => {
        try {
            const {menuBtnState} = this.state;
            let historyAddressLS = JSON.parse(localStorage.getItem('history_address_search'))
            let historyLocationLS = JSON.parse(localStorage.getItem('history_location_list'))
            let favoriteLS = getAddressFavoriteDataLS();

            // 즐찾 삭제시 묻지않고 바로 삭제하도록 처리. 필요할경우는 아래 calertConfirm 주석 해제.
            // let result = await calertConfirm('해당 즐겨찾기를 삭제하시겠습니까?');
            // if (result) {
                // 장소
                if (menuBtnState === MENU_BTN_LATEST_SEARCH) {
                    // 최근장소쪽에도 즐겨찾기 마크를 비활성화시켜야 하기 때문에 연결되어있는 favoriteId의 idx를 취득.
                    let delPlaceFavoriteIdx = this.getFavoriteIdx(data.favoriteId, historyAddressLS);
                    // 최근장소의 해당 idx의 favoriteId를 없앤다.
                    historyAddressLS[delPlaceFavoriteIdx].favoriteId = undefined;
                    // 즐겨찾기 리스트에도 해당 favoriteId를 제외한 나머지 리스트를 취득한다.
                    favoriteLS.placeList = favoriteLS.placeList.filter((innerData) => {
                        return data.favoriteId !== innerData.favoriteId
                    })
                    // 최근장소에 대한 localStorage를 갱신한다.
                    localStorage.setItem('history_address_search', JSON.stringify(historyAddressLS))
                    
                } else {
                    // 최근장소쪽의 코멘트를 참고. 가리키는 데이터만 다를뿐 처리는 동일하다.
                    let delLocationFavoriteIdx = this.getFavoriteIdx(data.favoriteId, historyLocationLS);
                    historyLocationLS[delLocationFavoriteIdx].favoriteId = undefined;
                    favoriteLS.locationList = favoriteLS.locationList.filter((innerData) => {
                        return data.favoriteId !== innerData.favoriteId
                    })
                    localStorage.setItem('history_location_list', JSON.stringify(historyLocationLS))
                }
                // 즐겨찾기 리스트 랜더링
                this.setState({ favoriteData:  favoriteLS}, ()=>{
                    // 즐겨찾기 리스트의 localStorage갱신
                    setAddressFavoriteDataLS(favoriteLS)
                });
            // }
        }
        catch(e) {
            console.log(e)
        }
        
    }
    
    // 즐겨찾기 전체삭제 기능.
    handleDeleteAllElement = async () => {
        try {
            const {menuBtnState} = this.state;
            let historyAddressLS = JSON.parse(localStorage.getItem('history_address_search'))
            let historyLocationLS = JSON.parse(localStorage.getItem('history_location_list'))
            let favoriteLS = getAddressFavoriteDataLS();
            let tmpStr = (menuBtnState === MENU_BTN_LATEST_SEARCH) ? '장소' : '경로';
            let result = await calertConfirm(`${tmpStr}에 대한 모든 즐겨찾기를 삭제하시겠습니까?`);
            
            if (result) {
                // 장소
                if (menuBtnState === MENU_BTN_LATEST_SEARCH) {
                    // 최근장소의 모든 favoriteId를 없앤다.
                    for (let i in historyAddressLS) {
                        if (historyAddressLS[i].favoriteId !== undefined) {
                            historyAddressLS[i].favoriteId = undefined
                        }
                    }

                    // 즐겨찾기 장로 리스트의 모든 데이터를 삭제
                    favoriteLS.placeList = []

                    // 최근장소에 대한 localStorage를 갱신한다.
                    localStorage.setItem('history_address_search', JSON.stringify(historyAddressLS))
                }
                else {
                    // 최근장소의 모든 favoriteId를 없앤다.
                    for (let i in historyLocationLS) {
                        if (historyLocationLS[i].favoriteId !== undefined) {
                            historyLocationLS[i].favoriteId = undefined
                        }
                    }

                    // 즐겨찾기 장로 리스트의 모든 데이터를 삭제
                    favoriteLS.locationList = []
                    
                    // 최근장소에 대한 localStorage를 갱신한다.
                    localStorage.setItem('history_location_list', JSON.stringify(historyLocationLS))
                }

                // 즐겨찾기 리스트 랜더링
                this.setState({ favoriteData:  favoriteLS}, ()=>{
                    // 즐겨찾기 리스트의 localStorage갱신
                    setAddressFavoriteDataLS(favoriteLS)
                });
            }
            
        }
        catch(e) {
            console.log(e)
        }
        
    }

    render() {
        const {menuBtnState, headerTitleName} = this.state;
        // 최근 장소 history 리스트
        let tmpLatestHistoryAddressSearchListComponent = (arr) => {
            if (arr.length) {
                return arr.map((data, idx) => {
                    let favoriteFlag = data.favoriteId === undefined ? false : true;
                    
                    return (
                        
                        <li className="item" key={idx}>
                            <div className="grid cross-center">
                                <div className="col btn-favorite-info">
                                    <button type="button">
                                        {/* <i className="ico ico-favorite-on"></i><span className="blind">즐겨찾기 해제</span> */}
                                        {/* 즐겨찾기 해제 - 차후에 개발 */}
                                        <i className={favoriteFlag ? "ico ico-favorite-on" : "ico ico-favorite"}></i><span className="blind">즐겨찾기 등록</span>
                                    </button>
                                </div>
                                <div className="col col-8" onClick={ async () => {
                                    if (!this.isChkLocationFavoriteManage()) {
                                        let endFlag = await this.setAddressState(this.state.state, {
                                            address: this.setFinishAddressStrHistory(data),
                                            lat:data.lat,
                                            lng:data.lng,
                                        })
        
                                        if (endFlag) {
                                            this.props.history.replace(`${this.state.redirectUri}`);
                                        }
                                    }                                    
                                }}> 
                                    <span className="route-tit">
                                        {/* {(data.place_name !== undefined && data.place_name !== '') ? data.place_name : '(장소명없음)'} */}
                                        {(data.searchKeyword !== undefined && data.searchKeyword !== '') ? data.searchKeyword : '(검색어없음)'}
                                    </span>
    
                                    <p>{this.setFinishAddressStrHistory(data)}</p>
                                </div>
                                <div className="col right"><span></span></div>
                                <div className="col">
                                    <button type="button" onClick={()=>{this.handleDeleteElement(data,idx)}}>
                                        <span><i className="ico ico-del">삭제</i></span>
                                    </button>
                                {/* {this.isChkLocationFavoriteManage() ? (
                                    <button type="button" onClick={()=>{this.handleDeleteElement(data,idx)}}>
                                        <span><i className="ico ico-del">삭제</i></span>
                                    </button>
                                ) : null} */}
                                    
                                </div>
                            </div>
                        </li>
                    )
                });
            }

            return (
                <div class="no-data">
                    <p class="text-main">장소에 대한 즐겨찾기가 없습니다.</p>
                    <p class="text-main">즐겨찾기 추가는 출발지, 종료지, 경유지 선택시에 표시되는 최근장소 옆 별을 활성화해주세요.</p>
                </div>   
            )   
        }

        // 최근 경로 history 리스트
        let tmpLatestHistoryLocationListComponent = (arr) => {
            if (arr.length) {
                return arr.map((data, idx) => {
                    let favoriteFlag = data.favoriteId === undefined ? false : true;
                    
                    return (
                        <li className="item" key={idx}>
                            <div className="grid cross-center">
                                <div className="col btn-favorite-info">
                                    {/* 즐찾은 차후에 개발. */}
                                    <button type="button">
                                        {/* <i className="ico ico-favorite-on"></i><span className="blind">즐겨찾기 해제</span> */}
                                        <i className={favoriteFlag ? "ico ico-favorite-on" : "ico ico-favorite"}></i><span className="blind">즐겨찾기 등록</span>
                                    </button>
                                </div>
                                <div className="col col-9 juso-list" onClick={()=>{
                                    if (!this.isChkLocationFavoriteManage()) {
                                        store.dispatch(all_location_save(this.state.favoriteData.locationList[idx]))
                                        this.props.history.replace(`${this.state.redirectUri}`);
                                    }
                                    
                                }}>
                                    {/* 출발지 */}
                                    <span className="start">{data.startLocation}</span>
                                    {/* 경유지 */}
                                    {data.stopOverList.map((data2, idx) => (
                                        <span className="vial">{data2.waypointAddress}</span>
                                    ))}
                                    {/* 종료지 */}
                                    <span className="goal">{data.endLocation}</span>
                                </div>
                                <div className="col right"><span></span></div>
                                <div className="col">
                                    <button type="button" onClick={()=>{this.handleDeleteElement(data,idx)}}>
                                        <span><i className="ico ico-del">삭제</i></span>
                                    </button>
                                    {/* {this.isChkLocationFavoriteManage() ? (
                                        <button type="button" onClick={()=>{this.handleDeleteElement(data,idx)}}>
                                            <span><i className="ico ico-del">삭제</i></span>
                                        </button>
                                    ) : null} */}
                                </div>
                            </div>
                        </li>
                    )
                });    
            }
            return (
                <div class="no-data">
                    <p class="text-main">경로에 대한 즐겨찾기가 없습니다.</p>
                    <p class="text-main">즐겨찾기 추가는 출발지, 종료지, 경유지 선택시에 표시되는 최근경로 옆 별을 활성화해주세요.</p>
                </div>   
            )   
        }
            
        let visibleAllDeleteComponent = () => {
            const {favoriteData, menuBtnState} = this.state
            let retComponent = null;
            try {
                if (this.isChkLocationFavoriteManage()) {
                    if (menuBtnState === MENU_BTN_LATEST_SEARCH) {
                        if (favoriteData.placeList.length) {
                            retComponent = (
                                <button className="btn text2" onClick={()=>{this.handleDeleteAllElement()}}>
                                    전체삭제
                                </button>
                            )
                        }
                
                    }
                    // 경로일경우
                    else {
                        if (favoriteData.locationList.length) {
                            retComponent = (
                                <button className="btn text2" onClick={()=>{this.handleDeleteAllElement()}}>
                                    전체삭제
                                </button>
                            )
                        }
                    }
                }
            }
            catch(e){
                console.log(e)
            }

            return retComponent;
        }

        return (
            <>
                <Header
                    title={headerTitleName}
                    onBackButton={this.handleBack}
                />
                
                <main id="Contents">
                    <div className="route-list-container">
                        <ul className="list frequent">                                                                 
                            <li className="item">
                                <div className="grid cross-center">
                                    <div className="col col-2">
                                        <button type="button" className="btn-frequent"><i className="ico ico-home"></i><span>집</span></button>
                                    </div>
                                    <div className="col col-9 juso-list" style={{paddingLeft:'5px'}} onClick={ async ()=>{
                                        if (this.isChkFavoriteHome()) {
                                            const { state } = this.state;
                                            // 즐겨찾기 관리 화면이라면
                                            if (this.isChkLocationFavoriteManage()) {
                                                // 즐겨찾기 편집 처리.
                                                this.props.history.push(`/address_search_sub?state=${STATE_FAVORITE_HOME_MODIFY}`)

                                            }
                                            else {
                                                // 즐겨찾기 선택 처리.
                                                let tmpFavoData = getAddressFavoriteDataLS();
                                                let endFlag = await this.setAddressState(state, {
                                                    address: tmpFavoData.homeAddress,
                                                    lat:tmpFavoData.homeLat,
                                                    lng:tmpFavoData.homeLng,
                                                })
                                                
                                                if (endFlag) {
                                                    this.props.history.replace(`${this.state.redirectUri}`);
                                                }
                                            }
                                            
                                        }

                                        else {
                                            this.props.history.push(`/address_search_sub?state=${STATE_FAVORITE_HOME_ADD}`)
                                        }

                                    }}>
                                        {this.state.favoriteData.homeAddress.length ? this.state.favoriteData.homeAddress : '집 주소를 등록해주세요.'}
                                    </div>
                                    <div className="col col-1 right">
                                        <button type="button" onClick={async()=>{
                                            let result = await calertConfirm('집 주소를 삭제하시겠습니까?')
                                            if (result) {
                                                let tempLS = getAddressFavoriteDataLS();
                                                tempLS.homeAddress = '';
                                                tempLS.homeLat = '';
                                                tempLS.homeLng = '';

                                                //setState처리 실시
                                                this.setState(prevState => {
                                                    return {
                                                        favoriteData: tempLS
                                                    };
                                                }, ()=>{
                                                    setAddressFavoriteDataLS(tempLS)
                                                });
                                                
                                            }
                                        }}>
                                            <span><i className="ico ico-del">삭제</i></span>
                                        </button>
                                    </div>
                                </div>                             
                            </li> 
                            <li className="item">
                                <div className="grid cross-center">
                                    <div className="col col-2">
                                        <button type="button" className="btn-frequent"><i className="ico ico-office"></i><span>회사</span></button>
                                    </div>
                                    <div className="col col-9 juso-list"  style={{paddingLeft:'5px'}}  onClick={ async ()=>{
                                        if (this.isChkFavoriteCompany()) {
                                            const { state } = this.state;
                                            
                                            // 즐겨찾기 관리 화면이라면
                                            if (this.isChkLocationFavoriteManage()) {
                                                // 즐겨찾기 편집 처리.
                                                this.props.history.push(`/address_search_sub?state=${STATE_FAVORITE_COMPANY_MODIFY}`)

                                            }
                                            else {
                                                let tmpFavoData = getAddressFavoriteDataLS();
                                                let endFlag = await this.setAddressState(state, {
                                                    address: tmpFavoData.companyAddress,
                                                    lat: tmpFavoData.companyLat,
                                                    lng: tmpFavoData.companyLng,
                                                })
    
                                                if (endFlag) {
                                                    this.props.history.replace(`${this.state.redirectUri}`);
                                                }
                                            }
                                            
                                        } else {
                                            this.props.history.push(`/address_search_sub?state=${STATE_FAVORITE_COMPANY_ADD}`)
                                        }
                                    }}>
                                        {this.state.favoriteData.companyAddress.length ? this.state.favoriteData.companyAddress : '회사 주소를 등록해주세요.'}
                                        
                                    </div>
                                    <div className="col col-1 right">
                                        <button type="button" onClick={async()=>{
                                            let result = await calertConfirm('회사 주소를 삭제하시겠습니까?')
                                            if (result) {
                                                let tempLS = getAddressFavoriteDataLS();
                                                tempLS.companyAddress = '';
                                                tempLS.companyLat = '';
                                                tempLS.companyLng = '';

                                                //setState처리 실시
                                                this.setState(prevState => {
                                                    return {
                                                        favoriteData: tempLS
                                                    };
                                                }, ()=>{
                                                    setAddressFavoriteDataLS(tempLS)
                                                });
                                            }
                                        }}>
                                            <span><i className="ico ico-del">삭제</i></span>
                                        </button>
                                    </div>
                                </div>                             
                            </li>                             
                        </ul>    									
					</div>   
                    {/* 최근장소 */}
                    <div className="component-wrap space-top"> 
                        <div className="count-filter-group bg-white grid cross-center">
                            <div className="col">
                                <ul className="tabs-address">
                                    <li>
                                        <button 
                                            className={menuBtnState === MENU_BTN_LATEST_SEARCH ? "tab tab-on" : "tab"} 
                                            onClick={()=>{this.handleMenuStateChange(MENU_BTN_LATEST_SEARCH)}} >장소 <strong>{this.state.favoriteData.placeList.length}</strong>
                                        </button>
                                    </li>
                                    <li>
                                        <button 
                                            className={menuBtnState === MENU_BTN_LATEST_LOCATION ? "tab tab-on" : "tab"}
                                            onClick={() => {this.handleMenuStateChange(MENU_BTN_LATEST_LOCATION)}} > 경로 <strong > {this.state.favoriteData.locationList.length} </strong>
                                        </button>
                                    </li>                                
                                </ul> 
                            </div>
                            <div className="col right">
                                {visibleAllDeleteComponent()}
                                {/* {this.isChkLocationFavoriteManage() ? (
                                <button className="btn text2" onClick={()=>{this.handleDeleteAllElement()}}>
                                    전체삭제
                                </button>
                                ) : null} */}
                            </div>                                                  
                        </div>	
                        {/* 최근장소 - View */}
                        <div className="route-list-container">
                            <ul className="list route">              
                            {  
                                (menuBtnState === MENU_BTN_LATEST_SEARCH) ? 
                                    tmpLatestHistoryAddressSearchListComponent(this.state.favoriteData.placeList)  :        // 최근장소
                                    tmpLatestHistoryLocationListComponent(this.state.favoriteData.locationList)               // 최근경로
                            }                             	                               			        					        
                            </ul>
                        </div>	
                    </div>                                                                                
                </main>  

                <ScrollTopBtn position={'normal'}/>
 

            </>
        );
    }
};

export default LocationFavorite;
