import React, { Component } from 'react';
import {
    ADDRESS_SEARCH_STATE_RESERVE_START,
    ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_ADD,
    ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_UPDATE,
    ADDRESS_SEARCH_STATE_RESERVE_END,
} from '../../constantData'

import icon_delete from '../../img/booking/icon_delete.svg'
import icon_location from '../../img/booking/icon_location.svg'
import icon_point from '../../img/booking/icon_point.svg'         // 2
import icon_point_waypoint from '../../img/booking/icon_point_waypoint.svg'         // 2
import icon_switching from '../../img/booking/icon_switching.svg'         // 2
import icon_plus from '../../img/booking/icon_plus.svg'         // 2
import {calertPromise} from '../../lib/Util'

const INIT_WAYPOINT = {
    waypointAddress:'',
    waypointLat:'0',
    waypointLng:'0'
}
const LIMIT_STOPOVER_COUNT = 3;
class LocationSelector extends Component {
   
    componentDidMount() {
        console.log('locationSelector')
        console.log(this.props.history.location.pathname)
    }
    
    handleAddressStartPoint = () => {
        this.props.history.push(`/address_search?redirect=${this.props.history.location.pathname}&state=${ADDRESS_SEARCH_STATE_RESERVE_START}`)
    }
    handleAddressWayPoint = (idx) => {
        this.props.history.push(`/address_search?redirect=${this.props.history.location.pathname}&state=${ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_UPDATE}${idx}`)
    }
    handleAddressEndPoint = () => {
        this.props.history.push(`/address_search?redirect=${this.props.history.location.pathname}&state=${ADDRESS_SEARCH_STATE_RESERVE_END}`)
    }
    //

    handleMapStartPoint = () => {
        this.props.history.push(`/map_select?redirect=${this.props.history.location.pathname}&state=${ADDRESS_SEARCH_STATE_RESERVE_START}`)
    }
    handleMapWayPoint = (idx) => {
        this.props.history.push(`/map_select?redirect=${this.props.history.location.pathname}&state=${ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_UPDATE}${idx}`)
    }
    handleMapEndPoint = () => {
        this.props.history.push(`/map_select?redirect=${this.props.history.location.pathname}&state=${ADDRESS_SEARCH_STATE_RESERVE_END}`)
    }
 
    render() {
        let stopOverComponent = (arr) => {
            return arr.map((data, idx) => {
                return (
                    <span className="list_t1" key={idx}>
                	<table>
                    	<tr>
                        	<td className="list_sec1">
                                <img src={icon_delete} className="icon_LT1" alt="" id={idx} onClick={()=>{
                                this.props.onClickDel(idx);
                            }}/>
                            </td>
                            <td className="list_sec2"><img src={icon_point_waypoint} className="icon_LT2" alt=""/></td>
                            <td className="list_sec3" onClick={()=>{ this.handleAddressWayPoint(idx) }}>
                                {this.props.stopOverList[idx].waypointAddress ? this.props.stopOverList[idx].waypointAddress : '경유지를 입력하세요.'}
                            </td>
                            <td className="list_sec4">
                                <button className="btn_map" onClick={()=>{ this.handleMapWayPoint(idx) }}>지도</button>
                            </td>
                        </tr>
                    </table>
                </span>

                    // <CountryInfo key={idx} country={data.country} code={data.code} path={this.props.match.params.flag} detail={this.state.detail}/>
                )
            });
        }

        return (
            <span className="section_box_deatil" style={{ margin: '-50px 0 0 0' }}>
                {/* 컴포넌트화할 녀석들 begin */}
                <span className="section_title_booking">
                    <span className="bline">출발지 <span className="line_bar">|</span> {this.props.endPointStr}</span>
                    <span className="right">
                    <button className="btn change" onClick={this.props.onChangeSwitchingStartEnd}><img src={icon_switching} alt="" style={{
                        width:'10px'
                    }} />&nbsp;출발지/{this.props.endPointStr} 변환</button>
                    </span>
                </span>








                <span className="list_t1">
                    <table>
                        <tr>
                            <td className="list_sec1"></td>
                            <td className="list_sec2"><img src={icon_point} className="icon_LT2" alt="" /></td>

                            {(this.props.airStartEndState === true) ? (
                                <>

                                    <td className="list_sec3">
                                        <select style={{ display: 'inline-block', width: '100%', height: '20px', border: '0px', background: 'white', paddingLeft: '0px' }} value={this.props.AirOptionState} onChange={this.props.onChangeStartAirport}>
                                            <option value="0">출발 공항 선택</option>
                                            <option value="1">김포공항 국내선 청사</option>
                                            <option value="2">김포공항 국제선 청사</option>
                                            <option value="3">인천공항 1터미널</option>
                                            <option value="4">인천공항 2터미널</option>
                                        </select>
                                    </td>

                                    <td className="list_sec4">

                                    </td>

                                </>
                            ) : (
                                    <>
                                        <td className="list_sec3" onClick={this.handleAddressStartPoint}>
                                            {this.props.startLocationAddress ? `${this.props.startLocationAddress}` : '출발지를 입력하세요'}
                                        </td>

                                        <td className="list_sec4">
                                            <button className="btn_map" onClick={this.handleMapStartPoint}>지도</button>
                                        </td>
                                    </>
                                )}
                        </tr>
                    </table>
                </span>



{/* 
                <span className="list_t1">
                    <span className="list_sec1">
                    </span>
                    <span className="list_sec2">
                        <img src={icon_point} className="icon_LT2" alt="" />
                    </span>

                    {(this.props.airStartEndState === true) ? (
                        <>
                            <span className="list_sec3">
                                
                                <select style={{ width: '100%', height: '20px', border: '0px', background: 'white', paddingLeft:'0px' }} value={this.props.AirOptionState} onChange={this.props.onChangeStartAirport}>
                                    <option value="0">출발 공항 선택</option>
                                    <option value="1">김포공항 국내선 청사</option>
                                    <option value="2">김포공항 국제선 청사</option>
                                    <option value="3">인천공항 1터미널</option>
                                    <option value="4">인천공항 2터미널</option>
                                </select>
                            </span>

                            <span className="list_sec4">
                                
                            </span>
                        </>
                    ) : (
                        <>
                            <span className="list_sec3" style={{paddingTop:'2.5px'}} onClick={this.handleAddressStartPoint}>
                                {this.props.startLocationAddress ? `${this.props.startLocationAddress}` : '출발지를 입력하세요'}
                            </span>

                            <span className="list_sec4">
                                <button className="btn_map" onClick={this.handleMapStartPoint}>지도</button>
                            </span>
                        </>
                    )}
                </span> */}

                {stopOverComponent(this.props.stopOverList)}
                {/* <span className="list_t1">
                <span className="list_sec1">
                    <img src={icon_delete} className="icon_LT1" alt="" />
                </span>
                <span className="list_sec2">
                    <img src={icon_point} className="icon_LT2" alt="" />
                </span>
                <span className="list_sec3">신사역 8번 출구</span>
                <span className="list_sec4">
                    <button className="btn_map">지도</button>
                </span>
            </span> */}





                <span className="list_t1">
                    <table>
                        <tr>
                            <td className="list_sec1"></td>
                            <td className="list_sec2"><img src={icon_location} className="icon_LT3" alt="" /></td>

                            {(this.props.airStartEndState === true) ? (
                                <>
                                        <td className="list_sec3" onClick={this.handleAddressEndPoint}>
                                        {this.props.endLocationAddress.length !== 0 ? this.props.endLocationAddress : `${this.props.endPointStr}를 입력하세요`}
                                        </td>

                                        <td className="list_sec4">
                                            <button className="btn_map" onClick={this.handleMapEndPoint}>지도</button>
                                        </td>
                                    </>

                            ) : (
                                    <>
                                        <td className="list_sec3">
                                            <select style={{ display: 'inline-block', width: '100%', height: '20px', border: '0px', background: 'white', paddingLeft: '0px' }} value={this.props.AirOptionState} onChange={this.props.onChangeEndAirport}>
                                                <option value="0">도착 공항 선택</option>
                                                <option value="1">김포공항 국내선 청사</option>
                                                <option value="2">김포공항 국제선 청사</option>
                                                <option value="3">인천공항 1터미널</option>
                                                <option value="4">인천공항 2터미널</option>
                                            </select>
                                        </td>

                                        <td className="list_sec4">
                                            
                                        </td>
                                    </>
                                )}
                        </tr>
                    </table>
                </span>


                <button className="btn_lo_add" onClick={async ()=>{
                    if (this.props.stopOverList.length >= LIMIT_STOPOVER_COUNT) {
                        await calertPromise('최대 3개까지 추가하실 수 있습니다.')
                    } else {
                        this.props.onClickAdd(INIT_WAYPOINT);
                    }

                    
                }}><b><img src={icon_plus} alt="" /></b> 경유지 추가</button>
                 {/* onClick={this.handleAddStopover} */}
                {/* 컴포넌트화할 녀석들 End */}
            </span>

        );
    }
};

export default LocationSelector;
