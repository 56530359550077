import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

import axios from 'axios';
import Util, {calert, calertPromise} from '../../lib/Util'
import { MobileAuthLimitTime } from '../../constantData'
import RnInterface from '../../lib/RnInterface'
import Loading from '../../components/Common/Loading'
import HeaderJoin2 from '../../components/Common/HeaderJoin2'
import MemberJoinCustomCss from '../../css/CustomGlobalStyle/MemberJoinCustom'


import '../../css/default.css';
import '../../css/member.css';

//view_~~~ 로 시작하는 파일은 html inline에 style로 정해놓은 것을 css화 시킨 것이다.
// import '../css/view_login.css';

import btn_prev from '../../img/btn/btn_prev.svg';


class MemberJoin3View extends Component {
    state = {
        name: '',                   // 이용자 이름
        phoneNum: '',               // 휴대폰 번호
        authNum:'',                 // 이용자가 입력한 인증번호
        responseAuthNum:'',         // sms 응답 받은 인증번호
        authCheckFlag: 1,           // 1: 비활성화  0: 활성화
        authChkResult: 0,           // 인증 성공 여부 0 : 실패, 1 : 성공
        deviceId:'',
        osType:'',
        loading:0

    };
    
    /*
        * 사용할 API
          - http://apitest.client.mosiler.com/Help/Api/GET-SetVerifySms_phone 이걸써야하는지
          - http://api.mosiler.com/help/operations/SetVerifySms#response-json 이걸써야하는지..
    */

    // RN(React Native)의 DeviceInfo를 WebView측으로 받는 처리
    settingDeviceInfo = async () =>{
        try {
            let deviceData = await RnInterface.getData('deviceinfo')
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            let deviceInfoSplit = '';
    
            // false로 왔을때는 기본값 osType:A deviceid:1로 지정 (PC)
            // 차후에는 지워야할듯
            deviceData = (deviceData === false) ? 'A@@1' : deviceData;
    
            // deviceData가 false가 아니면 @@를 split한다.
            if (deviceData) {
                deviceInfoSplit = deviceData.split('@@')
            }
            //세팅
            this.setState({
                deviceId: deviceInfoSplit[1],
                osType: deviceInfoSplit[0],
                loading:1
            })
        }
        catch(e){
            console.log(e)
        }
        
        
    }

    componentDidMount() {
        window.timer = null;

        let tmpName = localStorage.getItem('name');
        let tmpPhoneNum = localStorage.getItem('phoneNum');
        let tmpAuthChkResult = localStorage.getItem('authChkResult');
        this.settingDeviceInfo();
        if (tmpName !== null && 
            tmpPhoneNum !== null && 
            tmpAuthChkResult !== null
            ) 
            this.setState({
                name: tmpName,
                phoneNum: tmpPhoneNum,
                authChkResult: tmpAuthChkResult
            })
    }

    componentWillUnmount() {
        clearInterval(window.timer);
    }
    

    /* Promise 정의 */
    // 내가 스크랩한 Business card의 List 출력 처리 Begin
    promissChkVerifySms = () => {
        return new Promise((resolve, reject)=>{
            axios({
                url : `/SetVerifySms?phone=${this.state.phoneNum}`,
                method: 'GET',
                timeout: 5000,
                headers: {
                    'Content-Type':'application/json',
                    'OsType': this.state.osType,
                    'DeviceId': this.state.deviceId,
                    'Token': '4D006D0B3FF2019759A6FD719AE8B291E2B2A4935F0CC42D72534C494C95AAFD'
                }
            }).then(res => {
                resolve(res.data);
            }).catch( (err) => {
                return reject(err);
            });
        });
    }

    /*
    
    http://apitest.client.mosiler.com/TestVerifySms?phone=01087223834 response
    성공시
    {
        "Result": "success",
        "Message": "23488",
        "TokenStatus": 1,
        "ResultCode": "{\"result_code\":\"1\",\"message\":\"success\",\"msg_id\":\"163202715\",\"success_cnt\":1,\"error_cnt\":0,\"msg_type\":\"SMS\"}"
    }
    실패시
    {
        "Result": "fail",
        "Message": "",
        "TokenStatus": 1,
        "ResultCode": null
    }
    */
    // 이전 버튼, 상단 뒤로가기 버튼
    handlePrevBtn = () => {
        this.props.history.goBack();
    }
    
    // 다음
    handleNextBtn = async () => {
        if (!this.state.name.length) {
            await  calertPromise('이름을 입력해주세요.')
            return;
        }
        // 인증 최종 성공시
        if (this.state.authChkResult) {
            window.timer = undefined;

            localStorage.setItem('name', this.state.name)
            localStorage.setItem('phoneNum', this.state.phoneNum)
            localStorage.setItem('authChkResult', this.state.authChkResult)

            let tmpAccountType = (localStorage.getItem('AccountType') !== null) ? localStorage.getItem('AccountType') : '';
            // Email가입자는 ID, Password 확인해야함
            if (tmpAccountType === 'email') {
                this.props.history.push('/join/4');
            } else {
                this.props.history.push('/join/4_sns');
            }
            
        } else {
            await calertPromise('본인 인증이 완료 되지 않았습니다. 인증을 진행해주세요. ')
        }
        
    }

    limitTimer = () => {
        let time = MobileAuthLimitTime;
        let min = '';
        let sec = '';
        window.timer = setInterval(()=>{
            min = parseInt(time / 60);
            sec = time % 60;
            document.querySelector('.text-darkblue').innerHTML = `${Util.getNumber2Ciphers(min)}:${Util.getNumber2Ciphers(sec)}`;
            time --;
    
            if (time < 0) {
                this.setState({
                    responseAuthNum: '',
                    authCheckFlag:1
                })
                clearInterval(window.timer)
                document.querySelector('.is-successed').style.display="none"
                document.querySelector('.is-successed').innerHTML = '';
                document.querySelector('.is-errored').style.display=""
                document.querySelector('.is-errored').innerHTML = '인증시간이 초과되었습니다.';
                document.querySelector('.text-darkblue').innerHTML = '';
            }
        }, 1000);
    }

    /*
    성공시
    인증에 성공하였습니다.

    실패시 
    인증시간이 초과되었습니다.

    
    */

    // 인증 요청, 인증 재요청
    handleRequestVerifySMS = async () => {
        try {
            // 검수용
            if (this.state.phoneNum === '0333444555' || this.state.phoneNum === '123456') {
                
                localStorage.setItem('name', 'ios quiet reflection')
                localStorage.setItem('phoneNum', this.state.phoneNum)
                localStorage.setItem('authChkResult', this.state.authChkResult)

                let tmpAccountType = (localStorage.getItem('AccountType') !== null) ? localStorage.getItem('AccountType') : '';
                // Email가입자는 ID, Password 확인해야함
                if (tmpAccountType === 'email') {
                    this.props.history.push('/join/4');
                } else {
                    this.props.history.push('/join/4_sns');
                }
            } 
            else {
                // 휴대폰 입력되어있는지 체크
                // 입력되어 있으면 API 호출
                // 입력 안되어 있으면 alert 표시
                if (this.state.phoneNum.length >= 10) {
                    this.setState({ loading: 0 })
                    let resultData = await this.promissChkVerifySms();

                    if (resultData.Result !== 'success') {
                        await calertPromise(resultData.Message);
                        this.setState({ loading: 1 })
                    } else {
                        this.limitTimer();

                        this.setState({
                            responseAuthNum: resultData.Message,
                            authCheckFlag: 0,
                            loading: 1
                        })
                        await calertPromise('문자 메시지를 전송하였습니다.\n인증번호를 입력해주세요.');


                        // 이 시점에 timer 처리가 필요하다.
                        // 타이머 2분 초과되면 responseAuthNum
                        // 인증확인 버튼은 2분동안 활성화
                        // 2분 지나면 인증확인은 비활성화
                    }

                } else {
                    await calertPromise('휴대폰 번호를 입력해주세요.')
                }
            }
            
        } catch (e) {
            await calertPromise('인증요청 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
            this.setState({loading:1})
        }


    }

    // 인증 확인
    handleCheckAuthNum = () => {
        if (this.state.responseAuthNum === this.state.authNum) {
            this.setState({
                authChkResult: 1
            })
            document.querySelector('.is-successed').style.display=""
            document.querySelector('.is-successed').innerHTML = '인증이 완료되었습니다.';
            document.querySelector('.is-errored').style.display="none"
            document.querySelector('.is-errored').innerHTML = '';
            

            // await calertPromise('인증이 완료되었습니다.')
            clearInterval(window.timer);
        } else {
            document.querySelector('.is-successed').style.display="none"
            document.querySelector('.is-successed').innerHTML = '';
            document.querySelector('.is-errored').style.display=""
            document.querySelector('.is-errored').innerHTML = '인증에 실패하였습니다.';
            // await calertPromise(' 확인 부탁드립니다.')
        }
    }

    // 이름
    handleChangeName = (e) => {
        this.setState({
            name: e.target.value
        })
    }
    // 휴대폰 번호
    handleChangePhoneNum = (e) => {
        this.setState({
            phoneNum: e.target.value
        })
    }
    // 인증번호
    handleChangeAuthNum = (e) => {
        this.setState({
            authNum: e.target.value
        })
    }

    render() {
        return (
            <>
            {!this.state.loading ? (<Loading />) : ''}
            <MemberJoinCustomCss />
                <HeaderJoin2
                    onBackButton={this.handlePrevBtn}
                    title={''} />
                <main id="Contents"> 
                    <div className="key-visual-wrap">
                        <div className="key-visual-container">
                            <div className="key-contents">
                                <div className="key-text">
                                    <strong>휴대폰으로 본인인증을 진행합니다.</strong><br />개인정보 입력 후 인증번호를 요청하세요.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="component-wrap inner-container">
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">이름</label>									
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <input type="text" placeholder="최소 2글자 이상, 한글만 입력" className="input type1" onChange={this.handleChangeName} value={this.state.name}/>
                                    </div>
                                </div>	
                            </div>
                            <div className="form-info">
                                <span className="text-sub">실명으로 입력해 주세요</span>
                            </div>                                                  
                        </div>
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">휴대전화번호</label>									
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <span className="input-group has-append">
                                            <input type="tel" placeholder="- 없이 숫자만 입력" className="input type1" onChange={this.handleChangePhoneNum} value={this.state.phoneNum} disabled={!this.state.authCheckFlag}/>
                                            <span className="append">
                                                <button type="button" className={!this.state.authCheckFlag ? "btn small" : "btn small primary"} disabled={!this.state.authCheckFlag} onClick={this.handleRequestVerifySMS}><span>인증요청</span></button>
                                            </span>  
                                        </span>
                                    </div>
                                    <div className="form-controls flexible mar-t5">
                                        <span className="input-group has-append">
                                            <input type="tel" placeholder="인증번호 5자리 입력" className="input type1" onChange={this.handleChangeAuthNum} value={this.state.authNum}/>
                                            <span className="append">
                                                <button type="button" value="인증확인" className={this.state.authCheckFlag ? "btn small" : "btn small primary"} disabled={this.state.authCheckFlag} onClick={this.handleCheckAuthNum}><span>인증확인</span></button>
                                            </span> 
                                        </span>
                                    </div>
                                </div>	
                            </div>  
                            <div class="form-alert">
                                <div className="col">
                                    <p className="is-errored"></p>
                                    <p className="is-successed"></p>
                                </div>
                                {/* (<> <h1>남는시간</h1></>) */}
                                <div className="col right">
                                    {!this.state.authCheckFlag ? '남은시간 ' : ''}
                                    <strong className="text-darkblue"></strong>
                                </div>                                
                            </div>                                                                          
                        </div>                        
                    </div> 
                    <div className="component-wrap">
                        <div className="btn-area btn-group-fixed vertical">
                            <button type="button" className="btn large secondary" onClick={this.handleNextBtn}><span>다음</span></button>
                        </div>
                    </div>                     
                </main>    

            </>
        );
    }
};

export default MemberJoin3View;
