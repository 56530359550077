import axios from 'axios';
import { Token, Timeout } from '../constantData'
import Util from './Util';

const CouponApi = {};

CouponApi.CheckShareCode = (Code, ostype='', deviceid='') => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/CheckShareCode?parentCode=${Code}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
            },
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });

}


CouponApi.GetCouponByRedeemCode = (customerNo, redeemCode, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/GetCouponByRedeemCode?customerNo=${customerNo}&code=${redeemCode}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });

}

CouponApi.GetMyCoupons = (customerNo, ostype='', deviceid='', loginEncrypt, drivingtype) => {
    let requrl = `/GetMyCoupons?customerNo=${customerNo}`
    if (drivingtype !== undefined) {
        requrl +=  `&drivingType=${drivingtype}`
    }
    return new Promise((resolve, reject)=>{
        axios({
            url : requrl,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}

CouponApi.GetAvailaleCoupons = (customerNo, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/GetAvailaleCoupons?customerNo=${customerNo}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}

CouponApi.GetExpiredCoupons = (customerNo, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/GetExpiredCoupons?customerNo=${customerNo}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}


CouponApi.UseCouponEBridge = (customerNo, mallReserved, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/useCoupon.vm?member_id=${customerNo}&card_user_uid=${mallReserved}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });

}

CouponApi.CancelCouponEBridge = (CustomerNo, psCouponId, mallReserved, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/cancelCoupon.vm?member_id=${CustomerNo}&coupon_num=${psCouponId}&memo=coupon cancel by user&card_user_uid=${mallReserved}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}

CouponApi.SetPsCouponId = (paymentCardId, coupon_num, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/SetPsCouponId?PaymentCard_id=${paymentCardId}&PsCouponId=${coupon_num}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });

}




CouponApi.GetCouponTypeToStr = (DiscountRate, DiscountAmount) => {
    let tmpStr = '';
    // 할인 가격이 명시되어있을때
    // 할인율이 null이고, 할인가격이 null이 아니라면
    if (DiscountRate === null && DiscountAmount !== null) {
        tmpStr = `￦${Util.numberWithCommas(DiscountAmount+'')}`;
    } else {
        tmpStr = `${DiscountRate}%`;
    }
    return tmpStr;
}




export default CouponApi;