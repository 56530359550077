import React, { useEffect, useState } from 'react';
import { createGlobalStyle } from 'styled-components'
import { NavLink } from 'react-router-dom';
import Header from '../../components/Common/Header';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';
import DataLabels  from 'chartjs-plugin-datalabels'
import { Bar, Doughnut, Pie } from 'react-chartjs-2';
import Util, { calertPromise } from '../../lib/Util'
import RnInterface from '../../lib/RnInterface'
import MonthlyMosilerApi, {
    getTotalMinuteToHourMinuteStr, 
    getTotalMinuteToHourMinuteStrColon,
    getColonToKoreanStr, 
    getDrivingStatusToKoreanStr, 
    getExpenceTypeToStr, 
    getArraySumExpence,
    getSumReportAmount, 
    getCancelType,
    getArraySumReportAmount,
    getExpenceArrIdx,
    getConvertSimpleAddr,
    getSumExpence,
    getArraySum2,
} from '../../lib/MonthlyMosilerApi'
import {getNextDayStr} from '../../lib/TimeApi'
import store from '../../reduxStore'
import { useCookies } from 'react-cookie';
import moment from 'moment'
import Modal from 'react-modal'
import Loading from '../../components/Common/Loading'
//https://itwithruilan.tistory.com/77 chartjs custom
//https://yeon22.github.io/Chartjs-kr/docs/latest/configuration/legend.html

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    DataLabels
);

const barOptions = {
    
    indexAxis: 'y',
    animation : {
        easing: 'easeInOutQuart'
    },
    elements: {
        bar: {
            borderWidth: 1,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: 'top',
        },

    },
};

const labels = ['초과시간'];


const options = {
    animation : {
        easing: 'easeInOutQuart'
    },
    responsive: true,
    
    plugins: {
        legend: {
            position: 'top',
            
        },
    },
};


const pieOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            display:false,
        },
        
        datalabels: {
            color: ['#000','#000','#000','#000'],
            anchor: 'center',
            textAlign:'center',
            font: {
                weight:'bold',
                style:''
            },
            textStrokeColor:'white',
            textStrokeWidth:2,
            textShadowBlur:5,
            textShadowColor:'white',
            formatter: (val, context) => {
                let idx = context.dataIndex
                if (val === 0) {
                    return '';
                }
                return context.chart.data.labels[idx] + '\n' +  Math.floor(val)+'시간';
            }
        }
    }
}




const GlobalStyle = createGlobalStyle``;


/*
{
    "Result": "success",
    "Message": "",
    "Item": {
        "ContractTypeDay": 12,
        "ContractTypeHour": 8,
        "UseDayss": 16,
        "ReserveDays": 0,
        "RemainDays": 0,
        "ExceedDays": 4,
        "UseTimes": 9128,
        "ReserveTimes": 0,
        "RemainTimes": 0,
        "ExceedTimes": 3368
    },
    "TokenStatus": 1,
    "ItemList": [
        {
            "DrivingNo": 161723,
            "DriverNo": 1250,
            "DriverName": "윤상용",
            "DrivingStatus": "use",
            "StartDate": "2022-07-07T09:00:00",
            "ReserveHourMin": "10:00",
            "UseHourMin": "12:54",
            "DiffHourMin": "02:54",
            "ReserveMin": 600,
            "UseMin": 774,
            "DiffMin": 174,
            "StartAreaFee": 0,
            "AreaFee": 0,
            "NightFee": 0,
            "ExtraTimeFee": 85000,
            "SelectDriverFee": 0,
            "ReserveCancelFee": 0,
            "ParkingFee": 0,
            "TollgateFee": 0,
            "EtcFee": 0,
            "UseTime": 504,
            "ExceedAmount": 0,
            "RealUseTime": 504,
            "RealTimeDeductionAfterAmount": 0
        },
    ],
    "ResultCode": ""
}


*/

const ReportView = (props) => {
    const [cookies] = useCookies();
    
    const [barRawData, SetBarRawData] = useState({
        labels: ['초과시간'],
        datasets: [{
            data: [0],
            backgroundColor: 'rgba(255, 99, 32, 0.5)',
            borderColor: 'rgba(255, 99, 132, 1)',
        }],
    })
    const [DouchnutRawData, SetDouchnutRawData] = useState({
        labels: ['이용', '예약', '잔여'],
        datasets: [
            {
                label: '# of Votes',
                data: [0,0,0,0],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(38, 116, 224, 0.2)',
                    'rgba(36, 189, 54, 0.2)',
                    
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(38, 116, 224, 1)',
                    'rgba(36, 189, 54, 1)',
                ],
                borderWidth: 1,
            },
        ],
    })

    const [initFlag, SetInitFlag] = useState(false)
    const [reportDateIndex, SetReportDateIndex] = useState(0)
    const [ReportDateArr, SetReportDateArr] = useState([
        {StartDate:'2022-02-02',EndDate:'2022-02-02'}
    ])

    const [reportData2, setReportData2] = useState({
        ContractTypeDay: 15,
        ContractTypeHour: 8,

        UseDays: 10,
        ReserveDays: 0,
        RemainDays: 10,
        ExceedDays: 4,
        
        UseTimes: 9128,
        ReserveTimes: 0,
        RemainTimes: 0,
        ExceedTimes: 3368,
        OvercarriedMinute : 0,
        
        expence: [{
            StartDate: "2022-01-01T00:00:00.00", ItemCode: "4", DrivingNo: 0, UseAmount: 0, BillingYN: "Y",
        }],
        PersonalHistoryList: [{
            Id: 1, CustomerNo: 0, SettingDate: "2022-08-22T00:00:00", History: ""
        }]
    })
    
    const [reportData, setReportData] = useState({
       
        ItemList: [
            {
                BillingYN: "Y",
                
                CancelTime: 0,
                CancelType: -1,
                DiffHourMin: "02:54",
                DiffMin: 174,
                DriverName: "윤상용",
                DriverNo: 1250,
                DrivingNo: 161723,
                DrivingStatus: "use",
                DrivingStatusCode: "5",
                EndDate: "2022-07-07T21:54:00",
                ExceedAmount: 0,
                ExtraTimeFee: 85000,
                MinimumTimeApplyFlag: 0,
                NightFee: 0,
                
                RemainTime: 4986,
                ReserveCancelFee: 0,
                ReserveHourMin: "10:00",
                ReserveMin: 600,
                ReserveTime: 600,
                ResultUseTime: 774,
                RevEndDt: "2022-07-07T20:00:00",
                RevStartDt: "2022-07-07T10:00:00",
                SelectDriverFee: 0,
                StartAreaFee: 0,
                AreaFee: 0,
                StartDate: "2022-07-07T09:00:00",
                UseHourMin: "12:54",
                UseMin: 774,
                UseTime: 774,
                
                
                TollgateFee: 0,
                EtcFee: 0,
                ParkingFee: 0,
                RealTimeDeductionAfterAmount: 0,
                RealUseTime: 0,
                UseTimeDeductionAfterAmount: 0,
                CancelAmount: 0,
                ExceedTime:0,

                RevStartAddress:'',
                RevEndAddress:'',
                RevWaypointAddress:'',
                StartAddress:'',
                EndAddress:'',
                WaypointAddress:'',
                MmHourPriceHistory:0,
            },
        ],

    })

    const [viewModalData, setViewModalData] = useState({
        ViewData : {
            DrivingNo: 0,
            DriverNo: 0,
            DriverName: "",
            DrivingStatus: "use",
            StartDate: "2022-07-07T09:00:00",
            ReserveHourMin: "0",
            UseHourMin: "0",
            DiffHourMin: "0",
            ReserveMin: 0,
            UseMin: 0,
            DiffMin: 0,
            StartAreaFee: 0,
            AreaFee: 0,
            NightFee: 0,
            ExtraTimeFee: 0,
            SelectDriverFee: 0,
            ReserveCancelFee: 0,
            ParkingFee: 0,
            TollgateFee: 0,
            EtcFee: 0,
            UseTime: 0,
            ExceedAmount: 0,
            RealUseTime: 0,
            RealTimeDeductionAfterAmount: 0,
            CancelAmount:0,
            CancelType:0,
            CancelTime:0,
        },
    })

    const [viewModalFlag, setViewModalFlag] = useState(false)
    const [loadingFlag, setLoadingFlag] = useState(false)

    useEffect(() => {
        async function initProcess() {
            setLoadingFlag(true)
            await RnInterface.AndroidURLChange(props.location.pathname)
            //setMarginTop();
            const CustomerNo = store.getState().global_data.loginedData.CustomerNo
            // 계약건 데이터를 바탕으로 달별로 구분하는 처리를 실시
            let result = await MonthlyMosilerApi.GetMmReportContractDateList(
                CustomerNo,
                cookies.ostype,
                cookies.deviceid,
                cookies.login_encrypt
            )
            if (result.Result === 'success') {
                SetReportDateArr(result.ItemList)
                let seperateIndex = 0;

                // 날짜영역 데이터 기반으로 현 시간 기준 어느날짜에 해당되는지에 대한 index를 취득
                for (let i in result.ItemList) {
                    let now = moment();
                    let chkFrom = moment(result.ItemList[i].StartDate);
                    let chkTo = moment(result.ItemList[i].EndDate);
                    if ( (now.unix() >= chkFrom.unix()) && (now.unix() <= chkTo.unix())) {
                        SetReportDateIndex(i)
                        seperateIndex = i;
                        break;
                    }
                }

                // 상기 처리가 끝났고, index가 설정되었다면 해당 index로, 아니라면 0값으로 데이터를 취득한다.
                let result2 = await MonthlyMosilerApi.GetMmReportList(
                    CustomerNo, 
                    result.ItemList[seperateIndex].StartDate, 
                    result.ItemList[seperateIndex].EndDate,
                    cookies.ostype,
                    cookies.deviceid,
                    cookies.login_encrypt
                )  
                if (result2.Result === 'success') {
                    setReportData({ItemList: result2.ItemList})
                    setReportData2({
                        ContractTypeDay: result2.Item.ContractTypeDay,
                        ContractTypeHour: result2.Item.ContractTypeHour,
                        
                        UseDays: result2.Item.UseDays,
                        ReserveDays: result2.Item.ReserveDays,
                        RemainDays: result2.Item.RemainDays,
                        ExceedDays: result2.Item.ExceedDays,
                        
                        UseTimes: result2.Item.UseTimes,
                        ReserveTimes: result2.Item.ReserveTimes,
                        RemainTimes: result2.Item.RemainTimes,
                        ExceedTimes: result2.Item.ExceedTimes,
                        expence: result2.Item.expence,
                        PersonalHistoryList: result2.Item.PersonalHistoryList,
                        OvercarriedMinute : result2.Item.OvercarriedMinute,
                    })
                    updateChartRawData(result2.Item)
                    setLoadingFlag(false)
                }
                else {
                    await calertPromise(result2.Message)
                    setLoadingFlag(false)
                }
            }
            else {
                await calertPromise(result.Message)
                setLoadingFlag(false)
            }   
            setLoadingFlag(false)
            SetInitFlag(true)
        }
        initProcess();
    }, [])


    const getReportData = async (idx) => {
        try {
            setLoadingFlag(true)
            const CustomerNo = store.getState().global_data.loginedData.CustomerNo
            let result = await MonthlyMosilerApi.GetMmReportList(
                CustomerNo, 
                ReportDateArr[idx].StartDate, 
                ReportDateArr[idx].EndDate,
                cookies.ostype,
                cookies.deviceid,
                cookies.login_encrypt
            )  

            if (result.Result === 'success') {
                setReportData({ItemList: result.ItemList})
                setReportData2({
                    ContractTypeDay: result.Item.ContractTypeDay,
                    ContractTypeHour: result.Item.ContractTypeHour,

                    UseDays: result.Item.UseDays,
                    ReserveDays: result.Item.ReserveDays,
                    RemainDays: result.Item.RemainDays,
                    ExceedDays: result.Item.ExceedDays,
                    
                    UseTimes: result.Item.UseTimes,
                    ReserveTimes: result.Item.ReserveTimes,
                    RemainTimes: result.Item.RemainTimes,
                    ExceedTimes: result.Item.ExceedTimes,
                    expence: result.Item.expence,
                    PersonalHistoryList: result.Item.PersonalHistoryList,
                    OvercarriedMinute : result.Item.OvercarriedMinute,
                })
                updateChartRawData(result.Item)
                setLoadingFlag(false)
            }
            else {
                await calertPromise(result.Message)
                setLoadingFlag(false)
            }
        }
        catch(e){
            await calertPromise('리포트 취득도중 문제가 발생했습니다. 컨시어지로 문의 부탁드립니다.')
            setLoadingFlag(false)
        }
    }

    const handleChangeModalStatusTrueAddData = (reqData) => {
        setViewModalFlag(true)
        setViewModalData(reqData)
    }

    const handleChangeModalStatusTrue = () => {
        setViewModalFlag(true)
    }

    const handleChangeModalStatusFalse = () => {
        setViewModalFlag(false)
    }

    const updateChartRawData = (obj) => {
        const barData = {
            labels,
            datasets: [
                {
                    data: [ obj.ExceedTimes/60],
                    backgroundColor: 'rgba(255, 99, 32, 0.5)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    barThickness: 30,
                    maxBarThickness: 100,
                    
                }
            ],
        };

        // const DoughnutData = {
        //     labels: ['이용', '예약', '잔여'],
        //     datasets: [
        //         {
        //             label: '# of Votes',
        //             data: [ obj.UseTimes/60, obj.ReserveTimes/60, obj.RemainTimes/60],
        //             backgroundColor: [
        //                 'rgba(255, 99, 132, 0.2)',
        //                 'rgba(38, 116, 224, 0.2)',
        //                 'rgba(36, 189, 54, 0.2)',
                        
        //             ],
        //             borderColor: [
        //                 'rgba(255, 99, 132, 1)',
        //                 'rgba(38, 116, 224, 1)',
        //                 'rgba(36, 189, 54, 1)',
        //             ],
        //             borderWidth: 1,
        //         },
        //     ],
        // };

        const DoughnutData = {
            labels: ['잔여','이용', '예약',  '초과'],
            datasets: [
                {
                    label: '# of Votes',
                    data: [ obj.RemainTimes/60, obj.UseTimes/60, obj.ReserveTimes/60, obj.ExceedTimes/60],
                    backgroundColor: [
                        'rgba(36, 189, 54, 0.2)',
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(255, 99, 32, 0.35)',
                        'rgba(38, 116, 224, 0.2)',
                        
                    ],
                    borderColor: [
                        'rgba(36, 189, 54, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(38, 116, 224, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        };

        SetBarRawData(barData)
        SetDouchnutRawData(DoughnutData)
    }

    const setMarginTop = () => {
        let height = document.querySelector('.sub-header').clientHeight
        document.getElementsByClassName('inner-container2')[0].style.marginTop = height + 'px'
    }

    const handleBack = () => {
        props.history.goBack()
    }

    const handleHome = () => {
        props.history.replace('/main')
    }

    // 
    const subHeaderComponent = () => {
        return (
            <div className='sub-header'>
                
                <div className='item-btn'>
                    {reportDateIndex <= 0 ? 
                    (<button className='date-sel-btn-disable' disabled={true}>{'<'}</button>)
                    : (
                        <button className='date-sel-btn' onClick={()=>{
                            let tmp = reportDateIndex;
                            tmp--;
                            SetReportDateIndex(tmp)
                            getReportData(tmp)
                            
                        }}>{'<'}</button>
                    )}
                </div>
                
                <div className='item'>
                    {/* <span><button className='date-sel-btn'>2022년 05월</button></span> */}
                    {/* <span className='date-view'>{moment(ReportDateArr[reportDateIndex].StartDate).format('yyyy년 MM월')}</span> */}
                    <span className='date-view'>{`${ReportDateArr[reportDateIndex].StartDate} ~ ${ReportDateArr[reportDateIndex].EndDate}`}</span>
                    
                    {/* <span className='small'>(계약일: {reportData.ContractDate})</span> */}
                </div>
                <div className='item-btn' >
                    {reportDateIndex >= ReportDateArr.length-1 ? 
                    (<button className='date-sel-btn-disable' disabled={true}>{'>'}</button>)
                    : (
                        <button className='date-sel-btn' onClick={()=>{
                            let tmp = reportDateIndex;
                            tmp++;
                            SetReportDateIndex(tmp)
                            getReportData(tmp)
                        }}>{'>'}</button>
                        
                    )}
                </div>
            </div>
        )
    }

    // 실비 20% 비용 추가
    const Commission20 = (amount) => {
        return amount * 0.2
    }

    const exceedFlagToStr = (data, drivingstatus) => {
        let tmpStr = '';
        if (drivingstatus === 'use' || drivingstatus === 'reserve') {
            
            // 추가비용 + 출발지할증 + 지역할증 + 심야할증
            let sum = getSumReportAmount(data);     

            // 실비
            let tmpArr = getExpenceArrIdx(reportData2.expence, data.DrivingNo)
            let com20 = 0;
            if (tmpArr.length >= 1) {
                for (let i in tmpArr) {
                    sum +=  Number(reportData2.expence[tmpArr[i]].UseAmount)
                    com20 += Number(reportData2.expence[tmpArr[i]].UseAmount)
                }
            }

            /* 실비 합산처리. (청구비용에 있기 때문에 하지 않는다.)
             */
            // console.log(`exceedFlagToStr DrivingNo: ${data.DrivingNo}, 2차 sum: ${sum}, tmpArr = ${tmpArr}`)

            if (data.BillingYN === 'Y')
                // 실비 20% 비용 합산
                tmpStr = Util.numberWithCommas(sum + Commission20(com20)) 
            else 
                tmpStr = '집계중'

                /*
            if (getSumReportAmount(data) <= 0){
                tmpStr = '-'
            }
            else {
                

            }*/
        }
        else if(drivingstatus === 'cancel') {
            if (data.ExceedAmount >= 1) {
                tmpStr = Util.numberWithCommas(Number(data.ExceedAmount))
            }
            else {
                tmpStr = '-'
            }
        }
        
        return tmpStr;
    }

    const serviceFeeSum = (data, flag) => {
        let retData = 0;
        switch(flag) {
            case 'parking':
                for(let i in data) retData += data[i].ParkingFee
                break;
            case 'toll':
                for(let i in data) retData += data[i].TollgateFee
                break;
            case 'etc':
                for(let i in data) retData += data[i].EtcFee
                break;
            case 'all':
                for(let i in data){
                    retData += data[i].ParkingFee
                    retData += data[i].TollgateFee
                    retData += data[i].EtcFee
                } 
                break;
        }
        
        return Util.numberWithCommas(retData);
    }

    const detailDriving = async (data) => {
        handleChangeModalStatusTrueAddData(data)
    }

    const getViewCaseDate = (DrivingStatus, AllData) => {
        let retData = '';
        switch(DrivingStatus) {
            case 'reserve':
            case 'cancel':
                retData = moment(AllData.RevStartDt).format('MM/DD')
                break;
            case 'use':
                retData = moment(AllData.StartDate).format('MM/DD')                
                break;
            default:
                retData = moment(AllData.RevStartDt).format('MM/DD')
                break;
        }
        return retData;
    }
    
    const usageDetailComponentNew = (arr) => {
        if (!arr.length) {
            return(
                <tr>
                    <td style={fontStyle} colSpan={6}><b>데이터가 없습니다.</b></td>
                </tr>
            )
        }
        return arr.map((data,idx)=>{
            return (
                <tr>
                    <td style={fontStyle}>{getViewCaseDate(data.DrivingStatus, data)}</td>
                    <td style={fontStyle}>{getDrivingStatusToKoreanStr(data.DrivingStatus)}</td>
                    {/* <td style={fontStyle}>박창식</td> */}
                    {/* <td style={fontStyle}>{getColonToKoreanStr(data.ReserveHourMin)} / {getColonToKoreanStr(data.UseHourMin)}</td> */}
                    {/* <td style={fontStyle}>{getColonToKoreanStr(data.ReserveHourMin)}</td> */}
                    <td style={fontStyle}>{getTotalMinuteToHourMinuteStr(data.ResultUseTime)}</td>
                    <td style={fontStyle}>{exceedFlagToStr(data, data.DrivingStatus)}</td>
                    {/* <td style={fontStyle}>{data.DiffHourMin}</td> */}
                    {/* <td style={fontStyle}>{Util.numberWithCommas(data.StartAreaFee + data.AreaFee + data.NightFee)}</td> */}
                    {/* <td style={fontStyle}>{Util.numberWithCommas(data.CancelAmount)}</td> */}
                    {/* <td style={fontStyle}>{exceedFlagToStr(data)}</td> */}
                    <td style={fontStyle}><button onClick={()=>detailDriving(data)}>상세보기</button></td>
                    {/* 없음, 시간차감, 10000 */}
                </tr>
            )
        })
    }
    

//getExpenceTypeToStr
    const serviceDetailComponent = (arr) => {
        if (!arr.length) {
            return(
                <tr>
                    <td style={fontStyle} colSpan={3}><b>데이터가 없습니다.</b></td>
                </tr>
            )
        }
        return arr.map((data,idx)=>{
            return (
                <>
                    <tr>
                        <td style={fontStyle}>{moment(data.StartDate).format('MM/DD')}</td>                    
                        {/* <td style={fontStyle}>{Util.numberWithCommas(data.ParkingFee)}</td> */}
                        <td style={fontStyle}>{getExpenceTypeToStr(data.ItemCode)}</td>
                        <td style={fontStyle}>{Util.numberWithCommas(data.UseAmount)}</td>
                        {/* <td style={fontStyle}>{Util.numberWithCommas(data.EtcFee)}</td> */}
                        {/* <td style={fontStyle}>{Util.numberWithCommas(data.ParkingFee + data.TollgateFee + data.EtcFee)}</td> */}
                    </tr>
                </>
            )
        })
    }

    const serviceDetailComponent2 = (arr) => {
        if (!arr.length) {
            return(
                <tr>
                    <td style={fontStyle} colSpan={3}><b>데이터가 없습니다.</b></td>
                </tr>
            )
        }
        return arr.map((data,idx)=>{
            return (
                <>
                    <tr>
                        <td style={fontStyle}>{moment(data.date).format('MM/DD')}</td>                    
                        <td style={fontStyle}>{data.name}</td>
                        <td style={fontStyle}>{data.subdata}</td>
                        <td style={fontStyle}>{Util.numberWithCommas(data.amount)}</td>
                        {/* <td style={fontStyle}>{Util.numberWithCommas(data.EtcFee)}</td> */}
                        {/* <td style={fontStyle}>{Util.numberWithCommas(data.ParkingFee + data.TollgateFee + data.EtcFee)}</td> */}
                    </tr>
                </>
            )
        })
    }

    const personalHistoryComponent = (arr) => {
        if (!arr.length) {
            return(
                <tr>
                    <td style={fontStyle} colSpan={2}><b>데이터가 없습니다.</b></td>
                </tr>
            )
        }
        return arr.map((data,idx)=>{
            return (
                <>
                    <tr>
                        <td style={fontStyle}>{moment(data.SettingDate).format('MM/DD')}</td>                    
                        <td style={fontStyle}>{data.History}</td>
                    </tr>
                </>
            )
        })
    }

    const tmpHeaderComp = (title) => {
        return  (
            <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px', borderBottom:'1px solid #d8d8d8'}}>
                <div className="col"><h1 style={{fontWeight:'bold', fontSize:'1.4em'}}>{title}</h1></div>
            </div>
        )
    }   
    const tmpBlankComp = () => {
        return  (
            <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                <div className="col left"><span></span></div>
                <div className="col right"><span></span></div>
            </div>
        )
    }   

    const paymentFeeProcessArr = () => {
        let PaymentArr = [];
        // 실비
        for (let i in reportData2.expence) {
            if (reportData2.expence[i].BillingYN === 'Y') {
                PaymentArr.push({
                    date: reportData2.expence[i].StartDate,
                    name: getExpenceTypeToStr(reportData2.expence[i].ItemCode),
                    subdata: Util.numberWithCommas(reportData2.expence[i].UseAmount + ''),
                    amount: reportData2.expence[i].UseAmount + Commission20(reportData2.expence[i].UseAmount)
                })
            }
        }
        
        // 추가이용
        for (let i in reportData.ItemList) {
            if (reportData.ItemList[i].ExceedAmount >= 1 && reportData.ItemList[i].BillingYN === 'Y') {
                PaymentArr.push({
                    date: reportData.ItemList[i].StartDate,
                    name: '추가이용',
                    subdata: getTotalMinuteToHourMinuteStr(reportData.ItemList[i].ExceedTime),
                    amount: reportData.ItemList[i].ExceedAmount
                })
            }
        }

        // 취소
        for (let i in reportData.ItemList) {
            if (reportData.ItemList[i].CancelAmount >= 1) {
                PaymentArr.push({
                    date: reportData.ItemList[i].RevStartDt,
                    name: '취소',
                    subdata: getCancelType(reportData.ItemList[i].CancelType),
                    amount: reportData.ItemList[i].CancelAmount
                })
            }
        }

        // 할증
        for (let i in reportData.ItemList) {
            if (reportData.ItemList[i].StartAreaFee >= 1 && reportData.ItemList[i].BillingYN === 'Y') {
                PaymentArr.push({
                    date: reportData.ItemList[i].RevStartDt,
                    name: '출발지할증',
                    subdata: '경기,인천출발',
                    amount: reportData.ItemList[i].StartAreaFee
                })
            }
            if (reportData.ItemList[i].AreaFee >= 1 && reportData.ItemList[i].BillingYN === 'Y') {
                PaymentArr.push({
                    date: reportData.ItemList[i].RevStartDt,
                    name: '지역할증',
                    subdata: '서울외도착',
                    amount: reportData.ItemList[i].AreaFee
                })
            }
            if (reportData.ItemList[i].NightFee >= 1 && reportData.ItemList[i].BillingYN === 'Y') {
                PaymentArr.push({
                    date: reportData.ItemList[i].RevStartDt,
                    name: '심야할증',
                    subdata: '23PM~06AM',
                    amount: reportData.ItemList[i].NightFee
                })
            }
        }

        PaymentArr = PaymentArr.sort((a,b)=> (moment(a.date) > moment(b.date) ? 1 : -1));
        return PaymentArr;

    }

    const driverNodeComponent = (data) => {
        const styleobj= {fontWeight:'bold', fontSize:'1.4em'}
        return (
            <>
                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                    <div className="col left"><span>상태</span></div>
                    {data.DrivingStatus === 'use' ? (<div className="col right"><span>이용완료</span></div>):null}
                    {data.DrivingStatus === 'reserve' ? (<div className="col right"><span>예약</span></div>):null}
                    {data.DrivingStatus === 'cancel' ? (<div className="col right"><span>취소</span></div>):null}
                </div>

                {tmpHeaderComp('이용')}
                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                    <div className="col left"><span>일자</span></div>
                    {/* <div className="col right"><span>{moment(data.RevStartDt).format('MM/DD')} {moment(data.RevStartDt).format('HH:mm')}~{moment(data.RevEndDt).format('HH:mm')} ({data.ReserveHourMin})</span></div> */}
                    <div className="col right"><span>{moment(data.RevStartDt).format('yyyy-MM-DD')}</span></div>
                </div>
                
                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                    <div className="col left"><span>예약시간</span></div>
                    <div className="col right"><span>{moment(data.RevStartDt).format('HH:mm')}~{getNextDayStr(moment(data.RevStartDt).format('yyyyMMDD'), moment(data.RevEndDt).format('yyyyMMDD'))}{moment(data.RevEndDt).format('HH:mm')} ({getColonToKoreanStr(data.ReserveHourMin)})</span></div>
                </div>

                {data.DrivingStatus === 'use' ? (
                    <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                        <div className="col left"><span>이용시간</span></div>
                        <div className="col right"><span>{moment(data.StartDate).format('HH:mm')}~{getNextDayStr(moment(data.StartDate).format('yyyyMMDD'), moment(data.EndDate).format('yyyyMMDD'))}{moment(data.EndDate).format('HH:mm')} ({getColonToKoreanStr(data.UseHourMin)})</span></div>
                    </div>
                ) : null}

                {tmpBlankComp()}
                
                {/* 주소 */}
                {data.DrivingStatus === 'use' ? (
                    <>
                        <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                            <div className="col left"><span>출발지</span></div>
                            <div className="col right"><span>{getConvertSimpleAddr(data.StartAddress)}</span></div>
                        </div>
                        {data.WaypointAddress !== '' ? (
                            <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                                <div className="col left"><span>경유지</span></div>
                                <div className="col right"><span>{getConvertSimpleAddr(data.WaypointAddress)}</span></div>
                            </div>
                        ) : null}
                        
                        <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                            <div className="col left"><span>종료지</span></div>
                            <div className="col right"><span>{getConvertSimpleAddr(data.EndAddress)}</span></div>
                        </div>

                        
                    </>
                ) : (
                    <>
                        <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                            <div className="col left"><span>예약출발지</span></div>
                            <div className="col right"><span>{getConvertSimpleAddr(data.RevStartAddress)}</span></div>
                        </div>
                        {data.RevWaypointAddress !== '' ? (
                            <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                                <div className="col left"><span>예약경유지</span></div>
                                <div className="col right"><span>{getConvertSimpleAddr(data.RevWaypointAddress)}</span></div>
                            </div>
                        ) : null}
                        <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                            <div className="col left"><span>예약종료지</span></div>
                            <div className="col right"><span>{getConvertSimpleAddr(data.RevEndAddress)}</span></div>
                        </div>
                    </>
                )}

                {tmpBlankComp()}

                {data.DrivingStatus !== 'cancel' ? (
                    <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                        <div className="col left"><span>반영시간</span></div>
                        <div className="col right"><span>{getTotalMinuteToHourMinuteStr(data.ResultUseTime)} 차감</span></div>
                    </div>
                ) : (
                    <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                        <div className="col left"><span>반영시간</span></div>
                        <div className="col right"><span>{getTotalMinuteToHourMinuteStr(data.CancelTime)} 차감</span></div>
                    </div>
                )}
                

                {data.DrivingStatus !== 'cancel' ? (
                    <>
                        {/* 초과비용 발생시 */}
                        {data.BillingYN === 'Y' ? (<>
                            {((data.ExceedAmount >= 1) || (getSumExpence(reportData2.expence, data.DrivingNo) >= 1) || (data.StartAreaFee >= 1 || data.AreaFee >= 1 || data.NightFee >= 1)) ? (
                                <>
                                    {tmpHeaderComp('청구비용')}
                                    {(data.ExceedAmount >= 1) ? (
                                        <>
                                            <div className="grid cross-center" style={{ alignItems: 'center', padding: '10px 0px' }}>
                                                <div className="col left"><span>추가이용시간</span></div>
                                                <div className="col right"><span>{getTotalMinuteToHourMinuteStr(data.ExceedTime)}</span></div>
                                            </div>
                                            <div className="grid cross-center" style={{ alignItems: 'center', padding: '10px 0px' }}>
                                                <div className="col left"><span>시간당단가</span></div>
                                                <div className="col right"><span>{Util.numberWithCommas(data.MmHourPriceHistory + '')}원</span></div>
                                            </div>

                                            {tmpBlankComp()}

                                            <div className="grid cross-center" style={{ alignItems: 'center', padding: '10px 0px' }}>
                                                <div className="col left"><span>추가이용</span></div>
                                                <div className="col right"><span>{Util.numberWithCommas(data.ExceedAmount + '')}원</span></div>
                                            </div>
                                        </>
                                    ) : null}

                                    {/* 할증 */}
                                    {/* {data.StartAreaFee !== 0 ? (
                                        <div className="grid cross-center" style={{ alignItems: 'center', padding: '10px 0px' }}>
                                            <div className="col left"><span>출발지 할증</span></div>
                                            <div className="col right"><span>{Util.numberWithCommas(data.StartAreaFee + '')}</span></div>
                                        </div>
                                    ) : null} */}

                                    {data.StartAreaFee !== 0 || data.AreaFee !== 0 ? (
                                        <div className="grid cross-center" style={{ alignItems: 'center', padding: '10px 0px' }}>
                                            <div className="col left"><span>지역할증</span></div>
                                            <div className="col right"><span>{Util.numberWithCommas((data.StartAreaFee + data.AreaFee) + '')}</span></div>
                                        </div>
                                    ) : null}

                                    {data.NightFee !== 0 ? (
                                        <div className="grid cross-center" style={{ alignItems: 'center', padding: '10px 0px' }}>
                                            <div className="col left"><span>심야할증</span></div>
                                            <div className="col right"><span>{Util.numberWithCommas(data.NightFee + '')}</span></div>
                                        </div>
                                    ) : null}

                                    

                                    {/* 결제대행 (실비) */}
                                    {(getSumExpence(reportData2.expence, data.DrivingNo) >= 1) ? (
                                        <>
                                            <div className="grid cross-center" style={{ alignItems: 'center', padding: '10px 0px' }}>
                                                <div className="col left"><span>결제대행</span></div>
                                                <div className="col right"><span>{Util.numberWithCommas(getSumExpence(reportData2.expence, data.DrivingNo) + Commission20(getSumExpence(reportData2.expence, data.DrivingNo)) + '')}원</span></div>
                                            </div>
                                        </>
                                    ) : null}
                                </>
                            ) : null}

                            {getExpenceArrIdx(reportData2.expence, data.DrivingNo).length >= 1 ? (
                                <>
                                    {tmpHeaderComp('결제대행내역')}
                                    {getExpenceArrIdx(reportData2.expence, data.DrivingNo).map((tmpdata) => {
                                        return (<>
                                            <div className="grid cross-center" style={{ alignItems: 'center', padding: '10px 0px' }}>
                                                <div className="col left"><span>{getExpenceTypeToStr(reportData2.expence[tmpdata].ItemCode)}</span></div>
                                                <div className="col right"><span>{Util.numberWithCommas(reportData2.expence[tmpdata].UseAmount)}원</span></div>
                                            </div>
                                        </>)
                                    })}
                                    <div className="grid cross-center" style={{ alignItems: 'center', padding: '10px 0px' }}>
                                        <div className="col left"><span>대행수수료</span></div>
                                        <div className="col right"><span>{Util.numberWithCommas(Commission20(getSumExpence(reportData2.expence, data.DrivingNo))) + ''}원</span></div>
                                    </div>

                                </>) : null}
                        </>) : (<>
                            {tmpHeaderComp('청구비용')}
                            <div className="grid cross-center" style={{ alignItems: 'center', padding: '10px 0px' }}>
                                <div className="col left"><span>{'집계중'}</span></div>

                            </div>


                        </>)}
                    </>
                ) : null}




                {/*                 
                {data.DrivingStatus === 'use' ? (
                    <>
                        {tmpHeaderComp('이용')}
                        <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                            <div className="col left"><span>이용시간</span></div>
                            <div className="col right"><span>{moment(data.StartDate).format('MM/DD')} {moment(data.StartDate).format('HH:mm')}~{moment(data.EndDate).format('HH:mm')} ({data.UseHourMin})</span></div>
                        </div>
                        <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                            <div className="col left"><span>최소이용시간</span></div>
                            <div className="col right"><span>{data.MinimumTimeApplyFlag === 1 ? '적용' : '미적용'}</span></div>
                        </div>

                        {tmpHeaderComp('시간차감정보')}
                        <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                            <div className="col left"><span>시간차감여부</span></div>
                            <div className="col right"><span>{data.ExceedAmount === 0 ? '시간차감' : '초과'}</span></div>
                        </div>
                        
                        {data.BillingYN === 'Y' ? (
                            <>
                                {(data.ExceedAmount >= 1) ? (
                                    <>
                                        <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                                            <div className="col left"><span>초과금</span></div>
                                            <div className="col right"><span>{Util.numberWithCommas(data.ExceedAmount+'')}</span></div>
                                        </div>
                                    </>
                                ):null}
                            </>
                        ) : null}
                        
                    </>
                    
                ) : null}
                
                { ( (data.StartAreaFee !== 0) || (data.AreaFee !== 0) || (data.NightFee !== 0) ) ? (
                    <>
                        {tmpHeaderComp('할증')}
                    </>
                ) : null}

                 */}
                

                { (data.DrivingStatus === 'cancel') ? (<>
                    {tmpHeaderComp('취소 수수료')}
                    <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>                
                        <div className="col left"><span>취소수수료</span></div>
                        <div className="col right"><span>{(data.CancelTime >= 1 || data.CancelAmount >= 1) ? '발생' : '미발생'}</span></div>
                    </div>

                    {data.CancelTime >= 1 ? (
                        <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>                
                            <div className="col left"><span>취소차감시간</span></div>
                            <div className="col right"><span>{getTotalMinuteToHourMinuteStr(data.CancelTime)}</span></div>
                        </div>
                    ) : null}
                    {(data.CancelAmount >= 1) ? (
                        <div className="grid cross-center" style={{ alignItems: 'center', padding: '10px 0px' }}>
                            <div className="col left"><span>취소차감금액</span></div>
                            <div className="col right"><span>{Util.numberWithCommas(data.CancelAmount + '')}</span></div>
                        </div>
                    ) : null}

                    
                </>) : (<></>)}
            </>
        )
        
    }

    const driverNodeAllViewComponent = (data) => {
        const styleobj= {fontWeight:'bold', fontSize:'1.4em'}
        return (
            <>
                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                    <div className="col left"><span>상태</span></div>
                    {data.DrivingStatus === 'use' ? (<div className="col right"><span>이용완료</span></div>):null}
                    {data.DrivingStatus === 'reserve' ? (<div className="col right"><span>예약</span></div>):null}
                    {data.DrivingStatus === 'cancel' ? (<div className="col right"><span>취소</span></div>):null}
                </div>
                {tmpHeaderComp('이용')}
                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                    <div className="col left"><span>일자</span></div>
                    {/* <div className="col right"><span>{moment(data.RevStartDt).format('MM/DD')} {moment(data.RevStartDt).format('HH:mm')}~{moment(data.RevEndDt).format('HH:mm')} ({data.ReserveHourMin})</span></div> */}
                    <div className="col right"><span>{moment(data.RevStartDt).format('yyyy-MM-DD')}</span></div>
                </div>
                
                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                    <div className="col left"><span>예약시간</span></div>
                    <div className="col right"><span>{moment(data.RevStartDt).format('HH:mm')}~{getNextDayStr(moment(data.RevStartDt).format('yyyyMMDD'), moment(data.RevEndDt).format('yyyyMMDD'))}{moment(data.RevEndDt).format('HH:mm')} ({getColonToKoreanStr(data.ReserveHourMin)})</span></div>
                </div>

                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                    <div className="col left"><span>이용시간</span></div>
                    <div className="col right"><span>{moment(data.StartDate).format('HH:mm')}~{getNextDayStr(moment(data.StartDate).format('yyyyMMDD'), moment(data.EndDate).format('yyyyMMDD'))}{moment(data.EndDate).format('HH:mm')} ({getColonToKoreanStr(data.UseHourMin)})</span></div>
                </div>

                {tmpBlankComp()}

                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                    <div className="col left"><span>출발지</span></div>
                    <div className="col right"><span>{getConvertSimpleAddr(data.StartAddress)}</span></div>
                </div>
                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                    <div className="col left"><span>경유지</span></div>
                    <div className="col right"><span>{getConvertSimpleAddr(data.WaypointAddress)}</span></div>
                </div>
                
                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                    <div className="col left"><span>종료지</span></div>
                    <div className="col right"><span>{getConvertSimpleAddr(data.EndAddress)}</span></div>
                </div>

                {tmpBlankComp()}

                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                    <div className="col left"><span>반영시간</span></div>
                    <div className="col right"><span>{getTotalMinuteToHourMinuteStr(data.ResultUseTime)} 차감</span></div>
                </div>

                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                    <div className="col left"><span>예약출발지</span></div>
                    <div className="col right"><span>{getConvertSimpleAddr(data.RevStartAddress)}</span></div>
                </div>
                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                    <div className="col left"><span>예약경유지</span></div>
                    <div className="col right"><span>{getConvertSimpleAddr(data.RevWaypointAddress)}</span></div>
                </div>
                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                    <div className="col left"><span>예약종료지</span></div>
                    <div className="col right"><span>{getConvertSimpleAddr(data.RevEndAddress)}</span></div>
                </div>

                {tmpHeaderComp('청구비용')}

                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                    <div className="col left"><span>추가이용시간</span></div>
                    <div className="col right"><span>{getTotalMinuteToHourMinuteStr(data.ExceedTime)}</span></div>
                </div>
                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                    <div className="col left"><span>시간당단가</span></div>
                    <div className="col right"><span>{Util.numberWithCommas(data.MmHourPriceHistory + '')}원</span></div>
                </div>

                {tmpBlankComp()}

                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                    <div className="col left"><span>추가이용</span></div>
                    <div className="col right"><span>{Util.numberWithCommas(data.ExceedAmount + '')}원</span></div>
                </div>

                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>                
                    <div className="col left"><span>출발지 할증</span></div>
                    <div className="col right"><span>{Util.numberWithCommas(data.StartAreaFee+'')}</span></div>
                </div>

                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>                
                    <div className="col left"><span>지역할증</span></div>
                    <div className="col right"><span>{Util.numberWithCommas(data.AreaFee+'')}</span></div>
                </div>
                
                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>                
                    <div className="col left"><span>심야할증</span></div>
                    <div className="col right"><span>{Util.numberWithCommas(data.NightFee+'')}</span></div>
                </div>
                
                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>
                    <div className="col left"><span>결제대행</span></div>
                    <div className="col right"><span>{Util.numberWithCommas(getSumExpence(reportData2.expence, data.DrivingNo) + '')}원</span></div>
                </div>

                {tmpHeaderComp('결제대행내역')}
                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>                
                    <div className="col left"><span>{'test'}</span></div>
                    <div className="col right"><span>{'10000'}원</span></div>
                </div>

                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>                
                    <div className="col left"><span>대행수수료</span></div>
                    <div className="col right"><span>??원</span></div>
                </div>

                {tmpHeaderComp('청구비용')}
                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>                
                    <div className="col left"><span>{'집계중'}</span></div>
                    
                </div>
                
                    
                {tmpHeaderComp('취소')}
                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>                
                    <div className="col left"><span>취소수수료</span></div>
                    <div className="col right"><span>{(data.CancelTime >= 1 || data.CancelAmount >= 1) ? '발생' : '미발생'}</span></div>
                </div>

                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>                
                    <div className="col left"><span>취소차감시간</span></div>
                    <div className="col right"><span>{getTotalMinuteToHourMinuteStr(data.CancelTime)}</span></div>
                </div>

                <div className="grid cross-center" style={{alignItems:'center', padding:'10px 0px'}}>                
                    <div className="col left"><span>취소차감금액</span></div>
                    <div className="col right"><span>{Util.numberWithCommas(data.CancelAmount+'')}</span></div>
                </div>


                
            </>
            
        )
        
    }
    
    const fontStyleTitle={ fontSize:'1em' }
    const fontStyle={ fontSize:'0.8em' }
    const buttonStyle={ fontSize:'0.6em' }
    
    let allPaymentArr = paymentFeeProcessArr();
    

    // https://react-chartjs-2.js.org/examples/horizontal-bar-chart
    return (
        <>
            <GlobalStyle />
            <Header
                title="월간모시러 리포트"
                onBackButton={handleBack}
                onHomeButton={handleHome}
            />
            {loadingFlag ? <Loading /> : null}
            
            <main id="Contents">
                {subHeaderComponent()}
                <div className='inner-container2'>
                    
                    {/* <h1 className='h1'>이용일수</h1>
                    <div className="chart">                        
                        <div class="tbl type2">
                            <table>
                                <tbody>
                                    <tr>
                                        <td class="point">상품이용 가능일수</td>
                                        <td>{reportData2.ContractTypeDay}일</td>
                                    </tr>
                                    <tr>
                                        <td class="point">이용일수</td>
                                        <td>{reportData2.UseDays}일</td>
                                    </tr>
                                    <tr>
                                        <td class="point">예약일수</td>
                                        <td>{reportData2.ReserveDays}일</td>
                                    </tr>
                                    <tr>
                                        <td class="point">잔여일수</td>
                                        <td>{reportData2.RemainDays}일</td>
                                    </tr>
                                    <tr>
                                        <td class="point">초과일수</td>
                                        <td>{reportData2.ExceedDays}일</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> */}

                    {/* ({  Math.floor( (reportData2.UseTimes / ((reportData2.ContractTypeHour * reportData2.ContractTypeDay)*60)) * 100)}% / 100%) */}
                    <h1 className='h1'>이용 시간 </h1>
                    <div className="chart">
                        <Pie options={pieOptions} data={DouchnutRawData}/>
                        {/* <Doughnut options={options} data={DouchnutRawData} /> */}
                        {/* <Bar options={barOptions} data={barRawData} /> */}
                        <div class="tbl type2" style={{paddingTop:'20px'}}>
                            <table>
                                <tbody>
                                    {/* <tr>
                                        <td class="point">상품이용 가능시간</td>
                                        <td>{getTotalMinuteToHourMinuteStr((reportData2.ContractTypeHour * reportData2.ContractTypeDay) * 60)}</td>
                                    </tr> */}
                                    <tr>
                                        <td class="point">잔여시간</td>
                                        <td>
                                            {getTotalMinuteToHourMinuteStr(reportData2.RemainTimes)}
                                            {reportData2.OvercarriedMinute !== 0 ? <>
                                                <br/>
                                                <span style={{fontSize:'0.8em'}}>(+ {getTotalMinuteToHourMinuteStr(reportData2.OvercarriedMinute)} (이월))</span>
                                            </>:null}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="point">이용시간</td>
                                        <td>{getTotalMinuteToHourMinuteStr(reportData2.UseTimes)}</td>
                                    </tr>
                                    <tr>
                                        <td class="point">예약시간</td>
                                        <td>{getTotalMinuteToHourMinuteStr(reportData2.ReserveTimes)}</td>
                                    </tr>
                                    
                                    <tr>
                                        <td class="point">초과시간</td>
                                        <td>{getTotalMinuteToHourMinuteStr(reportData2.ExceedTimes)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
{/* 
                    {reportData2.ExceedTimes >= 1 ? (
                        <div className="chart">
                            <Bar options={barOptions} data={barRawData} />
                            <div class="tbl type2" style={{paddingTop:'20px'}}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td class="point">초과시간</td>
                                            <td>{getTotalMinuteToHourMinuteStr(reportData2.ExceedTimes)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : null} */}
                    
                    
                    <h1 className='h1'>
                        이용 상세
                        <NavLink to="#" className="tooltip tooltip-inline">
                            <i className="ico ico-quest"></i>
                            <span className="tooltip-content-custom2">
                                <p>● 예약/이용 : 예약시간 / 이용시간.</p>
                                <p>● 연장 : 예약시간보다 이용시간이<br/>많은 경우의 차이시간.</p>
                                <p>● 할증 : 출발지, 지역, 심야할증 <br/>합산금액.</p>
                                <p>● 취소 : 예약 취소 수수료.</p>
                                <p>● 초과 : 초과운행료.<br/>
                                - 연장 발생시 : 잔여시간차감<br/>
                                - 잔여시간 소진시 : 금액차감<br/>
                                </p>
                            </span>
                        </NavLink>
                    </h1>                 

                    <div className="chart">
                        <div class="tbl type1" style={{paddingTop:'10px', wordBreak:'keep-all'}}>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={fontStyleTitle}>일자</th>
                                        <th style={fontStyleTitle}>구분</th>
                                        {/* <th style={fontStyle}>파트너</th> */}
                                        {/* <th style={fontStyleTitle}>예약</th> */}
                                        <th style={fontStyleTitle}>이용시간</th>
                                        <th style={fontStyleTitle}>추가비용</th>
                                        {/* <th style={fontStyleTitle}>연장</th> */}
                                        <th style={fontStyleTitle}>상세</th>
                                        {/* <th style={fontStyleTitle}>할증</th> */}
                                        {/* <th style={fontStyleTitle}>취소</th> */}
                                        {/* <th style={fontStyleTitle}>초과</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {usageDetailComponentNew(reportData.ItemList)}
                                    {/* {reportData.ItemList.length >= 1 ? (<>
                                        <tr>
                                            <td colSpan={3} style={fontStyle} className='point'>총 추가비용</td>
                                            <td colSpan={2} style={fontStyle} className='point'>{Util.numberWithCommas(getArraySumReportAmount(reportData.ItemList))}</td>
                                        </tr>
                                    </>) : null} */}
                                </tbody>
                            </table>
                        </div>
                    </div>


                    {/* 부가세 적용해야할지 말지 여부 로직 정할때 다시 얘기할 것 */}
                    <h1 className='h1'>청구 비용</h1>
                    <div className="chart">
                        <div class="tbl type1" style={{paddingTop:'10px', wordBreak:'keep-all'}}>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={fontStyleTitle}>일자</th>
                                        <th style={fontStyleTitle}>항목</th>
                                        <th style={fontStyleTitle}>기준</th>
                                        <th style={fontStyleTitle}>금액</th>
                                        
                                        {/* <th style={fontStyleTitle}>통행료</th> */}
                                        {/* <th style={fontStyleTitle}>기타</th> */}
                                        {/* <th style={fontStyleTitle}>청구액</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {serviceDetailComponent(reportData2.expence)} */}
                                    {serviceDetailComponent2(allPaymentArr)}

                                    {/* getArraySum2 */}
                                    
                                    {/* {reportData2.expence.length >= 1 ? (<>
                                        <tr>
                                            <td colSpan={2} style={fontStyle} className='point'>합계 금액</td>
                                            <td style={fontStyle} className='point'>{Util.numberWithCommas(getArraySumExpence(reportData2.expence))}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} style={fontStyle} className='point'>결제대행수수료 (20%포함)</td>
                                            <td style={fontStyle} className='point'>{  Util.numberWithCommas((getArraySumExpence(reportData2.expence) * 0.2))  }</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} style={fontStyle} className='point'>청구금</td>
                                            <td style={fontStyle} className='point'>{  Util.numberWithCommas( (getArraySumExpence(reportData2.expence) * 0.2) +  getArraySumExpence(reportData2.expence))  }</td>
                                        </tr>
                                    </>) : null} */}

                                    {allPaymentArr.length >= 1 ? (<>
                                        {/* <tr>
                                            <td colSpan={2} style={fontStyle} className='point'>합계 금액</td>
                                            <td style={fontStyle} className='point'>{Util.numberWithCommas(getArraySum2(allPaymentArr))}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} style={fontStyle} className='point'>결제대행수수료 (20%포함)</td>
                                            <td style={fontStyle} className='point'>{  Util.numberWithCommas((getArraySum2(allPaymentArr) * 0.2))  }</td>
                                        </tr> */}
                                        <tr>
                                            <td colSpan={3} style={fontStyle} className='point'>청구금</td>
                                            <td style={fontStyle} className='point'>{  Util.numberWithCommas( getArraySum2(allPaymentArr) )  }</td>
                                        </tr>
                                    </>) : null}
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>

                    { (reportData2.PersonalHistoryList.length >= 1) ? (
                        <>
                            <h1 className='h1'>리워드</h1>
                            <div className="chart">
                                <div class="tbl type1" style={{paddingTop:'10px', wordBreak:'keep-all'}}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style={fontStyleTitle}>일자</th>
                                                <th style={fontStyleTitle}>내용</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {personalHistoryComponent(reportData2.PersonalHistoryList)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    ) : null}
                    

                </div>


            </main>
            <Modal
                isOpen={viewModalFlag}
                onRequestClose={handleChangeModalStatusFalse}
                contentLabel="Example Modal" 
                overlayClassName="modal-overlay"
                className="modal-box-top"
                bodyOpenClassName="modal-scrolllock"
            >
                <section className="modal-container">
                    <div className="modal">
                        <div className="modal-head">
                            <h1 className="modal-tit" tabindex="0" style={{fontSize:'1.4em', textAlign:'left', paddingLeft:'30px'}}>운행건 상세정보</h1>
                            <button className="btn-top-close" onClick={handleChangeModalStatusFalse}>
                                <span className="blind">팝업 닫기</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div style={{
                                height:'50vh',
                                overflow:'auto',
                                paddingLeft:'30px',
                                paddingRight:'30px'
                                }}>
                                    {/* {driverNodeAllViewComponent(viewModalData)} */}
                                {driverNodeComponent(viewModalData)}
                            </div>  
                                                   
                        </div>
                    </div>
                </section>
            </Modal>
        </>
    );
};

export default ReportView;

