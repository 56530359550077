import React, { Component } from 'react';
import AccountApi from '../../lib/AccountApi';
import {calert, calertPromise} from '../../lib/Util'
import HeaderSimple from '../../components/Common/HeaderSimple';
import Loading from '../../components/Common/Loading'
import RnInterface from '../../lib/RnInterface'
import HeaderJoin2 from '../../components/Common/HeaderJoin2'
import MemberJoinCustomCss from '../../css/CustomGlobalStyle/MemberJoinCustom'

import '../../css/default.css';
import '../../css/member.css';

class MemberJoinAgreeView extends Component {

    state = {
        phone: '',
        loading:1,
    }
    
    async componentDidMount() {
        try {
            await RnInterface.AndroidURLChange(this.props.location.pathname)
        }
        catch(e){
            console.log(e)
        }
    }

    handleBackButton = () => {
        this.props.history.goBack();
    }

    handleOnProcess = async() => {
        try {
            if (!this.state.phone.length) {
                await calertPromise('휴대전화번호를 입력하세요.');
            } else {
                this.setState({loading:0})	// 로딩중
                let result = await AccountApi.FindUserId(this.state.phone, '','')
                if (result.Result === 'success') {
                    if (result.Message !== null) {
                        await calertPromise(result.Message);
                        this.setState({loading:1})	// 로딩완료
                        this.props.history.goBack()
                    } else {
                        await calertPromise("회원가입이 되어있지 않습니다.");
                        this.setState({loading:1})	// 로딩완료
                    }
                } else {
                    await calertPromise(result.Message);
                    this.setState({loading:1})	// 로딩완료
                }
            }
            
        } catch(e) {
            await calertPromise('ID 조회 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
            this.setState({loading:1})	// 로딩완료
        }
        
    }

    handleChangePhone = (e) => {
        this.setState({phone: e.target.value})
    }
    /*
    
    검색 : <input.*[^>]
    치환 : $0/
    
    검색 : <img.*[^>]
    치환 : $0 alt=""/
    */

    render() {
        return (
            <>
                {!this.state.loading ? (<Loading />) : ''}
                <MemberJoinCustomCss />
                <HeaderJoin2 
                    onBackButton={this.handleBackButton}/>
                
                <main id="Contents"> 
                    <div className="key-visual-wrap">
                        <div className="key-visual-container">
                            <div className="key-contents">
                                <div className="key-text">
                                    <strong>회원 가입 시 인증한</strong><br />휴대전화번호를 입력하세요.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="component-wrap inner-container">
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">휴대전화번호</label>									
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <input type="tel" placeholder="- 없이 숫자만 입력" className="input type1" value={this.state.phone} onChange={this.handleChangePhone}/>
                                    </div>
                                </div>	
                            </div>                                               
                        </div>
                    </div> 
                    <div className="component-wrap">
                        <div className="btn-area btn-group-fixed vertical">
                            <button type="button" className="btn large secondary" onClick={this.handleOnProcess}><span>확인</span></button>
                        </div>
                    </div>                                            
                </main>
            </>
        );
    }
};

export default MemberJoinAgreeView;
