import axios from 'axios';
import { Token, Timeout } from '../constantData'
import moment from 'moment-timezone'

const NoticeApi = {};

NoticeApi.GetNoticeList = (viewTarget, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/GetNoticeList?viewTargetFlag=${viewTarget}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });

}

NoticeApi.CustomerNotice = (viewTarget, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/v2/CustomerNotice?viewTargetFlag=${viewTarget}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });

}

// 리스폰스 타임을 얻어온다. 서버 시간 기준으로 봐야하기에..
// url은 데이터가 적은 CustomerNotice를 사용한다.
NoticeApi.GetResponseTime = (ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        // resolve( moment('2021-06-25 19:00').tz('Asia/Seoul').toDate() );

        axios({
            url : `/v2/CustomerNotice`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve( moment(res.headers.date).tz('Asia/Seoul').toDate() );
        }).catch( (err) => {
            return reject(err);
        });
    });
}

// 리스폰스 타임을 얻어온다. 서버 시간 기준으로 봐야하기에..
// url은 데이터가 적은 CustomerNotice를 사용한다.
// NoticeApi.GetResponseTime = (ostype='', deviceid='', loginEncrypt) => {
//     return new Promise((resolve, reject)=>{
//         resolve('2021-06-24 18:59');
//     });
// }



export default NoticeApi;