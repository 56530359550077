/* ************************************************************************************** */
/* ********화면안 변경이 필요해보임. 실제 앱 화면과 다른 화면임 모시러쪽에 확인해봐야함****** */
/* ********************                          20201127            ******************** */
/* ************************************************************************************** */
import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import {calert, calertPromise} from '../../lib/Util';
import CouponApi from '../../lib/CouponApi'
import RnInterface from '../../lib/RnInterface'
import HeaderJoin2 from '../../components/Common/HeaderJoin2'
import MemberJoinCustomCss from '../../css/CustomGlobalStyle/MemberJoinCustom'

// import axios from 'axios';

import '../../css/default.css';
import '../../css/member.css';


import btn_prev from '../../img/btn/btn_prev.svg';




/*
검색 : <input.*[^>]
치환 : $0/

검색 : <img.*[^>]
치환 : $0 alt=""/
*/

/*
[기존 모시러 앱]
추천 코드 화면에서는 
  - 번호를 미입력
    => 다음 단계로 이동 가능
  - 번호를 입력
    => 쿠폰 API를 호출하여 존재하는지 체크해서 없으면 메시지 띄우기
    => 다음 단계로 이동 불가

[새로운 하이브리드 모시러 앱]
추천 코드 화면에서는 
  - 번호를 미입력
    => this.state.couponCode값이 0이라면 > 다음 단계로 이동 가능
  - 번호를 입력
    => this.state.couponCode값이 1이상이고, 등록하지 않았다면 > 다음단계 이동 불가
    => this.state.couponCode값이 1이상이고, 등록했다면 > 다음단계 이동 가능
    

[질문]
1) 회원가입시 쿠폰 등록하는 화면이 있는데
"쿠폰번호를 체크"하는 API를 못찾겠습니다.
http://apitest.client.mosiler.com/help > PromotionCoupon란에 6개중 아래 4개가 있는데
GET GetAvailaleCoupons?customerNo={customerNo}	
GET GetExpiredCoupons?customerNo={customerNo}	
GET GetMyCoupons?customerNo={customerNo}	
GET GetCouponByRedeemCode?customerNo={customerNo}&code={code}

상기 API는 로그인 이후 나의 쿠폰 리스트 취득, 사용 가능한 쿠폰 리스트 쿠폰 유효기간에 대한 API인 것 같습니다.
쿠폰번호를 체크하는 API가 있으면 알려주실 수 있을까요?


[부탁말씀]
1) 현재 본 서버에 kimpaksayosi@naver.com 계정이 가입되어 있는데
본서버쪽 API를 호출하기가 부담스러워서 바쁘신데 죄송하지만
계정 탈퇴 처리를 부탁드립니다.

*/

class MemberJoin2View extends Component {

    state = {
        couponCode: '',
        couponAddFlag : 0,
        accountType:'',
    }
    
    /* 초기화 */
    async componentDidMount() {
        try {
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            document.getElementsByClassName('member_form')
            
            let tmpCouponCode = localStorage.getItem('couponCode');
            let tmpCouponAddFlag = localStorage.getItem('couponAddFlag');
            let tmpAccountType = localStorage.getItem('AccountType');
            
            if (tmpCouponCode !== null && tmpCouponAddFlag !== null && tmpAccountType !== null) 
                this.setState({
                    couponCode: tmpCouponCode,
                    couponAddFlag : parseInt(tmpCouponAddFlag),
                    accountType: tmpAccountType
            })
        }
        catch(e){
            console.log(e)
        }
        
    }

    /* Promise 정의 */
    // 내가 스크랩한 Business card의 List 출력 처리 Begin
    // promissChkCouponCode = () => {
    //     return new Promise((resolve, reject)=>{
    //         axios({
    //             url : `/SnsLogin?id=${kakaoid}&isforce=${isForce}`,
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type':'application/json',
    //                 'OsType': 'A',
    //                 'DeviceId': '273',
    //                 'Token': '4D006D0B3FF2019759A6FD719AE8B291E2B2A4935F0CC42D72534C494C95AAFD'
    //             }
    //         }).then(res => {
    //             resolve(res);
    //         }).catch( (err) => {
    //             return reject(err);
    //         });
    //     });
    // }


    /* 버튼 처리 정의 */
    handlePrevBtn = () => {
        this.props.history.goBack();
    }

    handleNextBtn = () => {
        this.props.history.push('/join/3')
        // this.state.couponCode값이 0이라면 > 다음 단계로 이동 가능
        // if (this.state.couponCode.length === 0) {
        //     localStorage.setItem('couponCode', this.state.couponCode);
        //     localStorage.setItem('couponAddFlag', this.state.couponAddFlag);

        //     this.props.history.push('/join/3')
            
        // } else {
        //     if (this.state.couponAddFlag) {
        //         localStorage.setItem('couponCode', this.state.couponCode);
        //         localStorage.setItem('couponAddFlag', this.state.couponAddFlag);

        //         this.props.history.push('/join/3')
        //     } else {
        //         calert('추천 코드가 유효하지 않습니다.\n정확한 추천코드를 입력하세요.');
        //     }
        // }
    }

    handleCouponRegist = async () => {
        try {
            let deviceinfo = await RnInterface.getData('deviceinfo')
            if (deviceinfo === false) {
                deviceinfo = ['A', '1']
            } 
            else {
                deviceinfo = deviceinfo.split('@@')
            }
            // 소문자로 입력해도 무조건 대문자로 나오게 해야함.
            let shareCodeResult = await CouponApi.CheckShareCode(this.state.couponCode, deviceinfo[0], deviceinfo[1])
            if (shareCodeResult.Result === 'success') {
                this.setState({couponAddFlag: 1});
                localStorage.setItem('memberjoin_parentcode',this.state.couponCode)
                await calertPromise('등록이 완료되었습니다.');
                this.props.history.push('/join/3')
            } else {
                await calertPromise('코드가 맞지 않습니다.');
            }          
            
        }
        catch(e) {
            await calertPromise('코드 검사 도중 오류가 발생했습니다. 관리자에게 문의해주세요.');
        }
    }

    handleChangeText = (e) => {
        this.setState({
            couponCode: e.target.value
        })
    }
    

    render() {
        return (
            <>
            <MemberJoinCustomCss />
                <HeaderJoin2
                    onBackButton={this.handlePrevBtn}
                    title={''} />
                <main id="Contents"> 
                    <div className="key-visual-wrap">
                        <div className="key-visual-container">
                            <div className="key-contents">
                                <div className="key-text">
                                    공유받은 초대 쿠폰 코드를 등록하면<br /><strong>할인쿠폰을 드립니다.</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="component-wrap inner-container">
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">쿠폰등록</label>									
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <span className="input-group has-append">
                                            <input type="text" placeholder="쿠폰번호를 입력해주세요." className="input type1" onChange={this.handleChangeText} value={this.state.couponCode} />
                                            <span className="append">
                                                <button type="button" className={this.state.couponCode.length >= 8 ? 'btn small' : 'btn small primary'} onClick={this.handleCouponRegist}><span>등록</span></button>
                                            </span>  
                                        </span>
                                    </div>
                                </div>	
                            </div>                                                
                        </div>                        
                    </div>
                    <div className="component-wrap space-top">
                        <section className="information-text on">
                            <h1 className="information-head">
                                <button type="button" className="btn-viewmore">
                                    <i className="ico ico-info"></i>
                                    꼭 확인해주세요.
                                    <i className="icon arrow"></i>
                                </button>
                            </h1>
                            <div className="information-body">
                                <div className="info-desc">
                                    <h3 className="info-stit">유의사항</h3>
                                    <ul className="text-sub">
                                        <li>쿠폰마다 이용 가능한 서비스, 조건, 유효기간이 다르기 때문에 사용 전에 꼭 확인해주세요.</li>
                                        <li>쿠폰은 재사용할 수 없으며, 서비스 이용 중 임의 취소 시 해당 쿠폰은 사용 처리됩니다.</li>
                                    </ul>
                                </div>
                            </div>
                        </section>
                    </div>                    
                    <div className="component-wrap">
                        <div className="btn-area btn-group-fixed vertical">
                            <button type="button" className="btn large secondary" onClick={this.handleNextBtn}><span>다음</span></button>
                        </div>
                    </div>                     
                </main>    
 
            </>
        );
    }
};

export default MemberJoin2View;
