import React, { useState, useEffect } from 'react';
import Modal from 'react-modal'
import PropTypes from 'prop-types'; // 패키지 추가
import ReserveApi from '../../../lib/ReserveApi';


const CarInfoModal = ({isOpen, onRequestClose}) => {
    const initCarInfoObj = {
        customerName:'',
        carNo:'',
        carName:''
    }
    const [carInfoObj, setCarInfoObj] = useState(initCarInfoObj)
    const [btnLoading, setBtnLoading] = useState(0)
    
    useEffect(()=>{
        
    }, [])
    
    const changeInput = (e) => {
        let name = e.target.name;
        let value = e.target.value
        setCarInfoObj({
            ...carInfoObj,
            [name]: value
        })
    }

    const modalClose = () => {
        setCarInfoObj(initCarInfoObj)
        onRequestClose()
    }

    const searchCar = async () => {
        try {
            let objData = {
                "REGINUMBER" : carInfoObj.carNo,
                "OWNERNAME" : carInfoObj.customerName
            }
            setBtnLoading(1)
            let result = await ReserveApi.getCarName(objData)
            

            if (result.result === 'SUCCESS') {
                setCarInfoObj({
                    ...carInfoObj,
                    carName: result.data.CARNAME
                })
                setBtnLoading(0)
            } 
            // 로그인 실패시
            else {
                
            }
        }
        catch(e) {
            console.log(e)
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={modalClose}
            contentLabel="Example Modal" 
            overlayClassName="modal-overlay"
            className="modal-box"
            bodyOpenClassName="modal-scrolllock" >
            <section className="modal-container">
                <div className="modal">
                    <div className="modal-head">
                        <h1 className="modal-tit" tabindex="0">할증 요금 안내</h1>
                    </div>
                    <div className="modal-body">
                        <div className="modal-contents">
                            <div className="modal-contents-body">
                                <div className="component-wrap">
                                    <div className="inner-container">

                                        <div className="form-controls type-carinfo" style={{paddingBottom: '10px'}}>
                                            <div className="row">
                                                <input type="text" name="customerName" id="" onChange={changeInput} value={carInfoObj.customerName} placeholder="차량 소유주 이름" title="" className='input type1' />
                                                <input type="text" name="carNo" id="" onChange={changeInput} value={carInfoObj.carNo} placeholder="차량 번호 ex: 12가1234" title="" className='input type1' />
                                            </div>   
                                        </div>

                                        <div className="btn-area vertical" style={{paddingBottom: '10px'}}>
                                            <button
                                                type="button"
                                                className="btn small secondary"
                                                onClick={searchCar}
                                                disabled={(btnLoading === 1)}
                                            >
                                                <span>차량검색</span>
                                            </button>
                                        </div>

                                        <div className="form-controls type-carinfo" style={{paddingBottom: '10px'}}>
                                            <div className="row">
                                                <input type="text" name="customerName" id="" value={carInfoObj.carName} placeholder="차량 소유주 이름" title="" className='input type1' />
                                            </div>   
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button className="btn-top-close" onClick={modalClose}>
                        <span className="blind">팝업 닫기</span>
                    </button>
                    
                    <div className="component-wrap inner-container">
                        <div className="btn-area vertical">
                            <button
                                type="button"
                                className="btn large secondary"
                                onClick={modalClose}
                                disabled={ (!carInfoObj.carName.length) || (btnLoading === 1) } >
                                <span>등록</span>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </Modal>
    )
}

CarInfoModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired
}

export default CarInfoModal; 
