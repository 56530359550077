// 전역 Library 선언
import React, { Component } from 'react';
// import querystring from 'qs';
// import Iframe from 'react-iframe'
// import { API_BASE_URL, Token, PAYMENT_BASE_URL, PAYMENT_INTERFACE_URL, PARTNER_BASE_URL } from "../../constantData";
import { createGlobalStyle } from "styled-components";
import { withCookies } from 'react-cookie'

// Custom Library 선언
import RnInterface from '../../lib/RnInterface'
import Header from "../../components/Common/Header";

import store from '../../reduxStore'
import PaymentApi from '../../lib/PaymentApi'
import Loading from '../../components/Common/Loading'

import Util, {calert, calertPromise} from '../../lib/Util'

import '../../css/default.css';

const GlobalStyle = createGlobalStyle`
.iframe_area { display:block; width:100%; height:100vh; border:1px; }
`;


class ManageCardRegistView extends Component {
    state = {
        formData: null,
        ostype: 'A',
        outer_html: '<html><body>helloworld</body></html>',
        loading: 0
    }

    setUpFormData = async () => {
        try {
            // const { cookies } = this.props;
            let type = await RnInterface.getData('devicetype');
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            // Android일때는 native쪽에 결제모듈 화면을 띄우라는 메시지를 전송한다.
            // native는 MallUserId라는 메시지가 존재하는지를 확인하고 존재한다면 결제용 새로운 웹뷰를 띄우도록 처리하고 있다.
            if (type === 'A') {
                await RnInterface.callReactNative('MallUserId' + store.getState().global_data.loginedData.CustomerNo);
                // await RnInterface.getDataOnlyListener()
            }


            // 결제 결과를 native -> webview측으로 보내서 
            // 이곳에서 받게된다.
            let resultData = await RnInterface.getDataOnlyListener()

            let paymentResultData = {};
            let formData = {}
            const { cookies } = this.props;

            formData.CustomerNo = store.getState().global_data.loginedData.CustomerNo;
            formData.CardData = JSON.parse(resultData)
            // alert(resultData)


            if (formData.CardData.ResultCode === '3001') {
                paymentResultData = await PaymentApi.setPaymentCard(
                    formData,
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                )

                if (paymentResultData.Result === 'success') {

                    let tmpPartnerCardAuthResult = await PaymentApi.getPartnerCardAuth(
                        formData.CardData.DisplayCardNo,
                        formData.CustomerNo,
                        cookies.get('ostype'),
                        cookies.get('deviceid'),
                        cookies.get('login_encrypt')
                    );

                    this.setState({loading:1})


                    if (tmpPartnerCardAuthResult) {
                        await calertPromise('카드가 등록되었습니다.')
                        let obj = {};
                        try {
                            obj = Util.getQueryStringObject()
                            if (obj.redirect !== undefined) {
                                this.props.history.replace({
                                    pathname: `/${obj.redirect}`,
                                    state : {
                                        result_cardregist: 'success',
                                        partnerCard: true
                                    }
                                });
                            } else {
                                this.props.history.goBack();
                            }
                        } catch(e) {
                            this.props.history.goBack();
                        }
                    } else {
                        await calertPromise('정상적으로 등록되었습니다.')
                        let obj = {};
                        try {
                            obj = Util.getQueryStringObject()
                            if (obj.redirect !== undefined) {
                                this.props.history.replace({
                                    pathname: `/${obj.redirect}`,
                                    state : {
                                        result_cardregist: 'success',
                                        partnerCard: false
                                    }
                                });
                            } else {
                                this.props.history.goBack();
                            }
                        } catch(e) {
                            this.props.history.goBack();
                        }
                    }

                    // let testData = await PaymentApi.test01(formData.CardData.DisplayCardNo, formData.CustomerNo)
                    

                    // if (await ) {
                    
                    //     this.props.history.replace('/card');
                    // } else {
                    //     // ???
                    // }
                } else {
                    await calertPromise('카드 등록에 실패했습니다.')
                    this.setState({loading:1})
                    this.props.history.goBack();
                }
            } else {
                await calertPromise('아래의 사유에 의해 카드 등록에 실패했습니다.\n-' + formData.CardData.ResultMsg);
                this.setState({loading:1})
                this.props.history.goBack();


                // 카드 등록이 되었다면 
                // this.getPartnerCardAuth(data.DisplayCardNo, data.Id);를 호출한다.
                // 위 함수는 제휴카드 인증에 대한 내용이다.
                // 제휴카드 인증결과 success라면 아래 함수를 호출한다.
                // setPaymentPsBinnumChk
            }

            


        } catch (e) {
            await calertPromise(JSON.stringify(e));
            this.setState({loading:1})
            this.props.history.goBack();
            // calert(JSON.stringify(e))
            // JSON.parse에서 에러가 발생하면 이쪽에서 처리한다.
            // 결제부분에서 실패하거나 성공하면 RN쪽으로 json형태로 반환하고, 
            // 그 값을 web단으로 보낸다. 만약에 그 값이 json형태가 아닐경우
            // 예외처리를 실시해야한다.
            // calert('혹시 여기?')
            // calert(JSON.stringify(e))
        }

    }

    componentDidMount() {
        this.setUpFormData();
    }

    handleBack = () => {
        this.props.history.goBack();
    }

    handleHome = () => {
        this.props.history.replace('/main')
    }

    render() {
        return (
            <>
                {!this.state.loading ? (<Loading />) : ''}
                <GlobalStyle />
                <Header
                    title="결제등록하기"
                    onBackButton={this.handleBack}
                    onHomeButton={this.handleHome}
                />


            </>
        );
    }
};

export default withCookies(ManageCardRegistView);
/*
{this.state.ostype !== 'I' ?
                (<>
                거기냐
                <Iframe
                    url={`http://smilepay.mosiler.com/mainBillPay.aspx?MallUserId=${store.getState().global_data.loginedData.CustomerNo}`}
                    id="iframe_id"
                    className="iframe_area"
                />

                </>) :
                ('여기냐?')
                }

*/