import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { useCookies } from 'react-cookie';
import CheckPageStatusComponent from './class/ScrollToTop';
import { useHistory } from 'react-router';
import RnInterface from './lib/RnInterface';

import {
    TestComponent,
    TestComponent2,
    TestComponent3,
    AddressTest,
    TestPayment,
    MapTest2,
    

    AddressSearch,
    AddressSearchSub,
    MapSelect,
    LocationFavorite,
    PolicyPrice,
    CommonLinkView,


    LoginCheck,
    LoginView,
    MemberJoin1View,
    MemberJoin2View,
    MemberJoin3View,
    MemberJoin4View,
    MemberJoin4SnsView,
    MemberJoin5View,
    MemberJoinComplateView,
    MemberJoinTermsView,
    
    FirstStepCarInfo0View,
    FirstStepCarInfo1View,
    FirstStepCarInfo2View,

    MainMenuView,
    MainMenuNewView,
    // MainMenuMyInfoView,
    MainMenuMemberChangeInfo,
    MainMenuMemberChangeInfoChangePw,
    MainMenuMemberChangeInfoChangePhone,
    MainMenuMemberChangeInfoChangeAddress,

    UseStateView,
    UseStateDetailView,
    UseStateDetailNativeOnlyView,
    SafetyInfoView,
    UseHistoryView,
    UseHistoryDetailView,
    UseHistoryDetailNativeOnlyView,
    EstimateView,
    EstimateViewNativeOnlyView,
    Coupon10CheckNativeOnlyView,

    MemberSearchIdView,
    MemberSearchPwView,

    ReserveGeneralView,
    ReserveAirView,
    ReservePartnershipView,
    // ReserveGolfView,
    ReserveConfirmView,
    ReserveConfirmNewView,
    
    ReserveIntroView,
    ReserveStep1View,
    ReserveStep2View,
    ReserveStep3View,
    ReserveStep4View,
    ReserveConfirmStep1View,
    ReserveConfirmStep2View,

    ReserveStep1ViewSki,
    ReserveStep2ViewSki,
    ReserveStep3ViewSki,
    ReserveStep4ViewSki,
    ReserveConfirmStep1ViewSki,
    ReserveConfirmStep2ViewSki,

    ReserveStep1ViewMonthlyOnce,
    ReserveStep2ViewMonthlyOnce,

    ManageCardView,
    ManageCardRegistView,
    ManageCardPartnerRegistView,
    MainPaymentView,
    
    //Message
    MemberFreeView,
    MemberFreeComplateView,

    // Current Reserve
    CurrentReserveView,
    
    // App Setting
    AppSettingView,
    AppSettingTermsView,
    TermsMainView,
    
    // Notice
    NoticeView,
    NoticeLinkView,
    AppleLoginRedirect,
    
    CouponView,
    
    InviteView,

    RealtimeLiveDrivingView,

    TicketView,
    TicketBuyingView,

    // 고객의 소리
    VoiceOfCustomerView,
    VoiceOfCustomerNativeOnlyView,
    ReserveComplateView,
    ButlerServiceSearch,
    PolicyPriceMonthly,
    MonthlyMyInfoView,
    ReportView,   
    
} from './pages';

import oauthTest from './pages/oauthTest';


const App = (props) => {
    let TestCom = (testProps) => {
        window.scrollTo(0, 0);
        // const [cookies, setCookie] = useCookies();
        // 쿠키 6개 존재하고, deviceid를 제외한 5개가 값이 들어가 있다면
        // testProps.history.push('/');
        // console.log(cookies)
        return (<></>);
    }
    // const history = useHistory();
    
    // useEffect(()=>{
    //     return history.listen( async (location) => {
    //         // /main이외의 화면이라면
    //         if (location.pathname.indexOf('main') === -1) {
    //             // ~~/:param 형태의 URL중 param이 정해지지 않은 화면에 대해서는 
    //             // 해당 화면의 이름만을 애널리틱스로 보낸다.
    //             if ( location.pathname.indexOf('use_state_detail') > -1 )
    //                 await RnInterface.callReactNative(`ScreenViewAnalyticsuse_state_detail`)
    //             else if ( location.pathname.indexOf('use_history_detail') > -1 )    
    //                 await RnInterface.callReactNative(`ScreenViewAnalyticsuse_history_detail`)
    //             else if ( location.pathname.indexOf('safety_info') > -1 )           
    //                 await RnInterface.callReactNative(`ScreenViewAnalyticssafety_info`)
    //             else if ( location.pathname.indexOf('set_estimate') > -1 )          
    //                 await RnInterface.callReactNative(`ScreenViewAnalyticsset_estimate`)
    //             else if ( location.pathname.indexOf('get_estimate') > -1 )          
    //                 await RnInterface.callReactNative(`ScreenViewAnalyticsget_estimate`)
    //             else if ( location.pathname.indexOf('apple_login_process') > -1 )   
    //                 await RnInterface.callReactNative(`ScreenViewAnalyticsapple_login_process`)                    
    //             else    
    //                 await RnInterface.callReactNative(`ScreenViewAnalytics${location.pathname.replace('/','')}`)
    //         }
    //     })
    // }, [history])


    return (
        <>
            {/* 화면 이동시마다 아래 임시 컴포넌트가 호출된다. */}
            <Route component={CheckPageStatusComponent} />

            <LastLocationProvider>
            <Switch>
                {/* props 전달 : <Route path='/' render{()=> <board props={data} />} /> */}
                {/* <Route exact path="/" component={LoginView} /> */}
                {/* <Route exact path="/" render={()=> <LoginView />} /> */}
                {/* <Route exact path="/" component={LoginCheck} store={props.store} /> */}                

                {/* <Route exact path="/myinfo"  render={(routeProps)=> <MainMenuMyInfoView {...routeProps} store={props.store} />}  /> 
                메뉴 호출하는 route였는데 MainMenuView.js에서 css를 통한 메뉴 호출이 가능해졌기 때문에 미사용 */}
                {/* <Route exact path="/oauth" component={oauthTest} /> => 미사용 (웹 로그인 형식으로 사용하려 했으나 뒤로가기 문제 때문에 
                    react-native 카카오 로그인 라이브러리를 사용하게 되었기 때문에 미사용.)*/}
                
                {/* 실험용 테스트 컴포넌트(주로 새로운 라이브러리 검증 및 로직 검증 때문에 사용되었음) */}
                <Route exact path="/test" component={TestComponent} />
                <Route exact path="/test2" component={TestComponent2} />
                <Route exact path="/test3" component={TestComponent3} />
                <Route exact path="/testmap2" component={MapTest2} />              
                <Route exact path="/address_test" component={AddressTest} />
                <Route exact path="/test_payment" component={TestPayment} />
                
                {/* 로그인 체크 */}                
                <Route exact path="/"  component={LoginCheck}  />
                
                {/* 로그인 화면 */}                
                <Route exact path="/login"  component={LoginView} />
                
                {/* 소셜 로그인 (Apple) */}
                <Route exact path="/apple_login_process/:id" component={AppleLoginRedirect} />
                
                {/* Common(주소 검색, 맵 선택) */}
                <Route exact path="/address_search" component={AddressSearch} />
                <Route exact path="/address_search_sub" component={AddressSearchSub} />
                <Route exact path="/butler_search" component={ButlerServiceSearch} />
                
                <Route exact path="/map_select" component={MapSelect} />
                <Route exact path="/location_favorite" component={LocationFavorite} />
                <Route exact path="/location_favorite_manage" component={LocationFavorite} />
                <Route exact path="/policy_price" component={PolicyPrice} />
                <Route exact path="/policy_price_monthly" component={PolicyPriceMonthly} />
                
                <Route exact path="/common_link" component={CommonLinkView} />
                

                {/* 고객의 소리 */}
                <Route exact path="/voc" component={VoiceOfCustomerView} />
                
                
                
                {/* Join */}
                <Route exact path="/join_terms/:state" component={MemberJoinTermsView} />
                <Route exact path="/join/1" component={MemberJoin1View} />
                <Route exact path="/join/2" component={MemberJoin2View} />
                <Route exact path="/join/3" component={MemberJoin3View} />
                <Route exact path="/join/4" component={MemberJoin4View} />
                <Route exact path="/join/4_sns" component={MemberJoin4SnsView} />
                <Route exact path="/join/5" component={MemberJoin5View} />
                <Route exact path="/join/end"  component={MemberJoinComplateView}  />

                {/* 메뉴 */}
                <Route exact path="/main" component={MainMenuView} />
                <Route exact path="/main_old" component={MainMenuNewView} />

                {/* <Route exact path="/main" component={MainMenuNewView} />
                <Route exact path="/main_old" component={MainMenuView} /> */}
                
                {/* 고객 정보 (월간모시러) */}
                <Route exact path="/info_monthly" component={MonthlyMyInfoView} />
                {/* 리포트 (월간모시러) */}
                <Route exact path="/report_monthly" component={ReportView} />
                

                <Route exact path="/changeinfo" component={MainMenuMemberChangeInfo} />
                <Route exact path="/myinfo_changepw" component={MainMenuMemberChangeInfoChangePw} />
                <Route exact path="/myinfo_changephone" component={MainMenuMemberChangeInfoChangePhone} />
                <Route exact path="/myinfo_changeaddress" component={MainMenuMemberChangeInfoChangeAddress} />
                
                {/* ID / 비번 찾기 */}
                <Route exact path="/search_id" component={MemberSearchIdView} />
                <Route exact path="/search_pw" component={MemberSearchPwView} />
                
                {/* 초기 설정 */}
                <Route exact path="/first_0" component={FirstStepCarInfo0View} />
                <Route exact path="/first_1/:flag" component={FirstStepCarInfo1View} />         {/* flag  0: 신규 1: 변경 */}
                <Route exact path="/first_end/:flag" component={FirstStepCarInfo2View} />       {/* flag  0: 신규 1: 변경 */}

                {/* 예약 */}
                <Route exact path="/reserve/:state" component={ReserveGeneralView} />
                <Route exact path="/reserve_air/:state" component={ReserveAirView} />
                <Route exact path="/reserve_partner/:state" component={ReservePartnershipView} />

                
                              
                {/* 예약확인 */}
                <Route exact path="/reserve_confirm" component={ReserveConfirmView} />

                {/* 예약 리뉴얼 Begin */}               
                <Route exact path="/reserve_renew/:state/intro" component={ReserveIntroView} />
                <Route exact path="/reserve_renew/:state/1" component={ReserveStep1View} />
                <Route exact path="/reserve_renew/:state/2" component={ReserveStep2View} />
                <Route exact path="/reserve_renew/:state/3" component={ReserveStep3View} />
                <Route exact path="/reserve_renew/:state/4" component={ReserveStep4View} />
                <Route exact path="/reserve_renew/:state/5" component={ReserveConfirmStep1View} />
                <Route exact path="/reserve_confirm_new" component={ReserveConfirmStep2View} />
                <Route exact path="/reserve_complate_after_view" component={ReserveComplateView} />
                {/* 예약 리뉴얼 End */}


                {/* 예약 리뉴얼 Ski version Begin */}               
                <Route exact path="/reserve_renew_ski/:state/1" component={ReserveStep1ViewSki} />
                <Route exact path="/reserve_renew_ski/:state/2" component={ReserveStep2ViewSki} />
                <Route exact path="/reserve_renew_ski/:state/3" component={ReserveStep3ViewSki} />
                <Route exact path="/reserve_renew_ski/:state/4" component={ReserveStep4ViewSki} />
                <Route exact path="/reserve_renew_ski/:state/5" component={ReserveConfirmStep1ViewSki} />
                <Route exact path="/reserve_confirm_new_ski"    component={ReserveConfirmStep2ViewSki} />
                {/* 예약 리뉴얼 End */}


                {/* 예약 리뉴얼(월간모시러 단건) Begin */}               
                <Route exact path="/reserve_monthlyonce/:state/1" component={ReserveStep1ViewMonthlyOnce} />
                <Route exact path="/reserve_monthlyonce/:state/2" component={ReserveStep2ViewMonthlyOnce} />
                {/* 예약 리뉴얼(월간모시러 단건) End */}
                

                {/* 예약 현황 */}
                <Route exact path="/use_state" component={UseStateView} />
                <Route exact path="/use_state_detail/:drivingno" component={UseStateDetailView} />
                <Route exact path="/safety_info/:drivingno" component={SafetyInfoView} />
                
                
                {/* 이용 기록 */}
                <Route exact path="/use_history" component={UseHistoryView} />
                <Route exact path="/use_history_detail/:drivingno" component={UseHistoryDetailView} />
                
                {/* 평가 하기 / 보기 */}
                <Route exact path="/set_estimate/:drivingno" component={EstimateView} />
                <Route exact path="/get_estimate/:drivingno" component={EstimateView} />
                
                {/* native 전용 화면 */}
                <Route exact path="/use_state_detail_native/:drivingno" component={UseStateDetailNativeOnlyView} />
                <Route exact path="/use_history_detail_native/:drivingno" component={UseHistoryDetailNativeOnlyView} />
                <Route exact path="/set_estimate_native/:drivingno" component={EstimateViewNativeOnlyView} />
                <Route exact path="/chk_ten_coupon_native" component={Coupon10CheckNativeOnlyView} />
                <Route exact path="/voc_native" component={VoiceOfCustomerNativeOnlyView} />

                {/* 쿠폰 */}
                <Route exact path="/coupon" component={CouponView} />

                {/* 친구초대 */}
                <Route exact path="/invite" component={InviteView} />
                
                {/* 카드 등록 */}
                <Route exact path="/card" component={ManageCardView} />
                <Route exact path="/card_regist" component={ManageCardRegistView} />
                <Route exact path="/partner_card_regist" component={ManageCardPartnerRegistView} />
                <Route exact path="/main_payment" component={MainPaymentView} />

                {/* 회원탈퇴 */}
                <Route exact path="/member_free" component={MemberFreeView} />
                <Route exact path="/member_free_complate" component={MemberFreeComplateView} />

                {/* 앱 세팅 */}
                <Route exact path="/setting" component={AppSettingView} />
                <Route exact path="/setting/:state" component={AppSettingTermsView} />
                <Route exact path="/terms" component={TermsMainView} />
                
                
                {/* 공지사항 */}
                <Route exact path="/notice" component={NoticeView} />
                <Route exact path="/notice_link" component={NoticeLinkView} />
                

                {/* 실시간 운행 */}
                <Route exact path="/realtime_drive" component={RealtimeLiveDrivingView} />

                {/* 충전권 */}
                <Route exact path="/ticket" component={TicketView} />
                <Route exact path="/ticket_buying" component={TicketBuyingView} />
                
                <Route exact path="/static/media/:a" component={<></>} />
                <Route exact path="/static/css/:a" component={<></>} />
                <Route exact path="/static/js/:a" component={<></>} />

                <Route exact path="/module/js/:a" component={<></>} />
                <Route exact path="/module/css/:a" component={<></>} />

            </Switch>
            </LastLocationProvider>
        </>
    );
};

export default App;

