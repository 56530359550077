import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
/* .result_box {width:300px; height:125px; top:50%; left:50%; margin:-250px 0 0 -150px; text-align:center; position:absolute;}
.result_box span.icon_box { display:inline-block; width:100%; text-align:center; padding:20px 0;}
.result_box span.icon_box img.icon_check { width:80px;}
.result_box span.TXST_t1 { display:inline-block; width:100%; font-size:1.5em;}
.result_box span.TXST_t2 { display:inline-block; width:100%; font-size:1.1em; color:#666; line-height:160%; margin:50px 0;}
.result_box span.result_box { display:inline-block; width:100%; text-align:center;} */

.result_box_main_container {}
.result_box_main_container .result_box {display:flex; flex-direction:column; height:100vh; width:100vw;  text-align:center; }
.result_box_main_container .result_box .item1 { flex:1; }
.result_box_main_container .result_box .item2 { flex-shrink:1; }
.result_box_main_container .result_box span.icon_box { display:inline-block; width:100%; text-align:center; padding-bottom:20px;}
.result_box_main_container .result_box span.icon_box img.icon_check { width:80px;}
.result_box_main_container .result_box span.TXST_t1 { display:inline-block; width:100%; font-size:1.5em;}
.result_box_main_container .result_box span.TXST_t2 { display:inline-block; width:100%; font-size:1.1em; color:#666; line-height:160%; margin:50px 0;}
.result_box_main_container .result_box span.result_box { display:inline-block; width:100%; text-align:center;}
.result_box_main_container .result_box .item3 { flex:1; }

`;