import React, { Component } from "react";
import { withCookies } from "react-cookie";
import Header from "../../components/Common/Header";
import Iframe from "react-iframe";
import { createGlobalStyle } from "styled-components";
import RnInterface from "../../lib/RnInterface";
import Util from '../../lib/Util'

// todocss
const GlobalStyle = createGlobalStyle`

.iframe_area { 
    display: block;
    
    border: 0px;
    width: 100%;
    height: calc(100vh - 53px);    
}
/*
.appsettingview_container { display:flex; width:100%; height:100vh; overflow-y:hidden }
.iframe_area::-webkit-scrollbar {
    width:5px;
}
.iframe_area::-webkit-scrollbar-thumb {
    background-color: #2f3542;
    border-radius: 15px;
}
*/
`;

const NOTICE_LINK_STATE_MAIN_MENU = '0';
const NOTICE_LINK_STATE_NOTICE = '1';

class NoticeLinkView extends Component {
    state = {
        viewState: NOTICE_LINK_STATE_MAIN_MENU,
        link: '',
    }

    async componentDidMount() {
        try {
            let {link, viewstate} = Util.getQueryStringObject();
            await RnInterface.AndroidURLChange(this.props.location.pathname);

            this.setState({ 
                viewState :  (viewstate === NOTICE_LINK_STATE_MAIN_MENU) ? NOTICE_LINK_STATE_MAIN_MENU : NOTICE_LINK_STATE_NOTICE,
                link: link
            });

            // 메시지 수신 받는 eventListener 등록
            window.addEventListener( 'message', this.receiveMsgFromChild );

        } catch (e) {
            console.log(e);
        }
    }

    // 자식으로부터 메시지 수신
    receiveMsgFromChild = async (e) => {
        try {
            if (e.data && e.data.indexOf('GOTO_RESERVATION_GOLF||SUCCESS') !== -1) {
                this.props.history.replace('/main')
                this.props.history.push('/reserve_renew/3/1')
            }
            else if (e.data && e.data.indexOf('GOTO_NEW_BROWSING') !== -1) {
                let tmpData = e.data.split('||')[1]
                
                await RnInterface.callReactNative(`NewIntentLinkBrowsing${tmpData}`)
            }
        }
        catch(e) {
            console.log(e)
            
        }
    }
    
    handleBack = () => {
        switch(this.state.viewState) {
            case NOTICE_LINK_STATE_MAIN_MENU:
                this.props.history.replace("/main");
                break;
            case NOTICE_LINK_STATE_NOTICE:
                this.props.history.goBack();
                break;
            default:
                this.props.history.replace("/main");
                break;
        }
    };

    handleHome = () => {
        this.props.history.replace("/main");
    };

    componentWillUnmount () {
        window.removeEventListener("message", this.receiveMsgFromChild);
    }

    render() {
        return (
            <>
                <GlobalStyle />
                <Header
                    title="공지사항"
                    onBackButton={this.handleBack}
                    onHomeButton={this.handleHome}
                />
                <main id="Contents">
                    <div className="component-wrap  detail-view">
                        <Iframe 
                            url={this.state.link} 
                            id="iframe_id" 
                            className="iframe_area " />
                    </div>
                
                </main>

                {/* <div className="appsettingview_container hd_margin_top" >
                    
                </div> */}
            </>
        );
    }
}

export default withCookies(NoticeLinkView);
