import axios from 'axios';
import moment from 'moment'
import { 
    Token, 
    Timeout,
    NAVER_KEY,
    NAVER_CLIENT_ID,
} from '../constantData'
import Util, {customMoment} from './Util'
import store from '../reduxStore'

const ReserveApi = {};
/******************************************************************************************/
/*                                      API 호출 부                                       */
/******************************************************************************************/
ReserveApi.GetDrivingTypes = (ostype = '', deviceid = '', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/v2/GetDrivingTypes`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type': 'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize': loginEncrypt,
            }
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

ReserveApi.CalcDrivingReservation = (objData, ostype = '', deviceid = '', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/v2/CalcDrivingReservation`,
            method: 'POST',
            timeout: Timeout,
            headers: {
                'Content-Type': 'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize': loginEncrypt,
            },
            data: objData
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

ReserveApi.SetDrivingReservation = (objData, ostype = '', deviceid = '', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/v2/SetDrivingReservation`,
            method: 'POST',
            timeout: Timeout + 20000,
            headers: {
                'Content-Type': 'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize': loginEncrypt,
            },
            data: objData
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

ReserveApi.SetDrivingReservationMonthly = (objData, ostype = '', deviceid = '', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/v2/SetDrivingReservationMonthly`,
            method: 'POST',
            timeout: Timeout + 20000,
            headers: {
                'Content-Type': 'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize': loginEncrypt,
            },
            data: objData
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}


ReserveApi.GetDrivingReservationList = (customerNo, pageNumber, _pageSize, pageSize, ostype = '', deviceid = '', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/GetDrivingReservationList?customerNo=${customerNo}&pageNumber=${pageNumber}&_pageSize=${_pageSize}&pageSize=${pageSize}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type': 'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize': loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

ReserveApi.CancelDrivingReservation = (customerno, drivingno, ostype = '', deviceid = '', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/CancelDrivingReservation?customerno=${customerno}&drivingno=${drivingno}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type': 'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize': loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}


ReserveApi.GetDrivingEndList = (customerNo, pageNumber, _pageSize, pageSize, ostype = '', deviceid = '', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/GetDrivingEndList?customerNo=${customerNo}&pageNumber=${pageNumber}&_pageSize=${_pageSize}&pageSize=${pageSize}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type': 'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize': loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

// 경유지 추가 (최대 10곳)
ReserveApi.Add_DRVG_STOP_AREA = (formData, ostype = '', deviceid = '', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/Add_DRVG_STOP_AREA`,
            method: 'POST',
            timeout: Timeout,
            headers: {
                'Content-Type': 'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize': loginEncrypt,
            },
            data: formData
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

// 경유지 취득
ReserveApi.Get_DRVG_STOP_AREA = (customerno, drivingno, ostype = '', deviceid = '', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/Get_DRVG_STOP_AREA?customerno=${customerno}&drivingno=${drivingno}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type': 'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize': loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

// 운행 상세 내용
ReserveApi.DrivingDetail = (drivingNo, ostype = '', deviceid = '', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/v2/DrivingDetail?drivingNo=${drivingNo}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type': 'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize': loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

// 운행 종료(이용기록) 상세 내용 
ReserveApi.GetDrivingEndDetail = (drivingno, ostype = '', deviceid = '', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/GetDrivingEndDetail?drivingno=${drivingno}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type': 'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize': loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

// 부가서비스 내역
ReserveApi.GetDrivingExpensesByDrivingNo = (drivingno, ostype = '', deviceid = '', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/GetDrivingExpensesByDrivingNo?drivingno=${drivingno}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type': 'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize': loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

// 파트너 정보 취득
ReserveApi.GetDriverInfo = (driverno, ostype = '', deviceid = '', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/GetDriverInfo?driverno=${driverno}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type': 'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize': loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

// 평가 등록
ReserveApi.AddEstimate = (objData, ostype = '', deviceid = '', loginEncrypt) => {
    const {customerno, drivingno, drivingpoint, opinion} = objData;
    return new Promise((resolve, reject) => {
        axios({
            url: `/AddEstimate?customerno=${customerno}&drivingno=${drivingno}&drivingpoint=${drivingpoint}&counselpoint=${drivingpoint}&dresspoint=${drivingpoint}&opinion=${opinion}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type': 'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize': loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

// 평가 보기
ReserveApi.DrivingEstimate = (drivingNo, ostype = '', deviceid = '', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/v2/DrivingEstimate?drivingNo=${drivingNo}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type': 'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize': loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}



// 등록되어있는 본인의 default card정보를 취득한다.
ReserveApi.GetBasicPaymentCards = (customerno, ostype = '', deviceid = '', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/GetBasicPaymentCards?customerNo=${customerno}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type': 'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize': loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

// 현재 로그인한 사람이 10% 쿠폰을 가지고 있는가를 체크하는 처리이다.
ReserveApi.get_check_10Coupon = (customerno, ostype = '', deviceid = '', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/v2/get_check_10Coupon?customerNo=${customerno}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type': 'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize': loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

// 예상소요시간 산출
ReserveApi.PredictingTime = (startLocationLng, startLocationLat, endLocationLng, endLocationLat, stopOverList) => {
    let wayPoints = '';
    let wayPointArr = '';
    console.log('PredictingTime');

    try {
        // 경유지 정보가 없을 경우에는 처리하지 않도록 처리해야할 필요가있음..
        if (stopOverList.length >= 1) {
            for (let i in stopOverList) {
                if (!stopOverList[i].waypointAddress.length) {
                    continue;
                }
                
                wayPointArr += stopOverList[i].waypointLng;
                wayPointArr += ',';
                wayPointArr += stopOverList[i].waypointLat;

                if ( i < (stopOverList.length - 1)){
                    wayPointArr += '|';
                }
            }

            wayPoints = '&waypoints=' + wayPointArr;
        }
    }
    catch(e) {
        console.log('length undefined 에러이면 무시.()')
        console.log(e)
    }   

    return new Promise((resolve, reject) => {       
        // 출발지, 종료지가 동일할경우에는
        if (
            ( startLocationLng === endLocationLng) && (startLocationLat === endLocationLat) && wayPoints === '' ) {
                resolve({
                    hour: 0,
                    min: 0
                });
        }
        else {
            axios({
                url: `/map-direction/v1/driving?start=${startLocationLng},${startLocationLat}&goal=${endLocationLng},${endLocationLat}${wayPoints}`,
                method: 'GET',
                timeout: Timeout,
                headers: {
                    "X-NCP-APIGW-API-KEY-ID": NAVER_KEY,
                    "X-NCP-APIGW-API-KEY": NAVER_CLIENT_ID,
                },
            }).then(res => {
                let dur = res.data.route.traoptimal[0].summary.duration;
                // console.log(`before hour min : ${parseInt((dur/(1000*60*60))%24)} : ${parseInt((dur/(1000*60))%60)}`)
                // console.log(`after hour min : ${(parseInt(Math.ceil(parseInt((dur/(1000*60)%60)) / 10) * 10) >= 60) ? parseInt((dur/(1000*60*60))%24) + 1 : parseInt((dur/(1000*60*60))%24)} : ${parseInt(Math.ceil(parseInt((dur/(1000*60)%60)) / 10) * 10) % 60}`)

                resolve({
                    // hour: parseInt((dur/(1000*60*60))%24),
                    // min: parseInt((dur/(1000*60))%60)
                    // millsecond를 10분단위로 나눠서 표시하도록 조치.
                    hour: (parseInt(Math.ceil(parseInt((dur/(1000*60)%60)) / 10) * 10) >= 60) ? parseInt((dur/(1000*60*60))%24) + 1 : parseInt((dur/(1000*60*60))%24),
                    min: parseInt(Math.ceil(parseInt((dur/(1000*60)%60)) / 10) * 10) % 60
                });
            }).catch((err) => {
                return reject(err);
            });
        }
        
    });

    //예상소요시간_jane
    // await axios.get(
    //     //`/map-direction/v1/driving?start=${startLocationLng},${startLocationLat}&goal=${endLocationLng},${endLocationLat}`,
    //     `/map-direction/v1/driving?start=${startLocationLng},${startLocationLat}&goal=${endLocationLng},${endLocationLat}${wayPoints}`,
    //     {
    //         headers: {
    //             "X-NCP-APIGW-API-KEY-ID": NAVER_KEY,
    //             "X-NCP-APIGW-API-KEY": NAVER_CLIENT_ID,
    //         },
    //     }
    // )
    // .then(res => {
    //     dur = res.data.route.traoptimal[0].summary.duration;
    //     dHour = parseInt((dur/(1000*60*60))%24);
    //     dMin = parseInt((dur/(1000*60))%60);

    //     this.setState({
    //         durHour : dHour,
    //         addHour : dHour,
    //         durMin: dMin,
    //         startAddress: startLocationAddress
    //     })
    // })
    // .catch (err => {
    //     console.log(err);
    // })
}

// 예약 취소시 12시간 이내인가? 이외인가?
ReserveApi.CanCancelDriving = (drivingNo, ostype = '', deviceid = '', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/v2/CanCancelDriving?drivingNo=${drivingNo}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type': 'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize': loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

// 예약 후 해당 예약(drivingNo)에 몇명의 파트너가 배정 되었는지 리스트를 보여준다.
// 
ReserveApi.GetDrivingRequestList = (drivingNo, ostype = '', deviceid = '', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/v2/GetDrivingRequestList?drivingNo=${drivingNo}&pageSize=100&pageNo=1`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type': 'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize': loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

// 파트너 배차신청 리스트에서 특정 파트너를 지정하면 배차가 완료되도록 하는 처리이다.
ReserveApi.ChoiceDrivingDriver = (drivingNo, driverNo, ostype = '', deviceid = '', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/v2/ChoiceDrivingDriver?drivingNo=${drivingNo}&driverNo=${driverNo}`,
            method: 'POST',
            timeout: Timeout,
            headers: {
                'Content-Type': 'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize': loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

// 날씨정보 취득
ReserveApi.getWeatherData = (yyyymmdd) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/weatherData.json`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

// 차량 정보 취득
ReserveApi.getCarName = (objData) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/assist/common/carzen/CarAllInfoInquiry`,
            method: 'POST',
            timeout: 15000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token 5a5b3346fa4045818d326a00a7fdf19128427058'
            },
            data: objData
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}





/******************************************************************************************/
/*                                      예약관련 Util Method                              */
/******************************************************************************************/

ReserveApi.getDrivingTypeStr = (drivingType) => {
    let retData = '';
    let {drivingTypeData}  = store.getState().DrivingType_ReduxModule

    switch (Number(drivingType)) {
        case 1:
            retData = '일반';
            break;
        // case 3:
        //     retData = '레저';
        //     break;
        case 3:
            retData = '골프';
            break;
        case 26:
            retData = '공항 홈발렛';
            break;
        default:
            retData = '일반';
            break;
    }
/*    
    // API를 통해 취득받아오던 예약 명칭을 잠시 숨긴다.
    if (drivingTypeData.length) {
        let tmp = drivingTypeData.filter((data) => (Number(data.Code) === Number(drivingType) ));
        if (!tmp.length) {
            switch (Number(drivingType)) {
                case 1:
                    retData = '일반';
                    break;
                case 3:
                    retData = '골프';
                    break;
                case 26:
                    retData = '공항 홈발렛';
                    break;
                default:
                    retData = '일반';
                    break;
            }

        }
        else retData = tmp[0].Name
    } else {
        switch (Number(drivingType)) {
            case 1:
                retData = '일반';
                break;
            case 3:
                retData = '골프';
                break;
            case 26:
                retData = '공항 홈발렛';
                break;
            default:
                retData = '일반';
                break;
        }
    }

    
*/
    return retData;
}

ReserveApi.getDrivingTypeDescStr = (drivingType) => {
    let retData = (<>내게 꼭 맞는 운전동행서비스를 바로바로<br/>이용 고객의 마음까지 운전합니다. 운전동행서비스. 모시러</>);
    switch (Number(drivingType)) {
        case 1:
            retData = (<>
                <span className="bk_st1"><span className="bk_strong"><span className="bg_line"></span>내게 꼭 맞는 운전동행서비스</span>를 바로바로 이용</span>
                <span className="bk_st2">고객의 마음까지 운전합니다. <b>운전동행서비스. 모시러</b></span>
            </>);
            break;
        // 레저
        // case 3:
        //     retData = (<>
        //     <span className="bk_st1"><span className="bk_strong"><span className="bg_line"></span>레저의, 레저에 의한, 레저를 위한</span> 운행 파트너,</span>
        //     <span className="bk_st2">고객의 마음까지 운전합니다. <b>운전동행서비스. 모시러</b></span>
        //     </>);
        //     break;
        case 3:
            retData = (<>
            <span className="bk_st1"><span className="bk_strong"><span className="bg_line"></span>골프의, 골프에 의한, 골프를 위한</span> 운행 파트너,</span>
            <span className="bk_st2">고객의 마음까지 운전합니다. <b>운전동행서비스. 모시러</b></span>
            </>);
            break;
        case 26:
            retData = (<>
                <span className="bk_st1"><span className="bk_strong"><span className="bg_line"></span>내게 꼭 맞는 운전동행서비스</span>를 바로바로 이용</span>
                <span className="bk_st2">고객의 마음까지 운전합니다. <b>운전동행서비스. 모시러</b></span>
                </>);
            break;
        default:
            break;
    }


    return retData;
}

function getExam(intdata) {
    return Number(intdata) >= 10 ? ('' + intdata) : ('0' + intdata);
}

ReserveApi.getWaypointGenerate = (arr) => {
    let tmpStr = '';
    let tmpStrBody = '';

    if (arr.length >= 1) {
        for (let i in arr) {
            // string으로 json형태로 제작
            tmpStrBody += `"WaypointAddress${getExam(parseInt(i) + 1)}" : "${arr[i].waypointAddress}",`
            tmpStrBody += `"WaypointLat${getExam(parseInt(i) + 1)}" : "${arr[i].waypointLat}",`
            tmpStrBody += `"WaypointLng${getExam(parseInt(i) + 1)}" : "${arr[i].waypointLng}",`
        }
        tmpStrBody = tmpStrBody.substring(0, tmpStrBody.length - 1);
        tmpStr = JSON.parse(`{${tmpStrBody}}`)
    } else {
        tmpStr = {}
    }
    return tmpStr;
}

ReserveApi.getWaypointGenerate2 = (arr) => {
    let tmpStr = '';
    let tmpStrBody = '';

    
    if (arr.length >= 1) {
        for (let i in arr) {
            if (arr[i].waypointAddress === undefined)
                continue;
            // string으로 json형태로 제작
            tmpStrBody += `"WaypointAddress${getExam(parseInt(i) + 1)}" : "${arr[i].waypointAddress}",`
            tmpStrBody += `"WaypointLat${getExam(parseInt(i) + 1)}" : ${arr[i].waypointLat},`
            tmpStrBody += `"WaypointLng${getExam(parseInt(i) + 1)}" : ${arr[i].waypointLng},`
        }
        tmpStrBody = tmpStrBody.substring(0, tmpStrBody.length - 1);
        tmpStr = JSON.parse(`{${tmpStrBody}}`)
    } else {
        tmpStr = {}
    }
    return tmpStr;
}

ReserveApi.getReservationDateTime = (date, start, end) => {
    let data1 = moment(date).format('YYYY-MM-DD');
    let data2 = moment(start).format('HH:mm');
    let data3 = moment(end).format('HH:mm');
    
    return {
        RevStartDt: moment(`${data1}T${data2}`),
        RevEndDt: moment(`${data1}T${data3}`)
    }
}

// 예약현황 화면에서 예약접수 or 예약 완료 문자열을 반환하는 처리.
ReserveApi.getDrivingStatusToStr = (DrivingStatus) => {
    let tmpStr = '';
    switch (Number(DrivingStatus)) {
        case 10:
        case 11:
            tmpStr = '예약완료';
            break;
        default:
            tmpStr = '예약접수';
            break;
    }
    return tmpStr;
}

// 쿠폰 관련 데이터 표시 처리
ReserveApi.getCouponDiscountToStr = (CouponId, DiscountAmount, DiscountRate) => {
    /* 
        'CouponId' : 쿠폰아이디
        'DiscountAmount' : 할인금액
        'DiscountRate' : 할인율
    ===> 'CouponId'  값이 있고 'DiscountAmount' 값이 있으면 할인금액 'x,xxx원' 표시, 
         'CouponId'  값이 있고 'DiscountAmount' 값이 없으면서  'DiscountRate' 값이 있으면 할인율 'xx%' 표시
    */
    let tmpStr = '0원';
    // coupon id가 존재하면
    if (CouponId !== null) {
        // DiscountAmount가 있으면
        if (DiscountAmount !== null) {
            // DiscountAmount값을 반환
            tmpStr = Util.numberWithCommas(DiscountAmount + '') + '원';
        }
        // DiscountAmount가 없으면
        else {
            // 데이터값이 존재할경우
            if (DiscountRate !== null) {
                // 할인율이 1% 이상일 경우
                if (DiscountRate >= 1) {
                    tmpStr = DiscountRate + '%';
                }
                // 할인율 0% 일때 초기값 tmpStr그대로 출력
            }
            // 데이터값이 없을 경우 tmpStr 그대로 출력           
        }
    }
    // CouponId가 없으면 tmpStr 그대로 출력

    return tmpStr;
}

// 시간 추가 관련 처리(이용 내역 상세 화면 > 결제정보 > 시간추가 항목)
ReserveApi.getTimeAddToStr = (RevStartDt, RevEndDt, StartDate, EndDate) => {
    /* 
        예약시 운행시간 : 'RevEndDT'-'RevStartDt' 과
        실운행시간 : 'EndDate'-'StartDate' 과의 시간차

        우선 분단위로 했는데 변경되는대로 반영예정.
    */
    let retData = 0;
    // console.log(RevStartDt)
    // console.log(RevEndDt)
    // console.log(StartDate)
    // console.log(EndDate)

    try {
        // 각 데이터가 null값이 왔을 경우
        if (RevStartDt=== null || RevEndDt=== null || StartDate=== null || EndDate=== null) {
            retData = 0;
        } 
        // 그 외의 경우
        else {
            if (RevStartDt.length && RevEndDt.length && StartDate.length && EndDate.length) {
        
                let RevStartDtM = moment(RevStartDt)
                let RevEndDtM = moment(RevEndDt)
        
                let StartDateM = moment(StartDate)
                let EndDateM = moment(EndDate)
        
                let reserveResult = moment.duration(RevEndDtM.diff(RevStartDtM)).asMinutes()
                let realResult = moment.duration(EndDateM.diff(StartDateM)).asMinutes()
        
                retData = Math.abs(realResult - reserveResult)
            } 
        }
        
    } catch(e) {
        console.log(e);
    }
    
    
    return retData;
}

// 시작 시간, 종료 시간의 시간 차이를 시간 분 으로 표현한다.
ReserveApi.getTimeDifferenceHourToStr = (StartDate, EndDate) => {
    let retData = 0;
    let retResult = '';
    let minuteData = 0;
    try {
        retData = moment.duration(customMoment(StartDate).diff(customMoment(EndDate))).asHours();
        retData = Math.abs(retData);
        minuteData = Math.round(Math.abs((Math.floor(retData) - retData) * 60))
        
        retResult = `${ Math.floor(retData)}시간 ${ (minuteData <= 9 ? '0'+minuteData : minuteData) }분`;
        // retResult = `${retData}시간`;
        
    } catch(e) {
        console.log(e);
        retResult = '-시간';
    }
    
    
    return retResult;
}

// 시작 시간, 종료 시간의 시간 차이를 시간 분 으로 표현한다. (ver2) - getTimeDifferenceHourToStr 확인후 이걸로 대체 가능하다면 이걸로 적용시키기.
ReserveApi.getTimeDifferenceHourToStr2 = (StartDate, EndDate) => {
    let retData = 0;
    let retResult = '';
    let minuteData = 0;
    try {
        retData = moment.duration(customMoment(StartDate).diff(customMoment(EndDate))).asHours();
        retData = Math.abs(retData);
        minuteData = Math.round(Math.abs((Math.floor(retData) - retData) * 60))
        
        retResult = `${  Util.getNumber2Ciphers( Math.floor(retData)) }시간 ${ (minuteData <= 9 ? '0'+minuteData : minuteData) }분`;
        // retResult = `${retData}시간`;
        
    } catch(e) {
        console.log(e);
        retResult = '-시간';
    }
    
    
    return retResult;
}

// 제휴카드 관련 문자열처리
ReserveApi.getPartnershipStr = (state) => {
    let retResult = '일반 예약';
    switch(state) {
        case 'hana':
            retResult="하나카드"
            break;
        case 'master':
            retResult="마스터카드 프리미엄"
            break;
        default:
            break;

    }
    return retResult;
}

// 기존 도착지라는 문자열을 종료지 문자열로 변경
// 단, 골프 예약시에는 기존대로 도착지로 변경
ReserveApi.getChangeStrJongryoji = (drivingType) => {
    let retResult = '종료지'
    switch(Number(drivingType)) {
        case 3:     // 골프 예약일때
            retResult = "종료지"
            break;
        default:
            break;

    }
    return retResult;
}

// 지역제한 (ex: 서울, 경기, 인천 이외의 곳은 선택 못하게 막는 처리.)
ReserveApi.isChkLimitLocal = (localName) => {
    let koreanLocalName = [
        '서울',
        '경기',
        '인천'
    ]
    return koreanLocalName.filter((data)=>( ( localName.indexOf(data) !== -1 )) ).length   
}

// 현재 시간의 "분"을 확인해서 X1~X9의 경우에는 무조건 올림처리 해줘서 10분단위로 표시되도록 처리해야한다.
// ReserveApi.getNowMinuteToTenMinute = (localName) => {
//     let timeData = moment('2021-01-01 00:51');
//     console.log(timeData.hour())
//     console.log( Math.ceil(timeData.minute() / 10) * 10   )
//     timeData.set('hour', (Math.ceil(timeData.minute() / 10) * 10 >= 60) ? timeData.hour() + 1 : timeData.hour() )
//     timeData.set('minute', (Math.ceil(timeData.minute() / 10) * 10 ) % 60 )
// }

// 골프 할인이 적용되었는지를 판단한다.
// true : 골프 할인이 적용
// false : 골프 할인이 미적용
ReserveApi.isChkReserveDiscount = (drivingType, reserveDiscount) => {
    let retData = false;
    if (reserveDiscount !== undefined) {
        // 골프 예약이라면?
        if (Number(drivingType) === 3) {
            // 예약할인이 적용되었다면 (0원이 아닐 경우)
            if (Number(reserveDiscount) !== 0) {
                // 골프 할인이 적용.
                retData = true;
            }
        }
    }
    
    return retData
}

/* CancelType에 대해
			 * 0 : 2일 이후 취소시
			 * 1 : 전일 취소
			 * 2 : 당일 취소
			 * 3 : 상기 이외
			 */
ReserveApi.getCancelType = (type) => {
    let retData = '';
    switch(Number(type)) {
        case 1:
            retData = '전일 취소 30,000원'
            break;
        case 2:
            retData = '당일 취소 예약금의 50%'
            break;
        default: 
            retData = ''
            break;
    }
    return retData;
}

export default ReserveApi;