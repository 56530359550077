import axios from 'axios';
import { Token, Timeout } from '../constantData'
import moment from 'moment-timezone'

const MonthlyMosilerApi = {};

// 월간모시러 고객정보 취득 API
MonthlyMosilerApi.GetMmUserInfo = (customerNo, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/v2/GetMmUserInfo?customerNo=${customerNo}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });

}


// 월간모시러 Report 출력전 
// 고객 계약 기간에 따른 Report 날짜 리스트 출력
MonthlyMosilerApi.GetMmReportContractDateList = (customerNo, ostype = '', deviceid = '', loginEncrypt) => {
    //month : '2022-01' or '2022-02'
    return new Promise((resolve, reject) => {
        axios({
            url: `/v2/GetMmReportContractDateList?customerNo=${customerNo}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type': 'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize': loginEncrypt,
            }
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

// 월간모시러 Report 출력
MonthlyMosilerApi.GetMmReportList = (customerNo, startDate, endDate, ostype = '', deviceid = '', loginEncrypt) => {
    //month : '2022-01' or '2022-02'
    return new Promise((resolve, reject) => {
        axios({
            url: `/v2/GetMmReportList?customerNo=${customerNo}&startDate=${startDate}&endDate=${endDate}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type': 'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize': loginEncrypt,
            }
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

MonthlyMosilerApi.CalcDrivingReservationMonthly = (objData, ostype = '', deviceid = '', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/v2/CalcDrivingReservationMonthly`,
            method: 'POST',
            timeout: Timeout,
            headers: {
                'Content-Type': 'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize': loginEncrypt,
            },
            data: objData
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

MonthlyMosilerApi.GetMmAvaliablePercent = (customerNo, ostype = '', deviceid = '', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/v2/GetMmAvaliablePercent?customerNo=${customerNo}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type': 'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize': loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

export const getTotalMinuteToHourMinuteStr = (totalminute) => {
    let retData = '';
    if (Math.floor(Number(totalminute) % 60) === 0) {
        retData = `${Math.floor(Number(totalminute) / 60)}시간`
    }
    else {
        retData = `${Math.floor(Number(totalminute) / 60)}시간 ${Math.floor(Number(totalminute) % 60)}분`
    }
    return retData
}

export const getTotalMinuteToHourMinuteStrColon = (totalminute) => {
    let retData = '';
    if (Math.floor(Number(totalminute) % 60) === 0) {
        retData = `${Math.floor(Number(totalminute) / 60)}:00`
    }
    else {
        let tmpMin = (Math.floor(Number(totalminute) % 60)) + '';
        
        if (tmpMin.length === 1) {
            tmpMin = '0'+tmpMin
        }

        retData = `${Math.floor(Number(totalminute) / 60)}:${tmpMin}`
    }
    return retData
}

export const getColonToKoreanStr = (str) => {
    let retData = '';
    try {
        if (str.split(':')[1] !== undefined) {
            // 분이 0이라면
            if (str.split(':')[1] === '00') {
                retData = `${str.split(':')[0]}시간`
            }
            else {
                retData = `${str.split(':')[0]}시간 ${str.split(':')[1]}분`
            }
        }
        else 
            retData = `-`
    }
    catch(e) {
        retData = `-`
    }
    return retData;
}

export const getDrivingStatusToKoreanStr = (str) => {
    let retData = '';
    switch(str) {
        case 'reserve':
            retData = '예약'
            break;
        case 'cancel':
            retData = '취소'
            break;
        case 'use':
            retData = '이용'
            break;
        default:
            break;
    }
    return retData;
}

export const getExpenceTypeToStr = (type) => {
    let retData = '-';
    switch(type) {
        case '1':
            retData = '주차비'
            break;
        case '3':
            retData = '교통비'
            break;
        case '4':
            retData = '기타'
            break;
        default:
            break;
    }
    return retData;
}

export const getArraySumExpence = (arr) => {
    let retData = 0;
    for(let i in arr) {
        if (arr[i].BillingYN === 'Y')
            retData += arr[i].UseAmount
    }
    return retData;
}

export const getArraySum2 = (arr) => {
    let retData = 0;
    for(let i in arr) {
        retData += arr[i].amount
    }
    return retData;
}



export const getSumReportAmount = (data) => {
    return (data.StartAreaFee + data.AreaFee + data.NightFee + data.ExceedAmount);
}

/*
billingYN이 Y 이거나 drivingstatus가 cancel일 경우는 금액 표시. 금액 합산 실시.
*/

export const getArraySumReportAmount = (arr) => {
    let retData = 0;
    for(let i in arr) {
        console.log(arr[i].DrivingStatus)
        if ( (arr[i].BillingYN === 'Y' && arr[i].DrivingStatus === 'use') || arr[i].DrivingStatus === 'cancel' ) {
            console.log(true)
            retData += arr[i].StartAreaFee + arr[i].AreaFee + arr[i].NightFee + arr[i].ExceedAmount
            console.log(retData)
        }
            
    }
    return retData;
}

export const getCancelType = (type) => {
    let retData = '';
    switch(Number(type)) {
        case 0:
            retData = '2일이상전 취소'
            break;
        case 1:
            retData = '전일취소'
            break;
        case 2:
            retData = '당일취소'
            break;
        case 3:
            retData = '운행중취소'
            break;
        default: 
            retData = ''
            break;
    }
    return retData;
}

export const getExpenceArrIdx = (expenceArr, drivingno) => {
    let retData = [];
    for(let i in expenceArr) {
        if (expenceArr[i].DrivingNo === drivingno) {
            retData.push(i);
        }
    }
    return retData;
}

export const getConvertSimpleAddr = (address) => {
    let retData = '';
    try {
        // 값이 있을 때
        if (address !== '') {
            let tmp = address.split(' ');
            retData = `${tmp[0]} ${tmp[1]} ${tmp[2]}`
        }
    }
    catch(e) {
        retData = ''
    }
    
    
    return retData
}

export const getSumExpence = (expenceArr, DrivingNo) => {

    let retData = 0;
    let tmpArr = [];
    for(let i in expenceArr) {
        if (expenceArr[i].DrivingNo === DrivingNo) {
            tmpArr.push(i);
        }
    }
    
    for(let s in tmpArr) {
        retData += expenceArr[tmpArr[s]].UseAmount
    }
    return retData;

}


export default MonthlyMosilerApi;


/*
const getArraySum = (arr) => {
    let retData = 0;
    arr.reduce((prev, current)=> prev.UseAmount + current.UseAmount, retData)
    return retData;
}



const array1 = [{UseAmount:10}, {UseAmount:20}, ];


console.log(getArraySum(array1));
// expected output: 10


*/