import axios from 'axios';
import { KAKAO_LOCAL_API_URL, NAVER_SEARCH_ADDRESS_URL, Timeout } from '../constantData'

const AddressApi = {};

// 미사용
// AddressApi.requestAddress = (adr) => {
//     let urlInfo = `/v2/local/search/keyword.json?page=1&size=15&sort=accuracy&query=${encodeURIComponent(adr)}`
//     return new Promise((resolve, reject)=>{
//         axios({
//             url : `${KAKAO_LOCAL_API_URL}${urlInfo}`,
//             method: 'GET',
//             timeout: Timeout,
//             headers: {
//                 'Authorization': `KakaoAK 9c8644cebd4d4307dd91d7678b7f66ca`,
//             }
//         }).then(res => {
//             resolve(res.data);
//         }).catch( (err) => {
//             return reject(err);
//         });
//     });
// }


AddressApi.requestPlace = (adr) => {
    let urlInfo = `/v2/local/search/keyword.json?page=1&size=15&sort=accuracy&query=${encodeURIComponent(adr)}`
    return new Promise((resolve, reject)=>{
        axios({
            url : `${KAKAO_LOCAL_API_URL}${urlInfo}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Authorization': `KakaoAK 9c8644cebd4d4307dd91d7678b7f66ca`,
            }
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}

AddressApi.requestAddress = (adr) => {
    let urlInfo = `/v2/local/search/address.json?page=1&size=30&query=${encodeURIComponent(adr)}`
    return new Promise((resolve, reject)=>{
        axios({
            url : `${KAKAO_LOCAL_API_URL}${urlInfo}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Authorization': `KakaoAK 9c8644cebd4d4307dd91d7678b7f66ca`,
            }
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}

AddressApi.requestAddressNaver = (adr) => {
    let urlInfo = `?query=${encodeURIComponent(adr)}`
    return new Promise((resolve, reject)=>{
        axios({
            url : `/map-geocode/v2/geocode${urlInfo}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Accept': `application/json`,
                'X-NCP-APIGW-API-KEY-ID': `grx6yruzmn`,
                'X-NCP-APIGW-API-KEY': `hM5MUJCyLKuKmXVopgruX1Buim5rV5LBBOM690cT`,
            }
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}

AddressApi.getConvertLatlngToAddress = (lat, lng) => {
    let urlInfo = `/v2/local/geo/coord2address.json?x=${lng}&y=${lat}&input_coord=WGS84`
    return new Promise((resolve, reject)=>{
        axios({
            url : `${KAKAO_LOCAL_API_URL}${urlInfo}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Authorization': `KakaoAK 9c8644cebd4d4307dd91d7678b7f66ca`,
            }
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}

/*
AddressApi.getPickAddressData()

설명 : 위 메소드를 사용하려면 아래의 조건이 붙는다.

1. 주소 선택 화면이 필요한 화면에서 폼(text 폼, 버튼 등)을 클릭할 때 화면 이동후 query string으로 redirect={현재 화면의 uri}를 보내야한다.
ex) this.props.history.replace('/address_test?redirect=first_1')

2. 주소 선택 화면이 필요한 화면의 componentDidMount에 AddressApi.getPickAddressData 메소드를 배치한다.
ex) 
    componentDidMount () {
        if (AddressApi.getPickAddressData() !== null) {
            let result = AddressApi.getPickAddressData();
            this.setState({
                carInfo: result.addressData
            },()=>{localStorage.clear();})
        }
        
    }

그러면 주소 선택 화면에서 주소 선택시 AddressApi.setPickAddressData에 의해 localStorage에 저장되며
redirect로 지정한 화면으로 돌아가며 본 메소드가 호출되어 아래의 정보를 리턴하게 된다.
{
    addressData : '서울 서대문구 증가로2길',     // 주소는 신주소 방식
    lat : '37.12644126454',
    lng : '127.123654123654',
}

*/
AddressApi.getPickAddressData = () => {
    let retObj = null;

    let tmpAddressData = localStorage.getItem('tmp_address_data')
    let tmpLat = localStorage.getItem('tmp_lat')
    let tmpLng = localStorage.getItem('tmp_lng')

    if (tmpAddressData !== null && 
        tmpLat !== null && 
        tmpLng !== null
        ) {
            retObj = {
                addressData:tmpAddressData,
                lat:tmpLat,
                lng:tmpLng,
            }
           
            
    }
    return retObj;
}

/*
AddressApi.setPickAddressData()

설명 : 주소 검색 창에서 주소를 선택하면 
AddressApi.setPickAddressData에 의해 localStorage에 데이터를 집어넣게 된다.
*/
AddressApi.setPickAddressData = (address,lat,lng) => {
    // 아래는 테스트용
    localStorage.setItem('tmp_address_data', address)
    localStorage.setItem('tmp_lat', lat)
    localStorage.setItem('tmp_lng', lng)

}


export default AddressApi;