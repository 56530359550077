import React, { Component } from 'react';
import {
    ADDRESS_SEARCH_STATE_RESERVE_START,
    ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_ADD,
    ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_UPDATE,
    ADDRESS_SEARCH_STATE_RESERVE_END,
} from '../../constantData'


import icon_delete from '../../img/booking/icon_delete.svg'
import icon_location from '../../img/booking/icon_location.svg'
import icon_point from '../../img/booking/icon_point.svg'         // 2
import icon_point_waypoint from '../../img/booking/icon_point_waypoint.svg'         // 2
import icon_plus from '../../img/booking/icon_plus.svg'         // 2
import {calertPromise} from '../../lib/Util'

const INIT_WAYPOINT = {
    waypointAddress:'',
    waypointLat:'0',
    waypointLng:'0'
}
const LIMIT_STOPOVER_COUNT = 3;

//console.log(this.state.durMin);

class LocationSelector extends Component {
    componentDidMount() {
        console.log('locationSelector')
        console.log(this.props.history.location.pathname)
    }
    
    handleAddressStartPoint = () => {
        this.props.history.push(`/address_search?redirect=${this.props.history.location.pathname}&state=${ADDRESS_SEARCH_STATE_RESERVE_START}&menuid=0`)
    }
    handleAddressWayPoint = (idx) => {
        this.props.history.push(`/address_search?redirect=${this.props.history.location.pathname}&state=${ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_UPDATE}${idx}&menuid=0`)
    }
    handleAddressEndPoint = () => {
        this.props.history.push(`/address_search?redirect=${this.props.history.location.pathname}&state=${ADDRESS_SEARCH_STATE_RESERVE_END}&menuid=0`)
    }
    handleAddressLocation = () => {
        this.props.history.push(`/address_search?redirect=${this.props.history.location.pathname}&menuid=1`)
    }

    handleFavoriteManage = () => {
        this.props.history.push(`/location_favorite_manage`)
    }
    //

    handleMapStartPoint = () => {
        this.props.history.push(`/map_select?redirect=${this.props.history.location.pathname}&state=${ADDRESS_SEARCH_STATE_RESERVE_START}`)
    }
    handleMapWayPoint = (idx) => {
        this.props.history.push(`/map_select?redirect=${this.props.history.location.pathname}&state=${ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_UPDATE}${idx}`)
    }
    handleMapEndPoint = () => {
        this.props.history.push(`/map_select?redirect=${this.props.history.location.pathname}&state=${ADDRESS_SEARCH_STATE_RESERVE_END}`)
    }
 
    render() {
        let stopOverComponent = (arr) => {
            return arr.map((data, idx) => {
                return (
                    <div className="reserve-list" key={idx}>
                        <div className="grid cross-center flex-between">
                            <div className="col" style={{ width: '26px' }}>
                                <button type="button" className="ico btn-map-delete" id={idx} onClick={async () =>{
                                await this.props.onClickDel(idx); this.props.callback(1);}}><span>경유지삭제</span></button>
                            </div>
                            <div className="col col-8" onClick={()=>{ this.handleAddressWayPoint(idx) }}>
                                <i className="ico ico-vial1"><span>경유지</span></i>
                                {this.props.stopOverList[idx].waypointAddress ? this.props.stopOverList[idx].waypointAddress : '경유지를 입력하세요.'}
                            </div>
                            <div className="col right">
                                <button className="btn-option" onClick={()=>{ this.handleMapWayPoint(idx) }}>지도</button>
                            </div>
                        </div>
                    </div>
                //     <span className="list_t1" key={idx}>
                // 	<table>
                //     	<tr>
                //         	<td className="list_sec1">
                //                 <img src={icon_delete} className="icon_LT1" alt="" id={idx} onClick={async () =>{
                //                 await this.props.onClickDel(idx);
                //                 this.props.callback(1);
                //             }}/>
                //             </td>
                //             <td className="list_sec2"><img src={icon_point_waypoint} className="icon_LT2" alt=""/></td>
                //             <td className="list_sec3" onClick={()=>{ this.handleAddressWayPoint(idx) }}>
                //                 {this.props.stopOverList[idx].waypointAddress ? this.props.stopOverList[idx].waypointAddress : '경유지를 입력하세요.'}
                //             </td>
                //             <td className="list_sec4">
                //                 <button className="btn_map" onClick={()=>{ this.handleMapWayPoint(idx) }}>지도</button>
                //             </td>
                //         </tr>
                //     </table>
                    
                // </span>



                    // <span className="list_t1" key={idx}>
                    //     <span className="list_sec1">
                    //         <img src={icon_delete} className="icon_LT1" alt="" id={idx} onClick={()=>{
                    //             this.props.onClickDel(idx);
                    //         }} />
                    //     </span>
                    //     <span className="list_sec2">
                    //         <img src={icon_point_waypoint} className="icon_LT2" alt="" />
                    //     </span>
                    //     <span className="list_sec3" onClick={()=>{
                    //         this.handleAddressWayPoint(idx)
                    //     }}>
                    //         {this.props.stopOverList[idx].waypointAddress ? this.props.stopOverList[idx].waypointAddress : '경유지를 입력하세요.'}
                            
                    //     </span>
                    //     <span className="list_sec4">
                    //         <button className="btn_map" onClick={()=>{ this.handleMapWayPoint(idx) }}>지도</button>
                    //     </span>
                    // </span>

                    // <CountryInfo key={idx} country={data.country} code={data.code} path={this.props.match.params.flag} detail={this.state.detail}/>
                )
            });
        }

        let callbackSwitchGeneral = () => {
            return (
                <div class="btn-checkbox" onClick={ ()=>{
                    this.props.callback('1');
                }}>
                    <input type="checkbox" id="same1" checked={this.props.checkSwitch} onChange={this.props.onChangeAddressSwitch} />
                    {/* <label for="same1">출발지/{this.props.endPointStr} 동일</label> */}
                    <label for="same1">출발지/종료지 동일</label>  {/* 20211021 애덤 지시사항으로 이곳만 "출발지/종료지 동일"로 하고 나머지는 종료지로 한다. */}
                </div> 

                // <button className="btn same" onChange={this.props.onChangeAddressSwitch}>
                //     <div className="check-box shape" onClick={()=>{
                //         this.props.callback('1')
                //     }}>
                //         <input type="checkbox" id="same1" name="same1"  checked={this.props.checkSwitch} />
                //         <label for="same1" className="">출발지/종료지 동일</label>
                //     </div>
                // </button>
            )
        }
        
        let callbackSwitchGolf = () => {
            return (

                <div class="btn-checkbox golf" onClick={ ()=>{
                    this.props.callback('1');
                }}>
                    <input type="checkbox" id="same2" checked={this.props.checkSwitch} onChange={this.props.onChangeAddressSwitch} />
                    {/* <label for="same2">출발지/{this.props.endPointStr} 동일</label> */}
                    <label for="same2">출발지/종료지 동일</label>   {/* 20211021 애덤 지시사항으로 이곳만 "출발지/종료지 동일"로 하고 나머지는 종료지로 한다. */}
                </div> 

                // <button className="btn golf same" onChange={this.props.onChangeAddressSwitch}>
                //     <div className="check-box shape" onClick={()=>{
                //         this.props.callback('1')
                //     }}>
                //         <input type="checkbox" id="same2" name="same2"  checked={this.props.checkSwitch} />
                //         <label for="same2" className="">출발지/종료지 동일</label>
                //     </div>
                // </button>
            )
        }

        let endMap = () => {
            return (
                <button className="btn-option" onClick={this.handleMapEndPoint}>지도</button>
            )
        }

        return (
            <span className="section_box_deatil" style={{ margin: '-60px 0 0 0' }}>
                 {/* 컴포넌트화할 녀석들 begin */}
                 <div className="reserve-head grid">
                    <div className="col title">출발지 <span className="form-bar">|</span> {this.props.endPointStr}</div>
                    <div className="col right">
                        {this.props.type === 1 ? callbackSwitchGeneral() : callbackSwitchGolf()}
                    </div>
                </div>
                
                <div className="reserve-list">
                   <div className="grid cross-center flex-between">
                        <div className="col" style={{ width: '26px' }}></div>
                        <div className="col col-8" onClick={this.handleAddressStartPoint}>
                            <i className="ico ico-start"><span>출발지</span></i>
                            {this.props.startLocationAddress ? `${this.props.startLocationAddress}` : '출발지를 입력하세요'}
                        </div>
                        <div className="col right">
                            <button className="btn-option" onClick={this.handleMapStartPoint}>지도</button>
                        </div>
                   </div>
                </div>

                {stopOverComponent(this.props.stopOverList)}

                <div className="reserve-list">
                    <div className="grid cross-center flex-between">
                        <div className="col" style={{ width: '26px' }}>
                            <button type="button" className="ico btn-map-plus" onClick={async ()=>{                    
                                if (this.props.stopOverList.length >= LIMIT_STOPOVER_COUNT) {
                                    await calertPromise('최대 3개까지 추가하실 수 있습니다.')
                                } else {
                                    this.props.onClickAdd(INIT_WAYPOINT);
                                } 
                                }}><span>경유지추가</span>
                            </button>
                        </div>
                        
                        {/* <div className="col col-8" onClick={this.props.type === 1 ? this.handleAddressEndPoint : ''}>
                            <i className="ico ico-goal"><span>종료지</span></i>
                            {this.props.endLocationAddress.length !== 0 ? this.props.endLocationAddress : `${this.props.endPointStr}를 입력하세요`}
                        </div>
                        <div className="col right">
                            {this.props.type === 1 ? endMap() : ''}
                        </div> */}

                        <div className="col col-8" onClick={this.handleAddressEndPoint}>
                            <i className="ico ico-goal"><span>종료지</span></i>
                            {this.props.endLocationAddress.length !== 0 ? this.props.endLocationAddress : `${this.props.endPointStr}를 입력하세요`}
                        </div>
                        <div className="col right">
                            {endMap()}
                        </div>

                   </div>
                </div>


                <div className="reserve-foot">
                    <div className="grid cross-center flex-between">
                        <div className="col left">
                            <button type="button" onClick={this.handleAddressLocation}><i className="ico ico-map-history"></i><span className="text-white">최근경로</span></button>
                        </div>
                        
                        <div className="col right">
                            <button onClick={this.handleFavoriteManage}>
                                <i className="ico ico-map-bookmark"></i><span className="text-white">즐겨찾기관리</span>
                            </button>
                        </div>
                    </div>
                </div>


            </span>

        );
    }
};

export default LocationSelector;
