import React, { Component } from 'react';
import { withCookies } from 'react-cookie'
import { withLastLocation } from 'react-router-last-location';
import { createGlobalStyle } from "styled-components";
import Header from '../../components/Common/Header'
import ReserveApi from '../../lib/ReserveApi'
import store from '../../reduxStore'
import Loading from '../../components/Common/Loading'

import icon_arrow from "../../img/record/icon_arrow.svg"
import icon_point from "../../img/record/icon_point.svg"
import icon_location from "../../img/record/icon_location.svg"
import icon_calendar from "../../img/message/icon_calendar2.svg"
import icon_user from '../../img/rating/icon_user.svg'
import icon_star from '../../img/rating/icon_star.svg'

import MessageCss from '../../css/CustomGlobalStyle/Message'
import RnInterface from '../../lib/RnInterface'

import Util, { calert, calertConfirm, calertPromise, customMoment, calertImagePopup, isChkMonthlyMosilerCustomer, getUserInfoEncrypt } from '../../lib/Util'

import { NavLink } from 'react-router-dom';
import event_monthly_mosiler from '../../img/menuevent/monthlymosiler/top_monthly_mobile.png'

import { 
    getReservationListRedux, 
    getReservationListPagingRedux, 
    cancelReservationRedux ,
    empty_use_state_service,
    init_page_user_state_service,
    loading_only_use_state_service,
    loading_end_only_use_state_service,
    setDriverSelectedRedux,

} from '../../modules/UseState/UseState_ReduxModule'
import { connect } from 'react-redux';
import Modal from 'react-modal'

import { 
    getChauffeurDrivenEventAvailableDate,
    setChauffeurDrivenCancelComplate,
    getChauffeurDrivenApplyStatus
} from '../../lib/GoogleSheetApi'



const GlobalStyle = createGlobalStyle`
/* booking_state.css */
@charset "utf-8";

/* 이용내역 */
.record {display:inline-block; width:100%; min-height:calc(100vh - 53px); background:#ebebed; padding:0px;}
.record li.record_box { display:inline-block; width:100%; background:#fff; margin-bottom:15px;}
.record span.record_hd { display:inline-block; width:100%; padding:20px 20px 10px 20px; border-bottom:1px solid #eee;}

.record span.record_hd_color1 {border-left:5px solid #1fafd2 !important}
.record span.record_hd_color3 {border-left:5px solid #718ae4 !important}
.record span.record_hd span.date { display:inline-block; width:100%; font-size:1.1em; padding-bottom:0px;}
.record span.record_hd span.cancel {color:#ff0000 !important}
.record span.record_hd span.subject { display:inline-block; width:100%; padding:10px 0;}
.record span.record_hd span.subject span.kind { display:inline-block; font-size:1.5em; font-weight:bold; padding-right:20px;}
.record span.record_hd span.subject span.number { display:inline-block; font-size:1.2em;}
.record span.record_hd span.subject button.btn_more { display:inline-block; float:right; background:#fff; border:0px;}
.record span.record_hd span.subject button.btn_more img {width:10px;}

.record span.record_detail { display:inline-block; width:100%; padding:10px 20px 10px 25px; border-bottom:1px solid #eee;}

.record table.rtable {width:100%; font-size:1.2em;}
.record table.rtable th {width:90px; padding:5px 0; font-weight:normal; color:#999;}
.record table.rtable td {padding:5px 0;}
.record table.rtable th.price {color:#718ae4 !important; font-weight:bold;}
.record table.rtable td.price {color:#718ae4; font-weight:bold; font-size:1.3em;}
.record table.rtable td.cnumber {color:#999; padding:0px 0 5px 0;}

.record span.record_pay { display:inline-block; width:100%; padding:10px 25px;}


/* 이용내역 - 자세히보기 */
.record li.hd_notice { display:inline-block; width:100%; padding:20px; color:#999;}
.record span.record_hd span.btn { display:inline-block; width:100%; padding:5px 0;}
.record span.record_hd span.btn a.btn_grade { display:inline-block; background:#000; padding:8px 20px; color:#fff; font-size:1.2em; border-radius:4px;}

.record span.record_detail2 { display:inline-block; width:100%; padding:10px 20px; border-bottom:1px solid #eee;}
.record span.record_detail2 span.section_title { display:inline-block; width:100%; font-size:1.3em; padding:10px 0 10px 0; border-bottom:1px solid #eee; margin-bottom:10px;}

.record table.rtable2 {width:100%; font-size:1.2em;}
.record table.rtable2 th {width:90px; padding:5px 0; font-weight:normal; color:#999;}
.record table.rtable2 td {padding:5px 0;}
.record table.rtable2 td.price {font-size:1.3em; font-weight:bold;}
.record table.rtable2 th.result {color:#fff !important;}
.record table.rtable2 td.result_price {font-size:1.3em; font-weight:bold;}
.record table.rtable2 td.result_kind {font-size:0.9em; padding:10px 0 5px 0;}
.record table.rtable2 td.result_sprice {font-size:0.9em; padding:0 0 5px 0;}
.record table.rtable2 td.result_date {font-size:0.9em; color:#9397a2;}

.record table.rtable3 {width:100%; font-size:1.1em;}
.record table.rtable3 td {padding:0px 0 10px 0;}
.record table.rtable3 td.first { vertical-align:top; width:20px; padding:4px 0; float:right;}
.record table.rtable3 td.first img {width:10px;}
.record table.rtable3 td:nth-child(2) {padding-bottom:0px !important;}

.record span.navi_info { display:inline-block; width:100vw; background:#f2f8fe; padding:20px; margin:10px 0 10px -20px;}

.record li.price_result { display:inline-block; width:100%; background:#4d5467; padding:20px; color:#fff;}

/* 20210126 */
.record_detail_component {  padding: 10px 20px 7px 20px; } 
.record_detail_component .record_detail_margin { display:flex; flex-direction:row; font-size:1.2em; padding:7px 0;  justify-content:center; align-items:center;} 
.record_detail_component .record_detail_margin .record_detail_title { flex:3; text-align:right; color:#999; font-weight:bold;} 
.record_detail_component .record_detail_margin .record_detail_sub2 { flex:1; } 
.record_detail_component .record_detail_margin .record_detail_sub3 { flex:10;} 

.seperator_container { display:flex; height:1px; padding: 0 20px;}
.seperator_container .seperator_80 { flex:1; background: #ececec; }

.seperator_container2 { display:flex; height:1px; padding: 0 10px;}
.seperator_container2 .seperator_70 { flex:1; background: #ececec; }

.record_detail_startstop_component {  padding: 0px 20px ;  } 
.record_detail_startstop_component .record_detail_startstop_margin { display:flex; flex-direction:row; justify-content:center; align-items:center; font-size:1.2em; padding:15px 0px;} 
.record_detail_startstop_component .record_detail_startstop_margin  img { display:inline-block; width:11px; padding-bottom:2px; } 

.record_detail_startstop_component .record_detail_startstop_margin .record_detail_title { flex:3; text-align:right; color:#999; text-align:right;} 
.record_detail_startstop_component .record_detail_startstop_margin .record_detail_sub2 { flex:1; } 
.record_detail_startstop_component .record_detail_startstop_margin .record_detail_sub3 { flex:10; } 

.record_detail_pay_component
.record_detail_pay_component {  padding: 5px 20px; } 
.record_detail_pay_component .record_detail_margin { display:flex; flex-direction:row; font-size:1.2em; padding:15px 0; justify-content:center; align-items:center; } 
.record_detail_pay_component .record_detail_margin .record_detail_title { flex:3; text-align:right; color:#718ae4 !important; font-weight:bold;} 
.record_detail_pay_component .record_detail_margin .record_detail_sub2 { flex:1; } 
.record_detail_pay_component .record_detail_margin .record_detail_sub3 { flex:10; color:#718ae4; font-weight:bold; font-size:1.3em; } 



`;

class UseStateView extends Component {
    state = {
        reserveList: [],
        loading: 0,
        loadingMain: 0,
        modalOpenFlag: false,
        drivReqTmpData:[],

    }

    asyncTest = async () => {
        try {
            let { CustomerNo } = store.getState().global_data.loginedData
            let { cookies } = this.props;
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            // console.log(this.props)
            
            
            
            this.props.initReserveData(
                CustomerNo,
                this.props.page,
                cookies.get('ostype'),
                cookies.get('deviceid'),
                cookies.get('login_encrypt')
            )

            ReserveApi.getDrivingTypeStr(2)

            // let tmpReserveResult = await ReserveApi.GetDrivingReservationList(
            //     CustomerNo,
            //     1,
            //     0,
            //     MAX_PAGE,
            //     cookies.get('ostype'),
            //     cookies.get('deviceid'),
            //     cookies.get('login_encrypt')
            // );

            // if (tmpReserveResult.Result === 'success') {
            //     this.setState({
            //         reserveList: tmpReserveResult.ItemList,
            //         loading: 1,
            //         loadingMain:1
            //     })
            // } else {
            //     calert(tmpReserveResult.Message)
            //     this.setState({loading:1})	// 로딩완료

            // }
        } catch (e) {
            console.log(e);
            await calertConfirm('데이터 취득 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
            
        }

    }

    handleGetPagingData = () => {
        let { CustomerNo } = store.getState().global_data.loginedData
        let { cookies } = this.props;

        this.props.getPagingData(
            CustomerNo,
            this.props.page,
            cookies.get('ostype'),
            cookies.get('deviceid'),
            cookies.get('login_encrypt')
        )
        
    }

    componentDidMount() {
        this.asyncTest();
        // 스크롤 이벤트 적용
        window.addEventListener('scroll', this.onScroll);
        
    }

    handleBack = (params) => {
        if (this.props.lastLocation.pathname === '/reserve_confirm') {
            this.props.history.replace('/main');
        } else {
            this.props.history.goBack();
        }
    }

    handleHome = (params) => {
        this.props.history.replace('/main');
    }

    handleDetailView = (DrivingNo, reserveData) => {
        this.props.initPage();
        let { CustomerNo } = store.getState().global_data.loginedData
        let { defaultCardInfo } = this.props;
        this.props.history.push({
            pathname: `/use_state_detail/${DrivingNo}`,
            state: {
                DrivingNo,
                CustomerNo,
                defaultCardInfo,
                reserveData               
            }
        }  );
    }

    // component를 닫게 되면 이벤트도 닫아줘야한다.
    componentWillUnmount() {       
        window.removeEventListener("scroll", this.onScroll);
    }
    
    // 스크롤 로딩 with redux
    onScroll = (e) => {
        const { innerHeight } = window;
        const { scrollHeight } = document.body;
        const myScroll = e.srcElement.scrollingElement.scrollTop
        console.log('onScroll',this.props.getinfo.length)
        console.log('this.props.page',this.props.page)
        
        // length가 3개 이상일때 이 체크가 없으면 onScroll이 화면이 열릴때 한번 더 호출되어 데이터를 추가해버린다.
        // "예약이 2개 되는 건지, 2개 나오는 건지 확인바람. 예약이 2건이 있으면 4개 나옴" 이 현상을 해결했는데 안되면 다른 식으로 해결필요.
        if (this.props.getinfo.length >= 3) {
            // 스크롤 체크를 실시한다.
            if ((innerHeight + myScroll) >= scrollHeight - 1) {
            
                if (this.props.scrollDataSyncFlag === 1) {
                    let { CustomerNo } = store.getState().global_data.loginedData
                    let { cookies } = this.props;
            
                    this.props.getPagingData(
                        CustomerNo,
                        this.props.page,
                        cookies.get('ostype'),
                        cookies.get('deviceid'),
                        cookies.get('login_encrypt')
                    )
                }
                
            }
        }
        
    };

    handleChangeModalStatusTrueAddData = (reqData) => {
        return new Promise((resolve) => {
            this.setState((prevState) => ({ 
                modalOpenFlag : true ,
                drivReqTmpData: reqData
            }), () => {
                resolve(true)
            });
        })
    }

    handleChangeModalStatusTrue = () => {
        this.setState((prevState) => ({ modalOpenFlag : true }));
    }

    handleChangeModalStatusFalse = () => {
        this.setState((prevState) => ({ modalOpenFlag : false }));
    }

    // 리스트 취득할때 메뉴 -> 예약현황을 열었을때만 리스트를 취득하게 하고, 
    // 상세화면 -> 예약현황으로 들어갔을때에도 데이터를 취득하지 않고 유지되도록 재설계가 필요함.
    // 데이터 저장소는 redux를 사용. 
    // 데이터가 존재하면 기존 데이터 활용
    // 데이터가 하나도 없으면 API 통해 취득
    // 삭제 했을 경우는 삭제 API 실행 후 success라고 나왔다면 현재redux에 저장된 데이터중 drivingno와 일치하는 노드를 삭제한다.
    // 삭제 실패했을 경우에는 데이터를 유지한다. 삭제시, 경유지도 신경쓴다.
    // 스크롤할때 페이징 처리가 필요하다. (witive때 코드 참고한다.)
    // useState가 완료되면 바로 history도 적용한다. (구조는 비슷하기에 조금만 수정하면될듯.)

    render() {
        let { CustomerCategory } = store.getState().global_data.loginedData
        
        let reserveListNew = (arr) => {
            return arr.map((data, idx) => {

                return (
                    <>
                        <li className="data-list-item">
                            <div className="data-header">
                            <div className="desc-sum">
                                    {/* <span className="state-txt state-cancel">{ReserveApi.getDrivingStatusToStr(data.DrivingStatus)}</span> */}
                                    {/* <span className="state-txt">{data.DrivingStatusName}</span> */}
                                </div>

                                <div className="desc-title"  onClick={() => { this.handleDetailView(data.DrivingNo, data) }}>
                                    <strong>{data.CustomerComment && data.CustomerComment.indexOf('스키예약') >= 0 ? '스키' : ReserveApi.getDrivingTypeStr(data.DrivingType)}</strong>
                                    <strong className="desc-day">예약접수일 : {customMoment(data.RegDt).format('YYYY-MM-DD HH:mm')}</strong>
                                </div>

                                {data.DrivingStatusName === '운행중' ? (
                                    <></>
                                ) : (
                                    <div className="btn-area grid gut-guide">
                                        <button 
                                            type="button" 
                                            className="btn small pressed" 
                                            onClick={async (e) => {
                                                try {
                                                    e.preventDefault();
                                                    let { CustomerNo } = store.getState().global_data.loginedData
                                                    let { cookies, defaultCardInfo } = this.props;
                                                    let alertMsg = '';

                                                    this.props.loadingStart()

                                                    // 취소 가능한지 여부를 확인
                                                    let canCancelResult = await ReserveApi.CanCancelDriving(
                                                        data.DrivingNo,
                                                        cookies.get('ostype'),
                                                        cookies.get('deviceid'),
                                                        cookies.get('login_encrypt')
                                                    )

                                                    if (canCancelResult.Result === 'success') {
                                                        // 월간모시러 예약취소
                                                        if (isChkMonthlyMosilerCustomer(CustomerCategory)) {    // 월간모시러 고객이면?
                                                            if (canCancelResult.Item.CanCancel) {
                                                                if (Number(canCancelResult.Item.CancelFormFlag) === 1) {
                                                                    alertMsg += '예약 취소를 진행하시겠습니까?'
                                                                    // alertMsg += `\n${ReserveApi.getCancelType(canCancelResult.Item.CancelFormFlag)}`
                                                                    // alertMsg += '\n(취소수수료는 매달 정산시 반영됩니다.)'
                                                                    // 전일 취소
                                                                }
                                                                else if (Number(canCancelResult.Item.CancelFormFlag) === 2) {
                                                                    alertMsg += '예약 취소를 진행하시겠습니까?'
                                                                    // alertMsg += `\n${ReserveApi.getCancelType(canCancelResult.Item.CancelFormFlag)}`
                                                                    // alertMsg += '\n(취소수수료는 매달 정산시 반영됩니다.)'
                                                                    // 전일 취소
                                                                }
                                                                else {
                                                                    alertMsg += '예약 취소를 진행하시겠습니까?'
                                                                }
                                                                
                                                                // 예를 눌렀을 때
                                                                if (await calertConfirm( alertMsg )) {

                                                                    this.props.delReserveData(
                                                                        CustomerNo,
                                                                        data.DrivingNo,
                                                                        data,
                                                                        defaultCardInfo,
                                                                        cookies.get('ostype'),
                                                                        cookies.get('deviceid'),
                                                                        cookies.get('login_encrypt')
                                                                    )
                                                                    // await RnInterface.callReactNative(`ScreenViewAnalyticsReservationCancel`)
                                                                } 
                                                                // 아니오를 눌렀을 때
                                                                else {
                                                                    this.props.loadingEnd()    
                                                                }
                                                            }
                                                            else {
                                                                await calertPromise(canCancelResult.Message)
                                                            }
                                                            
                                                            this.props.loadingEnd()
                                                        }
                                                        else {
                                                            // 취소할 수 있는 상황이라면
                                                            if (canCancelResult.Item.CanCancel) {
                                                                alertMsg += '예약 취소를 진행하시겠습니까?'
                                                                if (canCancelResult.Item.CancelFee !== 0) {
                                                                    alertMsg += `\n\n취소수수료 ${Util.numberWithCommas(String(canCancelResult.Item.CancelFee))}원이 발생됩니다.`
                                                                    alertMsg += `\n(${ReserveApi.getCancelType(canCancelResult.Item.CancelFormFlag)})\n\n`
                                                                    alertMsg += `아래 금액이 환불됩니다.\n`
                                                                    if (canCancelResult.Item.CancelCardAmt >= 1)
                                                                        alertMsg += `카드 : ${Util.numberWithCommas(String(canCancelResult.Item.CancelCardAmt))}원\n`
                                                                    if (canCancelResult.Item.CancelPrepaidAmt >= 1)
                                                                        alertMsg += `충전권 : ${Util.numberWithCommas(String(canCancelResult.Item.CancelPrepaidAmt))}원\n`
                                                                    
                                                                }                                                                
                                                                
                                                                // 예를 눌렀을 때
                                                                if (await calertConfirm( alertMsg )) {
                                                                    // 쇼퍼드리븐 이벤트
                                                                    if (!getChauffeurDrivenEventAvailableDate()) {
                                                                        if (await getChauffeurDrivenApplyStatus(data.DrivingNo) >= 1) {
                                                                            let chauffeurResult = await setChauffeurDrivenCancelComplate(CustomerNo, data.DrivingNo)
                                                                            if (chauffeurResult !== 'success')
                                                                                await setChauffeurDrivenCancelComplate(CustomerNo, data.DrivingNo)
                                                                        }
                                                                    }

                                                                    this.props.delReserveData(
                                                                        CustomerNo,
                                                                        data.DrivingNo,
                                                                        data,
                                                                        defaultCardInfo,
                                                                        cookies.get('ostype'),
                                                                        cookies.get('deviceid'),
                                                                        cookies.get('login_encrypt')
                                                                    )
                                                                    // await RnInterface.callReactNative(`ScreenViewAnalyticsReservationCancel`)
                                                                } 
                                                                // 아니오를 눌렀을 때
                                                                else {
                                                                    this.props.loadingEnd()    
                                                                }

                                                            }
                                                            // 취소할 수 없다면
                                                            else {
                                                                // 출발시간 기준 12시간 이내이거나 배차가 완료되었을 경우는 예약취소 할 수 없습니다.
                                                                await calertPromise(canCancelResult.Message)
                                                                this.props.loadingEnd()
                                                            }
                                                        }
                                                        
                                                    } 
                                                    // result가 fail이라면
                                                    else {
                                                        await calertPromise('예약취소를 실패했습니다. 잠시후 다시 시도해주세요.')
                                                        this.props.loadingEnd()
                                                    }
    
                                                } catch (e) {
                                                    await calertConfirm('예약 취소 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
                                                    this.props.loadingEnd()
                                                }
    
                                            }}>
                                            <span>예약취소</span>
                                        </button>
                                        &nbsp;&nbsp;
                                        
                                        {isChkMonthlyMosilerCustomer(CustomerCategory) ? (
                                            <button
                                                type="button"
                                                className="btn small pressed liner"
                                                onClick={async (e) => {
                                                    try {
                                                        e.preventDefault();
                                                        this.props.loadingStart()
                                                        let alertMsg = `예약변경은 우측하단 카카오톡 채널(모시러 컨시어지)에 문의해주시기 바랍니다.
                                                
                                                모시러 컨시어지 운영시간
                                                (09:00~17:00)`;
                                                        await calertPromise(alertMsg)
                                                
                                                        this.props.loadingEnd()
                                                    } catch (e) {
                                                        await calertPromise('예약변경안내 표시중 문제가 발생했습니다. 관리자에게 문의해주세요.')
                                                        this.props.loadingEnd()
                                                    }

                                                }}>
                                                <span>예약변경안내</span>
                                            </button>
                                        ) : (<></>)}
                                        
                                        
                                    {/*  고객앱에서 고객님이 파트너 선택하는 기능을 당분간 블락. 
                                        사유: 계속되는 파트너의 중복 배차 신청으로 인한 중복 배정 등
                                        rise : 버튼 표시부를 비활성화. 
                                        데이터 취득부는 그대로 유지(변경시 영향범위가 커짐.)
                                        {(data.driverRequestList !== undefined && data.driverRequestList.length) ? (
                                            <button 
                                                type="button" 
                                                className="btn small pressed liner" 
                                                onClick={async (e) => {
                                                    try {
                                                        console.log(data.driverRequestList)
                                                        this.handleChangeModalStatusTrueAddData(data.driverRequestList)
                                                    } catch (e) {
                                                        await calertConfirm('?????')
                                                        this.props.loadingEnd()
                                                    }
        
                                                }}>
                                                <span>파트너선택</span>
                                            </button>
                                        ) : null} */}
                                        
                                    </div>
                                )}

                            </div>
                            <ul className="desc-list">
                                <li className="desc-item">
                                    <div className="tit"><strong>예약날짜</strong></div>
                                    <div className="desc"><strong>{customMoment(data.RevStartDt).format('YYYY-MM-DD')}</strong></div>
                                </li>
                                <li className="desc-item">
                                    <div className="tit"><strong>예약시간</strong></div>
                                    <div className="desc"><strong>{`${customMoment(data.RevStartDt).format('HH:mm')} ~ ${customMoment(data.RevEndDt).format('HH:mm')}`} ({ReserveApi.getTimeDifferenceHourToStr(data.RevStartDt, data.RevEndDt)})</strong></div>
                                </li>
                                <li className="desc-item">
                                    <div className="tit"><span>출발지</span></div>
                                    <div className="desc"><span>{data.StartAddress}</span></div>
                                </li>
                                <li className="desc-item">
                                    <div className="tit"><span>종료지</span></div>
                                    <div className="desc"><span>{data.EndAddress}</span></div>
                                </li>
                                {data.DriverName !== null && (
                                    <li className="desc-item">
                                        <div className="tit"><span>파트너</span></div>
                                        <div className="desc"><span>{data.DriverName}</span></div>
                                    </li>
                                )}
                            </ul>  

                            {isChkMonthlyMosilerCustomer(CustomerCategory) ? (<></>) : (
                                <div className="data-amount">
                                    <ul className="desc-list">
                                        <li className="desc-item">
                                            <div className="amount-tit"><strong>결제금액</strong></div>
                                            <div className="amount-desc price">
                                                {data.ReseveCharge > 1 && (<p><strong className="text-money">{Util.numberWithCommas(String(data.ReseveCharge))}원</strong></p>)}
                                                {data.PrePaidTicketYN === 'Y' && (
                                                    <p><span className="mar-r5">충전권 결제</span><strong className="text-money">{Util.numberWithCommas(String(data.PrePaidTicketAmount))}원</strong></p>   
                                                )}                                            
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            )}
                            
                        </li>
                    </>
                )
            })
        }

        let driverNodeComponent = (arr) => {
            return arr.map((data, idx) => {
                return (
                    <div className="grid cross-center driver-info" style={{alignItems:'center', padding:'10px 10px'}}>
                        <div className="col col-3 driver-photo">
                            <img alt="" src={data.ProfileImgName} onError={(e)=>{e.target.src=icon_user}} onClick={
                                async ()=>{  
                                    calertImagePopup(data.ProfileImgName,'','')
                                }}/>
                        </div>
                        <div className="col col-6 info-box">
                            <div className="grid cross-center">
                                <div className="col text-main"><strong>{data.UserName}</strong> 파트너</div>
                            </div>
                            <div className="grid cross-start">
                                <div className="col rating-box">
                                    <span className="rating-btn"><img src={icon_star} className={""}  /></span>
                                    <span className="rating-btn"><img src={icon_star} className={""}  /></span>
                                    <span className="rating-btn"><img src={icon_star} className={""}  /></span>
                                    <span className="rating-btn"><img src={icon_star} className={""}  /></span>
                                    <span className="rating-btn"><img src={icon_star} className={""}  /></span>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col col-3">
                            <div className="grid cross-center">
                                <button 
                                    type="button" 
                                    className="btn medium primary"
                                    style={{width:'100%'}}
                                    onClick={async ()=>{

                                        let result = await calertConfirm(`현재 선택하신 파트너는\n${data.UserName} 파트너입니다.\n이 파트너로 하시겠습니까?`);
                                        if (result) {
                                            let { CustomerNo } = store.getState().global_data.loginedData
                                            let { cookies } = this.props;
                                            this.props.setDriverSelected(
                                                CustomerNo,
                                                data.DrivingNo,
                                                data.DriverNo,
                                                cookies.get('ostype'),
                                                cookies.get('deviceid'),
                                                cookies.get('login_encrypt')
                                            )
                                            this.handleChangeModalStatusFalse();
                                        }                                
                                    }}>선택</button>
                            </div>
                        </div>
                    </div>
                )
            })
        }

        if (!this.props.loadingMain) return (<>
            <Loading />
            <GlobalStyle />
            <MessageCss />

            <Header
                title="예약현황"
                onBackButton={this.handleBack}
                onHomeButton={this.handleHome}
            />
        </>);
        return (
            <>
                {!this.props.loading ? (<Loading />) : ''}
                <GlobalStyle />
                <MessageCss />

                <Header
                    title="예약현황"
                    onBackButton={this.handleBack}
                    onHomeButton={this.handleHome}
                />
                               
                {this.props.getinfo.length ? (
                    <main id="Contents">
                        <div className="component-wrap">
                            {/*
                            <div class="title-group title-group-inner bg-gray">
                                <h1 class="head-title">타이틀</h1>
                                <div class="sub-area">
                                    <div class="btn-group">
                                        <button type="button" class="btn text">버튼</button>
                                    </div>
                                </div>
                            </div>
                            <div class="text-group text-group-inner bg-gray">
                                <p class="text-medium">배차 전 예약상태를 확인 하실 수 있습니다.</p>
                            </div> 
                            */}
                            {isChkMonthlyMosilerCustomer(store.getState().global_data.loginedData.CustomerCategory) ? <></> : (
                                <div style={{ padding: '15px 15px' }}>
                                    <NavLink to="#" className="box booking" onClick={async (e) => {
                                        e.preventDefault();
                                        let token = getUserInfoEncrypt({
                                            UserName: store.getState().global_data.loginedData.CustomerName,
                                            UserPhone: store.getState().global_data.loginedData.PhoneNumber,
                                            UserEmail: store.getState().global_data.loginedData.Email,
                                        })
                                        let linkAddress = 'http://mosiler.com/annual/introduce?inflow=app_annual_usestate&token=' + token
                                        let result = await RnInterface.callReactNative(`NewIntentLinkBrowsing${linkAddress}`)
                                        // PC 환경일때는
                                        if (!result) {
                                            window.open(linkAddress, '_blank')
                                        }
                                    }}>
                                        <div className='monthly-container'>
                                            <div className='sub-02-main' style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                                                {/* <div style={{ fontWeight: 'bold', paddingBottom: '15px' }}>월간 모시러 가입하고 <span style={{ color: '#e2cb24' }}>최대 34% 할인</span></div>
                                            <div style={{ fontWeight: 'bold', fontSize: '0.9em' }}>더 알아보기</div> */}
                                                {/* mm_bg_header */}
                                                <div style={{ fontWeight: 'bold', paddingBottom: '5px' }}>월 <span style={{ color: '#e2cb24' }}>50만원</span> 으로</div>
                                                <div style={{ fontWeight: 'bold', paddingBottom: '5px' }}>서비스를 이용해보세요</div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            )}
                            

                            <div className="count-filter-group grid cross-center">
                                <div className="col left count-area">총 <strong className="text-prime">{this.props.totalPage}</strong>건</div>
                                <div className="col right"></div>  
                            </div> 
                        
                            <div className="data-list-container">
                                <ul className="data-list">
                                    {reserveListNew(this.props.getinfo)}
                                </ul>
                            </div>
                        </div>  
                        
                        
                        
                    </main>
                ) : (
                    <div className="message_box">
                        <span className="icon_box"><img src={icon_calendar} className="icon_check" alt="" /></span>
                        <span className="TXST_t1"><b>예약하신 내역</b>이 없습니다.</span>
                    </div>
                )}

                <Modal
                    isOpen={this.state.modalOpenFlag}
                    onRequestClose={this.handleChangeModalStatusFalse}
                    contentLabel="Example Modal" 
                    overlayClassName="modal-overlay"
                    className="modal-box-top"
                    bodyOpenClassName="modal-scrolllock"
                >
                    <section className="modal-container">
                        <div className="modal">
                            <div className="modal-head">
                                <h1 className="modal-tit" tabindex="0">파트너 선택</h1>
                                <button className="btn-top-close" onClick={this.handleChangeModalStatusFalse}>
                                    <span className="blind">팝업 닫기</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div class="text-group text-group-inner bg-gray">
                                    <p class="text-sub text-gray">{this.state.drivReqTmpData.length}명의 파트너가 고객님과 운행하길 원합니다.</p>
                                    <p class="text-sub text-gray">원하는 파트너를 선택해주세요.</p>
                                    {/* <p class="text-sub text-gray">시간이 지나면 무작위로 파트너가 배정됩니다.()</p> */}
                                </div>
                                <div  style={{
                                    height:'50vh',
                                    overflow:'auto'
                                    }}>
                                    {driverNodeComponent(this.state.drivReqTmpData)}
                                </div>                               
                            </div>
                        </div>
                    </section>
                </Modal>


{/* 
                <div className="record hd_margin_top" style={{ background: this.props.getinfo.length ? '#ebebed' : '#fff' }}>
                <ul>
                    {this.props.getinfo.length ? (
                        reserveList(this.props.getinfo)
                    ) : (
                        <div className="message_box">
                            <span className="icon_box"><img src={icon_calendar} className="icon_check" alt="" /></span>
                            <span className="TXST_t1"><b>예약하신 내역</b>이 없습니다.</span>
                        </div>
                    )}
                </ul>
                </div> */}
            </>
        );
    }
};

const mapStateToProps = (state) => ({
    getinfo: state.UseState_ReduxModule.getinfo,
    page: state.UseState_ReduxModule.page,
    loading: state.UseState_ReduxModule.loading,
    loadingMain: state.UseState_ReduxModule.loadingMain,
    scrollDataSyncFlag: state.UseState_ReduxModule.scrollDataSyncFlag,
    defaultCardInfo: state.UseState_ReduxModule.defaultCardInfo,
    totalPage: state.UseState_ReduxModule.totalPage,
    getDriverReqList: state.UseState_ReduxModule.getDriverReqList,
});

const mapDispatchToProps = (dispatch) => ({
    initEmptyReserveData: () => dispatch(empty_use_state_service()),
    initPage: ()=> dispatch(init_page_user_state_service()),
    initReserveData: (cn, page, ostype, deviceid, loginEncrypt) => dispatch(getReservationListRedux(cn, page, ostype, deviceid, loginEncrypt)),
    getPagingData: (cn, page, ostype, deviceid, loginEncrypt) => dispatch(getReservationListPagingRedux(cn, page, ostype, deviceid, loginEncrypt)),
    delReserveData: (cn, no, data, carddata, ostype, deviceid, loginEncrypt) => dispatch(cancelReservationRedux(cn, no, data, carddata, ostype, deviceid, loginEncrypt)),
    setDriverSelected: (cn, drivingNo, driverNo, ostype, deviceid, loginEncrypt) => dispatch(setDriverSelectedRedux(cn, drivingNo, driverNo, ostype, deviceid, loginEncrypt)),
    
    loadingStart : () => dispatch(loading_only_use_state_service()),
    loadingEnd : () => dispatch(loading_end_only_use_state_service())

    // decreaseStoreCount: () => dispatch(actions.decreaseCount()),
    // increaseCountAsyncThunk: () => dispatch(actions.increaseCountAsyncThunk()),
    // decreaseCountAsyncThunk: () => dispatch(actions.decreaseCountAsyncThunk()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLastLocation(withCookies(UseStateView)));



