import React, { Component } from "react";
import { withCookies } from "react-cookie";
import Header from "../../components/Common/Header";
import Terms from "../../constantData";
import Iframe from "react-iframe";
import { createGlobalStyle } from "styled-components";
import RnInterface from "../../lib/RnInterface";
import store from '../../reduxStore'
import { calertPromise } from '../../lib/Util'
import "../../css/default.css";
import "../../css/member.css";
import { USER_VOICE_URL } from '../../constantData'

// todocss
const GlobalStyle = createGlobalStyle`
.appsettingview_container { display:flex; width:100%; height:100vh; }
.iframe_area { width:100%; height:100%; border:0px;}
.iframe_area::-webkit-scrollbar {
    width:5px;
}

.iframe_area::-webkit-scrollbar-thumb {
    background-color: #2f3542;
    border-radius: 15px;
  }
`;

// 2021/05/17 parent와 child(iframe)간의 통신처리가 필요하다.
//

    

class VoiceOfCustomerNativeOnlyView extends Component {
    async componentDidMount() {
        try {
            await RnInterface.AndroidURLChange(this.props.location.pathname);
            const { CustomerNo, CustomerName,  PhoneNumber } = store.getState().global_data.loginedData;

            if (CustomerNo === undefined) {
                await calertPromise('현재 로그인 되어있지 않습니다.\n고객의 소리를 남기고 싶다면 로그인 후 메뉴 > 고객의소리로 들어가주세요.')
                await RnInterface.callReactNative('VoiceOfCustomerLoginFailedFlag')
            }
            
            // 메시지 수신 받는 eventListener 등록
            window.addEventListener( 'message', this.receiveMsgFromChild );
       
        } catch (e) {
            console.log(e);
        }
    }

    // 자식으로부터 메시지 수신
    receiveMsgFromChild = async (e) => {
        try {
            if (e.data && e.data.indexOf('USER_VOICE||SUCCESS') !== -1) {
                // this.props.history.goBack();
                await RnInterface.callReactNative('VoiceOfCustomerClose')
            }
        }
        catch(e) {
            console.log(e)
            await RnInterface.callReactNative('VoiceOfCustomerClose')
        }
    }

    componentWillUnmount () {
        window.removeEventListener("message", this.receiveMsgFromChild);
    }

    handleBack = async () => {
        await RnInterface.callReactNative('VoiceOfCustomerClose')
    };

    handleHome = () => {
        this.props.history.replace("/main");
    };

    render() {
        const { CustomerName,  PhoneNumber } = store.getState().global_data.loginedData;
        const url = `${USER_VOICE_URL}?name=${CustomerName}&phone=${PhoneNumber}`;
        return (
            <>
                <GlobalStyle />
                <Header
                    title="고객의 소리"
                    onBackButton={this.handleBack}
                />

                <div className="appsettingview_container hd_margin_top">

                    <Iframe 
                        url={url} 
                        id="iframe_id" 
                        className="iframe_area " />
                </div>
            </>
        );
    }
}

export default withCookies(VoiceOfCustomerNativeOnlyView);
