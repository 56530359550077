import { MAX_COOKIE_DATE } from '../constantData'

const CookieApi = {};

CookieApi.SettingCookie = (cookies, dataObj, chkStatus = 1) => {
    const expires = new Date()
    expires.setDate(expires.getDate() + MAX_COOKIE_DATE)

    // chkStatus가 true면 90일까지 자동로그인 한다.
    if (chkStatus) {
        cookies.set('email_id',         dataObj.UserId,         { path: '/', expires });
        cookies.set('account_type',     dataObj.AccountType,    { path: '/', expires });
        cookies.set('hashcode',         dataObj.hash,           { path: '/', expires });
        cookies.set('login_encrypt',    dataObj.LoginEncrypt,   { path: '/', expires });
        cookies.set('ostype',           dataObj.OsType,         { path: '/', expires });
        cookies.set('deviceid',         dataObj.DeviceId,       { path: '/', expires });
        cookies.set('auto_login_chk',   1,                      { path: '/', expires });
    }
    // chkStatus가 false면 세션이 유지될때까지 로그인되고, 완전 종료될때는 소멸된다.
    else {
        cookies.set('email_id',         dataObj.UserId,         { path: '/' });
        cookies.set('account_type',     dataObj.AccountType,    { path: '/' });
        cookies.set('hashcode',         dataObj.hash,           { path: '/' });
        cookies.set('login_encrypt',    dataObj.LoginEncrypt,   { path: '/' });
        cookies.set('ostype',           dataObj.OsType,         { path: '/' });
        cookies.set('deviceid',         dataObj.DeviceId,       { path: '/' });
        cookies.set('auto_login_chk',   0,                      { path: '/' });
    }
}

CookieApi.RemoveCookie = (cookies) => {
    for (let i in cookies.getAll()) {
        cookies.remove(i);
    }
}



export default CookieApi;