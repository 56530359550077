import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import '../../css/default.css';
import '../../css/booking.css';

import btn_menu from '../../img/btn/btn_menu.svg'
import btn_home from '../../img/btn/btn_home.svg'

class HeaderMain extends Component {

    render() {
        return (
            <>
                <header id="Header" className="">
                    <button type="button" className="btn-all-menu" onClick={()=>{
                            this.props.onBackButton()
                        }}>
                        <span className="blind">이전화면가기</span>
                    </button>
                    {this.props.onHomeButton !== undefined && (
                        <button type="button" className="btn-top-home"  onClick={(e)=>{
                            e.preventDefault();
                            this.props.onHomeButton()
                        }}>
                            <span className="blind">홈화면가기</span>
                        </button>
                    )}
                    <h1>{this.props.title}</h1>
                </header>  
            </>
        );
    }
};

export default HeaderMain;
