import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import '../../css/default.css';
import '../../css/booking.css';


import btn_prev from '../../img/btn/btn_prev.svg'
import btn_home from '../../img/btn/btn_home.svg'

class HeaderJoin extends Component {

    render() {
        return (<>
            <div className="head" style={{background:'#f3f4f8'}}>
                <ul className="type1 type1_mt5">
                    <li className="btn">
                        
                    </li>
                    <li className="head_title"></li>
                    
                </ul>
            </div>
            
        </>
        );
    }
};

export default HeaderJoin;
