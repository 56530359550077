import React, { Component } from "react";
import TicketApi from "../../lib/TicketApi";
import { withCookies } from "react-cookie";
import Header from "../../components/Common/Header";
import Loading from "../../components/Common/Loading";
import { calert, calertPromise, isChkResultSuccess } from "../../lib/Util";
import TicketCss from "../../css/CustomGlobalStyle/Ticket";
import store from "../../reduxStore";
import moment from "moment";
import RnInterface from "../../lib/RnInterface";
import ReserveApi from "../../lib/ReserveApi";


import Util from "../../lib/Util";

import "../../css/default.css";
import "../../css/app.ui.css";
import icon_money from "../../img/ticket/icon_money.png";
import icon_notice from "../../img/coupon/icon_notice.svg";

const TICKET_ALL_LIST = 0; // 이용 가능
const TICKET_USE_LIST = 1; // 이용 완료

const SUB_TICKET_LIST_DETAIL_USE = 0; // 이용 기록 > 사용 내역
const SUB_TICKET_LIST_DETAIL_CHARGE = 1; // 이용 기록 > 충전 내역

const MAX_ELEMENT = 20;

const MAN_WON = 10000;

class TicketView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: 0, // 로딩
            ticketList: [], // 이용권 리스트
            remain: 0, // 이용 가능 금액

            viewState: TICKET_ALL_LIST, // 충전권 탭을 눌렀는지 이용내역 탭을 눌렀는지 여부
            ticketListDetailState: SUB_TICKET_LIST_DETAIL_USE, // 이용내역 > "사용내역"을 눌렀는지 "충전내역"을 눌렀는지

            useList: [], // 이용내역 > 사용내역 리스트
            payList: [], // 이용내역 > 충전내역 리스트

            monthRange: 1, // 이용내역 > 개월 선택

            usePage: 1, // 이용내역 > 사용내역 페이징
            payPage: 1, // 이용내역 > 충전내역 페이징

            usePageEnd: false, // 이용내역 > 사용내역 로딩 데이터 있는지 여부. 데이터 없으면 true가 된다.
            payPageEnd: false, // 이용내역 > 충전내역 로딩 데이터 있는지 여부. 데이터 없으면 true가 된다.

            ticketCheckedIdx: -1, // 충전권 > 충전권 상품 목록에서 어떤 상품을 선택했는지의 해당 상품의 ID값
            topBtnVisible: false, // 위로 가기 아이콘 표시 여부

            cardFlag : false,        // 카드가 등록 되어있는지 체크 true : 카드 등록 / false : 카드 미등록.
        };
    }

    scrollDataSyncFlagUse = 1; // 스크롤 로딩시 싱크 맞추기 위한 변수(이용)
    scrollDataSyncFlagPay = 1;

    // setState 싱크처리
    setStateSync = (data) => {
        return new Promise((resolve) => {
            this.setState(data, () => {
                resolve(true);
            });
        });
    };

    // 현재 카드가 등록되어 있는지 체크
    getCustomerCard = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const { cookies } = this.props;
                let { CustomerNo } = store.getState().global_data.loginedData
                let defaultPaymentCardData = await ReserveApi.GetBasicPaymentCards(
                    CustomerNo,
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                )

                // 기본카드가 있을 경우
                if (defaultPaymentCardData.Item && 
                    defaultPaymentCardData.Result === 'success') {
                    resolve(true)
                }
                // 기본카드가 없을 경우
                else {
                    resolve(false)
                }
            }
            catch (e) {
                return reject(e);
            }
        })
    }

    async componentDidMount() {
        const { cookies } = this.props;
        const { CustomerNo } = store.getState().global_data.loginedData;
        

        // 스크롤 이벤트 적용
        window.addEventListener("scroll", this.onScroll);

        try {
            await RnInterface.AndroidURLChange(this.props.location.pathname);           
            let tmpCardData = await this.getCustomerCard();
            
            // 충전권 상품 리스트 취득
            let tmpPaidTicketList = await TicketApi.GetPrePaidTicketList(
                cookies.get("ostype"),
                cookies.get("deviceid"),
                cookies.get("login_encrypt")
            );

            // 이용 가능 금액 취득
            let tmpRemainTicketListResult =
                await TicketApi.GetCustomerRemainTicket(
                    CustomerNo,
                    cookies.get("ostype"),
                    cookies.get("deviceid"),
                    cookies.get("login_encrypt")
                );

            // 충전권 상품 리스트 취득 성공시
            if (isChkResultSuccess(tmpPaidTicketList)) {
                await this.setStateSync({
                    ticketList: tmpPaidTicketList.Item,
                    ticketCheckedIdx: tmpPaidTicketList.Item[0].Id,
                });
            }

            // 이용 가능 금액 취득 성공시
            if (isChkResultSuccess(tmpRemainTicketListResult)) {
                await this.setStateSync({
                    remain: tmpRemainTicketListResult.Item.RemainAmount,
                });
            }

            // 로딩 화면 비표시.
            await this.setStateSync({
                cardFlag: tmpCardData,
                loading: 1,
            });
            
        } catch (e) {
            await calertPromise(
                "충전권 상품 로딩중 문제가 발생했습니다. 관리자에게 문의해주세요."
            );
            this.setState({
                loading: 1,
            });
        }
    }

    componentWillUnmount() {
        // component를 닫게 되면 스크롤 이벤트도 닫아줘야한다.
        window.removeEventListener("scroll", this.onScroll);
    }

    // 스크롤 로딩
    onScroll = async (e) => {
        const { innerHeight } = window;
        const { scrollHeight } = document.body;
        const myScroll = e.srcElement.scrollingElement.scrollTop;
        const {
            viewState,
            ticketListDetailState,
            useList,
            payList,
            usePage,
            monthRange,
            payPage,
            usePageEnd,
            payPageEnd,
        } = this.state;

        this.toggleVisibility();

        // 이용내역 탭으로 이동되었다면?
        if (viewState === TICKET_USE_LIST) {
            // 현재 사용내역 화면이라면?
            if (ticketListDetailState === SUB_TICKET_LIST_DETAIL_USE) {
                if (useList.length >= MAX_ELEMENT) {
                    // 스크롤 체크를 실시한다.
                    if (innerHeight + myScroll >= scrollHeight - 1) {
                        // scrollDataSyncFlag : 데이터 싱크를 위한 flag이다. 초기값은 1

                        if (this.scrollDataSyncFlagUse === 1) {
                            if (!usePageEnd) {
                                let { CustomerNo } =
                                    store.getState().global_data.loginedData;
                                let { cookies } = this.props;
                                this.scrollDataSyncFlagUse = 0;

                                try {
                                    this.setState({ loading: 0 });
                                    let tmp =
                                        await TicketApi.GetCustomerUseTicketList(
                                            CustomerNo,
                                            usePage,
                                            MAX_ELEMENT,
                                            monthRange,
                                            cookies.get("ostype"),
                                            cookies.get("deviceid"),
                                            cookies.get("login_encrypt")
                                        );
                                    if (tmp.Result === "success") {
                                        this.setState(
                                            (prevState) => ({
                                                useList: [
                                                    ...prevState.useList,
                                                    ...tmp.ItemList,
                                                ],
                                                usePage: prevState.usePage + 1,
                                                loading: 1,
                                                usePageEnd: tmp.ItemList.length
                                                    ? false
                                                    : true,
                                            }),
                                            () => {
                                                this.scrollDataSyncFlagUse = 1;
                                            }
                                        );
                                    }
                                } catch (e) {
                                    await calertPromise(
                                        "데이터 취득 중에 오류가 발생했습니다. 관리자에게 문의해주세요."
                                    );
                                    this.setState({ loading: 1 }); // 로딩완료
                                }
                            }
                        }
                    }
                }
            } else {
                if (payList.length >= MAX_ELEMENT) {
                    // 스크롤 체크를 실시한다.
                    if (innerHeight + myScroll >= scrollHeight - 1) {
                        if (this.scrollDataSyncFlagPay === 1) {
                            if (!payPageEnd) {
                                let { CustomerNo } =
                                    store.getState().global_data.loginedData;
                                let { cookies } = this.props;
                                this.scrollDataSyncFlagPay = 0;

                                try {
                                    this.setState({ loading: 0 });
                                    let tmp =
                                        await TicketApi.GetCustomerPayTicketList(
                                            CustomerNo,
                                            payPage,
                                            MAX_ELEMENT,
                                            monthRange,
                                            cookies.get("ostype"),
                                            cookies.get("deviceid"),
                                            cookies.get("login_encrypt")
                                        );
                                    if (tmp.Result === "success") {
                                        this.setState(
                                            (prevState) => ({
                                                payList: [
                                                    ...prevState.payList,
                                                    ...tmp.ItemList,
                                                ],
                                                payPage: prevState.payPage + 1,
                                                loading: 1,
                                                payPageEnd: tmp.ItemList.length
                                                    ? false
                                                    : true,
                                            }),
                                            () => {
                                                this.scrollDataSyncFlagPay = 1;
                                            }
                                        );
                                    }
                                } catch (e) {
                                    await calertPromise(
                                        "데이터 취득 중에 오류가 발생했습니다. 관리자에게 문의해주세요."
                                    );
                                    this.setState({ loading: 1 }); // 로딩완료
                                }
                            }
                        }
                    }
                }
            }
        }
    };

    // 사용내역을 눌렀는지 충전내역을 눌렀는지를 파악하여 해당 리스트를 갱신한다.
    getUseChargeUpdateList = () => {
        return new Promise(async (resolve, reject) => {
            const { cookies } = this.props;
            const { CustomerNo } = store.getState().global_data.loginedData;
            const { monthRange, usePage, payPage } = this.state;
            this.setState({ loading: 0 });

            try {
                // 사용내역
                if ( 
                    this.state.ticketListDetailState ===
                    SUB_TICKET_LIST_DETAIL_USE
                ) {
                    let tmp = await TicketApi.GetCustomerUseTicketList(
                        CustomerNo,
                        usePage,
                        MAX_ELEMENT,
                        monthRange,
                        cookies.get("ostype"),
                        cookies.get("deviceid"),
                        cookies.get("login_encrypt")
                    );

                    if (tmp.Result === "success") {
                        this.setState(
                            (prevState) => ({
                                useList: tmp.ItemList,
                                usePage: prevState.usePage + 1,
                                loading: 1,
                            }),
                            () => {
                                resolve(true);
                            }
                        ); // 로딩완료
                    } else {
                        await calertPromise(tmp.Message);
                        this.setState({ loading: 1 }, () => {
                            resolve(true);
                        }); // 로딩완료
                    }
                }
                // 충전내역
                else {
                    let tmp = await TicketApi.GetCustomerPayTicketList(
                        CustomerNo,
                        payPage,
                        MAX_ELEMENT,
                        monthRange,
                        cookies.get("ostype"),
                        cookies.get("deviceid"),
                        cookies.get("login_encrypt")
                    );

                    if (tmp.Result === "success") {
                        this.setState(
                            (prevState) => ({
                                payList: tmp.ItemList,
                                payPage: prevState.payPage + 1,
                                loading: 1,
                            }),
                            () => {
                                resolve(true);
                            }
                        ); // 로딩완료
                    } else {
                        await calertPromise(tmp.Message);
                        this.setState({ loading: 1 }, () => {
                            resolve(true);
                        }); // 로딩완료
                    }
                }
            } catch (e) {
                await calertPromise(
                    "데이터 취득 중에 오류가 발생했습니다. 관리자에게 문의해주세요."
                );
                this.setState({ loading: 1 }); // 로딩완료
                return reject(e);
            }
        });
    };

    handleChangeSelectMonth = (e) => {
        this.setState(
            {
                monthRange: e.target.value,
                payPage: 1,
                usePage: 1,
                usePageEnd: false,
                payPageEnd: false,
            },
            async () => {
                await this.getUseChargeUpdateList();
            }
        );
    };

    handleBack = () => {
        this.props.history.replace("/main");
    };

    handleHome = () => {
        this.props.history.replace("/main");
    };

    handleChangeViewState = (params) => {
        // 이용내역을 클릭했다면 사용내역,충전내역에 따라
        this.setState({ viewState: params });
    };

    handleTicketNext = async () => {
        const { ticketList, ticketCheckedIdx, cardFlag } = this.state;
        if (cardFlag) {
            this.props.history.push({
                pathname: "/ticket_buying",
                state: {
                    selectedData: ticketList.filter((data) => {
                        return data.Id === ticketCheckedIdx;
                    })[0],
                    ticketList,
                },
            });
        } 
        // 카드 등록이 되어있지 않다면
        else {
            await calertPromise('현재 카드 등록이 되어있지 않습니다.\n메뉴 > 카드관리 화면에서\n새 카드를 등록해주세요.')
        }
    };

    toggleVisibility = () => {
        if (window.pageYOffset > 96) {
            this.setState({ topBtnVisible: true });
        } else {
            this.setState({ topBtnVisible: false });
        }
    };

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    render() {
        const {
            viewState,
            ticketList,
            remain,
            ticketListDetailState,
            useList,
            payList,
            topBtnVisible,
        } = this.state;

        let tmpComponentUseTicketList = (arr) => {
            if (!arr.length) 
                return (<div class="no-data"><p class="text-main">사용 내역이 없습니다.</p></div>)
            return arr.map((data, idx) => (
                <li className="data-list-item">
                    <div className="data-header">
                        {data.UseStatus === 1 ? (
                            <div className="desc-sum">
                                <span className="state-txt state-cancel">사용취소</span>
                                <span className="text-import">{moment(data.UpdateDt).format("YYYY-MM-DD")} {moment(data.UpdateDt).format("HH:mm")}</span>
                            </div>
                        ) : null}
                        <div className="desc-title" onClick="">
                            <strong>사용</strong>  
                            <span className="desc-day">{moment(data.RegDt).format("YYYY-MM-DD")} {moment(data.RegDt).format("HH:mm")}</span>                                                           
                        </div>
                        {/*
                        <div class="btn-area">
                            <button type="button" class="btn small secondary"><span>예약취소</span></button>
                        </div>
                        */}
                    </div>  
                    <ul className="desc-list">
                        <li className="desc-item">
                            <div className="tit"><span>사용금액</span></div>
                            <div className="desc"><strong>{Util.numberWithCommas(String(data.UseAmount))}원</strong></div>
                        </li>
                        <li className="desc-item">
                            <div className="tit"><span>출발지</span></div>
                            <div className="desc"><span>{data.StartAddress}</span></div>
                        </li>    
                        <li className="desc-item">
                            <div className="tit"><span>종료지</span></div>
                            <div className="desc"><span>{data.EndAddress}</span></div>
                        </li>    
                        <li className="desc-item">
                            <div className="tit"><span>파트너</span></div>
                            <div className="desc"><span>{data.DriverName}</span></div>
                        </li>
                    </ul>
                </li>     

            ));
        };

        let tmpComponentBuyTicketList = (arr) => {
            if (!arr.length) 
                return (<div class="no-data"><p class="text-main">충전 내역이 없습니다.</p></div>)
            return arr.map((data, idx) => (

                <li className="data-list-item">
                    <div className="data-header">

                        {data.PayStatus === 1 ? (
                            <div className="desc-sum">
                                <span className="state-txt state-cancel">환불완료</span>
                                <span className="text-import">{moment(data.UpdateDt).format("YYYY-MM-DD")} {moment(data.UpdateDt).format("HH:mm")}</span>
                            </div>
                        ) : null}

                        
                        <div className="desc-title" onClick="">
                            <strong className="text-prime">충전</strong> 
                            <span className="desc-day">{moment(data.RegDt).format("YYYY-MM-DD")} {moment(data.RegDt).format("HH:mm")}</span>                                                
                        </div>
                        {/*
                        <div class="btn-area">
                            <button type="button" class="btn small secondary"><span>예약취소</span></button>
                        </div>
                        */}
                    </div>  
                    <ul className="desc-list">
                        <li className="desc-item">
                            <div className="tit"><span>결제금액</span></div>
                            <div className="desc">
                                <strong className="text-money">{Util.numberWithCommas(String(data.Amount))}원</strong>
                            </div>
                        </li>
                        <li className="desc-item">
                            <div className="tit"><span>충전금액</span></div>
                            <div className="desc">
                                <strong className="text-prime text-money">{Util.numberWithCommas(String(data.ReturnAmount))}원</strong>
                                {/* <span>({data.Name} 충전권)</span> */}
                            </div>
                        </li>
                        <li className="desc-item">
                            <div className="tit"><span>결제정보</span></div>
                            <div className="desc">
                                <span>신용카드<span className="masking">{data.CardNo}</span></span>
                            </div>
                        </li>                                                                                                                                                  
                    </ul>                                     
                </li>                      


















                // <div className="inner-container space-top">
                //     <ul className="list ticket">
                //         <li className="item">
                //             <div className="grid">
                //                 <div className="col col-3">
                //                     <b className="text-main text-primary">
                //                         충전
                //                     </b>
                //                 </div>
                //                 <div className="col col-9">
                //                     <span>
                //                         {moment(data.RegDt).format("YYYY-MM-DD")}
                //                     </span>
                //                     <span className="form-bar">|</span>
                //                     <span>
                //                         {moment(data.RegDt).format("HH:mm")}
                //                     </span>
                //                     {data.PayStatus === 1 ? (
                //                         <div className="col right">
                //                             <strong className="text-import">
                //                                 취소
                //                             </strong>
                //                         </div>
                //                     ) : null}
                //                 </div>
                //             </div>
                //         </li>
                //         <li className="item">
                //             <div className="item-row grid">
                //                 <div className="col col-3">
                //                     <strong className="text-gray">
                //                         충전금액
                //                     </strong>
                //                 </div>
                //                 <div className="col col-9">
                //                     <strong className="text-money text-primary">
                //                         {Util.numberWithCommas(String(data.Amount))}
                //                         원
                //                     </strong>
                //                     <span className="mar-l5">
                //                         ({data.Name} 이용권)
                //                     </span>
                //                 </div>
                //             </div>
                //             <div className="item-row grid">
                //                 <div className="col col-3">
                //                     <strong className="text-gray">
                //                         결제정보
                //                     </strong>
                //                 </div>
                //                 <div className="col col-9">
                //                     <span>신용카드 {data.CardNo}</span>
                //                 </div>
                //             </div>
                //         </li>
                //     </ul>
                // </div>

                
            ));
        };

        let tmpTicketList = (arr) =>
            arr.map((data, idx) => {
                if (!Number(data.Amount)) return (<></>)
                return (
                    <div 
                        className={ this.state.ticketCheckedIdx === data.Id ? "box ticket checked" : "box ticket" }
                        onClick={() => {
                            this.setState({ ticketCheckedIdx: data.Id });
                        }}
                    >
                        <div className="box-inner">
                            <h3 className="ticket-title"><span className="text-bold">{data.Name}</span> 충전권</h3>
                            {/*                             
                            {data.AddPercentAmount <= 0 ? (
                                <>
                                    <div className="ticket-money">
                                        <span className="text-primary">
                                            {Util.numberWithCommas(String(data.Amount))}원
                                        </span>
                                    </div>
                                </>    
                            ) : (
                            <>
                                <div className="ticket-money">
                                    <span className="text-through">
                                        {Util.numberWithCommas(String(data.Amount))}원
                                    </span>
                                    <i class="form-arw">→</i>
                                    <span className="text-primary">
                                        {Util.numberWithCommas( String( data.Amount * (data.AddPercentAmount / 100) + data.Amount ) )} 원
                                    </span>
                                </div>
                                <p className="ticket-plus text-darkorange">
                                    ({data.AddPercentAmount}% 증액)
                                </p>
                            </>    
                            )} */}
                            
                        </div>
                    </div>
                )
            });

        return (
            <>
                {!this.state.loading ? <Loading /> : ""}

                <Header
                    title="충전권 구매"
                    onBackButton={this.handleBack}
                    onHomeButton={this.handleHome}
                />

                <main id="Contents" className="section-ticketview">
                    <div className="box ticket-info">
                        <div className="money-box grid cross-center">
                            <div className="col">
                                <i className="ico ico-money"></i>
                            </div>
                            <div className="col">
                                <span>이용가능금액</span>
                            </div>
                            <div className="col right">
                                <b className="text-largest">
                                    {Util.numberWithCommas(String(remain))}
                                </b>
                            </div>

                            <div className="col">
                                <span>원</span>
                            </div>
                        </div>
                        <br/>
                        {/* <div className="money-box grid cross-center" style={{wordBreak:'keep-all', background:'#d0d0d0', color:'#5e5e5e', border: '1px solid #d0d0d0'}}> */}
                        <div className="money-box grid cross-center" style={{wordBreak:'keep-all', background:'#efefef', color:'black', border: '1px solid #d0d0d0'}}>
                            <span>충전권을 통해 모시러 서비스를 편하게 이용해 보세요!</span>
                            <span>서비스 이용 요금 및 주차비, 주유비 등 기타 비용을 충전권을 통해 자동 차감 후 리포트를 공유드립니다.</span>
                        </div>
                    </div>
                    <div className="tabs-container">
                        <div className="tabs-nav">
                            <ul className="tabs-group">
                                <li style={{ width: "50%" }}>
                                    <button
                                        className={
                                            viewState === TICKET_ALL_LIST
                                                ? "tab tab-on"
                                                : "tab"
                                        }
                                        onClick={() => {
                                            this.setState({
                                                payPage: 1,
                                                usePage: 1,
                                                usePageEnd: false,
                                                payPageEnd: false,
                                            });
                                            this.handleChangeViewState(
                                                TICKET_ALL_LIST
                                            );
                                        }}
                                    >
                                        충전권
                                    </button>
                                </li>
                                <li style={{ width: "50%" }}>
                                    <button
                                        className={
                                            viewState === TICKET_USE_LIST
                                                ? "tab tab-on"
                                                : "tab"
                                        }
                                        onClick={async () => {
                                            this.handleChangeViewState(
                                                TICKET_USE_LIST
                                            );
                                            this.setState({
                                                payPage: 1,
                                                usePage: 1,
                                                usePageEnd: false,
                                                payPageEnd: false,
                                            }, async ()=>{
                                                await this.getUseChargeUpdateList();
                                            });
                                            
                                            
                                        }}
                                    >
                                        이용내역
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div class="tabs-content">
                            {/* 전체 선택시 */}
                            {viewState === TICKET_ALL_LIST ? (
                                <>
                                    <div className="inner-container">
                                        {tmpTicketList(ticketList)}
                                    </div>
                                    <div className="component-wrap space-top">
                                        <section className="information-text on">
                                            <h1 className="information-head">
                                                <button
                                                    type="button"
                                                    className="btn-viewmore"
                                                >
                                                    <i className="ico ico-info"></i>
                                                    꼭 확인해주세요.
                                                    <i className="icon arrow"></i>
                                                </button>
                                            </h1>
                                            <div className="information-body">
                                                <div className="info-desc">
                                                    <h3 className="info-stit">
                                                        이용안내
                                                    </h3>
                                                    <ul className="text-sub">
                                                        <li className="text-import">
                                                            충전권 이용이 100% 드라이버 매칭을 보장하지 않기에 모시러 컨시어지를 통해 사전 문의를 권장드립니다.
                                                        </li>
                                                        <li>
                                                            실제 사용금액으로
                                                            차감됩니다.
                                                        </li>
                                                        <li className="text-darkblue">
                                                            이용금액을 초과할
                                                            경우, 초과된
                                                            금액만큼의 비용은
                                                            재충전 또는
                                                            카드결제가
                                                            가능합니다.
                                                        </li>
                                                        <li className="text-import">
                                                            쿠폰과 충전권 중복
                                                            사용 불가합니다.
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="info-desc">
                                                    <h3 className="info-stit">
                                                        환불안내
                                                    </h3>
                                                    <ul className="text-sub">
                                                        <li>
                                                            앱내에서 직접 취소가
                                                            불가하오니, 모시러 컨시어지로 문의해
                                                            주시기 바랍니다.
                                                        </li>
                                                        <li>
                                                            미사용시 :
                                                            결제금액의 100%
                                                            환불(단, 7일 이내)
                                                        </li>
                                                        <li>
                                                            사용시 : 결제금액의
                                                            10% 환불수수료 +
                                                            사용금액과 기간에
                                                            따른 추가수수료를
                                                            공제한 나머지 환불
                                                        </li>
                                                        <li>
                                                            환불방식은 모시러 컨시어지로
                                                            문의해주시기
                                                            바랍니다.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="count-filter-group grid cross-center">

                                        <div className="col col-6">
                                            <ul className="input-tab-style-wrap">
                                                <li className="option-box" onClick={async () => {
                                                    this.setState(
                                                        {
                                                            payPage: 1,
                                                            usePage: 1,
                                                            usePageEnd: false,
                                                            payPageEnd: false,
                                                            ticketListDetailState:
                                                                SUB_TICKET_LIST_DETAIL_USE,
                                                        },
                                                        async () => {
                                                            await this.getUseChargeUpdateList();
                                                        }
                                                    );
                                                }}>
                                                    <input type="radio" name="option2" id="option20" value="N" checked={ticketListDetailState === SUB_TICKET_LIST_DETAIL_USE} />
                                                    <label for="option20" className="tit"><span>사용내역</span></label>
                                                </li>
                                                <li className="option-box" onClick={async () => {
                                                    this.setState(
                                                        {
                                                            payPage: 1,
                                                            usePage: 1,
                                                            usePageEnd: false,
                                                            payPageEnd: false,
                                                            ticketListDetailState:
                                                                SUB_TICKET_LIST_DETAIL_CHARGE,
                                                        },
                                                        async () => {
                                                            await this.getUseChargeUpdateList();
                                                        }
                                                    );
                                                }}>
                                                    <input type="radio" name="option2" id="option21" value="Y" checked={ticketListDetailState === SUB_TICKET_LIST_DETAIL_CHARGE} />
                                                    <label for="option21" className="tit"><span>충전내역</span></label>
                                                </li>
                                            </ul> 
                                        </div>                                     
                                        <div className="col right"></div>                                   






{/* 
                                        <div className="col col-3">
                                            <select
                                                class="select type1 W100"
                                                value={this.state.monthRange}
                                                onChange={
                                                    this.handleChangeSelectMonth
                                                }
                                            >
                                                <option value="1" title="3개월">3개월</option>
                                                <option value="2" title="6개월">6개월</option>
                                                <option value="3" title="9개월">9개월</option>
                                                <option value="4"  title="12개월">12개월</option>
                                            </select>
                                        </div> */}

                                        
                                        {/* <div className="col right">
                                            <button
                                                className="btn text3 text3-on"
                                                onClick={async () => {
                                                    this.setState(
                                                        {
                                                            payPage: 1,
                                                            usePage: 1,
                                                            usePageEnd: false,
                                                            payPageEnd: false,
                                                            ticketListDetailState:
                                                                SUB_TICKET_LIST_DETAIL_USE,
                                                        },
                                                        async () => {
                                                            await this.getUseChargeUpdateList();
                                                        }
                                                    );
                                                }}
                                            >
                                                <span>사용내역</span>
                                            </button>
                                            <span className="bar"></span>
                                            <button
                                                className="btn text3"
                                                onClick={async () => {
                                                    this.setState(
                                                        {
                                                            payPage: 1,
                                                            usePage: 1,
                                                            usePageEnd: false,
                                                            payPageEnd: false,
                                                            ticketListDetailState:
                                                                SUB_TICKET_LIST_DETAIL_CHARGE,
                                                        },
                                                        async () => {
                                                            await this.getUseChargeUpdateList();
                                                        }
                                                    );
                                                }}
                                            >
                                                <span>충전내역</span>
                                            </button>
                                        </div> */}

                                    </div>

                                    {/* 사용 내역 | 충전 내역 */}
                                    {ticketListDetailState === SUB_TICKET_LIST_DETAIL_USE ? (
                                        <div className="component-wrap">
                                            <div className="data-list-container ticket-list">
                                                <ul className="data-list ticket-card">
                                                    {tmpComponentUseTicketList(useList)}
                                                </ul>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="component-wrap">
                                            <div className="data-list-container ticket-list">
                                                <ul className="data-list ticket-card">
                                                    {tmpComponentBuyTicketList(payList)}
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>

                        {/* 충전권 이용내역이라면 다음버튼은 비활성화한다. */}
                        {viewState === TICKET_ALL_LIST ? (
                            <div className="component-wrap inner-container">
                                <div className="btn-area vertical">
                                    <button
                                        type="button"
                                        className="btn large secondary"
                                        onClick={this.handleTicketNext}
                                    >
                                        <span>다음</span>
                                    </button>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </main>
                {/*
                <aside className="quick topVisible">
		            <button type="button" className="quickTopBtn"><span className="blind">맨위로</span></button>
	            </aside>
                */}
                <div className="scroll-to-top  channel2">
                    {topBtnVisible && (
                        <div onClick={this.scrollToTop}>
                            <button type="button" className="btn-quick-top">
                                <span className="blind">맨위로</span>
                            </button>
                        </div>
                    )}
                </div>
            </>
        );
    }
}

export default withCookies(TicketView);
