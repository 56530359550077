import React, { Component } from 'react';

import axios from 'axios';
import sha1 from 'sha1';

import Util from '../../lib/Util';
import { Token } from '../../constantData'
import Loading from '../../components/Common/Loading'
import {calert, calertPromise} from '../../lib/Util'
import RnInterface from '../../lib/RnInterface'
import HeaderJoin2 from '../../components/Common/HeaderJoin2'
import MemberJoinCustomCss from '../../css/CustomGlobalStyle/MemberJoinCustom'

import '../../css/default.css';
import '../../css/member.css';

//view_~~~ 로 시작하는 파일은 html inline에 style로 정해놓은 것을 css화 시킨 것이다.
// import '../css/view_login.css';

import btn_prev from '../../img/btn/btn_prev.svg';


/*

><
\n<

검색 : <input.+?>
치환 : $0>>

검색 : >>>
치환 : />



검색 : <img.+?>
치환 : $0>>

검색 : >>
치환 :  alt=""/>


*/

class MemberJoin4View extends Component {
    state = {
        email: '',
        pw: '',
        pwConfirm: '',
        joinData: {},            // step1~3까지의 데이터
        loading:1,
        emailChkStr:'',
        passwordChkStr:'',
        passwordConfirmChkStr:'',
        apiResultStr:''
    }

    async componentDidMount() {
        try {
            await RnInterface.AndroidURLChange(this.props.location.pathname)
        }
        catch(e){
            console.log(e)
        }

    }

    /* Promise 정의 */
    // 내가 스크랩한 Business card의 List 출력 처리 Begin
    promissEmailChk = () => {
        return new Promise((resolve, reject) => {
            axios({
                url: `/CheckEmail?userId=${this.state.email}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Token': Token,
                    'OsType' : this.state.osType,
                    'DeviceId' : this.state.deviceId,
                }
            }).then(res => {
                resolve(res.data);
            }).catch((err) => {
                return reject(err);
            });
        });
    }


    handleEmail = (e) => { this.setState({ email: e.target.value }) }
    handlePassword = (e) => { this.setState({ pw: e.target.value }) }
    handlePasswordConfirm = (e) => { this.setState({ pwConfirm: e.target.value }) }

    handlePrevBtn = (e) => { this.props.history.goBack(); }
    handleNextBtn = async (e) => {
        let chkFlag = 0;

        if (!Util.isChkEmail(this.state.email)) {
            this.setState({emailChkStr: '이메일 형식에 맞게 입력해주세요.'})
            // await calertPromise('이메일 형식에 맞게 입력해주세요.')
            return;
        }
        chkFlag++;
        this.setState({
            emailChkStr:'',
            apiResultStr:'',
        })

        if (!Util.isChkPw(this.state.pw) || !Util.isChkPw(this.state.pwConfirm)) {
            if (!Util.isChkPw(this.state.pw)) {
                this.setState({
                    passwordChkStr: '비밀번호 형식에 맞게 입력해주세요.',
                    apiResultStr:'',
                })
            } else {
                this.setState({
                    passwordChkStr: '',
                    apiResultStr:'',
                })
            }
            if (!Util.isChkPw(this.state.pwConfirm)) {
                this.setState({
                    passwordConfirmChkStr: '비밀번호 형식에 맞게 입력해주세요.',
                    apiResultStr:'',
                })
            } else {
                this.setState({
                    passwordConfirmChkStr: '',
                    apiResultStr:'',
                })
            }
            // await calertPromise('비밀번호 형식에 맞게 입력해주세요.')
            return;
        }
        chkFlag++;
        this.setState({
            passwordChkStr:'',
        })

        if (this.state.pw !== this.state.pwConfirm) {
            // await calertPromise('비밀번호가 맞지 않습니다 다시 입력해주세요.')
            this.setState({
                passwordChkStr: '비밀번호가 일치하지 않습니다.',
                passwordConfirmChkStr: '비밀번호가 일치하지 않습니다.',
                apiResultStr:'',
            })
            return;
        }
        chkFlag++;

        this.setState({

            passwordConfirmChkStr:'',
        })
        
        try {
            if (chkFlag === 3) {
                this.setState({loading:0})
                let resultData = await this.promissEmailChk();
                // success가 아니라면?
                if (resultData.Result !== 'success') {
                    // await calertPromise(resultData.Message)
                    
                    // 이미 가입된 이메일 주소입니다.
                    this.setState({
                        loading:1,
                        emailChkStr:resultData.Message,
                        passwordChkStr:'',
                        passwordConfirmChkStr:'',
                        apiResultStr: '',
                    })
                }
                // Success라면?
                else {
                    localStorage.setItem('c_emailid', this.state.email);
                    localStorage.setItem('c_password', sha1(this.state.pw));
                    
                    // 주소 입력 화면으로 이동
                    this.props.history.push('/join/5');
                }
            }
        } catch(e) {
            await calertPromise('ID 체크 도중 오류가 발생하였습니다. 관리자에게 문의해주세요.')
            this.setState({loading:1})

        }
        
    }
    
    render() {
        return (
            <>
            {!this.state.loading ? (<Loading />) : ''}
                <MemberJoinCustomCss />
                <HeaderJoin2
                    onBackButton={this.handlePrevBtn}
                    title={''} />
                <main id="Contents"> 
                    <div className="key-visual-wrap">
                        <div className="key-visual-container">
                            <div className="key-contents">
                                <div className="key-text">
                                    <strong>이메일로</strong> 회원가입을 진행합니다.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="component-wrap inner-container">
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">이메일</label>									
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <input type="email" placeholder="이메일 주소를 입력해주세요." className="input type1" onChange={this.handleEmail} value={this.state.email} />
                                    </div>
                                </div>	
                            </div>
                            <div class="form-alert">
                                <p className="is-errored">{this.state.emailChkStr}</p>
                            </div>                                                 
                        </div>                        
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">비밀번호</label>	
                                    <span className="text-sub text-gray pad-l5">(설정 가능한 특수문자 : @ $ ! % * # ? &)</span>								
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <input type="password" placeholder="영문, 숫자, 특수문자 조합으로 8~10자 설정" className="input type1" onChange={this.handlePassword} value={this.state.pw} />
                                    </div>
                                </div>	
                            </div>
                            <div class="form-alert">
                                <p className="is-errored">{this.state.passwordChkStr}</p>
                            </div>                                                  
                        </div>  
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">비밀번호 확인</label>							
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <input type="password" placeholder="영문, 숫자, 특수문자 조합으로 8~10자 설정" className="input type1" onChange={this.handlePasswordConfirm} value={this.state.pwConfirm} />
                                    </div>
                                </div>	
                            </div>
                            <div class="form-alert">
                                <p className="is-errored">{this.state.passwordConfirmChkStr}</p>
                            </div>                                                  
                        </div>                                                 
                    </div>
                    <div className="component-wrap">
                        <div className="btn-area btn-group-fixed vertical">
                            <button type="button" className="btn large secondary" onClick={this.handleNextBtn}><span>다음</span></button>
                        </div>
                    </div>                     
                </main>    
 
            </>
        );
    }
};

export default MemberJoin4View;
