import React, { Component } from 'react';
import {withCookies} from 'react-cookie'
import {NavLink} from 'react-router-dom'
import HeaderJoinCancel from '../../components/Common/HeaderJoinCancel'
import Loading from '../../components/Common/Loading'
import RnInterface from '../../lib/RnInterface';
import ReserveApi from '../../lib/ReserveApi';
import store from '../../reduxStore'

import Slider from 'react-slick'


import photo_test from '../../img/photo_test.jpg'
import photo_test2 from '../../img/photo_test2.jpg'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import icon_user from '../../img/rating/icon_user.svg'
import { calertPromise } from '../../lib/Util';



const VIEW_TYPE_TOP              = 0;
const VIEW_TYPE_BOTTOM           = 1;
const VIEW_TYPE_COVID_CHK        = 2;
const VIEW_TYPE_TEMPERATURE_CHK  = 3;
const VIEW_TYPE_DRINKING_CHK     = 4;


class SafetyInfoView extends Component {
    state = {
        loading:1,
        buttonType:VIEW_TYPE_TEMPERATURE_CHK,
        imageUrl:'',
        imagePathObj:{
            DriverTemperatureCheckImgName:'',
            DriverAlcoholCheckImgName:'',
            DriverCovid19ImgName:'',
        },
    }
    
    handleBack = () => {
        this.props.history.goBack();
    }

    handleHome = () => {

    }
    
    setStateAsyncButtonType = (type, url) => {

        return new Promise((resolve) => {
            this.setState({ buttonType: type }, () => {
                resolve(true)
            })
        })
    }

    getImageStateCheck = (btnType) => {
        let retData = '';
        switch(btnType) {
            case VIEW_TYPE_COVID_CHK: 
                retData = this.state.imagePathObj.DriverCovid19ImgName;
                break;
            case VIEW_TYPE_TEMPERATURE_CHK: 
                retData = this.state.imagePathObj.DriverTemperatureCheckImgName;
                break;
            case VIEW_TYPE_DRINKING_CHK: 
                retData = this.state.imagePathObj.DriverAlcoholCheckImgName;
                break;
            default:
                break;
        }
        return retData;

    }

    async componentDidMount() {
        try {
            await RnInterface.AndroidURLChange(this.props.location.pathname);

            const { getinfo } = store.getState().UseState_ReduxModule
            const { drivingno } = this.props.match.params;

            let data = getinfo.filter((data) => { return data.DrivingNo === parseInt(drivingno) })
            console.log(data);
            this.setState({ 
                imagePathObj : {
                    DriverTemperatureCheckImgName: data[0].DriverTemperatureCheckImgName,
                    DriverAlcoholCheckImgName : data[0].DriverAlcoholCheckImgName,
                    DriverCovid19ImgName: data[0].DriverCovid19ImgName,

                    // DriverTemperatureCheckImgName: 'https://lh3.googleusercontent.com/proxy/9IVLXFdKGn2EUY7mhgu_qAalAkhv_Hu6svs-hxskrAWxJkW7ZVMzoQDGVdKIQ8vfSocQqfEJs2tb7Wzs1iYoVuxFKz2z4pdrh7WgRfYMCWp1LGzs-g',
                    // DriverAlcoholCheckImgName : 'https://lh3.googleusercontent.com/proxy/9IVLXFdKGn2EUY7mhgu_qAalAkhv_Hu6svs-hxskrAWxJkW7ZVMzoQDGVdKIQ8vfSocQqfEJs2tb7Wzs1iYoVuxFKz2z4pdrh7WgRfYMCWp1LGzs-g',
                    // DriverCovid19ImgName: 'https://lh3.googleusercontent.com/proxy/9IVLXFdKGn2EUY7mhgu_qAalAkhv_Hu6svs-hxskrAWxJkW7ZVMzoQDGVdKIQ8vfSocQqfEJs2tb7Wzs1iYoVuxFKz2z4pdrh7WgRfYMCWp1LGzs-g',
                    
                },
            });

        }
        catch(e) {
            console.log(e)
        }
        

        // const { cookies } = this.props;
        // const { driverNo } = this.props.match.params;

        // try {
        //     let driverPhotoData = await ReserveApi.GetDriverTodayInfo(
        //         driverNo,
        //         cookies.get('ostype'),
        //         cookies.get('deviceid'),
        //         cookies.get('login_encrypt')
        //     )

        //     if (driverPhotoData.Result === 'success') {
        //         this.setState({ 
        //             imagePathObj: driverPhotoData.Item,
        //             loading: 1 
        //         });
        //     }
        //     else {
        //         await calertPromise('데이터를 취득하지 못했습니다. 다시 시도해주세요.');
        //         this.setState({ loading: 1 });
        //     }
            
        // }
        // catch(e) {
        //     await calertPromise('데이터 취득 도중 오류가 발생했습니다. 고객센터에 문의해주세요.')
        //     this.setState({ loading: 1 });
        // }
        
    }

    handleButton1 = async (type) => {
        await this.setStateAsyncButtonType(type)
    }
    

    render() {
        const { buttonType } = this.state
        let sliderSetting = {  
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            
        }


        return (
            <> 
                {!this.state.loading && (<Loading />)}
                <HeaderJoinCancel
                    title="운행 안전정보"
                    onBackButton={this.handleBack} 
                />

                <main id="Contents">
                    <div className="text-group text-group-inner bg-gray">
                        <p className="text-sub text-gray">모시러는 편안하고 안전한 운행을 위해 항상 최선을 다합니다.</p>
                        <p className="text-sub text-gray">고객의 마음까지 운전합니다. 운전동행서비스 모시러</p>
                    </div>
                    <div className="inner-container category-menu">
                        <div className="scroll-banner-contents">

                            <Slider {...sliderSetting}>
                                {/* <ul className="visual-box-list visual-box-icon">
                                    <li className={buttonType === VIEW_TYPE_TOP ? "visual-box on" : "visual-box"} >
                                    
                                        <NavLink to="#" className="box-wrap ico-clothe-top" onClick={()=>{ this.handleButton1(VIEW_TYPE_TOP)}} >
                                            <div class="box-text">출근복장<p>상의</p></div>
                                        </NavLink>
                                    </li>                                                                                                                                                       
                                </ul>

                                <ul className="visual-box-list visual-box-icon">
                                    <li className={buttonType === VIEW_TYPE_BOTTOM ? "visual-box on" : "visual-box"} >
                                        <NavLink to="#" className="box-wrap ico-clothe-bottom"  onClick={()=>{ this.handleButton1(VIEW_TYPE_BOTTOM)}} >
                                            <div className="box-text">출근복장<p>하의</p></div>
                                        </NavLink>
                                    </li>                                                                                                                                                       
                                </ul> */}
                                
                                <ul className="visual-box-list visual-box-icon">
                                    <li className={buttonType === VIEW_TYPE_TEMPERATURE_CHK ? "visual-box on" : "visual-box"} >
                                        <NavLink to="#" className="box-wrap ico-covid-temp"  onClick={(e)=>{ e.preventDefault(); this.handleButton1(VIEW_TYPE_TEMPERATURE_CHK)}} >
                                            <div className="box-text">체온측정</div>
                                        </NavLink>
                                    </li>                                                                                                                                                       
                                </ul>

                                <ul className="visual-box-list visual-box-icon">
                                    <li className={buttonType === VIEW_TYPE_DRINKING_CHK ? "visual-box on" : "visual-box"} >
                                        <NavLink to="#" className="box-wrap ico-alcohol"  onClick={(e)=>{ e.preventDefault(); this.handleButton1(VIEW_TYPE_DRINKING_CHK)}} >
                                            <div className="box-text">음주측정</div>
                                        </NavLink>
                                    </li>                                                                                                                                                       
                                </ul>
                                
                                {this.state.imagePathObj.DriverCovid19ImgName !== '' && (
                                    <ul className="visual-box-list visual-box-icon">
                                        <li className={buttonType === VIEW_TYPE_COVID_CHK ? "visual-box on" : "visual-box"} >
                                            <NavLink to="#" className="box-wrap ico-covid-test"  onClick={(e)=>{ e.preventDefault();  this.handleButton1(VIEW_TYPE_COVID_CHK)}} >
                                                <div className="box-text">코로나<p>검사증</p></div>
                                            </NavLink>
                                        </li>
                                    </ul>
                                )}                                

                            </Slider>
                          
                        </div> 

                    </div>
                    <div className="category-list">
                        <div className="line-box">
                            <div class="visual-img">
                                <img src={this.getImageStateCheck(this.state.buttonType)} onError={(e)=>{e.target.src = icon_user}}  alt="" />
                            </div>                            
                        </div>  
                        {/* <div className="line-box">
                        <div class="visual-img">
                                <img src={photo_test2} alt="" />
                            </div>                             
                        </div>  */}
                    </div>                     
                </main>


 



                {/* <main id="Contents">
                    <div className="component-wrap">
                        <div className="desc-title">
                            <span>코멘트 영역</span>
                            <span>모시러는 편안하고 안전한 운행을 위해 항상 최선을 다합니다.</span>
                            <span>고객의 마음까지 운전합니다. 운전동행서비스 모시러</span>
                        </div>

                        <div className="area_button">
                            <button type="button" className={buttonType === VIEW_TYPE_TOP ? "btn small pressed" : "btn small"}   onClick={()=>{ this.handleButton1(VIEW_TYPE_TOP) }}>출근복장상의</button>
                            <button type="button" className={buttonType === VIEW_TYPE_BOTTOM ? "btn small pressed" : "btn small"}   onClick={()=>{ this.handleButton1(VIEW_TYPE_BOTTOM) }}>출근복장하의</button>
                            <button type="button" className={buttonType === VIEW_TYPE_COVID_CHK ? "btn small pressed" : "btn small"}   onClick={()=>{ this.handleButton1(VIEW_TYPE_COVID_CHK) }}>코로나검사증</button>
                            <button type="button" className={buttonType === VIEW_TYPE_TEMPERATURE_CHK ? "btn small pressed" : "btn small"}   onClick={()=>{ this.handleButton1(VIEW_TYPE_TEMPERATURE_CHK) }}>체온측정</button>
                            <button type="button" className={buttonType === VIEW_TYPE_DRINKING_CHK ? "btn small pressed" : "btn small"}   onClick={()=>{ this.handleButton1(VIEW_TYPE_DRINKING_CHK) }}>음주측정</button>
                        </div>
                        
                        <div className="area_photo">
                            그림영역
                        </div>
                    </div>
                    
                </main> */}

                
            </>
        );
    }
};

export default withCookies(SafetyInfoView);


