import axios from 'axios';
import CookieApi from '../lib/CookieApi'
import { Token } from '../constantData';
import {global_data_async } from '../modules/Global/global_data'
import store from '../reduxStore'
import RnInterface from './RnInterface';

const TIME_OUT = 5000;
const AccountApi = {};


AccountApi.requestLogin = (id, hashcode='', accountType, ostype='', deviceid='', isforce='Y') => {
    let urlData = '';
    switch(accountType) {
        case 'email':
            urlData = `/EmailLogin?id=${id}&pw=${hashcode}&isforce=${isforce}`
            break;
        case 'kakao':
            urlData = `/SnsLogin?id=${id}&isforce=${isforce}`
            break;
        case 'apple':
            urlData = `/AppleLogin?id=${id}&isforce=${isforce}`
            break;
        default:
            urlData = ``
            break;
    }

    return new Promise( (resolve, reject)=>{
        axios({
            url : urlData,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token
            }
        }).then( async res => {
            // await RnInterface.callReactNative('LoginedDataAnalytics' + JSON.stringify(res.data));
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}

AccountApi.SetLogOut = (userId, ostype='', deviceId='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `SetLogOut?userId=${userId}&deviceId=${deviceId}`,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceId,
                'Token': Token,
                'Authorize' : loginEncrypt,
            }
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}

// 아이디 찾기
AccountApi.FindUserId = (phoneNo, ostype='', deviceid='') => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/FindUserId?phoneNo=${phoneNo}`,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token
            }
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}

// 비밀번호 찾기
AccountApi.FindPassword = (userId, phoneNo, ostype='', deviceid='') => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/FindPassword?userId=${userId}&phoneNo=${phoneNo}`,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token
            }
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}



// kakaoLoginAuth : kakao 인증 실시
AccountApi.kakaoLoginAuth = () => {
    return new Promise ((resolve, reject) => {
        window.Kakao.Auth.login({
            
            success: function (authObj) {
                // alert(JSON.stringify(authObj))
                window.Kakao.API.request({
                    url: '/v2/user/me',
                    success: function (response) {
                        // alert(response);
                        resolve(response);
                        // if (response.kakao_account.email === undefined) {
                        //     alert('이메일 주소와 비밀번호를 입력하시고 로그인해주세요.')
                        // } else {

                        //     //alert('로그인 시도중...')
                        // }

                    },
                    fail: function (error) {
                        return reject(error);
                    }
                });

            },
            fail: function (err) {
                return reject(err);
            },
        })
    })
}


// AccountApi.kakaoLoginAuth2 = () => {
//     let Kakao = window.Kakao;
//     return new Promise ((resolve, reject) => {
//         Kakao.Auth.authorize({
//             redirectUri: 'http://192.168.219.100:3000/oauth'
//         });
//     })
// }

// 회원 가입
AccountApi.JoinForm = (objData, ostype='', deviceid='') => {
    
    return new Promise((resolve, reject) => {
        axios({
            url: `/JoinForm`,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType' : ostype,
                'DeviceId' : deviceid,
                'Token': Token,
            },
            data: objData
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

// 회원 가입
AccountApi.CheckEmail = (email, ostype='', deviceid='') => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/CheckEmail?userId=${email}`,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'Token': Token,
                'OsType' : ostype,
                'DeviceId' : deviceid,
            }
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

// 회원 정보 수정
AccountApi.UpdateCustomer = (formData, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/UpdateCustomer`,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'Token': Token,
                'OsType' : ostype,
                'DeviceId' : deviceid,
                'Authorize' : loginEncrypt,
            },
            data: formData
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

// 회원가입시 쓰이는 임시 데이터 삭제
AccountApi.removeJoinLocalStorageData = () => {
    localStorage.removeItem('couponAddFlag');
    localStorage.removeItem('AccountType');
    localStorage.removeItem('authChkResult');
    localStorage.removeItem('couponCode');
    localStorage.removeItem('deviceId');
    localStorage.removeItem('emailaddress');
    localStorage.removeItem('c_password');
    localStorage.removeItem('osType');
    localStorage.removeItem('name');
    localStorage.removeItem('c_emailid');
    localStorage.removeItem('phoneNum');
    localStorage.removeItem('check_status');
    localStorage.removeItem('memberjoin_parentcode');
    
}

// 회원정보가 업데이트 되었을때는 Login을 재시도 하고 성공하면 유저 정보를 취득한다.
// 유저 취득정보가 인자가 된다.
// 그 정보를 store에 저장시키고, cookie에도 저장한다.
AccountApi.updateMyInfo = async (loginResult, hash, cookies) =>{
    // redux store에 저장
    await store.dispatch(global_data_async(loginResult.Item));

    // 데이터를 쿠키에 저장
    CookieApi.SettingCookie(cookies, {
        UserId : loginResult.Item.UserId,
        AccountType : loginResult.Item.AccountType,
        hash : hash,
        LoginEncrypt : loginResult.Item.LoginEncrypt,
        OsType : loginResult.Item.OsType,
        DeviceId : loginResult.Item.DeviceId
    }, Number(cookies.get('auto_login_chk')))
}
// updateMyInfo 동작에서 LocalStorage 저장 처리를 추가
// 이 함수 호출 조건은 LocalStorage에서 데이터를 정상적으로 받아왔을때이다.
AccountApi.updateMyInfoWithLocalStorageLoginCheck = async (loginResult, lsData, cookies) =>{
    // redux store에 저장
    await store.dispatch(global_data_async(loginResult.Item));

    // 데이터를 쿠키에 저장
    CookieApi.SettingCookie(cookies, {
        UserId          : lsData.email_id,
        AccountType     : lsData.account_type,
        hash            : lsData.hashcode,
        LoginEncrypt    : loginResult.Item.LoginEncrypt,
        OsType          : lsData.ostype,
        DeviceId        : lsData.deviceid
    }, Number(lsData.auto_login_chk))
        
    // localStorage에 갱신
    localStorage.setItem('mosiler_cookie',JSON.stringify({
        account_type        : lsData.account_type,
        deviceid            : lsData.deviceid,
        email_id            : lsData.email_id, 
        login_encrypt       : loginResult.Item.LoginEncrypt,
        ostype              : lsData.ostype,
        auto_login_chk      : lsData.auto_login_chk,
        hashcode            : lsData.hashcode,
    }))

}

// LoginCheck 화면에서 로그인에 성공했을때는 아래 함수를 호출한다.
// 다른 로그인처리 부분도 봐야겠지만... 앵간하면 이 함수가 많이 쓰였음.하네..
AccountApi.LoginSuccessUpdateLoginInfo = async (loginResult, cookies) =>{
    // redux store에 저장
    await store.dispatch(global_data_async(loginResult.Item));

    // 데이터를 쿠키에 저장
    CookieApi.SettingCookie(cookies, {
        UserId          : cookies.get('email_id'),
        AccountType     : cookies.get('account_type'),
        hash            : cookies.get('hashcode'),
        LoginEncrypt    : loginResult.Item.LoginEncrypt,
        OsType          : cookies.get('ostype'),
        DeviceId        : cookies.get('deviceid'),
    }, Number(cookies.get('auto_login_chk')))
        
    // localStorage에 갱신
    localStorage.setItem('mosiler_cookie',JSON.stringify({
        email_id            : cookies.get('email_id'),
        account_type        : cookies.get('account_type'),
        hashcode            : cookies.get('hashcode'),
        login_encrypt       : loginResult.Item.LoginEncrypt,
        ostype              : cookies.get('ostype'),
        deviceid            : cookies.get('deviceid'),
        auto_login_chk      : cookies.get('auto_login_chk'),
    }))
}



AccountApi.add_partn_pref = (formData, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/add_partn_pref`,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'Token': Token,
                'OsType' : ostype,
                'DeviceId' : deviceid,
                'Authorize' : loginEncrypt,
            },
            data: formData
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

AccountApi.Get_partn_pref = (customerno, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/Get_partn_pref?customerno=${customerno}`,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'Token': Token,
                'OsType' : ostype,
                'DeviceId' : deviceid,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

AccountApi.update_partn_pref = (formData, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/update_partn_pref`,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'Token': Token,
                'OsType' : ostype,
                'DeviceId' : deviceid,
                'Authorize' : loginEncrypt,
            },
            data: formData
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

AccountApi.setFcmToken = (CustomerNo, fcmtoken, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/v2/SetFcmToken?regId=${fcmtoken}&customerNo=${CustomerNo}`,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'Token': Token,
                'OsType' : ostype,
                'DeviceId' : deviceid,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}


    //customerno: 12532
    //
    // 내가 스크랩한 Business card의 List 출력 처리 Begin
    // processFreeMember = (customerno) => {
    //     return new Promise((resolve, reject)=>{
    //         axios({
    //             url : `/FreeCustomer?customerno=${customerno}`,
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type':'application/json',
    //                 'OsType': 'A',
    //                 'DeviceId': '273',
    //                 'Token': '4D006D0B3FF2019759A6FD719AE8B291E2B2A4935F0CC42D72534C494C95AAFD'
    //             }
    //         }).then(res => {
    //             resolve(res.data);
    //         }).catch( (err) => {
    //             return reject(err);
    //         });
    //     });
    // }


export default AccountApi;