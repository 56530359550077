import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import HeaderJoin2 from '../../components/Common/HeaderJoin2'
import Util, {calert, calertConfirm, isChkResultSuccess} from '../../lib/Util'
import TicketApi from '../../lib/TicketApi';
import RnInterface from '../../lib/RnInterface';
import Loading from '../../components/Common/Loading'
import store from '../../reduxStore'
import { calertPromise } from '../../lib/Util'
import { withCookies } from 'react-cookie';

import '../../css/app.ui.css';

const MAN_WON = 10000;

// 20210512 구매쪽 진행할때 Hugo님께 얘기하고 진행할것
class TicketBuyingView extends Component {
    
    state = {
        selectTicket:   '1',
        ticketName:     '',
        ticketAmount:   0,
        ticketPercent:  0,
        ticketList:     [],
        count:          1,
        loading:        1,
    }

    // setState 싱크처리
    setStateSync = (data) => {
        return new Promise((resolve) => {
            this.setState(data, () => {
                resolve(true);
            });
        });
    };

    async componentDidMount () {
        const { cookies } = this.props;
        const { state } = this.props.history.location;
        await RnInterface.AndroidURLChange(this.props.location.pathname); 

        if (state && state.previousView && state.previousView === 'reserve_confirm') {
            await this.setStateSync({
                loading:0
            });

            // 충전권 상품 리스트 취득
            let tmpPaidTicketList = await TicketApi.GetPrePaidTicketList(
                cookies.get("ostype"),
                cookies.get("deviceid"),
                cookies.get("login_encrypt")
            );

            // 충전권 상품 리스트 취득 성공시
            if (isChkResultSuccess(tmpPaidTicketList)) {
                await this.setStateSync({
                    ticketList: tmpPaidTicketList.Item,
                    selectTicket:   '1',
                    ticketName:     tmpPaidTicketList.Item[0].Name,
                    ticketAmount:   tmpPaidTicketList.Item[0].Amount,
                    ticketPercent:  tmpPaidTicketList.Item[0].AddPercentAmount,
                    loading:1
                });
            }
        } else {
            await this.setStateSync({
                selectTicket:   String(this.props.history.location.state.selectedData.Id),
                ticketName:     this.props.history.location.state.selectedData.Name,
                ticketAmount:   this.props.history.location.state.selectedData.Amount,
                ticketPercent:  this.props.history.location.state.selectedData.AddPercentAmount,
                ticketList:     this.props.history.location.state.ticketList,
                
            });
        }
    }
    
    handleCallCenter = async (e) => {
        e.preventDefault();
        await RnInterface.callReactNative('callTelephone15224556')
    }
    
    handlePrevBtn = () => {
        this.props.history.goBack();
    }

    getSelectedTicketList = (id) => {
        return this.state.ticketList.filter( (data) => {return data.Id === parseInt(id)} )[0];
    }
    
    handleSelectTicketList = (e) => {
        let tmpData = this.getSelectedTicketList(e.target.value)
        this.setState({
            selectTicket:   e.target.value,
            ticketName:     tmpData.Name,
            ticketAmount:   tmpData.Amount,
            ticketPercent:  tmpData.AddPercentAmount,
        })
    }

    handleSelectTicketCount = (e) => {
        this.setState({
            count: parseInt(e.target.value),
        })
    }


    handleLoadingStart = () => {
        return new Promise((resolve)=>{
            this.setState({ loading: 0 }, () => {               
                resolve(true)
            });
        })        
    }

    handleLoadingEnd = () => {
        return new Promise((resolve)=>{
            this.setState({ loading: 1 }, () => {               
                resolve(true)
            });
        })        
    }
    

    handlePayment = async () => {
        const { CustomerNo } = store.getState().global_data.loginedData
        const { cookies } = this.props;
        const {
            selectTicket,
            count,
            ticketAmount,
        } = this.state;

        let msg = `${Util.numberWithCommas(String(ticketAmount * count))}원이 결제됩니다. 계속하시겠습니까?`

        if (await calertConfirm(msg)) {
            try {
                await this.handleLoadingStart()
                let callApi = await TicketApi.PurchasePrePaidTicket(CustomerNo, selectTicket, count, cookies.get('ostype'), cookies.get('deviceid'), cookies.get('login_encrypt'));
                if (callApi.Result === 'success') {
                    if (callApi.ResultCode === 'NoCard') {
                        await calertPromise('카드가 등록되어 있지 않습니다.\n카드 등록 후 다시 결제해주세요.')
                        // 결제가 완료되었습니다.
                        this.props.history.goBack();
                    }
                    else {
                        await calertPromise('결제가 완료되었습니다.')
                        // await RnInterface.callReactNative('ScreenViewAnalyticsTicketPurchase')
                        // 결제가 완료되었습니다.
                        this.props.history.goBack();
                    }
    
                }
                else {
                    await calertPromise('결제에 실패하였습니다.')
                    // 결제에 실패하였습니다.
                    await this.handleLoadingEnd()
    
                }
    
            }
            catch (e) {
                await calertPromise('충전권 결제 중에 오류가 발생했습니다. 관리자에게 문의해주세요.')
                await this.handleLoadingEnd()
            }
        }
    }

    // 충전권 구매시 개수는 할인율 문제 때문에 우선
    // “단품“으로만 구매하는 것으로 하고,
    // 차후에 개수를 정해서 구매하게 될 수도 있다고
    // Jimmy가 얘기했습니다.
    render() {
        const { 
            selectTicket,
            ticketName,
            ticketAmount,
            ticketPercent,
            count,
         } = this.state;

        // const { AddPercentAmount, Amount, Id, Name } = this.props.history.location.state.selectedData ;
        
        let tmpTicketOption = (arr) =>(
            arr.map((data, idx) => (
                <>
                {/* {data.Amount >= 1 ? (<option value={`${data.Id}`}>{data.Name} 충전권 (금액:{data.Amount / MAN_WON}만원) </option>) : null} */}
                    {data.Amount >= 1 ? (<option value={`${data.Id}`}>{data.Name} 충전권</option>) : null}
                </>
            ))
        )

        if (!this.state.loading) return (<Loading />)
        return (<>
                {!this.state.loading ? (<Loading />) : ''}
                <HeaderJoin2 onBackButton={this.handlePrevBtn} title={''} />
                <main id="Contents" className="">
                    <div className="key-visual-wrap">
						<div className="key-visual-container">
							<div className="key-text">
                                <b>{ticketName} 충전권을 구매하시겠습니까?</b>
                                {ticketPercent >= 1 ? (<p className="key-text-sub">{ticketPercent}% 증액(금액 {Util.numberWithCommas( String((ticketAmount) * (ticketPercent / 100)) )}원)의 혜택을 받으셨습니다.</p>) : null}
                                
							</div>
						</div>
					</div>
                    <div className="component-wrap">
                        <div className="inner-container">
                            <div className="form vertical">
								<div className="form-body">
									<div className="form-title">
										<label className="form-tit">충전권 종류</label>									
									</div>
									<div className="form-cont">
                                        <div className="form-controls type-ticket">
                                            {/* 20210517 className에서 W70 -> W100로 변경. 차후 개수 선택이 부활하면 W70으로 변경한다.  */}
                                            <select title="충전권 종류 선택" value={this.state.selectTicket} onChange={this.handleSelectTicketList} className="select type1 W100">
                                                {tmpTicketOption(this.state.ticketList)}
                                            </select>  
                                            
                                            {/* 20210517 기능은 구현되어 있지만 차후 개수 선택이 부활될 수 있기 때문에 주석처리 해놓는다. */}
                                            {/* <select title="충전권 갯수 선택" className="select type1 W30" value={String(this.state.count)} onChange={this.handleSelectTicketCount}>
                                                <option value="1">1개</option>
                                                <option value="2">2개</option>
                                                <option value="3">3개</option>
                                            </select> */}

                                        </div>
									</div>								
								</div>
                                <div className="bul dash mar-t5 text-darkgray">
                                    
                                    {/* 20210517 차후 개수 선택이 부활될 수 있기 때문에 주석처리 해놓는다. */}
                                    {/* <p className="text-import">최대 3개까지 구매가능합니다.</p>
                                    <p>3개 초과 구매시 고객센터 (<Link to='#' onClick={this.handleCallCenter}>1522-4556</Link>)로 문의해주시기 바랍니다.</p> */}
                                    
                                    {/* <p className="text-darkblue">일반 결제보다 정액200 이용권은 20%, 정액100 이용권은 15%, 정액10 이용권은 10% 증액되어 적용됩니다.</p> */}
                                </div>
							</div>
                        </div>
                    </div>
                    <div className="component-wrap space-top">
                        <div className="box info">
                            <div className="box-head">							
                                <span>꼭 확인해주세요. </span>                        
                            </div>
                            <div className="box-body">
                                <h3 className="info-stit">이용안내</h3>
                                <div className="bul dash">
                                    <p>이용권 사용가능 시간은 개시 시점부터 계산됩니다.</p>
                                    <p className="text-darkblue">이용시간을 초과할 경우, 초과된 시간만큼의 비용은 재충전 또는 카드 결제 가능합니다. (단, 카드결제는 일반결제 금액으로 진행됨)</p>
                                    <p className="text-import">쿠폰과 충전권 중복 사용 불가합니다.</p>
                                </div>
                                <h3 className="info-stit">환불안내</h3>
                                <div className="bul dash">
                                    <p>앱내에서 직접 취소가 불가하오니, 모시러 컨시어지로 문의해 주시기 바랍니다.</p>
                                    <p>미사용시 : 구매하신 금액의 100% 환불(단, 7일 이내)</p>
                                    <p>사용시 : 구매하신 금액의 10% 환불수수료 + 사용하신 시간에 따른 추가수수료를 공제한 나머지 환불</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="component-wrap inner-container">
                        <div className="btn-area vertical">
                            <button type="button" className="btn large secondary" onClick={this.handlePayment}>
                                <span><b>{Util.numberWithCommas( String(ticketAmount * count))}</b>원 결제하기</span>
                            </button>
                        </div>
				    </div>
                </main>
            </>
        );
    }
};
export default withCookies(TicketBuyingView);