import React, { Component } from "react";
import { withLastLocation } from "react-router-last-location";
import { withCookies } from "react-cookie";
import Header from "../../../components/Common/Header";
import RnInterface from "../../../lib/RnInterface";
import store from '../../../reduxStore'
import Loading from '../../../components/Common/Loading'
import moment from 'moment'
import { calertPromise, setLocalStorage } from "../../../lib/Util";

const NONE = 'NONE';
const OK = 'OK';
const FIFTEEN_DAYS_AFTER_FLAG = 'FIFTEEN_DAYS_AFTER_FLAG';

class ReserveComplateView extends Component {

    state = {
        loading: 1,
        reserveType: 1,
        totalTime:'',      
    };

    async componentDidMount() {
        await RnInterface.AndroidURLChange(this.props.location.pathname)
        this.props.history.replace('/main')
        this.props.history.push('/use_state')
    }   

    handleGotoMainPage =  (flag) => {
        if (flag === FIFTEEN_DAYS_AFTER_FLAG) {
            localStorage.setItem('monthly_mosiler_date', moment().format('yyyy-MM-DD HH:mm'))
        }
        this.props.history.replace('/main')
        this.props.history.push('/use_state')
    }

    render() {
        // 향후에 이거 쓸 것 같아서 우선 내비둔다.
        // 화면은 비표시하고, 바로 화면 이동하도록 한다.
        return (<></>)
        return (
            <>
                {/* 왠지 이 화면 쓸 것 같아서 유지한다. */}
                {!this.state.loading ? (<Loading />) : ''}
                <Header
                    // title={`${ReserveApi.getDrivingTypeStr(this.state.reserveType)} 예약 (1/5)`}
                    title={`월간 모시러 소개`}
                    onBackButton={this.handleGotoBack}
                    onHomeButton={this.handleGotoHome}
                />
                <div className="booking_box2 hd_margin_top">
                    <div className="component-wrap inner-container">
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">월간 모시러 안내 페이지</label>
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    </div>
                                </div>	
                            </div>                                               
                        </div>
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">사용해 보시는건 어떨까요?</label>
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    </div>
                                </div>	
                            </div>                                               
                        </div>
                        <div className="form vertical">
                            <div className="form-body">
                                <div className="form-title">
                                    <label className="form-tit">여러분과 함께하는 월간 모시러</label>
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    </div>
                                </div>	
                            </div>                                               
                        </div>
                    </div>    
                   
                    {/* 해당 페이지의 스크롤을 맨 밑으로 내렸을때, 버튼 영역을 공백 공간으로 놔야 한다. 버튼은 fixed로 되어있다. */}
                    <div style={{paddingBottom:'calc(1.25em + 3.375rem)'}}></div>
                    <div className="component-wrap">
                        <div className="btn-area btn-group-fixed horizontal">
                            <button type="button" className="btn large secondary liner text-bold"   onClick={()=>this.handleGotoMainPage(FIFTEEN_DAYS_AFTER_FLAG)}>
                                <span>7일동안 보지않기</span>
                            </button>
                            <button type="button" className="btn large secondary text-bold"         onClick={()=>this.handleGotoMainPage(OK)}>
                                <span>확인</span>
                            </button>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}
//1.25 + 3.375

export default withLastLocation(withCookies(ReserveComplateView));
