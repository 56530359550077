import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import {createGlobalStyle} from 'styled-components'
import Header from '../../components/Common/Header';
import moment from 'moment';
import store from '../../reduxStore'
import RnInterface from '../../lib/RnInterface';
import MonthlyMosilerApi from '../../lib/MonthlyMosilerApi'
import Loading from '../../components/Common/Loading'
import Util, { calertPromise } from '../../lib/Util';

const GlobalStyle = createGlobalStyle`
    body {background-color: #f3f4f8;}
`;


/*
{
    "Result": "success",
    "Message": "",
    "Item": {
        "CustomerNo": 12622,
        "CustomerName2": "1111",
        "CustomerPhone2": "1111",
        "RealPassengerName": "2222",
        "RealPassengerPhone": "2222",
        "ContractRemark": "test3",
        "PaymentTypeMonthly": 0,
        "MmRemark": "test3",
        "BillingDate": "2022-07-20T00:00:00",
        "ContractStartDate": "2022-07-01T00:00:00",
        "ContractEndDate": "2022-07-02T00:00:00",
        "ContractTypeDay": 10,
        "ContractTypeHour": 8,
        "ContractOneDayAvaliableHour": 8,
        "MmHourPrice": 10000,
        "DeleteYN": "Y",
        "ExtrafeeFlag": 1,
        "ChangeHourPriceFlag": 0,
        "ChangeHourPriceDate": "2022-06-30T00:00:00",
        "ChangeHourPrice": 30000,
        "DrivingEndAfterTimeModifyProcessFlag": 0,
        "RemainTime": 0
    },
    "TokenStatus": 1,
    "ResultCode": ""
}
*/






const MonthlyMyInfoView = (props) => {

    const [userInfo, setUserInfo] = useState({
        "CustomerNo": 0,
        "CustomerName2": "0",
        "CustomerPhone2": "0",
        "RealPassengerName": "0",
        "RealPassengerPhone": "0",
        "ContractRemark": "0",
        "PaymentTypeMonthly": 0,
        "MmRemark": "0",
        "BillingDate": "0",
        "ContractStartDate": "0",
        "ContractEndDate": "0",
        "ContractTypeDay": 0,
        "ContractTypeHour": 0,
        "ContractOneDayAvaliableHour": 0,
        "MmHourPrice": 0,
        "DeleteYN": "N",
        "ExtrafeeFlag": 0,
        "ChangeHourPriceFlag": 0,
        "ChangeHourPriceDate": "0",
        "ChangeHourPrice": 0,
        "DrivingEndAfterTimeModifyProcessFlag": 0,
        "RemainTime": 0
    })
    const [customerName, setCustomerName] = useState('')
    const [loading, setLoading] = useState(true)
    const [cookies] = useCookies();
    

    useEffect(()=>{
        async function initProcess() {
            await RnInterface.AndroidURLChange(props.location.pathname)
        }

        async function getData() {
            try {
                let result = await MonthlyMosilerApi.GetMmUserInfo(
                    store.getState().global_data.loginedData.CustomerNo,
                    cookies.ostype,
                    cookies.deviceid,
                    cookies.login_encrypt
                )
                console.log(result)
    
                if (result.Result === 'success') {
                    setUserInfo(result.Item)
                    setCustomerName(store.getState().global_data.loginedData.CustomerName)
                }
                else {
                    await calertPromise(result.Message)
                }
                setLoading(false)
            }
            catch(e) {
                await calertPromise('데이터를 취득도중 문제가 발생했습니다.')
                setLoading(false)
            }
            
        }
        
        initProcess();
        getData();
    },[])

    const handleBack = () => {
        props.history.goBack()
    }
    
    const handleHome = () => {
        props.history.replace('/main')
    }

    const getStrPaymentType = (type) => {
        let retData = '';
        switch(Number(type)) {
            case 0:
                retData = '계산서';
                break;
            case 1:
                retData = '카드';
                break;
            default:
                break;
        }
        return retData;
    }

    return (
        <>
            <GlobalStyle />
            <Header
                title="월간모시러 가입 정보"
                onBackButton={handleBack}
                onHomeButton={handleHome}
            />
            {loading ? <Loading /> : ""} 

            <main id="Contents" className="section-member"> 
                <div className="inner-container">
                    <div className="profile-wrap">
                        <i className="ico ico-bi-profile"></i>
                        <span className="profile-name">{customerName}님</span>
                    </div>
                </div>
                <div className="inner-container">
                    <div className="box myinfo">
                        <div className='flex-component'>
                            <div className="item">
                                <div className="form-title">
                                    <label className="form-tit">계약시작일</label>
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <span className="input-group has-append">
                                            {moment(userInfo.ContractStartDate).format('yyyy-MM-DD')}
                                        </span>
                                    </div>
                                </div>
                            </div>    
                            <div className="item">
                                <div className="form-title">
                                    <label className="form-tit">계약종료일</label>								
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <span className="input-group has-append">                                                
                                            {moment(userInfo.ContractEndDate).format('yyyy-MM-DD')}
                                        </span>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <br/>
                        {/* <div className='flex-component'>
                            <div className="item">
                                <div className="form-title">
                                    <label className="form-tit">상품(일)</label>								
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <span className="input-group has-append">                                                
                                            {userInfo.ContractTypeDay}일
                                        </span>
                                    </div>
                                </div>
                            </div>    
                            <div className="item">
                                <div className="form-title">
                                    <label className="form-tit">상품(시간)</label>								
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <span className="input-group has-append">                                                
                                        {userInfo.ContractTypeHour}시간
                                        </span>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <br/>
                         */}

                        <div className='flex-component'>
                            <div className="item">
                                <div className="form-title">
                                    <label className="form-tit">매월 이용 가능시간</label>								
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <span className="input-group has-append">                                                
                                            {userInfo.ContractTypeDay * userInfo.ContractTypeHour}시간
                                        </span>
                                    </div>
                                </div>
                            </div>    
                            <div className="item">
                                <div className="form-title">
                                    <label className="form-tit">하루 최소 이용시간</label>								
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <span className="input-group has-append">                                                
                                        {userInfo.ContractOneDayAvaliableHour}시간
                                        </span>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <br/>


                        <div className='flex-component'>
                            <div className="item">
                                <div className="form-title">
                                    <label className="form-tit">결제수단</label>								
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <span className="input-group has-append">                                                
                                            {getStrPaymentType(userInfo.PaymentTypeMonthly)}
                                        </span>
                                    </div>
                                </div>
                            </div>    
                            <div className="item">
                                <div className="form-title">
                                    <label className="form-tit">청구일</label>								
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <span className="input-group has-append">                                                
                                        매월 {moment(userInfo.BillingDate).format('DD')}일
                                        </span>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <br />
                        <div className='flex-component'>
                            <div className="item">
                                <div className="form-title">
                                    <label className="form-tit">시간당 단가</label>								
                                </div>
                                <div className="form-cont">
                                    <div className="form-controls flexible">
                                        <span className="input-group has-append">                                                
                                            {Util.numberWithCommas(userInfo.MmHourPrice+'')}원
                                        </span>
                                    </div>
                                </div>
                            </div>    
                            
                        </div>
                    </div>    
                </div>
            </main>    
            
        </>
    );
};

export default MonthlyMyInfoView;

