import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { withCookies } from 'react-cookie'
import Header from '../../components/Common/Header'

import store from '../../reduxStore'
import PaymentApi from '../../lib/PaymentApi'
import RnInterface from '../../lib/RnInterface'
import ReserveApi from '../../lib/ReserveApi'

import '../../css/default.css';
import '../../css/booking.css';

import icon_card from '../../img/card/icon_card.svg'
import btn_home from '../../img/btn/btn_home.svg'
import { createGlobalStyle } from "styled-components";
import Loading from '../../components/Common/Loading'
import {calert, calertPromise} from '../../lib/Util'

const GlobalStyle = createGlobalStyle`
/* card.css */
@charset "utf-8";



/* 설정 - 기본 */
.card_box { display:inline-block; width:100%;padding:0px;}
.card_box ul { display:inline-block; width:100%; padding:20px 20px 10px 20px;}
/* .card_box li { display:inline-block; width:100%; padding:20px;} */

.card_box li span.section_title { display:inline-block; width:100%;}


.card_con span.card_hd { display:inline-block; width:100%; font-weight: bold;height:50px; line-height:50px; background:#a5abbb; padding:0px 15px; color:#fff; font-size:1.1em; border-radius:10px 10px 0 0; letter-spacing:-1px;}
.card_con span.card_hd img {width:20px; margin-right:5px;}
.card_con span.card_hd button.btn_card_certf { display:inline-block; float:right; color:#1fafd2; border:1px solid #1fafd2; padding:7px 10px; border-radius:50px; background:#fff; font-weight:0.9em; margin:10px 0 0 0;}
.card_con span.card_incon { display:inline-block; width:100%; background:#fff; padding:20px 30px;}
.card_con span.card_incon_bline {border:1px solid #ebebeb; border-top-width:none; border-bottom-left-radius:10px; border-bottom-right-radius:10px; }
.card_con span.card_incon span.stitle {display:inline-block; width:100%; padding-bottom:10px;}
.card_con span.card_incon span.sinfo {display:inline-block; width:100%; color:#ccc;}
.card_con span.card_incon_last {border-radius:0 0 10px 10px}

.card_state {display:inline-block; width:100%; background:#fff; padding:10px 25px; border-radius:10px;border:1px solid #ebebeb;}
.card_state span.card_name {display:inline-block; float:left; line-height:36px;}
.card_state span.card_number {display:inline-block; float:right; line-height:36px;}
.card_state span.card_btn { display:inline-block; float:right;}
.card_state span.card_btn button.btn_delete { display:inline-block; background:#eee; height:36px; line-height:36px; border:0px; padding:0px 15px; font-size:1.1em; margin-left:20px;}

.card_box ul.sub_padding_top {padding-top:5px;}
.card_box li table.partnercard_info { width:100%; padding-top:10px;}
.card_box li table.partnercard_info td.title{color:#ff4b00; font-weight:bold;}

.card_box li table.partnercard_info_sub { width:100%; line-height:15px; padding-top:2px; }
.card_box li table.partnercard_info_sub tr.subtitle{color:#888888; font-weight:bold;}
.card_box li table.partnercard_info_sub td.hypen_width{ width:10px;}
.card_box li table.partnercard_info_sub tr.desc{color:#888888;}


/* Button Box */
li.btn_box {width:calc(100% - 40px); position:absolute; bottom:20px;}
.member_form li.btn_box2 {width:calc(100%); padding:20px; background:rgba(255, 255, 255, 0.9); position:fixed; bottom:0px; left:0%;}

/* Button Type */
.btn_st_bmargin {margin-bottom:10px;}
.btn_st_f1f0f5 { display:inline-block; width:100%; background:#f1f0f5; height:50px; line-height:50px; font-size:1.3em; border:0px; color:#9a9a9a;}
.btn_st_2e303f { display:inline-block; width:100%; background:#2e303f; height:50px; line-height:50px; font-size:1.3em; border:0px; color:#fff;}
.btn_st_ffffff { display:inline-block; width:100%; background:#fff; height:50px; line-height:50px; font-size:1.3em; border:0px; color:#000;}
.btn_st2_ffffff { display:inline-block; width:100%; background:#fff; height:50px; line-height:50px; font-size:1.3em; border:1px solid #000; color:#000;}
.btn_st1_1fafd2 { display:inline-block; background:#1fafd2; height:45px; line-height:45px; font-size:1.1em; border:0px; color:#fff; padding:0 30px; border-radius:50px; font-weight:bold;}

.btn_st_49 {width:49% !important; margin-right:2%; float:left;}
.btn_st_50 {width:49% !important; float:left;}

footer { padding:0px 20px 20px 20px; text-align:center; background:#ebebed;}
footer .btn_st_2e303f { display:inline-block; width:100%; background:#2e303f; height:50px; line-height:50px; font-size:1.3em; border:0px; color:#fff;}

`;

// 20201204 기준 현재 퍼블리싱 안나옴
class ManageCardView extends Component {
    state = {
        cardNo: '',
        expiredData: '',
        CustomerNo: store.getState().global_data.loginedData.CustomerNo,
        itemList: [],
        defaultCardData: {},
        ostype: 'A',
        loading:0,
        hiddenFlag:false,
    }

    getCardList = async () => {
        try {
            const { cookies } = this.props;
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            let getOsType = await RnInterface.getData('devicetype')
    
            let resultData = await PaymentApi.GetMyPaymentCards(
                this.state.CustomerNo,
                cookies.get('ostype'),
                cookies.get('deviceid'),
                cookies.get('login_encrypt')
            )
            console.log(resultData)
    
            if (resultData.Result === 'success') {
                let tmpDefaultCardData = {};
                for (let i in resultData.ItemList) {
                    let tmptmptmp = resultData.ItemList[i];
    
                    if (resultData.ItemList[i].IsDefault) {
                        tmpDefaultCardData = resultData.ItemList[i];
                        break;
                    }
                }
    
                this.setState({
                    itemList: resultData.ItemList,
                    defaultCardData: tmpDefaultCardData,
                    ostype: getOsType,
                    loading:1
                })
            } else {
                await calertPromise('데이터 로드에 실패하였습니다. 다시 시도해주세요.')
                this.setState({loading:1})
            }
        } catch(e) {
            await calertPromise('데이터 취득 도중 오류가 발생하였습니다. 관리자에게 문의해주세요.')
            this.setState({loading:1})
        }
        
    }

    componentDidMount() {
        this.getCardList();
    }

    handleDeleteCard = async (cardid) => {
        try {
            this.setState({loading:0})
            const { cookies } = this.props;
            let MallReserved = this.state.defaultCardData.MallReserved;

            // 현재 예약한 정보가 존재하면 삭제 못하도록 처리해야한다.
            let reserveResult = await ReserveApi.GetDrivingReservationList(
                this.state.CustomerNo,
                1, 10, 10,
                cookies.get('ostype'),
                cookies.get('deviceid'),
                cookies.get('login_encrypt')
            )

            if (reserveResult && reserveResult.Result === 'success') {
                if (reserveResult.ItemList.length) {
                    await calertPromise('현재 예약중인 건이 존재하므로\n 삭제할 수 없습니다.');
                    this.setState({loading:1})
                } else {
                    // 제휴카드 관련 데이터가 존재한다면
                    if (MallReserved.length) {
                        // 제휴카드 등록한 정보를 삭제해야한다.
                        let partnerCardDeleteResult = await PaymentApi.cardDeleteEBridge(
                            this.state.CustomerNo,
                            MallReserved, 
                            cookies.get('ostype'),
                            cookies.get('deviceid'),
                            cookies.get('login_encrypt')
                        )
                        console.log(partnerCardDeleteResult)
                        // 리스폰스값이 success라면
                        if (partnerCardDeleteResult.success) {
                            // 그리고 등록한 모시러쪽 카드 정보도 삭제해야한다.
                            let resultData = await PaymentApi.SetDeletePaymentCard(
                                this.state.CustomerNo,
                                cardid,
                                cookies.get('ostype'),
                                cookies.get('deviceid'),
                                cookies.get('login_encrypt')
                            )

                            if (resultData.Result === 'success') {
                                this.setState({loading:1})
                                await calertPromise('삭제가 완료되었습니다.')
                                this.getCardList();
                            } else {
                                this.setState({loading:1})
                                await calertPromise('삭제하지 못했습니다. 다시 시도해주세요.')
                            }
                        } 
                        // 실패했을 경우
                        else {
                            this.setState({loading:1})
                            await calertPromise('삭제하지 못했습니다. 다시 시도해주세요.')
                        }
                    } 
                    else {
                        let resultData = await PaymentApi.SetDeletePaymentCard(
                            this.state.CustomerNo,
                            cardid,
                            cookies.get('ostype'),
                            cookies.get('deviceid'),
                            cookies.get('login_encrypt')
                        )

                        if (resultData.Result === 'success') {
                            this.setState({loading:1})
                            await calertPromise('삭제가 완료되었습니다.')
                            this.getCardList();
                        } else {
                            this.setState({loading:1})
                            await calertPromise('삭제하지 못했습니다. 다시 시도해주세요.')
                        }
                    }
                }
            } else {
                await calertPromise('카드 삭제에 실패하였습니다. 잠시후 다시 시도해주세요.')    
                this.setState({loading:1})
            }          
            
        } catch(e) {
            await calertPromise('삭제 도중 오류가 발생하였습니다. 관리자에게 문의해주세요.')
            this.setState({loading:1})
        }
        
    }

    handleSetDefaultCard = async (cardid) => {
        try {
            this.setState({loading:0})
            const { cookies } = this.props;
            let resultData = await PaymentApi.SetDefaultPaymentCard(
                this.state.CustomerNo,
                cardid,
                cookies.get('ostype'),
                cookies.get('deviceid'),
                cookies.get('login_encrypt')
            )
    
            if (resultData.Result === 'success') {
                await calertPromise('기본 카드 변경이 완료되었습니다.')    
                this.getCardList();
                this.setState({loading:1})
            } else {
                await calertPromise('기본 카드를 변경하지 못했습니다.')
                this.setState({loading:1})
            }
        } catch(e) {
            await calertPromise('기본 카드 변경 도중 오류가 발생하였습니다. 관리자에게 문의해주세요.')
            this.setState({loading:1})
        }
        
    }


    /*
    * 필요한 API
    http://apitest.client.mosiler.com/Help/Api/GET-GetMyPaymentCards_customerNo
    http://apitest.client.mosiler.com/Help/Api/POST-SetPaymentCard
    http://apitest.client.mosiler.com/Help/Api/POST-SetDeletePaymentCard
    http://apitest.client.mosiler.com/Help/Api/POST-SetDefaultPaymentCard

    https://pg.smartro.co.kr/application/guide/process/
    스마트로 API를 이용해서 카드를 등록하고 등록이 완료되면 result값을 가지고 setPaymentCard처리를 하는듯하다.
    처리가 끝나면 지금 등록한게 최초라면 이 값을 default로서 설정해준다.

    카드등록 모드가 있고, 결제모드가 있는듯..

    */

    handleGotoBack = () => { this.props.history.replace('/main') }
    handleGotoHome = () => { this.props.history.replace('/main') }

    handleCardNo = (e) => { this.setState({ cardNo: e.target.value }) }
    handleExpiredData = (e) => { this.setState({ expiredData: e.target.value }) }
    handleRegistCard = () => {

        if (this.state.ostype === 'I') {
            this.props.history.push(`/card_regist?customerno=${this.state.CustomerNo}`)
        } else {
            this.props.history.push('/card_regist')
        }

    }

    handleRegistCard2 = async () => {
        const { cookies } = this.props;
        try {
            let data = await PaymentApi.test01('46588775', this.state.CustomerNo, cookies.get('ostype'),
            cookies.get('deviceid'),
            cookies.get('login_encrypt'))
            await calertPromise(data);
        }catch(e) {
            await calertPromise(JSON.stringify(e));
        }
        
    }

    //as is 앱에서 goToRegistrationPartnerCard()를 찾아서 처리방법을 알아야 한다.
    handlePartnerAuth = async () => {
        const {CustomerNo, Email, CustomerName} = store.getState().global_data.loginedData
        const {cookies} = this.props;
        const { defaultCardData } = this.state;
        let partnerUseYn = false;       // 파트너카드 유무
        let tmpPartnerData = {};

        this.setState({loading:0})
        let cardinfoResult = await PaymentApi.CardInfoEBridge(
            CustomerNo,
            CustomerName,
            Email,
            defaultCardData.DisplayCardNo,
            cookies.get('ostype'),
            cookies.get('deviceid'),
            cookies.get('login_encrypt')
        )

        if (cardinfoResult && cardinfoResult.success) {            
            partnerUseYn = true;
            const resDataTmp = JSON.parse(cardinfoResult.data);
            const resData = resDataTmp.content;
            tmpPartnerData = {
                ebridgeUrl: resData.card_list[0].card_register_url,
                mallReserved: resData.card_list[0].uid,
                paymentCardId: defaultCardData.Id
            }
            console.log(resDataTmp)
            
            console.log(resData);
        } 
        
        // 파트너 카드 유무가 true일때
        if (partnerUseYn) {
            this.setState({loading:1})
            // 파트너 카드 등록 전용 화면으로 라우팅 실시. 라우팅시 데이터를 넘겨야 한다.
            this.props.history.replace({
                pathname:'/partner_card_regist',
                state:tmpPartnerData
            })
            
        } 
        else {

        }


        
        // calert('제휴카드')
    }



    render() {

        const CardListComponent = (tmpArr) => {
            return tmpArr.map((data, idx) => {
                return (
                    <>
                        <span className="card_state">
                            <span className="card_name">{data.IsDefault ? '[기본]' : ''}{data.IssuerCardNm}</span>
                            <span className="card_btn">
                                <button className="btn_delete" onClick={() => {
                                    this.handleDeleteCard(data.Id)
                                }}>
                                    삭제
                            </button>
                            </span>
                            <span className="card_number">{data.DisplayCardNo}</span>
                        </span>

                        {/* <span className="bk_name">
                        { data.IsDefault ? '' : (<button onClick={()=>{ this.handleSetDefaultCard(data.Id) }}>대표카드지정</button>) }
                        { data.IsDefault ? '대표' : ''} {data.IssuerCardNm} : {data.DisplayCardNo} 
                    <button onClick={()=>{
                        this.handleDeleteCard(data.Id)
                    }}>삭제</button></span> */}
                    </>
                )
            });
        }

        return (<>
        {!this.state.loading ? (<Loading />) : ''}
            <GlobalStyle />
            <Header
                title="카드관리"
                onBackButton={this.handleGotoBack}
                onHomeButton={this.handleGotoBack} />
 
            <main id="Contents">
                <div className="inner-container bg-gray">
                    <div clclassNameass="form vertical">
                        <div className="form-body">
                            <div className="form-title">
                                <strong>사용중 결제카드</strong>
                            </div>
                            <div className="form-cont mar-t10">
                                <div className="row card_con">
                                    <span className="card_hd">
                                        <img src={icon_card} alt="" /> {this.state.itemList.length ? `기본카드 (${this.state.defaultCardData.IssuerCardNm})` : '카드 정보를 입력해 주세요.'}
                                        {(Object.keys(this.state.defaultCardData).length && this.state.defaultCardData.PsBinnumChk !== "" && this.state.defaultCardData.PsBinnumChk !== null) ? (
                                            <>
                                                {(Object.keys(this.state.defaultCardData).length && this.state.defaultCardData.MallReserved !== "") ? (
                                                    <button className="btn_card_certf">제휴카드 인증완료</button>
                                                ) : (
                                                        <button className="btn_card_certf" onClick={this.handlePartnerAuth}>제휴카드 인증</button>
                                                )}
                                            </>

                                        ) : (
                                                ''
                                        )}
                                    </span>
                                    <span className="card_incon card_incon_bline">
                                        <span className="stitle">카드번호</span>
                                        <span className="sinfo text-larger text-bold">
                                            {this.state.defaultCardData.DisplayCardNo ? this.state.defaultCardData.DisplayCardNo : '(없음)'}
                                        </span>
                                    </span>
                                </div>
                                <div className="row mar-t10">
                                    {CardListComponent(this.state.itemList)}
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>  
                {/* 안내문구 */}
                <div className="component-wrap space-top">
                    <section className="information-text on">
                        <h1 className="information-head">
                            <button type="button" className="btn-viewmore" onClick={()=>{
                                this.setState({
                                    hiddenFlag:!this.state.hiddenFlag
                                })
                            }}>
                                <i className="ico ico-info"></i>
                                꼭 확인해주세요.
                                <i className={this.state.hiddenFlag ? "icon arrow" : "icon arrowdown"}></i>
                            </button>
                        </h1>
                        <div className="information-body" style={{display:this.state.hiddenFlag ? 'block' : 'none'}}>
                            <div className="info-desc">
                                <h3 className="info-stit">마스터 프리미엄, 하나 제휴카드 혜택안내</h3>
                                <ul className="text-sub">
                                    <li>
                                        <strong>마스터 프리미엄, 하나카드 바우처문의</strong>는 <span className='text-import'>모시러 컨시어지 카카오톡</span>으로 문의 부탁드립니다.<br />
                                    </li>
                                </ul>
                            </div>                                    

                            {/* <div className="info-desc">
                                <h3 className="info-stit">제휴카드 혜택안내</h3>
                                <ul className="text-sub">
                                    <li>
                                        <strong>마스터 프리미엄카드</strong><br />
                                        <span className="text-import">모든 서비스 상시 10% 할인쿠폰 발급</span>
                                    </li>
                                    <li>
                                        <strong>하나카드</strong><br />
                                        <span className="text-import">공항홈발렛 서비스 상시 10% 할인쿠폰 발급</span>
                                        <br />(결제수단카드 변경 시 사용 불가)
                                    </li>
                                </ul>
                            </div>    */}
                            {/*
                            <div className="info-desc">
                                <h3 className="info-stit">할인쿠폰 사용방법</h3>
                                <ul className="text-sub">
                                    <li>예약결제 시 쿠폰선택</li>
                                    <li>쿠폰관리 메뉴에서 보유쿠폰 확인가능</li>
                                    <li className="text-darkblue">마스터 프리미엄카드 할인과 골프장 예약 할인은 중복 사용이 불가(2/1부터 ~ 4/30까지 : 출발일 기준)</li>
                                </ul>
                            </div>   
                            */}                                                               
                        </div>
                    </section> 
                </div>   
                {this.state.itemList.length === 0 ? (

                <div className="component-wrap">
                    <div className="btn-area btn-group-fixed vertical">
                        <button type="button" className="btn large secondary"  onClick={this.handleRegistCard}><span>새카드 등록하기</span></button>
                    </div>
                </div> 

                ) : null}                                                             
            </main>    
 
        </>
        );
    }
};

export default withCookies(ManageCardView);
