import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import Header from '../../components/Common/Header'
import Terms from '../../constantData'
import Iframe from 'react-iframe'
import { createGlobalStyle } from "styled-components";
import RnInterface from '../../lib/RnInterface'

// import AppSettingApi from '../../lib/AppSettingApi';

// import { NavLink } from 'react-router-dom';
// import store from '../../reduxStore'
// import {global_data_async} from '../../modules/Global/global_data'

import '../../css/default.css';
import '../../css/member.css';
// todocss
const GlobalStyle = createGlobalStyle`
.appsettingview_container { display:flex; width:100%; height:100vh; }
.iframe_area { width:100%; height:100%; border:0px;}
.iframe_area::-webkit-scrollbar {
    width:5px;
}

.iframe_area::-webkit-scrollbar-thumb {
    background-color: #2f3542;
    border-radius: 15px;
  }
`;

class AppSettingTermsView extends Component {
    async componentDidMount() {
        try {
            await RnInterface.AndroidURLChange(this.props.location.pathname)
        }
        catch(e){
            console.log(e)
        }
    }
    
    getStateTitle = (state) => {
        let retStr = '';
        switch(state) {
            case 'service':
                retStr = '이용약관';
                break;
            case 'privacy':
                retStr = '개인정보처리방침';
                break;
            case 'location':
                retStr = '위치정보수집약관';
                break;
            default:
                break;
        }
        return retStr;
    }
    handleBack = () => { this.props.history.goBack() }
    handleHome = () => { this.props.history.replace('/main') }
    render() {

        return (
            <>
            <GlobalStyle/>
            <Header 
            title={`${this.getStateTitle(this.props.match.params.state)}`}
            onBackButton={this.handleBack}
            onHomeButton={this.handleHome}
            />
            <div className="appsettingview_container hd_margin_top">
            <Iframe 
                    url={Terms[this.props.match.params.state].web}
                    id="iframe_id"
                    className="iframe_area "
                />
            </div>
                
            </>
        );
    }
};

export default withCookies(AppSettingTermsView);
