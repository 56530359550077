import React, { useEffect } from 'react';
import {createGlobalStyle} from 'styled-components'

const GlobalStyle = createGlobalStyle`
.float-label { width:100%; }
/* .float-label label { position: absolute; padding-left: 11px;  top:-16px; color: #999; pointer-events: none; font-weight: bold; transform: translate(0,26px) scale(1); transform-origin: top left; transition: all 0.2s ease-out; } */
.float-label label { position: absolute; transform: translate(10px,12px) scale(1); color: #999; pointer-events: none; font-weight: bold; transition: all 0.2s ease-out;}
.float-label:focus-within label { transform: translate(0px,-2px) scale(0.75); }
.float-label .active-label {  transform: translate(0px,-2px) scale(0.75); }
.float-label input[type=text] { height:2.7rem; }

`;

const FloatLabelInput = (props) => {

    console.log(props);
    return (
        <>

            {/* 
            참고 링크
            https://dev.to/rafacdomin/creating-floating-label-placeholder-for-input-with-reactjs-4m1f
            */}
            <GlobalStyle />
            <div className="float-label" style={props.secondFlag ? {marginLeft:'5px'} : {}}>
                <label htmlFor={props.inputTypeId} className={`${props.value.length ? 'active-label':''} `}>{props.labelText}</label>
                <input type="text" id={props.inputTypeId} onChange={props.onChange} value={props.value} className={`${props.className} W100`} />
            </div>
            
        </>
    );
};

export default FloatLabelInput;

