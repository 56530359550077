import React, { Component } from 'react';
import AddressApi from '../../lib/AddressApi';
// import { createGlobalStyle } from "styled-components";
import Util, { 
    getAddressFavoriteDataLS,
    setAddressFavoriteDataLS 
} from '../../lib/Util';
import RnInterface from '../../lib/RnInterface'
import store from '../../reduxStore';
import ReserveApi from '../../lib/ReserveApi'
import ScrollTopBtn from '../../components/Common/ScrollTopBtn'

import axios from 'axios'
import querystring from 'qs'

import {
    start_location_service,
    end_location_service,
    step_over_add_service,
    step_over_update_service,
    all_location_save
} from '../../modules/Reserve/LocationSelector_ReduxModule'

import {
    address_pick_service
} from '../../modules/Global/Address_ReduxModule'

import {
    STATE_FAVORITE_HOME_ADD,
    STATE_FAVORITE_HOME_MODIFY,
    STATE_FAVORITE_COMPANY_ADD,
    STATE_FAVORITE_COMPANY_MODIFY,

    KAKAO_PLACE_SEARCH_URL,
    KAKAO_PLACE_SEARCH_KEY,
    GOOGLE_PLACE_KEY,
    GOOGLE_PLACE_URL,

} from '../../constantData';

import '../../css/default.css';
import '../../css/booking.css';

import moment from 'moment';
import LoadingInner from '../../components/Common/LoadingInner'


const PLACE_FLAG = 0;                   // 장소별
const NEW_OLD_ADDRESS_FLAG = 1;         // 주소(신주소, 구주소)별

const MENU_BTN_LATEST_SEARCH = '0';       // 최근장소 클릭시
const MENU_BTN_LATEST_LOCATION = '1';     // 최근경로 클릭시

const DEFAULT_CENTER_COORDS = {
    latitude: 37.3595669,
    longitude: 127.1054065,
};

const NO_ROAD_ADDRESS_STR = '(도로명없음)';
const NO_JIBUN_ADDRESS_STR = '(지번없음)';




class AddressSearchSub extends Component {

    state = {
        redirectUri: '',
        state: '',
        addressName: '',
        addressList: [],
        historyAddressList: [],         // 최근 장소 리스트(LocalStorage)
        historyLocationList: [],         // 최근 경로 리스트(LocalStorage)
        addressType : PLACE_FLAG,
        menuBtnState: MENU_BTN_LATEST_SEARCH,
        addressListLoading: 1,
        menuIdFlag : 0,                 // menuIdFlag가 0일때는 최근장소, 최근경로, 주소창 전부 표시, 1일때는 최근경로만 표시
        
    }

    typingTimer = null;
    doneTypingInterval = 500;

    

    setStateAsyncAddressName = (_addressName) => {
        return new Promise((resolve) => {
            this.setState({ addressName: _addressName }, () => {
                resolve(true)
            })
        })
    }

    setStateAsyncAddressList = (_addressList) => {
        return new Promise((resolve) => {
            this.setState({ addressList: _addressList }, () => {
                resolve(true)
            })
        })
    }

    setStateAddressListLoading = () => {
        return new Promise((resolve) => {
            this.setState({ addressListLoading: 0 }, () => {
                resolve(true)
            })
        })
    }
    setStateAddressListLoadingEnd = () => {
        return new Promise((resolve) => {
            this.setState({ addressListLoading: 1 }, () => {
                resolve(true)
            })
        })
    }

    getHeaderTitleAddressSearch = () => {
        let data = Util.getQueryStringObject();
        let retData = '';
        try {
            if (data.state === STATE_FAVORITE_HOME_ADD) {
                retData = '집 주소 등록';
            }
            else if(data.state === STATE_FAVORITE_HOME_MODIFY) {
                retData = '집 주소 변경';
            }
            else if(data.state === STATE_FAVORITE_COMPANY_ADD) {
                retData = '회사 주소 등록';
            }
            else if(data.state === STATE_FAVORITE_COMPANY_MODIFY) {
                retData = '회사 주소 변경';
            }
            else {
                retData = '';
            }
            
        }
        catch(e) {
            console.log(e)
            retData = '';
        }
        return retData;
    }


    // 검색 개선버전.
    // 검색시 타이머 적용. 콜수 최소화
    asyncAddress2 = async (_addressName) => {
        await this.setStateAsyncAddressName(_addressName)
        await this.setStateAddressListLoading()
        if (!this.state.addressName.length) {
            await this.setStateAsyncAddressList([])
        }

        // 변경이 있을때마다 기존의 타임아웃을 지워버리고, 아래에서 다시 타임아웃을 등록하고,
        // 입력이 없을때 timeout이 발생한 다음 timeout종료.
        clearTimeout(this.typingTimer)

        if (this.state.addressName) {
            
            // addressName이 존재할때 timeout을 건다.
            this.typingTimer = setTimeout(async()=>{
                // 장소별은 kakao api 사용
                if (this.state.addressType === PLACE_FLAG) {
                    let adr_result = await AddressApi.requestPlace(_addressName)
                    
                    // 장소 검색 결과 0이라면
                    if (adr_result.meta.total_count === 0) {
                        // 주소로 검색
                        adr_result = await AddressApi.requestAddress(_addressName)
                    }
                    
                    await this.setStateAsyncAddressList(adr_result.documents);
                    await this.setStateAddressListLoadingEnd()
                }
            }, this.doneTypingInterval)

        }
    }

    asyncAddress = async (_addressName) => {
        
        await this.setStateAsyncAddressName(_addressName)
        
        // 장소별은 kakao api 사용
        if (this.state.addressType === PLACE_FLAG) {
            let adr_result = await AddressApi.requestPlace(_addressName)
            
            // 장소 검색 결과 0이라면
            if (adr_result.meta.total_count === 0) {
                // 주소로 검색
                adr_result = await AddressApi.requestAddress(_addressName)
            }
            
            await this.setStateAsyncAddressList(adr_result.documents);
        }
        
    }

    handleChangeAddress = (e) => {
        this.asyncAddress(e.target.value);
    }

    handleChangeAddress2 = (e) => {
        this.asyncAddress2(e.target.value);
    }

    handleDelAddressName = (e) => {
        this.setState({
            addressName: '',
            addressList: [],
        })
    }

    // 검색결과 place_name이 없을때에는 road_address_name 표시. 
    // road_address_name가 없을때에는 address_name 표시
    strongName = (data) => {
        let retData = '';
        try {
            // data.place_name
            if (data.place_name !== undefined) {
                retData = data.place_name
            } else if (data.road_address_name !== undefined) {
                retData = data.road_address_name
            } else {
                retData = data.address_name;
            }
        }
        catch(e){
            console.log(e)
            retData = '';
        }
        return retData;
        
    }

    getRoadAddressStr = (data) => {
        let retData = NO_ROAD_ADDRESS_STR;
        try {
            // data.address_type값이 존재한다면 address.json response.
            if (data.address_type !== undefined) {
                // 해당되면 값 갱신
                if (data.address_type === 'ROAD_ADDR') {
                    retData = data.address_name;
                } else if (data.road_address.address_name !== undefined) {
                    retData = data.road_address.address_name;
                }
                // 해당되지 않으면 기본값으로 리턴
            } 
            // keyword.json response처리를 하고 있는가?
            else {
                // 해당되면 값 갱신
                if (data.road_address_name !== undefined && data.road_address_name !== '') {
                    retData = data.road_address_name;
                }
                // 해당되지 않으면 기본값으로 리턴
            }
        }
        catch(e){
            console.log(e)
            retData = NO_ROAD_ADDRESS_STR;
        }
        return retData;       
    }

    getJibunAddressStr = (data) => {
        let retData = NO_JIBUN_ADDRESS_STR;
        try {
            // address.json response 처리
            if (data.address_type !== undefined) {
                // 해당되면 값 갱신
                if (data.address_type === 'REGION_ADDR') {
                    retData = data.address_name;
                }
                else if (data.address.address_name !== undefined) {
                    retData = data.address.address_name;
                }
                // 해당되지 않으면 기본값으로 리턴
            } 
            // keyword.json response처리
            else {
                // 해당되면 값 갱신
                if (data.address_name !== undefined && data.address_name !== '') {
                    retData = data.address_name;
                }
                // 해당되지 않으면 기본값으로 리턴
            }

            
        }
        catch(e){
            console.log(e)
            retData = NO_JIBUN_ADDRESS_STR;
        }
        return retData;       
    }
    
    // 최종 주소 문자열을 가공한다. (주소 검색시)
    // 도로명 O || 지번 X  => 도로명 + 장소명
    // 도로명 X || 지번 O  => 지번명 + 장소명
    // 도로명 O || 지번 O  => 도로명 + 장소명
    // 도로명 X || 지번 X  => 장소명
    setFinishAddressStr = (data) => {
        let retData = '';
        let tmpPlaceName = ( data.place_name !== undefined ) ? data.place_name : ''
        console.log(this.state.addressName)
        // 도로명이 있을 경우
        if (this.getRoadAddressStr(data) !== NO_ROAD_ADDRESS_STR) {
            retData = `${this.getRoadAddressStr(data)} ${tmpPlaceName}`;
        } 
        // 도로명이 없고 지번이 있을 경우
        else if (this.getJibunAddressStr(data) !== NO_JIBUN_ADDRESS_STR) {
            retData = `${this.getJibunAddressStr(data)} ${tmpPlaceName}`;
        } 
        // 도로명도 없고, 지번도 없을 경우
        else {
            retData = `${tmpPlaceName}`;
        }
        
        return retData;
        
    }   

    // 최종 주소 문자열을 가공한다. (최근검색측 처리(localStorage))
    // 도로명 O || 지번 X  => 도로명 + 장소명
    // 도로명 X || 지번 O  => 지번명 + 장소명
    // 도로명 O || 지번 O  => 도로명 + 장소명
    // 도로명 X || 지번 X  => 장소명
    setFinishAddressStrHistory = (data) => {
        let retData = '';
        let tmpPlaceName = ( data.place_name !== undefined ) ? data.place_name : ''
        // 도로명이 있을 경우
        if (data.new_address !== NO_ROAD_ADDRESS_STR) {
            retData = `${data.new_address} ${tmpPlaceName}`;
        } 
        // 도로명이 없고 지번이 있을 경우
        else if (data.old_address !== NO_JIBUN_ADDRESS_STR) {
            retData = `${data.old_address} ${tmpPlaceName}`;
        } 
        // 도로명도 없고, 지번도 없을 경우
        else {
            retData = `${tmpPlaceName}`;
        }
        
        return retData;
        
    }   

    async componentDidMount() {
        try {
            await RnInterface.AndroidURLChange(this.props.location.pathname)

            let tmpHistoryAddressList = localStorage.getItem('history_address_search');     // 최근 장소 리스트 취득
            let tmpHistoryLocationList = localStorage.getItem('history_location_list');     // 최근 경로 리스트 취득

            let data = Util.getQueryStringObject();

            this.setState({
                historyAddressList: (tmpHistoryAddressList !== null) ? JSON.parse(tmpHistoryAddressList) : [],
                historyLocationList : (tmpHistoryLocationList !== null) ? JSON.parse(tmpHistoryLocationList) : [],
                redirectUri: data.redirect,
                state: data.state,
                menuBtnState: data.menuid,
                menuIdFlag: data.menuid,
            })

        } catch (e) {
            console.log(e)
        }

        // console.log(JSON.parse(localStorage.getItem('history_address_search')));
    }

    handleAddressSearchResultClickEvent = async (data) => {
        try {
            let tmpFavoData = getAddressFavoriteDataLS();
            let qsData = Util.getQueryStringObject();
            switch(qsData.state) {
                case STATE_FAVORITE_HOME_ADD:
                case STATE_FAVORITE_HOME_MODIFY:
                    tmpFavoData.homeAddress = this.setFinishAddressStr(data);
                    tmpFavoData.homeLat = data.y;
                    tmpFavoData.homeLng = data.x
                    break;
                case STATE_FAVORITE_COMPANY_ADD:
                case STATE_FAVORITE_COMPANY_MODIFY:
                    tmpFavoData.companyAddress = this.setFinishAddressStr(data);
                    tmpFavoData.companyLat = data.y
                    tmpFavoData.companyLng = data.x
                    break;
                default:
                    break;
            }
            setAddressFavoriteDataLS(tmpFavoData);
            this.props.history.goBack();
        }
        catch(e) {
            console.log(e)
        }        
        
    }

    render() {
        // 검색 결과 리스트
        let tmpAddressSearchListComponent = (arr) => {
            return arr.map((data, idx) => {
                return (
                    /*
                        결과값 클릭시 수행해야할 사항
                        1. 주소 관련 redux쪽으로 데이터를 전달해야함
                        2. localStorage - history_address_search에 맨 첫번째 배열로 넘겨야한다.
                    */

                    <li className="item" onClick={()=>{
                        this.handleAddressSearchResultClickEvent(data)
                    }}>
                        <div className="grid cross-center">
                            <div className="col left">
                                {/* route-tit에 검색어와 매칭되는 부분은 강조되도록 <em></em> 안에 내용을 집어넣는다.(이부분은 공수가 조금 걸릴것으로 예상.)  */}
                                <strong class="route-tit"> {this.strongName(data)} </strong>
                                <p>{ this.getRoadAddressStr(data)}</p>
                                <p>(지번) {this.getJibunAddressStr(data)}</p>
                            </div>
                        </div>
                    </li>                    
                )
            });
        }


        return (
            <>
                {/* Header */}
                <header id="Header" className={"no-line"}>
                    <button type="button" className="btn-top-close" onClick={() => { 
                        this.props.history.goBack()
                    }}>
                        <span className="blind">닫기</span>
                    </button>
                    <h1>{this.getHeaderTitleAddressSearch()}</h1>
                </header>    

                <main id="Contents">
                    {/* 검색어 입력 영역 begin */}
                    <div className="search-box">
                        <div className="form-controls flexible">
                            <span className="input-group has-prepend has-append">
                                <span className="prepend"><button type="button"><span><i className="ico ico-search">search</i></span></button></span>
                                <input 
                                    type="text" 
                                    placeholder="장소, 주소 검색" 
                                    className="input type1 search" 
                                    value={this.state.addressName} 
                                    onChange={this.handleChangeAddress2} 
                                    // onKeyUp={this.handleChangeAddress2} 
                                    />
                                <span className="append"><button type="button" onClick={this.handleDelAddressName}><span><i className="ico ico-search-close">close</i></span></button></span>
                            </span>
                        </div>

                    </div>
                    {/* 검색어 입력 영역 end */}  
                    
                    {/* 검색어 목록 영역 begin */}
                    <div className={"component-wrap space-top"} >
                        {this.state.addressName.length ? (
                            <>
                            <div className="count-filter-group grid cross-center bg-white">
                                <div className="count-area">
                                    장소명 <em>'{this.state.addressName}'</em> 검색결과
                                </div>
                            </div>	
                            <div className="route-list-container">
                                <ul className="list route">
                                    {!this.state.addressListLoading ? (
                                        <>
                                            <LoadingInner />
                                        </>
                                    ) : (
                                        <>
                                            {tmpAddressSearchListComponent(this.state.addressList)}
                                        </>
                                    )}
                                    
                                </ul>
                            </div>
                            </>
                        ) : (
                            <>
                                <div class="no-data">
                                    <p class="text-main">주소가 없습니다.</p>
                                    <p class="text-main">주소 검색란에 주소를 검색해주세요.</p>
                                </div>
                            </>
                        )}
                    </div>  
                    {/* 검색어 목록 영역 end */}

                </main>
                <ScrollTopBtn position={'normal'}/>
            </>
        );
    }
};


export default AddressSearchSub;
