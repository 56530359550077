import React, { useEffect, useState } from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ArcElement
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import DataLabels  from 'chartjs-plugin-datalabels'
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
	DataLabels
);



function App() {
	const pieOptions = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top',
			},
			title: {
				display: true,
				text: 'Chart.js Pie Chart'
			},
			datalabels: {
				formatter: (val, context) => {
					let idx = context.dataIndex
					console.log(context.chart)
					return context.chart.data.labels[idx] + ' ' + val;

				}
			}
		}
	}
	const data = {
		labels: ['이용', '예약', '잔여', '초과'],
        datasets: [
            {
                label: '# of Votes',
                data: [10,20,30,40],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(38, 116, 224, 0.2)',
                    'rgba(36, 189, 54, 0.2)',
                    'rgba(236, 189, 54, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(38, 116, 224, 1)',
                    'rgba(36, 189, 54, 1)',
					'rgba(236, 189, 54, 1)',
                ],
                borderWidth: 1,
            },
        ],
	};

	return (
		<main id="Contents">
			<Pie options={pieOptions} data={data} />
		</main>
	);
}

export default App;
