import React, { Component } from 'react';
import { withLastLocation } from 'react-router-last-location';

import { withCookies } from 'react-cookie';

import store from '../../reduxStore'

import ReserveApi from '../../lib/ReserveApi'
import NoticeApi from '../../lib/NoticeApi'
import LocationSelectorContainer from '../../containers/Reserve/LocationSelectorContainer'
import {
    plusSecondsMomentWithFormatCustom
} from '../../lib/TimeApi'
import Header from '../../components/Common/Header'

import Util, {
    getAddressFavoriteDataLS,
    setAddressFavoriteDataLS,
    getLocalStorage,
    setLocalStorage
} from '../../lib/Util'

import {
    FAVORITE_INIT_TEMPLATE,
    CARINFO_PRESET_INIT_TEMPLATE,
    COMMENT_PRESET_INIT_TEMPLATE,
    
} from '../../constantData'

import Loading from '../../components/Common/Loading'
import RnInterface from '../../lib/RnInterface'

import TimeSelector2 from '../../components/Reserve/TimeSelector2';
import { calert, calertPromise, calertPromise2, calertImageLinkPopup, calertPromise3 } from '../../lib/Util'
import moment from 'moment';

import ScrollTopBtn from '../../components/Common/ScrollTopBtn'
import {getReserveAvaliableDate, isReserveAvaliableDateValidateChk} from '../../lib/TimeApi'

import '../../css/default.css';
import '../../css/booking.css';
import "react-datepicker/dist/react-datepicker.css";

import icon_notice from '../../img/booking/icon_notice.svg'


import { NavLink } from 'react-router-dom';
// import AddressApi from '../../lib/AddressApi';
// import TimeSelectorContainter from '../../containers/Reserve/TimeSelectorContainer'

// import btn_prev from '../../img/btn/btn_prev.svg'
// import btn_home from '../../img/btn/btn_home.svg'

import icon_arrow_down from '../../img/booking/icon_arrow_down.svg'
import global_data from '../../modules/Global/global_data';
// import eventImg from '../../img/event/golf/event.png'
// import eventImg2 from '../../img/event/golf/event2.png'
import eventImg2 from '../../img/event/golf/ski_m_btn.png'



import FloatLabelInput from '../../components/Common/Input/FloatLabelInput.js'
import Modal from 'react-modal'
// import DateSelector from '../../components/Reserve/DateSelector'
// import TimeSelector from '../../components/Reserve/TimeSelector'

// import icon_arrow_right from '../../img/booking/icon_arrow_right.svg'

// import icon_point from '../../img/booking/icon_point.svg'         // 2
// import icon_delete from '../../img/booking/icon_delete.svg'
// import icon_location from '../../img/booking/icon_location.svg'


const PRESET_NAME_MAX_LENGTH = 16;
const TEXTAREA_MAX_LENGTH = 100;
const TEXTAREA_REQUEST_MAX_LENGTH = 300;
const RESERVE_TYPE_GENERAL_FLAG = 1
const RESERVE_TYPE_GOLF_FLAG = 3

const CAR_INFO_TYPE_CAR_NO = 'CAR_NO';
const CAR_INFO_TYPE_CAR_MODEL = 'CAR_MODEL';

const DEV_START_TIME    = 'DEV_START';
const DEV_END_TIME      = 'DEV_END';

const LIMIT_HOUR = 0;
const RESERVATION_BASE_TIME = (window.location.href.indexOf('devclient.mosiler.com') !== -1) ? 0 : 0;                   // 예약 기준 시간 (현재일로부터 29시간 이후로 시작/종료시간을 산정.)  devclient면 30시간(테스트용) hybclient는 29시간
const RESERVATION_GOLF_BASE_TIME = 10;              // 골프 기본 시간 (10시간)

const CLOSE_HOUR = 19;                              // 예약 종료 시간 19:00. 만약 18:00로 변경할 경우  18로 변경한다.

const PRESET_TYPE_COMMENT = 'comment';
const PRESET_TYPE_CARINFO = 'carinfo';
/*
검색 : <input.*[^>]
치환 : $0/

검색 : <img.*[^>]
치환 : $0 alt=""/
*/


/*
예약일, 시간 세팅 관련 설계
1. 예상소요시간
    a. 계산 여부
        1) (출발지 and 목적지)가 선택되어야만 예상소요시간을 계산할 수 있도록 처리한다. (경유지가 있다면 경유지도 포함해서 계산. 없다면 포함하지 않고 계산.)
        2) 예상 소요시간 API 동작이 완료되면 그 이후에 Calc로 예상소요시간을 포함한다.
2. 시간추가
    a. 시간추가
        1) 1시간 : + 버튼 활성화, - 버튼 비활성화 (기본값)
           2시간 : +, - 버튼 활성화
           3시간 : +, - 버튼 활성화
        2) 예상소요시간이 존재한다면 시간선택에 포함하여 계산한다. 
           예상소요시간이 없다면 시간선택기본값만 계산한다.
        3) 종료시간은 기존에 TimeSelector를 지우고, 평문 처리하며 비활성화 디자인으로 바꿔야 한다.
           (3번에 따른 영향범위 고려하여 코딩해야함. 부활할 수도 있기 때문에 기존 데이터는 보관한다. 주석처리 꼭해놓을것)
        

*/

class ReserveGeneralView extends Component {

    state = {
        // 기존 데이터
        // date: moment().add(2, 'hours').toDate(),            // 현재 시각에서 2시간 이후 시점의 날짜로 봐야한다.
        // startTime: moment().add(2, 'hours'),
        // fixedStartTime: moment().add(2, 'hours'),
        // endTime: ((Number(this.props.match.params.state) === RESERVE_TYPE_GENERAL_FLAG) ? moment().add(3, 'hours') : moment().add(12, 'hours')),     
        
        date: moment().add(RESERVATION_BASE_TIME, 'hours').toDate(),                   // 현재 시각에서 24시간 이후 시점의 날짜로 봐야한다.
        startTime: moment().add(RESERVATION_BASE_TIME, 'hours').startOf('days'),       // 시작시간 00:00로 변경한다.
        fixedStartTime: moment().add(RESERVATION_BASE_TIME, 'hours'),                  // 현재 시각에서 24시간 이후 시점의 날짜로 봐야한다.
        endTime: ((Number(this.props.match.params.state) === RESERVE_TYPE_GENERAL_FLAG) ? moment().add(RESERVATION_BASE_TIME + 1, 'hours') : moment().add(RESERVATION_BASE_TIME + RESERVATION_GOLF_BASE_TIME, 'hours')),       // 일반 예약은 시작시간에서 1시간 추가해야하기 때문에 25시간이고, 골프는 기본 10시간이므로 24+10=34시간

        carLocation: '',
        carNo:'',
        carModel:'',
        comment: '',
        CustomerNo: 0,
        passengerName: '',
        passengerPhone: '',
        passengerCheckStatus: false,
        reserveType: 1,
        hourView: '',
        typeView: ' ',
        moneyView: 0,
        loading: 1,

        displayEndTime:moment(),

        selectHour: LIMIT_HOUR,
        predictingTimeStr:'',
        totalTime:'',
        detailViewState: false,

        startAreaFee: 0,
        areaFee: 0,
        drivingCharge: 0,
        ReserveDiscountAmount:0,
        nightFee:0,
        

        // 쿠폰 관련
        CouponId: '',               // 쿠폰 ID
        partnerUseYn: false,        // 파트너 카드 존재 유무
        mallReserved: '',           // 파트너 카드의 정보(?)
        paymentCardId: '',          // default card의 id
        sales10CouponId: '',        // 기본 10% 할인 쿠폰 id
        myDefaultCardYn: false,     // 개인 default card 존재 유무
        calcItem:{},                // 계산 결과 전체 값

        carInfoAreaFlag : true,
        passengerAreaFlag : true,

        covidKitStatus: false,      // 방역키트 제공

        trustNowTime: moment().toDate(),

        predictingHour:0,
        predictingMin:0,

        startTimeString:'',
        endTimeString:'',

        timeAddMoment : moment().startOf('days'),
        modalOpenFlag: false,

        carInfoPreset: CARINFO_PRESET_INIT_TEMPLATE,
        carInfoSelectedIdx:'0',
        selectedPresetName:'',
        selectedPresetCarModel:'',
        selectedPresetCarNo:'',
        selectedPresetCarMemo:'',

        commentPreset: COMMENT_PRESET_INIT_TEMPLATE,
        commentPresetSelectedIdx:'0',
        selectedCommentPresetName:'',
        selectedPresetComment:'',

        presetType: PRESET_TYPE_CARINFO,
    }


    

    handleChangeModalStatusTrueAddData = (reqData) => {
        return new Promise((resolve) => {
            this.setState((prevState) => ({ 
                modalOpenFlag : true ,
                drivReqTmpData: reqData
            }), () => {
                resolve(true)
            });
        })
    }

    handleChangeModalStatusTrue = () => {
        this.setState((prevState) => ({ modalOpenFlag : true }));
    }

    handleChangeModalStatusFalse = () => {
        this.setState((prevState) => ({ modalOpenFlag : false }));
    }

    handleChangePresetType = (type) => {
        this.setState((prevState) => ({ presetType : type }));
    }


    // setState 싱크처리
    setStateSync = (data) => {
        return new Promise((resolve) => {
            this.setState(data, () => {
                resolve(true);
            });
        });
    };


    

    // 현재 등록되어있는 Default 카드 정보를 취득해서 그 카드가 일반카드인지 파트너카드인지에 따라서
    // 쿠폰 확인 후 쿠폰이 존재한다면 적용하는 처리를 담당한다.
    // 화면 load시 한번만 호출된다.
    getCustomerDefaultCard = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const { cookies } = this.props;
                let { CustomerNo } = store.getState().global_data.loginedData
                let defaultPaymentCardData = await ReserveApi.GetBasicPaymentCards(
                    CustomerNo,
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                )

                // 기본카드가 있을 경우
                if (defaultPaymentCardData.Item && 
                    defaultPaymentCardData.Result === 'success') {
                    const smallReserved = defaultPaymentCardData.Item.MallReserved;
                    const spaymentCardId = defaultPaymentCardData.Item.Id;

                    // MallReserved값이 존재하면 (MallReserved값은 아마도 파트너 카드인지 아닌지 여부를 판별하는 프로퍼티인것 같다.)
                    if (smallReserved !== "") {
                        console.log("Payment CustomerDefaultCard....");
                        // 파트너 카드이고 , 쿠폰 1599 값을 넣어준다.
                        this.setState({ partnerUseYn: true, CouponId: '1599', mallReserved: smallReserved, paymentCardId: spaymentCardId, sales10CouponId: "" });
                        // 다시 계산 
                        // this.calcDrivingReservation();
                        resolve(true)
                    }
                    // MallReserved값이 없다면
                    else {
                        console.log('else')
                        // 10% 쿠폰이 있는지 재확인
                        let coupon10Data = await ReserveApi.get_check_10Coupon(
                            CustomerNo,
                            cookies.get('ostype'),
                            cookies.get('deviceid'),
                            cookies.get('login_encrypt')
                        )
                        console.log(coupon10Data)

                        if (coupon10Data.Item && coupon10Data.Result === 'success') {
                            const ssales10CouponId = coupon10Data.Item;
                            // get_check_10Coupon 취득한 값이 공값이 아니라 1661이라면
                            if (ssales10CouponId !== "" && ssales10CouponId === "1661") {
                                console.log("Payment get_check_10Coupon....");
                                // 파트너 카드는 아니지만 쿠폰 1661을 넣어준다.
                                this.setState({ partnerUseYn: false, CouponId: '1661', mallReserved: smallReserved, paymentCardId: spaymentCardId, sales10CouponId: ssales10CouponId });
                                resolve(true)
                                // 재계산
                                // this.calcDrivingReservation();
                            }
                            // get_check_10Coupon가 없다면 쿠폰 id는 없다고 봐야.
                            else {
                                this.setState({ partnerUseYn: false });
                                resolve(true)
                            }
                        }
                        // coupon10Data.Item가 존재하지 않으면
                        else {
                            this.setState({ partnerUseYn: false });
                            resolve(true)
                        }
                    }
                }
                // 기본카드가 없을 경우
                else {
                    resolve(false)
                }
            }
            catch (e) {
                return reject(e);
            }
        })
    }

    handleGotoBack = () => {
        this.props.history.replace('/main');
    }

    // 차량번호, 차량모델 취득하는 메소드
    // 로그인시에 차량번호, 모델을 등록해놨다면 
    // 해당 정보를 취득하도록하고,
    // 상기 정보를 등록하지 않았다면
    // 
    getCarNoCarModel = (type) => {
        const { loginedData } = store.getState().global_data
        let retData = '';
        try {
            switch(type) {
                case CAR_INFO_TYPE_CAR_NO:
                    retData = loginedData.CarNo !== null ? loginedData.CarNo : '';
                    break;
                case CAR_INFO_TYPE_CAR_MODEL:
                    retData = loginedData.CarName !== null ? loginedData.CarName : '';
                    break;
                defalut:
                    retData = '';
                    break;
            }
        }
        catch(e) {
            console.log('getCarNoCarModel err : ', e)
        }
        
      
        return retData;
    }

    // url이 devclient.mosiler.com이라면
    // 현재 시간 + 3시간 5분으로 세팅하여 starttime을 지정한다.
    // 일반예약만 해당.
    getStartTimeEndTimeDevGeneral = (flag) => {
        let retdata = moment();
        
        // 현재 url이 devclient.mosiler.com라면
        if (window.location.href.indexOf('devclient.mosiler.com') !== -1) {
            if (flag === DEV_START_TIME) {
                retdata = moment().add(3, 'hours').add(5, 'minutes')
            }
            else {
                retdata = moment().add(4, 'hours').add(5, 'minutes')
            }           
        } 
        // 현재 url이 devclient.mosiler.com 이외라면
        else {
            if (flag === DEV_START_TIME) {
                retdata = moment().add(2, 'hours')
            }
            else {
                retdata = moment().add(3, 'hours')
            }           
        }
        
        return  retdata;
    }

    // 렌더가 6번 발생한다. 렌더 발생이 많아서 생기는 버그 같다. state 갱신을 좀 줄일 필요가 ...
    // 값 변경시에는 렌더가 3번 발생.
    asyncProcess = async () => {
        try {
            this.setState({ loading: 0 })	// 로딩중
            const { cookies } = this.props;
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            // Default 카드 정보 취득 (쿠폰 유무도 확인)
            // let tmpDetaultCardYn = await this.getCustomerDefaultCard();
            console.log(this.props.match.params);
            let tmpInitReserveBackupData = [
                // localStorage.getItem('reserve_temp_date'),                          // 0
                // localStorage.getItem('reserve_temp_startTime'),                     // 1
                // localStorage.getItem('reserve_temp_endTime'),                       // 2
                // localStorage.getItem('reserve_temp_carLocation'),                   // 3
                // localStorage.getItem('reserve_temp_comment'),                       // 4
                // localStorage.getItem('reserve_temp_type'),                          // 5
                // localStorage.getItem('reserve_temp_carNo'),                         // 6
                // localStorage.getItem('reserve_temp_carModel'),                      // 7
                // localStorage.getItem('reserve_temp_selectHour'),                    // 8
                // localStorage.getItem('reserve_temp_covidKit'),                      // 9
                localStorage.getItem('reserve_view_all_state'),                     // 10
            ]
            let backupAllState = tmpInitReserveBackupData[0]  !== null ? JSON.parse(tmpInitReserveBackupData[0]) : null;

            // 즐겨찾기 LocalStorage에 있는지 확인후 없으면 추가 begin
            let favoriteData = getAddressFavoriteDataLS()
            if ( JSON.stringify(favoriteData) === JSON.stringify({}) ) {
                setAddressFavoriteDataLS(FAVORITE_INIT_TEMPLATE)
            }
            // 즐겨찾기 LocalStorage에 있는지 확인후 없으면 추가 end

            let { loginedData } = store.getState().global_data
            // 골프 팝업 begin
            
            if (Number(this.props.match.params.state) === RESERVE_TYPE_GOLF_FLAG) {
                let popupSSFlag = sessionStorage.getItem('mosiler_golf_alert_flag_session') !== null ? sessionStorage.getItem('mosiler_golf_alert_flag_session') : null
                let popupLSFlag = localStorage.getItem('mosiler_golf_alert_flag_local')     !== null ? localStorage.getItem('mosiler_golf_alert_flag_local') : null
                let eventUpdateCount = 3;


                // * 골프 화면 팝업 갱신시 작업해야할 것들
                // 1. eventUpdateCount = 3;를 4으로 변경
                // 2. 이미지 반영
                // 3. URL & title= 내용 입력

                // 확인버튼, 배경 클릭시 앱을 종료할때까지는 표시하지 않는다.
                if (popupSSFlag !== null) { }

                // 다시 보지 않기를 눌렀을 때에는 다시 표시되어선 안된다.
                else if (popupLSFlag !== null) { 
                    if (parseInt(popupLSFlag) !== eventUpdateCount) {
                        // 다시 보지 않기 클릭시 false, 확인 및 배경 클릭시 true
                        let booleanResult = await calertImageLinkPopup (
                            eventImg2,
                            // '/common_link?redirect=http://mosiler.com/golf_app.html&title=골프장 얼리버드 이벤트'
                            '/common_link?redirect=http://mosiler.com/golf_app.html&title=레저'
                        )
                        if (booleanResult) {
                            sessionStorage.setItem('mosiler_golf_alert_flag_session',eventUpdateCount+'' )
                        } 
                        else {
                            localStorage.setItem('mosiler_golf_alert_flag_local',eventUpdateCount+'' )
                        }
                    }
                }

                else {                   
                    // 다시 보지 않기 클릭시 false, 확인 및 배경 클릭시 true
                    let booleanResult = await calertImageLinkPopup (
                            eventImg2,
                            // '/common_link?redirect=http://mosiler.com/golf_app.html&title=골프장 얼리버드 이벤트'
                            '/common_link?redirect=http://mosiler.com/golf_app.html&title=레저'
                        )

                    if (booleanResult) {
                        sessionStorage.setItem('mosiler_golf_alert_flag_session',eventUpdateCount+'' )
                    } 
                    else {
                        localStorage.setItem('mosiler_golf_alert_flag_local',eventUpdateCount+'' )
                    }
                }
            }           
            
            
            // 골프 팝업 end

            let carinfoPresetLS = getLocalStorage('carinfoPreset');
            if (carinfoPresetLS === null) {
                setLocalStorage('carinfoPreset',CARINFO_PRESET_INIT_TEMPLATE)
                carinfoPresetLS = CARINFO_PRESET_INIT_TEMPLATE
            } 

            let commentPresetLS = getLocalStorage('commentPreset');
            if (commentPresetLS === null) {
                setLocalStorage('commentPreset',COMMENT_PRESET_INIT_TEMPLATE)
                commentPresetLS = COMMENT_PRESET_INIT_TEMPLATE
            } 

            let isBackupAllStateResult = (backupAllState !== null);

            // 현 시간 Get begin
            let serverTime = await NoticeApi.GetResponseTime(cookies.get('ostype'), cookies.get('deviceid'), cookies.get('login_encrypt'))
            // 현 시간 Get end

            this.setState({

                // 이전 date, startTime, endTime
                date: isBackupAllStateResult ? new Date(backupAllState.date) : getReserveAvaliableDate(moment(serverTime).toDate(), CLOSE_HOUR),
                startTime: isBackupAllStateResult ? moment(backupAllState.startTime) : moment().add(RESERVATION_BASE_TIME, 'hours').startOf('days'),
                endTime: isBackupAllStateResult ? moment(backupAllState.endTime) : ((Number(this.props.match.params.state) === RESERVE_TYPE_GENERAL_FLAG) ? moment().add(RESERVATION_BASE_TIME+1, 'hours') : moment().add(RESERVATION_BASE_TIME + RESERVATION_GOLF_BASE_TIME, 'hours')),

                carLocation: isBackupAllStateResult ? backupAllState.carLocation : '',
                comment: isBackupAllStateResult ? backupAllState.comment : '',
                reserveType: isBackupAllStateResult ? Number(backupAllState.reserveType) : Number(this.props.match.params.state),
                
                passengerName: isBackupAllStateResult ? backupAllState.passengerName: '',
                passengerPhone: isBackupAllStateResult ? backupAllState.passengerPhone: '',
                passengerCheckStatus: isBackupAllStateResult ? backupAllState.passengerCheckStatus: false,
                CustomerNo: store.getState().global_data.loginedData.CustomerNo,
                typeView: ReserveApi.getDrivingTypeStr(this.props.match.params.state),
                selectHour: isBackupAllStateResult ? parseInt(backupAllState.selectHour) : LIMIT_HOUR,
                carNo:  isBackupAllStateResult ? backupAllState.carNo : this.getCarNoCarModel(CAR_INFO_TYPE_CAR_NO),
                carModel: isBackupAllStateResult ? backupAllState.carModel : this.getCarNoCarModel(CAR_INFO_TYPE_CAR_MODEL),

                // 방역키트 지급 축소에 따른 방역키트 체크박스 항상 false
                // covidKitStatus: isBackupAllStateResult ? backupAllState.covidKitStatus : (Number(this.props.match.params.state) === RESERVE_TYPE_GOLF_FLAG) ? true : false,

                trustNowTime: moment(serverTime).toDate(),

                timeAddMoment : isBackupAllStateResult ? moment(backupAllState.timeAddMoment) : moment().startOf('days'),
                // myDefaultCardYn: tmpDetaultCardYn,

                
                // Preset
                carInfoPreset               : isBackupAllStateResult ? backupAllState.carInfoPreset : carinfoPresetLS,
                carInfoSelectedIdx          : isBackupAllStateResult ? backupAllState.carInfoSelectedIdx : '0',
                selectedPresetName          : isBackupAllStateResult ? backupAllState.selectedPresetName : '',
                selectedPresetCarModel      : isBackupAllStateResult ? backupAllState.selectedPresetCarModel : '',
                selectedPresetCarNo         : isBackupAllStateResult ? backupAllState.selectedPresetCarNo : '',
                selectedPresetCarMemo       : isBackupAllStateResult ? backupAllState.selectedPresetCarMemo : '',
        
                commentPreset               : isBackupAllStateResult ? backupAllState.commentPreset : commentPresetLS,
                commentPresetSelectedIdx    : isBackupAllStateResult ? backupAllState.commentPresetSelectedIdx : '0',
                selectedCommentPresetName   : isBackupAllStateResult ? backupAllState.selectedCommentPresetName : '',
                selectedPresetComment       : isBackupAllStateResult ? backupAllState.selectedPresetComment : '',
        
                presetType                  : isBackupAllStateResult ? backupAllState.presetType : PRESET_TYPE_CARINFO,

                

            }, () => {
                console.log('asyncProcess')
                // 계산 처리 후 loading : 1로 바꾼다.
                this.getCalcDrivingReservation();
            })
        } 
        catch(e) {
            console.log(e);
        }
        
    }

    componentDidMount() {
        // 초기값 세팅
        this.asyncProcess();
        // Load될때 
        console.log(this.props.lastLocation)
    }

    componentWillUnmount() {
        console.log('componentWillUnmount 호출')
    }
    

    componentWillUnmount() {
        // localStorage.setItem('reserve_temp_type', this.state.reserveType)
        // localStorage.setItem('reserve_temp_date', this.state.date)
        // localStorage.setItem('reserve_temp_startTime', this.state.startTime.toDate())
        // localStorage.setItem('reserve_temp_endTime', this.state.endTime.toDate())    
        // localStorage.setItem('reserve_temp_carLocation', this.state.carLocation)
        // localStorage.setItem('reserve_temp_comment', this.state.comment)
        // localStorage.setItem('reserve_temp_passengername', this.state.passengerName)
        // localStorage.setItem('reserve_temp_passengerphone', this.state.passengerPhone)
        // localStorage.setItem('reserve_temp_carNo', this.state.carNo)
        // localStorage.setItem('reserve_temp_carModel', this.state.carModel)
        // localStorage.setItem('reserve_temp_selectHour', this.state.selectHour)
        // localStorage.setItem('reserve_temp_covidKit', this.state.covidKitStatus)
        localStorage.setItem('reserve_view_all_state', JSON.stringify(this.state))
        

    }

    // handleAddStopover = (e) => {
    //     let tmpArr = this.state.stopOverList;
    //     let tmpArrLen = this.state.stopOverList.length;
    //     if (tmpArrLen >= this.LIMIT_STOPOVER_COUNT) {
    //         await calertPromise('경유지는 3개까지 등록 가능합니다.');
    //     } else {
    //         tmpArr.push(1);
    //         this.setState({
    //             stopOverList: tmpArr
    //         })
    //     }
    // }

    handleChangeCarInfoPreset = (e) => { 
        let presetLS = getLocalStorage('carinfoPreset');
        let idx = Number(e.target.value)-1;
        
        this.setState({ 
            carInfoSelectedIdx: e.target.value,
            selectedPresetName:       e.target.value === '0' ? '' : presetLS[idx].presetName,
            selectedPresetCarModel:   e.target.value === '0' ? '' : presetLS[idx].presetCarModel,
            selectedPresetCarNo:      e.target.value === '0' ? '' : presetLS[idx].presetCarNo,
            selectedPresetCarMemo:    e.target.value === '0' ? '' : presetLS[idx].presetCarMemo,

            // idx 0을 선택했을 때는 기존값으로 돌려놔야함
            // idx 1 이상값을 선택했을 때는 을 선택했을 때는 기존값으로 돌려놔야함
            carLocation:    e.target.value === '0' ? '' : presetLS[idx].presetCarMemo,
            carNo:          e.target.value === '0' ? this.getCarNoCarModel(CAR_INFO_TYPE_CAR_NO) : presetLS[idx].presetCarNo,
            carModel:       e.target.value === '0' ? this.getCarNoCarModel(CAR_INFO_TYPE_CAR_MODEL) : presetLS[idx].presetCarModel,
        }) 

    }

    handleChangePresetName = (e) => { 
        
        if (Util.getStringLength(e.target.value) <= PRESET_NAME_MAX_LENGTH) {
            this.setState({ selectedPresetName: e.target.value }) 
        }       
    }

    
    handleChangePresetCarModel = (e) => { this.setState({ selectedPresetCarModel: e.target.value }) }
    handleChangePresetCarNo = (e) => { this.setState({ selectedPresetCarNo: e.target.value }) }
    handleChangePresetCarMemo = (e) => { 
        if (Util.getStringLength(e.target.value) <= TEXTAREA_MAX_LENGTH) {
            this.setState({ selectedPresetCarMemo: e.target.value }) 
        }       
    }  

    handleSetPresetCarInfo = async () => {
        const {carInfoSelectedIdx,
            selectedPresetName,
            selectedPresetCarModel,
            selectedPresetCarNo,
            selectedPresetCarMemo,} = this.state;
        
        if (!selectedPresetName.length) {
            await calertPromise('프리셋 제목을 입력해주세요.')
            return;
        } 
        else if (!selectedPresetCarModel.length) {
            await calertPromise('차량 모델명을 입력해주세요.')
            return;
        }
        else if (!selectedPresetCarNo.length) {
            await calertPromise('차량 번호를 입력해주세요.')
            return;
        }
        else if (!selectedPresetCarMemo.length) {
            await calertPromise('파트너와 만날 장소를 입력해주세요.')
            return;
        }
    
        console.log(`${selectedPresetName} ${selectedPresetCarModel} ${selectedPresetCarNo} ${selectedPresetCarMemo}`)
        let presetLS = getLocalStorage('carinfoPreset');
        let idx = Number(carInfoSelectedIdx)-1;

        presetLS[idx].presetName = selectedPresetName
        presetLS[idx].presetCarModel = selectedPresetCarModel
        presetLS[idx].presetCarNo = selectedPresetCarNo
        presetLS[idx].presetCarMemo = selectedPresetCarMemo

        setLocalStorage('carinfoPreset', presetLS);

        this.setState({ 
            carInfoPreset: presetLS,
            carLocation: selectedPresetCarMemo,
            carNo: selectedPresetCarNo,
            carModel: selectedPresetCarModel,
        }) 

        this.handleChangeModalStatusFalse()
        
    }

    handleSetPresetCarInfo = async () => {
        const {carInfoSelectedIdx,
            selectedPresetName,
            selectedPresetCarModel,
            selectedPresetCarNo,
            selectedPresetCarMemo,} = this.state;
        
        if (!selectedPresetName.length) {
            await calertPromise('프리셋 제목을 입력해주세요.')
            return;
        } 
        else if (!selectedPresetCarModel.length) {
            await calertPromise('차량 모델명을 입력해주세요.')
            return;
        }
        else if (!selectedPresetCarNo.length) {
            await calertPromise('차량 번호를 입력해주세요.')
            return;
        }
        else if (!selectedPresetCarMemo.length) {
            await calertPromise('파트너와 만날 장소를 입력해주세요.')
            return;
        }
    
        console.log(`${selectedPresetName} ${selectedPresetCarModel} ${selectedPresetCarNo} ${selectedPresetCarMemo}`)
        let presetLS = getLocalStorage('carinfoPreset');
        let idx = Number(carInfoSelectedIdx)-1;

        presetLS[idx].presetName = selectedPresetName
        presetLS[idx].presetCarModel = selectedPresetCarModel
        presetLS[idx].presetCarNo = selectedPresetCarNo
        presetLS[idx].presetCarMemo = selectedPresetCarMemo

        setLocalStorage('carinfoPreset', presetLS);

        this.setState({ 
            carInfoPreset: presetLS,
            carLocation: selectedPresetCarMemo,
            carNo: selectedPresetCarNo,
            carModel: selectedPresetCarModel,
        }) 

        this.handleChangeModalStatusFalse()
        
    }

    handleClearPreset = async () => {
        const {carInfoSelectedIdx} = this.state;
        
        /*
            제목, 모델, 번호, 내용에 대해서 아래의 처리를 실시
            1. localSTorage에 해당 idx 내용 삭제후 carInfoPreset: presetLS, 처리 실시
            2. carLocation, carNo, carModel값을 빈값으로 한다.
            3. 아래 값에 대해서 빈값으로둔다.
            selectedPresetName,selectedPresetCarModel,selectedPresetCarNo,selectedPresetCarMemo
        */
        
        let presetLS = getLocalStorage('carinfoPreset');
        let idx = Number(carInfoSelectedIdx)-1;

        presetLS[idx].presetName = ''
        presetLS[idx].presetCarModel = ''
        presetLS[idx].presetCarNo = ''
        presetLS[idx].presetCarMemo = ''

        setLocalStorage('carinfoPreset', presetLS);

        this.setState({ 
            carInfoPreset: presetLS,
            carLocation: '',
            carNo: '',
            carModel: '',
            selectedPresetName:'',
            selectedPresetCarModel:'',
            selectedPresetCarNo:'',
            selectedPresetCarMemo:''
        }) 
        await calertPromise('해당 프리셋의 정보를 삭제했습니다.')       
    }

    handleChangeCommentPreset = (e) => { 
        let presetLS = getLocalStorage('commentPreset');
        let idx = Number(e.target.value)-1;
        
        this.setState({ 
            commentPresetSelectedIdx: e.target.value,
            selectedCommentPresetName:       e.target.value === '0' ? '' : presetLS[idx].presetName,
            selectedPresetComment:   e.target.value === '0' ? '' : presetLS[idx].presetComment,
            
            // idx 0을 선택했을 때는 기존값으로 돌려놔야함
            // idx 1 이상값을 선택했을 때는 을 선택했을 때는 기존값으로 돌려놔야함
            comment:    e.target.value === '0' ? '' : presetLS[idx].presetComment,
            
        }) 

    }

    handleChangeCommentPresetName = (e) => { 
        
        if (Util.getStringLength(e.target.value) <= PRESET_NAME_MAX_LENGTH) {
            this.setState({ selectedCommentPresetName: e.target.value }) 
        }       
    }

    handleChangePresetComment = (e) => { 
        if (Util.getStringLength(e.target.value) <= TEXTAREA_REQUEST_MAX_LENGTH) {
            this.setState({ selectedPresetComment: e.target.value }) 
        }       
    }  

    handleSetCommentPreset = async () => {
        const {
            commentPresetSelectedIdx,
            selectedCommentPresetName,
            selectedPresetComment
            // 요청사항 선택 인덱스
            // 요청사항 프리셋 이름
            // 요청사항 프리셋 코멘트 내용.
            } = this.state;
        
        if (!selectedCommentPresetName.length) {
            await calertPromise('프리셋 제목을 입력해주세요.')
            return;
        } 
        else if (!selectedPresetComment.length) {
            await calertPromise('요청사항 내용을 입력해주세요.차량 모델명을 입력해주세요.')
            return;
        }

        let presetLS = getLocalStorage('commentPreset');
        let idx = Number(commentPresetSelectedIdx)-1;

        presetLS[idx].presetName = selectedCommentPresetName
        presetLS[idx].presetComment = selectedPresetComment

        setLocalStorage('commentPreset', presetLS);

        this.setState({ 
            commentPreset: presetLS,
            comment: selectedPresetComment,
        }) 

        this.handleChangeModalStatusFalse()
        
    }

    handleClearCommentPreset = async () => {
        const {commentPresetSelectedIdx} = this.state;
        
        /*
            제목, 모델, 번호, 내용에 대해서 아래의 처리를 실시
            1. localSTorage에 해당 idx 내용 삭제후 carInfoPreset: presetLS, 처리 실시
            2. carLocation, carNo, carModel값을 빈값으로 한다.
            3. 아래 값에 대해서 빈값으로둔다.
            selectedPresetName,selectedPresetCarModel,selectedPresetCarNo,selectedPresetCarMemo
        */
        
        let presetLS = getLocalStorage('commentPreset');
        let idx = Number(commentPresetSelectedIdx)-1;

        presetLS[idx].presetName = ''
        presetLS[idx].presetComment = ''

        setLocalStorage('commentPreset', presetLS);

        this.setState({ 
            commentPreset: presetLS,
            comment: '',
            selectedCommentPresetName:'',
            selectedPresetComment:''
        }) 
        await calertPromise('해당 프리셋의 정보를 삭제했습니다.')       
    }

    


    handleDelStopover = (e) => {
        let tmpArr = [];

        for (let i in this.state.stopOverList) {
            if (e.target.id === i) continue;
            else tmpArr.push(this.state.stopOverList[i]);
        }

        this.setState({
            stopOverList: tmpArr
        })
    }

    handleSaveDate = (data) => {
        return new Promise((resolve)=>{
            this.setState({ date: data }, () => {

                this.getCalcDrivingReservation();        
                resolve(true)

            });
        })      
    }

    changeHourView = (data) => {
        return data.replace('시간','시간 ')
    }

    handleUpdateStartTimeGeneral = (data) => {
        // 이부분도 수정해야한다. 
        // 이유 : 지정한 종료 시간에 대해서는 그대로 유지되어야할것 같아서이다.
        this.setState({
            startTime: data,
            // endTime: moment(data).add(this.state.selectHour, 'hours'),
        }, () => {
            console.log('handleUpdateStartTimeGeneral')
            this.getCalcDrivingReservation('timeadd');
        });
    }

    handleUpdateStartTimeGolf = (data) => {
        this.setState({
            startTime: data,
            // endTime: moment(data).add(10, 'hours'),
            // endTime: moment(data).add(10 + this.state.selectHour, 'hours'),
        }, () => {
            console.log('handleUpdateStartTimeGolf1')
            this.getCalcDrivingReservation();
        });
    }

    handleUpdateEndtime = (data) => {
        this.setState({ endTime: data }, () => {
            console.log('handleUpdateEndtime')
            this.getCalcDrivingReservation();
        });
    }

    handleCarLocation = (e) => {
        if (Util.getStringLength(e.target.value) <= TEXTAREA_MAX_LENGTH) {
            this.setState({ carLocation: e.target.value })
        }
    }

    handleComment = (e) => {
        if (Util.getStringLength(e.target.value) <= TEXTAREA_REQUEST_MAX_LENGTH) {
            this.setState({ comment: e.target.value })
        }
    }

    handleCarNo = (e) => {
        this.setState({ carNo: e.target.value })        
    }

    handleCarModel = (e) => {
        this.setState({ carModel: e.target.value })
    }

    handleUpdateDetailViewStateToggle = () => {
        this.setState((prevstate)=>({ detailViewState: !prevstate.detailViewState }));
    }
    
    handleEndtimeIncrease = (e) => {
        const {startTime, endTime} = this.state;
        let tmpStartTime = moment(startTime.format());
        let tmpEndTime = moment(endTime.format())

        this.setState((prevState)=>{
            return {
                //endTime: moment(tmpEndTime.format()).add(1, 'hours'),       // 현재 선택되어있는 시간에서 +1시간 시키고
                //selectHour: moment(tmpEndTime.format()).add(1, 'hours').diff(tmpStartTime, 'hours'),
                selectHour: prevState.selectHour + 1,                       // selectHour도 1 증가
            }
        }, ()=>{
            this.getCalcDrivingReservation('timeadd');
        })
    }
    
    handleEndtimeDecrease = async (e) => {
        const {startTime, endTime, selectHour} = this.state;
        let tmpStartTime = moment(startTime.format());
        let tmpEndTime = moment(endTime.format())

        console.log('현재 시간 : ', tmpEndTime.diff(tmpStartTime, 'hours'));
        
        if (selectHour <= LIMIT_HOUR) {
            await calertPromise(`${LIMIT_HOUR}시간 이하가 될 수 없습니다.`)
        }
        else {
            this.setState((prevState)=>{
                return {
                    //endTime: moment(tmpEndTime.format()).subtract(1, 'hours'),
                    // selectHour: moment(tmpEndTime.format()).subtract(1, 'hours').diff(tmpStartTime, 'hours'),
                    selectHour: prevState.selectHour - 1,
                }
            }, ()=>{
                this.getCalcDrivingReservation('timeadd');
            })
        }       
    }

    //소요시간 변경_20210329_jane
    handleCallbackDuration = (text) => {
        this.setState({
            hour : text,
            min : text
        }, async () => {
            await this.getCalcDrivingReservation();
        })
    }

    gotoPolicyPrice = (state) => {
        this.props.history.push(`/policy_price?state=${state}`)
    }



    getCalcDrivingReservation = async (type) => {
        try {
            // "CustomerNo" : 12534,
            // "PaymentType": "2",
            // "DrivingType": 1,
            // "RevStartDt": "2020-12-16 00:00:00.000",
            // "RevEndDt" : "2020-12-16 02:30:00.000"
            const { cookies } = this.props;
            let tmpDate = this.state.date
            let tmpStartDate = this.state.startTime.toDate()
            let tmpEndDate = this.state.endTime.toDate()

            let tmpStartDateFormat = '';
            let tmpEndDateFormat = '';

            let testStart = Number(moment(tmpStartDate).format('YYYYMMDD'))                 // start date
            let testEnd = Number(moment(tmpEndDate).format('YYYYMMDD')); //end date1111111111

            let finalTimeProcess = null;
            let prdictingResult = {
                hour:0,
                min:0
            };
            

            const {
                startLocationAddress,
                startLocationLat,
                startLocationLng,
                endLocationAddress,
                endLocationLat,
                endLocationLng,
                stopOverList,
                checkSwitch
            } = store.getState().LocationSelector_ReduxModule;

            const { IsOldFare } = store.getState().global_data.loginedData


            // 아래 값에 변경이 이루어졌을때 계산중을 띄운다.
            if (this.state.moneyView !== 0 && this.state.hourView !== '일반 ') {
                this.setState({
                    moneyView: '(계산중...)',
                    hourView: '(계산중...)',
                })
            }

            // start date를 먼저 세팅해주고
            tmpStartDateFormat = plusSecondsMomentWithFormatCustom(
                tmpDate.getDate(),
                (tmpDate.getMonth() + 1),
                tmpDate.getFullYear(),
                tmpStartDate.getHours(),
                tmpStartDate.getMinutes(),
            )

            // 아래 코드를 넣는 이유 : 23:30 ~ 0:30 시간대일경우에는 0:30는 다음날로 처리해야하는데 당일로 처리하고 있어서
            // 계산 결과가 23시간 이렇게 나온다. 이 문제를 방지하고자 처리하는 것이다.
            // end가 start의 날짜를 넘어간다면 
            if (testStart < testEnd) {      
                let tmpAfterDay = moment(this.state.date).add(1,'days').toDate();

                tmpEndDateFormat = plusSecondsMomentWithFormatCustom(
                    tmpAfterDay.getDate(),
                    (tmpAfterDay.getMonth() + 1),
                    tmpAfterDay.getFullYear(),
                    tmpEndDate.getHours(),
                    tmpEndDate.getMinutes(),
                )
            } 
            
            else {
                tmpEndDateFormat = plusSecondsMomentWithFormatCustom(
                    tmpDate.getDate(),
                    (tmpDate.getMonth() + 1),
                    tmpDate.getFullYear(),
                    tmpEndDate.getHours(),
                    tmpEndDate.getMinutes(),
                )
            }

            // console.log('testStart : ',testStart)         // 2021-01-30 23:28:00.000
            // console.log('after Date : ',testEnd)         // 2021-01-30 23:28:00.000
            // console.log('nowDate : ',moment(this.state.date).format('YYYYMMDD'))         // 2021-01-30 23:28:00.000
            // console.log('after Date : ',moment(this.state.date).add(1,'days').format('YYYYMMDD'))         // 2021-01-30 23:28:00.000
            // console.log('tmpStartDate : ',tmpStartDate)         // 2021-01-30 23:28:00.000
            // console.log('tmpEndDate : ',tmpEndDate)           // 2021-01-17 00:28:00.000
            // console.log('tmpStartDateFormat : ',tmpStartDateFormat)         // 2021-01-30 23:28:00.000
            // console.log('tmpEndDateFormat : ',tmpEndDateFormat)           // 2021-01-17 00:28:00.000

        
            let tmpObj = {
                CustomerNo: this.state.CustomerNo,
                PaymentType: '2',
                DrivingType: this.state.reserveType,
                RevStartDt: tmpStartDateFormat,
                // RevEndDt는 예상시간 산출 후에 세팅해야한다.
                // RevEndDt: tmpEndDateFormat,  
                // CouponId: this.state.CouponId,
                StartAddress: startLocationAddress,
                StartLat: startLocationLat,
                StartLng: startLocationLng,

                EndAddress: endLocationAddress,
                EndLat: endLocationLat,
                EndLng: endLocationLng,

                ...ReserveApi.getWaypointGenerate2(stopOverList)
            }
            this.setState({ loading: 0 })	// 로딩중


            /*
                const RESERVE_TYPE_GENERAL_FLAG = 1
                const RESERVE_TYPE_GOLF_FLAG = 3
                
                if (Number(this.props.match.params.state) === RESERVE_TYPE_GENERAL_FLAG)

            */
            

            // 일반예약일때만 예상소요시간을 산출해야한다.
            if ( Number(this.props.match.params.state) === RESERVE_TYPE_GENERAL_FLAG) {
                // 예상소요시간은 주소지가 확정되었을때만 호출되도록 해야할 것 같다.
                // 시간 바꿀때마다 시간이 오래걸림.

                // 출발지 도착지가 지정되었거나, 경유지가 있을때 추가한 경유지에 주소 지정을 다 했을 때 true 그외에는 false
                if ( this.isChkAddressChosen() ) {
                    
                    // +, - 버튼 눌렀을 때 PredictingTime 메소드는 호출하지 않는다. (예상소요시간 API호출을 최소화)
                    // type이 undefined일때는 +,-를 안눌렀을때이다.
                    // type이 undefeind가 아닐때는 +,-를 눌렀을때이다.
                    if (type === undefined) {
                        try {
                            prdictingResult = await ReserveApi.PredictingTime(
                                startLocationLng, 
                                startLocationLat, 
                                endLocationLng, 
                                endLocationLat,
                                stopOverList
                            );
                            await this.setStateSync({
                                predictingHour: prdictingResult.hour,
                                predictingMin: prdictingResult.min
                            });
                        }
                        catch(e) {
                            await this.setStateSync({
                                predictingHour:0,
                                predictingMin:0
                            });
                        }

                        // prdictingResult값을 state에 저장해야함.
                    }
                } 

                /*
                    차후에는 쓰로틀 방식도 고려한다.
                    +,-버튼을 누르면 우선 다음버튼 비활성화
                    1초뒤에 Loading처리 후 로딩이 완료되면
                    다음버튼 활성화.
                */
                /*
                    단순하게 생각하자. 
                    +, - 버튼을 누를때 걍 calc호출하는 방향도 고려해봐야..
                    종료시간은 의미가 없는거 아닌가..
                    증감할때는 endtime에 의존하는것도 아닌듯..
                    시간추가는 단순히 시간추가일뿐이고..
                    시작시간 기준으로 예상소요시간 
                */

                // 시작시간 + 예상소요시간 계산하기
                if (this.state.predictingHour === 0 && this.state.predictingMin === 0) {
                    finalTimeProcess = moment(tmpStartDateFormat).add(this.state.predictingHour, 'hours').add(this.state.predictingMin,'minutes').add(1,'hours');
                }
                else {
                    // 예상소요시간이 1시간 미만일때는 
                    if (this.state.predictingHour <= 0) {
                        // 기본 1시간을 강제하고, 시간 추가 기능을 추가.
                        finalTimeProcess = moment(tmpStartDateFormat)
                                            .add(1,'hours')
                                            .add(this.state.timeAddMoment.hours(),'hours')
                                            .add(this.state.timeAddMoment.minutes(),'minute');
                                            
                    } 
                    else {
                        // finalTimeProcess = moment(tmpStartDateFormat).add(this.state.predictingHour, 'hours').add(this.state.predictingMin,'minutes').add(this.state.selectHour,'hours');   -10분-
                        finalTimeProcess = moment(tmpStartDateFormat)
                                            .add(this.state.predictingHour, 'hours')
                                            .add(this.state.predictingMin,'minutes')
                                            .add(this.state.timeAddMoment.hours(),'hours')
                                            .add(this.state.timeAddMoment.minutes(),'minute');
                    }
                }
                
                // Timezone 오류로 인해서 금액이 이상하게 나오는 현상을 해결
                tmpObj.RevEndDt = finalTimeProcess.format('yyyy-MM-DD HH:mm:00.000');        // 종료시간 + 예상소요시간을 Form에 추가해서 계산하도록 처리한다.
                               
            }
            // 골프예약일경우 예상시간을 고려하지 않고, 시작 시간을 기준으로 10시간 + 추가시간을 고려한다.
            else if (Number(this.props.match.params.state) === RESERVE_TYPE_GOLF_FLAG) {
                // finalTimeProcess = moment(tmpStartDateFormat).add(RESERVATION_GOLF_BASE_TIME, 'hours').add(this.state.selectHour,'hours');           -10분-
                finalTimeProcess = moment(tmpStartDateFormat)
                                    .add(RESERVATION_GOLF_BASE_TIME, 'hours')
                                    .add(this.state.timeAddMoment.hours(),'hours')
                                    .add(this.state.timeAddMoment.minutes(),'minute');
                // Timezone 오류로 인해서 금액이 이상하게 나오는 현상을 해결
                tmpObj.RevEndDt = finalTimeProcess.format('yyyy-MM-DD HH:mm:00.000');
            }
            // 그이외의 예약일경우
            // else {

            // }
            

            let result = await ReserveApi.CalcDrivingReservation(
                tmpObj,
                cookies.get('ostype'),
                cookies.get('deviceid'),
                cookies.get('login_encrypt')
            )
            if (result.Result === 'success') {
                
                // 아래 내용은 20210930부로 표시하지 않는다. (adam 지시)
                if (false) {
                    // 할인 금액이 0원이 아닐때는 현재 할인행사중이라는 의미가 된다.
                if (result.Item.ReserveDiscountAmount !== 0) {
                    // 최초 골프예약화면에 들어갔다면 reserve_temp_date는 세팅이 안되어 있기 때문에 null이된다. 이 값으로 처음 들어갔는지의 여부를 알 수 있다. 
                    if (localStorage.getItem('reserve_temp_ReseveCharge') === null) {
                        // 골프만 해당된다. 일반예약은 해당되지 않는다.
                        if ((Number(this.props.match.params.state) === RESERVE_TYPE_GOLF_FLAG)) {
                            // 여기서만 사용하기 위해 alert 글꼴 커스터마이징 실시.
                            // await calertPromise3('[골프할인안내]\n현재일 기준\n예약일 3일 이내 : 10,000원 할인\n예약일 3일 이전 : 20,000원 할인\n됩니다.')
                            await calertPromise3('[골프할인안내]\n골프 왕복시\n심야할증, 출발지할증은\n 과금하지 않습니다.')
                        }
                    }
                }
                }

                
                
                
                localStorage.setItem('reserve_temp_ReseveCharge', result.Item.ReseveCharge)
                
                

                this.setState({
                    calcItem: result.Item,
                    moneyView: result.Item.ReseveCharge,        // 총금액
                    hourView: result.Item.DrivingTimes,
                    loading: 1, 

                    predictingTimeStr: (startLocationAddress.length && endLocationAddress.length) ? 
                        `${Util.getStrDoubleDigit(this.state.predictingHour)}시간 ${Util.getStrDoubleDigit(this.state.predictingMin)}분` : '',

                    totalTime: ReserveApi.getTimeDifferenceHourToStr2(tmpStartDateFormat, finalTimeProcess.format()),

                    startAreaFee: result.Item.StartAreaFee,         // 출발지 할증
                    areaFee: result.Item.AreaFee,                   // 지역할증
                    drivingCharge: result.Item.DrivingCharge,       // 운행요금
                    nightFee: result.Item.NightFee,

                    displayEndTime: finalTimeProcess,               // Display할 종료시간
                    ReserveDiscountAmount : result.Item.ReserveDiscountAmount,

                    startTimeString:tmpObj.RevStartDt,
                    endTimeString:tmpObj.RevEndDt,
                    
                })
            } else {
                await calertPromise('금액을 계산 하지 못했습니다.')
                this.setState({ loading: 1 })	// 로딩완료
            }

        } catch (e) {
            console.log(e)
            await calertPromise('금액 계산 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
            this.setState({ loading: 1 })	// 로딩완료
        }
    }

    handlePassengerCheckStatus = (e) => {
        let { loginedData } = store.getState().global_data

        this.setState({
            passengerCheckStatus: e.target.checked,
            passengerName: e.target.checked ? loginedData.CustomerName : '',
            passengerPhone: e.target.checked ? loginedData.PhoneNumber : ''
        })
    }

    handlePassengerName = (e) => {
        this.setState({
            passengerName: e.target.value
        })
    }

    handlePassengerPhone = (e) => {
        this.setState({
            passengerPhone: e.target.value
        })
    }

    handleNextBtnValidate = async () => {
        try {
            const {
                startTime,
                endTime,
                passengerName,
                passengerPhone,
                comment,
                reserveType,
                carLocation,
                carNo,
                carModel,
            } = this.state;
            const {
                startLocationAddress,
                startLocationLat,
                startLocationLng,
                stopOverList,
                endLocationAddress,
                endLocationLat,
                endLocationLng,
                checkSwitch         // 왕복 flag
            } = store.getState().LocationSelector_ReduxModule;
            let retData = true;

            // 출발지 체크
            if (startLocationAddress.length <= 5 ||
                startLocationLat === 0 ||
                startLocationLng === 0) {
                    await calertPromise('출발지를 입력해주세요.')
                return;
            }

            // 도착지 체크
            if (endLocationAddress.length <= 5 ||
                endLocationLat === 0 ||
                endLocationLng === 0) {
                    await calertPromise(`${ReserveApi.getChangeStrJongryoji(this.state.reserveType)}를 입력해주세요.`)
                return;
            }

            // 경유지 체크 (1개 이상 추가시 체크대상)
            if (stopOverList.length >= 1) {
                for (let i in stopOverList) {
                    console.log(stopOverList[i]);
                    if (
                        (stopOverList[i].waypointAddress.length <= 5) ||
                        (stopOverList[i].waypointLat.length === 0) ||
                        (stopOverList[i].waypointLng.length === 0)
                    ) {
                        await calertPromise(`${Number(i) + 1} 번째 경유지를 입력해주세요.`)
                        return;
                    }
                }
            }

            // 예약일(시작, 종료시간) 체크 (시작시간이 종료시간보다 더 크거나 같을때)
            // if (startTime.toDate().getTime() >= endTime.toDate().getTime()) {
            //     await calertPromise('시작시간이나 종료시간을 확인해주세요.')
            //     return;
            // }

            // 차량정보 부분 체크 추가(이번 리뉴얼때 차량정보 입력 부분이 필수항목이 되었다.)
            if (carLocation.length === 0 || carNo.length === 0 || carModel.length === 0) {
                // let tmpMsg =`${(carModel.length === 0) ? '차량 제조사/모델' : '' + '\n'}${(carNo.length === 0) ? '차량 번호' : ''  + '\n'}${(carLocation.length === 0) ? '파트너와 만날 장소' : '' + '\n'}`;
                // console.log(tmpMsg)              
                await calertPromise(`차량정보 항목에 누락된 정보를\n입력해주세요.`)
                this.setState({ carInfoAreaFlag:false });
                return;
            }

            // 탑승자 정보 체크
            if (passengerName.length === 0 || passengerPhone.length === 0) {
                await calertPromise('탑승자정보 이름이나 전화번호를 입력해주세요.')
                this.setState({ passengerAreaFlag:false });
                return;
            }

            if (!checkSwitch) {
                if (startLocationAddress === endLocationAddress) {
                    await calertPromise('출발지/목적지가 같습니다.\n왕복을 원하시면 출발지/목적지 동일을 체크해주세요.')
                    return;
                }
            }

            // 현재 시간이 CLOSE_HOUR를 넘겼다면 당일, 명일 예약 불가능하도록 설정할 것.
            // const { cookies } = this.props;
            // 현 시간 Get begin
            // let serverTime = await NoticeApi.GetResponseTime(cookies.get('ostype'), cookies.get('deviceid'), cookies.get('login_encrypt'))
            // 현 시간 Get end
            // if (!isReserveAvaliableDateValidateChk(serverTime, CLOSE_HOUR)) {
            //     await calertPromise(`${CLOSE_HOUR}시 이후는 당일, 명일 예약이 불가능합니다.`)
            //     this.setState({
            //         date: getReserveAvaliableDate(moment(serverTime).toDate(), CLOSE_HOUR),
            //         trustNowTime: moment(serverTime).toDate()
            //     })
            //     return;
            // }

            if (retData) {
                this.props.history.push('/reserve_confirm')
            } else {
                await calertPromise('입력 실패')
            }


        } catch (e) {
            console.log(e);
        }
    }

    handleCovidKitStatus = (e) => {
        this.setState({
            covidKitStatus: e.target.checked,
        })
    }

    handleTimeAddMomentChange = (e) => {
        this.setState({
            timeAddMoment: e,
        }, () => {
            this.getCalcDrivingReservation('timeadd');
        })
    }

    // 출발지/목적지/경유지 주소가 전부 지정되었는지를 체크하는 메소드
    isChkAddressChosen = () => {
        let retData = false;
        const {
            startLocationAddress,
            endLocationAddress,      
            stopOverList,
        } = store.getState().LocationSelector_ReduxModule;
        
        // 출발지, 목적지가 전부 지정되었을때 true를 반환.
        retData = (startLocationAddress.length && endLocationAddress.length)
        
        // 경유지 지정 : true, 경유지 미지정 false
        if (stopOverList.length) {
            for(let i in stopOverList) {
                if (stopOverList[i].waypointAddress.length) {
                    retData = true;
                    continue;
                } else {
                    retData = false;
                    break;
                }
            }
        }
        return retData;
    }
 
    render() {



        // const StartTimeSelectCustomComponent = ({ value, onClick }) => (
        //     <span className="ts1" >시작시간 <b onClick={onClick}>{value}</b></span>
        // );

        // const EndTimeSelectCustomComponent = ({ value, onClick }) => (
        //     <span className="ts1" >종료시간 <b onClick={onClick}>{value}</b></span>
        // );

        // const filterPassedTime = time => {
        //     const currentDate = new Date();
        //     const selectedDate = new Date(time);

        //     return currentDate.getTime() < selectedDate.getTime();
        // }

        // const filterPassedEndTime = time => {
        //     // 날짜를 한번 선택하면 이 함수도 호출되나? yes
        //     let retData = false;
        //     let startTime = new Date(this.state.startTime);
        //     let endTime = new Date(this.state.endTime);
        //     let listVisibleTime = new Date(time);

        //     // 같은 날이라면 시작시간 이후의 시간을 선택 가능하도록 처리하고
        //     if (
        //         startTime.getFullYear() === endTime.getFullYear() &&
        //         startTime.getMonth() === endTime.getMonth() &&
        //         startTime.getDay() === endTime.getDay()
        //     ) {
        //         retData = startTime.getTime() < listVisibleTime.getTime();

        //     }
        //     // 같은날의 다음날이라면 무조건 표시
        //     else {
        //         retData = true;
        //     }

        //     return retData;
        // }

        const {presetType} = this.state;
        let callGeneralOrGolf = (this.state.reserveType === 1) ? this.handleUpdateStartTimeGeneral : this.handleUpdateStartTimeGolf
        const { IsOldFare } = store.getState().global_data.loginedData
        // const IsOldFare = true;
        console.log('reserveType',this.state.reserveType);

        let carInfoAreaDynamicClassName = (flag, value) => {
            let retData = 'input type1';
            try {
                
                /* 
                    최초 화면에 들어갔다면 input type1이 나와야 함
                    다음 눌렀을 때 입력안된 영역은 input type1 fail이 나와야 함
                    입력 안된 영역에 1자라도 입력시에는 input type1이 나와야 함.
                */
                if (flag && !value.length) {
                    retData = 'input type1';
                }
                else if (!flag && !value.length) {
                    retData = 'input type1 fail';
                }
                else if (!flag && value.length) {
                    retData = 'input type1';
                }
            }
            catch(e) {

            }
            
            return retData;
        }

        let presetListComponent = (arr) => {
            return arr.map((data, idx) => {
                return (
                    <option value={idx+1}>[프리셋 {idx+1}] {!data.presetName.length ? '미등록' : data.presetName}</option>
                )
            });
        }

        return (
            <>
                {!this.state.loading ? (<Loading />) : ''}
                <Header
                    title={`${this.state.typeView} 예약`}
                    onBackButton={this.handleGotoBack}
                    onHomeButton={this.handleGotoBack}
                />

                <div className="booking_box2 hd_margin_top">
                    <ul className={`bk_type1 bk_type_c${this.props.match.params.state}`}>
                        <li className="bk_head">
                            
                            <span className="bk_title">{this.state.typeView}<img src={icon_arrow_down} className="icon_arrow" alt=""/></span>
                            <span className="bk_info">
                                {ReserveApi.getDrivingTypeDescStr(this.props.match.params.state)}
                            </span>
                        </li>
                    </ul>

                    <ul className="bk_section">
                        <li className="section_box">

                            {/* <LocationSelectorContainer history={this.props.history} endPointStr={ReserveApi.getChangeStrJongryoji(this.state.reserveType)}/> */}
                            <LocationSelectorContainer history={this.props.history} type={this.state.reserveType} endPointStr={ReserveApi.getChangeStrJongryoji(this.state.reserveType)} callback={this.handleCallbackDuration}/>
                            
                            {/* 골프는 일반 처럼 편도, 왕복이 가능해졌기 때문에 이 문구는 필요없음.(6/2)
                            {(Number(this.props.match.params.state) === RESERVE_TYPE_GOLF_FLAG) ? (
                                <div className="bul notice">
                                    <p className="text-import">골프서비스는 출발지로 돌아와 종료되는 왕복운행입니다.<br/>편도운행을 원하시는 경우, 일반예약 이용 또는 고객센터로 문의해 주시기 바랍니다.</p>
                                </div>
                            ) : null} */}

                            <div className="bul notice">
                                <p>경유지는 최대 3개까지 등록하실 수 있습니다.</p>
                                <p>계속해서 더 많은 경유지를 추가하실 경우, 요청사항에 메모해주세요.</p>
                                <p>지역별/출발지별 요금이 과금될수 있습니다. </p>
                            </div>
                            
                            { (Number(this.props.match.params.state) !== RESERVE_TYPE_GOLF_FLAG) && (
                                <div className="grid cross-center mar-t10">
                                <div className="col right">
                                    <span><button className="btn text1" onClick={()=>{
                                        this.gotoPolicyPrice(3)
                                    }}>지역할증안내 확인하기</button></span>
                                </div>
                            </div>
                            ) }

                        </li>

                        <li className="section_box">
                            <span className="section_box_deatil">

                                <div className="reserve-head grid">
                                    <div className="col title">예약일</div>
                                    {/* <div className="col right">
                                        <span className="text-sub">예상소요시간 <strong>{this.state.predictingTimeStr}</strong></span>
                                    </div> */}
                                </div>
                                
                                <TimeSelector2
                                    startTime={this.state.startTime}
                                    endTime={this.state.endTime}
                                    selectedDate={this.state.date}
                                    type={this.state.reserveType}
                                    onChangeStart={callGeneralOrGolf}
                                    onChangeEnd={this.handleUpdateEndtime}
                                    onDateChange={this.handleSaveDate}
                                    fixedStartTime={this.state.fixedStartTime}
                                    hourView={this.changeHourView(this.state.hourView)}

                                    onIncrease={this.handleEndtimeIncrease}
                                    onDecrease={this.handleEndtimeDecrease}
                                    onDetailToggle={this.handleUpdateDetailViewStateToggle}
                                    
                                    selectHour={this.state.selectHour}
                                    detailState={this.state.detailViewState}
                                    predictingTimeStr={this.state.predictingTimeStr}
                                    predictingHour={this.state.predictingHour}
                                    predictingMin={this.state.predictingMin}
                                    totalTime={this.state.totalTime}
                                    moneyViewObj={{
                                        allMoney: this.state.moneyView,
                                        startAreaFee: this.state.startAreaFee,
                                        areaFee: this.state.areaFee,
                                        drivingCharge: this.state.drivingCharge,
                                        nightFee: this.state.nightFee
                                    }}
                                    displayEndTime={this.state.displayEndTime}
                                    ReserveDiscountAmount={this.state.ReserveDiscountAmount}

                                    limitHour={LIMIT_HOUR}
                                    nowTime={this.state.trustNowTime}
                                    closeHour={CLOSE_HOUR}
                                    
                                    timeAddMoment={this.state.timeAddMoment}
                                    onTimeAddMomentChange={this.handleTimeAddMomentChange}
                                />
                                
                            </span>

                            { (Number(this.props.match.params.state) !== RESERVE_TYPE_GOLF_FLAG) && (
                                <div className="grid cross-center mar-t15 mar-b15">
                                    <div className="col right">
                                        <span><button className="btn text1" onClick={()=>{
                                            this.gotoPolicyPrice(1)
                                        }}>전체요금안내 확인하기</button></span>
                                    </div>
                                </div>
                            ) }                         
                            
                        </li>
                    </ul>


                    <div className="accordion-container type-reserve">
                        {/* 차량정보 */}
                        <section className="accordion on">
                            <h1 className="accordion-head">
                                {/* accordion-head 하위에는 원래 button이었지만 div로 볁경한다. */}
                                <div>
                                    <span className="grid cross-center mar-r25">
                                        <div className="col title">차량정보<i className="ico required"><span className="blind">필수입력항목</span></i></div>
                                        <div className="col right"></div>
                                    </span>
                                    {/* <i className="arrow"></i> */}
                                </div>
                            </h1>
                            <div className="accordion-body">
                                <div className="form-controls type-carinfo">

                                    <div className="row">
                                        
                                            <div className="col col-9">
                                                <select className="select type1" value={this.state.carInfoSelectedIdx} onChange={this.handleChangeCarInfoPreset}>
                                                    <option value="0">차량정보 프리셋 선택</option>
                                                    {presetListComponent(this.state.carInfoPreset)}
                                                </select>
                                            </div>
                                            
                                            <div className="col col-3">
                                                <button 
                                                    type="button" 
                                                    className={ (this.state.carInfoSelectedIdx === "0") ? "btn medium" : "btn medium pressed" } 
                                                    disabled={this.state.carInfoSelectedIdx === "0"} 
                                                    onClick={()=>{
                                                        this.handleChangePresetType(PRESET_TYPE_CARINFO)
                                                        this.handleChangeModalStatusTrue();
                                                    }}>
                                                        <span>변경</span>
                                                </button>

                                                <NavLink to="#" className="tooltip">
                                                    <i className="ico ico-quest"></i>
                                                    <span className="tooltip-content-custom">
                                                        <p><strong>자주 사용하는 차량정보를</strong></p>
                                                        <p><strong>미리 설정할 수 있습니다.</strong></p>
                                                        <p>프리셋 설정은 셀렉트 박스에</p>
                                                        <p>원하는 프리셋을 선택 후 </p>
                                                        <p>변경 버튼을 누르면 변경 가능합니다.</p>
                                                        <p>최대 5개까지 등록 가능합니다.</p>
                                                    </span>
                                                </NavLink>

                                            </div>
                                        
                                    </div>
                                    
                                    
                                    <div className="row">
                                        {/* <FloatLabelInput inputTypeId="carinfo-1" labelText="차량 모델" onChange={this.handleCarModel} value={this.state.carModel} className={carInfoAreaDynamicClassName(this.state.carInfoAreaFlag, this.state.carModel)} />
                                        <FloatLabelInput secondFlag inputTypeId="carinfo-2" labelText="차량 번호" onChange={this.handleCarNo} value={this.state.carNo} className={carInfoAreaDynamicClassName(this.state.carInfoAreaFlag, this.state.carNo)} /> */}

                                        <input type="text" name="" id="" onChange={this.handleCarModel} value={this.state.carModel} placeholder="차량 모델 ex: BMW 7" title="" className={carInfoAreaDynamicClassName(this.state.carInfoAreaFlag, this.state.carModel)} />
                                        <input type="text" name="" id="" onChange={this.handleCarNo} value={this.state.carNo} placeholder="차량 번호 ex: 12가 1234" title="" className={carInfoAreaDynamicClassName(this.state.carInfoAreaFlag, this.state.carNo)} />
                                    </div>
                                    <div className="row">
                                        <textarea className={carInfoAreaDynamicClassName(this.state.carInfoAreaFlag, this.state.carLocation)} rows="3" placeholder="파트너와 만날 장소를 입력해주세요." value={this.state.carLocation} onChange={this.handleCarLocation}></textarea>
                                    </div>

                                    <div className="row text-gray">
                                        {Util.getStringLength(this.state.carLocation)}/{TEXTAREA_MAX_LENGTH} 
                                        {Util.getStringLength(this.state.carLocation) >= TEXTAREA_MAX_LENGTH ? (<b> (100자 이상 입력할 수 없습니다.)</b>) : ''}
                                    </div>
                                    { (!this.getCarNoCarModel(CAR_INFO_TYPE_CAR_NO).length || !this.getCarNoCarModel(CAR_INFO_TYPE_CAR_MODEL).length ) && (
                                        <div className="bul notice">
                                            <p><NavLink className="text-underline" to="/first_1/1">메뉴 &gt; 회원정보변경 &gt; 차량정보</NavLink> 화면에서 번호, 모델명을 등록하시면 이후 예약 시, 자동삽입됩니다.</p>
                                        </div>
                                    ) }
                                </div>
                            </div>
                        </section>

                        {/* 탑승자정보 */}
                        <section className="accordion on">
                            <h1 className="accordion-head">
                                {/* accordion-head 하위에는 원래 button이었지만 div로 볁경한다. */}
                                <div>
                                    <span className="grid cross-center">
                                        <div className="col col-7 title">탑승자정보<i className="ico required"><span className="blind">필수입력항목</span></i></div>
                                        <div className="col col-5">
                                            <span className="check-box round">
                                                <input type="checkbox" id="passenger" checked={this.state.passengerCheckStatus} onChange={this.handlePassengerCheckStatus} />
                                                <label for="passenger"><span>본인일 경우 선택</span></label>
                                            </span>                                      
                                        </div>
                                    </span>
                                    {/* <i className="arrow"></i> */}
                                </div>
                            </h1>  
                            <div className="accordion-body">
                                <div className="form-controls type-passenger">
                                    <input type="text" disabled={this.state.passengerCheckStatus} className={`${carInfoAreaDynamicClassName(this.state.passengerAreaFlag, this.state.passengerName)} W100`}  placeholder="탑승자 이름" value={this.state.passengerName} onChange={this.handlePassengerName} />
                                    <input type="text" disabled={this.state.passengerCheckStatus} className={`${carInfoAreaDynamicClassName(this.state.passengerAreaFlag, this.state.passengerPhone)} W100`} placeholder="탑승자 전화번호" value={this.state.passengerPhone} onChange={this.handlePassengerPhone} />
                                </div>        
                            </div>
                        </section> 

                        {/* 요청사항 */}
                        <section className="accordion on">
                            <h1 className="accordion-head">
                                {/* accordion-head 하위에는 원래 <button type="button">이었지만 div로 변경한다. */}
                                <div>
                                    <span className="grid cross-center">
                                        <div className="col col-7 title">요청사항</div>
                                        <div className="col col-5">
                                            {/* 방역키트 지급 축소에 따른 방역키트제공 체크박스 비활성화
                                            <span className="check-box round">
                                                <input type="checkbox" id="covidkit" checked={this.state.covidKitStatus} onChange={this.handleCovidKitStatus} />
                                                <label for="covidkit"><span>방역키트 제공</span></label>
                                            </span>                                       */}
                                        </div>
                                    </span>
                                    {/* <i className="arrow"></i> */}
                                </div>
                            </h1>
                            <div className="accordion-body">
                                <div className="form-controls type-carinfo">
                                    <div className="row">
                                        
                                            <div className="col col-9">
                                                <select 
                                                    className="select type1" 
                                                    value={this.state.commentPresetSelectedIdx} 
                                                    onChange={this.handleChangeCommentPreset}>
                                                    <option value="0">요청사항 프리셋 선택</option>
                                                    {presetListComponent(this.state.commentPreset)}
                                                </select>
                                            </div>
                                            
                                            <div className="col col-3">
                                                <button 
                                                    type="button" 
                                                    className={ (this.state.commentPresetSelectedIdx === "0") ? "btn medium" : "btn medium pressed" } 
                                                    disabled={this.state.commentPresetSelectedIdx === "0"} 
                                                    onClick={()=>{
                                                        this.handleChangePresetType(PRESET_TYPE_COMMENT)
                                                        this.handleChangeModalStatusTrue();
                                                    }}>
                                                        <span>변경</span>
                                                </button>
                                                <NavLink to="#" className="tooltip">
                                                    <i className="ico ico-quest"></i>
                                                    <span className="tooltip-content-custom">
                                                        <p><strong>자주 사용하는 요청사항을</strong></p>
                                                        <p><strong>미리 설정할 수 있습니다.</strong></p>
                                                        <p>프리셋 설정은 셀렉트 박스에</p>
                                                        <p>원하는 프리셋을 선택 후 </p>
                                                        <p>변경 버튼을 누르면 변경 가능합니다.</p>
                                                        <p>최대 5개까지 등록 가능합니다.</p>
                                                    </span>
                                                </NavLink>
                                            </div>
                                        
                                    </div>
                                    <div className="row">
                                        <textarea className="input type1" rows="6" placeholder="요청사항을 입력해주세요." value={this.state.comment} onChange={this.handleComment}></textarea>
                                    </div>
                                    <div className="row text-gray">                                            
                                        {Util.getStringLength(this.state.comment)}/{TEXTAREA_REQUEST_MAX_LENGTH} 
                                        {Util.getStringLength(this.state.comment) >= TEXTAREA_REQUEST_MAX_LENGTH ? (<b> (300자 이상 입력할 수 없습니다.)</b>) : ''}
                                        
                                    </div>
                                </div>                
                            </div>
                        </section>                                                                       
                    </div>


                    <div className="component-wrap inner-container">
                        {(IsOldFare && Number(this.props.match.params.state) === RESERVE_TYPE_GENERAL_FLAG) && (
                            <div className="grid cross-center mar-b10">
                                <div className="col right text-main text-darkgray">
                                    고객님은 기존 요금제 적용 대상 회원입니다.
                                </div>
                            </div>	)}
                        
                        <div className="grid cross-center mar-b10">
                            <div className="col text-main text-darkgray">
                                <span>총예약시간</span> <strong className="text-black">{this.state.totalTime}</strong>
                                {/*
                                <strong>{this.state.typeView}&nbsp;{this.changeHourView(this.state.hourView)}</strong>
                                */}
                            </div>
                            <div className="col right text-main text-darkgray">
                                <span>이용예상금액 <strong className="text-large text-black">{Util.numberWithCommas(this.state.moneyView)}</strong>원</span>
                            </div>
                        </div>	
                        <div className="btn-area vertical">
                            <button type="button" className="btn large secondary" onClick={this.handleNextBtnValidate}><span>다음</span></button>
                        </div>	
                    </div> 

                </div>
                <ScrollTopBtn position={'channel'}/>
                <Modal
                    isOpen={this.state.modalOpenFlag}
                    onRequestClose={this.handleChangeModalStatusFalse}
                    contentLabel="Example Modal" 
                    overlayClassName="modal-overlay"
                    className="modal-box"
                    bodyOpenClassName="modal-scrolllock"
                >
                    <section className="modal-container">
                        <div className="modal-preset-setting">
                            <div className="modal-head">
                                
                                {presetType === PRESET_TYPE_CARINFO ? (
                                    <h1 className="modal-tit" tabindex="0">차량정보 프리셋 변경 [프리셋 {this.state.carInfoSelectedIdx}]</h1>
                                ) : (
                                    <h1 className="modal-tit" tabindex="0">요청사항 프리셋 변경 [프리셋 {this.state.commentPresetSelectedIdx}]</h1>
                                )}
                                
                                <button className="btn-top-close" onClick={this.handleChangeModalStatusFalse}>
                                    <span className="blind">팝업 닫기</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="accordion-body">
                                    <div className="form-controls type-carinfo" style={{
                                        paddingLeft:'10px',
                                        paddingRight:'10px',
                                    }}>

                                        {presetType === PRESET_TYPE_CARINFO ? (
                                            <>
                                                <div className="row">
                                                    <input type="text" name="" id="preset_name" placeholder="프리셋 제목(한글 8자)" title="" className={'input type1 W100'}   value={this.state.selectedPresetName} onChange={this.handleChangePresetName}  />
                                                </div>

                                                <div className="row">
                                                    <input type="text" name="" id="preset_car_model" placeholder="차량 모델 ex: BMW 7" title="" className={'input type1'}  value={this.state.selectedPresetCarModel} onChange={this.handleChangePresetCarModel} />
                                                    <input type="text" name="" id="preset_car_no" placeholder="차량 번호 ex: 12가 1234" title="" className={'input type1'} value={this.state.selectedPresetCarNo} onChange={this.handleChangePresetCarNo} />
                                                </div>

                                                <div className="row">
                                                    <textarea className="input type1" rows="3" placeholder="파트너와 만날 장소를 입력해주세요." value={this.state.selectedPresetCarMemo} onChange={this.handleChangePresetCarMemo}></textarea>
                                                </div>

                                                <div className="row text-gray">
                                                    {Util.getStringLength(this.state.selectedPresetCarMemo)}/{TEXTAREA_MAX_LENGTH}
                                                    {Util.getStringLength(this.state.selectedPresetCarMemo) >= TEXTAREA_MAX_LENGTH ? (<b> (100자 이상 입력할 수 없습니다.)</b>) : ''}
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <div className="btn-area vertical W50">
                                                        <button type="button" className="btn large gray noround " onClick={this.handleClearPreset}><span>삭제</span></button>
                                                    </div>
                                                    <div className="btn-area vertical W50">
                                                        <button type="button" className="btn large primary noround" onClick={this.handleSetPresetCarInfo}><span>저장</span></button>
                                                    </div>
                                                </div>
                                            </>
                                        
                                        ) : (
                                            <>
                                                <div className="row">
                                                    <input type="text" name="" id="preset_name" placeholder="프리셋 제목(한글 8자)" title="" className={'input type1 W100'}   value={this.state.selectedCommentPresetName} onChange={this.handleChangeCommentPresetName}  />
                                                </div>
                                                <div className="row">
                                                    <textarea className="input type1" rows="3" placeholder="저장할 요청사항을 입력해주세요." value={this.state.selectedPresetComment} onChange={this.handleChangePresetComment}></textarea>
                                                </div>

                                                <div className="row text-gray">
                                                    {Util.getStringLength(this.state.selectedPresetComment)}/{TEXTAREA_REQUEST_MAX_LENGTH}
                                                    {Util.getStringLength(this.state.selectedPresetComment) >= TEXTAREA_REQUEST_MAX_LENGTH ? (<b> (300자 이상 입력할 수 없습니다.)</b>) : ''}
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <div className="btn-area vertical W50">
                                                        <button type="button" className="btn large gray noround " onClick={this.handleClearCommentPreset}><span>삭제</span></button>
                                                    </div>
                                                    <div className="btn-area vertical W50">
                                                        <button type="button" className="btn large primary noround" onClick={this.handleSetCommentPreset}><span>저장</span></button>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        

                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Modal>

            </>
        );
    }
};

export default withLastLocation(withCookies(ReserveGeneralView));
