import React, { Component } from 'react';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import TimePickerCustomCss from '../../css/CustomGlobalStyle/TimePickerCustom'
import DatePicker, { registerLocale } from "react-datepicker";
import ko from 'date-fns/locale/ko';
import icon_calendar from '../../img/booking/icon_calendar.svg'
import icon_arrow_right from '../../img/booking/icon_arrow_right.svg'
import scriptjs from 'scriptjs'
registerLocale('ko', ko)

const MAX_HOURS = 24;
const MAX_MINUTE = 60;
const MINUTE_STEP = 10;     // 10분단위로 (10, 20, 30, 40 ...)
const RESERVE_TYPE_GENERAL_FLAG = 1
const RESERVE_TYPE_GOLF_FLAG = 3
class TimeSelector2Air extends Component {
    constructor(props) {
        super(props)
        this.endEsc = null;
        this.state={
            // selectedDate: moment('2021-01-10T00:00:00.000'),
            // startTime: moment(),
            // endTime: moment().add(1,'hours'),
            startVisibleHours:[],
            startVisibleMinute:[],
            endVisibleHours:[],
            endVisibleMinute:[],
            startTimePickerProps:{},
            endTimePickerProps:{}
        }
    }  

    initStartDisabledHour = () => {
        // 여기에 date가 개입하게 되면..
        let tmpArr = [];
        // let nowMoment = moment()        // 현재 시간으로 하고 싶을때는 moment()로
        // let nowMoment = moment('2021-01-13T19:51:00');
        let nowMoment = this.props.fixedStartTime
        
        let selectedDate = moment(this.props.selectedDate);
        console.log(nowMoment.minutes());
        if (Number(selectedDate.format('YYYYMMDD')) === Number(nowMoment.format('YYYYMMDD'))) {
            // 아래처리를 내가 왜 넣어놨었지?
            // this.props.onChangeStart(this.props.fixedStartTime)
            for (let i=0; i<MAX_HOURS; i++) {
                // 매시 51분 이상일때는 disabled list로 포함시켜야 한다.
                if ( (i < nowMoment.hours()) ) { tmpArr.push(i); }  // 같은 시 이면 현 시점에서 선택 가능한 시을 배열화 한다.
                else if ( (i === nowMoment.hours()) && (nowMoment.minutes() >= 51)) {  tmpArr.push(i); }
            }
        } else {
            tmpArr = [];
        }
        console.log(tmpArr)
        
        return tmpArr;
    }

    initStartDisabledMinute = () => {
        // 여기에 date가 개입하게 되면..
        let tmpArr = [];
        // let nowMoment = moment()        // 현재 시간으로 하고 싶을때는 moment()로
        let nowMoment = this.props.startTime
        let selectedDate = moment(this.props.selectedDate);
        
        if (Number(selectedDate.format('YYYYMMDD')) === Number(nowMoment.format('YYYYMMDD'))) {
            for (let i=0; i<MAX_MINUTE; i+=MINUTE_STEP) {
                if (i < nowMoment.minutes()) {  tmpArr.push(i);  }  // 같은 시 이면 현 시점에서 선택 가능한 분을 배열화 한다.
            }
        } 
        else {
            tmpArr = [];
        }
        
        return tmpArr;
    }

    initEndDisabledHour = (e) => {
        // 여기에 date가 개입하게 되면..
        let tmpArr = [];
        let tmpStartTime = (e && e !== undefined) ? 
            moment(e.toDate()).add(1, 'hours').hours() : 
            moment(this.props.startTime.toDate()).add(1, 'hours').hours();
        
        // 지정한 startTime 이전으로는 선택할 수 없도록 처리한다.
        for (let i=0; i<tmpStartTime; i++) {
            tmpArr.push(i);
        }

        return tmpArr;
    }

    initEndDisabledMinute = (e) => {
        // 여기에 date가 개입하게 되면..
        let tmpArr = [];
        let tmpStartTime = (e && e !== undefined) ? 
            moment(e.toDate()).add(1, 'hours').minutes() : 
            moment(this.props.startTime.toDate()).add(1, 'hours').minutes();
        
        // 지정한 startTime 이전으로는 선택할 수 없도록 처리한다.
        for (let i = 0; i < tmpStartTime; i+=MINUTE_STEP ) {
            tmpArr.push(i);
        }

        return tmpArr;
    }


    disabledMinute = (e) => {
        // 여기에 date가 개입하게 되면..
        let tmpArr = [];
        // let nowMoment = moment()        // 현재 시간으로 하고 싶을때는 moment()로
        let nowMoment = this.props.fixedStartTime
        let selectedDate = moment(this.props.selectedDate);

        if (Number(selectedDate.format('YYYYMMDD')) === Number(e.format('YYYYMMDD'))) {
            if (e.hours() === nowMoment.hours()) {
                for (let i=0; i<MAX_MINUTE; i+=MINUTE_STEP) {
                    // 같은 시 이면 현 시점에서 선택 가능한 분을 배열화 한다.
                    if (i < nowMoment.minutes()) {
                        tmpArr.push(i);
                    }
                }
            } else {
                tmpArr = [];
            }
            
        } else {
            tmpArr = [];
        }
        
        return tmpArr;
    }

        /*
    starttime : 14:15면
    endtime은 15:15일테고

    endtime을 누르면 
    15:15 이전은 선택되면 안된다
    시를 16으로 바뀌면 00, 10, 20, 30.. 다 나와야 한다.
    다시 15시로 바뀌면 20, 30, 40, 50 으로 나와야 한다.

    이렇게 되려면 startTime
   
    */

    disabledMinuteFromStartTime = (e) => {
        // 여기에 date가 개입하게 되면..
        let tmpArr = [];
        let tmpStartTime = moment(this.props.startTime.toDate()).add(1,'hours');
        
        // 내가 지정한 시 와 startTime이 같다면
        if (e.hours() === tmpStartTime.hours()) {
            for (let i=0; i<MAX_MINUTE; i+=MINUTE_STEP) {
                // 같은 시 이면 현 시점에서 선택 가능한 분을 배열화 한다.
                if (i < tmpStartTime.minutes()) {
                    tmpArr.push(i);
                }
            }
        } else {
            tmpArr = [];
        }
        
        return tmpArr;
    }



    componentDidMount () {
        // scriptjs('https://code.jquery.com/jquery-1.12.4.min.js', ()=>{
            
        // });
        console.log(window)
        console.log('componentDidMount timeselector')

        this.setState({
            startVisibleHours:this.initStartDisabledHour(),
            startVisibleMinute: this.initStartDisabledMinute(),
            endVisibleHours:this.initEndDisabledHour(),
            endVisibleMinute: this.initEndDisabledMinute(),
        })
        /*
        -컴포넌트가 로드 될때
        1. startTime 관점
            1) 현재 날짜와 시간을 고려하여 startVisibleHours, startVisibleMinute를 세팅한다.
            startVisibleHours
                오늘 날짜일 경우 => 현재 시각을 고려하여 disable할 시를 계산한다.
                오늘 날짜 외일 경우 => 24시간 표시한다.
            startVisibleMinute =>
                오늘 날짜일 경우 => 현재 시각을 고려하여 disable할 분을 계산한다.
                오늘 날짜 외일 경우 => 전체 분을 표시한다.
        2. endTime 관점
            1) startTime만 보면 된다. 지정한 시, 분에 따라 선택 가능한 값이 달라야 한다.
               만약 startTime - 11:05라면 
        
        -startTime 변경시
        1. handleChangeStartTime()가 호출되어야 한다.
            startTime을 갱신한다.
            endtime은 starttime + 1시간으로 갱신한다.
            startVisibleMinute도 변경해야한다. ("시" 변경시 분 계산을 해줘서 표시할 분을 표시해야한다.)
            endVisibleHour, endVisibleMinute도 변경해줘야한다.
            

        -endTime 변경시
        
        -날짜 변경시
        */

    }

    handleChangeStartTime = (e) => {
        // 클릭할때마다 시간 계산 실시해서 setState에 넣어놓고
        // disabledHours에는 state값을 리턴하는 식으로 진행.
        // 시작시간일경우 "시"를 건들면 안된다. 분을 건들자.
        // 현재 시간이 14:10 라면 분쪽은 15, 20, 25, 30, 35, 40, 45, 50, 55 선택 가능
        // 현재 시간이 15:00 라면 분쪽은 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55 선택 가능
        
        // let tmp = moment(e.toDate());
        // console.log(tmp.add(1,'hours'))
        console.log('handleChangeStartTime')
        this.props.onChangeStart(e)

        

        this.setState({
            // startTime: e,
            // endTime: tmp.add(1,'hours'),

            startVisibleMinute: this.disabledMinute(e),
            endVisibleHours: this.initEndDisabledHour(e),
            endVisibleMinute: this.initEndDisabledMinute(e)
        },()=>{
            
        })
        
        
    }

    handleChangeEndTime = (e) => {
        // 클릭할때마다 시간 계산 실시해서 setState에 넣어놓고
        // disabledHours에는 state값을 리턴하는 식으로 진행.
        // 시작시간일경우 "시"를 건들면 안된다. 분을 건들자.
        // 현재 시간이 14:10 라면 분쪽은 15, 20, 25, 30, 35, 40, 45, 50, 55 선택 가능
        // 현재 시간이 15:00 라면 분쪽은 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55 선택 가능
        console.log('handleChangeEndTime')
        this.props.onChangeEnd(e)
        this.setState({
            // endTime: e,
            endVisibleMinute: this.disabledMinuteFromStartTime(e)
        },()=>{
            
        })
        // 20210218 [MosilerAPP-User]TestCheck_List.xlsx H10셀에 의해 사용하지 않음
        // this.endEsc();
    }

    changeHourView = (data) => {
        let tmp = '';
        try {
            tmp = data.replace('시간','시간 ')
            
        }
        catch(e){}
        return tmp;
    }

    render() {
        const DatePickCustomComponent = ({ value, onClick }) => (
            <span className="list_sec1" style={{paddingBottom:'14.5px'}}>
                <span style={{fontSize:'1.05em'}}>{value} </span>
                <button className="icon_calandar" onClick={onClick}>
                    <img src={icon_calendar} alt="" />
                </button>
            </span>
        );

        const filterPassedDate = date => {
            // date : 화면상에 디스플레이될 날짜 목록

            // 선택시 본 콜백이 발생하며 달력에 표시된 모든 데이터가 나온다.
            // 여기서 선택했을때 이전의 날짜는 선택 못하도록 해야한다.
            let currentDate = new Date();
            currentDate.setDate(currentDate.getDate() - 1)    // 하루 전

            let selectedDate = new Date(date);              // 선택한 날짜

            // console.log(currentDate.getTime()) 
            return currentDate.getTime() <= selectedDate.getTime();
        }


        return (
            <>
                <TimePickerCustomCss />
                <span className="list_t2">

                    {/* 날짜를 지정하는게 의미가 없음. 시작시간, 종료시간이 다음날을 넘어가는 경우가 생길 수 있기 때문. */}
                    <DatePicker
                        dateFormat="yyyy-MM-dd(eee)"
                        renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled
                        }) => (
                            <div style={{
                                margin: 10,
                                display: "flex",
                            }} >
                                <div style={{flex:'1'}}>
                                    <button style={{fontSize:'1.2em', border:'none'}} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                        {"◀"}
                                    </button>
                                </div>
                                <div style={{flex:'10'}}>
                                    <span style={{
                                        fontSize:'1.2em',
                                        fontWeight:'bold'
                                    }}>
                                    {moment(date).format('YYYY')}년 {moment(date).format('MM')}월
                                    </span>
                                </div>
                                <div style={{flex:'1'}}>
                                    <button style={{fontSize:'1.2em', border:'none'}} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                        {"▶"}
                                    </button>
                                </div>
                            </div>
                        )}
                        selected={this.props.selectedDate}
                        onChange={async date => {
                            await this.props.onDateChange(date);
                            this.setState({
                                startVisibleHours: this.initStartDisabledHour(),
                                startVisibleMinute: this.initStartDisabledMinute(),
                                endVisibleHours: this.initEndDisabledHour(),
                                endVisibleMinute: this.initEndDisabledMinute(),
                            })

                        }}
                        customInput={<DatePickCustomComponent />}
                        popperPlacement="auto"
                        locale="ko"
                        
                        filterDate={filterPassedDate}
                    />


                    {/* <DateSelector
                                        onChangeData={this.handleSaveDate} /> */}
                    {/* <span className="list_sec1">
                                        <span>{this.state.date}</span>
                                        <span>2020.07.15(수)</span>
                                        <button className="icon_calandar">
                                            <img src={icon_calendar} alt="" />
                                        </button>
                                    </span> */}

                    <span className="list_sec1">
                        <span className="time_se">
                            <span className="ts1">시작시간</span>
                            <TimePicker
                                showSecond={false}
                                value={this.props.startTime}
                                onChange={this.handleChangeStartTime}
                                format={'HH:mm'}
                                disabledHours={() => (this.state.startVisibleHours)}
                                disabledMinutes={() => (this.state.startVisibleMinute)}
                                minuteStep={MINUTE_STEP}
                                hideDisabledOptions={true}
                                focusOnOpen={true}
                                inputReadOnly={true}
                            />
                        <span class="ts_arrow"><img src={icon_arrow_right} alt="" /></span>
                        <span className="ts1">종료시간</span>
                            <TimePicker
                                disabled={Number(this.props.type) === RESERVE_TYPE_GOLF_FLAG}
                                showSecond={false}
                                value={this.props.endTime}
                                // className="xxx"
                                onChange={this.handleChangeEndTime}
                                format={'HH:mm'}
                                disabledHours={() => (this.state.endVisibleHours)}
                                disabledMinutes={() => (this.state.endVisibleMinute)}
                                minuteStep={MINUTE_STEP}
                                hideDisabledOptions={true}
                                focusOnOpen={true}
                                inputReadOnly={true}
                                addon={(data)=>{
                                    this.endEsc = data.props.onEsc
                                }}
                            />
                        </span>
                        <span className="right_container">
                            <span className="right_test1_hour"><b>{this.props.hourView}</b> </span>
                        </span>
                    </span>
                </span>



            

            </>
        );
    }
};

export default TimeSelector2Air;
