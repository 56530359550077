import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import '../../css/default.css';
import '../../css/booking.css';


import btn_prev from '../../img/btn/btn_prev.svg'
import btn_home from '../../img/btn/btn_home.svg'

class HeaderJoin extends Component {

    render() {
        return (<>

            <header id="Header" className="">
                <button type="button" className="btn-history-back" onClick={()=>{
                        this.props.onBackButton()
                    }}>
                    <span className="blind">이전화면가기</span>
                </button>
                {this.props.onHomeButton !== undefined && (
                    <button type="button" className="btn-top-home"  onClick={(e)=>{
                        e.preventDefault();
                        this.props.onHomeButton()
                    }}>
                        <span className="blind">홈화면가기</span>
                    </button>
                )}
                <h1>{this.props.title}</h1>
            </header>  
{/* 

            <div className="head"  style={{borderBottom:'1px solid #ececec'}}>
                <ul className="type1 type1_mt5">
                    <li className="btn">
                        <button className="btn_style1" onClick={()=>{
                            this.props.onBackButton()
                        }}>
                            <img src={btn_prev} className="hd_icon" alt="" />
                        </button>
                    </li>
                    <li className="head_title">{this.props.title}</li>
                    
                </ul>
            </div> */}
            
        </>
        );
    }
};

export default HeaderJoin;
