import axios from 'axios';
import { Token, PARTNER_BASE_URL, Timeout } from '../constantData'

const InviteApi = {};

InviteApi.GetShareCode = (CustomerNo, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/GetShareCode?customerNo=${CustomerNo}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
            
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}

InviteApi.GetShareCodeHistory = (CustomerNo, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/GetShareCodeHistory?customerNo=${CustomerNo}`,
            method: 'GET',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
            
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}

InviteApi.SetShareCode = (formData, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/SetShareCode`,
            method: 'POST',
            timeout: Timeout,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token,
                'Authorize' : loginEncrypt,
            },
            data: formData
            
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}


export default InviteApi;