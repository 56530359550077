import React, { Component } from "react";
import { withLastLocation } from "react-router-last-location";
import { withCookies } from "react-cookie";
import Header from "../../../components/Common/Header";
import LocationSelectorNewContainer from '../../../containers/Reserve/LocationSelectorNewContainer'
import RnInterface from "../../../lib/RnInterface";
import ReserveApi from "../../../lib/ReserveApi";
import moment from 'moment'
import { 
    calertPromise,
    getAddressFavoriteDataLS,
    setAddressFavoriteDataLS
} from "../../../lib/Util";
import store from '../../../reduxStore'
import Loading from '../../../components/Common/Loading'

import "../../../css/default.css";
import "../../../css/booking.css";
import "react-datepicker/dist/react-datepicker.css";

import icon_arrow_down from '../../../img/booking/icon_arrow_down.svg'
import { FAVORITE_INIT_TEMPLATE } from "../../../constantData";



const RESERVE_TYPE_GENERAL_FLAG = 1
const RESERVE_TYPE_GOLF_FLAG = 3

const LIMIT_HOUR = 0;
const RESERVATION_BASE_TIME = (window.location.href.indexOf('devclient.mosiler.com') !== -1) ? 0 : 0;                   // 예약 기준 시간 (현재일로부터 29시간 이후로 시작/종료시간을 산정.)  devclient면 30시간(테스트용) hybclient는 29시간
const RESERVATION_GOLF_BASE_TIME = 10;              // 골프 기본 시간 (10시간)



const RESERVE_PAGE_1 = 1;



/*
    * Reserve Confirm View에서 아래의 데이터를 넘겨야 한다.
    - localStorage name : reserve_view_all_state  
    - Property            
            date                    date: "2022-01-04T15:00:00.000Z"
            startTime               startTime: "2022-01-03T22:00:00.000Z"
            endTime                 endTime: "2022-01-04T05:20:55.939Z"
            reserveType             reserveType: 1
            startTimeString         startTimeString: "2022-01-05 07:00:00.000"
            endTimeString           endTimeString: "2022-01-05 10:00:00.000"
            carLocation             
            comment                 
            passengerName           
            passengerPhone          
            carNo                   
            carModel                
            covidKitStatus                     
*/


class ReserveStep1View extends Component {
    // 원 페이지로 가는게 좋을까.
    // 페이지마다 js로 만들어야 하나...
    // 우선 원페이지로 시도해볼까?

    state = {
        loading: 1,
        pageCount:RESERVE_PAGE_1,
        reserveType: 1,
        totalTime:'',      
    };

    unlisten = null;

    async componentDidMount() {
        await RnInterface.AndroidURLChange(this.props.location.pathname)
        this.setState({
            reserveType: Number(this.props.match.params.state),
        });

        // 즐겨찾기 LocalStorage에 있는지 확인후 없으면 추가 begin
        let favoriteData = getAddressFavoriteDataLS()
        if ( JSON.stringify(favoriteData) === JSON.stringify({}) ) {
            setAddressFavoriteDataLS(FAVORITE_INIT_TEMPLATE)
        }
        // 즐겨찾기 LocalStorage에 있는지 확인후 없으면 추가 end

        
        this.unlisten = this.props.history.listen((location, action) => {           
            // 예약화면 (1/5)에서 주소 선택시의 주소선택링크가 push되어 스택에 남게 되고,
            // 예약화면 (1/5)로 넘어와서 뒤로가기를 누르면 push된 링크를 pop만 하게 되고, 
            // 예약 화면 (1/5) 자체는 스택에 남아있게 된다. 예약화면 (1/5)에 머물게 된다.
            // 이를 해결하기 위해 예약화면 (1/5)에서 안드로이드의 뒤로가기버튼을 눌렀을때나 this.props.history.goBack()이 호출되었을 때
            // 아래의 이벤트가 발생하며, 바로 메인화면으로 넘어가는 처리를 한다.
            if (
                (action === 'POP') &&
                location.pathname === '/reserve_monthlyonce/1/1') {
                    this.props.history.replace('/main')
                    console.log(`${location.pathname} ${location.key} ${action}`)
            }
        })
    }
    
    // 예약화면 (1/5)에서 벗어날때 아래가 호출되며 unlisten()을 통해 listen이벤트를 remove시킨다.
    componentWillUnmount() {
        if (this.unlisten !== null) this.unlisten();
    }

    handleGotoBack = async () => {
        this.props.history.goBack();
    };

    handleGotoHome = async () => {
        this.props.history.replace('/main');
        this.props.history.push('/main');
    };

    setStateOnce = (key, val) => {
        return new Promise((resolve) => {
            this.setState({
                [key]:val
            }, ()=>{
                resolve(true)
            }) 
        })
    }

    //소요시간 변경_20210329_jane
    handleCallback = async () => {
        await this.setStateOnce('totalTime', '1')
    };

    handleStep1ValidateBtn = async () => {
        const {
            startLocationAddress,
            startLocationLat,
            startLocationLng,
            stopOverList,
            endLocationAddress,
            endLocationLat,
            endLocationLng,
            checkSwitch, // 왕복 flag
        } = store.getState().LocationSelector_ReduxModule;
        let retData = true;
        // 출발지 체크
        if (
            startLocationAddress.length <= 5 ||
            startLocationLat === 0 ||
            startLocationLng === 0
        ) {
            await calertPromise("출발지를 입력해주세요.");
            return;
        }
        // 도착지 체크
        if (
            endLocationAddress.length <= 5 ||
            endLocationLat === 0 ||
            endLocationLng === 0
        ) {
            await calertPromise(
                `${ReserveApi.getChangeStrJongryoji(
                    this.state.reserveType
                )}를 입력해주세요.`
            );
            return;
        }
        // 경유지 체크 (1개 이상 추가시 체크대상)
        if (stopOverList.length >= 1) {
            for (let i in stopOverList) {
                console.log(stopOverList[i]);
                if (
                    stopOverList[i].waypointAddress.length <= 5 ||
                    stopOverList[i].waypointLat.length === 0 ||
                    stopOverList[i].waypointLng.length === 0
                ) {
                    await calertPromise(
                        `${
                            Number(i) + 1
                        } 번째 경유지를 입력해주세요.`
                    );
                    return;
                }
            }
        }
        
        // 출발지 종료지가 같을때
        if (startLocationAddress === endLocationAddress) {
            // 왕복체크가 안되어있다면
            if (!checkSwitch) {
                await calertPromise('출발지와 종료지가 같다면 왕복을 활성화해주세요.');
                return;
            }
        }
        this.props.history.push(`/reserve_monthlyonce/${this.state.reserveType}/2`)
    }

    render() {
        const {
            startLocationAddress,
            endLocationAddress,
        } = store.getState().LocationSelector_ReduxModule;

        const isChkStep1ButtonVisible = ()=>{
            return (startLocationAddress.length && endLocationAddress.length)
        }

        return (
            <>
            {!this.state.loading ? (<Loading />) : ''}
                <Header
                    // title={`${ReserveApi.getDrivingTypeStr(this.state.reserveType)} 예약 (1/5)`}
                    title={`예약 (1/2)`}
                    onBackButton={this.handleGotoBack}
                    onHomeButton={this.handleGotoHome}
                />
                <div className="booking_box2 hd_margin_top">
                    

                    <LocationSelectorNewContainer
                        history={this.props.history}
                        type={this.state.reserveType}
                        endPointStr={ReserveApi.getChangeStrJongryoji(
                            this.state.reserveType
                        )}
                        onRefreshParent={this.handleCallback}
                    />
                    {isChkStep1ButtonVisible() ? (
                        <div className="component-wrap inner-container">
                            <div className="btn-area btn-group-fixed horizontal">
                                <button
                                    type="button"
                                    className="btn large secondary"
                                    // onClick={this.handleNextBtnValidate}
                                    onClick={this.handleStep1ValidateBtn}
                                >
                                    <span>다음</span>
                                </button>
                            </div>
                        </div>
                    ) : null}

                </div>
            </>
        );
    }
}

export default withLastLocation(withCookies(ReserveStep1View));
