import React, { Component } from 'react';

class ScrollTopBtn extends Component {

    state = {
        topBtnVisible: false,
    }

    componentDidMount() {
        // 스크롤 이벤트 적용
        window.addEventListener("scroll", this.onScrollTopBtn);
    }
    componentWillUnmount() {
        // component를 닫게 되면 스크롤 이벤트도 닫아줘야한다.
        window.removeEventListener("scroll", this.onScrollTopBtn);
    }
    
    toggleVisibility = () => {
        if (window.pageYOffset > 96) {
            this.setState({ topBtnVisible: true });
        } else {
            this.setState({ topBtnVisible: false });
        }
    };

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    // 스크롤 로딩
    onScrollTopBtn = (e) => {
        this.toggleVisibility();
    };

    render() {
        return (
        <div className={`${this.props.position === 'normal' ? "scroll-to-top" : "scroll-to-top channel"}`}>
            {this.state.topBtnVisible && (
                <div onClick={this.scrollToTop}>
                    <button type="button" className="btn-quick-top">
                        <span className="blind">맨위로</span>
                    </button>
                </div>
            )}
        </div>
        );
    }
};

export default ScrollTopBtn;
