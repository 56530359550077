import React, { Component } from 'react';
import {
    ADDRESS_SEARCH_STATE_RESERVE_START,
    ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_ADD,
    ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_UPDATE,
    ADDRESS_SEARCH_STATE_RESERVE_END,
} from '../../constantData'


import icon_delete from '../../img/booking/icon_delete.svg'
import icon_location from '../../img/booking/icon_location.svg'
import icon_point from '../../img/booking/icon_point.svg'         // 2
import icon_point_waypoint from '../../img/booking/icon_point_waypoint.svg'         // 2
import icon_plus from '../../img/booking/icon_plus.svg'         // 2
import {calertPromise, calertConfirm} from '../../lib/Util'
import AnimatedCheckbox from '../Common/AnimatedCheckbox';
import {createGlobalStyle} from 'styled-components'
const GlobalStyle = createGlobalStyle`
    .pulse { animation: pulse-animation 1s 2; }
    @keyframes pulse-animation {
        0% { box-shadow: 0 0 0 0px rgba(202, 0, 42, 0.6); }
        100% { box-shadow: 0 0 0 10px rgba(202, 0, 42, 0); }
    }

    .pulse2 { animation: view-popup 4s; animation-fill-mode: forwards;}
    @keyframes view-popup {
        0%{ 
            display:block;
            opacity:0; 
        }
        25% { 
            opacity:1;
        }
        75% { 
            opacity:1;
        }
        100% { 
            opacity:0;
            display:none;
        }
    }
    
`;



const INIT_WAYPOINT = {
    waypointAddress:'',
    waypointLat:'0',
    waypointLng:'0'
}
const LIMIT_STOPOVER_COUNT = 3;

//console.log(this.state.durMin);

class LocationSelectorNew extends Component {
    state = {
        addressStep1Flag: false,
        addressStep2Flag: false,
        addressStep3Flag: false,
    }

    componentDidMount() {
        // console.log('locationSelector')
        // console.log(this.props.history.location.pathname)
    }
    
    handleAddressStartPoint = (e) => {
        e.preventDefault();
        this.props.history.push(`/address_search?redirect=${this.props.history.location.pathname}&state=${ADDRESS_SEARCH_STATE_RESERVE_START}&menuid=0`)
    }
    handleAddressWayPoint = (e, idx) => {
        e.preventDefault();
        this.props.history.push(`/address_search?redirect=${this.props.history.location.pathname}&state=${ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_UPDATE}${idx}&menuid=0`)
    }
    handleAddressEndPoint = (e) => {
        e.preventDefault();
        this.props.history.push(`/address_search?redirect=${this.props.history.location.pathname}&state=${ADDRESS_SEARCH_STATE_RESERVE_END}&menuid=0`)
    }
    handleAddressLocation = () => {
        this.props.history.push(`/address_search?redirect=${this.props.history.location.pathname}&menuid=1`)
    }

    handleFavoriteManage = () => {
        this.props.history.push(`/location_favorite_manage`)
    }
    //

    handleMapStartPoint = () => {
        this.props.history.push(`/map_select?redirect=${this.props.history.location.pathname}&state=${ADDRESS_SEARCH_STATE_RESERVE_START}`)
    }
    handleMapWayPoint = (idx) => {
        this.props.history.push(`/map_select?redirect=${this.props.history.location.pathname}&state=${ADDRESS_SEARCH_STATE_RESERVE_WAYPOINT_UPDATE}${idx}`)
    }
    handleMapEndPoint = () => {
        this.props.history.push(`/map_select?redirect=${this.props.history.location.pathname}&state=${ADDRESS_SEARCH_STATE_RESERVE_END}`)
    }
 
    render() {
        let stopOverComponent = (arr) => {
            return arr.map((data, idx) => {
                return (
                    <>




                        <div className={(this.props.checkSwitch && !this.props.stopOverList[idx].waypointAddress.length) ? "form-controls flexible pulse" : "form-controls flexible"} style={{marginTop:'10px'}}>
                            <button 
                                type="button" 
                                className="ico btn-map-delete" 
                                id={idx} 
                                onClick={async () =>{
                                    await this.props.onClickDel(idx); 
                                    // this.props.callback(1); 
                                }}>
                                    <span>경유지 삭제</span>
                            </button>
                            <input type="text" placeholder={`경유지 ${idx+1} 주소` } className="input type3" value={this.props.stopOverList[idx].waypointAddress} readOnly={true} onClick={(e)=>{ this.handleAddressWayPoint(e, idx) }}/>
                            <button className={`btn-option-new-${this.props.type}`} onClick={(e)=>{ this.handleAddressWayPoint(e, idx) }}>주소검색</button>
                        </div>
                        
                    </>

                    // <div className="reserve-list" key={idx}>
                    //     <div className="grid cross-center flex-between">
                    //         <div className="col" style={{ width: '26px' }}>
                    //             <button type="button" className="ico btn-map-delete" id={idx} onClick={async () =>{
                    //             await this.props.onClickDel(idx); this.props.callback(1); }}><span>경유지삭제</span></button>
                    //         </div>
                    //         <div className="col col-8" onClick={()=>{ this.handleAddressWayPoint(idx) }}>
                    //             <i className="ico ico-vial1"><span>경유지</span></i>
                    //             {this.props.stopOverList[idx].waypointAddress ? this.props.stopOverList[idx].waypointAddress : '경유지를 입력하세요.'}
                    //         </div>
                    //         <div className="col right">
                    //             <button className="btn-option-new" onClick={()=>{ this.handleMapWayPoint(idx) }}>지도</button>
                    //         </div>
                    //     </div>
                    // </div>



                //     <span className="list_t1" key={idx}>
                // 	<table>
                //     	<tr>
                //         	<td className="list_sec1">
                //                 <img src={icon_delete} className="icon_LT1" alt="" id={idx} onClick={async () =>{
                //                 await this.props.onClickDel(idx);
                //                 this.props.callback(1);
                //             }}/>
                //             </td>
                //             <td className="list_sec2"><img src={icon_point_waypoint} className="icon_LT2" alt=""/></td>
                //             <td className="list_sec3" onClick={()=>{ this.handleAddressWayPoint(idx) }}>
                //                 {this.props.stopOverList[idx].waypointAddress ? this.props.stopOverList[idx].waypointAddress : '경유지를 입력하세요.'}
                //             </td>
                //             <td className="list_sec4">
                //                 <button className="btn_map" onClick={()=>{ this.handleMapWayPoint(idx) }}>지도</button>
                //             </td>
                //         </tr>
                //     </table>
                    
                // </span>



                    // <span className="list_t1" key={idx}>
                    //     <span className="list_sec1">
                    //         <img src={icon_delete} className="icon_LT1" alt="" id={idx} onClick={()=>{
                    //             this.props.onClickDel(idx);
                    //         }} />
                    //     </span>
                    //     <span className="list_sec2">
                    //         <img src={icon_point_waypoint} className="icon_LT2" alt="" />
                    //     </span>
                    //     <span className="list_sec3" onClick={()=>{
                    //         this.handleAddressWayPoint(idx)
                    //     }}>
                    //         {this.props.stopOverList[idx].waypointAddress ? this.props.stopOverList[idx].waypointAddress : '경유지를 입력하세요.'}
                            
                    //     </span>
                    //     <span className="list_sec4">
                    //         <button className="btn_map" onClick={()=>{ this.handleMapWayPoint(idx) }}>지도</button>
                    //     </span>
                    // </span>

                    // <CountryInfo key={idx} country={data.country} code={data.code} path={this.props.match.params.flag} detail={this.state.detail}/>
                )
            });
        }

        let callbackSwitchGeneral = () => {
            return (
                <div class="btn-checkbox" onClick={ ()=>{
                    // this.props.callback('1');
                }}>
                    <input type="checkbox" id="same1" checked={this.props.checkSwitch} onChange={this.props.onChangeAddressSwitch} />
                    {/* <label for="same1">출발지/{this.props.endPointStr} 동일</label> */}
                    <label for="same1">네</label>  {/* 20211021 애덤 지시사항으로 이곳만 "출발지/종료지 동일"로 하고 나머지는 종료지로 한다. */}
                </div> 

                // <button className="btn same" onChange={this.props.onChangeAddressSwitch}>
                //     <div className="check-box shape" onClick={()=>{
                //         this.props.callback('1')
                //     }}>
                //         <input type="checkbox" id="same1" name="same1"  checked={this.props.checkSwitch} />
                //         <label for="same1" className="">출발지/종료지 동일</label>
                //     </div>
                // </button>
            )
        }
        
        let callbackSwitchGolf = () => {
            return (

                <div class="btn-checkbox golf" onClick={ ()=>{
                    this.props.callback('1');
                }}>
                    <input type="checkbox" id="same2" checked={this.props.checkSwitch} onChange={this.props.onChangeAddressSwitch} />
                    {/* <label for="same2">출발지/{this.props.endPointStr} 동일</label> */}
                    <label for="same2">출발지/종료지 동일</label>   {/* 20211021 애덤 지시사항으로 이곳만 "출발지/종료지 동일"로 하고 나머지는 종료지로 한다. */}
                </div> 

                // <button className="btn golf same" onChange={this.props.onChangeAddressSwitch}>
                //     <div className="check-box shape" onClick={()=>{
                //         this.props.callback('1')
                //     }}>
                //         <input type="checkbox" id="same2" name="same2"  checked={this.props.checkSwitch} />
                //         <label for="same2" className="">출발지/종료지 동일</label>
                //     </div>
                // </button>
            )
        }

        let endMap = () => {
            return (
                <button className={`btn-option-new-${this.props.type}`} onClick={this.handleMapEndPoint}>지도</button>
            )
        }
        const changeRadioBtn = async (e) => {
            let result = e.target.checked === true ? true : false
            console.log(result)
            
            this.props.onChangeAddressSwitch(result)
            // 부모쪽의 화면을 갱신하기 위해 
            this.props.onRefreshParent();

            
        }

        return (
            <span className="section_box_deatil" style={{ margin: '-60px 0 0 0' }}>
                <GlobalStyle />
                 {/* 컴포넌트화할 녀석들 begin */}
                 {/* <div className="reserve-head grid">
                    <div className="col title">출발지 <span className="form-bar">|</span> {this.props.endPointStr}</div>
                    <div className="col right">
                        {this.props.type === 1 ? callbackSwitchGeneral() : callbackSwitchGolf()}
                    </div>
                </div> */}
                <div className="reserve-list reserve-list-minheight">
                    <div className="search-box">
                        <h1 className='h1'>출발지</h1>
                        <div className="form-controls flexible">
                            <input type="text" placeholder="출발지 주소" className="input type3" value={this.props.startLocationAddress} onClick={this.handleAddressStartPoint} readOnly={true}/>
                            <button className={`btn-option-new-${this.props.type}`} onClick={this.handleAddressStartPoint}>주소검색</button>
                        </div>
                        
                        
                    </div>   
                </div>

                {(this.props.startLocationAddress.length>=1) && (
                    <div className="reserve-list reserve-list-minheight">
                        <div className="search-box">
                            <div className="form-controls flexible"  style={{justifyContent:'space-between'}}>
                                <h1 className='h1' style={{display:'inline'}}>경유지</h1>
                                
                                <div style={{display:'flex', alignItems:'center'}}>
                                    {this.props.checkSwitch ? (<span className='pulse2'>왕복일 경우, 경유지 필수입니다.</span>):null}
                                </div>
                            </div>

                            {stopOverComponent(this.props.stopOverList)}
                            <div className="form-controls flexible">
                                <button type="button" className={`btn-option-new-${this.props.type}`} style={{width:'100%', height:'40px', 'marginTop':'20px', fontSize:'1.2em'}} onClick={async ()=>{
                                    if (this.props.stopOverList.length >= LIMIT_STOPOVER_COUNT) {
                                        await calertPromise('최대 3개까지 추가하실 수 있습니다.')
                                    } else {
                                        this.props.onClickAdd(INIT_WAYPOINT);
                                    } 
                                    }}>
                                        <span>+ 경유지 추가</span>
                                </button>
                            </div>

                            
                        </div>   
                    </div>
                )}
                
                

                {(this.props.startLocationAddress.length>=1) && (
                    <div className="reserve-list reserve-list-minheight">
                        
                        <div className="search-box">
                            <div className="form-controls flexible"  style={{justifyContent:'space-between'}}>
                                <h1 className='h1' style={{display:'inline'}}>종료지</h1>
                                
                                <div style={{display:'flex', alignItems:'center'}}>
                                    <h1 className='h2' style={{display:'inline'}}>왕복</h1>&nbsp;    
                                    <AnimatedCheckbox
                                        reserveType={this.props.type}
                                        
                                        checkSwitch={this.props.checkSwitch}
                                        onChangeCheckdata={changeRadioBtn} />

                                    {/* <div className="radio-box2">
                                        <input type="radio" name="option2" id="option20" value='true' checked={this.props.checkSwitch === true} onChange={changeRadioBtn} />
                                        <input type="radio" name="option2" id="option21" value='false' checked={this.props.checkSwitch === false} onChange={changeRadioBtn}/>
                                    </div> */}
                                </div>
                            </div>
                            
                            
                            
                            

                            {/* <div className="form-controls flexible" style={{paddingTop:'10px', paddingBottom:'5px', fontSize:'1.15em'}}> */}
                                {/* <span>출발지와 동일한가요?</span>
                                {callbackSwitchGeneral()} */}
                            {/* </div> */}
                            
                            {!this.props.checkSwitch ? (
                                <>
                                    <div className="form-controls flexible">
                                        <input type="text" placeholder="종료지 주소" className="input type3" value={this.props.endLocationAddress} readOnly={true} onClick={this.handleAddressEndPoint}/>
                                        <button className={`btn-option-new-${this.props.type}`} onClick={this.handleAddressEndPoint}>주소검색</button>
                                    </div>
                                    
                                </>
                            ) : (
                                <>
                                    <div className="form-controls flexible">
                                        <input type="text" placeholder="종료지 주소" className="input type3" value={this.props.endLocationAddress} readOnly={true} />
                                        {/* <button className={`btn-option-new-${this.props.type}`} onClick={this.handleAddressEndPoint}>주소검색</button> */}
                                    </div>
                                    
                                </>
                            )}                        
                        </div>
                    </div>
                )}
                
                {/* 

                <div className="reserve-foot">
                    <div className="grid cross-center flex-between">
                        <div className="col left">
                            <button type="button" onClick={this.handleAddressLocation}><i className="ico ico-map-history"></i><span className="text-white">최근경로</span></button>
                        </div>
                        
                        <div className="col right">
                            <button onClick={this.handleFavoriteManage}>
                                <i className="ico ico-map-bookmark"></i><span className="text-white">즐겨찾기관리</span>
                            </button>
                        </div>
                    </div>
                </div> */}


            </span>

        );
    }
};

export default LocationSelectorNew;
