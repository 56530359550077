// 라이브러리 Load
import React, { Component } from 'react';
import { withCookies } from 'react-cookie'
import { NavLink } from 'react-router-dom'
import moment from 'moment'

// 리덕스 store
import store from '../../../reduxStore'

// Custom Component
import Header from '../../../components/Common/Header'
import Loading from '../../../components/Common/Loading'


import {
    plusSecondsMomentWithFormatCustom
} from '../../../lib/TimeApi'

// Mosiler API
import CouponApi from '../../../lib/CouponApi'
import ReserveApi from '../../../lib/ReserveApi'
import RnInterface from '../../../lib/RnInterface'
import TicketApi from '../../../lib/TicketApi'

import Modal from 'react-modal'

// Etc API
import Util, { 
    calert, 
    calertConfirm, 
    calertPromise, 
    calertPromise3,
    // calertPromiseCustomMsg ,
    isChkResultSuccess,
    setLocalStorage,
    getLocalStorage
} from '../../../lib/Util'

// import { 
//     IsPayment, 
//     IsPaymentPartner, 
//     TargetType_Reservation, 
//     Payment_Insert,
//     PAYMENT_CODE_SUCCESS,
//     PAYMENTPARTNER_CODE_SUCCESS,
// } from '../../../constantData'

// image, Css
import PaymentCss from '../../../css/CustomGlobalStyle/Payment'

import wiDdaySsunny from '../../../img/weather/wi-day-sunny.svg'
import wiCloudy from '../../../img/weather/wi-cloudy.svg'
import wiRain from '../../../img/weather/wi-rain.svg'
import wiRainMix from '../../../img/weather/wi-rain.svg'
import wiSnow from '../../../img/weather/wi-snow.svg'


import icon_point from "../../../img/record/icon_point.svg"
import icon_point_waypoint from "../../../img/record/icon_point_waypoint.svg"
// import icon_arrow from "../../../img/record/icon_arrow.svg"
import icon_location from "../../../img/record/icon_location.svg"

// import icon_arrow from "../../../img/record/icon_arrow.svg"
// import { MAX_WAYPOINT } from '../../../constantData'


// var tlmCouponUseAfterReuseYn = true;

// const RESERVE_GENERAL = '1'
const RESERVE_GOLF = '3'
const RESERVE_AIRPORT_HOMEVALET = '26'
const RESERVE_AIRPORT_HOMEVALET_INTEGER = 26

const DISCOUNT_TYPE_COUPON = '0'
const DISCOUNT_TYPE_TICKET = '1'
const DISCOUNT_TYPE_NONE = '2'     // 이경우는 할인 없음 (쿠폰에서 사용안함을 선택했을 때.)

/*
2021/1/6 확인사항++++++++++
1. 쿠폰 등록해서 확인해봐야함
2. 최종 예약확인 화면에서 필요한 state값을 reserve_all_list에 넣어야 한다.
3. 등록되어있는 카드 체크 (미등록일 경우는 다음으로 넘어가지 못하도록하고, 카드 미등록시 카드 추가 버튼 신설. )

* 관련코드
if (Object.keys(defaultCardInfo).length === 0) {
    await calertPromise('카드가 등록되어 있지 않습니다. 등록하고 다시 예약해주세요.')

    // 화면 이동을 위해 잠시 설정했던 데이터는 백업해둔다.
    localStorage.setItem('ReserveConfirmViewBackup', JSON.stringify(this.state));

    // 카드 등록 화면으로 이동.
    this.props.history.replace(`/card_regist?redirect=reserve_confirm&customerno=${this.state.CustomerNo}`)
}

*/


class ReserveConfirmStep1View extends Component {
    state = {
        date: new Date(),
        RevStartDt: new Date(),
        RevEndDt: new Date(),
        carLocation: '',
        carNo:'',
        carModel:'',
        CustomerComment: '',
        CustomerNo: 0,
        Passenger: '',
        PassengerPhoneNo: '',
        passengerCheckStatus: true,
        DrivingType: Number(this.props.match.params.state),
        hourView: '',
        typeView: '일반 ',
        moneyView: 0,
        CustomerName: '',
        CustomerPhoneNo: '',
        loading: 1,
        
        DiscountAmount: '0',            // 할인 적용된 금액
        DiscountRate: '0',              // 할인율
        DrivingCharge: '0',              // 기본 요금
        ReserveCharge: '10',            // 최종 결제금액
        calcPricing:{},
        finalEndTime:'',
        covidKitStatus: false,

        couponAvailableList: [],         // 사용가능한 쿠폰 리스트


        selectCouponRedeemCode: '0',      // 드랍다운으로 선택한 쿠폰의 리딤코드  *필요*
        selCouponId: null,                 // 선택한 쿠폰의 ID              *필요*
        
        ticketData:{                        // *필요*
            Item:{
                RemainAmount: 0
            }
        },

        discountType: DISCOUNT_TYPE_NONE,       // *필요*
        mallReserved: '',           // 파트너 카드의 정보(?)    *필요*
        CouponId: '',               // 쿠폰 ID              *필요*
        defaultCardInfo:{},             // *필요*
        partnerHiddenCoupon:null,       // 제휴카드에 따라 calc api 호출시 쿠폰 id가 넘어오는 경우도 있다.   *필요*




        freeTicketFlag : true,          // 사용 가능한 무료이용권이 없을때 쿠폰 선택 유무 : true - 무료이용권이 있다. false - 무료이용권이 없다.    *필요*
        useCouponAvaliable: false,       // 무료이용권 이용 가능 유무 true : 이용 가능, false : 이용 불가       *필요*
        psCouponId:'',                   // useCoupon API호출후 coupon_num값. 경유지 등록실패시, 예약 취소와 동시에 쿠폰 취소도 필요하다.   *필요*


        // 쿠폰 관련
        // partnerUseYn: false,        // 파트너 카드 존재 유무
        // paymentCardId: '',          // default card의 id
        // sales10CouponId: '',        // 기본 10% 할인 쿠폰 id
        // myDefaultCardYn: false,     // 개인 default card 존재 유무
        // calcItem:{},                // 계산 결과 전체 값
        // tlmCouponCode:'',
        // TransactionId:'',
        

        modalOpenFlag: false,
        

        startTimeString:'',
        endTimeString:'',
        weatherResult:[],
        hiddenFlag:false,
    }

    async componentDidMount() {
        
        try {
            const { IsOldFare } = store.getState().global_data.loginedData
            this.setState({loading:0})
            await RnInterface.AndroidURLChange(this.props.location.pathname)
            let tmpTicketData = await this.getTicketRemain();   // 1. 충전권 데이터 취득
            await this.initState(tmpTicketData)                              // 2. 예약화면에서 작성했던 예약 내역 (localStorage)
            await this.getCustomerDefaultCard();                // 3. Default Card 정보
            await this.getCouponData();                         // 4. Coupon 정보 (사용 가능한 쿠폰만 표시)         
            


            // await this.getCalcDrivingReservationConfirm();      // 5. CalcDrivingReservation 이후의 값
            

            if (this.props.location.state !== undefined &&
                this.props.location.state.result_cardregist === 'success') {
                await this.initStateBackup();       // localstorage에 임시저장한 정보를 빼온다.
                await this.getCustomerDefaultCard();                // 2. Default Card 정보
            }

            // alert('현재 DrivingType: ' + this.state.DrivingType);
            this.setState({
                loading:1,
                ticketData: tmpTicketData,
                
            })
            
            let messageFlag = sessionStorage.getItem('chargeticketmessageflag') !== null ? true : false
                
            // if (IsOldFare && !messageFlag) {
            //     await calertPromise3('앱에서 예약 시 충전권 사용을 원할 경우, 금액제 충전권만 사용 가능합니다. 시간제 충전권 사용을 원하시면 카카오톡채널(화면 우측 하단 노란색 아이콘)으로 문의해주시기 바랍니다.');
            //     sessionStorage.setItem('chargeticketmessageflag','helloworld');
            // }
           
        }
        catch(e) {
            this.setState({loading:1})
            console.log(e)
        }
        
        /* 
            초기화에 필요한 값
            1. 예약화면에서 작성했던 예약 내역 (localStorage)
            2. Default Card 정보
            3. Coupon 정보
            4. CalcDrivingReservation 이후의 값
        */
    }


    handleChangeModalStatusTrue = () => {
        this.setState((prevState) => ({ modalOpenFlag : true }));
    }

    handleChangeModalStatusFalse = () => {
        this.setState((prevState) => ({ modalOpenFlag : false }));
    }

    // 충전권 정보를 취득한다.
    getTicketRemain = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const { cookies } = this.props;
                const { CustomerNo } = store.getState().global_data.loginedData
                let tmpRemainTicketListResult = await TicketApi.GetCustomerRemainTicket(
                    CustomerNo,
                    cookies.get("ostype"),
                    cookies.get("deviceid"),
                    cookies.get("login_encrypt")
                );
    
                // 이용 가능 금액 취득 성공시
                if (isChkResultSuccess(tmpRemainTicketListResult)) {
                    resolve(tmpRemainTicketListResult)
                }
                else {
                    resolve ({})
                }
            }
            catch(e){
                return reject(e);
            }
            
        })
    }
    

    // 현재 등록되어있는 Default 카드 정보를 취득해서 그 카드가 일반카드인지 파트너카드인지에 따라서
    // 쿠폰 확인 후 쿠폰이 존재한다면 적용하는 처리를 담당한다.
    // 화면 load시 한번만 호출된다.
    getCustomerDefaultCard = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const { cookies } = this.props;
                let { CustomerNo } = store.getState().global_data.loginedData
                let defaultPaymentCardData = await ReserveApi.GetBasicPaymentCards(
                    CustomerNo,
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                )
                // 기본카드가 있을 경우
                if (defaultPaymentCardData.Item && defaultPaymentCardData.Result === 'success') {
                    this.setState({defaultCardInfo: defaultPaymentCardData.Item},()=>{
                        resolve(true)
                    })
                }
                // 기본카드가 없을 경우
                else {
                    resolve(false)
                }
            }
            catch (e) {
                return reject(e);
            }
        })
    }

    // 예약 화면에서 작성했던 예약 내역 정보를 취득.
    initState = (ticket) => {
        let tmpInitReserveBackupData = [
            localStorage.getItem('reserve_view_all_state'),                      // 0
        ]

        let { loginedData } = store.getState().global_data      // 로그인 데이터 취득

        let backupAllState = tmpInitReserveBackupData[0]  !== null ? JSON.parse(tmpInitReserveBackupData[0]) : null;
        let isBackupAllStateResult = (backupAllState !== null);

        return new Promise((resolve) => {
            // 값 초기화
            this.setState({
                CustomerNo: loginedData.CustomerNo,
                CustomerName: loginedData.CustomerName,
                CustomerPhoneNo: loginedData.PhoneNumber,
                
                discountType: ticket !== undefined ? ((ticket.Item.RemainAmount >= 1) ? DISCOUNT_TYPE_TICKET : DISCOUNT_TYPE_NONE) : DISCOUNT_TYPE_NONE,             // 충전권이 1원 이상 있다면 충전권 기본 선택
                
                startTimeString : backupAllState.startTimeString,
                endTimeString : backupAllState.endTimeString,

            }, ()=>{
                resolve(true)
            })
        })
    }

    // 화면 전환시 백업한 예약 데이터들을 받아온다.
    initStateBackup = () => {
        return new Promise((resolve) => {
            let restoreData = localStorage.getItem('ReserveConfirmViewBackup');
            if (restoreData !== null) {
                console.log('initStateBackup not null')
                // 값 초기화
                this.setState({
                    ...JSON.parse(restoreData)
                }, () => {
                    resolve(true)
                })
            } else {
                console.log('initStateBackup null')
                resolve(true)
            }
        })
    }
    

    getCouponData = () => {
        return new Promise(async (resolve, reject) => {
            const { cookies } = this.props;
            const { CustomerNo } = store.getState().global_data.loginedData;
            

            try {

                let resultData = await CouponApi.GetMyCoupons(
                    CustomerNo,
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt'),
                    this.state.DrivingType
                )               

                // 쿠폰 취득 성공시
                if (resultData.ItemList && resultData.Result === 'success') {

                    let tmpAvaliableList = [];
                    /*
                    쿠폰관련 기능반영요청입니다.
                    GetMyCoupons 로 쿠폰리스트 가져올때 DrivingType을 추가해놓았습니다. 
                    DrivingType 값과 해당서비스 DrivingType 값이 같을때만 예약화면 보유쿠폰리스트에 노출되게 해주세요. 
                    리턴받는 DrivingType 값이 null 이거나 '0' 이면 모든서비스(운행)에 노출되는 로직입니다. 

                        * GetMyCoupons 취득값중 DrivingType이 null or 0일때
                        쿠폰 사용 가능한 예약
                            - 일반 예약
                            - 골프 예약
                            - 공항홈발렛
                            - 마스터카드 예약
                            - 하나카드 예약
                        
                        * GetMyCoupons 취득값중 DrivingType이 1 or 3 or 25일때
                        쿠폰 사용 가능한 예약
                        DrivingType이 1일때 : 일반 예약, 마스터카드 예약, 하나카드 예약
                        DrivingType이 3일때 : 골프 예약
                        DrivingType이 25일때 : 공항홈발렛
                    */

                    tmpAvaliableList = resultData.ItemList.filter((data) => {
                        //GetMyCoupons취득값중 DrivingType값이 null이거나 0일때
                        if (Number(data.DrivingType) === null || Number(data.DrivingType) === 0) {
                            return (data.IsUsedYn === 'N') && (data.IsExpiredYn === 'N')
                        }
                        // 상기 외라면 DrivingType값과 사용자가 접근한 예약 서비스 DrivingType이 일치하는 쿠폰에 한해서 리스트에 추가한다.
                        return (data.IsUsedYn === 'N') && (data.IsExpiredYn === 'N') && (Number(data.DrivingType) === Number(this.state.DrivingType))
                    })
                    
                    // 정리된 쿠폰 리스트가 1개 이상이라면
                    if (tmpAvaliableList.length) {
                        // 쿠폰리스트를 넣고,
                        // 쿠폰리스트의 0번째의 리딤코드, 쿠폰ID를 자동세팅.
                        // 이유 : 이전 쿠폰 셀렉트 박스에 "사용 안함"이 있었는데 
                        // 그것을 없애기 위해 이곳에서 별도 처리실시.
                        this.setState({
                            couponAvailableList: tmpAvaliableList,
                            selectCouponRedeemCode: tmpAvaliableList[0].RedeemCode,
                            selCouponId : tmpAvaliableList[0].Id
                        }, () => { resolve(true) })
                    }
                    else {
                        this.setState({ couponAvailableList: tmpAvaliableList, }, () => { resolve(true) })
                    }


                } else {
                    this.setState({
                        couponAvailableList: [],                       
                    }, () => {
                        resolve(false)
                    })
                }
            } catch (e) {
                await calertPromise('쿠폰 취득 도중 오류가 발생했습니다. 고객센터에 문의해주세요.')
                console.log(e);
                return reject(e);
            }
        })
    }

    // 현재 보유중인 카드가 제휴 인증된 카드인지를 판별하는 처리
    isChkPartnerCard = () => {
        const {defaultCardInfo} = this.state;
        let retData = false;
        
        // 카드가 존재하고 MallReserved값이 있다면
        if (Object.keys(defaultCardInfo).length && defaultCardInfo.MallReserved !== '') {
            retData = true;
        }
        return retData;
    }

    getCalcDrivingReservationConfirm = () => {
        console.log('getCalcDrivingReservationConfirm');
        return new Promise(async (resolve, reject) => {
            try {
                // "CustomerNo" : 12534,
                // "PaymentType": "2",
                // "DrivingType": 1,
                // "RevStartDt": "2020-12-16 00:00:00.000",
                // "RevEndDt" : "2020-12-16 02:30:00.000"
                const {
                    startLocationAddress,
                    startLocationLat,
                    startLocationLng,
                    endLocationAddress,
                    endLocationLat,
                    endLocationLng,
                    stopOverList
                } = store.getState().LocationSelector_ReduxModule;

                const { cookies } = this.props;
                const {
                    discountType,
                    calcPricing,
                    startTimeString,
                    endTimeString,
                } = this.state;

                let tmpDate = this.state.date
                let tmpStartDate = new Date(this.state.RevStartDt)
                let tmpEndDate = new Date(this.state.RevEndDt)

                let tmpStartDateFormat = '';
                let tmpEndDateFormat = '';

                let testStart = Number(moment(tmpStartDate).format('YYYYMMDD'))                 // start date
                let testEnd = Number(moment(tmpEndDate).format('YYYYMMDD'));                    //end date

                // start date를 먼저 세팅해주고
                tmpStartDateFormat = plusSecondsMomentWithFormatCustom(
                    tmpDate.getDate(),
                    (tmpDate.getMonth() + 1),
                    tmpDate.getFullYear(),
                    tmpStartDate.getHours(),
                    tmpStartDate.getMinutes(),
                )

                // 아래 코드를 넣는 이유 : 23:30 ~ 0:30 시간대일경우에는 0:30는 다음날로 처리해야하는데 당일로 처리하고 있어서
                // 계산 결과가 23시간 이렇게 나온다. 이 문제를 방지하고자 처리하는 것이다.
                // end가 start의 날짜를 넘어간다면 
                if (testStart < testEnd) {
                    let tmpAfterDay = moment(this.state.date).add(1,'days').toDate();

                    tmpEndDateFormat = plusSecondsMomentWithFormatCustom(
                        tmpAfterDay.getDate(),
                        (tmpAfterDay.getMonth() + 1),
                        tmpAfterDay.getFullYear(),
                        tmpEndDate.getHours(),
                        tmpEndDate.getMinutes(),
                    )
                }

                else {
                    tmpEndDateFormat = plusSecondsMomentWithFormatCustom(
                        tmpDate.getDate(),
                        (tmpDate.getMonth() + 1),
                        tmpDate.getFullYear(),
                        tmpEndDate.getHours(),
                        tmpEndDate.getMinutes(),
                    )
                }

                


                let tmpObj = {
                    CustomerNo: this.state.CustomerNo,
                    PaymentType: '2',
                    DrivingType: this.state.DrivingType,
                    RevStartDt: startTimeString,
                    // RevEndDt: tmpEndDateFormat,
                    RevEndDt: endTimeString,

                    // 결제전 쿠폰을 사용할경우에는 내가 선택한 selCouponID값이 들어가도록 설정한다.
                    // 쿠폰을 사용하지 않을 경우에는 null값으로 넘긴다.
                    CouponId: (discountType === DISCOUNT_TYPE_COUPON) ?  this.state.selCouponId : null,       // 내가 선택한 쿠폰값이 적용(integer)
                    PrePaidTicketYN: (discountType === DISCOUNT_TYPE_TICKET) ?  'Y' : 'N',
                    // PrePaidTicketAmount: (discountType === DISCOUNT_TYPE_TICKET) ?  (calcPricing.DrivingCharge + calcPricing.StartAreaFee + calcPricing.AreaFee) : 0,
                    
                    StartAddress: startLocationAddress,
                    StartLat: startLocationLat,
                    StartLng: startLocationLng,

                    EndAddress: endLocationAddress,
                    EndLat: endLocationLat,
                    EndLng: endLocationLng,

                    ...ReserveApi.getWaypointGenerate2(stopOverList)
                }

                // alert('이 alert은 예약확인 화면이 열렸을때 getCalcDrivingReservation API를 호출 하도록 되어있음.')
                // alert(`현재 등록된 카드 있어? ${Object.keys(this.state.defaultCardInfo).length ? '있음' : '없음'}`)
                // alert(`제휴인증된 카드야? ${this.isChkPartnerCard()}`)
                // alert('getCalcDrivingReservationConfirm 계산 전 폼 데이터')
                // alert(JSON.stringify(tmpObj))
                

                this.setState({ loading: 0 })	// 로딩중
                let result = await ReserveApi.CalcDrivingReservation(
                    tmpObj,
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                )

                
                if (result.Result === 'success') {
                    // alert('CalcDrivingReservation 응답결과 success')
                    // alert(JSON.stringify(result.Item))
                    // alert(`CalcDrivingReservation 응답결과 중 쿠폰 ID : ${result.Item.CouponId}`)
                    // localStorage.setItem('reserve_temp_ReseveCharge', result.Item.ReseveCharge)  

                    this.setState({
                        DiscountAmount: result.Item.DiscountAmount, 
                        DiscountRate: result.Item.DiscountRate,
                        DrivingCharge: result.Item.DrivingCharge,       // 운행요금
                        ReserveCharge: result.Item.ReseveCharge,
                        calcPricing: result.Item,
                        partnerHiddenCoupon: (result.Item.CouponId === 1599) ? result.Item.CouponId : null,              // /v2/calcDrivingReservation 호출 시 =>  couponId : 1599 이면 히든값으로 보관. 1599가 아니면 null값으로.
                        
                        startAreaFee: result.Item.StartAreaFee,         // 출발지 할증
                        areaFee: result.Item.AreaFee,                   // 지역할증
                        
                    })
                    
                    resolve(true)
                } else {
                    await calertPromise('금액을 계산 하지 못했습니다.')
                    resolve(false)
                }

            } catch (e) {
                await calertPromise('금액 계산 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
                return reject(e)

            }
        })
    }


    // 공항 홈발렛 전용 메소드. 
    // 무료이용권이 없을 때 나오는 alert에서 '아니오'를 눌렀을때 호출된다.
    getCalcDrivingReservationConfirmCancelFreeTicket = () => {
        return new Promise(async (resolve, reject) => {
            try {
                // "CustomerNo" : 12534,
                // "PaymentType": "2",
                // "DrivingType": 1,
                // "RevStartDt": "2020-12-16 00:00:00.000",
                // "RevEndDt" : "2020-12-16 02:30:00.000"
                const {
                    startLocationAddress,
                    startLocationLat,
                    startLocationLng,
                    endLocationAddress,
                    endLocationLat,
                    endLocationLng
                } = store.getState().LocationSelector_ReduxModule;

                const {
                    startTimeString,
                    endTimeString
                } = this.state;
                
                const { cookies } = this.props;
                let tmpDate = this.state.date
                let tmpStartDate = new Date(this.state.RevStartDt)
                let tmpEndDate = new Date(this.state.RevEndDt)

                let tmpStartDateFormat = '';
                let tmpEndDateFormat = '';

                let testStart = Number(moment(tmpStartDate).format('YYYYMMDD'))                 // start date
                let testEnd = Number(moment(tmpEndDate).format('YYYYMMDD')); //end date

                // start date를 먼저 세팅해주고
                tmpStartDateFormat = plusSecondsMomentWithFormatCustom(
                    tmpDate.getDate(),
                    (tmpDate.getMonth() + 1),
                    tmpDate.getFullYear(),
                    tmpStartDate.getHours(),
                    tmpStartDate.getMinutes(),
                )

                // 아래 코드를 넣는 이유 : 23:30 ~ 0:30 시간대일경우에는 0:30는 다음날로 처리해야하는데 당일로 처리하고 있어서
                // 계산 결과가 23시간 이렇게 나온다. 이 문제를 방지하고자 처리하는 것이다.
                // end가 start의 날짜를 넘어간다면 
                if (testStart < testEnd) {
                    let tmpAfterDay = moment(this.state.date).add(1,'days').toDate();

                    tmpEndDateFormat = plusSecondsMomentWithFormatCustom(
                        tmpAfterDay.getDate(),
                        (tmpAfterDay.getMonth() + 1),
                        tmpAfterDay.getFullYear(),
                        tmpEndDate.getHours(),
                        tmpEndDate.getMinutes(),
                    )
                }

                else {
                    tmpEndDateFormat = plusSecondsMomentWithFormatCustom(
                        tmpDate.getDate(),
                        (tmpDate.getMonth() + 1),
                        tmpDate.getFullYear(),
                        tmpEndDate.getHours(),
                        tmpEndDate.getMinutes(),
                    )
                }

/* 제휴카드 인증 받지 않은 상태여도
    요청값에 아래처럼 하면 될까요?
    CouponId: 1599,       // 내가 선택한 쿠폰값이 적용(integer)
    DiscountAmount: null,
    DiscountRate: null,

*/
                let tmpObj = {
                    CustomerNo: this.state.CustomerNo,
                    PaymentType: '2',
                    DrivingType: this.state.DrivingType,
                    RevStartDt: startTimeString,
                    RevEndDt: endTimeString,
                    CouponId: 1599,       // 내가 선택한 쿠폰값이 적용(integer)
                    DiscountAmount: null,
                    DiscountRate: null,

                    StartAddress: startLocationAddress,
                    StartLat: startLocationLat,
                    StartLng: startLocationLng,

                    EndAddress: endLocationAddress,
                    EndLat: endLocationLat,
                    EndLng: endLocationLng,
                }

                this.setState({ loading: 0 })	// 로딩중
                let result = await ReserveApi.CalcDrivingReservation(
                    tmpObj,
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                )

                
                if (result.Result === 'success') {
                    this.setState({
                        DiscountAmount: result.Item.DiscountAmount, 
                        DiscountRate: result.Item.DiscountRate,
                        DrivingCharge: result.Item.DrivingCharge,
                        ReserveCharge: result.Item.ReseveCharge,
                        calcPricing: result.Item,
                        partnerHiddenCoupon: (result.Item.CouponId === 1599) ? result.Item.CouponId : null,              // /v2/calcDrivingReservation 호출 시 =>  couponId : 1599 이면 히든값으로 보관. 1599가 아니면 null값으로.
                    })
                    
                    resolve(true)
                } else {
                    await calertPromise('금액을 계산 하지 못했습니다.')
                    resolve(false)
                }

            } catch (e) {
                await calertPromise('금액 계산 도중 오류가 발생했습니다. 관리자에게 문의해주세요.')
                return reject(e)

            }
        })

    }


    // 쿠폰 변경시 호출
    handleChangeSelectCoupon = async (e) => {

        //1. 공항홈발렛 예약결제에만 적용됨 ===> 일반, 골프 등 타서비스 예약결제시 적용 안함
        // 여기선 해당되는가?
        // => 해당된다. DrivingType에 따라 처리가 달라야한다.
        // 제휴카드의 경우는 partnerHiddenCoupon값이 항상 1599로 오고있다고 한다.
        // 공항 홈발렛의 경우에만 partnerHiddenCoupon값 체크를 하고,
        // 그 외에는 partnerHiddenCoupon를 언급조차하면 안됨.
        const { 
            couponAvailableList, 
            // selectCouponRedeemCode, 
            // selCouponId, 
            partnerHiddenCoupon, 
            DrivingType, 
            freeTicketFlag 
        } = this.state;

        if (DrivingType === RESERVE_AIRPORT_HOMEVALET) {

            // 예약 확인 버튼 클릭시 useCoupon.vm 호출 > 이용권이 없다고 나올경우 팝업이 뜬다. (사용가능한 무료이용권없음. 계속 결제 진행?)
            // 이때 사용자가 취소버튼을 클릭할때 freeTicketFlag값을 false로 변경하여 쿠폰을 선택할 수 있어야함.

            // 현재 제휴 쿠폰이 있을 경우에는 다른 쿠폰을 선택할 수 없도록 막아야한다.
            if (partnerHiddenCoupon !== null && partnerHiddenCoupon === 1599 && freeTicketFlag === true) {
                this.setState({
                    selectCouponRedeemCode: '0',
                    selCouponId: null                   // integer or null
                })
                await calertPromise('현재 무료이용권이 있습니다.\n중복할인할 수 없습니다.')
            }
            else {
                if (e.target.value === "0") {   // 드랍다운에서 "사용 안함" 선택시.
                    this.setState({
                        selectCouponRedeemCode: e.target.value,
                        selCouponId: null                   // integer or null
                    }, async () => {
                        // this.setState({ loading: 0 })	// 로딩완료
                        // await this.getCalcDrivingReservationConfirm();
                        // this.setState({ loading: 1 })	// 로딩완료
                    })
                } else {
                    let tmpData = couponAvailableList.filter((data) => (data.RedeemCode === e.target.value));       // 내가 선택한 쿠폰의 쿠폰 ID를 취득하기위한 필터처리
                    this.setState({
                        selectCouponRedeemCode: e.target.value,
                        selCouponId: Number(tmpData[0].Id)      // 내가 선택한 쿠폰의 쿠폰 ID값 (integer or null)
                    }, async () => {
                        // this.setState({ loading: 0 })	// 로딩완료
                        // await this.getCalcDrivingReservationConfirm();
                        // this.setState({ loading: 1 })	// 로딩완료
                    })
                }

            }
        }
        // 공항홈발렛 이외의 경우
        else {

            if (e.target.value === "0") {   // 드랍다운에서 "사용 안함" 선택시.
                this.setState({
                    selectCouponRedeemCode: e.target.value,
                    selCouponId: null                   // integer or null
                }, async () => {
                    // this.setState({ loading: 0 })	// 로딩완료
                    // await this.getCalcDrivingReservationConfirm();
                    // this.setState({ loading: 1 })	// 로딩완료
                })
            } else {
                let tmpData = couponAvailableList.filter((data) => (data.RedeemCode === e.target.value));       // 내가 선택한 쿠폰의 쿠폰 ID를 취득하기위한 필터처리
                this.setState({
                    selectCouponRedeemCode: e.target.value,
                    selCouponId: Number(tmpData[0].Id)      // 내가 선택한 쿠폰의 쿠폰 ID값 (integer or null)
                }, async () => {
                    // this.setState({ loading: 0 })	// 로딩완료
                    // await this.getCalcDrivingReservationConfirm();
                    // this.setState({ loading: 1 })	// 로딩완료
                })
            }
        }
    }

    handleGotoBack = () => {this.props.history.goBack();}
    handleGotoHome = () => {this.props.history.replace('/main'); this.props.history.push('/main');}
    stateChangeFreeTicketCancel = () => {
        return new Promise((resolve) => {
            this.setState({
                freeTicketFlag: false,
                selCouponId:null,
            }, ()=>{
                resolve(true)
            })
        })
    }


    handleDiscountTypeChange = async (type) => { 
        // 쿠폰을 선택했고, 예약할인을 받았는지 여부를 체크
        if (
            type===DISCOUNT_TYPE_COUPON && 
            ReserveApi.isChkReserveDiscount(this.state.DrivingType, this.state.calcPricing.ReserveDiscountAmount)) {
            await calertPromise('예약할인 적용 시 쿠폰은\n사용할 수 없습니다.')
        }
        else {
                this.setState({ discountType: type },async ()=>{
                    // this.setState({ loading: 0 })	// 로딩완료
                    // await this.getCalcDrivingReservationConfirm();
                    // this.setState({ loading: 1 })	// 로딩완료
                }) 
        }
    }

    handleDiscountTypeChangeCoupon = () => { 
        this.setState({ discountType: DISCOUNT_TYPE_COUPON },async ()=>{
            // this.setState({ loading: 0 })	// 로딩완료
            // await this.getCalcDrivingReservationConfirm();
            // this.setState({ loading: 1 })	// 로딩완료
        }) 
    }
    
    handleDiscountTypeChangeTicket = () => { 
        this.setState({ discountType: DISCOUNT_TYPE_TICKET },async ()=>{
            // this.setState({ loading: 0 })	// 로딩완료
            // await this.getCalcDrivingReservationConfirm();
            // this.setState({ loading: 1 })	// 로딩완료
        }) 
    }
    
    handleChargeTicket = () => {
        // 화면 이동을 위해 잠시 설정했던 데이터는 백업해둔다.
        localStorage.setItem('ReserveConfirmViewBackup', JSON.stringify(this.state));

        // 카드 등록 화면으로 이동.
        this.props.history.push({
            pathname:'/ticket_buying',
            state: {
                previousView:'reserve_confirm'
            },
        })
        

    }

    saveLocationLocalStorage = () => {
        const {
            startLocationAddress, startLocationLat, startLocationLng,
            endLocationAddress, endLocationLat, endLocationLng,
            stopOverList, checkSwitch
        } = store.getState().LocationSelector_ReduxModule;

        let tmpLocationList = localStorage.getItem('history_location_list');
        let tmpParseData = '';
        let resultData = '';
        let newData = {
            date: moment().format(),
            endLat: endLocationLat,
            endLng: endLocationLng,
            endLocation: endLocationAddress,
            startLat: startLocationLat,
            startLng: startLocationLng,
            startLocation: startLocationAddress,
            stopOverList: stopOverList,
            checkSwitch: (checkSwitch === '') ? false : checkSwitch
        }

        // null이 아닐때는 존재하므로 JSON.parse실시
        if (tmpLocationList !== null) {
            tmpParseData = JSON.parse(tmpLocationList);   
            resultData = [newData, ...tmpParseData];
        }
        // null일 경우에는 신규추가가 되므로 신규 작성한다.
        else {
            resultData = [newData];
        }
        
        localStorage.setItem('history_location_list', JSON.stringify(resultData))
    }

    // Default Card가 존재하는지 여부. true: 존재, false: 없음
    isChkCardValid = () => (Object.keys(this.state.defaultCardInfo).length !== 0)

    handleFinalValidateBtn = async () => {
        const { defaultCardInfo, 
            selectCouponRedeemCode,
            selCouponId,
            ticketData,
            discountType,
            mallReserved,
            CouponId,
            partnerHiddenCoupon} = this.state;
        
        //카드가 존재한다면
        if (this.isChkCardValid()) {
            let reserveAllState = getLocalStorage('reserve_view_all_state');
            if (reserveAllState !== null) {
                reserveAllState = {
                    ...reserveAllState,
                    selectCouponRedeemCode,
                    selCouponId,
                    ticketData,
                    discountType,
                    mallReserved,
                    CouponId,
                    defaultCardInfo,
                    partnerHiddenCoupon
                }
                setLocalStorage('reserve_view_all_state',reserveAllState)                
                this.props.history.push('/reserve_confirm_new_ski')
            } 

            
        }
        // 카드가 없다면
        else {
            await calertPromise(`현재 카드가 등록되어 있지 않습니다.\n카드 등록 후 예약해주세요.`)
        }             
    }

    handleCardRegist = () => {
        // 화면 이동을 위해 잠시 설정했던 데이터는 백업해둔다.
        localStorage.setItem('ReserveConfirmViewBackup', JSON.stringify(this.state));

        // 카드 등록 화면으로 이동.
        this.props.history.push(`/card_regist?redirect=reserve_renew_ski/${this.state.DrivingType}/5&customerno=${this.state.CustomerNo}`)
    }

    

    render() {
        const {
            startLocationAddress,
            stopOverList,
            endLocationAddress,

        } = store.getState().LocationSelector_ReduxModule;

        const {
            date,
            RevStartDt,
            RevEndDt,
            CustomerComment,
            Passenger,
            CustomerName,
            ReserveCharge,
            couponAvailableList,
            selectCouponRedeemCode,
            defaultCardInfo, 
            // DiscountRate, 
            DrivingCharge, 
            carLocation,
            // CustomerNo,
            PassengerPhoneNo,
            // passengerCheckStatus,
            // reserveType,
            // hourView,
            // typeView,
            // moneyView,
            calcPricing,
            discountType,
            covidKitStatus
        } = this.state;


        let generateCouponSelectOption = (arr) => {
            return arr.map((data, idx) => {
                return (
                    <>
                        <option value={data.RedeemCode}>{`${data.PromotionName} ${CouponApi.GetCouponTypeToStr(data.DiscountRate, data.DiscountAmount)} (${moment(data.ExpiredDt).format('YY-MM-DD')} 까지)`}</option>
                    </>
                )
            });
        }
        
        // "없음" 선택 컴포넌트
        let noneComponent = () => {
            return (
                
            <section className="accordion on ">
                <h1 className="accordion-head2">
                    <button type="button"  onClick={(e)=>{
                        this.handleDiscountTypeChange(DISCOUNT_TYPE_NONE)
                        e.stopPropagation()
                    }}>
                        <span className="grid cross-center mar-r25">
                            <div className="col title">
                                <div className="radio-box">
                                    <input type="radio" name="payment_ticket" id="payment_ticket" checked={discountType === DISCOUNT_TYPE_NONE} />
                                    없음
                                </div>
                            </div>
                            <div className="col right"></div>
                        </span>
                    </button>
                </h1>
            </section>  
            )
        }

        // "쿠폰" 선택 컴포넌트
        let counponComponent = () => {
            if (couponAvailableList.length)
                return(
                    <>
                        <section className="accordion on ">
                            <h1 className="accordion-head2">
                                <button type="button" onClick={(e)=>{
                                    this.handleDiscountTypeChange(DISCOUNT_TYPE_COUPON)
                                    e.stopPropagation()
                                }}>
                                    <span className="grid cross-center mar-r25">
                                        <div className="col title">
                                            <div className="radio-box">
                                                <input type="radio" name="payment_coupon" id="payment_coupon" checked={discountType === DISCOUNT_TYPE_COUPON} />
                                                쿠폰
                                            </div>
                                        </div>
                                        <div className="col right">
                                            <span className="text-medium text-primary">
                                                {couponAvailableList.length ? `${couponAvailableList.length}장 사용가능` : ''}
                                            </span>
                                        </div>
                                    </span>
                                    {/* <i className="arrow"></i> */}
                                </button>
                            </h1>
                            <div className="accordion-body2">
                                <select className="select type1" value={selectCouponRedeemCode} onChange={this.handleChangeSelectCoupon} disabled={discountType !== DISCOUNT_TYPE_COUPON}>
                                    {/* <option value="0">사용 안함</option>
                                    <option value="0">적용할 수 있는 쿠폰이 없습니다.</option>
                                    <option value="0">선택가능한 쿠폰이 있습니다.</option>
                                    {generateCouponSelectOption(couponAvailableList)} */}

                                    {/* <option value="0">사용 안함</option> */}
                                    {generateCouponSelectOption(couponAvailableList)}
                                </select>
                            </div>
                        </section>  

                    </>
                )
            return (<></>)
        }

        // "충전권" 선택 컴포넌트
        let ticketComponent = () => {
            
            return(
                <>
                    <section className="accordion on ">
                        <h1 className="accordion-head2">
                            <button type="button" onClick={(e)=>{
                                this.handleDiscountTypeChange(DISCOUNT_TYPE_TICKET)
                                e.stopPropagation()
                            }}>
                                <span className="grid cross-center mar-r25">
                                    <div className="col title">
                                        <div className="radio-box">
                                            <input type="radio" name="payment_ticket" id="payment_ticket" checked={discountType === DISCOUNT_TYPE_TICKET} />
                                            충전권
                                        </div>
                                    </div>
                                    <div className="col right">
                                        <span className="text-medium">
                                            잔여금액&nbsp;<strong>{Util.numberWithCommas(String(this.state.ticketData.Item.RemainAmount))}원</strong>
                                        </span>
                                    </div>
                                </span>
                                {/* <i className="arrow"></i> */}
                            </button>
                        </h1>
                        
                    </section>  
                </>
            )
        }

        
        return (
            <>
                {!this.state.loading ? (<Loading />) : ''}
                <PaymentCss />
                <Header
                    // title={`${ReserveApi.getDrivingTypeStr(this.state.DrivingType)} 예약 (5/5)`}
                    title={`스키 예약 (4/4)`}
                    onBackButton={this.handleGotoBack}
                    onHomeButton={this.handleGotoHome}
                />

                <div className="booking_box2 hd_margin_top">
                    <div className="reserve-list">
                        <div className="search-box">
                            <h1 className='h1' style={{paddingBottom:'10px'}}>카드 정보<i className="ico required2"><span className="blind">필수입력항목</span></i></h1>

                            {this.isChkCardValid() ? (
                                <div className="grid cross-center flex-between"  style={{fontSize:'1.1em'}}>   
                                    <div className="col"> {defaultCardInfo.IssuerCardNm} </div>
                                    <div className="col right"> {defaultCardInfo.DisplayCardNo} </div>
                                </div>
                            ) : (
                                <div className="component-wrap">
                                    <div className="btn-area horizontal">
                                        <button type="button" className="btn small secondary" onClick={this.handleCardRegist}><span>카드 등록</span></button>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                    <div className="reserve-list">
                        <div className="search-box">
                            <h1 className='h1' style={{paddingBottom:'10px'}}>결제 옵션<i className="ico required2"><span className="blind">필수입력항목</span></i></h1>

                            <div className="accordion-container" style={{borderTop:'none'}}>
                                {/* 일반, 골프 예약일 경우 모두표시 */}
                                {noneComponent()}
                                {counponComponent()}
                                {ticketComponent()}                                
                            </div>

                            
                        </div>
                    </div>
                    <div className="component-wrap">
                        <section className="information-text on">
                        <h1 className="information-head">
                            <button type="button" className="btn-viewmore" onClick={()=>{
                                this.setState({
                                    hiddenFlag:!this.state.hiddenFlag
                                })
                            }}>
                                <i className="ico ico-info"></i>
                                꼭 확인해주세요.
                                <i className={this.state.hiddenFlag ? "icon arrow" : "icon arrowdown"}></i>
                            </button>
                        </h1>
                        <div className="information-body" style={{display:this.state.hiddenFlag ? 'block' : 'none'}}>
                            <div className="info-desc">
                                <h3 className="info-stit">쿠폰사용안내</h3>
                                <ul className="text-sub">
                                    <li><p className="text-import">쿠폰 사용 시</p>운행요금에 대해서만 할인이 적용되며, 할증 및 연장요금, 수수료 등은 할인이 적용되지 않습니다.</li>
                                </ul>
                            </div>
                            <div className="info-desc">
                                <h3 className="info-stit">마스터 프리미엄, 하나 제휴카드 혜택안내</h3>
                                <ul className="text-sub">
                                    <li>
                                        <strong>마스터 프리미엄, 하나카드 바우처문의</strong>는 <span className='text-import'>모시러 컨시어지 카카오톡</span>으로 문의 부탁드립니다.<br />
                                    </li>
                                </ul>
                                
                            </div>
                        </div>
                        </section> 
                    </div>
                    
                    <div className="component-wrap inner-container">
                        {/* <div class="btn-area horizontal"> */}
                        <div class="btn-area btn-group-fixed horizontal">
                        
                            <button
                                type="button"
                                className="btn large secondary"
                                onClick={this.handleFinalValidateBtn}
                            >
                                <span>다음</span>
                            </button>
                        </div>
                    </div>
                    
                </div>


            </>
        );
    }
};

export default withCookies(ReserveConfirmStep1View);

