import React, { Component } from 'react';
// import ChannelService from '../class/ChannelService'
import KakaoChannel from '../class/KakaoChannel';
import {CHANNEL_PLUGIN} from '../constantData'
import {withCookies} from 'react-cookie'
import store from '../reduxStore'
import RnInterface from '../lib/RnInterface'
import { isChkfeatureSupportVersion } from '../lib/Util'

class ScrollToTop extends Component {
    async getSnapshotBeforeUpdate(prevProps, prevState) {
        const { location = {} } = prevProps;
        const {loginedData} = store.getState().global_data
        // console.log(location.pathname)
        // 컴포넌트 업데이트 시 페이지 이동인지 확인
        if (this.props.location.pathname !== location.pathname) {
            window.scrollTo(0, 0);    
        }
        
        // 이곳에 RnInterface를 사용하게 되면 다른곳에 RnInterface처리시 영향이 갈 수 있으므로
        // 사용하지 않는다. 이곳은 화면 변경시마다 매번 호출되기 때문.
        // let deviceType = await RnInterface.getData('devicetype')
        // if (deviceType !== false) {
        //     if (deviceType === 'A') {
        //         await RnInterface.callReactNative(`AndroidURLChange${this.props.location.pathname}`)
        //     }
        // }

        // 아래 페이지 접근시
        if (this.props.location.pathname === '/main' ||
            this.props.location.pathname.indexOf('/reserve/') !== -1 ||
            // this.props.location.pathname.indexOf('/policy_price') !== -1 ||
            this.props.location.pathname.indexOf('/ticket') !== -1 ||
            this.props.location.pathname.indexOf('/ticket_buying') !== -1 ||
            this.props.location.pathname.indexOf('/notice') !== -1 ||
            this.props.location.pathname.indexOf('/reserve_confirm') !== -1 ||
            this.props.location.pathname.indexOf('/reserve_air/') !== -1 ||
            this.props.location.pathname.indexOf('/reserve_renew/') !== -1 ||
            this.props.location.pathname.indexOf('/reserve_monthlyonce') !== -1 ||
            this.props.location.pathname.indexOf('/use_state') !== -1 ||
            this.props.location.pathname.indexOf('/common_link') !== -1 ||
            this.props.location.pathname.indexOf('/reserve_partner/') !== -1) {   

            KakaoChannel.show()
        } 
        else {
            KakaoChannel.hide()
        }       



        // 1.3.20 이후부터는 채널톡 아이콘을 표시하지 않도록 한다.
        if (false) {
            if (Object.keys(loginedData).length) {
                console.log('window.ChannelIOCustomInitBootFlag',window.ChannelIOCustomInitBootFlag)
                
                // ChannelIO boot 초기화가 되어있다면
                if (window.ChannelIOCustomInitBootFlag) {
                    // 아래 페이지 접근시
                    if (this.props.location.pathname === '/main' ||
                        this.props.location.pathname.indexOf('/reserve/') !== -1 ||
                        this.props.location.pathname.indexOf('/policy_price') !== -1 ||
                        this.props.location.pathname.indexOf('/ticket') !== -1 ||
                        this.props.location.pathname.indexOf('/ticket_buying') !== -1 ||
                        this.props.location.pathname.indexOf('/notice') !== -1 ||
                        this.props.location.pathname.indexOf('/reserve_confirm') !== -1 ||
                        this.props.location.pathname.indexOf('/reserve_air/') !== -1 ||
                        this.props.location.pathname.indexOf('/reserve_partner/') !== -1) {
                            
                        // 채널톡 보임
                        // ChannelService.show();                  
                    } 
                    else {
                        // 채널톡 보임
                        // ChannelService.hide();
                        
                    }
                    
                }
                // ChannelIO boot 초기화되어있지 않을때
                else {
                    // Boot Channel as a registered user
                    const settings = {
                        userId: loginedData.UserId,
                        pluginKey: CHANNEL_PLUGIN,
                        mobileMessengerMode: 'iframe',
                        profile: {
                            name: loginedData.CustomerName,
                            mobileNumber: loginedData.PhoneNumber,
                            email: loginedData.Email,
                        },
                        locale: "ko",
                        zIndex: 100000000000000
                    };
                    // 채널톡 보임
                    // ChannelService.boot(settings);
                    // ChannelService.hide();
                }
                
            }
        }    

        return '';
    }
    componentDidUpdate(prevProps, prevState) {
        
    }
    

    render() {
        return <></>;
    }
}

export default withCookies(ScrollToTop);