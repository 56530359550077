// <Route exact path="/use_state_detail/:drivingno" component={UseStateView} />

import React, { Component } from 'react';
import {NavLink} from 'react-router-dom'
import { withCookies } from 'react-cookie'

import store from '../../reduxStore'
import Util, { calert, calertConfirm, calertPromise, customMoment, calert10CouponPopup } from '../../lib/Util'
import ReserveApi from '../../lib/ReserveApi'
// import icon_arrow from "../../img/record/icon_arrow.svg"

import RnInterface from '../../lib/RnInterface'

import Loading from '../../components/Common/Loading'

class Coupon10CheckNativeOnlyView extends Component {
    state = {
        info: {},
        stopOverList: [],
        loading:1,
    }

    redirectPage = async() => {
        // URL형식 : `${LIVE_URL}/chk_ten_coupon_native?view_state=${viewState}&driving_no=${drivno}`,
        try {
            // URL의 쿼리스트링을 파싱  (view_state, driving_no)
            let qsData = Util.getQueryStringObject();
            
            if (qsData.view_state === 'usehistory')     
                this.props.history.replace(`/use_history_detail_native/${qsData.driving_no}`)
            else 
                this.props.history.replace(`/set_estimate_native/${qsData.driving_no}`)
        }
        catch(e) {
            console.log(e)
            await calertPromise('화면 전환 도중 오류가 발생되었습니다.\n로그인 후 메뉴 > 이용기록 > 이용기록 상세를 확인해주세요.')
            await RnInterface.callReactNative('UseHistoryLoginFailedFlag')
        }
    }

    async componentDidMount() {
        
        // 2021/11/30 부로 3천원 할인 쿠폰이 없어진다. 바로 화면이동처리로 넘어간다.
        this.redirectPage()

        /*  
        2021/11/30부로 3천원 할인 쿠폰은 없어진다. 아래의 처리는 의미기 없기 때문에 만약을 대비해 주석처리 한다.
        (차후에 부활할 수도 있음)
        https://mosiler.slack.com/archives/C01BVCFL0N9/p1638158906047100

        try {
            this.setState({loading:0})
            await RnInterface.AndroidURLChange(this.props.location.pathname)

            const { cookies }  = this.props;
            const { CustomerNo } = store.getState().global_data.loginedData;

            if (CustomerNo === undefined) {
                await calertPromise('현재 로그인 되어있지 않습니다.\n로그인 후 메뉴 > 이용기록 > 이용기록 상세를 확인해주세요.')
                await RnInterface.callReactNative('UseHistoryLoginFailedFlag')
            } else {

                let response = await ReserveApi.get_check_10Coupon(CustomerNo,
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt'))

                if (response &&  response.Result == "success") {
                    if (response.Item == "1661") {
                        console.log("10salesCoupon Exist[1661].");
                        await calert10CouponPopup();
                        this.redirectPage()
                    }
                } else {
                    this.redirectPage()
                }
            }

            
        } catch(e) {
            this.setState({loading:1})
            console.log(e)
            await calertPromise('데이터 취득 도중 오류가 발생했습니다.\n로그인 후 메뉴 > 이용기록 > 이용기록 상세를 확인해주세요.')
            await RnInterface.callReactNative('UseHistoryLoginFailedFlag')
        }
        */
    }


    render() {
        return (
            <>
            <Loading />
            </>
        );
    }
};

export default withCookies(Coupon10CheckNativeOnlyView);
