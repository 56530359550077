// <Route exact path="/use_state_detail/:drivingno" component={UseStateView} />

import React, { Component } from 'react';
import { withCookies } from 'react-cookie'
import { createGlobalStyle } from "styled-components";
import store from '../../reduxStore'
import Util, { calert, calertConfirm, calertPromise, customMoment, customerCommentStrProcess } from '../../lib/Util'
// import icon_arrow from "../../img/record/icon_arrow.svg"
import icon_point from "../../img/record/icon_point.svg"
import icon_point_waypoint from "../../img/record/icon_point_waypoint.svg"
import icon_arrow from "../../img/record/icon_arrow.svg"
import icon_location from "../../img/record/icon_location.svg"

import Header from '../../components/Common/Header'
import ReserveApi from '../../lib/ReserveApi';
import RnInterface from '../../lib/RnInterface'

import Loading from '../../components/Common/Loading'


import {getReservationListItemPromise} from '../../modules/UseState/UseState_ReduxModule'
import {cancelReservationReduxPromise} from '../../modules/UseState/UseState_ReduxModule'

const AIRPORT_HOME_VALET_CODE = 26;
const GlobalStyle = createGlobalStyle`
/* booking_state.css */
@charset "utf-8";

/* record_usestatedetail.css */
/* 이용내역 */
/*.record_usestatedetail {display:inline-block; width:100%; min-height:calc(100vh - 53px); background:#ebebed; padding:0px;}*/
.record_usestatedetail {display:inline-block; width:100%; min-height:calc(100vh - 53px); background:#fff; padding:0px;}
.record_usestatedetail li.record_box { display:inline-block; width:100%; background:#fff; margin-bottom:0px;}
/*.record_usestatedetail li.record_box { display:inline-block; width:100%; background:#fff; margin-bottom:15px;}*/
.record_usestatedetail span.record_hd { display:inline-block; width:100%; padding:20px 20px 10px 20px; border-bottom:1px solid #eee;}
.record_usestatedetail span.record_hd_color1 {border-left:5px solid #1fafd2 !important}
.record_usestatedetail span.record_hd_color3 {border-left:5px solid #718ae4 !important}
.record_usestatedetail span.record_hd span.date { display:inline-block; width:100%; font-size:1.1em; padding-bottom:0px;}
.record_usestatedetail span.record_hd span.cancel {color:#ff0000 !important}
.record_usestatedetail span.record_hd span.subject { display:inline-block; width:100%; padding:10px 0;}
.record_usestatedetail span.record_hd span.subject span.kind { display:inline-block; font-size:1.5em; font-weight:bold; padding-right:20px;}
.record_usestatedetail span.record_hd span.subject span.number { display:inline-block; font-size:1.2em; font-weight:normal;}
.record_usestatedetail span.record_hd span.subject button.btn_more { display:inline-block; float:right; background:#fff; border:0px;}
.record_usestatedetail span.record_hd span.subject button.btn_more img {width:10px;}



.record_usestatedetail table.rtable {width:100%; font-size:1.2em;}
.record_usestatedetail table.rtable th {width:90px; padding:5px 0; font-weight:normal; color:#999;}
.record_usestatedetail table.rtable td {padding:5px 0;}
.record_usestatedetail table.rtable th.price {color:#718ae4 !important; font-weight:bold;}
.record_usestatedetail table.rtable td.price {color:#718ae4; font-weight:bold; font-size:1.3em;}
.record_usestatedetail table.rtable td.cnumber {color:#999; padding:0px 0 5px 0;}

.record_usestatedetail span.navi_info2 { display:inline-block; width:100%; padding:10px 20px 10px 25px; margin:0px;}
/*.record_usestatedetail span.navi_info2 { display:inline-block; width:100%; padding:5px 0 0px 0; margin:0px;}*/

/*.record_usestatedetail table.rtable4 {width:100%; font-size:1.1em; border-top:1px solid #eee;}*/
.record_usestatedetail table.rtable4 {width:100%; font-size:1.1em;}
.record_usestatedetail table.rtable4 td {padding:0px 0 10px 0;}
.record_usestatedetail table.rtable4 td.first { vertical-align:top; width:60px; padding:10px 0;}
.record_usestatedetail table.rtable4 td.first img {width:10px;}
/*.record_usestatedetail table.rtable4 td:nth-child(2) {padding-bottom:0px !important;}*/

.record_usestatedetail span.record_pay { display:inline-block; width:100%; padding:10px 25px;}


/* 이용내역 - 자세히보기 */
.record_usestatedetail li.hd_notice { display:inline-block; width:100%; padding:20px; color:#999;}
.record_usestatedetail span.record_hd span.btn { display:inline-block; width:100%; padding:5px 0;}
.record_usestatedetail span.record_hd span.btn a.btn_grade { display:inline-block; background:#000; padding:8px 20px; color:#fff; font-size:1.2em;}

.record_usestatedetail span.record_detail2 { display:inline-block; width:100%; padding:10px 20px; border-bottom:1px solid #eee;}
.record_usestatedetail span.record_detail2 span.section_title_beforereserve { display:inline-block; width:100%; font-size:1.3em; padding:10px 0 10px 0; border-bottom:1px solid #eee; margin-bottom:10px;}

.record_usestatedetail table.rtable2 {width:100%; font-size:1.2em; }
.record_usestatedetail table.rtable2 th {width:90px; padding:5px 0; font-weight:normal; color:#999; vertical-align:top;}
.record_usestatedetail table.rtable2 td {padding:5px 0;}
.record_usestatedetail table.rtable2 td.price {font-size:1.3em; font-weight:bold;}
.record_usestatedetail table.rtable2 th.result {color:#fff !important;}
.record_usestatedetail table.rtable2 td.result_price {font-size:1.3em; font-weight:bold;}
.record_usestatedetail table.rtable2 td.result_kind {font-size:0.9em; padding:10px 0 5px 0;}
.record_usestatedetail table.rtable2 td.result_sprice {font-size:0.9em; padding:0 0 5px 0;}
.record_usestatedetail table.rtable2 td.result_date {font-size:0.9em; color:#9397a2;}

.record_usestatedetail table.rtable3 {width:100%; font-size:1.1em;}
.record_usestatedetail table.rtable3 td {padding:0px 0 10px 0;}
.record_usestatedetail table.rtable3 td.first { vertical-align:top; width:20px; padding:4px 0;}
.record_usestatedetail table.rtable3 td.first img {width:10px;}
.record_usestatedetail table.rtable3 td:nth-child(2) {padding-bottom:0px !important;}

.record_usestatedetail span.navi_info { display:inline-block; width:100vw; background:#f2f8fe; padding:20px; margin:10px 0 10px -20px;}

.record_usestatedetail li.price_result { display:inline-block; width:100%; background:#4d5467; padding:20px; color:#fff;}

.btn_st_2e303f { display:inline-block; width:50%; background:#2e303f; height:50px; line-height:50px; font-size:1.3em; border:0px; color:#fff;}
.btn_st_f1f0f5 { display:inline-block; width:50%; background:#cecece; height:50px; line-height:50px; font-size:1.3em; border:0px; color:#565656;}
`;

class UseStateDetailView extends Component {
    state = {
        info: {},
        stopOverList: [],
        loading:0,
    }


    async componentDidMount() {
        try {
            this.setState({loading:0})
            await RnInterface.AndroidURLChange(this.props.location.pathname)

            const { drivingno } = this.props.match.params;
            const { cookies }  = this.props;
            const { CustomerNo } = store.getState().global_data.loginedData;

            if (CustomerNo === undefined) {
                await calertPromise('현재 로그인 되어있지 않습니다.\n로그인 후 메뉴 > 예약현황 > 예약현황 상세를 확인해주세요.')
                await RnInterface.callReactNative('UseStateLoginFailedFlag')
            } 
            else {
                await store.dispatch(getReservationListItemPromise(
                    CustomerNo,
                    drivingno,
                    cookies.get('ostype'),
                    cookies.get('deviceid'),
                    cookies.get('login_encrypt')
                ))
    
                // redux
                const { getinfoNative } = store.getState().UseState_ReduxModule
                
                let waypointArr = [getinfoNative.WaypointAddress01, getinfoNative.WaypointAddress02, getinfoNative.WaypointAddress03]
                waypointArr = waypointArr.filter((data1)=>{console.log(data1); return !(data1 === undefined || data1 === null || data1.length === 0)})
                // console.log('data[0].RevStartDt');
                // console.log(data[0].RevStartDt);
                // console.log(data[0].RevEndDt);
                // console.log(data[0].StartDate);
                // console.log(data[0].EndDate);
                this.setState({
                    info:  getinfoNative,
                    stopOverList: waypointArr,
                    loading:1
                })
            }

            
        } catch(e) {
            this.setState({loading:1})
            console.log(e)
        }
        
        
    }

    handleBack = async (params) => {
        // this.props.history.goBack();
        await RnInterface.callReactNative('UseStateClose')
    }

    handleHome = (params) => {
        this.props.history.replace('/main')
    }

    handleGotoSafetyView = () => {
        const { drivingno } = this.props.match.params;
        this.props.history.push(`/safety_info/${drivingno}`)
    }

    // handleCancelReservation = async () => {
    //     try {
    //         const { cookies }  = this.props;
    //         const { CustomerNo, DrivingNo, defaultCardInfo, reserveData } = this.props.history.location.state;      // UseState화면에서 예약 항목 선택시 해당 정보를 이곳에서도 확인 할 수 있도록 처리하고 있다.
    //         let alertMsg = '';

    //         this.setState({loading:0})
            
    //         // 취소 가능한지 여부를 확인
    //         let canCancelResult = await ReserveApi.CanCancelDriving(
    //             DrivingNo,
    //             cookies.get('ostype'),
    //             cookies.get('deviceid'),
    //             cookies.get('login_encrypt')
    //         )

    //         if (canCancelResult.Result === 'success') {
    //             // 취소할 수 있는 상황이라면
    //             if (canCancelResult.Item.CanCancel) {
    //                 alertMsg += '예약 취소를 진행하시겠습니까?'
    //                 if (canCancelResult.Item.CancelFee !== 0) {
    //                     alertMsg += `\n(취소수수료 ${Util.numberWithCommas(String(canCancelResult.Item.CancelFee))}원이 발생됩니다. )`
    //                 } 
                    
    //                 if (await calertConfirm( alertMsg )) {
                        
    //                     // (cn, no, data, carddata, ostype, deviceid, loginEncrypt)
    //                     let cancelResult = await store.dispatch(cancelReservationReduxPromise(
    //                         CustomerNo,
    //                         DrivingNo,
    //                         reserveData,
    //                         defaultCardInfo,
    //                         cookies.get('ostype'),
    //                         cookies.get('deviceid'),
    //                         cookies.get('login_encrypt')
    //                     ))
                        
    //                     // false일때는 우선 처리하지 않는다. alert도 상기 함수에서 띄우고 있다.
    //                     if (cancelResult) {
    //                         await RnInterface.callReactNative(`ScreenViewAnalyticsReservationCancel`)
    //                         this.props.history.goBack();
    //                     }
    //                 }
    //                 else {
    //                     this.setState({ loading:1 })
    //                 }
                    
    //             }
    //             // 취소할 수 없다면
    //             else {
    //                 // 출발시간 기준 12시간 이내이거나 배차가 완료되었을 경우는 예약취소 할 수 없습니다.
    //                 // await calertPromise('출발시간 기준 12시간 이내이거나 배차 배정이 완료되었을 경우는 예약취소 할 수 없습니다.')
    //                 await calertPromise(canCancelResult.Message)
    //                 this.setState({ loading:1 })
    //             }
    //         } 
    //         // result가 fail이라면
    //         else {
    //             await calertPromise('예약취소를 실패했습니다. 잠시후 다시 시도해주세요.')
    //             this.setState({ loading:1 })
    //         }
    //     } catch(e) {
    //         console.log(e)
    //         this.setState({loading:1})
    //     }
        
    // }

    render() {
        const { info } = this.state
        let examComponent = (arr) => {
            return arr.map((data, idx) => {
                return (
                    <>
                        <li><i className="ico ico-vial1">경유지</i>{data}</li>
                    </>
                )
            });
        }

        let serviceList = (arr)=>{
            try {
                let tmpArr = [];
                if (arr.length) {
                    for (let i in arr) {
                        tmpArr.push(arr[i].ItemCode)
                    }
                    return tmpArr.toString();
                } 
                return '신청한 내역이 없습니다.';
            }
            catch(e) {
                return '신청한 내역이 없습니다.';
            }           
        }

        if (!this.state.loading) return (<>
            <GlobalStyle />
            <Loading />
        </>);
        return (
            <>
            <GlobalStyle/>
                <Header
                    title="예약현황 상세"
                    onBackButton={this.handleBack}
                    // onHomeButton={this.handleHome}
                />

                <main id="Contents">
                    <div className="component-wrap  detail-view">
                        {/*
                        <div class="title-group title-group-inner bg-gray">
                            <h1 class="head-title">타이틀</h1>
                            <div class="sub-area">
                                <div class="btn-group">
                                    <button type="button" class="btn text">버튼</button>
                                </div>
                            </div>
                        </div>
                        <div class="text-group text-group-inner bg-gray">
                            <p class="text-medium">배차 전 예약상태를 확인 하실 수 있습니다.</p>
                        </div> 

                        파트너 미배정시 수수료가 안붙음
                        파트너 배정시 수수료가 붙음
                        */}
                  
                        <div className="data-list-container">
                            <ul className="data-list">
                                <li className="data-list-item">
                                    <div className="data-header">
                                        <div className="desc-sum">
                                            <span className="state-txt">
                                                &nbsp;
                                                {/* {info.DrivingStatusName} */}
                                            </span>
                                        </div>
                                        <div className="desc-title" onClick="">
                                            <strong>{(info.CustomerComment && info.CustomerComment.indexOf('스키예약') >= 0) ? '스키' : ReserveApi.getDrivingTypeStr(info.DrivingType)}</strong>  
                                            <span className="desc-day">예약접수일 : {customMoment(info.RegDt).format('YYYY-MM-DD HH:mm')}</span>                                              
                                        </div>
                                        <div className="btn-area grid gut-guide">
                                            {/* native view에서는 우선 예약취소 못하도록 한다.
                                            예약 취소시 카드 데이터도 취득 받아야 하는데 과정이 약간 복잡. 대응은 가능.
                                            시간이 없기 때문에 우선 숨기고, 차후에 지적이 나올때 대응하는 것으로..
                                            <div className="col item">
                                                <button type="button" className="btn small pressed" onClick={this.handleCancelReservation}><span>예약취소</span></button>
                                            </div> */}
                                            {(info.DriverNo !== -1 && 
                                                (
                                                    info.DriverTemperatureCheckImgName !== '' &&
                                                    info.DriverAlcoholCheckImgName !== ''
                                                )
                                            ) &&
                                            (
                                                <>
                                                    <div className="col item">
                                                        <button type="button" className="btn small pressed liner" onClick={this.handleGotoSafetyView}><span>운행안전정보</span></button>
                                                    </div>
                                                    
                                                    {/* <button type="button" className="btn small" onClick={ async ()=>{
                                                        await calertImagePopup(info.DriverProfileImgName, info.DriverName, info.DriverSSN)
                                                    }}><span>파트너보기</span></button> */}
                                                </>
                                            )}
                                        </div>            
                                    </div>                                     
                                </li>
                            </ul>
                            <div className="data-view">
                                <ul className="desc-list">
                                    <li className="desc-item">
                                        <div className="tit"><strong>예약날짜</strong></div>
                                        <div className="desc"><strong>{customMoment(info.RevStartDt).format('YYYY-MM-DD')}</strong></div>
                                    </li>
                                    <li className="desc-item">
                                        <div className="tit"><strong>예약시간</strong></div>
                                        <div className="desc"><strong>{`${customMoment(info.RevStartDt).format('HH:mm')} ~ ${customMoment(info.RevEndDt).format('HH:mm')}`} ({ReserveApi.getTimeDifferenceHourToStr(info.RevStartDt, info.RevEndDt)})</strong></div>
                                    </li>
                                </ul>
                                <div className="route-box">
                                    <ul>
                                        <li><i className="ico ico-start">출발지</i>{info.StartAddress}</li>
                                        {examComponent(this.state.stopOverList)}                                        
                                        <li><i className="ico ico-goal">종료지</i>{info.EndAddress}</li>
                                    </ul>
                                </div> 
                                <ul className="desc-list">
                                    <li className="desc-item">
                                        <div className="tit"><span>예약자</span></div>
                                        <div className="desc"><span>{info.CustomerName}</span></div>
                                    </li>
                                    <li className="desc-item">
                                        <div className="tit"><span>탑승자</span></div>
                                        <div className="desc"><span>{info.Passenger}</span></div>
                                    </li>     
                                    <li className="desc-item">
                                        <div className="tit"><span>{Number(info.DrivingType) === AIRPORT_HOME_VALET_CODE ? '주차위치' : '차량위치' }</span></div>
                                        <div className="desc"><span>{info.CarMemo && info.CarMemo.length !== undefined ? info.CarMemo : '없음'}</span></div>
                                    </li>
                                    <li className="desc-item">
                                        <div className="tit"><span>요청사항</span></div>
                                        <div className="desc"><span>{customerCommentStrProcess(info.CustomerComment)}</span></div>
                                    </li>    
                                    <li className="desc-item">
                                        <div className="tit"><span>파트너</span></div>
                                        <div className="desc"><span>{info.DriverName}</span></div>
                                    </li>

                                    {/* 방역키트 지급 축소
                                    <li className="desc-item">
                                        <div className="tit"><span>방역키트</span></div>
                                        <div className="desc"><span>{info.RequestCovid19Kit ? '추가' : '미추가'}</span></div>
                                    </li> */}
                                </ul>                                                                                               
                            </div>      
                        </div>         
                        <div className="accordion-container">                                 
                            {/* 파트너 정보                               
                            <section className="accordion on space-top">
                                <h1 className="accordion-head">
                                    <button type="button">
                                        <span className="grid cross-center mar-r25">
                                            <div className="col title">파트너정보</div>
                                            <div className="col right"></div>
                                        </span>
                                        <i className="arrow"></i>
                                    </button>                                
                                </h1>
                                <div className="accordion-body">
                                    <div className="grid cross-center">
                                        <div className="col"></div>
                                        <div className="col right">
                                            <p>윤상호</p>                                          
                                            <div className="rating_box">
                                            </div>
                                            <NavLink to="tel:1577-7272" class="btn-call-center"><span class="blind">전화걸기</span></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </section>     
                            {/* 결제정보 */}
                            <section className="accordion on space-top">
                                <h1 className="accordion-head">
                                    <button type="button">
                                        <span className="grid cross-center mar-r25">
                                            <div className="col title">결제정보</div>
                                            <div className="col right"></div>
                                        </span>
                                        {/* <i className="arrow"></i> */}
                                    </button>                                
                                </h1>
                                <div className="accordion-body">
                                    <ul className="desc-list">
                                        <li className="desc-item">
                                            <div className="tit"><span>운행요금</span></div>
                                            <div className="desc price"><span>{Util.numberWithCommas(String(info.DrivingCharge))}원</span></div>
                                        </li>
                                        <li className="desc-item">
                                            <div className="tit"><span>출발지할증</span></div>
                                            <div className="desc price"><span>{Util.numberWithCommas(String(info.StartAreaFee))}원</span></div>
                                        </li>
                                        <li className="desc-item">
                                            <div className="tit"><span>지역할증</span></div>
                                            <div className="desc price"><span>{Util.numberWithCommas(String(info.AreaFee))}원</span></div>
                                        </li>
                                        <li className="desc-item">
                                            <div className="tit"><span>심야할증</span></div>
                                            <div className="desc price"><span>{Util.numberWithCommas(String(info.NightFee))}원</span></div>
                                        </li>
                                        {/* 할인금액 : 쿠폰 할인, 예약 할인 */}
                                        <li className="desc-item">
                                            <div className="tit"><span>할인금액</span></div>
                                            <div className="desc price"><span>{Util.numberWithCommas(String(info.DiscountAmount + info.ReserveDiscountAmount))}원</span></div>
                                        </li>     
                                        {/* <li className="desc-item">
                                            <div className="tit"><span>실비</span></div>
                                            <div className="desc price"><span>???원</span></div>
                                        </li> */}
                                        {/* <li className="desc-item">
                                            <div className="tit"><span>충전권사용</span></div>
                                            <div className="desc price"><span>{info.PrePaidTicketYN === 'Y' ? '사용' : '미사용'}</span></div>
                                        </li> */}
                                    </ul>  
                                </div>
                                <div className="data-amount">                                          
                                    <ul className="desc-list">                                             
                                        <li className="desc-item">
                                            <div className="amount-tit"><strong>결제금액</strong></div>
                                            <div className="amount-desc price">
                                                <p><strong className="text-money">{Util.numberWithCommas(String(info.ReseveCharge))}원</strong></p>
                                                {info.PrePaidTicketYN === 'Y' && (
                                                    <p><span className="mar-r5">충전권 결제</span><strong className="text-money">{Util.numberWithCommas(String(info.PrePaidTicketAmount))}원</strong></p>   
                                                )}                                
                                            </div>
                                        </li>                                                                                                                                                                                 
                                    </ul>                                        
                                </div>                              
                            </section>                                             
                        </div>  
                    </div>  
                </main>
            </>
        );
    }
};

export default withCookies(UseStateDetailView);
