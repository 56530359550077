import { combineReducers } from "redux";
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'


import global_data from "./Global/global_data";
import post from "./Communication/post";
import LocationSelector_ReduxModule from './Reserve/LocationSelector_ReduxModule'
import Address_ReduxModule from './Global/Address_ReduxModule'
import DrivingType_ReduxModule from './Global/DrivingType_ReduxModule'

import TimeSelector_ReduxModule from './Reserve/TimeSelector_ReduxModule'
import UseState_ReduxModule from './UseState/UseState_ReduxModule'
import UseHistory_ReduxModule from './UseHistory/UseHistory_ReduxModule'
import RealtimeLiveDriving_ReduxModule from './RealtimeLiveDriving/RealtimeLiveDriving_ReduxModule'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
        'global_data',
        'post',
        'Address_ReduxModule',
        'LocationSelector_ReduxModule',
        'TimeSelector_ReduxModule',
        'UseState_ReduxModule',
        'UseHistory_ReduxModule',
        'DrivingType_ReduxModule',
        'RealtimeLiveDriving_ReduxModule',
    ]
}


const rootReducer = combineReducers({
    global_data,
    post,
    Address_ReduxModule,
    LocationSelector_ReduxModule,
    TimeSelector_ReduxModule,
    UseState_ReduxModule,
    UseHistory_ReduxModule,
    DrivingType_ReduxModule,
    RealtimeLiveDriving_ReduxModule
});


export default persistReducer(persistConfig, rootReducer);

/*
redux 사용하게 되면 화면 갱신하거나 url을 직접 입력하게 되면 redux 정보가 지워진다. 
redux 정보에 의존하는 화면이 있기 때문에 이 정보가 없어지면 곤란하기 때문에
redux-persist 라이브러리를 사용하여 정보들을 localstorage에 저장하도록 한다.
*/